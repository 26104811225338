import Axios from "axios"
import { decorate, observable, action } from "mobx"
// import LocalGridConfig from "../../../config/LocalGridConfig"
import LocalGridConfig from '../../../config/LocalGridConfig'
import moment from 'moment';

export default class JournalsStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	deleteValues = null
	agGrid = null

	dropdown_list = null

	exportLoading = false
	listLoading = false

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('journal_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('journal_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// get all records with limited data
	getAllRecords = () => {
		return Axios.get(`getjournallist`).then(({ data }) => {
			this.dropdown_list = data.data
			return data
		})
	}

	// export data as a csv
	exportData = async () => {
		this.exportLoading = true;
		var ledger_id = 1;
		var params = {
			columnKeys: ['id', 'journal_code', 'journal_type', 'journal_groups.journal_group_name', 'journal_label', 'journal_desc', 'tax_settings.tax_name', 'updated_by', 'updated_at'],
			fileName: ledger_id + '-journals-export-' + moment().format("YYYY-MM-DD hh:mm:s"),
		};
		await this.agGrid.api.paginationSetPageSize(this.total)
		await this.agGrid.api.exportDataAsCsv(params)
		await this.agGrid.api.paginationSetPageSize(this.per_page)
		this.exportLoading = false;
	}

	// call api to get records
	getList = () => {
		this.listLoading = true
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.get(`journal/list`).then(({ data }) => {
			this.listLoading = false
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
					item.srno = index + 1
					item.parent_id = item.parent_id || 0
					if (item.journal_groups === null) {
						item.journal_groups = { journal_group_name: null }
					}
					if (item.tax_settings === null) {
						item.tax_settings = { tax_name: null }
					}
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('journal_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
			if (this.agGrid && this.agGrid.api) {
				this.agGrid.api.setFilterModel(filter)
			}
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`journal/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`journal/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`journal/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ImportFile = (formdata) => {
		const options = {
			headers: { Accept: '' },
		};
		return Axios.post(`journal/import`, formdata, options).then(({ data }) => {
			this.getList()
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(JournalsStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	dropdown_list: observable,
	agGrid: observable,
	exportLoading: observable,
	listLoading: observable,
	getAllRecords: action,
	getList: action,
	EditData: action,
	setEditValues: action,
	setDeleteValues: action,
	DeleteData: action,
	setupGrid: action,
	onFilterChanged: action,
	setPageSize: action,
	exportData: action,
	ImportFile: action,
	onGridChanged: action,
})
