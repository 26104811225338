import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DateComparator } from '../../../../utils/GlobalFunction';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import NumberFormat from 'react-number-format';
import { Button, Drawer } from 'antd';
import PreviewAETRChildStatementComponent from './PreviewAETRChildStatementComponent';
import { EditOutlined, EyeOutlined, TableOutlined } from '@ant-design/icons'
import EditComponent from '../../../TradeTransaction/AllTransactions/component/EditComponent';
import ViewComponent from '../../../TradeTransaction/AllTransactions/component/ViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

const PriviewChildAETRComponent = observer((props) => {
    const { UserStore, aETRReportsStore, aETRReportsChildStore } = useStore()
    const [previewAETRChild, setPreviewAETRChild] = useState(false)
    const [editDrawer, setEditDrawer] = useState(false)
    const [viewDrawer, setViewDrawer] = useState(false)
    const [transactionId, setTransactionId] = useState(null)

    const ActionRenderer = (prop) => {
        return prop.data.organisation !== "Total" && (
            <div className="action-column">
                {(UserStore.checkPermission(22, 'view')) && <Button title="View Trade Transaction" type="text" onClick={() => openViewDrawer(prop.data.tte_id)}><EyeOutlined /></Button>}
                {(UserStore.checkPermission(22, 'edit')) && <Button title="Edit Trade Transaction" type="text" onClick={() => openEditDrawer(prop.data.tte_id)}><EditOutlined /></Button>}
            </div>
        )
    }

    const gridOptions = {
        columnDefs: [{
            headerName: '#',
            headerTooltip: 'SR#',
            field: '',
            filter: 'agNumberColumnFilter',
            width: 37,
            valueGetter: function (params) {
                if (params.data.organisation !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            },
            tooltipValueGetter: function (params) {
                if (params.data.date !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            }
        }, {
            headerName: 'Organisation',
            headerTooltip: 'Organisation',
            field: 'organisation',
            tooltipField: 'organisation',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 130
        }, {
            headerName: 'Batch',
            headerTooltip: 'Batch',
            tooltipField: 'batch',
            field: 'batch',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 66
        }, {
            headerName: 'Type',
            headerTooltip: 'Type',
            field: 'etr_type',
            tooltipField: 'etr_type',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 56
        }, {
            headerName: 'Months',
            headerTooltip: 'Months',
            field: 'months',
            tooltipField: 'months',
            cellRendererFramework: (params) => (
                <div style={styles.cellsInt}>
                    <NumberFormat value={params.data.months} displayType={'text'} />
                </div>
            ),
            width: 74,
        }, {
            headerName: 'Traded',
            headerTooltip: 'Traded',
            field: 'traded_on',
            tooltipField: 'traded_on',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 88
        }, {
            headerName: 'Value',
            headerTooltip: 'Value',
            field: 'an_value',
            tooltipField: 'an_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.an_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 92
        }, {
            headerName: 'PTV',
            headerTooltip: 'PVT',
            field: 'ao_ltv',
            tooltipField: 'ao_ltv',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.organisation !== 'Total' ? params.data.ao_ltv ? params.data.ao_ltv : '100' : null} suffix="%" displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={params.data.ao_ltv ? 2 : 1} />
                </div>
            ),
            width: 53
        }, {
            headerName: 'Face Value',
            headerTooltip: 'Face Value',
            field: 'face_value',
            tooltipField: 'face_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.face_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 93
        }, {
            headerName: 'Deposit',
            headerTooltip: 'Deposit',
            field: 'aq_deposit',
            tooltipField: 'aq_deposit',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.aq_deposit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 80
        }, {
            headerName: 'Ccy',
            headerTooltip: 'Currency',
            field: 'currency',
            tooltipField: 'currency',
            filterParams: {
                defaultToNothingSelected: true,
            },
            cellStyle: { textAlign: 'center' },
            width: 50
        }, {
            headerName: 'Instalment',
            headerTooltip: 'Instalment',
            field: 'payment_each_month',
            tooltipField: 'payment_each_month',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.payment_each_month} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Principal',
            headerTooltip: 'Principal',
            field: 'principal_amt',
            tooltipField: 'principal_amt',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.principal_amt ? params.data.principal_amt : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Income',
            headerTooltip: 'Income',
            field: 'income_amt',
            tooltipField: 'income_amt',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.income_amt ? params.data.income_amt : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Profit',
            headerTooltip: 'Profit',
            field: 'profit',
            tooltipField: 'profit',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.profit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 90,
        }, {
            headerName: 'Receipts',
            headerTooltip: 'Receipts',
            field: 'income_value',
            tooltipField: 'income_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.income_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 83,
        }, {
            headerName: 'Outstanding',
            headerTooltip: 'Outstanding',
            field: 'outstanding',
            tooltipField: 'outstanding',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.outstanding} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Actions',
            headerTooltip: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            sortable: false,
            filter: false,
            pinned: 'right',
            width: 78,
        }]
    }

    const getChildStatement = () => {
        let payload = {
            organisations: aETRReportsStore.internalChildStatement.length ? aETRReportsStore.internalChildStatement[0].org_id : null,
            batches: aETRReportsStore.internalChildStatement.length ? aETRReportsStore.internalChildStatement[0].batch : null,
        }
        aETRReportsStore.getaETRChildDetails(payload).then((data) => {
            setPreviewAETRChild(data)
        })
    }

    const closeChildStatement = () => {
        setPreviewAETRChild(false)
    }

    const close = () => {
        props.close()
    }

    const styles = {
        cells: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '50%'
        },
        cellsInt: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50%'
        }
    }

    const setId = (id) => setTransactionId(id)

    // open drawer for edit
    const openEditDrawer = (id) => {
        setEditDrawer(true)
        setId(id)
    }

    // close drawer for edit
    const closeEditDrawer = () => setEditDrawer(false)

    // open drawer for view
    const openViewDrawer = (id) => {
        setViewDrawer(true)
        setId(id)
    }

    // close drawer for view
    const closeViewDrawer = () => setViewDrawer(false)

    let list_data = aETRReportsStore.internalChildStatement
    let pinnedBottomRowData = [];
    let totalValue = {
        organisation: "Total",
        face_value: 0,
        an_value: 0,
        aq_deposit: 0,
        income_value: 0,
        payment_each_month: 0,
        outstanding: 0,
        profit: 0,
        income_amt: 0,
        principal_amt: 0

    }

    aETRReportsChildStore.agGrid && aETRReportsChildStore.agGrid.api.forEachNodeAfterFilter((rowNode, index) => {
        let currentObj = rowNode.data;
        totalValue['face_value'] += currentObj['face_value'];
        totalValue['aq_deposit'] += currentObj['aq_deposit'];
        totalValue['an_value'] += currentObj['an_value'];
        totalValue['income_value'] += currentObj['income_value'];
        totalValue['payment_each_month'] += currentObj['payment_each_month'];
        totalValue['outstanding'] += currentObj['outstanding'];
        totalValue['profit'] += currentObj['profit'];
        totalValue['principal_amt'] += currentObj['principal_amt'];
        totalValue['income_amt'] += currentObj['income_amt'];
    });
    pinnedBottomRowData.push(totalValue)

    const setColumnSetting = (params) => {
        let payload = {
            request_type: "id",
            id: aETRReportsChildStore.setting_value.id,
            table_grid: params ? null : JSON.stringify(aETRReportsChildStore.agGrid.columnApi.getColumnState())
        }
        aETRReportsChildStore.columnsConfig(payload).then(() => {
            if (params === 'undo') {
                localStorage.removeItem('child_aETR_report_grid')
                aETRReportsChildStore.agGrid.api.setFilterModel(null)
                aETRReportsChildStore.agGrid.columnApi.resetColumnState()
                aETRReportsChildStore.agGrid.api.onFilterChanged(null)
                vsmNotify.success({
                    message: "Grid reset successfully."
                })
            } else {
                vsmNotify.success({
                    message: "Grid saved successfully."
                })
            }
        })
    }

    let columns = []
    let column = gridOptions.columnDefs
    let columnConfig = aETRReportsChildStore && aETRReportsChildStore.setting_value && aETRReportsChildStore.setting_value.data
    if (columnConfig && columnConfig !== 'null') {
        let columnData = columnConfig
        let cols = column.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        columnData.forEach(element => {
            cols = column.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = column
    }

    useEffect(() => {
        let payload = {
            "request_type": "type"
        }
        aETRReportsChildStore.getSetting(102, payload)
    }, [aETRReportsChildStore])

    return aETRReportsChildStore.setting_value && (
        <Drawer
            visible={props.visible}
            onClose={close}
            title={`${aETRReportsStore.internalChildStatement && aETRReportsStore.internalChildStatement.length ? aETRReportsStore.internalChildStatement[0].organisation + ' - ' + aETRReportsStore.internalChildStatement[0].batch : null}`}
            width='82%'
            className="primary"
            destroyOnClose={true}
            extra={[
                <>
                    {props.type !== 'inStatement' && <Button key="1" onClick={getChildStatement} className='mr-10'>Get Statement</Button>}
                    <Button key="2" title="Save Column Settings" type="primary" onClick={() => setColumnSetting('save')}><TableOutlined /></Button>
                    <Button key="3" title="Reset" type="primary" onClick={() => setColumnSetting('undo')} className="mr-40 ml-10"><FontAwesomeIcon icon={faUndo} /></Button>
                </>
            ]}
        >
            <>
                <div className="ag-theme-alpine grid_wrapper mt-10">
                    <AgGridReact
                        rowData={list_data}
                        modules={AllModules}
                        columnDefs={gridOptions.columnDefs}
                        defaultColDef={LocalGridConfig.defaultColDef}
                        columnTypes={LocalGridConfig.columnTypes}
                        overlayNoRowsTemplate={vsmCommon.noRecord}
                        onGridReady={aETRReportsChildStore.setupGrid}
                        onColumnResized={aETRReportsChildStore.onGridChanged}
                        onColumnMoved={aETRReportsChildStore.onGridChanged}
                        onColumnPinned={aETRReportsChildStore.onGridChanged}
                        gridOptions={LocalGridConfig.options}
                        onFilterChanged={aETRReportsChildStore.onFilterChanged}
                        onSortChanged={aETRReportsChildStore.onGridChanged}
                        rowSelection={'single'}
                        onCellDoubleClicked={false}
                        frameworkComponents={{ ActionRenderer }}
                        pinnedBottomRowData={pinnedBottomRowData}
                    />
                </div>
                {previewAETRChild ? <PreviewAETRChildStatementComponent visible={previewAETRChild} close={closeChildStatement} rowData={previewAETRChild} type={'inChildETR'} /> : null}
                {editDrawer && <EditComponent transactionId={transactionId} setId={setId} visible={editDrawer} close={closeEditDrawer} />}
                {viewDrawer && <ViewComponent transactionId={transactionId} setId={setId} visible={viewDrawer} close={closeViewDrawer} />}
            </>
        </Drawer>
    );
})

export default PriviewChildAETRComponent;