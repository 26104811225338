import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Card, Select, Divider, Button } from 'antd'
import useStore from '../../../../store'
import { vsmImport, vsmNotify } from '../../../../config/messages'


const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OldImportStore: { dropdown_list, deleteMapping } } = useStore()
	const [deleteForm, setDeleteForm] = useState()

	// handle submit api call
	const handleSubmit = () => {
		const data = {
			ledger_id: 1,
			mapping_id: form.getFieldsValue().mapping_name
		}
		setDeleteForm(true)
		deleteMapping(data).then(() => {
			vsmNotify.success({
				message: vsmImport.delete
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setDeleteForm(false))
	}

	return (
		<>
			<Card className="mt-20" title="Delete by Mapping">
				<Form form={form}
					labelCol={{ span: 6 }}
					id="deleteform"
					onFinish={handleSubmit}
				>
					<Form.Item label="Transaction Type Mapping" required>
						<Form.Item name="mapping_name" noStyle rules={vsmImport.validation.mapping}>
							<Select showSearch placeholder="Select Transaction Type Mapping"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									(dropdown_list && dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.mapping_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Form.Item>
					<Divider />
					<div className="text-center">
						<Button loading={deleteForm} form="deleteform" htmlType="submit" type="primary" danger>Delete</Button>
					</div>
				</Form>
			</Card>
		</>
	)
})

export default DeleteComponent
