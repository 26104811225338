import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmReconciliation } from '../../../../config/messages'
import useStore from '../../../../store'

const UnlinkComponent = observer((props) => {
	const [form] = Form.useForm()
	const { ReconciliationStore, ReconciliationStore: { UnlinkData, unlinkValues } } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to unlink Cash Transaction with Trade Transaction
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = unlinkValues.id
		data.transaction_id = unlinkValues.transaction_id
		data.cte_transaction_id = unlinkValues.cte_transaction_id
		let journalLogPayload;
		if (ReconciliationStore.agGridInner) {
			let tteSelected = ReconciliationStore.agGridInner.api.getSelectedRows()
			if (tteSelected && tteSelected.length > 0) {
				let tte_selected_ids = tteSelected.map(item => item && item.id)
				let cte_selected_ids = tteSelected.map(item => item && item.cte_id)
				journalLogPayload = { "cte_transaction_ids": ReconciliationStore.selectedIds, "tte_transaction_ids": tte_selected_ids, "log_cte_ids": cte_selected_ids }
			} else { journalLogPayload = null }
		}
		UnlinkData(data, journalLogPayload).then(() => {
			if (props.tab === "viewCash") { props.handleRefresh() }
			close()
			vsmNotify.success({
				message: vsmReconciliation.unlink
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false) })
	}

	// Reset form and close unlink form
	const close = () => {
		props.close()
		form.resetFields()
		setSaving(false)
	}

	return unlinkValues ? (
		<Modal
			destroyOnClose
			centered
			title={`Unlink Transaction - #${unlinkValues && unlinkValues.id}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form=' unlinkform' loading={saving} htmlType="submit" type="primary" danger> Unlink</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id=" unlinkform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to unlink this Trade Transaction ?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default UnlinkComponent
