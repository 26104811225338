import React from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../store'

const PublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore: { statusValues, relation_list } } = useStore()

	// Reset form & close publish form
	const close = () => {
		form.resetFields()
		props.close()
	}

	return statusValues ? (
		<Modal
			centered
			title={`Error in Publishing Model - #${statusValues.id} - ${statusValues.transaction_type_field_mapping.mapping_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={[<Button key="1" htmlType="button" onClick={close}>Ok</Button>]}
		>

			<Form form={form} id="publishform"
				labelCol={{ span: 6 }}
			>
				<>
					<Row>
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>You cannot Publish this model as following items used in the model are not published or active. Please Publish or activate these items first before publishing this model.</h3>
							<span>
								<b>Algorithms</b>
								<ul>
									{
										(
											relation_list && relation_list.algo.length > 0 ?
												(relation_list.algo.map((item) => (
													<li key={item.id}>{item.name}</li>
												))) : <li>{''}</li>
										)
									}
								</ul>
							</span>
							<span>
								<b>Transaction Fields</b>
								<ul>
									{
										(
											relation_list && relation_list.field.length > 0 ?
												(relation_list.field.map((item) => (
													<li key={item.id}>{item.name}</li>
												))) : <li>{''}</li>
										)
									}
								</ul>
							</span>
							<span>
								<b>Transaction Type Mappings</b>
								<ul>
									{
										(
											relation_list && relation_list.mapping.length > 0 ?
												(relation_list.mapping.map((item) => (
													<li key={item.id}>{item.name}</li>
												))) : <li>{''}</li>
										)
									}
								</ul>
							</span>
						</Col>
					</Row>
				</>
			</Form>
		</Modal>
	) : null
})

export default PublishComponent
