import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import NumberFormat from 'react-number-format'
import DaysListComponent from './DayListComponent'
import { Spin } from 'antd'

const ListComponent = observer((props) => {
	const { QueryBankVsCashStore, QueryBankVsCashStore: { setupGrid, list_data, getChildList, bankAccList } } = useStore()
	const [openSubDrawer, setOpenSubDrawer] = useState(false)
	const [title, setTitle] = useState(null)
	const [spin, setSpin] = useState(false)

	const closeSubDrawer = () => {
		setSpin(true)
		QueryBankVsCashStore.days_list_data = null
		QueryBankVsCashStore.getList(null)
		setOpenSubDrawer(false)
	}

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		},
		red_bg: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%',
			backgroundColor: 'red',
			margin: '0 -15px',
			padding: '0 15px'
		}
	}

	const gridOptions = {
		columnDefs: [{
			headerName: 'Month',
			tooltipField: 'month',
			headerTooltip: 'Month',
			field: 'month',
			filter: false,
			width: 93
		}, {
			headerName: 'Closing Bank Balance',
			headerTooltip: 'Closing Bank Balance',
			field: 'bank_closing',
			tooltipField: 'bank_closing',
			filter: false,
			cellRendererFramework: function (params) {
				return (
					<div style={styles.cells}>
						<NumberFormat value={params.data.bank_closing ? params.data.bank_closing : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				)
			},
			width: 133
		}, {
			headerName: 'Closing Credebt Balance',
			headerTooltip: 'Closing Credebt Balance',
			field: 'cash_closing',
			tooltipField: 'cash_closing',
			filter: false,
			cellRendererFramework: function (params) {
				return (
					<div style={parseInt(params.data.cash_closing) !== parseInt(params.data.bank_closing) ? styles.red_bg : styles.cells}>
						<NumberFormat value={params.data.cash_closing ? params.data.cash_closing : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				)
			},
			width: 131
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('query_bank_cash')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	const onCellClicked = (params) => {
		setTitle(`View ${bankAccList.find(bank => bank.id === props.bank_ID).name} - ${bankAccList.find(bank => bank.id === props.bank_ID).account_number} - ${params.data.month}`)
		setOpenSubDrawer(true)
		let payload = {
			report_type: "bank_vs_cash",
			bank_account_id: props.bank_ID,
			month: params.data.month,
			action: "detail_view"
		}
		getChildList(payload)
	}

	useEffect(() => {
		if (list_data) {
			setSpin(false)
		}
	}, [list_data])

	return (
		<>
			<Spin size="large" spinning={spin} tip={"Loading"}>
				<div className="ag-theme-alpine grid_wrapper no_data_height" style={{ height: props.fYearType === 'all' && list_data.length > 28 ? 'calc(100vh - 100px)' : list_data.length * 30 + 35, minHeight: '70px', maxHeight: 'calc(100vh - 100px)' }}>
					<AgGridReact
						rowData={list_data}
						modules={AllModules}
						columnDefs={columns}
						defaultColDef={LocalGridConfig.defaultColDef}
						columnTypes={LocalGridConfig.columnTypes}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						onGridReady={setupGrid}
						// onColumnResized={QueryBankVsCashStore.onGridChanged}
						// onColumnMoved={QueryBankVsCashStore.onGridChanged}
						// onColumnPinned={QueryBankVsCashStore.onGridChanged}
						gridOptions={LocalGridConfig.options}
						pagination={false}
						filter={false}
						onCellDoubleClicked={onCellClicked}
						floatingFiltersHeight={0}
						suppressRowClickSelection={true}
						pinnedBottomRowData={false}
					/>
				</div>
			</Spin>
			{openSubDrawer && <DaysListComponent visible={openSubDrawer} close={closeSubDrawer} title={title} bankID={props.bank_ID} />}
		</>
	)
})

export default ListComponent;