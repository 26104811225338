import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../utils/GlobalFunction'
import { Link } from 'react-router-dom'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	return (
		<div className="action-column">
			<Button title="Edit Status" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			{
				(props.data && props.data.organisations_count > 0) ? (
					<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot delete this Status as it is associated with Organisation."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					<Button title="Delete Status" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
				)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		tooltipField: 'id',
		headerTooltip: '# ID',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Status Name',
		headerTooltip: 'Status Name',
		tooltipField: 'status_name',
		field: 'status_name',
		width: 130
	}, {
		headerName: 'No. of Organisations',
		headerTooltip: 'No. of Organisations',
		tooltipField: 'organisations_count',
		field: 'organisations_count',
		cellRendererFramework: (data) => {
			return (data.data) ? <Link className="link_color" to="/organisations/all-organisations">{data.data.organisations_count}</Link> : null
		},
		filter: 'agNumberColumnFilter',
		width: 150
	}, {
		headerName: 'Code',
		headerTooltip: 'Code',
		tooltipField: 'status_code',
		field: 'status_code',
		width: 130
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		field: 'updated_by',
		tooltipField: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		field: 'updated_at',
		tooltipField: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
