import React from 'react'
import { useHistory } from 'react-router-dom'
import { Result, Button } from 'antd'

const Unauthorized = () => {
	let history = useHistory()
	return (
		<Result
			status="403"
			title="403"
			subTitle="Sorry, you are not allowed to use this page."
			extra={<Button type="primary" onClick={() => {
				history.goBack()
			}}>Go Back</Button>}
		/>
	)
}

export default Unauthorized
