import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const JournalGroups = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { JournalGroupStore: { per_page, agGrid, getAllRecords, setEditValues, setDeleteValues, setPageSize }, UserStore } = useStore()

	// Open form for add new Journal Type
	const openAddModal = () => setAddModal(true)

	// Close form for add new Journal Type
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing Journal Type and set values to form
	const openEditModal = (data) => {
		getAllRecords()
		setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit Journal Type
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing Journal Type
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('journal_group_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.JournalGroups.path} />
			{(!UserStore.checkPermission(30, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.JournalGroups.title}
					extra={[
						UserStore.checkPermission(30, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
				</PageHeader>
			)}
		</>
	)
})

export default JournalGroups
