import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const Organisation_Status = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { OrgStatusStore, OrgStatusStore: { per_page, setEditValues, setDeleteValues, setPageSize }, UserStore } = useStore()

	// Open form for add new Role
	const openAddModal = () => setAddModal(true)

	// Close form for close new Role
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing Role and set values to form
	const openEditModal = (data) => {
		setEditValues(data)
		setEditModal(true)
	}

	// Close form of edit Role
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing Role
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('org_status_grid')
		OrgStatusStore.agGrid.api.setFilterModel(null)
		OrgStatusStore.agGrid.api.setSortModel(null)
		OrgStatusStore.agGrid.columnApi.resetColumnState()
		OrgStatusStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Status.path} />
			<PageHeader
				className={UserStore.fullscreen_class}
				title={BreadcrumbConfig.Status.title}
				extra={[
					<Button key="1" onClick={openAddModal}>New</Button>,
					<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
					<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
					<EnterFullscreen key='4' />
				]}
			>
				<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
				<AddComponent visible={addModal} close={closeAddModal} />
				<EditComponent visible={editModal} close={closeEditModal} />
				<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
			</PageHeader>
		</>
	)
})

export default Organisation_Status
