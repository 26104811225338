import React from 'react'
import { Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'

const DeleteComponent = observer((props) => {

	return (
		<Modal
			centered
			title={`Delete Condition Block`}
			visible={true}
			onCancel={() => props.close()}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" onClick={() => { props.removeBlock(props.item_key); props.close() }} type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => { props.close() }}>Cancel</Button>
			]}
		>
			{
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to remove this Condition Block? You can not undo this action.</h3>
					</Col>
				</Row>
			}
		</Modal>
	)
})

export default DeleteComponent
