import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmTransactionFields } from '../../../../../config/messages'
import useStore from '../../../../../store'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionFieldStore: { DeleteData, deleteValues } } = useStore()
	const [saving, setSaving] = useState()
	const [deleteButton, ToggleDeleteButton] = useState(true)
	const [deleteText, setDeleteText] = useState('Transfer & Delete')

	// Made function call to delete existing record
	const handleSubmit = (data) => {
		setDeleteText('Transferring existing Journals, Please wait')
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionFields.delete
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setDeleteText('Transfer & Delete')
			ToggleDeleteButton(true)
		})
	}

	// Set the form values to delete
	useEffect(() => {
		ToggleDeleteButton(true)
		if (deleteValues) {
			form.setFieldsValue({
				field_name: deleteValues.field_name,
				count: deleteValues.association_count
			})
		}
	}, [deleteValues, form])

	return deleteValues ? (
		<Modal
			title={`Delete Field - #${deleteValues.id} - ${deleteValues.field_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				(deleteValues.association_count > 0) ? (
					<Button key="1" disabled={deleteButton} form='deleteform' loading={saving} htmlType="submit" type="primary" danger>{deleteText}</Button>
				) : (
						<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>
					),
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					ToggleDeleteButton(true)
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				<Form.Item name="transfer_id" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to remove this Transaction Field?</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
