import React from 'react'
import { Switch } from 'antd'

const SwitchComponent = (props) => {
	return (
		<div>
			<Switch
				size='small'
				defaultChecked={props.values.status}
				disabled={props.values.field_type === "DB Column"}
				onChange={(val) => props.onSwitchChange(val, props.values)}
			/>
		</div>
	)
}

export default SwitchComponent
