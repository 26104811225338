import { Button, Col, Row } from 'antd'
import React from 'react'
import FormulaComponent from './FormulaComponent'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const MinMaxBlock = (props) => {
	if (props.formula.child.length <= 0) {
		props.formula.child.push({ key: 0, component: 'Custom', value: '' }, { key: 1, component: 'Custom', value: '' })
	}

	const addBlock = () => {
		var key = 1
		if (props.formula.child) {
			props.formula.child.map((item) => {
				if (item.key >= key) {
					key = item.key + 1
				}
				return true
			})
		}
		props.formula.child.push({ key, component: 'Custom' })
		props.setFormula(props.main)
	}

	const removeBlock = (key) => {
		if (props.formula.child) {
			props.formula.child = props.formula.child.filter((x) => {
				return (x.key !== key)
			})
		}
		props.setFormula(props.main)
	}

	return props.formula.child.length > 0 ? props.formula.child.map((item, index) => {
		return (
			<Row style={{ flexFlow: 'nowrap' }} key={index} className={'builder_wrapper' + index !== (props.formula.child.length - 1) ? 'mb-10' : ''}>
				<Col flex="auto">
					<FormulaComponent main={props.main} formula={item} setFormula={props.setFormula} />
				</Col>
				<Col flex="110px" className="pl-15">
					{
						props.formula.child.length > 2 && (
							<Button onClick={() => { removeBlock(item.key) }}><CloseOutlined /></Button>
						)
					}
					{
						(index === (props.formula.child.length - 1)) && (
							<Button onClick={() => { addBlock() }}><PlusOutlined /></Button>
						)
					}
				</Col>
			</Row>
		)
	}) : null
}

export default MinMaxBlock
