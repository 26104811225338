import React from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../store'

const PublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AlgorithmsStore: { statusValues, setupGrid, agGrid, publish_list } } = useStore()

	// reset form and close publish form
	const close = () => {
		form.resetFields()
		props.close()
		setupGrid(agGrid)
	}

	return statusValues ? (
		<Modal
			centered
			title={`Error in Publishing Algorithm - #${statusValues.id} - ${statusValues.algorithm_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={[<Button key="1" htmlType="button" onClick={close}>Ok</Button>]}
			afterClose={() => { setupGrid(agGrid) }}
		>

			<Form form={form} id="publishform"
				labelCol={{ span: 6 }}
			>
				<>
					<Row>
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>You cannot Publish this Algorithm as the are items used in this Algorithm tat are not published or active yet. Please Publish or Activate them first before publishing this Algorithm.</h3>
							<span>
								<b>Associated Transaction Fields :</b>
								<ul>
									{
										(
											publish_list && publish_list.tte_field.length > 0 ?
												(publish_list.tte_field.map((item, index) => (
													<li key={index}>{item.field_name}</li>
												))) : <li>{''}</li>
										)
									}
								</ul>
							</span>
							<span>
								<b>Associated Algorithms :</b>
								<ul>
									{
										(
											publish_list && publish_list.algorithm.length > 0 ?
												(publish_list.algorithm.map((item, index) => (
													<li key={index}>{item.algorithm_name}</li>
												))) : <li>{''}</li>
										)
									}
								</ul>
							</span>
						</Col>
					</Row>
				</>
			</Form>
		</Modal>
	) : null
})

export default PublishComponent
