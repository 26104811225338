import Axios from "axios"
import { decorate, observable, action } from "mobx"
import { vsmJournalLines, vsmNotify, vsmTransactionModels } from "../../../config/messages"
import moment from 'moment';
import LocalGridConfig from "../../../config/LocalGridConfig";

export default class TransactionModelStore {
	list_data = []
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	deleteValues = null
	statusValues = null
	journalLinesValues = null
	transactions_list = null
	fieldType_list = []
	agGrid = null

	dropdown_type_list = null
	journal_list = null
	field_list = null
	algorithm_list = null
	relation_list = null
	array_list = null;
	deleted_list = []

	live_report_list_data = []
	live_report_agGrid = null

	// Setup grid and set column size to autosize
	setupLiveReportGrid = (params) => {
		this.live_report_agGrid = params
		let columnConfig = localStorage.getItem('live_report_agGrid')
		if (this.live_report_agGrid && this.live_report_agGrid.columnApi && columnConfig) {
			this.live_report_agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onLiveReportGridChanged = (params) => {
		localStorage.setItem('live_report_agGrid', JSON.stringify(params.columnApi.getColumnState()))
	}

	getLiveReportList = (advancFilter) => {
		let payload = { "model_id": 327, "transaction_ids": "all", "date": "all", ...advancFilter }
		return Axios.post(`transactionmodel/execute/verify_all_transactions/`, payload).then(({ data }) => {
			this.live_report_list_data = data
		}).catch(() => {
			vsmNotify.error({
				message: vsmTransactionModels.somethingWentWrong
			})
		})
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// set form values to edit
	setStatusValues = (data) => {
		this.statusValues = data
	}

	// set journal line table values
	setJournalLinesValues = (data) => {
		this.journalLinesValues = data
	}

	// set index on drag
	setArrayList = (data) => {
		this.array_list = data
	}

	// set transaction list to null
	setTransactionList = () => {
		this.transactions_list = null
	}

	// set field mapping list to null
	setFieldMappingList = () => {
		this.dropdown_type_list = null
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('transaction_model_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('transaction_model_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('transaction_model_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('transaction_model_grid', columnConfig)
		}
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get all mapping list
	getAllMappingList = () => {
		return Axios.get(`transactionmodel/typefieldmappinglist`).then(({ data }) => {
			this.dropdown_type_list = data
			return data
		})
	}

	// call api to get all journals list
	getAllJournalsList = () => {
		return Axios.get(`transactionmodel/journalslist/`).then(({ data }) => {
			this.journal_list = data
			return data
		})
	}

	// call api to get all field transaction list
	getAllFieldList = (id) => {
		var payload = { mapping_id: id }
		return Axios.post(`transactionmodel/fieldlist/`, payload).then(({ data }) => {
			this.field_list = data
			return data
		})
	}

	// call api to get all algorithm list
	getAllAlgorithmList = () => {
		return Axios.get(`transactionmodel/algorithmlist/`).then(({ data }) => {
			this.algorithm_list = data
			return data
		})
	}

	// call Transaction model Relation api
	getAllRelations = (formdata, setPublishModal, handlePublish) => {
		var payload = { model_id: formdata.model_id }
		Axios.post(`transactionmodel/getallrelations/`, payload).then(({ data }) => {
			this.relation_list = data.data
			if (data.data.algo.length <= 0 && data.data.field.length <= 0 && data.data.mapping.length <= 0) {
				handlePublish(formdata)
			} else {
				this.getList()
				setPublishModal(true)
			}
		})
	}

	// call Journal lines list api
	getJournalLinesList = (formdata) => {
		return Axios.post(`transactionmodel/journallineslist/`, formdata).then(({ data }) => {
			let arrayList = data.data
			arrayList.forEach((item, i) => {
				item.index = i + 1;
			});
			this.array_list = arrayList
			return data
		})
	}

	// call get transactions api
	getTransactionsList = (formdata) => {
		return Axios.post(`transactionmodel/gettransactions/`, formdata).then(({ data }) => {
			this.transactions_list = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.transactions_list = []
			return data
		})
	}

	// call get transactions api
	getFieldTypeList = (formdata) => {
		return Axios.post(`transactionmodel/verify/getFields/`, formdata).then(({ data }) => {
			let fieldList = data.data
			fieldList.forEach((item, i) => {
				item.default_value = (item.field_type === "DB Column") ? 0 :
					((item.field_type === "Date") ? (item.default_value && moment(item.default_value))
						: item.default_value)
			});
			this.fieldType_list = fieldList
			return data
		}).catch(({ response: { data } }) => {
			this.fieldType_list = []
		})
	}

	// call excecute transactions api
	executeTransaction = (formdata) => {
		return Axios.post(`transactionmodel/execute/byTransaction/`, formdata).then(({ data }) => {
			let arrayList = data.journalLines
			arrayList.forEach((item, i) => {
				item.index = i + 1;
			});
			this.array_list = arrayList
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// excute by field api
	executeByField = (formdata) => {
		return Axios.post(`transactionmodel/execute/byFieldValues/`, formdata).then(({ data }) => {
			let arrayList = data.journalLines
			arrayList.forEach((item, i) => {
				item.index = i + 1;
			});
			this.array_list = arrayList
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.post(`transactionmodel/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.association_count = item.get_transactions_field_values_count || 0
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
					item.srno = index + 1
					if (item.transaction_type_field_mapping === null) {
						item.transaction_type_field_mapping = { mapping_name: null }
					}
					item.published = item.status ? "Yes" : "No"
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}

			return data
		})
	}

	// call to get record particular ID
	getRecordById = (formdata) => {
		return Axios.get(`transactionmodel/show/${formdata.id}`).then(({ data }) => {
			data.is_found = true
			return data
		}).catch(({ response: { data } }) => {
			data.is_found = false
			return Promise.reject(data)
		})
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`transactionmodel/add`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`transactionmodel/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`transactionmodel/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call unpublish/publish api
	TogglepublishData = (formdata) => {
		return Axios.post(`transactionmodel/changestatus/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call verify api
	verifyData = (formdata) => {
		return Axios.post(`transactionmodel/verifystatus/`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// add dropdown field
	handleAdd = (obj) => {
		const values = this.array_list ? [...this.array_list] : [];
		var key = 1
		if (this.array_list) {
			this.array_list.map((item) => {
				if (item.index >= key) {
					key = item.index + 1
				}
				return true
			})
		}
		obj.index = key
		values.push(obj);
		this.array_list = values
	}

	handleEdit = (obj) => {
		var values = this.array_list
		values[this.journalLinesValues.index - 1] = obj
		this.array_list = [...values]
	}

	// remove dropdown field
	handleRemove = (i) => {
		const values = [...this.array_list];
		values.splice(i, 1);
		this.array_list = values
		vsmNotify.success({
			message: vsmJournalLines.delete
		})
	}

	// Verify ALl Transactions
	VerifyAllTransaction = (id) => {
		this.array_list = null
		var payload = {
			model_id: id,
			transaction_ids: 'all',
			date: 'all'
		}
		return Axios.post(`transactionmodel/execute/verify_all_transactions/`, payload).then(({ data }) => {
			this.array_list = data.journalLines
			vsmNotify.success({
				message: vsmTransactionModels.verifyAllSuccess
			})
		}).catch(() => {
			vsmNotify.error({
				message: vsmTransactionModels.somethingWentWrong
			})
		})
	}

}

decorate(TransactionModelStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	deleteValues: observable,
	statusValues: observable,
	journalLinesValues: observable,
	journal_list: observable,
	field_list: observable,
	algorithm_list: observable,
	agGrid: observable,
	dropdown_type_list: observable,
	array_list: observable,
	deleted_list: observable,
	relation_list: observable,
	transactions_list: observable,
	fieldType_list: observable,
	live_report_list_data: observable,
	live_report_agGrid: observable,
	getList: action,
	EditData: action,
	setDeleteValues: action,
	setStatusValues: action,
	setJournalLinesValues: action,
	setFieldMappingList: action,
	DeleteData: action,
	setupGrid: action,
	setPageSize: action,
	TogglepublishData: action,
	getAllMappingList: action,
	getAllJournalsList: action,
	getAllFieldList: action,
	getAllAlgorithmList: action,
	handleRemove: action,
	handleAdd: action,
	setArrayList: action,
	getAllRelations: action,
	getJournalLinesList: action,
	getTransactionsList: action,
	getFieldTypeList: action,
	onFilterChanged: action,
	VerifyAllTransaction: action,
	onChildnGridChanged: action,
	handleEdit: action,
	setupLiveReportGrid: action,
	onLiveReportGridChanged: action,
	getLiveReportList: action,
})
