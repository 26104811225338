import React, { useState } from 'react'
import { Form, Button, Modal, Input, Spin } from 'antd'
import { vsmRoles, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import InputComponent from '../../../../../components/InputComponent'

const AddComponent = (props) => {
	const [form] = Form.useForm()
	const { OrganisationStore, RoleStore: { AddData } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fetchRole, setFetchRole] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmRoles.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable & set value of role
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setFetchRole(true)
	}

	return (
		<Modal
			centered
			title="New Organisation Role"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout='vertical'
				onChange={handleChange}
			>
				<Form.Item name="role_name" rules={vsmRoles.validation.role_name} label="Role Name" required tooltip='In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child.'>
					<Input placeholder="Role Name" maxLength={50} />
				</Form.Item>
				<InputComponent
					required type="select" mode="multiple" label="Related Role/s" name="related_role_ids" placeholder="Choose Related Roles"
					tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
					onChange={handleChange} rules={vsmRoles.validation.related_role}
					onFocus={() => fetchRole && OrganisationStore.getOrgRoles().then(() => setFetchRole(false))}
					notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
					options={{
						values: OrganisationStore.dropdown_orgRole_list,
						value_key: 'id',
						text_key: 'role_name',
					}}
				/>
			</Form>
		</Modal>
	)
}

export default AddComponent
