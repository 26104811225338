import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row, Form, Input } from 'antd'
import InputComponent from '../../../../components/InputComponent';
import { vsmFXCurrencies } from '../../../../config/messages';

const DynamicFieldElement = observer(({ onChange, form }) => {

	return (
		<div className="w-100">
			<Form.List name='rates' labelCol={{ span: 6 }}>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							return (
								<div className="w-100" key={field.key}>
									<Row gutter={15}>
										<Col span={18}>
											<InputComponent
												required label={form.getFieldValue('rates')[index] && form.getFieldValue('rates')[index].currency_code}
												placeholder="Please enter rate"
												rules={vsmFXCurrencies.validation.manual_currency}
												name={[field.name, 'rate']}
												key={[field.key, 'rate']}
											/>
										</Col>
										<Col span={6}>
											<Input
												disabled
												defaultValue={form.getFieldValue('rates')[index] && form.getFieldValue('rates')[index].currency_settings && form.getFieldValue('rates')[index].currency_settings.current_base_value}
											/>
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default DynamicFieldElement
