import React from 'react';
import { Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { EyeOutlined, EyeFilled } from '@ant-design/icons'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import GridConfig from '../../../../config/GridConfig';
import { vsmCommon } from '../../../../config/messages';
import { CurrencyFormat, DateComparator } from '../../../../utils/GlobalFunction';

const ListComponent = observer((props) => {
    const { openViewDrawer, openReconciledViewDrawer } = props
    const { CashToCashReconciliationStore } = useStore()

    const ActionRenderer = (prop) => {
        return prop.data && (
            <div className="action-column">
                {(<Button title="View Cash Transaction" type="text" onClick={() => { openViewDrawer(prop.data) }}><EyeOutlined /></Button>)}
                {(<Button title="View Reconciled Cash Transaction" type="text" onClick={() => { openReconciledViewDrawer(prop.data) }}><EyeFilled /></Button>)}
            </div>
        )
    }

    const gridOptions = {
        columnDefs: [{
            headerName: '# ID',
            field: 'id',
            headerTooltip: '# ID',
            tooltipField: 'id',
            filter: 'agNumberColumnFilter',
            pinned: 'left',
            cellRenderer: "agGroupCellRenderer",
            width: 65
        }, {
            headerName: 'Posted On',
            headerTooltip: 'Posted On',
            field: 'posted_on',
            tooltipField: 'posted_on',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 84
        }, {
            headerName: 'Value On',
            headerTooltip: 'Value On',
            field: 'value_on',
            tooltipField: 'value_on',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 84
        }, {
            headerName: 'Bank Name',
            headerTooltip: 'Bank Name',
            field: 'bank_name',
            tooltipField: 'bank_name',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: (params) => {
                    CashToCashReconciliationStore.getBankFilter().then((data) => {
                        params.success([...data.data.map(x => x.name)]);
                    })
                }
            },
            width: 150
        }, {
            headerName: 'Reconciliation CTE ID',
            headerTooltip: 'Reconciliation CTE ID',
            field: 'reconciliation_cte_id',
            tooltipField: 'reconciliation_cte_id',
            width: 70
        }, {
            headerName: 'Account Number',
            headerTooltip: 'Account Number',
            field: 'account_number',
            tooltipField: 'account_number',
            width: 80
        }, {
            headerName: 'Reconcile Bank Account Number',
            headerTooltip: 'Reconcile Bank Account Number',
            field: 'reconcile_cross_bank_account_number.account_number',
            tooltipField: 'reconcile_cross_bank_account_number.account_number',
            width: 80
        }, {
            headerName: 'Transaction Details',
            field: 'transaction_details',
            headerTooltip: 'Transaction Details',
            tooltipField: 'transaction_details',
            width: 220
        }, {
            headerName: 'Debit (+)',
            headerTooltip: 'Debit (+)',
            field: 'debit',
            type: 'rightAligned',
            tooltipValueGetter: (params) => {
                return params.data?.debit ? params.data.debit : '0.00'
            },
            filter: 'agNumberColumnFilter',
            cellRendererFramework: function (data) {
                return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
            },
            width: 85
        }, {
            headerName: 'Credit (-)',
            headerTooltip: 'Credit (-)',
            field: 'credit',
            type: 'rightAligned',
            tooltipValueGetter: (params) => {
                return params.data?.credit ? params.data.credit : '0.00'
            },
            filter: 'agNumberColumnFilter',
            cellRendererFramework: (data) => {
                return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
            },
            width: 85
        }, {
            headerName: 'Currency',
            headerTooltip: 'Currency',
            field: 'currency_code',
            tooltipField: 'currency_code',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: (params) => {
                    CashToCashReconciliationStore.getCurrencyFilter().then((data) => {
                        params.success([...data.data.map(x => x.currency_code)])
                    })
                }
            },
            width: 51
        }, {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'reconciliation_status',
            tooltipField: 'reconciliation_status',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                // values: ['Pending(Identified)', 'Partially Reconciled', 'Fully Reconciled']
                values: ['Pending', 'Internal / Identified ', 'Internal / Reconciled', 'Payment / Identified', 'Payment / Recognised', 'Receipts  / Identified', 'Receipts / Recognised', 'Pending', 'Pending', 'Charges', 'Interests']
            },
            width: 144,
        },/*  {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'status_name',
            tooltipField: 'status_name',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
                buttons: ['apply', 'reset'],
                values: ['Pending', 'Imported / added', 'Auto Identified', 'Manually Verified', 'Problematic / Unidentified', 'Problematic / Identified', 'Internal / Identified']
            },
            width: 120
        }, */ {
            headerName: 'Actions',
            headerTooltip: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            sortable: false,
            filter: false,
            pinned: 'right',
            width: 145
        }]
    }

    let columns = []
    let columnConfig = localStorage.getItem('cashTocash_reconciliation_grid')
    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    return (
        <div className="ag-theme-alpine grid_wrapper pb-50">
            <AgGridReact
                rowData={CashToCashReconciliationStore.list_data}
                modules={AllModules}
                columnDefs={gridOptions.columnDefs}
                defaultColDef={GridConfig.defaultColDef}
                columnTypes={GridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                frameworkComponents={{
                    ActionRenderer, openViewDrawer, openReconciledViewDrawer
                }}
                onGridReady={CashToCashReconciliationStore.setupGrid}
                onSwitchChange={CashToCashReconciliationStore.onSwitchChange}
                onColumnResized={CashToCashReconciliationStore.onGridChanged}
                onColumnMoved={CashToCashReconciliationStore.onGridChanged}
                onColumnPinned={CashToCashReconciliationStore.onGridChanged}
                gridOptions={{ ...GridConfig.options, cacheBlockSize: CashToCashReconciliationStore.per_page }}
            />
            <Row gutter={24}>
                <Col span={24}>
                    <div className="text-right mt-10">
                        <p>Total Cash Transaction Count : {CashToCashReconciliationStore.total_cte_count}</p>
                        <p>Reconcile Processed Count : {CashToCashReconciliationStore.reconcile_cash_to_cash_processed_count}</p>
                    </div></Col>
            </Row>
        </div>
    );
})

export default ListComponent;