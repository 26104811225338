import React from 'react'
import { DeleteOutlined, EditOutlined, BankOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../../../store'
import { useParams } from 'react-router-dom'

export const ActionRenderer = (props) => {
	const { currentTab, openEditModal, openDeleteModal, openMapBankAccount } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	const { id } = useParams()
	var permissionId = currentTab === "people" ? 41 : 35

	return (
		<div className="action-column">
			{UserStore.checkPermission(permissionId, 'role_mapping_edit') &&
				<Button title="Edit Role Mapping" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			}
			{(!UserStore.checkPermission(permissionId, 'role_mapping_delete')) ? null : (
				(props.data.association_count > 0) ? (
					<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot delete this Role as transactions are associated. Please remove transactions manually to delete Role mapping."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					<Button title="Delete Role Mapping" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
				)
			)}
			{(parseInt(id) === props.data.entity_id) &&
				<Button title="Add Bank Accounts" type="text" onClick={() => { openMapBankAccount(props.data) }}><BankOutlined /></Button>
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'People Name',
		field: 'entity_name',
		width: 150
	}, {
		headerName: 'Role',
		field: 'role_name',
		width: 150
	}, {
		headerName: 'Title',
		field: 'title',
		width: 150
	}, {
		headerName: 'Mobile',
		field: 'mobile',
		valueGetter: (params) => (params.data && params.data.view_phone) ? params.data.view_phone.map(x => x.contact_value).join(', ') : null,
		width: 150
	}, {
		headerName: 'Email',
		field: 'email',
		valueGetter: (params) => (params.data && params.data.view_email) ? params.data.view_email.map(x => x.contact_value).join(', ') : null,
		width: 150
	}, {
		headerName: 'PPS Number',
		field: 'pps_number',
		width: 150
	}, {
		headerName: 'Address',
		field: 'address',
		width: 150,
		valueGetter: (params) => (params.data && params.data.view_addresses) ?
			params.data.view_addresses.address_1 +
			(params.data.view_addresses.address_2 ? (', ' + params.data.view_addresses.address_2) : '') +
			(params.data.view_addresses.address_3 ? (', ' + params.data.view_addresses.address_3) : '') +
			(params.data.view_addresses.city ? (', ' + params.data.view_addresses.city) : '') +
			(params.data.view_addresses.postal_code ? (', ' + params.data.view_addresses.postal_code) : '')
			: null
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
