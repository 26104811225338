import React, { useState } from 'react';
import { observer } from 'mobx-react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import { Button, PageHeader } from 'antd';
import Unauthorized from '../../Unauthorized';
import useStore from '../../../store';
import RecordPerPage from '../../../components/RecordPerPage';
import EnterFullscreen from '../../../components/EnterFullscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import ListComponent from './componenets/ListComponent';
import CashTransactionView from '../CashTransactions/components/ViewComponent'

const CashToCashReconciliation = observer(() => {
    const [viewDrawer, setViewDrawer] = useState(false)
    const [transactionId, setTransactionId] = useState(null)
    const [ReconciledView, setReconciledView] = useState(false)
    const { UserStore, CashToCashReconciliationStore } = useStore()

    // set id for edit / view drawer
    const setId = (id) => setTransactionId(id)

    const openViewDrawer = (data) => {
        setId(data.id)
        setViewDrawer(true)
    }

    const closeViewDrawer = () => setViewDrawer(false)

    const openReconciledViewDrawer = (data) => {
        setReconciledView(true)
        setId(data.reconciliation_cte_id)
        setViewDrawer(true)
    }

    const closeReconciledViewDrawer = () => {
        setReconciledView(false)
        setViewDrawer(false)
    }

    const doReconcile = () => {
        CashToCashReconciliationStore.DoReconcile()
    }

    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.CashToCashReconciliation.path} />
            {(!UserStore.checkPermission(76, 'list')) ? (<Unauthorized />) : (
                <PageHeader
                    className={UserStore.fullscreen_class}
                    title={BreadcrumbConfig.CashToCashReconciliation.title}
                    extra={[
                        <Button key='1' onClick={doReconcile} loading={CashToCashReconciliationStore.loader}>Reconcile</Button>,
                        <RecordPerPage key="2" defaultValue={CashToCashReconciliationStore.per_page + ' per page'} onChange={CashToCashReconciliationStore.setPageSize} />,

                        <Button key="3" title="Reset" onClick={CashToCashReconciliationStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,

                        <EnterFullscreen key='4' />
                    ]}
                >
                    <ListComponent openViewDrawer={openViewDrawer} openReconciledViewDrawer={openReconciledViewDrawer} />
                    <CashTransactionView transactionId={transactionId} setId={setId} visible={viewDrawer} close={!ReconciledView ? closeViewDrawer : closeReconciledViewDrawer} />
                </PageHeader>
            )}
        </>
    );
})

export default CashToCashReconciliation;