import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { observer } from "mobx-react"
import React, { useEffect } from "react"
import LocalGridConfig from '../../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../../config/messages'
import useStore from '../../../../../../store'
import { EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import ConvertComponent from './ConvertComponent'

const ListComponent = observer((props) => {

    const { IPSettingsStore } = useStore()
    const { getRecordDetails } = props

    const ActionRenderer = (props) => {
        const { getRecordDetails } = props.agGridReact.props.frameworkComponents

        return (
            <div className="action-column">
                <Tooltip placement="topRight" title={"Edit User"} >
                    <Button type="text" onClick={() => getRecordDetails(props.data)}><EditOutlined /></Button>
                </Tooltip>
            </div>
        )
    }

    const gridOptions = {
        columnDefs: [{
            headerName: '#ID',
            field: 'id',
            filter: 'agNumberColumnFilter',
            pinned: 'left',
        },
        {
            headerName: 'Allowed IPs',
            field: 'ipAddress',
            cellRendererFramework: function (data) {
                return (
                    data.data && (
                        data.data.ip_address.map(x => <div>{x}</div>)
                    )
                )
            }
        },
        {
            headerName: 'Expiry Date',
            field: 'expiry_date'
        },
        {
            headerName: 'API Key',
            field: 'api_key'
        },
        {
            headerName: 'Secret Code',
            field: 'secret',
            cellRendererFramework: function (data) {
                return <ConvertComponent values={data.data} />
            }
        },
        {
            headerName: 'Status',
            field: 'status'
        },
        {
            headerName: 'Created By',
            field: 'created_by',
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            sortable: false,
            filter: false,
            pinned: 'right',
        }
        ]
    }

    useEffect(() => {
        IPSettingsStore.getList(IPSettingsStore.userValues.id)
    }, [IPSettingsStore])

    return (
        <div className="ag-theme-alpine grid_wrapper ipsettingsgrid">
            <AgGridReact
                rowData={IPSettingsStore.list_data}
                modules={AllModules}
                columnDefs={gridOptions.columnDefs}
                defaultColDef={{ ...LocalGridConfig.defaultColDef }}
                gridOptions={{ ...LocalGridConfig.options, rowHeight: 56 }}
                columnTypes={LocalGridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                onGridReady={IPSettingsStore.setupGrid}
                onColumnResized={IPSettingsStore.onGridChanged}
                onColumnMoved={IPSettingsStore.onGridChanged}
                onColumnPinned={IPSettingsStore.onGridChanged}
                frameworkComponents={{
                    ActionRenderer, getRecordDetails
                }}
            />
        </div>
    )
})

export default ListComponent