import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import moment from 'moment'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()

	return (
		<div className="action-column">
			{UserStore.checkPermission(79, 'edit') && (
				<Button title="Edit" type="text" disabled={props.data.disable_action} onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			)}
			{(props.data.bank_field_mapping) ? (
				<Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Regular Expression as there is bank field mapping with that regex."} >
					{UserStore.checkPermission(79, 'delete') && <Button type="text" disabled><DeleteOutlined /></Button>}
				</Tooltip>
			) : (UserStore.checkPermission(79, 'delete') && (
				<Button title="Delete" disabled={props.data.disable_action} type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
			))
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		headerTooltip: '# ID',
		tooltipField: 'id',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Name',
		field: 'regex_name',
		tooltipField: 'regex_name',
		headerTooltip: 'Name',
		width: 150
	}, {
		headerName: 'Regular Expression',
		headerTooltip: 'Regular Expression',
		tooltipField: 'regex_value',
		field: 'regex_value',
		width: 220
	}, {
		headerName: 'Description',
		headerTooltip: 'Description',
		field: 'description',
		tooltipField: 'description',
		width: 220
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		field: "updated_by",
		tooltipField: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		tooltipField: 'updated_at',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
			// use inRangeInclusive: true for the range filter to include the selected
			// from and to dates. Setting it false would fetch only the inbetween dates
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: function (filterLocalDateAtMidnight, cellValue) {
				//using moment js
				var dateAsString = moment(cellValue).format('DD/MM/YYYY');
				var dateParts = dateAsString.split("/");
				var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0
				}

				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}

				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
			}
		},
		width: 185
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
