import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()

	return (
		<div className="action-column">
			{
				(props.data && props.data.id !== 1) ? (
					<>
						{UserStore.checkPermission(38, 'edit') && <Button title="Edit Org Role" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>}
						{
							(props.data && props.data.association_count > 0) ? (
								<Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Role as it is associated with Organisation"} >
									<Button type="text" disabled><DeleteOutlined /></Button>
								</Tooltip>
							) : (
								UserStore.checkPermission(38, 'delete') && <Button title="Delete Org Role" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
							)
						}
					</>
				) : null
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		headerTooltip: "# ID",
		field: 'id',
		tooltipField: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Role Name',
		headerTooltip: 'Role Name',
		tooltipField: 'role_name',
		field: 'role_name',
		width: 150
	}, {
		headerName: 'No. of Organisations',
		headerTooltip: 'No. of Organisations',
		field: 'count_in_organisations_count',
		tooltipField: "count_in_organisations_count",
		filter: 'agNumberColumnFilter',
		width: 80
	}, {
		headerName: 'Related Roles',
		headerTooltip: 'Related Roles',
		field: 'associated_role_names',
		tooltipValueGetter: (params) => (params.data && params.data.associated_role_names && params.data.associated_role_names.length > 0) ?
			(params.data.associated_role_names.map((item, i) => { return item.role_name })) : null,
		valueGetter: (params) => (params.data && params.data.associated_role_names && params.data.associated_role_names.length > 0) ?
			(params.data.associated_role_names.map((item, i) => { return item.role_name })) : null,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true
		},
		width: 150
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		tooltipField: "updated_by",
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		tooltipField: 'updated_at',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
