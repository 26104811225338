import React, { useState } from 'react'
import { Form, Button, Modal, Select, Tooltip, Radio, Row, Col, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { vsmJournalLines } from '../../../../../../../config/messages';
import useStore from '../../../../../../../store'
import { observer } from 'mobx-react';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore: { journal_list, field_list, array_list, algorithm_list, getAllJournalsList, getAllFieldList, getAllAlgorithmList, handleAdd } } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [mapWith, setMapWith] = useState("Field")
	const [fetchJournal, setFetchJournal] = useState(true)
	const [fetchField, setFetchField] = useState(true)
	const [fetchAlgo, setFetchAlgo] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const journals = journal_list.find((item) => item.id === data.journal_id)
		const field_value = field_list && field_list.find((item) => item.id === data.field_id)
		const algorithm_value = algorithm_list && algorithm_list.find((item) => item.id === data.algorithm_id)
		var obj = {
			journal_id: data.journal_id,
			field_id: data.field_id,
			algorithm_id: data.algorithm_id,
			line_type: data.line_type,
			journal_line_order: 1,
			journal_type: journals.journal_type,
			code: journals.journal_code,
			journal_label: journals.journal_label,
			index: array_list ? array_list.length : 0,
			uses_as: data.line_type,
			mapped_value: (field_value && (field_value.field_name)) || (algorithm_value && (algorithm_value.algorithm_name))
		}
		handleAdd(obj)
		props.handleChange()
		close()
	}

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.map_with === "Field" && data.hasOwnProperty("algorithm_id")) || (data.map_with === "Algorithm" && data.hasOwnProperty("field_id"))) {
				changeMapwith()
			}
		}).catch(e => {
			setDisabled(true)
			if ((e.values.map_with === "Field" && e.values.hasOwnProperty("algorithm_id")) || (e.values.map_with === "Algorithm" && e.values.hasOwnProperty("field_id"))) {
				changeMapwith()
			}
		})
	}

	const changeMapwith = async () => {
		await setMapWith(form.getFieldValue("map_with"))
		form.setFieldsValue({
			algorithm_id: null,
			field_id: null
		})
		handleChange();
	}

	// Reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
		setFetchJournal(true)
		setFetchField(true)
		setFetchAlgo(true)
	}

	return (
		<Modal
			centered
			title="New Journal Line"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addJournalform' htmlType="submit" type="primary">Add Line</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addJournalform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>

				<Form.Item label="Journal" required>
					<Form.Item name="journal_id" noStyle rules={vsmJournalLines.validation.journal_id}>
						<Select showSearch placeholder="Select Journal"
							optionFilterProp="children"
							onChange={handleChange}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onFocus={() => fetchJournal && getAllJournalsList().then(() => setFetchJournal(false))}
							notFoundContent={fetchJournal ? <Spin size="small" /> : "No Record Found."}
							allowClear
						>
							{
								(journal_list && journal_list.map((item, index) => (
									<Select.Option key={index} value={item.id}>{item.journal_code + ' - ' + item.journal_label}</Select.Option>
								)))
							}
						</Select>
					</Form.Item>
					<Tooltip title="A Journal is an account against which Journal Entries are stored, e.g. in Accounts, this would be one nominal account in the chart of accounts.">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>
				<Row>
					<Col className="mb-10">
						<label className="label_class">Map Journal with either Field or Algorithm.</label>
					</Col>
				</Row>

				<Form.Item label="Map with">
					<Form.Item name="map_with" initialValue="Field" noStyle className="radio_group_full">
						<Radio.Group buttonStyle="solid">
							<Radio.Button value="Field">Field</Radio.Button>
							<Radio.Button value="Algorithm">Algorithm</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Tooltip title="Debit and Credit are fields used in Transaction Models and Journal Entries. Debit represents a value taken out and Credit represents a value received. Debits are displayed on the left side of the Transaction Model and in Reports. Credits are displayed on the right side of the Transaction Model and in Reports. ">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>

				{
					mapWith === "Field" ?
						<Form.Item label="Field">
							<Form.Item name="field_id" noStyle rules={vsmJournalLines.validation.field_id}>
								<Select showSearch placeholder="Select Field"
									className="text_overflow"
									optionFilterProp="children"
									onChange={handleChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onFocus={() => fetchField && getAllFieldList(props.data).then(() => setFetchField(false))}
									notFoundContent={fetchField ? <Spin size="small" /> : "No Record Found."}
									allowClear
								>
									{
										(field_list && field_list.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.field_name + ' - ' + item.field_type}</Select.Option>
										)))
									}
								</Select>
							</Form.Item>
							<Tooltip title="Transaction Fields are a set of available variables related to a Transaction that can be assigned to a Journal Model Line value.">
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						</Form.Item>
						:
						<Form.Item label="Algorithm">
							<Form.Item name="algorithm_id" noStyle rules={vsmJournalLines.validation.algorithm_id}>
								<Select showSearch placeholder="Select Algorithm"
									optionFilterProp="children"
									onChange={handleChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onFocus={() => fetchAlgo && getAllAlgorithmList().then(() => setFetchAlgo(false))}
									notFoundContent={fetchAlgo ? <Spin size="small" /> : "No Record Found."}
									allowClear
								>
									{
										(algorithm_list && algorithm_list.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.algorithm_name}</Select.Option>
										)))
									}
								</Select>
							</Form.Item>
							<Tooltip title="Algorithm is a tool for building a finite sequence of defined mathematical calculations optionally embedded within logical expressions. Algorithms are used in Transaction Models to calculate values for Journal Entries.">
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						</Form.Item>
				}

				<Form.Item label="Use as">
					<Form.Item name="line_type" initialValue={"Debit"} noStyle className="radio_group_full">
						<Radio.Group buttonStyle="solid">
							<Radio.Button value="Debit">Debit</Radio.Button>
							<Radio.Button value="Credit">Credit</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Tooltip title="Debit and Credit are fields used in Transaction Models and Journal Entries. Debit represents a value taken out and Credit represents a value received. Debits are displayed on the left side of the Transaction Model and in Reports. Credits are displayed on the right side of the Transaction Model and in Reports. ">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>

			</Form>
		</Modal>
	)
})

export default AddComponent
