import React from 'react'
import { Radio } from 'antd'

const MainOption = (props) => {
	const handleChange = (e) => {
		props.formula.component = e.target.value
		switch (e.target.value) {
			case 'ELSE IF':
			case 'IF':
				props.formula.child = [
					{
						block: 'If-Condition',
						component: 'Formula',
						operand1: { key: 0, component: 'Custom' },
						operand2: { key: 0, component: 'Custom' },
						operator: { key: 0, value: '=' }
					},
					{
						block: 'If-True',
						component: "Value",
						value: { component: "Custom", key: 0 }
					},
					{
						block: 'If-False',
						component: "Value",
						value: { component: "Custom", key: 0 }
					},
				]
				break;

			case 'Value':
				props.formula.value = { key: 0, component: 'Custom' }
				break;

			default: props.formula.child = [];
		}

		props.setFormula(props.main)
	}

	return (
		<Radio.Group defaultValue={props.formula.component} buttonStyle="solid" onChange={handleChange}>
			{
				props.formula.block === 'If-False' ? (
					<Radio.Button value="ELSE IF">ELSE IF</Radio.Button>
				) : (
						<Radio.Button value="IF">IF</Radio.Button>
					)
			}
			<Radio.Button value="Minimum">Minimum</Radio.Button>
			<Radio.Button value="Maximum">Maximum</Radio.Button>
			<Radio.Button value="Value">Value</Radio.Button>
		</Radio.Group>
	)
}

export default MainOption
