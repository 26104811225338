import React from "react"
import { Col, Form, Row, Slider, Switch } from 'antd'
import useStore from "../../../../../../store"
import AllowedIPElements from "../elements/AllowedIPElements"
import { observer } from "mobx-react"
import InputComponent from "../../../../../../components/InputComponent"
import { vsmIpSettings } from "../../../../../../config/messages"
import moment from "moment"

const FormComponent = observer(({ handleSubmit, handleChange, form }) => {
    const { IPSettingsStore } = useStore()

    const onFinish = (data) => {
        handleSubmit(data)
    }

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    }

    return (
        <Form
            form={form}
            id="addIpSettingform"
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={IPSettingsStore.initialValue}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item tooltip="Allowed IPs page allows defining IP addresses from which it is allowed to sign in." label="Allowed IPs" className="has-iteration" required>
                        <AllowedIPElements onChange={handleChange} form={form} />
                    </Form.Item>

                </Col>

                <Col span={12}>
                    <InputComponent
                        required
                        type="date"
                        name="expiry_date"
                        onChange={handleChange}
                        label='Expiry Date'
                        placeholder="Choose expiry date"
                        tooltip="Set expiration date for API settings."
                        rules={vsmIpSettings.validation.expiry_date}
                        disabledDate={disabledDate}
                    />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item initialValue={1} name='data_limit' label='Data Limit' tooltip='Set data limit between 1 to 60 per minute.'>
                        <Slider max={60} onChange={handleChange} className='w-100 d-flex' />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item initialValue='false' label="Status" htmlFor="" name='status' valuePropName='unchecked' tooltip='Choose status for API settings.'>
                        <Switch onChange={handleChange} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
})

export default FormComponent