import React from 'react'
import { Switch } from 'antd'
import useStore from '../../../../store'

const SwitchComponent = (props) => {
	const { UserStore } = useStore()
	const Store = {
		User: UserStore
	}
	return (
		<div>
			{(
				(props.values.id === 1) ||
				(Store.User.superUser && Store.User.superUser.includes(props.values.id)) ||
				(Store.User.user.user_role && ![1, 2].includes(Store.User.user.user_role.user_role_id) &&
					(UserStore.user.id !== props.values.created_by)) ||
				(
					(Store.User.user.user_role.user_role_id && [1, 2].includes(Store.User.user.user_role.user_role_id) && Store.User.user.id !== 1)
					&& (props.values.user_role && [1].includes(props.values.user_role.user_role_id))
				) ||
				(props.values.deleted_at !== null)
			) ? (
				<Switch
					size='small'
					disabled
					defaultChecked={props.values.status === "Activated" ? 1 : 0}
				/>
			) : (
				<Switch
					size='small'
					defaultChecked={props.values.status === "Activated" ? 1 : 0}
					onChange={(val) => props.onSwitchChange(val, props.values)}
				/>
			)}

		</div>
	)
}

export default SwitchComponent
