import Axios from "axios";
import { action, decorate, observable } from "mobx";


export default class IPSettingsStore {
    list_data = null
    agGrid = null
    editValues = null
    userValues = null
    initialValue = {
        ip_address: [null],
        access_key: []
    }

    setUserValues = (data) => {
        this.userValues = data
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = localStorage.getItem('ipSettings_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('ipSettings_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay();
        }
    }

    getList = (id) => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel()
            var sort = this.agGrid.columnApi.getColumnState()
        }
        return Axios.get(`user/api/list/${id}`).then(({ data }) => {
            let access_key = []
            if (data.data.length) {
                data.data.forEach((item) => {
                    access_key.push({ api_key: item.api_key, secret: item.secret })
                    item.status = item.status === 1 ? 'Activate' : 'Deactivate'
                    item.created_by = item.users && (item.users.first_name + ' ' + item.users.last_name)
                })
            }
            this.initialValue = { ...this.initialValue, access_key }
            this.list_data = data.data
            let columnConfig = localStorage.getItem('ipSettings_grid')
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
            } else {
                if (this.agGrid && this.agGrid.columnApi) {
                    this.agGrid.api.setFilterModel(filter)
                    this.agGrid.columnApi.applyColumnState({ state: sort })
                }
            }
            return data
        })
    }

    ReadData = (id) => {
        return Axios.get(`user/api/read/${this.userValues.id}/${id}`).then(({ data }) => {
            this.editValues = data
        }).catch(({ response: { data } }) => {
            this.editValues = null
            return Promise.reject(data)
        })
    }

    AddData = (formData) => {
        return Axios.post(`user/api/create`, formData).then(() => {
            this.getList(this.userValues.id)
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    EditData = (id, formData) => {
        return Axios.post(`user/api/update/${id}`, formData).then(() => {
            this.getList(this.userValues.id)
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }
}

decorate(IPSettingsStore, {
    initialValue: observable,
    list_data: observable,
    agGrid: observable,
    editValues: observable,
    userValues: observable,
    getList: action,
    setupGrid: action,
    onGridChanged: action,
    onFilterChanged: action,
    setUserValues: action,
    AddData: action,
    ReadData: action,
})