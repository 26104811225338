import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Button, Form } from 'antd'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmNotify, vsmPeople } from '../../../../config/messages'
import debounce from 'lodash/debounce'
import moment from 'moment'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleStore } = useStore()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [addAddress, setAddAddress] = useState(false)
	const [fileList, updateFileList] = useState([]);

	const handleSubmit = (data) => {
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null
		}

		if (addAddress) {
			if (data.address && data.address.length > 0) {
				data.address = data.address.filter(x => x && x)
				data.address = data.address.length > 0 ? JSON.stringify(data.address) : null
			}
		} else {
			data.address = null
		}

		if (data.email && data.email.length > 0) {
			data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
			data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
		}
		setSaving(true)
		var formData = PeopleStore.JsonToFormData(data)

		formData.append("add_address", addAddress === true ? 1 : 0)

		if (data.photo_url && data.photo_url.fileList[0] && data.photo_url.fileList.length > 0) {
			formData.append("photo_url", data.photo_url.fileList[0].originFileObj)
		} else {
			formData.append("photo_url", '')
		}

		formData.append("date_of_birth", data.date_of_birth ? moment(data.date_of_birth).format("Y-MM-DD") : '')
		PeopleStore.AddData(formData).then((data) => {
			if (props.setPeopleDropDown) {
				props.setPeopleDropDown(data.id)
			}
			close()
			vsmNotify.success({
				message: vsmPeople.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(() => {
		form.validateFields().then((data) => {
			let checkbox = form.getFieldValue("add_address")
			let address = form.getFieldValue("address")
			address = address && address.filter(x => x && x)
			if (checkbox && (!address || (address && address.length === 0))) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}, 200)

	const close = () => {
		setAddAddress(false)
		updateFileList([])
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title='Add New People'
			destroyOnClose={true}
			footer={[
				<div className="text-center">
					<Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
					<Button onClick={close} > Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				onChange={handleChange}
				handleSubmit={handleSubmit}
				addAddress={addAddress}
				setAddAddress={setAddAddress}
				updateFileList={updateFileList}
				fileList={fileList}
			/>
		</Drawer>
	)
})

export default AddComponent
