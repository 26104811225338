import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row, Input } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../store'
import { vsmTransactionModels, vsmNotify } from '../../../../../config/messages'

const UnpublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore: { TogglepublishData, statusValues } } = useStore()
	const [saving, setSaving] = useState()
	const [unpublishButton, ToggleUnpublishButton] = useState(true)

	// Made function call to unpublish existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = statusValues.id
		data.status = false
		delete data["unpublish"]
		TogglepublishData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionModels.unpublish
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			ToggleUnpublishButton(true)
		})
	}

	// Handle disable / enable button on field change
	const handleFieldChange = () => {
		if (form.getFieldsValue().unpublish.toLowerCase() === "unpublish") {
			ToggleUnpublishButton(false)
		} else {
			ToggleUnpublishButton(true)
		}
	}

	// Reset form & close unpublish form
	const close = () => {
		form.resetFields()
		ToggleUnpublishButton(true)
		props.close()
	}

	return statusValues ? (
		<Modal
			centered
			title={`Unpublish Model - #${statusValues.id} - ${statusValues.transaction_type_field_mapping.mapping_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='unpublishform' loading={saving} htmlType="submit" type="primary" danger disabled={unpublishButton}>Unpublish</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>

			<Form form={form} id="unpublishform" onFinish={handleSubmit}
				labelCol={{ span: 6 }}
			>
				<>
					<Row>
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Please note that Unpublishing Transaction Model will not affect any existing Transactions. The model will be disabled and will not be available to be used for new Transactions.</h3>
							<Form.Item style={{ marginBottom: 0 }} name="unpublish">
								<Input placeholder='Type "UNPUBLISH" to Confirm' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				</>
			</Form>
		</Modal>
	) : null
})

export default UnpublishComponent
