import React from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { Col, Row } from 'antd'
import { vsmTransactionReport } from '../../../../config/messages'
import useStore from '../../../../store'

const DateSelectionElement = observer(({ formDataList, onChange, disabledDate, SetDateRange, tab, pickerWeek }) => {
	const { aETRReportsStore } = useStore();

	return (
		<>
			{formDataList.includes('single_date') && (
				<Col>
					<InputComponent
						label={'Date'}
						type='date'
						required
						picker={"week"} format='YYYY-MM-DD'
						placeholder={'Select Date'}
						name='single_date'
						onChange={onChange}
						rules={vsmTransactionReport.validation.date}
						tooltip={'Select specific date to fetch reports for.'}
					/>
				</Col>
			)}
			{(formDataList.includes('date_range')) && (
				<Col span={tab === "inQuery" ? 7 : ''}>
					<InputComponent
						label="Date Range"
						placeholder={['Start Date', 'End Date']}
						picker={"week"} format='YYYY-MM-DD'
						type="date_range" name="date_range" onChange={onChange}
						rules={vsmTransactionReport.validation.date_range}
						tooltip="Used to fetch the reports between specific date range."
						disabledDate={disabledDate}
						onCalendarChange={val => SetDateRange(val)}
					/>
				</Col>
			)}
			{(formDataList.includes('date_x_value') || formDataList.includes('date_x_value_type')) && (
				<Row>
					{(formDataList.includes('date_x_value')) && (
						<Col span={4} className='mr-10'>
							<InputComponent
								label="Last"
								required type="number" name="date_x_value" min={1} max={12} className="w-100" onChange={onChange}
								rules={vsmTransactionReport.validation.date_x_value}
								tooltip="A date selection provide various options to choose date and accordingly generate report."
							/>
						</Col>
					)}
					{(formDataList.includes('date_x_value_type')) && (
						<Col>
							<InputComponent
								label="&nbsp;"
								onChange={onChange}
								type="radio_button" name='date_x_value_type'
								options={{
									className: "d-flex w-100",
									values: [{ value: 'day', text: 'Day/s' }, { value: 'week', text: 'Week/s' }, { value: 'month', text: 'Month/s' }, { value: 'quarter', text: 'Quarter/s' }, { value: 'year', text: 'Year/s' }]
								}}
							/>
						</Col>
					)}
				</Row>
			)}
			{(formDataList.includes('financial_year')) && (
				<div className="w250">
					<InputComponent
						label="Financial Year"
						required type="select" name="financial_year" placeholder="Financial Year" onChange={onChange}
						options={{
							values: aETRReportsStore.financial_year_list,
						}}
						tooltip="User can select the financial year to fetch reports in that particular range."
						rules={vsmTransactionReport.validation.financial_year}
					/>
				</div>
			)}
		</>
	)
})

export default DateSelectionElement
