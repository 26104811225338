import React, { useState, useEffect } from 'react'
import { Form, Button, Drawer, Spin } from 'antd'
import { observer } from 'mobx-react'
import { vsmPeopleRoleMapping, vsmNotify, vsmClassifications } from '../../../../config/messages'
import useStore from '../../../../store'
import FormComponent from './FormComponent'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [rPeopleData, setRPeopleData] = useState(null)
	const [currentTab, setCurrentTab] = useState("People")
	const [fetchRoles, setFetchRoles] = useState(true)
	const [fetchRelatedRoles, setFetchRelatedRoles] = useState(true)
	const [fetchTags, setFetchTags] = useState(true)
	const [loading, setLoading] = useState(true)
	const [rejectedkeys, setRejectedKeys] = useState()
	const { OrganisationStore, RoleMappingStore, PeopleStore, PeopleRoleMappingStore } = useStore()

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = PeopleRoleMappingStore.editValues.id
		data.entity_type = 2
		data.entity_id = data.people_id
		if (form.getFieldValue("related_entity_type") === "Ledger") {
			data.related_entity_id = null
			data.related_role_id = null
		}
		PeopleRoleMappingStore.EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmPeopleRoleMapping.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			var payload = { people_id: props.parent_id && parseInt(props.parent_id), mapping_type: 2 }
			PeopleRoleMappingStore.getRecordById(props.mappingId, payload).then((data) => {
				PeopleStore.getPeopleCardValue(data.data.entity_id, 'people')
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmClassifications.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props.visible, props.parent_id, props.mappingId, PeopleRoleMappingStore, PeopleStore])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = async () => {
		if (currentTab !== form.getFieldValue("related_entity_type")) {
			await form.resetFields(['related_people_id', 'related_role_id', 'related_entity_id'])
			OrganisationStore.rOrganisationValues = null
			setRPeopleData(null)
			setFetchRelatedRoles(true)
			setCurrentTab(form.getFieldValue("related_entity_type"))
		}
		setRejectedKeys(form.getFieldValue('role_id'))
		validateData()
	}

	// call to validate fields
	const validateData = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		RoleMappingStore.setEditValues(null)
		form.resetFields()
		setDisabled(true)
		setCurrentTab('People')
		PeopleRoleMappingStore.setEditValues(null)
		OrganisationStore.rOrganisationValues = null
		OrganisationStore.dropdown_ROrg_list = null
		PeopleStore.peopleCardValue = null
		PeopleStore.rPeopleCardValue = null
		PeopleStore.rPeopleValues = null
		setFetchRoles(true)
		setFetchRelatedRoles(true)
		setFetchTags(true)
		setRejectedKeys()
		RoleMappingStore.dropdown_tags_list = null
		props.close()
	}

	return (
		<Drawer
			destroyOnClose={true}
			title={`Edit Mapping of Organisation/People with ${PeopleStore.peopleValues && '- ' + PeopleStore.peopleValues.first_name} ${PeopleStore.peopleValues && PeopleStore.peopleValues.last_name ? '- ' + PeopleStore.peopleValues.last_name : ''}`}
			width={'75%'}
			visible={props.visible}
			onClose={close}
			footer={[
				<div className="text-center">
					<Button key="1" disabled={disabled} form='mapaddeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<FormComponent
					form={form}
					handleSubmit={handleSubmit}
					onChange={handleChange}
					currentTab={currentTab}
					fetchRoles={fetchRoles}
					fetchRelatedRoles={fetchRelatedRoles}
					fetchTags={fetchTags}
					setFetchRoles={setFetchRoles}
					setFetchRelatedRoles={setFetchRelatedRoles}
					setFetchTags={setFetchTags}
					rPeopleData={rPeopleData}
					setRPeopleData={setRPeopleData}
					visible={props.visible}
					id={props.parent_id}
					setCurrentTab={setCurrentTab}
					setRejectedKeys={setRejectedKeys}
					rejectedkeys={rejectedkeys}
					type="edit"
				/>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
