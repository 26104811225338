import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import { Link } from 'react-router-dom'
import DeleteComponent from './component/DeleteComponent'
import ListComponent from './component/ListComponent'
import CancelComponent from './component/CancelComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import ViewImportLogComponent from './component/ViewImportLogs'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const Import = observer((props) => {
	const { UserStore, ImportStore: { per_page, agGrid, setPageSize, getList, setDeleteValues, getCheck } } = useStore()
	const [importId, setImportId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [cancelImport, setCancelImport] = useState(false)
	const [deleteImport, setDeleteImport] = useState(false)
	const [autoRefresh, setAutoRefresh] = useState()

	const setId = (id) => setImportId(id)

	// open drawer for view
	const openViewDrawer = (id) => {
		setViewDrawer(true)
		setId(id)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// open cancle import modal
	const openCancleImport = (data) => {
		setDeleteValues(data)
		getCheck(data.id).then((data) => {
			if (data.status) {
				setCancelImport(true)
			} else {
				setDeleteImport(true)
			}
		})
	}

	// close cancle import modal
	const closeCancelImport = () => setCancelImport(false)

	// open delete import modal
	const openDeleteImport = (data) => {
		setDeleteValues(data)
		setDeleteImport(true)
	}

	// close delete import modal
	const closeDeleteImport = () => setDeleteImport(false)

	// auto refresh call
	const handleAutoRefresh = () => {
		if (!autoRefresh) {
			getList();
			var temp = setInterval(
				function () { getList(); }
				, 3000);
			setAutoRefresh(temp);
		}
	}

	// stop autoRefresh
	const handleStopAutoRefresh = () => {
		if (autoRefresh) {
			clearInterval(autoRefresh)
			setAutoRefresh(null)
		}
	}

	// clear interval on refresh
	useEffect(() => {
		return () => {
			if (autoRefresh) {
				clearInterval(autoRefresh)
				setAutoRefresh(null)
			}
		};
	}, [autoRefresh])

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('transaction_import_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Import.path} />
			{(!UserStore.checkPermission(32, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Import.title}
					extra={[
						autoRefresh ?
							<Button key="1" onClick={handleStopAutoRefresh}>Stop Auto Refresh</Button> :
							<Button key="1" onClick={handleAutoRefresh}>Auto Refresh</Button>,
						UserStore.checkPermission(21, 'add') && <Button key="2"><Link to="/trade-transactions/administration/import-log/new">Import New CSV</Link></Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent openViewDrawer={openViewDrawer} openCancleImport={openCancleImport} openDeleteImport={openDeleteImport} />
					<ViewImportLogComponent importId={importId} setId={setId} visible={viewDrawer} close={closeViewDrawer} />
					<DeleteComponent visible={deleteImport} close={closeDeleteImport} />
					<CancelComponent visible={cancelImport} close={closeCancelImport} />
				</PageHeader>)}
		</>
	)
})

export default Import
