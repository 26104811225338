import { Button, Form, Modal, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { vsmFXCurrencies, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store';
import InputComponent from '../../../../components/InputComponent';
import DynamicFieldElement from '../elements/DynamicFieldElement';
import moment from 'moment'

const BulkEditRates = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { FXCurrenciesStore } = useStore()
	const [currentTab, setCurrentTab] = useState("date")

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		if (currentTab === "date-range") {
			data.to_date = moment(data.date_range[0]).format("YYYY-MM-DD")
			data.from_date = moment(data.date_range[1]).format("YYYY-MM-DD")
			delete data["date_range"]
		} else { data.to_date = moment(data.to_date).format("YYYY-MM-DD") }
		delete data["filters"]
		data.rates = data.rates.map(item => ({ id: item.id, rate: item.rate }));
		setSaving(true)
		FXCurrenciesStore.BulkEditRates(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmFXCurrencies.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to bulk edit
	useEffect(() => {
		if (props.visible) {
			form.setFieldsValue({
				rates: FXCurrenciesStore.selectedIds,
			})
		}
	}, [props.visible, FXCurrenciesStore, form])

	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close bulkedit form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setCurrentTab("date")
		props.close()
	}

	return (
		<Modal
			centered
			title="Bulk Edit Manual Rates"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='bulkeditform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="bulkeditform"
				onFinish={handleSubmit}
				onChange={handleChange}
				labelCol={{ span: 5 }}
			>
				<h5>Note: Please enter rate against your base currency, If there is entry with same date it will overwrite that rate.</h5>
				<InputComponent
					label="Choose Date" type="radio_button" name="filters" initialValue="date"
					onChange={async (event) => {
						await setCurrentTab(event.target.value)
						form.resetFields(['date', 'date_range'])
						handleChange()
					}}
					options={{
						values: [
							{ value: 'date', text: 'Date' },
							{ value: 'date-range', text: 'Date Range' },
						]
					}}
				/>
				{currentTab === 'date' ?
					<InputComponent
						required label="Date" type="date" name="to_date"
						onChange={handleChange}
						rules={vsmFXCurrencies.validation.date}
					/>
					:
					<InputComponent
						required label="Date Range" type="date_range" name="date_range"
						onChange={handleChange}
						rules={vsmFXCurrencies.validation.date}
					/>}
				<div className="w-100 ml-auto mr-auto" key="1">
					<Row gutter={15} className="mb-10">
						<Col offset={4} span={14}>
							<label><b>Add New Rates:</b></label>
						</Col>
						<Col>
							<label><b>Current Rates:</b></label>
						</Col>
					</Row>
					<DynamicFieldElement form={form} onChange={handleChange} />
				</div>
			</Form>
		</Modal>
	)
})

export default BulkEditRates
