import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../../config/LocalGridConfig"

export default class TransactionFieldMappingStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	deleteValues = null
	unpublishValues = null
	agGrid = null

	dropdown_list = null
	transaction_field_list = null

	// Get all transaction Fields. This Function is temporary only
	getAllTransactionFields = () => {
		Axios.get(`gettransactionfieldlist`).then(({ data }) => {
			this.transaction_field_list = data.data
		})
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		if (data.association_count > 0) {
			this.getAllRecords()
		}
		this.deleteValues = data
	}

	// set form values to edit
	setUnpublishValues = (data) => {
		this.unpublishValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('transaction_field_mapping_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('transaction_field_mapping_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get all types list
	getAllRecords = () => {
		return Axios.get(`transactionmaping/gettransactionmappinglist`).then(({ data }) => {
			this.dropdown_list = data.data
			return data
		})
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.post(`transactionmaping/list`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.selected_tte_fields = item.selected_tte_fields.map(Number)
					item.association_count = item.field_count
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
					item.srno = index + 1
					item.published = item.status ? "Yes" : "No"
				});
			}
			this.list_data = data.data
			this.total = data.total
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`transactionmaping/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`transactionmaping/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`transactionmaping/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call unpublish/publish api
	TogglepublishData = (formdata) => {
		return Axios.post(`transactionmaping/status/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(TransactionFieldMappingStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	dropdown_list: observable,
	agGrid: observable,
	transaction_field_list: observable,
	unpublishValues: observable,
	getAllRecords: action,
	getList: action,
	EditData: action,
	setEditValues: action,
	setDeleteValues: action,
	setUnpublishValues: action,
	DeleteData: action,
	setupGrid: action,
	onFilterChanged: action,
	setPageSize: action,
	TogglepublishData: action,
	getAllTransactionFields: action,
	onGridChanged: action,
})


