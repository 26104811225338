import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button, Spin } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import UnpublishComponent from './component/UnpublishComponent'
import { vsmNotify, vsmTransactionFields } from '../../../../config/messages'
import RecordPerPage from '../../../../components/RecordPerPage'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const TransactionFields = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [unpublishModal, setUnpublishModal] = useState(false)
	const { TransactionFieldStore: { per_page, getAllRelations, setEditValues, setDeleteValues, setPageSize, exportData, exportLoading, TogglepublishData, setUnpublishValues, getAllFieldType, getAllFieldAlgorithm, agGrid, list_data, listLoading }, UserStore } = useStore()

	// Open form for add new transaction field
	const openAddModal = () => {
		getAllFieldType()
		getAllFieldAlgorithm()
		setAddModal(true)
	}
	// Close form to add new transaction field
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing transaction field and set values to form
	const openEditModal = (data) => {
		getAllFieldType()
		getAllFieldAlgorithm()
		setEditValues(data)
		setEditModal(true)
	}

	// Close form to edit transaction field
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing transaction field
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		if (!checked) {
			let formdata = { field_id: data.id }
			getAllRelations(formdata)
			agGrid.api.refreshCells({ force: true });
			setUnpublishValues(data)
			setUnpublishModal(true)
		} else {
			handlePublish(data)
			setUnpublishModal(false)
		}
	}

	// Handle Publish and call function to publish record
	const handlePublish = (data) => {
		let formdata = { id: data.id, status: true }
		TogglepublishData(formdata).then(() => {
			vsmNotify.success({
				message: vsmTransactionFields.publish
			})
		}).catch(() => {
			agGrid.api.refreshCells({ force: true });
		})
	}

	// Close unpublish modal
	const closeUnpublishModal = () => { setUnpublishModal(false) }

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('transaction_field_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.TransactionFields.path} />
			{(!UserStore.checkPermission(31, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={"has_export_spinner " + UserStore.fullscreen_class}
					title={BreadcrumbConfig.TransactionFields.title}
					extra={[
						UserStore.checkPermission(31, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						UserStore.checkPermission(31, 'export') && <Button key="2" onClick={exportData} disabled={listLoading || (list_data && list_data.length === 0)}>Export</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<Spin spinning={exportLoading}>
						<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} onSwitchChange={onSwitchChange}
						/>
					</Spin>
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
				</PageHeader>
			)}
		</>
	)
})

export default TransactionFields
