import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button } from 'antd'
import { useParams } from 'react-router-dom'
import useStore from '../../../store'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import AddBankComponent from './component/AddBankComponent'
import { default as OrgRolemappingEditComponent } from '../../OrganisationManagement/RoleMapping/component/EditComponent'
import { default as AddBankAccountComponent } from '../BankAccounts/component/AddComponent'
import ContentLoader from '../../../components/ContentLoader'
import Unauthorized from '../../Unauthorized'
import PageNotFound from '../../PageNotFound'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const RoleMapping = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [editOrgModal, setEditOrgModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [mapBankModal, setMapBankModal] = useState(false)
	const [addBankAccountModal, setAddBankAccountModal] = useState(false)
	const [loading, setLoading] = useState('pending')
	const [mappingId, setMappingId] = useState(null)
	const { PeopleBankAccountStore, PeopleStore, PeopleRoleMappingStore, OrganisationStore, UserStore } = useStore()
	const { id } = useParams()

	// set id on edit drawer
	const setId = (id) => setMappingId(id)

	// Open form for add new Organisation
	const openAddModal = () => {
		setAddModal(true)
	}

	// Close form for close new Organisation
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing Organisation and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		if (data.entity_type === "People") {
			setEditModal(true)
		} else {
			var payload = { organisation_id: data.entity_id }
			OrganisationStore.getRecordDetail(payload).then(data => {
				setEditOrgModal(200)
			})
		}
	}

	// Open to add Bank Accounts
	const openMapBankAccount = (data) => {
		PeopleBankAccountStore.getAllBanksList(PeopleStore.peopleValues.id)
		PeopleRoleMappingStore.setEditValues(data)
		setMapBankModal(true)
	}

	// Open add Bank Accounts
	const openAddBankAccount = (data) => {
		setAddBankAccountModal(true)
	}

	// Close Bank Account Modal
	const closeBankModal = () => setMapBankModal(false)

	// Close Add Bank Account Modal
	const closeAddBankModal = () => setAddBankAccountModal(false)

	// Close form of edit people
	const closeEditModal = () => {
		PeopleRoleMappingStore.setEditValues(null)
		setEditModal(false)
	}

	// Close form of edit Organisation
	const closeEditOrgModal = () => setEditOrgModal(false)

	// Open confirmation alert before deleting existing Organisation
	const openDeleteModal = (data) => {
		PeopleRoleMappingStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('ppl_role_mapping_grid')
		PeopleRoleMappingStore.agGrid.api.setFilterModel(null)
		PeopleRoleMappingStore.agGrid.api.setSortModel(null)
		PeopleRoleMappingStore.agGrid.columnApi.resetColumnState()
		PeopleRoleMappingStore.agGrid.api.onFilterChanged(null)
	}

	useEffect(() => {
		PeopleStore.drowpdown_people_list = null
		PeopleStore.peopleValues = null
		var payload = { people_id: id }
		PeopleStore.getRecordDetail(payload).then(data => {
			setLoading(200)
		}).catch(data => {
			if (data.status === 404) {
				setLoading(404)
			}
			if (data.status === 403) {
				setLoading(403)
			}
		})
		PeopleRoleMappingStore.getList(payload)
	}, [PeopleRoleMappingStore, PeopleStore, id])

	if (loading === 'pending') {
		return <ContentLoader />
	}
	if (loading === 200) {
		return (
			<>
				<BreadcrumbComponent items={[...BreadcrumbConfig.People_RoleMapping.path, { name: `${PeopleStore.peopleValues && (PeopleStore.peopleValues.first_name ? PeopleStore.peopleValues.first_name : '') + ' ' + (PeopleStore.peopleValues.last_name ? PeopleStore.peopleValues.last_name : '')}` }, { name: 'Role Mapping' }]} />
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.People_RoleMapping.title}
					extra={[
						UserStore.checkPermission(41, 'bank_accounts_add') && <Button key="1" onClick={openAddBankAccount}>Add Bank Account</Button>,
						UserStore.checkPermission(41, 'role_mapping_add') && <Button key="2" onClick={openAddModal}>Map New Role</Button>,
						<RecordPerPage key="3" defaultValue={PeopleRoleMappingStore.per_page + ' per page'} onChange={PeopleRoleMappingStore.setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} openMapBankAccount={openMapBankAccount} />
					<AddComponent visible={addModal} close={closeAddModal} parent_id={id} />
					<OrgRolemappingEditComponent visible={editOrgModal} close={closeEditOrgModal} parent_id={id} mappingId={mappingId} setId={setId} />
					<EditComponent visible={editModal} close={closeEditModal} parent_id={id} mappingId={mappingId} setId={setId} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					{mapBankModal && <AddBankComponent visible={mapBankModal} close={closeBankModal} />}
					<AddBankAccountComponent visible={addBankAccountModal} close={closeAddBankModal} parent_id={id} />
				</PageHeader>
			</>
		)
	} else {
		if (loading === 404) {
			return (
				<PageNotFound />
			)
		}
		if (loading === 403) {
			return (
				<Unauthorized />
			)
		}

	}

})

export default RoleMapping
