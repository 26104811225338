import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Drawer, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import moment from 'moment';
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = useForm()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [relatedTo, setRelatedTo] = useState(null)
	const [mapping, setMapping] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [orgId, setOrgId] = useState(null)
	const [relatedOrgId, setRelatedOrgId] = useState(null)
	const [roleId, setRoleId] = useState(null)
	const [relatedRoleId, setRelatedRoleId] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const { OrgBankAccountStore, OrganisationStore, AllTransactionStore, PeopleStore, AllTransactionStore: { AddData, getDependentList, getStatusList, getCsvModals } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.posted_on = moment(data.posted_on).format("YYYY-MM-DD")
		data.traded_on = moment(data.traded_on).format("YYYY-MM-DD")
		data.model = data.model ? data.model.filter(x => (x.model === true)) : null
		data.model = data.model && data.model.map((item) => ({ model: item.id, field: item.field }));
		data.batch_id = data.csv_columns.find(_x => _x.field_name === "Batch ID").default_value
		setSaving(true)
		if (data.type_field_mapping_id === 1) {
			let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
			if (data.transaction_items) {
				data.transaction_items.forEach((item, index) => {
					if (!item.id) {
						csvColumn && csvColumn.forEach((CSVdata) => {
							if (inCSV_Columns_payload.includes(CSVdata.field_name)) {
								switch (CSVdata.field_name) {
									case 'Asset Type':
										item.asset_type = CSVdata.default_value === 1 ? 'fixed' : 'current'
										break;
									case 'Batch ID':
										item.batch = CSVdata.default_value
										break;
									case 'EoT Value':
										item.eot = CSVdata.default_value
										break;
									case 'Repair and Maintenance':
										item.rm_price = CSVdata.default_value
										break;
									case 'Sell Rate':
										item.sell_rate = CSVdata.default_value
										break;
									case 'Tax Type Inc or Exc':
										item.tax_type_inc_exc = CSVdata.default_value
										break;
									default:
										break;
								}
							}
						})
						item.tax_settings_id = data.tax_settings_id
					}
				})
			} else {
				data.transaction_items = null
			}

			if (data.address_1 && data.country_id) {
				data.common_address = {
					address_1: data.address_1,
					address_2: data.address_2,
					address_3: data.address_3,
					city: data.city,
					state_county: data.state_county,
					postal_code: data.postal_code,
					country_id: data.country_id
				}
				delete data.address_1
				delete data.address_2
				delete data.address_3
				delete data.city
				delete data.state_county
				delete data.postal_code
				delete data.country_id
				data.add_address = 1
			} else {
				data.common_address = null
				data.add_address = 0
			}
		}
		if (data.csv_columns.find(_x => _x.field_name === 'EDSO').default_value) {
			data.edso = data.csv_columns.find(_x => _x.field_name === 'EDSO')?.default_value
		} else {
			data.edso = null
		}
		if (data.csv_columns.find(_x => _x.field_name === 'Batch ID').default_value) {
			data.batch_id = data.csv_columns.find(_x => _x.field_name === 'Batch ID')?.default_value
		} else {
			data.batch_id = null
		}
		AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			getStatusList()
			store.bank.getCurrencyList()
			setLoading(false)
		}
	}, [getStatusList, store.bank, props.visible])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		var role_id = form.getFieldValue("role_id")
		if (role_id !== roleId) {
			setRelatedOrgData(null)
			setOrgData(null)
			setOrgId(null)
			setRelatedOrgId(null)
			setRelatedRoleId(null)
			await form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
		}

		var entity_id = form.getFieldValue("entity_id")
		if ((orgId !== entity_id)) { await form.resetFields(['related_role_id', 'related_entity_id']) }

		var related_entity_type = form.getFieldValue("related_entity_type")
		if (relatedTo !== related_entity_type) {
			await form.resetFields(['related_role_id', 'related_entity_id'])
			await setRelatedTo(related_entity_type)
			setRelatedOrgData(null)
		}

		var related_role_id = form.getFieldValue("related_role_id")
		if ((related_role_id !== relatedRoleId)) { await form.resetFields(['related_entity_id']) }

		var related_entity_id = form.getFieldValue("related_entity_id")

		var related_entity_type_payload = (related_entity_type === "People") ? 2 : (related_entity_type === "Ledger") ? 3 : 1

		if (role_id && entity_id && related_role_id && related_entity_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || related_role_id !== relatedRoleId || (relatedOrgId !== related_entity_id) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_role_id: related_role_id, related_entity_id: related_entity_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id && related_role_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || (related_role_id !== relatedRoleId) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_role_id: related_role_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id &&
			(role_id !== roleId || (orgId !== entity_id))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id })
		} else if (role_id && role_id !== roleId) {
			getDependentList({ role_id: role_id })
		}

		if (entity_id && (orgId !== entity_id)) {
			OrganisationStore.getOrgCardValue(entity_id, "org").then((data) => {
				setOrgData(data)
			})
		}

		if (related_entity_id && (relatedOrgId !== related_entity_id)) {
			if (related_entity_type === "People") {
				PeopleStore.getPeopleCardValue(related_entity_id, 'people').then((data) => {
					setRelatedOrgData(data)
				})
			} else {
				OrganisationStore.getOrgCardValue(related_entity_id, "relatedOrg").then((data) => {
					setRelatedOrgData(data)
				})
			}
		}

		var mapping_id = form.getFieldValue("type_field_mapping_id")
		if (mapping_id && (mapping_id !== mapping)) {
			setMapping(mapping_id)
			getCsvModals(mapping_id, "add").then((data) => {
				setCsvColumn(data.fieldList)
				form.setFieldsValue({ csv_columns: data.fieldList })
			})
		}

		setOrgId(entity_id)
		setRelatedOrgId(related_entity_id)
		setRoleId(role_id)
		setRelatedRoleId(related_role_id)

		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		})
	}

	// Reset form and close add form
	const close = () => {
		props.close()
		setDisabled(true)
		setRelatedTo(null)
		setOrgData(null)
		setRelatedOrgData(null)
		setRoleId(null)
		setRelatedRoleId(null)
		setCsvColumn(null)
		setMapping(null)
		AllTransactionStore.csvModals = null
		PeopleStore.rPeopleCardValue = null
		form.resetFields()
	}

	useEffect(() => {
		if (props && props.from && props.from === 'a-ETR') {
			form.setFieldsValue({
				type_field_mapping_id: 1
			})
		}
	})

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Add Trade Transaction`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="addform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout="vertical"
					initialValues={AllTransactionStore.transactionItems}
				>
					<FormFieldComponent
						form={form}
						onChange={handleChange}
						tab={"add"}
						entity_type={1}
						related_to={relatedTo}
						orgData={orgData}
						relatedOrgData={relatedOrgData}
						roleId={roleId}
						relatedRoleId={relatedRoleId}
						csvColumn={csvColumn}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default AddComponent
