import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { Form, Button, Modal, Col, Row, Input } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmLedgerDetails } from '../../../../../config/messages';

const ArchiveFinancialYearComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [archiveButton, ToggleArchiveButton] = useState(true)
	const { LedgerDetailStore: { RestoreData, restoreValues } } = useStore()

	// Make function call to restore record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = restoreValues.id
		data.data_status = 0
		delete data["confirm"]
		RestoreData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmLedgerDetails.archive
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			ToggleArchiveButton(true)
		})
	}

	// Handle disable / enable button on field change
	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			ToggleArchiveButton(false)
		} else {
			ToggleArchiveButton(true)
		}
	}

	// Reset form & close unpublish form
	const close = () => {
		props.close()
		form.resetFields()
		ToggleArchiveButton(true)
	}

	return restoreValues ? (
		<Modal
			centered
			title={`Archive Data #FY - ${restoreValues.start_date} to ${restoreValues.end_date}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='archiveform' loading={saving} disabled={archiveButton} htmlType="submit" type="primary">Archive</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="archiveform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Archive all transaction data of selected Financial Year? (It might take some time, We will send email to Ledger admin once it is fully archived)</h3>
							<Form.Item style={{ marginBottom: 0 }} name="confirm">
								<Input placeholder='Type "CONFIRM" to archive data' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default ArchiveFinancialYearComponent
