import Axios from "axios"
import { decorate, observable, action } from "mobx"
import GridConfig from "../../config/GridConfig"
import { convertTextToID, globalStatus } from "../../utils/GlobalFunction"

export default class ReconciliationStore {
	list_data = null
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	reconciliation_bottom_text = null
	listAPIData = null

	unlinkValues = null
	agGrid = null
	agGridInner = null
	agGrid2Inner = null
	agGridLocal = null
	exportLoading = false
	listLoading = true
	editValues = null
	journalLogs = null
	selectedIds = null
	innerSelectedIds = null
	cashTotal = null
	tradeTotal = null
	suggestion_list = null
	cashTransTypeFilter = null
	currenciesList = null
	cashTransClassFilter = null
	transOrgFilter = null
	transRoleFilter = null

	filter_bank = []
	filter_transaction_type = []
	filter_currency = []
	filter_indentification_catagory = []
	filter_org_ppl = []
	filter_role = []
	filter_relatd_org_ppl = []
	filter_related_role = []

	getBankFilter = () => {
		return Axios.get(`getBankFilter`).then(data => {
			this.filter_bank = data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getTransactionTypesFilter = () => {
		return Axios.get(`cash/transaction/filter/get`).then(data => {
			this.filter_transaction_type = data.data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getCurrencyFilter = () => {
		return Axios.get(`getCurrencyFilter`).then(data => {
			this.filter_currency = data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getCatagoryFilter = () => {
		return Axios.get(`cash/transaction/filter/class/get`).then(data => {
			this.filter_indentification_catagory = data.data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getOrgPplFilter = (payload) => {
		return Axios.get(`trade/cash/filter/entity/get/${payload.name}/${payload.type}`).then(data => {
			if (payload.type === 1) {
				this.filter_org_ppl = data.data.data
			}
			if (payload.type === 2) {
				this.filter_relatd_org_ppl = data.data.data
			}
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getRoleFilter = (payload) => {
		return Axios.get(`trade/cash/filter/role/get/${payload.name}/${payload.type}`).then(data => {
			if (payload.type === 1) {
				this.filter_role = data.data.data
			}
			if (payload.type === 2) {
				this.filter_related_role = data.data.data
			}
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setUnlinkValues = (data) => {
		this.unlinkValues = data
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGridInner = (params) => {
		this.agGridLocal = this.agGridInner = params
	}

	// Setup grid and set column size to autosize
	setupGrid2Inner = (params) => {
		this.agGridLocal = this.agGrid2Inner = params
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGridLocal = params
		if (this.agGridLocal && this.agGridLocal.api.rowModel.rowsToDisplay.length === 0) {
			this.agGridLocal.api.showNoRowsOverlay()
		}
		if (this.agGridLocal && this.agGridLocal.api.rowModel.rowsToDisplay.length > 0) {
			this.agGridLocal.api.hideOverlay()
		}
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		let columnConfig = localStorage.getItem('reconciliation_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	// reset all the server side filters
	handleReset = () => {
		localStorage.removeItem('reconciliation_grid')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('reconciliation_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('reconciliation_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('reconciliation_grid', columnConfig)
		}
	}

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel
		if (final_filter.status_name) {
			var values_changed = []
			final_filter.status_name.values.forEach(x => {
				if (x) {
					(values_changed.push(globalStatus('reconcile_status', 'value', x)))
				} else { values_changed.splice(0, 0, null); }
			})
			final_filter.reconciliation_status = final_filter.status_name
			final_filter.reconciliation_status.values = values_changed
			delete final_filter.status_name
		}
		final_sort.forEach((x => {
			if (x.colId === 'status_name') {
				x.colId = 'reconciliation_status'
			}
		}))
		return { final_filter, final_sort }
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('reconciliation_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				var filter_data = this.changeFilterAndSort(params.request)

				if (filter_data.final_filter['ledgers_bank.name']) {
					filter_data.final_filter['ledgers_bank.name'].values = convertTextToID(filter_data.final_filter['ledgers_bank.name'], this.filter_bank, 'name', 'id')
				}

				if (filter_data.final_filter['cash_transactions_types.type_name']) {
					filter_data.final_filter['cash_transactions_types.type_name'].values = convertTextToID(filter_data.final_filter['cash_transactions_types.type_name'], this.filter_transaction_type, 'type_name', 'id')
				}

				if (filter_data.final_filter['global_currency.currency_code']) {
					filter_data.final_filter['global_currency.currency_code'].values = convertTextToID(filter_data.final_filter['global_currency.currency_code'], this.filter_currency, 'currency_code', 'id')
				}

				if (filter_data.final_filter['cte_transaction_classifications.transaction_class_name']) {
					filter_data.final_filter['cte_transaction_classifications.transaction_class_name'].values = convertTextToID(filter_data.final_filter['cte_transaction_classifications.transaction_class_name'], this.filter_indentification_catagory, 'transaction_class_name', 'id')
				}

				if (filter_data.final_filter['entity_name']) {
					filter_data.final_filter['entity_name'].values = convertTextToID(filter_data.final_filter['entity_name'], this.filter_org_ppl, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['role_name']) {
					filter_data.final_filter['role_name'].values = convertTextToID(filter_data.final_filter['role_name'], this.filter_role, 'entity_role', 'role_id')
				}

				if (filter_data.final_filter['related_entity_name']) {
					filter_data.final_filter['related_entity_name'].values = convertTextToID(filter_data.final_filter['related_entity_name'], this.filter_relatd_org_ppl, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['related_role_name']) {
					filter_data.final_filter['related_role_name'].values = convertTextToID(filter_data.final_filter['related_role_name'], this.filter_related_role, 'entity_role', 'role_id')
				}

				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}

				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('reconciliation_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

	// call api to get records
	getList = (payload = {}) => {
		this.listLoading = true
		return Axios.post(`cash/reconciliation/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.srno = index + 1
					if (!item.ledgers_bank) {
						item.ledgers_bank = { account_number: null }
					}
					item.ledgers_bank.account_number = item.ledgers_bank.iban ? item.ledgers_bank.iban : item.ledgers_bank.account_number
					item.status_name = globalStatus('reconcile_status', 'key', item.reconciliation_status)
				})
			}
			this.listAPIData = data
			this.reconciliation_bottom_text = data.reconciliation_bottom_text
			this.cashTotal = data.cashTotal
			this.tradeTotal = data.tradeTotal
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			this.listLoading = false
			return data
		})
	}

	// call api to get record detail
	getRecordById = (formdata) => {
		return Axios.post(`cash/reconciliation/link`, formdata).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.status_name = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status)
				})
			}
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get journal logs detail
	getJournalLogsById = (formdata) => {
		return Axios.post(`cash/reconciliation/transaction/journallog`, formdata).then(({ data }) => {
			this.journalLogs = data.data
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get record suggestion detail
	getSuggestionById = (data) => {
		return Axios.get(`cash/reconciliation/suggestion/${data.journalID}/${data.CTEID}`).then(({ data }) => {
			this.suggestion_list = data.data
			if (this.agGrid2Inner.columnApi) {
				var allColumnIds = []
				this.agGrid2Inner.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId)
				})
				this.agGrid2Inner.columnApi.autoSizeColumns(allColumnIds)
			}
			return data
		}).catch(({ response: { data } }) => {
			this.suggestion_list = []
			return Promise.reject(data)
		})
	}

	// export data from server as a csv
	exportData = () => {
		if (this.agGrid) {
			this.exportLoading = true;
			var final_filter = this.agGrid.api.getFilterModel()
			if (final_filter.status_name) {
				var values_changed = []
				final_filter.status_name.values.forEach(x => (
					values_changed.push(globalStatus('cash_transaction_status', 'value', x))
				))
				final_filter.status = final_filter.status_name
				final_filter.status.values = values_changed
				delete final_filter.status_name
			}
			var payload = {
				import_id: this.ImportId,
				filter_data: final_filter,
				sort_data: this.agGrid.api.getSortModel(),
				per_page: this.per_page
			}
			return Axios.post(`cash/reconciliation/export`, payload).then(({ data }) => {
				if (data.data && data.data.csv_link) { window.open(data.data.csv_link, '_blank'); }
				return data
			}).catch(e => {
				return Promise.reject(e)
			}).finally(() => this.exportLoading = false)
		}
	}

	// Call delete api
	UnlinkData = (formdata, journalLogPayload) => {
		return Axios.post(`cash/reconciliation/unlink`, formdata).then(({ data }) => {
			if (this.agGrid) { this.setupGrid(this.agGrid) }
			if (journalLogPayload) { this.getJournalLogsById(journalLogPayload) }
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call select api on suggestion popup select
	SuggestionSelectData = (formdata, journalLogPayload) => {
		return Axios.post(`cash/reconciliation/suggested/select`, formdata).then(({ data }) => {
			if (this.agGrid) { this.setupGrid(this.agGrid) }
			if (journalLogPayload) { this.getJournalLogsById(journalLogPayload) }
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call select api on select
	SelectData = (formdata, journalLogPayload) => {
		return Axios.post(`cash/reconciliation/select/${formdata.cte_id}/${formdata.id}`).then(({ data }) => {
			if (this.agGrid) { this.setupGrid(this.agGrid) }
			if (journalLogPayload) { this.getJournalLogsById(journalLogPayload) }
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call to get list of Transaction Types on Filter
	getCashTransTypeFilter = () => {
		return Axios.get(`cash/transaction/filter/get`).then(({ data }) => {
			this.cashTransTypeFilter = data.data
			return data
		}).catch(() => {
			this.cashTransTypeFilter = null
		})
	}

	// call to get list of Currencies
	getCurrenciesList = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
			this.currenciesList = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.currenciesList = null
			return Promise.reject(data)
		})
	}

	// call to get list of Organisations on Filter
	getTransOrgFilter = () => {
		return Axios.get(`trade/transactions/filter/organisations/get`).then(({ data }) => {
			this.transOrgFilter = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.transOrgFilter = data.data
			return Promise.reject(data)
		})
	}

	// call to get list of Org Roles on Filter
	getTransRoleFilter = () => {
		return Axios.get(`trade/transactions/filter/organisations-role/get`).then(({ data }) => {
			this.transRoleFilter = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.transRoleFilter = data.data
			return Promise.reject(data)
		})
	}
}

decorate(ReconciliationStore, {
	list_data: observable,
	total: observable,
	tradeTotal: observable,
	cashTotal: observable,
	selectedIds: observable,
	innerSelectedIds: observable,
	agGridInner: observable,
	agGrid2Inner: observable,
	agGridLocal: observable,
	current_page: observable,
	per_page: observable,
	unlinkValues: observable,
	editValues: observable,
	suggestion_list: observable,
	currenciesList: observable,
	agGrid: observable,
	journalLogs: observable,
	dropdown_list: observable,
	exportLoading: observable,
	listLoading: observable,
	cashTransTypeFilter: observable,
	cashTransClassFilter: observable,
	transOrgFilter: observable,
	transRoleFilter: observable,
	reconciliation_bottom_text: observable,
	listAPIData: observable,
	getList: action,
	getRecordById: action,
	exportData: action,
	setUnlinkValues: action,
	UnlinkData: action,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	getJournalLogsById: action,
	setupGridInner: action,
	setupGrid2Inner: action,
	getSuggestionById: action,
	SuggestionSelectData: action,
	SelectData: action,
	getCashTransTypeFilter: action,
	getCurrenciesList: action,
	getCashTransClassFilter: action,
	getTransOrgFilter: action,
	getTransRoleFilter: action,
	onGridChanged: action,
	handleReset: action,
	onChildnGridChanged: action
})
