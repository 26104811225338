import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Form, PageHeader, Row, Spin } from 'antd';
import moment from 'moment';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import InputComponent from '../../../components/InputComponent';
import { vsmTransactionReport } from '../../../config/messages';
import DateSelectionElement from '../SavedReports/elements/DateSelectionElement';
import ListComponent from './components/ListComponent';
import useStore from '../../../store';

const BankAuditReport = observer(() => {
	const [form] = Form.useForm()
	const { UserStore, BankAuditReportStore, BankAuditReportStore: { fYear, getFYear } } = useStore()
	const [fyYear, setFYYear] = useState(true)
	const [DateRange, SetDateRange] = useState([]);
	const [formDataList, setFormDataList] = useState([])
	const [disabled, setDisabled] = useState(false)
	const [saving, setSaving] = useState(true)
	const typeChange = ['generate_on', 'audit_group_on']
	const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)
	// const [isUpdated, setIsUpdated] = useState(false)
	const [childData, setChildData] = useState({
		"action": "preview_report",
		"audit_group_on": "week",
		"financial_year": fYear[0]?.value,
		"generate_on": "financial_year",
		"report_type": "audit"
	})

	const getFormDataList = useCallback(() => {
		var FormDataList = []
		FormDataList = [...FormDataList, ...typeChange]
		switch (form.getFieldValue('generate_on')) {
			case 'date_range':
				FormDataList = [...FormDataList, 'date_selection']
				switch (form.getFieldValue('date_selection')) {
					case 'range':
						FormDataList = [...FormDataList, 'date_range']
						break;
					case 'single':
						FormDataList = [...FormDataList, 'date']
						break;
					case 'auto':
						FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
						break;
					default:
				}
				break;
			case 'financial_year':
				FormDataList = [...FormDataList, 'financial_year']
				if (!GENERATEONFINANCIAL) {
					SETGENERATEONFINANCIAL(true)
					getFYear()
				}
				break;
			default:
		}
		return FormDataList
	}, [GENERATEONFINANCIAL, getFYear, form, typeChange])

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	const handleSubmit = (data) => {
		BankAuditReportStore.listData = null
		BankAuditReportStore.max_week = null
		setSaving(true)
		if (data.date_range && data.date_range.length) {
			var payload = {
				from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
				to_date: moment(data.date_range[1]).format('YYYY-MM-DD')
			}
			data.date_range = payload
			data.date_selection = 'range'
		}
		data.report_type = 'audit'
		data.audit_group_on = 'week'
		data.action = 'preview_report'
		setChildData(data)
		BankAuditReportStore.getList(data).then(() => {
		}).catch((e) => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const disabledDate = current => {
		if (!DateRange || DateRange.length === 0) { return false }
		return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
	}

	useEffect(() => {
		if (BankAuditReportStore.listData) {
			setSaving(false)
		}
	}, [BankAuditReportStore.listData, form])

	useEffect(() => {
		var data = getFormDataList()
		if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
			setFormDataList(data)
		}
	}, [getFormDataList, setFormDataList, formDataList])

	useEffect(() => {
		BankAuditReportStore.getList(childData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.BankAuditReport.path} />
			<PageHeader
				className={UserStore.fullscreen_class + ' query_bank remove-error-text'}
				title={BreadcrumbConfig.BankAuditReport.title}
				extra={[
					<div style={{ width: '1350px' }}>
						<Form
							style={{ display: 'block' }}
							layout='inline'
							form={form}
							id="addform"
							onFinish={handleSubmit}
							initialValues={{
								financial_year: fYear[0]?.value,
								generate_on: 'financial_year',
								audit_group_on: 'day',
								date_selection: 'range',
								date_x_value: 2,
								date_x_value_type: 'day',
							}}
						>
							<Row>
								{formDataList.includes('generate_on') && (
									<Col>
										<InputComponent
											required
											type="radio_button"
											label="Generate On"
											name="generate_on"
											onChange={handleChange}
											options={{
												values: [{ value: 'all', text: 'All Data' }, { value: 'date_range', text: 'Date Range' }, { value: 'financial_year', text: 'Financial Year' }, { value: 'periods', text: 'Period' }],
												rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
											}}
											tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
											rules={vsmTransactionReport.validation.generate_on}
										/>
									</Col>
								)}
								{(formDataList.includes('date_selection')) && (
									<DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} tab={'inQuery'} />
								)}
								{(formDataList.includes('financial_year')) && (
									<Col>
										<InputComponent
											type="select" label="Financial Year" name="financial_year"
											placeholder={"Select Financial Year"}
											tooltip="Select Financial Year"
											onChange={handleChange}
											onFocus={() => fyYear && getFYear().then(() => setFYYear(false))}
											notFoundContent={fyYear ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: fYear,
												value_key: 'value',
												text_key: 'text',
											}}
										/>
									</Col>
								)}
								<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Submit</Button>
							</Row>
						</Form>
					</div>
				]}
			>
				{BankAuditReportStore.listData ?
					<ListComponent childData={childData && childData} setSaving={setSaving} saving={saving} />
					:
					<p style={{ position: 'absolute', top: '50px', fontSize: '20px', textAlign: 'center', width: '100%', height: 'calc(100% - 30px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
						Please select Generated On then click on Submit to see the report.
					</p>
				}
			</PageHeader>
		</>
	)
})

export default BankAuditReport;