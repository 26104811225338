import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form, Divider } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const TradeIdElement = observer((onChange) => {

	return (
		<div className="w-100">
			<Form.List name='transaction_ids'>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							return (
								<div className="w-100" key={field.key}>
									{(index > 0) && (<Divider style={{ marginTop: -10 }}>OR</Divider>)}
									<div>
										<Row gutter={10}>
											<Col flex={1}>
												<InputComponent
													required name={[field.name]} key={[field.key]}
													onChange={onChange}
													placeholder="Enter Transaction ID"
													tooltip="Select date field and choose specific date to generate report"
												/>
											</Col>
											<Col className="d-flex">
												{(fields.length > 1) && (<Button type="danger" onClick={() => remove(field.name)} icon={<MinusOutlined />}></Button>)}
												{(index === (fields.length - 1)) && (<Button className="ml-5" type="primary" onClick={() => add()} icon={<PlusOutlined />}></Button>)}
											</Col>
										</Row>
									</div>
								</div>
							)
						})
					}
				}
			</Form.List >
		</div>
	)
})

export default TradeIdElement
