import React, { useState } from 'react'
import { Form, Button } from 'antd'
import { vsmOrgStatus, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store'
import FormComponent from './FormComponent'

const AddComponent = (props) => {
	const [form] = Form.useForm()
	const { OrgStatusStore: { AddData } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmOrgStatus.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return (
		<FormComponent
			form={form}
			form_id="addform"
			title="New Organisation Status"
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			formButtons={[
				<Button key="1" form='addform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		/>
	)
}

export default AddComponent
