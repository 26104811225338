import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Spin } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import useStore from '../../../../../store'
import { vsmPeopleRoles } from '../../../../../config/messages'

const FormComponent = observer(({ form, onChange, handleSubmit, tab }) => {

	const { PeopleRolesStore, UserStore } = useStore()
	const [fetchRelatedRoles, setFetchRelatedRoles] = useState(true)

	const onFinish = (data) => {
		handleSubmit(data)
	}

	return (
		<Form
			form={form} id="addeditform"
			layout='vertical'
			onFinish={onFinish}
		>
			<InputComponent
				required
				label="Role Name"
				name="role_name"
				tooltip="Different types of roles from which a role can be assigned to that particular user."
				onChange={onChange}
				placeholder="Role Name"
				rules={vsmPeopleRoles.validation.role_name}
			/>

			{((UserStore.user && UserStore.user.user_role.user_role_id === 1) ||
				(tab === "new" && PeopleRolesStore.list_data.length > 0) ||
				((PeopleRolesStore.list_data.length > 0) && PeopleRolesStore.editValues && PeopleRolesStore.editValues.relatedRoleEnabled)) && (
					<InputComponent
						required
						type="select"
						mode="multiple"
						label="Related Role/s"
						name="related_roles"
						placeholder="Choose Related Roles"
						options={{
							values: PeopleRolesStore.relates_roles || (PeopleRolesStore.editValues && PeopleRolesStore.editValues.associated_role_names &&
								PeopleRolesStore.editValues.associated_role_names.length > 0 ? PeopleRolesStore.editValues.associated_role_names.map((item) => ({
									id: item.id, role_name: item.role_name
								}))
								: undefined),
							value_key: 'id',
							text_key: 'role_name',
							rejected_keys: PeopleRolesStore.editValues && [PeopleRolesStore.editValues.id]
						}}
						onFocus={() => fetchRelatedRoles && PeopleRolesStore.getRelatedRoles().then(() => setFetchRelatedRoles(false))}
						notFoundContent={fetchRelatedRoles ? <Spin size="small" /> : "No Record Found."}
						tooltip="People Role Relation is the connection between the Roles played by two different People."
						onChange={onChange}
						rules={vsmPeopleRoles.validation.related_roles}
					/>
				)}

		</Form>
	)
})

export default FormComponent
