import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Spin, Drawer, Card } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import InputComponent from '../../../../../components/InputComponent'
import moment from 'moment'

const ViewComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AuditLogStore } = useStore()
	const [loading, setLoading] = useState(true)
	var index_count = 1

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			AuditLogStore.auditValues = null
			AuditLogStore.getRecordDetail(props.id).then((data) => {
				setLoading(false)
			}).catch((data) => {
				setLoading(false)
			})
		}
	}, [props, AuditLogStore])


	// set the form values to edit
	useEffect(() => {
		if (AuditLogStore.auditValues && props.visible) {
			form.setFieldsValue({
				id: AuditLogStore.auditValues.id,
				user_type: AuditLogStore.auditValues.user_type,
				user_id: AuditLogStore.auditValues.user_id,
				event: AuditLogStore.auditValues.event,
				auditable_type: AuditLogStore.auditValues.auditable_type,
				url: AuditLogStore.auditValues.url,
				ip_address: AuditLogStore.auditValues.ip_address,
				user_agent: AuditLogStore.auditValues.user_agent,
				tags: AuditLogStore.auditValues.tags,
				created_at: moment(AuditLogStore.auditValues.created_at).format('YYYY-MM-DD'),
				user: (AuditLogStore.auditValues && AuditLogStore.auditValues.users) ? (AuditLogStore.auditValues.users.first_name + " " + AuditLogStore.auditValues.users.last_name) : null,
			})
		}
	}, [AuditLogStore.auditValues, form, props.visible])

	// reset form and close edit form
	const close = () => {
		props.close()
		props.setId(null)
		form.resetFields()
	}

	return (
		<Drawer
			title={`View Log : #${AuditLogStore.auditValues && AuditLogStore.auditValues.id} - ${AuditLogStore.auditValues && AuditLogStore.auditValues.event}`}
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" htmlType="button" onClick={close}>Back to Listing</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form form={form} id="viewform"
					labelCol={{ span: 6 }}
				>
					<Row gutter={20}>
						<Col span={12}>
							<InputComponent disabled label="Model" name="auditable_type" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Page" name="user_type" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Id" name="id" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="IP Address" name="ip_address" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Tags" name="tags" placeholder="Organisation Name"
							/>
						</Col>
						<Col span={12}>
							<InputComponent disabled label="User Name" name="user" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Event" name="event" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="URL" name="url" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Browser Info" name="user_agent" placeholder="Organisation Name"
							/>
							<InputComponent disabled label="Action Taken On" name="created_at" placeholder="Organisation Name"
							/>
						</Col>
					</Row>
					{
						AuditLogStore.auditValues && AuditLogStore.auditValues.old_values && (
							<Card title="What's changed (Red is Old Value Green is New):">
								{
									AuditLogStore.auditValues && AuditLogStore.auditValues.old_values && Object.keys(AuditLogStore.auditValues.old_values).map((item, index) => {

										if (AuditLogStore.auditValues.new_values && AuditLogStore.auditValues.old_values[item] === AuditLogStore.auditValues.new_values[item]) {
											return (
												<div className="d-flex">
													<div className="min-w25">{index_count++}</div>
													<div className="d-flex w-100">
														<div className="min-w25 text-center"></div>
														<div><span className="text_blue_color word-break">{item}:</span><span className="ml-5 text_green_color word-break">{AuditLogStore.auditValues.new_values[item] === false || AuditLogStore.auditValues.new_values[item] === true ? AuditLogStore.auditValues.new_values[item].toString() : AuditLogStore.auditValues.new_values[item]}</span></div>
													</div>
												</div>
											)
										} else {
											return (
												<>
													<div className="d-flex">
														<div className="min-w25">{index_count++}</div>
														<div className="d-flex back_red_color w-100">
															<div className="min-w25 text-center">-</div>
															<div><span className="text_blue_color word-break">{item}:</span><span className="ml-5 text_green_color word-break">{AuditLogStore.auditValues.old_values[item] === false || AuditLogStore.auditValues.old_values[item] === true ? AuditLogStore.auditValues.old_values[item].toString() : AuditLogStore.auditValues.old_values[item]}</span></div>
														</div>
													</div>
													<div className="d-flex">
														<div className="min-w25">{index_count++}</div>
														<div className="d-flex back_green_color w-100">
															<div className="min-w25 text-center">+</div>
															<div><span className="text_blue_color word-break">{item}:</span><span className="ml-5 text_green_color word-break">{AuditLogStore.auditValues.new_values && (AuditLogStore.auditValues.new_values[item] === false || AuditLogStore.auditValues.new_values[item] === true ? AuditLogStore.auditValues.new_values[item].toString() : AuditLogStore.auditValues.new_values[item])}</span></div>
														</div>
													</div>
												</>
											)
										}
									})
								}
							</Card>
						)
					}
				</Form>
			</Spin>
		</Drawer>
	)
})

export default ViewComponent
