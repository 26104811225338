import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import SwitchComponent from './SwitchComponent'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			<>
				{UserStore.checkPermission(31, 'edit') &&
					<Button title="Edit Transaction FieldS" disabled={props.data.field_type === "DB Column"} type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
				}
				{
					(props.data && props.data.status === true) ? (
						UserStore.checkPermission(31, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You can not delete published Transaction Field. Please unpublish first to delete this Transaction Field."}>
							<Button disabled type="text"><DeleteOutlined /></Button>
						</Tooltip>
					) : (props.data && props.data.association_count > 0) ? (
						UserStore.checkPermission(31, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You can not delete this Transaction Field as it is already used by one or more Transactions."}>
							<Button disabled type="text"><DeleteOutlined /></Button>
						</Tooltip>
					) : (
						UserStore.checkPermission(31, 'delete') &&
						<Button title="Delete Transaction FieldS" disabled={props.data.field_type === "DB Column"} type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
					)
				}
			</>
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		headerTooltip: '# ID',
		tooltipField: 'id',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Field Name',
		field: 'field_name',
		headerTooltip: 'Field Name',
		tooltipField: 'field_name',
		width: 150
	}, {
		headerName: 'Field Type',
		headerTooltip: 'Field Type',
		field: 'field_type',
		tooltipField: 'field_type',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Algorithm Name',
		headerTooltip: 'Algorithm Name',
		field: 'algorithm_name',
		tooltipField: 'algorithm_name',
		width: 150
	}, {
		headerName: 'Default Value',
		headerTooltip: "Default Value",
		field: 'default_value',
		tooltipValueGetter: (params) => (params.data && params.data.default_value) ? params.data.default_value : "-",
		valueGetter: (params) => (params.data && params.data.default_value) ? params.data.default_value : "-",
		filter: false,
		width: 80
	}, {
		headerName: 'Description',
		headerTooltip: 'Description',
		field: 'description',
		tooltipField: 'description',
		width: 220
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		tooltipField: 'updated_by',
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		field: 'updated_at',
		tooltipField: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Published',
		headerTooltip: 'Published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Yes", "No"],
			defaultToNothingSelected: true
		},
		width: 150
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 80
	}]
}
