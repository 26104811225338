import React from 'react'
import { observer } from 'mobx-react'
import { Button, Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import useStore from '../../../../../../../store';
import ViewComponent from '../../../../../../CashTransaction/CashTransactions/components/ViewComponent'
import { CurrencyFormat } from '../../../../../../../utils/GlobalFunction';
import moment from 'moment'

const ListComponent = observer((props) => {
	const [transactionId, setTransactionId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	const { TransactionModelStore: { array_list, setArrayList }, LedgerBankAccountStore } = useStore()
	const DragHandle = sortableHandle(() => (
		<MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
	));

	// action component
	const ActionHandle = (prop) => {
		return (
			<>
				<Button size="small" type="primary" onClick={() => {
					if (props.openParentCashViewDrawer) {
						props.close()
						LedgerBankAccountStore.getDynamicFieldList()
						props.openParentCashViewDrawer(prop.data.cte_transaction_id)
					} else {
						openViewDrawer(prop.data.cte_transaction_id)
					}
				}}><EyeOutlined /></Button>
			</>
		)
	}

	// open drawer for view
	const openViewDrawer = async (id) => {
		await setTransactionId(id)
		LedgerBankAccountStore.getDynamicFieldList()
		setViewDrawer(true)
	}

	//close drawer for view
	const closeViewDrawer = () => {
		setViewDrawer(false)
	}

	// Delete component
	const ActionHandleJournalline = (prop) => {
		return (
			<div className='d-flex'>
				{props.currentView === "edit" && <Button title="Edit" type="text" size="large" style={{ padding: 4 }} onClick={() => { props.openEditModel(prop.data) }} ><EditOutlined /></Button>}
				<Button title="Delete" type="text" size="large" style={{ padding: 4 }} onClick={() => { props.openDeleteModal(prop.data) }} ><DeleteOutlined /></Button>
			</div>
		)
	};

	const columns = [
		{
			className: 'drag-visible',
			render: () => <DragHandle />,
		},
		{
			title: 'ID',
			dataIndex: 'index',
		},
		{
			title: 'Journal',
			dataIndex: 'journal_type',
		},
		{
			title: 'Code',
			dataIndex: 'code',
		},
		{
			title: 'Journal Label',
			dataIndex: 'journal_label',
		},
		{
			title: 'Mapped Value',
			key: 'mapped_value',
			width: '20%',
			ellipsis: true,
			render: (data) => {
				return data.mapped_value
			}
		},
		{
			title: 'Use as',
			dataIndex: 'uses_as',
		},
		{
			title: 'Action',
			key: 'action',
			render: (data) => <ActionHandleJournalline data={data} />
		},
	];

	const verifyColumns = [
		{
			title: '#',
			dataIndex: 'index',
			render: (text, record, index) => index + 1
		},
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Journal',
			dataIndex: 'journal_type',
		},
		{
			title: 'Code',
			dataIndex: 'code',
		},
		{
			title: 'Journal Label',
			dataIndex: 'journal_label',
		},
		{
			title: 'Mapped Value',
			key: 'mapped_value',
			render: (data) => {
				return data.mapped_value
			}
		},
		{
			title: 'Debit(+)',
			key: 'debit',
			align: "right",
			render: (data) => {
				if (data.debit) {
					return <CurrencyFormat value={data.debit} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
		{
			title: 'Credit(-)',
			key: 'credit',
			align: "right",
			render: (data) => {
				if (data.credit) {
					return <CurrencyFormat value={data.credit} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
		{
			title: 'Posting',
			key: 'posting',
			align: "right",
			render: (data) => {
				if (data.posting) {
					return <CurrencyFormat value={data.posting} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
	];

	function insertAt(array, index, ...elementsArray) {
		array.splice(index, 0, ...elementsArray);
	}

	if (props.page !== "Transaction Modal") {
		insertAt(verifyColumns, 3, {
			title: 'Journal Date',
			key: 'created_at',
			render: (data) => moment(data.created_at).format('YYYY-MM-DD')
		})
		insertAt(verifyColumns, 4, {
			title: 'Model',
			key: 'model_name',
			render: (data) => data.model_name ? data.model_name : null
		})
		verifyColumns.push({
			title: 'Reconciled To',
			key: 'cte_transaction_id',
			render: (data) => data.cte_transaction_id ? <ActionHandle data={data} /> : 'Pending'
		})
	}

	const SortableItem = sortableElement(props => <tr {...props} />);
	const SortableContainer = sortableContainer(props => <tbody {...props} />);

	// set array on drag end
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setArrayList(arrayMove(array_list, oldIndex, newIndex))
	};

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = array_list && array_list.findIndex(x => x.index === restProps['data-row-key']);
		if (index !== null) {
			return <SortableItem index={index} {...restProps} />;
		}
		return null
	};


	const DraggableContainer = props => (
		<SortableContainer
			useDragHandle
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	return (
		<>
			<Table
				size="small"
				loading={!array_list}
				className="journal_logs_table"
				pagination={false}
				dataSource={array_list}
				columns={props.currentView === "verify" ? verifyColumns : columns}
				rowKey="index"
				bordered
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow,
					},
				}}
				summary={pageData => {
					let totalDebit = 0;
					let totalCredit = 0;

					props.currentView === "verify" && array_list && array_list.length > 0 && pageData.forEach(({ debit, credit }) => {
						totalDebit += debit ? parseFloat(debit) : 0;
						totalCredit += credit ? parseFloat(credit) : 0;
					});

					let totalPosting = Math.round((parseFloat(totalDebit) - parseFloat(totalCredit)) * 100) / 100

					return props.currentView === "verify" && array_list && array_list.length > 0 && (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell></Table.Summary.Cell>
								<Table.Summary.Cell></Table.Summary.Cell>
								<Table.Summary.Cell></Table.Summary.Cell>
								<Table.Summary.Cell></Table.Summary.Cell>
								<Table.Summary.Cell></Table.Summary.Cell>
								<Table.Summary.Cell></Table.Summary.Cell>

								{(props.page !== "Transaction Modal") &&
									<>
										<Table.Summary.Cell></Table.Summary.Cell>
										<Table.Summary.Cell></Table.Summary.Cell>
									</>}
								<Table.Summary.Cell>
									<div className="text-right"><CurrencyFormat value={totalDebit} /></div>
								</Table.Summary.Cell>
								<Table.Summary.Cell>
									<div className="text-right"><CurrencyFormat value={totalCredit} /></div>
								</Table.Summary.Cell>
								<Table.Summary.Cell>
									<div className="text-right"><CurrencyFormat value={totalPosting} /></div>
								</Table.Summary.Cell>
								{(props.page !== "Transaction Modal") &&
									<Table.Summary.Cell></Table.Summary.Cell>}
							</Table.Summary.Row>
						</>
					);
				}}
			/>
			<ViewComponent transactionId={transactionId} setId={setTransactionId} visible={viewDrawer} close={closeViewDrawer} />
		</>
	)
})

export default ListComponent
