import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Input, Checkbox, Row, Col, Card, Drawer } from 'antd'
import useStore from '../../../../store'
import { vsmRolemNagement, vsmNotify } from '../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { RoleManagementStore } = useStore()
	const Store = {
		RoleManagement: RoleManagementStore,
	}

	useEffect(() => {
		if (props.visible) {
			Store.RoleManagement.getPermissionsList().then((data) => {
				form.setFieldsValue({
					permissions: data.data
				})
			})
		}
	}, [Store.RoleManagement, form, props.visible])

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.permissions = convertPermission(data)
		Store.RoleManagement.AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmRolemNagement.addRole
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	const convertPermission = (value) => {
		var actions = {}
		value.permissions.map((item) => {
			item.actions.map(action => {
				if (action.is_selected === 1) {
					if (!actions[item.id]) { actions[item.id] = [] }
					actions[item.id].push(action.id)
				}
				return null
			})
			return null
		})
		value.permissions = actions
		return actions
	}

	// check for valid form values then accordingly make save button disable / enable & set value of user
	const handleChange = () => {
		form.validateFields().then((data) => {
			var temp = convertPermission(data)
			if (Object.keys(temp).length > 0) {
				setDisabled(false)
			} else {
				setDisabled(true)
			}
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return (
		<Drawer
			centered
			title="New User Role"
			visible={props.visible}
			onClose={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={'45%'}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				labelCol={{ span: 6 }}
				onChange={handleChange}
				layout={'vertical'}
			>
				<Form.Item name="role_name" rules={vsmRolemNagement.validation.role_name} label="Role Name" required tooltip='The function assumed or played by a person associated with group of permissions.'>
					<Input placeholder="Role Name" maxLength={50} />
				</Form.Item>

				<Row>
					<Col span={5}>Features</Col>
					<Col span={19}>Actions</Col>
				</Row>

				<Form.List name="permissions">
					{
						(fields => {
							return (
								fields.map(field => {
									var value = form.getFieldValue('permissions')[field.key]
									var isDisabled = false
									value.actions.map((action, index) => {
										if (action.action_name === 'list' && action.is_selected !== 1) {
											isDisabled = true
										}
										return null
									})
									var all_checked = 0
									value.actions.map((action, index) => {
										if (action.is_selected === 1) {
											all_checked++
										}
										return null
									})
									return (
										<>
											{value.actions.length > 0 && (
												<Card className="mt-10">
													<Row gutter={24}>
														<Col span={5} style={{ borderRight: '1px solid #f0f0f0' }}><b>{value.title}</b></Col>
														<Col span={19}>
															<Checkbox checked={all_checked === value.actions.length} onChange={(e) => {
																var temp = form.getFieldValue('permissions')

																temp[field.key].actions.map((action, index) => {
																	temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																	return null
																})
																form.setFieldsValue({
																	permissions: temp
																})
															}}>Check all</Checkbox>

															{value.actions.map((action, index) => {
																var temp = action.action_name.replace(/_/g, ' ');
																return (
																	<div key={index} className='mr-10 mb-10' style={{ display: 'inline-block' }}>
																		<Form.Item
																			noStyle
																			name={[field.name, 'actions', index, 'is_selected']}
																		>
																			<Checkbox disabled={isDisabled && action.action_name !== 'list'} checked={action.is_selected === 1 ? true : false} value={action.id} onChange={(e) => {
																				var temp = form.getFieldValue('permissions')
																				temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																				if (temp[field.key].actions[index].action_name === 'list' && !e.target.checked) {
																					temp[field.key].actions.map((action, index) => {
																						temp[field.key].actions[index].is_selected = 0
																						return null
																					})
																				}

																				form.setFieldsValue({
																					permissions: temp
																				})
																			}}>{temp.charAt(0).toUpperCase() + temp.slice(1)}</Checkbox>
																		</Form.Item>
																	</div>
																)
															})}
														</Col>
													</Row>
												</Card>
											)}
										</>
									)
								})
							)
						})
					}
				</Form.List>
			</Form>
		</Drawer>
	)
})

export default AddComponent
