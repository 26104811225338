import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../store'
import SwitchComponent from './SwitchComponent'
import NumberFormat from 'react-number-format'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()

	return (
		<div className="action-column">
			{

				<>
					{UserStore.checkPermission(55, 'edit') && <Button title="Edit FX Currencies" type="text" disabled={props.data.disable_action} onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>}
					{(props.data.currency_association_count > 0 || props.data.ledgers_currency_count > 0) ? (
						<Tooltip placement="topRight" color={"red"} title={"Sorry, you cannot delete this Currency as it is associated with existing Transaction or Ledger."} >
							{UserStore.checkPermission(55, 'delete') && <Button type="text" disabled><DeleteOutlined /></Button>}
						</Tooltip>
					) : (
						UserStore.checkPermission(55, 'delete') && <Button title="Delete FX Currencies" disabled={props.data.disable_action} type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
					)}
				</>

			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [
		{
			headerName: '# ID',
			tooltipField: 'id',
			field: 'id',
			headerTooltip: '# ID',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 55
		}, {
			headerName: 'Name',
			headerTooltip: 'Name',
			field: 'currency_name',
			tooltipField: 'currency_name',
			width: 74
		}, {
			headerName: 'Code',
			headerTooltip: 'Code',
			field: 'currency_code',
			tooltipField: 'currency_code',
			width: 60
		}, {
			headerName: 'Symbol',
			headerTooltip: 'Symbol',
			tooltipField: 'currency_symbol',
			field: 'currency_symbol',
			filter: 'agSetColumnFilter',
			width: 75
		},
		{
			headerName: '1 EUR =',
			headerTooltip: '1 EUR =',
			tooltipField: 'currency_id_wise_day_value',
			field: 'currency_id_wise_day_value',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: (data) => {
				return <NumberFormat value={data.value} decimalScale={4} displayType='text' thousandSeparator={true} fixedDecimalScale={true} />
			},
			valueGetter: (data) => {
				return Number(data.data.currency_id_wise_day_value)
			},
			width: 75
		},
		{
			visibleToSuper: 1,
			headerName: 'Status',
			headerTooltip: 'Status',
			field: 'published',
			cellRendererFramework: function (data) {
				const { onSwitchChange } = data.agGridReact.props
				return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
			},
			sortable: false,
			filter: 'agSetColumnFilter',
			filterParams: {
				values: ["Active", "Deactive"],
				defaultToNothingSelected: true,
			},
			width: 80
		},
		{
			visibleToLedger: 1,
			headerName: 'Status',
			headerTooltip: 'Status',
			field: 'ledger_published',
			cellRendererFramework: function (data) {
				const { onSwitchChange } = data.agGridReact.props
				return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
			},
			sortable: false,
			filter: 'agSetColumnFilter',
			filterParams: {
				values: ["Active", "Deactive"],
				defaultToNothingSelected: true,	
			},
			width: 80
		},
		{
			headerName: 'Actions',
			headerTooltip: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 115
		}]
}
