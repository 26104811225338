import React from 'react'
import { HomeOutlined } from '@ant-design/icons';

export const BreadcrumbConfig = {
	TransactionFields: {
		title: 'Transaction Fields',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Transaction Fields' },
		]
	},
	JournalGroups: {
		title: 'Journal Groups',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Journal Groups' },
		]
	},
	TaxSettings: {
		title: 'Tax Settings',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Tax Settings' },
		]
	},
	TransactionTypes: {
		title: 'Trade Type',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Trade Transactions' },
			{ name: 'Trade Type' },
		]
	},
	TransactionSubTypes: {
		title: 'Transactions Type',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Trade Type' },
			{ name: 'Transactions Type' },
		]
	},
	TransactionFieldMapping: {
		title: 'Trade Transaction',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Trade Type' },
			{ name: 'Trade Transaction' },
		]
	},
	Journals: {
		title: 'Journals',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Journals' },
		]
	},
	TransactionModels: {
		title: 'Transaction Models',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Transaction Models', link: '/trade-transactions/administration/transaction-model' },
		]
	},
	TransactionModels_ADD: {
		title: 'Transaction Models',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Transaction Models', link: '/trade-transactions/administration/transaction-model' },
			{ name: 'New Transaction Model' }
		]
	},
	TransactionModels_EDIT: {
		title: 'Transaction Models',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Transaction Models', link: '/trade-transactions/administration/transaction-model' },
			{ name: 'Edit Transaction Model' }
		]
	},
	TransactionModels_VERIFY: {
		title: 'Transaction Models',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Transaction Models', link: '/trade-transactions/administration/transaction-model' },
			{ name: 'Verify Transaction Model' }
		]
	},
	Algorithms: {
		title: 'Algorithms',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Algorithms' }
		]
	},
	Import: {
		title: 'Import Log',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Import Log' }
		]
	},
	Import_AddComponent: {
		title: ' Import Batch',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Import Log', link: '/trade-transactions/administration/import-log' },
			{ name: ' Import Batch' }
		]
	},
	Organisations: {
		title: 'Organisations',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'All Organisations', link: '/organisations/all-organisations' },
		]
	},
	view_Organisations: {
		title: 'View Organisations',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'All Organisations', link: '/organisations/all-organisations' },
			{ name: 'View Organisations' },
		]
	},
	fx_gainlossReport: {
		title: 'FX Gain-Loss Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'FX Gain-Loss' }
		]
	},
	Organisations_RoleMapping: {
		title: 'Role Mapping',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'All Organisations', link: '/organisations/all-organisations' },
		]
	},
	Organisations_BankAccounts: {
		title: 'Bank Accounts',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'All Organisations', link: '/organisations/all-organisations' }
		]
	},
	Roles: {
		title: 'Roles',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'Administration' },
			{ name: 'Roles' },
		]
	},
	Status: {
		title: 'Organisation Status',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Organisations' },
			{ name: 'Organisation Status', link: '/organisations/status' },
		]
	},
	Algorithms_ADD: {
		title: 'Algorithms',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Algorithms', link: '/trade-transactions/administration/algorithms' },
			{ name: 'New Algorithm' }
		]
	},
	Algorithms_EDIT: {
		title: 'Algorithms',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Algorithms', link: '/trade-transactions/administration/algorithms' },
			{ name: 'Edit Algorithm' }
		]
	},
	Algorithms_VIEW: {
		title: 'Algorithms',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transaction' },
			{ name: 'Administration' },
			{ name: 'Algorithms', link: '/trade-transactions/administration/algorithms' },
			{ name: 'View Algorithm' }
		]
	},
	UserManagement: {
		title: 'All Users',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Users' },
			{ name: 'All Users' }
		]
	},
	RoleManagement: {
		title: 'User Roles',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Users' },
			{ name: 'User Roles' }
		]
	},
	AllLedgers: {
		title: 'Ledgers',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Ledgers' },
			{ name: 'All Ledgers', link: '/setup/ledger/all-ledgers' },
		]
	},
	LedgersSettings: {
		title: 'Ledger Settings',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Ledgers' },
			{ name: 'Ledger Settings', link: '/setup/ledger/ledger-settings' },
		]
	},
	LedgersBankAccount: {
		title: "Ledger's Bank Accounts",
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: "Ledger's Bank Accounts", link: '/setup/ledgers-bank-accounts' },
		]
	},
	SavedReports: {
		title: 'Reports',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports' }
		]
	},
	BankAuditReport: {
		title: 'Audit - Detail Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Audit - Detail Report' },
		]
	},
	BankImportSummary: {
		title: 'Bank Transactions Import Summary',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Bank Transactions Import Summary' }
		]
	},
	a_ETRReports: {
		title: 'Asset Management',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: ' Asset Management' }
		]
	},
	SavedReportsAddComponent: {
		title: 'Create Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports', link: '/reports/saved-reports' },
			{ name: 'New' }
		]
	},
	AuditLogs: {
		title: "Audit Logs",
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Ledgers' },
			{ name: "Audit Logs", link: '/setup/ledger/audit-logs' },
		]
	},
	OldImport: {
		title: 'Old Import',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Import Log', link: '/trade-transactions/import' },
			{ name: 'Old Import' }
		]
	},
	AllTransactions: {
		title: 'All Transactions',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'All Transactions' }
		]
	},
	SavedReportsPreviewReportComponent: {
		title: 'Preview Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports', link: '/reports/saved-reports' },
			{ name: 'Preview Report' }
		]
	},
	SavedReportsEditComponent: {
		title: 'Create Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports', link: '/reports/saved-reports' },
		]
	},
	SavedReportsPreviewReport: {
		title: 'Preview Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports', link: '/reports/saved-reports' },
			{ name: 'Preview Report' }
		]
	},
	SavedReportsPreviewData: {
		title: 'Preview Report',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Reports' },
			{ name: 'Saved Reports', link: '/reports/saved-reports' },
			{ name: 'Preview Data' }
		]
	},
	RegularExpression: {
		title: 'Transaction Formula',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Administration' },
			{ name: 'Transaction Formula' }
		]
	},
	CashTransactions: {
		title: 'All Transactions',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'All Transactions' },
		]
	},
	Classifications: {
		title: 'Bank Codes Analysis',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Administration' },
			{ name: 'Bank Codes Analysis' }
		]
	},
	Reconciliation: {
		title: 'Cash to Trade Reconciliation',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Cash Reconcilation' },
		]
	},
	CashToCashReconciliation: {
		title: 'Bank to Bank Reconciliation',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Cash Reconciliation' },
		]
	},
	QueryBankCash: {
		title: 'Bank Vs Cash',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Bank Vs Cash' },
		]
	},
	CTEImport: {
		title: 'Import Batch',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Cash Transactions' },
			{ name: 'Import Batch' }
		]
	},
	People: {
		title: 'People',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'People' },
			{ name: 'All People' },
		]
	},
	People_BankAccounts: {
		title: 'Bank Accounts',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'People' },
			{ name: 'All People', link: '/people/all-people' }
		]
	},
	People_RoleMapping: {
		title: 'Role Mapping',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'People' },
			{ name: 'All People', link: '/people/all-people' },
		]
	},
	People_roles: {
		title: 'People Roles',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'People' },
			{ name: 'Administration' },
			{ name: 'All People', link: '/people/roles' },
		]
	},
	FXCurrencies: {
		title: 'FX Currencies Management',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'FX Currencies Management' },
		]
	},
	view_people: {
		title: 'View People',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'People' },
			{ name: 'All People', link: '/people/all-people' },
			{ name: 'View People' },
		]
	},
	Manual_Journal_Log: {
		title: 'Manual Journal Log',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Journals', link: '/trade-transactions/administration/journals' },
			{ name: "Manual Journal Log" }
		]
	},
	Triggers: {
		title: 'Triggers',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Trade Transactions' },
			{ name: 'Administration' },
			{ name: 'Triggers' },
		]
	},
	Globak_Setting: {
		title: 'Global Settings',
		path: [
			{ name: <HomeOutlined />, link: '/dashboard' },
			{ name: 'Setup' },
			{ name: 'Global Settings' },
		]
	}
}
