import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Col, PageHeader, Row } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import LedgerDetail from './component/LedgerDetailComponent'
import FinancialYearSetting from './component/FinancialYearSettingComponent'
import ArchiveComponent from './component/ArchiveFinancialYearComponent'
import RestoreComponent from './component/RestoreFinancialYearComponent'

const LedgerSettings = observer(() => {
	const { LedgerDetailStore: { setRestoreValues }, UserStore } = useStore()
	const [archiveModal, setArchiveModal] = useState(false)
	const [restoreModal, setRestoreModal] = useState(false)

	// Open Archive Modal
	const openArchiveModal = (data) => {
		setRestoreValues(data)
		setArchiveModal(true)
	}

	// Close Archive Modal
	const closeArchiveModal = () => setArchiveModal(false)

	// Open Restore Modal
	const openRestoreModal = (data) => {
		setRestoreValues(data)
		setRestoreModal(true)
	}

	// Close Restore Modal
	const closeRestoreModal = () => setRestoreModal(false)

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.LedgersSettings.path} />
			<PageHeader
				title={BreadcrumbConfig.LedgersSettings.title}
			>
				<Row gutter={24}>
					<Col span={12}><LedgerDetail /></Col>
					<Col span={12}>{UserStore.checkPermission(69, 'financial_year') && <FinancialYearSetting openArchiveModal={openArchiveModal} openRestoreModal={openRestoreModal} />}</Col>
				</Row>
				<ArchiveComponent visible={archiveModal} close={closeArchiveModal} />
				<RestoreComponent visible={restoreModal} close={closeRestoreModal} />
			</PageHeader>
		</>
	)
})

export default LedgerSettings
