import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { RouterConfig } from '../config/RouterConfig'
import PageNotFound from '../pages/PageNotFound'
import useStore from '../store'

const AppRouter = () => {
	const { UserStore: { user } } = useStore()
	return (
		<Switch>
			{
				RouterConfig.map((item, index) => {
					var exact = true
					if (item.errorpage === true) {
						exact = false
					}
					if (user && user.user_role && user.user_role.user_role_id !== 1 && [].includes(item.path)) {
						return (
							<Route key={index} exact={exact} path={item.path} >
								<PageNotFound />
							</Route>
						)
					} else {
						return (
							<Route key={index} exact={exact} path={item.path} component={item.component} />
						)
					}

				})
			}
		</Switch>
	)
}

export default AppRouter
