import { Button, Drawer, Divider, Form } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useState } from 'react'
import { vsmIpSettings, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import FormComponent from './components/FormComponent'
import ListComponent from './components/ListComponent'

const IPSettings = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const { IPSettingsStore } = useStore()

	const close = () => {
		props.close()
		form.resetFields()
		IPSettingsStore.setUserValues(null)
		IPSettingsStore.editValues = null
	}

	const handleChange = (data) => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	const handleSubmit = (data) => {
		setSaving(true)
		var payload = {
			ledger_id: IPSettingsStore.userValues.default_ledger_id,
			user_id: IPSettingsStore.userValues.id,
			ip_address: data.ip_address,
			expiry_date: moment(data.expiry_date).format('YYYY-MM-DD'),
			data_limit: data.data_limit,
			status: data.status === true ? 1 : 0,
		}
		if (IPSettingsStore.editValues) {
			IPSettingsStore.EditData(IPSettingsStore.editValues.data.id, payload).then(() => {
				form.resetFields()
				vsmNotify.success({
					message: vsmIpSettings.edit
				})
			}).catch(e => {
				if (e.errors) {
					e.errors.forEach(x => {
						if (x.name.indexOf('.') !== -1) {
							x.name = x.name.split('.');
							x.name[1] = parseInt(x.name[1])
						}
					})
					form.setFields(e.errors)
				}
			}).finally(() => setSaving(false))
		} else {
			IPSettingsStore.AddData(payload).then(() => {
				form.resetFields()
				vsmNotify.success({
					message: vsmIpSettings.add
				})
			}).catch(e => {
				if (e.errors) {
					e.errors.forEach(x => {
						if (x.name.indexOf('.') !== -1) {
							x.name = x.name.split('.');
							x.name[1] = parseInt(x.name[1])
						}
					})
					form.setFields(e.errors)
				}
			}).finally(() => setSaving(false))
		}
	}

	const getRecordDetails = (data) => {
		IPSettingsStore.ReadData(data.id).then(() => {
			if (IPSettingsStore.editValues) {
				form.setFieldsValue({
					ip_address: IPSettingsStore.editValues.data.ip_address,
					expiry_date: moment(IPSettingsStore.editValues.data.expiry_date, 'YYYY-MM-DD'),
					data_limit: IPSettingsStore.editValues.data.data_limit,
					status: IPSettingsStore.editValues.data.status === 1 ? true : false
				})
			}
		})
	}

	return (
		<Drawer
			title="API Settings"
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" form='addIpSettingform' disabled={disabled} loading={saving} htmlType="submit" type="primary">{IPSettingsStore.editValues ? 'Update' : 'Save'}</Button>
					<Button key="2" htmlType="button" onClick={close} className='ml-10'>Cancel</Button>
				</div>
			]}
			width={'75%'}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} setDisabled={setDisabled} handleChange={handleChange} />
			<Divider />
			<ListComponent getRecordDetails={getRecordDetails} />
		</Drawer>
	)
})

export default IPSettings