import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Select, Tooltip, Radio, Row, Col, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { vsmJournalLines } from '../../../../../../../config/messages';
import useStore from '../../../../../../../store'
import { observer } from 'mobx-react';
import InputComponent from '../../../../../../../components/InputComponent';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore, TransactionModelStore: { journal_list, journalLinesValues, field_list, algorithm_list, getAllJournalsList, getAllFieldList, getAllAlgorithmList, handleEdit } } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [mapWith, setMapWith] = useState("Field")
	const [fetchJournal, setFetchJournal] = useState(true)
	const [fetchField, setFetchField] = useState(true)
	const [fetchAlgo, setFetchAlgo] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = async (data) => {
		const journals = journal_list.find((item) => item.id === data.journal_id)
		var obj = {
			journal_id: data.journal_id,
			field_id: journalLinesValues.field_id,
			algorithm_id: journalLinesValues.algorithm_id,
			line_type: journalLinesValues.uses_as,
			journal_line_order: journalLinesValues.journal_line_order,
			journal_type: journals.journal_type,
			code: journals.journal_code,
			journal_label: journals.journal_label,
			index: journalLinesValues.index,
			uses_as: journalLinesValues.uses_as,
			mapped_value: journalLinesValues.mapped_value
		}
		await handleEdit(obj)
		await props.setNewFields(true)
		close()
		props.handleChange()
	}

	// Set the form values to edit
	useEffect(() => {
		if (journalLinesValues && props.visible) {
			let map_with = journalLinesValues.mapped_value.includes("Field") ? "Field" : "Algorithm"
			if (map_with) {
				TransactionModelStore.field_list = journalLinesValues.mapped_data && [{ id: journalLinesValues.mapped_data.id, field_name: journalLinesValues.mapped_data.mapped_value }]
			} else {
				TransactionModelStore.algorithm_list = journalLinesValues.mapped_data && [{ id: journalLinesValues.mapped_data.id, algorithm_name: journalLinesValues.mapped_data.mapped_value }]
			}
			setMapWith(map_with)
			form.setFieldsValue({
				journal_id: journalLinesValues.journal_id,
				map_with: map_with,
				field_id: journalLinesValues.field_id,
				algorithm_id: journalLinesValues.algorithm_id,
				line_type: journalLinesValues.uses_as,
			})
		}
	}, [journalLinesValues, TransactionModelStore, form, props.visible])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		props.handleChange()
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// Reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
		setFetchJournal(true)
		setFetchField(true)
		setFetchAlgo(true)
		TransactionModelStore.journalLinesValues = null
	}

	return journalLinesValues ? (
		<Modal
			centered
			title="Edit Journal Line"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editJournalform' htmlType="submit" type="primary">Add Line</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="editJournalform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<InputComponent
					required allowClear type="select" label="Journal" name="journal_id" placeholder="Select Journal"
					tooltip="A Journal is an account against which Journal Entries are stored, e.g. in Accounts, this would be one nominal account in the chart of accounts."
					rules={vsmJournalLines.validation.journal_id}
					onChange={handleChange}
					onFocus={() => fetchJournal && getAllJournalsList().then(() => setFetchJournal(false))}
					notFoundContent={fetchJournal ? <Spin size="small" /> : "No Record Found."}
					options={{
						values: journal_list || [{
							id: journalLinesValues && journalLinesValues.journal_id,
							journal_code: journalLinesValues && journalLinesValues.code,
							journal_label: journalLinesValues && journalLinesValues.journal_label,
						}],
						value_key: 'id',
						text_key: { key: ["journal_code", " - ", "journal_label"] },
					}}
				/>

				<Row>
					<Col className="mb-10">
						<label className="label_class">Map Journal with either Field or Algorithm.</label>
					</Col>
				</Row>

				<Form.Item label="Map with">
					<Form.Item name="map_with" initialValue="Field" noStyle className="radio_group_full">
						<Radio.Group buttonStyle="solid" disabled>
							<Radio.Button value="Field">Field</Radio.Button>
							<Radio.Button value="Algorithm">Algorithm</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Tooltip title="Debit and Credit are fields used in Transaction Models and Journal Entries. Debit represents a value taken out and Credit represents a value received. Debits are displayed on the left side of the Transaction Model and in Reports. Credits are displayed on the right side of the Transaction Model and in Reports. ">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>

				{
					mapWith === "Field" ?
						<Form.Item label="Field">
							<Form.Item name="field_id" noStyle rules={vsmJournalLines.validation.field_id}>
								<Select disabled showSearch placeholder="Select Field"
									className="text_overflow"
									optionFilterProp="children"
									onChange={handleChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onFocus={() => fetchField && getAllFieldList(props.data).then(() => setFetchField(false))}
									notFoundContent={fetchField ? <Spin size="small" /> : "No Record Found."}
									allowClear
								>
									{
										(field_list && field_list.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.field_name}</Select.Option>
										)))
									}
								</Select>
							</Form.Item>
							<Tooltip title="Transaction Fields are a set of available variables related to a Transaction that can be assigned to a Journal Model Line value.">
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						</Form.Item>
						:
						<Form.Item label="Algorithm">
							<Form.Item name="algorithm_id" noStyle rules={vsmJournalLines.validation.algorithm_id}>
								<Select disabled showSearch placeholder="Select Algorithm"
									optionFilterProp="children"
									onChange={handleChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onFocus={() => fetchAlgo && getAllAlgorithmList().then(() => setFetchAlgo(false))}
									notFoundContent={fetchAlgo ? <Spin size="small" /> : "No Record Found."}
									allowClear
								>
									{
										(algorithm_list && algorithm_list.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.algorithm_name}</Select.Option>
										)))
									}
								</Select>
							</Form.Item>
							<Tooltip title="Algorithm is a tool for building a finite sequence of defined mathematical calculations optionally embedded within logical expressions. Algorithms are used in Transaction Models to calculate values for Journal Entries.">
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						</Form.Item>
				}

				<Form.Item label="Use as">
					<Form.Item name="line_type" initialValue={"Debit"} noStyle className="radio_group_full">
						<Radio.Group disabled buttonStyle="solid">
							<Radio.Button value="Debit">Debit</Radio.Button>
							<Radio.Button value="Credit">Credit</Radio.Button>
						</Radio.Group>
					</Form.Item>
					<Tooltip title="Debit and Credit are fields used in Transaction Models and Journal Entries. Debit represents a value taken out and Credit represents a value received. Debits are displayed on the left side of the Transaction Model and in Reports. Credits are displayed on the right side of the Transaction Model and in Reports. ">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>
			</Form>
		</Modal>
	) : null
})

export default EditComponent
