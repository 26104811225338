import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Col, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import useStore from '../../../../store'
import { vsmOrgBankAccount, vsmNotify } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrgBankAccountStore: { AddData, bankNameList, dropdown_bank_type_list, dropdown_currency_list, getBanksNameList, getBankAccountTypeList, getCurrencyList } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fetchBankNames, setFetchBankNames] = useState(true)
	const [fetchBankTypes, setFetchBankTypes] = useState(true)
	const [fetchCurrencies, setFetchCurrencies] = useState(true)
	const [currentTab, setCurrentTab] = useState("account")
	const { id } = useParams()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.organisation_id = id
		AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmOrgBankAccount.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
		}).catch(e => {
			if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// set field vaule null based on tab selection
	const changeMapwith = async () => {
		await setCurrentTab(form.getFieldValue("tab"))
		form.setFieldsValue({
			account_number: null,
			// sort_code: null,
			iban: null,
			swift_bic: null
		})
		handleChange()
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setCurrentTab('account')
		setFetchBankTypes(true)
		setFetchCurrencies(true)
		setFetchBankNames(true)
	}

	return (
		<Modal
			destroyOnClose={true}
			centered
			title="Add New Bank Account"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout='vertical'
				onChange={handleChange}
			>
				<InputComponent
					required allowClear type="autocomplete" label="Bank Name" name="bank_name" placeholder="Bank Name" onChange={handleChange}
					options={bankNameList}
					tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
					rules={vsmOrgBankAccount.validation.bank_name}
					onFocus={() => fetchBankNames && getBanksNameList().then(() => setFetchBankNames(false))}
					notFoundContent={fetchBankNames ? <Spin size="small" /> : "No Record Found."}
				/>
				<InputComponent
					required type="select" allowClear label="Bank Account Type" name="bank_account_type_id" placeholder="Select Bank Account Type" onChange={handleChange}
					options={{
						values: dropdown_bank_type_list,
						value_key: 'id',
						text_key: 'name'
					}}
					tooltip="A name or code given to a cash account that indicates the account's purpose."
					onFocus={() => fetchBankTypes && getBankAccountTypeList().then(() => setFetchBankTypes(false))}
					notFoundContent={fetchBankTypes ? <Spin size="small" /> : "No Record Found."}
				/>
				<InputComponent
					required type="select" allowClear label="Currency" name="currency_id" placeholder="Select Currency" onChange={handleChange}
					options={{
						values: dropdown_currency_list,
						value_key: 'id',
						text_key: 'currency_code'
					}}
					tooltip="Currency is a medium of exchange for goods and services."
					rules={vsmOrgBankAccount.validation.currency}
					onFocus={() => fetchCurrencies && getCurrencyList().then(() => setFetchCurrencies(false))}
					notFoundContent={fetchCurrencies ? <Spin size="small" /> : "No Record Found."}
				/>
				<Col>
					<InputComponent
						type="radio_button" name="tab" initialValue="account"
						options={{
							values: [
								{ value: 'account', text: 'Account Number' },
								{ value: 'iban', text: 'IBAN Number' }
							]
						}}
					/>
				</Col>
				{
					currentTab === "account" ?
						<>
							<InputComponent
								required label="Account Number" name="account_number" placeholder="Account Number" onChange={handleChange}
								tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
								rules={vsmOrgBankAccount.validation.account_num}
								maxLength={50}
							/>
							{/* <InputComponent
								required label="Sort Code" name="sort_code" placeholder="Sort Code" onChange={handleChange}
								tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
								rules={vsmOrgBankAccount.validation.sort_code}
								maxLength={50}
							/> */}
						</>
						:
						<>
							<InputComponent
								required label="IBAN" name="iban" placeholder="International Bank Account Number" onChange={handleChange}
								tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
								rules={vsmOrgBankAccount.validation.iba_num}
							/>
						</>
				}
				<InputComponent
					required label="Swift or BIC Code" name="swift_bic" placeholder="Swift or BIC Code" onChange={handleChange}
					tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
					rules={vsmOrgBankAccount.validation.swift_or_bic_code}
					maxLength={50}
				/>
			</Form>
		</Modal>
	)
})

export default AddComponent
