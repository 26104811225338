import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmAlgorithm, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AlgorithmsStore: { DeleteData, deleteValues } } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to delete exixting record
	const handleSubmit = (data) => {
		setSaving(true)
		DeleteData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmAlgorithm.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to delete
	useEffect(() => {
		if (deleteValues) {
			form.setFieldsValue({
				id: deleteValues.id
			})
		}
	}, [deleteValues, form])

	const close = () => {
		form.resetFields()
		props.close()
	}

	return deleteValues ? (
		<Modal
			title={`Delete Algorithm - #${deleteValues.id} - ${deleteValues.algorithm_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			centered
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				<Form.Item name="id" style={{ display: 'none' }}>
					<Input type="hidden" />
				</Form.Item>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to remove this Algorithm?</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
