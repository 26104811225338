import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../../config/LocalGridConfig'
import useStore from '../../../../../../store'
import { vsmCommon } from '../../../../../../config/messages'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal } = props
	const { TransactionSubTypeStore, TransactionSubTypeStore: { setupGrid, list_data, getList, onFilterChanged } } = useStore()

	useEffect(() => {
		getList()
	}, [getList])

	let columns = []
	let columnConfig = localStorage.getItem('transaction_subtype_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openDeleteModal
				}}
				onColumnResized={TransactionSubTypeStore.onGridChanged}
				onColumnMoved={TransactionSubTypeStore.onGridChanged}
				onColumnPinned={TransactionSubTypeStore.onGridChanged}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={TransactionSubTypeStore.onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
