import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class LedgerStore {
	list_data = null
	all_data = []
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	agGrid = null
	unpublishValues = null

	global_organisations_list = null
	ledger_admin_list = null
	archive = false
	organisation_values = null
	user = null
	clone_ledger_list = null

	initialValues = {
		select_clone_data: []
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set organisation values
	setOrganisationValues = (data) => {
		this.organisation_values = data
	}

	// set form values to enable
	setUnpublishValues = (data) => {
		this.unpublishValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('ledger_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('ledger_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get all global organisation list
	getAllGlobalOrganisationList = (payload) => {
		return Axios.post(`ledgers/getGlobalOrganisations`, payload).then(({ data }) => {
			this.global_organisations_list = data.data
			return data
		}).catch((data) => {
			this.global_organisations_list = null
			return Promise.reject(data)
		})
	}

	// call api to get ledger admin users
	getAllLedgerAdminList = () => {
		return Axios.get(`ledgers/getUsers`).then(({ data }) => {
			this.ledger_admin_list = data.data
			return data
		}).catch((data) => {
			this.ledger_admin_list = null
			return Promise.reject(data)
		})
	}

	// call to filter archive/unarchive data
	changeArchiveValue = (value) => {
		this.archive = value
		this.list_data = []
		this.all_data.forEach((item, index) => {
			if (this.archive === true) {
				if (item.deleted_at !== null) {
					this.list_data.push(item)
				}
			} else {
				if (item.deleted_at === null) {
					this.list_data.push(item)
				}
			}
		});
	}

	addUser = (user_data) => {
		this.user = user_data
		this.ledger_admin_list.filter(x => x.id === 0 ? false : true)
		this.ledger_admin_list.push(user_data)
	}

	// call api to get records
	getList = (payload = {}) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
		}
		return Axios.post(`ledgers/list`, payload).then(({ data }) => {
			var temp_data = []
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.published = item.status ? "Active" : "Deactive"
					if (item.ledger_admin !== null) {
						item.ledger_admin_name = item.ledger_admin.first_name + " " + item.ledger_admin.last_name + " (" + item.ledger_admin.email + ")"
					} else {
						item.ledger_admin_name = null
					}
					if (item.organisation !== null) {
						item.organisation_name = item.organisation.organisation_name
					} else {
						item.organisation_name = null
					}
					if (this.archive === true) {
						if (item.deleted_at !== null) {
							temp_data.push(item)
						}
					} else {
						if (item.deleted_at === null) {
							temp_data.push(item)
						}
					}
					if (this.agGrid) {
						this.agGrid.api.setFilterModel(filter)
					}
				});
			}
			this.list_data = temp_data
			this.all_data = data.data
			let columnConfig = localStorage.getItem('ledger_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			}
			return data
		})
	}

	// call to get record for particular organisation ID
	getRecordById = (formdata) => {
		return Axios.get(`organisations/read/${formdata.id}`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api for proxy login
	doProxyLogin = (formdata) => {
		return Axios.get(`ledgers/proxyLogin/${formdata.id}`).then(({ data }) => {
			localStorage.setItem('previous_token', localStorage.getItem('token'))
			localStorage.setItem('token', data.access_token)
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`ledgers/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call api to check legder admin validations
	AddAdminData = (formdata) => {
		return Axios.post(`ledgers/addLedgerAdmin`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`ledgers/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call restore api
	RestoreData = (formdata) => {
		return Axios.post(`ledgers/restore/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call archive api
	ArchiveData = (formdata) => {
		return Axios.post(`ledgers/softdelete/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call api to enable/disable data
	TogglepublishData = (formdata) => {
		return Axios.post(`ledgers/status/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data.errors)
		})
	}

	getCloneLedgerList = () => {
		return Axios.get(`ledgers/activeledgers`).then(({ data }) => {
			this.clone_ledger_list = data.data
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data.errors)
		})
	}

}

decorate(LedgerStore, {
	list_data: observable,
	all_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	organisation_values: observable,
	user: observable,
	unpublishValues: observable,
	agGrid: observable,
	archive: observable,
	global_organisations_list: observable,
	ledger_admin_list: observable,
	initialValues: observable,
	clone_ledger_list: observable,
	getList: action,
	addUser: action,
	EditData: action,
	setEditValues: action,
	setOrganisationValues: action,
	setUnpublishValues: action,
	getAllGlobalOrganisationList: action,
	getAllLedgerAdminList: action,
	RestoreData: action,
	ArchiveData: action,
	setupGrid: action,
	changeArchiveValue: action,
	setPageSize: action,
	TogglepublishData: action,
	doProxyLogin: action,
	onGridChanged: action,
})
