import React, { useState } from 'react'
import { observer } from "mobx-react";
import { Button, Card, PageHeader } from 'antd';
import ListComponent from "./ListComponent";
import { default as OrgBankAddComponent } from "../../../../../OrganisationManagement/BankAccounts/component/AddComponent";
import { default as OrgBankEditComponent } from "../../../../../OrganisationManagement/BankAccounts/component/EditComponent";
import AddComponent from "../../../../BankAccounts/component/AddComponent";
import EditComponent from "../../../../BankAccounts/component/EditComponent";
// import useStore from '../../../../../../store';
import { orgBanksPath, peopleBanksPath } from '../../../../../../utils/GlobalFunction'

const BankList = observer((props) => {
	const [addPplDrawer, setAddPplDrawer] = useState(false)
	const [addOrgDrawer, setAddOrgDrawer] = useState(false)
	const [editPplDrawer, setEditPplDrawer] = useState(false)
	const [editOrgDrawer, setEditOrgDrawer] = useState(false)
	const [accountId, setAccountId] = useState(null)
	// const { PeopleBankAccountStore, OrgBankAccountStore } = useStore()

	// set id on edit drawer
	const setId = (id) => setAccountId(id)

	// Open form for add new bank account organisation
	const openAddOrgModal = () => {
		setAddOrgDrawer(true)
	}

	// Close form for add new bank account organisation
	const closeAddOrgModal = () => setAddOrgDrawer(false)

	// Open form for edit existing bank account and set values to form organisation
	const openEditOrgModal = (data) => {
		setId(data.id)
		setEditOrgDrawer(true)
	}

	// Open form for edit bank account organisation
	const closeEditOrgModal = () => setEditOrgDrawer(false)

	// Open form for add new bank account People
	const openAddPplModal = () => {
		setAddPplDrawer(true)
	}

	// Close form for add new bank account People
	const closeAddPplModal = () => setAddPplDrawer(false)

	// Open form for edit existing bank account and set values to form People
	const openEditPplModal = (data) => {
		setId(data.id)
		setEditPplDrawer(true)
	}

	// Open form for edit bank account People
	const closeEditPplModal = () => setEditPplDrawer(false)

	// view all
	const viewAll = () => {
		window.open(`${props.currentTab === "people" ? peopleBanksPath : orgBanksPath}/${props.id}`, "_blank");
	}

	return (
		<>
			<Card className="mb-10">
				<PageHeader
					title="Banks"
					extra={[
						<Button key="1" onClick={viewAll}>View All</Button>,
						<Button key="2" onClick={() => { props.currentTab === "people" ? openAddPplModal() : openAddOrgModal() }}>New</Button>
					]}
				>
					<ListComponent currentTab={props.currentTab} openEditModal={props.currentTab === "people" ? openEditPplModal : openEditOrgModal} />
					<OrgBankAddComponent visible={addOrgDrawer} close={closeAddOrgModal} parent_id={props.id} handleViewDetailCall={props.handleViewDetailCall} />
					<OrgBankEditComponent visible={editOrgDrawer} close={closeEditOrgModal} parent_id={props.id} accountId={accountId} setId={setId} handleViewDetailCall={props.handleViewDetailCall} />
					<AddComponent visible={addPplDrawer} close={closeAddPplModal} parent_id={props.id} handleViewDetailCall={props.handleViewDetailCall} />
					<EditComponent visible={editPplDrawer} close={closeEditPplModal} parent_id={props.id} accountId={accountId} setId={setId} handleViewDetailCall={props.handleViewDetailCall} />
				</PageHeader>
			</Card>
		</>
	)
})

export default BankList
