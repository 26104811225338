import React from 'react'
import { Switch } from 'antd'
import useStore from '../../../../../store'

const SwitchComponent = (props) => {
	const { UserStore } = useStore()
	if (
		props.values.id === UserStore.user.default_ledger_id ||
		(
			UserStore.user && UserStore.user.user_role && UserStore.user.user_role.user_role_id !== 1 &&
			!UserStore.checkPermission(68, "status")
		)
	) {
		return (
			<div>
				<Switch
					size='small'
					disabled
					defaultChecked={props.values.status}
				/>
			</div>
		)
	} else {
		return (
			<div>
				<Switch
					size='small'
					defaultChecked={props.values.status}
					onChange={(val) => props.onSwitchChange(val, props.values)}
				/>
			</div>
		)
	}
}

export default SwitchComponent
