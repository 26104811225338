import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import useStore from '../../../../../../store'

export const ActionRenderer = (props) => {
	const { currentTab, openEditModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	var permissionId = currentTab === "people" ? 41 : 35

	return (
		<div className="action-column">
			{UserStore.checkPermission(permissionId, 'bank_accounts_edit') &&
				<Button title="Edit - Bank Accounts" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Bank Name',
		field: 'bank_name',
		width: 160
	}, {
		headerName: 'Account Type',
		field: 'account_type_name',
		width: 160
	}, {
		headerName: 'Currency',
		field: 'currency_code',
		width: 150
	}, {
		headerName: 'Account No. / IBAN',
		field: 'account_number',
		valueGetter: (params) => (params.data && params.data.account_number) ? params.data.account_number : params.data.iban,
		width: 170
	}, {
		headerName: 'Sort Code',
		field: 'sort_code',
		width: 160
	}, {
		headerName: 'Swift or BIC Code',
		field: 'swift_bic',
		width: 160
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
