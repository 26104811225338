import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Button, Form, Card, Upload, message, Row, Col, Spin, Progress } from 'antd'
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import useStore from '../../../../store'
import InputComponent from '../../../../components/InputComponent'
import { vsmCTEImport, vsmNotify } from '../../../../config/messages'
import sampleImport from '../../../../assets/CashTransactionsCheckFile.csv'
import sample_multiple_account_file from '../../../../assets/sample_multiple_account_file.csv'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const history = useHistory()
	const { CTEImportStore } = useStore()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loadingStatus, setloadingStatus] = useState(false)

	const close = () => {
		form.resetFields()
		setSaving(false)
		setDisabled(true)
		props.close()
	}

	const handleSubmit = (data) => {
		setSaving(true)
		const formData = new FormData();
		form.getFieldValue('file').fileList.forEach((item, index) => {
			formData.append('files[]', item.originFileObj);
		})
		formData.append('dragged_files', JSON.stringify(form.getFieldValue('dragged_files')));
		CTEImportStore.AddData(formData).then(async (data) => {
			if (data.import_ids && data.import_ids.length) {
				if (!data.is_more_then_5k) {
					await CTEImportStore.setImportedIds(data.import_ids)
					await fetchImportedDataStatus()
				} else {
					setSaving(false)
					vsmNotify.success({
						message: vsmCTEImport.fileTooBig
					})
					close()
				}
			} else {
				vsmNotify.success({
					message: vsmCTEImport.add
				})
				close()
			}
		}).catch(e => {
			if (e.errors) {
				form.setFields(e.errors)
				setSaving(false)
			}
		})
	}

	const fetchImportedDataStatus = () => {
		const interval = setInterval(() => {
			setloadingStatus(true)
			if (CTEImportStore.apiCallLimit <= 30) {
				let payload = {
					import_ids: CTEImportStore.store_import_ids
				}
				CTEImportStore.getImpotedDataStatus(payload).then((data) => {
					setloadingStatus(false)
					if (data.import_completed === 1) {
						setSaving(false)
						clearInterval(interval)
						history.push({
							pathname: '/reports/bank-import-summary',
							state: {
								isFromImport: true,
								importIds: JSON.stringify(CTEImportStore.store_import_ids)
							}
						})
					}
				}).catch(() => {
					setloadingStatus(false)
				})
			} else {
				setloadingStatus(false)
				clearInterval(interval)
				vsmNotify.error({
					message: vsmCTEImport.axiosLimit
				})
				close()
			}
		}, 30000);
	}

	// check for valid form values then accordingly make save button disable / enable
	const handlChange = (data) => {
		setDisabled(true)
		if (form.getFieldValue('file').fileList.length <= 0) { } else {
			if (form.getFieldValue('file').fileList.length <= 10) {
				form.validateFields().then((data) => {
					var error = false
					form.getFieldValue('file').fileList.forEach(item => {
						if (item.status !== 'done') {
							error = true
							return false
						}
					})
					if (!error) { setDisabled(false) }
				})
			} else {
				form.setFields([{ name: "file", errors: ["Maximum 10 Files are allowed"] }])
			}
		}
	}

	const beforeUpload = ({ file, onSuccess, onError }) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 50;
		setTimeout(() => {
			if (!isCSV) {
				message.error('You can only upload CSV file!')
				onError('You can only upload CSV file!')
				return true;
			} else if (!isLt20M) {
				message.error('Please upload CSV file with size of less then 50 MB.')
				onError('Please upload CSV file with size of less then 50 MB.')
				return true;
			} else {
				onSuccess("ok")
				return false
			}
		}, 0)
	}

	const handleFileChange = async (info) => {
		var infoFile = info.fileList
		var tempValue = []
		if (infoFile && infoFile.length > 0) {
			infoFile.map((item, index) => {
				var bankName = null
				var tempFileName = item.name.replace('.csv', '')
				if (tempFileName.includes('Multiple') || tempFileName.includes('multiple')) {
					bankName = CTEImportStore.new_bank_list && CTEImportStore.new_bank_list.find(item => item.id === 0)
				} else {
					bankName = CTEImportStore.new_bank_list && CTEImportStore.new_bank_list.find((field) => {
						return field.bankAccount_names.toUpperCase().endsWith(tempFileName.split('-')[0].toUpperCase().trim())
					})
				}

				var tempBankName = null
				if (form.getFieldValue('dragged_files') && form.getFieldValue('dragged_files')[index] && form.getFieldValue('dragged_files')[index]['bank_id']) {
					tempBankName = form.getFieldValue('dragged_files')[index]['bank_id']
				} else {
					tempBankName = bankName ? bankName.id : undefined
				}

				tempValue.push({
					file_name: item.name + '-' + index,
					bank_id: tempBankName
				})
				return null
			})

		}
		await form.setFields([{ name: "file", errors: [] }])
		await form.setFieldsValue({ dragged_files: tempValue })
		handlChange()
	}

	const renderStatusView = (fileName) => {
		return (
			<>
				<span>
					<h3><NumberFormat value={fileName.imported_transactions} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} /></h3>
					<h3>{fileName.percentage_count}%</h3>
				</span>
				<Progress percent={fileName.percentage_count} width={25} showInfo={false} size="small" />
			</>
		)
	}

	const findFileStatus = (fileName) => {
		let file = CTEImportStore.imported_data_status.find(data => data.import_name === fileName)
		if (file) {
			return renderStatusView(file)
		} else {
			return null
		}
	}

	useEffect(() => {
		if (props.visible) {
			CTEImportStore.getNewBankList()
		}
	}, [CTEImportStore, props])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'50%'}
			title='Import Cash Transaction'
			destroyOnClose={true}
			footer={[
				<div className="text-center" key="div">
					<Button key="1" form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled} onClick={handleSubmit}>Start Import in Background</Button>
					<Button key="2" onClick={close}>Close</Button>
				</div>
			]}
		>
			<Card>
				<Form form={form}
					labelCol={{ span: 6 }}
					id="addform"
					onFinish={handleSubmit}
				>
					<div className='draggerList'>
						<div className="text-right">
							<a className='mr-15' href={sample_multiple_account_file}>Sample Multiple Account Import</a>
							<a href={sampleImport}>Sample Single Account Import</a>
						</div>
						<Form.Item className="full-width-dragger w-100" name='file' required extra={[
							<div>
								<label className='mt-5 d-flex'>
									<InfoCircleOutlined className='mt-5' />
									<div className='pl-10'>
										<span style={{ display: 'block', paddingBottom: '5px' }}>Make sure file contain account number in start to automatically map it with bank account</span>
										<span style={{ display: 'block' }}>For Import File name use format like "YYYY-MM-DD Bank Report". Replace YYYY-MM-DD with today's date</span>
									</div>
								</label>
							</div>
						]} tooltip='To start your import easily you can drag CSV file or you can upload file directly choosing from files.'>
							<Upload.Dragger
								accept=".csv"
								name='file'
								multiple={true}
								onChange={handleFileChange}
								customRequest={beforeUpload}
								showUploadList={{ showRemoveIcon: true }}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">Drag your CSV file here or select by clicking here</p>
							</Upload.Dragger>
						</Form.Item>
					</div>

					<Form.List name='dragged_files'>
						{
							(fields) => {
								return fields && fields.map((field, index) => {
									return (
										<>
											<Row gutter={10}>
												<Col span={12}>
													<label>Rename Import File {index + 1}</label>
												</Col>

												<Col span={12}>
													<div className='statusBar'>
														{CTEImportStore.imported_data_status && form.getFieldValue("dragged_files") && form.getFieldValue("dragged_files")[index] && form.getFieldValue("dragged_files")[index]["bank_id"] === 0 ? (
															loadingStatus ? (<Spin spinning={loadingStatus} size='small' />) : (
																findFileStatus(form.getFieldValue("dragged_files")[index]["file_name"])
															)
														) : null}
													</div>
												</Col>
											</Row>
											<Row gutter={10}>
												<Col span={12}>
													<InputComponent
														required name={[field.name, 'file_name']}
														placeholder="Give your Import Name - Default will be Filename + Timestamp"
														onChange={handlChange}
														tooltip="Give some name to import to identify it later on. By default it will take filename + current timestamp combination."
														rules={vsmCTEImport.validation.file_name}
														maxLength={100}
														autoComplete="off"
													/>
												</Col>
												<Col span={12}>
													<InputComponent
														required placeholder="Select Bank Account"
														type="select"
														allowClear
														options={{
															values: CTEImportStore.new_bank_list,
															text_key: "bankAccount_names",
															value_key: "id",
														}}
														name={[field.name, 'bank_id']}
														key={[field.key, 'bank_id']}
														onChange={handlChange}
														tooltip="A name or code given to a cash account that indicates the account's purpose."
														rules={vsmCTEImport.validation.bank_name}
														maxLength={100}
														autoComplete="off"
													/>
												</Col>
											</Row>
										</>
									)
								})
							}
						}
					</Form.List>
				</Form>
			</Card>
		</Drawer >
	)
})

export default AddComponent
