import React from 'react'
import { observer } from 'mobx-react'
import { vsmPplBankAccount } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'
import { Col, Form, Spin } from 'antd'
import useStore from '../../../../store'

const FormComponent = observer(({ onFinish, onChange, form, currentTab, disableTab }) => {

	const { PeopleBankAccountStore, OrgBankAccountStore } = useStore()

	return (
		<Form form={form} id="addeditform" onFinish={onFinish}
			layout='vertical'
			onChange={onChange}
		>
			<InputComponent
				required allowClear type="autocomplete" label="Bank Name" name="bank_name" placeholder="Bank Name" onChange={onChange}
				options={OrgBankAccountStore.bankNameList}
				tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
				rules={vsmPplBankAccount.validation.bank_name}
				onFocus={() => PeopleBankAccountStore.fetchBankNames && OrgBankAccountStore.getBanksNameList().then(() => PeopleBankAccountStore.fetchBankNames = false)}
				notFoundContent={PeopleBankAccountStore.fetchBankNames ? <Spin size="small" /> : "No Record Found."}
				disabled={PeopleBankAccountStore.editValues}
			/>
			<InputComponent
				required type="select" allowClear label="Bank Account Type" name="bank_account_type_id" placeholder="Select Bank Account Type" onChange={onChange}
				options={{
					values: OrgBankAccountStore.dropdown_bank_type_list || [{
						id: PeopleBankAccountStore.editValues && PeopleBankAccountStore.editValues.bank_accounts_type && PeopleBankAccountStore.editValues.bank_accounts_type.id,

						name: PeopleBankAccountStore.editValues && PeopleBankAccountStore.editValues.bank_accounts_type && PeopleBankAccountStore.editValues.bank_accounts_type.name
					}],
					value_key: 'id',
					text_key: 'name'
				}}
				tooltip="A name or code given to a cash account that indicates the account's purpose."
				onFocus={() => PeopleBankAccountStore.fetchBankTypes && OrgBankAccountStore.getBankAccountTypeList().then(() => PeopleBankAccountStore.fetchBankTypes = false)}
				notFoundContent={PeopleBankAccountStore.fetchBankTypes ? <Spin size="small" /> : "No Record Found."}
				disabled={PeopleBankAccountStore.editValues}
			/>
			<InputComponent
				required type="select" allowClear label="Currency" name="currency_id" placeholder="Select Currency" onChange={onChange}
				options={{
					values: OrgBankAccountStore.dropdown_currency_list || [{
						id: PeopleBankAccountStore.editValues && PeopleBankAccountStore.editValues.currency && PeopleBankAccountStore.editValues.currency.id,
						currency_code: PeopleBankAccountStore.editValues && PeopleBankAccountStore.editValues.currency && PeopleBankAccountStore.editValues.currency.currency_code
					}],
					value_key: 'id',
					text_key: 'currency_code'
				}}
				tooltip="Currency is a medium of exchange for goods and services."
				rules={vsmPplBankAccount.validation.currency}
				onFocus={() => PeopleBankAccountStore.fetchCurrencies && OrgBankAccountStore.getCurrencyList().then(() => PeopleBankAccountStore.fetchCurrencies = false)}
				notFoundContent={PeopleBankAccountStore.fetchCurrencies ? <Spin size="small" /> : "No Record Found."}
				disabled={PeopleBankAccountStore.editValues}
			/>
			<Col>
				<InputComponent
					type="radio_button" name="tab" initialValue="account"
					disabled={disableTab}
					options={{
						values: [
							{ value: 'account', text: 'Account Number' },
							{ value: 'iban', text: 'IBAN Number' }
						]
					}}
				/>
			</Col>
			{
				currentTab === "account" ?
					<>
						<InputComponent
							required label="Account Number" name="account_number" placeholder="Account Number" onChange={onChange}
							tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
							rules={vsmPplBankAccount.validation.account_num}
							maxLength={50}
						/>
						{/* <InputComponent
								required label="Sort Code" name="sort_code" placeholder="Sort Code" onChange={onChange}
								tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
								rules={vsmPplBankAccount.validation.sort_code}
								maxLength={50}
							/> */}
					</>
					:
					<>
						<InputComponent
							required label="IBAN" name="iban" placeholder="International Bank Account Number" onChange={onChange}
							tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
							rules={vsmPplBankAccount.validation.iba_num}
						/>
					</>
			}
			<InputComponent
				required label="Swift or BIC Code" name="swift_bic" placeholder="Swift or BIC Code" onChange={onChange}
				tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
				rules={vsmPplBankAccount.validation.swift_or_bic_code}
				maxLength={50}
			/>
		</Form>
	)
})

export default FormComponent
