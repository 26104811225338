import React, { useState } from 'react'
import { observer } from "mobx-react";
import { Button, Card, PageHeader } from 'antd';
import ListComponent from "./ListComponent";
import AddComponent from "../../../../../TradeTransaction/AllTransactions/component/AddComponent";
import EditComponent from "../../../../../TradeTransaction/AllTransactions/component/EditComponent";
import ViewComponent from "../../../../../TradeTransaction/AllTransactions/component/ViewComponent";
import DeleteComponent from "../../../../../TradeTransaction/AllTransactions/component/DeleteComponent";
import useStore from '../../../../../../store';
import { allTransactionPath } from '../../../../../../utils/GlobalFunction'

const TradeTransactionList = observer((props) => {
	const [transactionId, setTransactionId] = useState(null)
	const [addDrawer, setAddDrawer] = useState()
	const [editDrawer, setEditDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { AllTransactionStore } = useStore()

	const setId = (id) => setTransactionId(id)

	// open drawer for add
	const openAddDrawer = () => setAddDrawer(true)

	// close drawer for add
	const closeAddDrawer = () => setAddDrawer(false)

	// open drawer for edit
	const openEditDrawer = (id) => {
		setId(id)
		setEditDrawer(true)
	}

	// close drawer for edit
	const closeEditDrawer = () => setEditDrawer(false)

	// open drawer for view
	const openViewDrawer = (id) => {
		setId(id)
		setViewDrawer(true)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// Open confirmation alert before deleting existing transactions
	const openDeleteModal = (data) => {
		AllTransactionStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// view all
	const viewAll = () => {
		let id = props.currentTab === "people" ? `${props.id}:P` : `${props.id}:O`
		localStorage.setItem("transaction_related_org_filter", id)
		window.open(`${allTransactionPath}`, "_blank");
	}

	return (
		<>
			<Card className="mb-10">
				<PageHeader
					title="Trade Transactions"
					extra={[
						<Button key="1" onClick={viewAll}>View All</Button>,
						<Button key="2" onClick={openAddDrawer}>New</Button>
					]}
				>
					<ListComponent openEditDrawer={openEditDrawer} openViewDrawer={openViewDrawer} openDeleteModal={openDeleteModal} />
					<AddComponent visible={addDrawer} close={closeAddDrawer} handleViewDetailCall={props.handleViewDetailCall} />
					<EditComponent transactionId={transactionId} setId={setId} visible={editDrawer} close={closeEditDrawer} handleViewDetailCall={props.handleViewDetailCall} />
					<ViewComponent transactionId={transactionId} setId={setId} visible={viewDrawer} close={closeViewDrawer} handleViewDetailCall={props.handleViewDetailCall} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} handleViewDetailCall={props.handleViewDetailCall} />
				</PageHeader>
			</Card>
		</>
	)
})

export default TradeTransactionList
