import { Button, Form, Modal, Radio, Row, Col, Input, Avatar, Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { vsmFXCurrencies, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store';

const ExchangeAPI = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [loading, setLoading] = useState(false)
	const [value, setValue] = useState()
	const [type, setType] = useState()
	const { FXCurrenciesStore } = useStore()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.id = FXCurrenciesStore.exchangeRateValues.id
		data.type = data.type ? data.type : "predefine"
		data.current_base_value = data.type === "manual" ? data.current_base_value : data.fx_rate_provider_id.current_rate
		data.current_base_value = data.current_base_value && data.current_base_value.replace(/,/g, '')
		data.fx_rate_provider_id = data.type === "manual" ? 1 : data.fx_rate_provider_id.id
		setSaving(true)
		FXCurrenciesStore.AddExchangeRateData(data).then(() => {
			close()
			if (props.currentTab === "status") { props.openActivateModal() }
			vsmNotify.success({
				message: vsmFXCurrencies.exchange_rate
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to bulk edit
	useEffect(() => {
		setLoading(true)
		let value = FXCurrenciesStore.exchangeRateValues
		if (props.visible && value) {
			FXCurrenciesStore.getExchangeRates({ currency_code: value.currency_code }).then((data) => {
				setValue(data)
				setLoading(false);
			}).catch(() => setLoading(false))
		}
	}, [props.visible, FXCurrenciesStore, form])

	const handleChange = (data) => {
		setType(form.getFieldValue("type"))
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setValue(null)
		setType(null)
		props.setTab()
	}

	return FXCurrenciesStore.exchangeRateValues ? (
		<Modal
			centered
			title={`Configure Exchange Rate for ${FXCurrenciesStore.exchangeRateValues.currency_code}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='exchangeform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
			width={600}
		>
			<Form form={form} id="exchangeform"
				onFinish={handleSubmit}
				onChange={handleChange}
				layout="vertical"
			>
				<Spin size="small" spinning={loading} tip={"Loading"}>
					<Form.Item className="w-100" required label="Select Predefined Currency" tooltip="There will be different foreign currency exchange rate API providers so user can select any provider which will fetch exchange rate daily against base Ledger currency.">
						<Form.Item className="w-100" name="fx_rate_provider_id">
							<Radio.Group className="w-100" onChange={() => form.resetFields(["type", "current_base_value"])}>
								{value && value.map((val, index) => {
									return val && (
										<Radio className="exchange__radio_wrapper" key={index} value={val}>
											<Row className="w-100" justify="space-between" align='middle'>
												<Col flex={1}>
													<Row gutter={10} align='middle'>
														<Col><Avatar className="exchange_image" shape="square" size="large" src={val.logo} /></Col>
														<Col><div>{val.description}</div></Col>
													</Row>
												</Col>
												<Col>
													<div>{val.current_rate}</div>
													<small><b>Latest {FXCurrenciesStore.exchangeRateValues.currency_code} Rate</b></small>
												</Col>
											</Row>
										</Radio>
									)
								})}
							</Radio.Group>
						</Form.Item>
					</Form.Item>

					<Form.Item className="w-100" required label="Select Manual Currency" tooltip="User can add manual exchange rate against the base Ledger currency.">
						<Form.Item className="w-100" name="type">
							<Radio.Group className="w-100" onChange={() => form.setFieldsValue({ fx_rate_provider_id: null })}>
								<Radio className="exchange__radio_wrapper" value={"manual"}>
									Add Manual 1 EUR =
									<Form.Item name="current_base_value" noStyle rules={type ? vsmFXCurrencies.validation.manual_currency : [{ required: false }]}>
										<Input
											disabled={!type}
											placeholder="Add Exchange Rate"
											style={{ width: 200, marginLeft: 10 }}
										/>
									</Form.Item>
									<div className="pl-10">{FXCurrenciesStore.exchangeRateValues.currency_code}</div>
								</Radio>
							</Radio.Group>
						</Form.Item>
					</Form.Item>
				</Spin>
			</Form>
		</Modal>
	) : null
})

export default ExchangeAPI
