import { Form, PageHeader, Row, Col, Button, Spin } from 'antd'
import React, { useState, useEffect, useCallback } from 'react'
import InputComponent from '../../../components/InputComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './Components/ListComponent'
import { vsmTransactionReport } from '../../../config/messages'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import moment from 'moment'
import DateSelectionElement from '../../Reports/SavedReports/elements/DateSelectionElement'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'



const FxGainlossChildReport = observer(() => {
	const [form] = Form.useForm()
	const { UserStore, FxGainLossChildStore, FxGainLossChildStore: { getFYear, getList, fYear, list_data, bankAccList, getBankAccList } } = useStore()
	const typeChange = ['generate_on', 'audit_group_on']
	const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)
	const [formDataList, setFormDataList] = useState([])
	const [disabled, setDisabled] = useState(true)
	const [DateRange, SetDateRange] = useState([]);
	const [saving, setSaving] = useState(false)
	const [bankAcc, setBankAcc] = useState(true)
	const [fyYear, setFYYear] = useState(true)
	const [childData, setChildData] = useState({
		financial_year: fYear[0]?.value,
		generate_on: 'financial_year',
		report_type: 'fx',
		action: 'preview_report',
		cte_bank_accounts: ['all']
	})


	
	const getFormDataList = useCallback(() => {
		var FormDataList = []
		FormDataList = [...FormDataList, ...typeChange]
		switch (form.getFieldValue('generate_on')) {
			case 'date_range':
				FormDataList = [...FormDataList, 'date_selection']
				switch (form.getFieldValue('date_selection')) {
					case 'range':
						FormDataList = [...FormDataList, 'date_range']
						break;
					case 'single':
						FormDataList = [...FormDataList, 'date']
						break;
					case 'auto':
						FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
						break;
					default:
				}
				break;
			case 'financial_year':
				FormDataList = [...FormDataList, 'financial_year']
				if (!GENERATEONFINANCIAL) {
					SETGENERATEONFINANCIAL(true)
					getFYear()
				}
				break;
			default:
		}
		return FormDataList
	}, [GENERATEONFINANCIAL, getFYear, form, typeChange])


	useEffect(() => {
		if (list_data) {
			setSaving(false)
		}
	}, [list_data, form])

	const handleSubmit = (data) => {
		FxGainLossChildStore.list_data = null
		setChildData(data)
		setSaving(true)
		if (data.date_range && data.date_range.length) {
			var payload = {
				from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
				to_date: moment(data.date_range[1]).format('YYYY-MM-DD')
			}
			data.date_range = payload
			data.date_selection = 'range'
		}

		data.report_type = 'fx'
		data.action = 'preview_report'
		var form_value = form.getFieldValue('cte_bank_accounts')
		if (form_value === undefined) {
			data.cte_bank_accounts = ['all']
		}
		getList(data).then(() => {
		}).catch((e) => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		setSaving(true)
		getList(childData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const disabledDate = current => {
		if (!DateRange || DateRange.length === 0) { return false }
		return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
	}
	const handleChange = async () => {

		await setFormDataList(getFormDataList())
		form.validateFields().then((data) => {

			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	const handleClear = (e) => {
		var data = form.getFieldsValue(true)
		data.cte_bank_accounts = ['all']
		handleSubmit(data)
	}

	useEffect(() => {
		var data = getFormDataList()
		if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
			setFormDataList(data)
		}
	}, [getFormDataList, setFormDataList, formDataList])
	return (
		<div>
			<BreadcrumbComponent items={BreadcrumbConfig.BankAuditReport.path} />
			<PageHeader
				key={'2'}
				className={UserStore.fullscreen_class + ' query_bank remove-error-text'}
				title={BreadcrumbConfig.fx_gainlossReport.title}
				extra={[
					<div style={{ width: '1340px' }} key='1'>
						<Form
							style={{ display: 'block' }}
							layout='inline'
							form={form}
							id='formdata'
							onFinish={handleSubmit}
							initialValues={{
								financial_year: fYear[0]?.value,
								generate_on: 'financial_year',
								action: 'preview_report',
								report_type: 'fx',
								audit_group_on: 'day',
								date_selection: 'range',
								date_x_value: 2,
								date_x_value_type: 'day',
							}}
						>
							<Row>
								<Col>
									<InputComponent
										style={{ width: '235px' }}
										required
										allowClear
										mode="multiple"
										type="select"
										label="Bank Account"
										name="cte_bank_accounts"
										onFocus={() => bankAcc && getBankAccList().then(() => setBankAcc(false))}
										notFoundContent={bankAcc ? <Spin size="small" /> : "No Record Found."}
										onChange={handleChange}
										maxTagCount={1}
										onClear={handleClear}
										maxTagTextLength={12}
										placeholder="All"
										tooltip="Select Bank Account"
										// value={selectedItems}
										// rules={vsmQueryBankVsCash.validation.bank_acc}
										options={{
											values:bankAccList,
											value_key: 'id',
											text_key: { key: ["name", " - ", "account_number"] },
										}}
									/>
								</Col>
								{formDataList.includes('generate_on') && (
									<Col>
										<InputComponent
											required
											type='radio_button'
											name='generate_on'
											label='Generate On'
											options={{
												values: [{ value: 'all', text: 'All Data' }, { value: 'date_range', text: 'Date Range' }, { value: 'financial_year', text: 'Financial Year' }, { value: 'periods', text: 'Period' }],
												rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
											}}
											onChange={handleChange}
											tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
											rules={vsmTransactionReport.validation.generate_on}
										/>
									</Col>

								)}
								{(formDataList.includes('date_selection')) && (
									<DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} tab={'inQuery'} />
								)}
								{(formDataList.includes('financial_year')) && (
									<Col>
										<InputComponent
											type="select" label="Financial Year" name="financial_year"
											placeholder={"Select Financial Year"}
											tooltip="Select Financial Year"
											onChange={handleChange}
											onFocus={() => fyYear && getFYear().then(() => setFYYear(false))}
											notFoundContent={fyYear ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: fYear,
												value_key: 'value',
												text_key: 'text',
											}}
										/>
									</Col>
								)}
								<Button disabled={disabled} loading={saving} form="formdata" htmlType="submit" type="primary">Submit</Button>
							</Row>
						</Form>

					</div>
				]}
			>

				{list_data ? <ListComponent /> :
					<p style={{ position: 'absolute', top: '300px', fontSize: '20px', textAlign: 'center', width: '100%', height: 'calc(100% - 30px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
						Please select Generated On then click on Submit to see the report.
					</p>}
			</PageHeader>
		</div>
	)
})

export default FxGainlossChildReport