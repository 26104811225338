import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import LocalGridConfig from '../../../../config/LocalGridConfig'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal, onSwitchChange, openExchangeAPIModal, openViewHistory } = props
	const { UserStore, FXCurrenciesStore: { getList, setupGrid, onFilterChanged, list_data, onGridChanged } } = useStore()
	var columnDefs = {}
	if (UserStore.user && UserStore.user.user_role.user_role_id === 1) {
		columnDefs = gridOptions.columnDefs.filter((x) => x.visibleToLedger !== 1)
	} else {
		columnDefs = gridOptions.columnDefs.filter((x) => x.visibleToSuper !== 1)
	}

	if (!UserStore.checkPermission(55, 'status')) {
		columnDefs = gridOptions.columnDefs.filter(x => x.headerName !== 'Status')
	}

	useEffect(() => {
		getList(UserStore.user)
	}, [getList, UserStore])

	let columns = []
	let columnConfig = localStorage.getItem('ppl_role_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openDeleteModal, openEditModal, openExchangeAPIModal, openViewHistory
				}}
				onGridReady={setupGrid}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onFilterChanged}
				onSwitchChange={onSwitchChange}
				suppressRowClickSelection={true}
				rowSelection={'multiple'}
			/>
		</div>
	)
})

export default ListComponent
