import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class PeopleRoleStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	deleteValues = null
	agGrid = null
	relates_roles = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('ppl_role_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('ppl_role_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	getFilter = (params) => {
		if (localStorage.getItem('params')) {
			var temp = JSON.parse(localStorage.getItem('params'))
			if (temp.ADMIN_PEOPLE_ROLE) {
				params = { "request": temp.ADMIN_PEOPLE_ROLE }
			}
		}
		return params
	}

	setFilter = (param) => {
		if (localStorage.getItem('params')) {
			var temp = JSON.parse(localStorage.getItem('params'))
			localStorage.setItem("params",
				JSON.stringify({ ...temp, "ADMIN_PEOPLE_ROLE": { "filter": param.getFilterModel(), "sort": param.getSortModel() } })
			)
		} else {
			localStorage.setItem("params",
				JSON.stringify({ "ADMIN_PEOPLE_ROLE": { "filter": param.getFilterModel(), "sort": param.getSortModel() } })
			)
		}
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		this.setFilter(params.api)
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay()
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay()
		}
	}

	getRelatedRoles = () => {
		return Axios.get(`people/role/list`).then(({ data }) => {
			this.relates_roles = data.data

			return data
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		return Axios.get(`people/role/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (data.data.length === 1) {
						item.relatedRoleEnabled = false
					} else {
						item.relatedRoleEnabled = true
					}
					item.updated_by = item.user && item.user.first_name + ' ' + item.user.last_name
					if (item.associated_role_names) {
						item.associated_role = item.associated_role_names.map(x => x.role_name)
					}
				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let params = this.getFilter(this.agGrid)
			if (params && params.request) {
				this.agGrid.api.setSortModel(params.request)
				this.agGrid.api.setFilterModel(params.request)
			}
			return data
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`people/role/add`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`people/role/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (role_id) => {
		return Axios.get(`people/role/delete/${role_id}`).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ImportPeopleRolesFile = (formdata) => {
		const options = {
			headers: { Accept: '' },
		}
		return Axios.post(`people/role/bank/import`, formdata, options).then(({ data }) => {
			this.getList()
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(PeopleRoleStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	agGrid: observable,
	relates_roles: observable,
	onFilterChanged: action,
	setPageSize: action,
	setupGrid: action,
	getList: action,
	EditData: action,
	setEditValues: action,
	DeleteData: action,
	setDeleteValues: action,
	getRelatedRoles: action,
	ImportPeopleRolesFile: action,
	onGridChanged: action,
})
