import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form, Spin } from 'antd'
import ListComponent from './ListComponent'
import { default as JournalLogsComponent } from './JournalLines/JournalLogsComponent'
import useStore from '../../../../../store'
import SuggestionComponent from '../Suggestion'
import DetailTableComponent from './DetailTable/DetailTableComponent'

const ReconcileComponent = observer((props) => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(true)
	const [suggestionModal, setSuggestionModal] = useState(false)
	const [detailValues, setDetailValues] = useState(null)
	const [currentValues, setCurrentvalues] = useState(null)
	const { ReconciliationStore, ReconciliationStore: { selectedIds, agGrid, agGridInner, getRecordById } } = useStore()

	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			let formData = { cte_transaction_ids: selectedIds }
			getRecordById(formData).then((data) => {
				setCurrentvalues(data.data)
				setDetailValues(data.addition_data)
				setLoading(false)
			}).catch((data) => {
				setCurrentvalues([])
				setLoading(false)
			})
		}

	}, [getRecordById, selectedIds, props.visible])

	// open drawer for view
	const openSuggestionDrawer = () => setSuggestionModal(true)

	// close drawer for view
	const closeSuggestionDrawer = () => setSuggestionModal(false)

	// reset form and close filter form
	const close = () => {
		props.close()
		form.resetFields()
		if (agGrid) { agGrid.api.deselectAll() }
		if (agGridInner) { agGridInner.api.deselectAll() }
		setCurrentvalues(null)
		setDetailValues(null)
		ReconciliationStore.journalLogs = null
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Reconcile Cash Transaction - #${selectedIds}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<DetailTableComponent list_data={detailValues} />
				<h3 className="text-center mt-20">Search and Select on Row of Trade Transaction</h3>
				<ListComponent list_data={currentValues} openViewModal={props.openViewDrawer} />
				<SuggestionComponent visible={suggestionModal} close={closeSuggestionDrawer} />
				<div className="mb-25 mt-25">
					<h3>
						<span>Journal Logs :</span>
					</h3>
					<JournalLogsComponent currentView={"verify"} openViewModal={props.openViewDrawer} closeViewDrawer={props.closeViewDrawer} openParentTransViewDrawer={props.openTransViewDrawer} openSuggestionDrawer={openSuggestionDrawer} />
				</div>
			</Spin>
		</Drawer>
	)
})

export default ReconcileComponent
