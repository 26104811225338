import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react"
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import PageNotFound from '../../PageNotFound'
import { Button, Form, PageHeader, Typography, Spin, Tooltip } from 'antd'
import useStore from '../../../store'
import CreateFormFields from '../../../components/CreateFormFields'
import { Link } from 'react-router-dom'
import { vsmGlobalSetting, vsmNotify } from '../../../config/messages'
import { InfoCircleOutlined } from '@ant-design/icons'

const GlobalSetting = observer(() => {

	const { UserStore, GlobalSettingStore } = useStore()
	const [form] = Form.useForm()
	const [loader, setLoader] = useState(true)
	const [saving, setSaving] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [fields, setFields] = useState()

	const handleSubmit = (data) => {
		setSaving(true)
		GlobalSettingStore.AddData(data).then(() => {
			setLoader(true)
			setDisabled(true)
			GlobalSettingStore.ReadData().then((data) => {
				setFields(data)
				setLoader(false)
			})
			vsmNotify.success({
				message: vsmGlobalSetting.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const handleChange = (data) => {
		form.validateFields().then(() => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	useEffect(() => {
		setLoader(true)
		GlobalSettingStore.ReadData().then((data) => {
			setFields(data)
			setLoader(false)
		})
	}, [GlobalSettingStore])

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Globak_Setting.path} />
			{(!UserStore.checkPermission(85, 'update')) ? (<PageNotFound />) : (
				<PageHeader
					className="has_export_spinner no_content_padding"
					title={<div>{BreadcrumbConfig.Globak_Setting.title} <Tooltip title="Settings are Global configuration or values. Which will be visible or used when needed.">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip></div>}>
					<Typography.Text type="secondary">Settings are Global configuration or values. Which will be visible or used when needed.</Typography.Text>

					<Spin spinning={loader}>
						<Form
							layout="vertical"
							form={form}
							className="mt-10"
							onFinish={handleSubmit}>
							<CreateFormFields fields={fields} onChange={handleChange} />
							<div>
								{UserStore.checkPermission(85, 'update') && <Button type='primary' htmlType='submit' loading={saving} disabled={disabled}>Save</Button>}
								<Button className='ml-10'><Link to='/dashboard'>Cancel</Link></Button>
							</div>
						</Form>
					</Spin>

				</PageHeader>
			)}
		</>
	)
})

export default GlobalSetting
