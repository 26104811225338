import React, { useState } from 'react'
import { Divider, Layout, Menu, Tooltip, Popover } from 'antd';
import useStore from '../store';
import { observer } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../img/logo.png';
import mobileLogo from '../img/mobile-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faExchangeAlt, faSitemap, faFileAlt, faCogs, faCircle, /* faPlusCircle */ } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faUser, faStar, faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons'
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, QuestionCircleOutlined, UpOutlined } from '@ant-design/icons';
const { Sider } = Layout;


const SidebarComponent = observer((props) => {
	const location = useLocation()

	let history = useHistory()
	const { UserStore: { ledger, menu, user, ledger_base_currency, changeLedger, doLogout, /* permissions */ } } = useStore()

	const [openMenu, setOpenMenu] = useState([])
	const [collapsed, setCollapsed] = useState(false)
	let menukey = 1

	// handle switch ledger
	const handleChangeLedger = (id) => {
		let formdata = {
			ledger_id: id
		}
		changeLedger(formdata)
	}

	// call logout function
	const Logout = () => {
		doLogout()
		history.replace('/')
	}

	const icons = {
		1: faTachometerAlt,
		20: faExchangeAlt,
		34: faSitemap,
		40: faUser,
		46: faFileAlt,
		54: faUserCircle,
		60: faCogs,
		74: faMoneyBillAlt,
		"0_0": faStar
	}

	// iterate the menu items
	const AppMenu = (menu_item, open = []) => {
		return menu_item ? Object.keys(menu_item)
			.sort((a, b) => (menu_item[a].sequence_no > menu_item[b].sequence_no) ? 1 : ((menu_item[b].sequence_no > menu_item[a].sequence_no) ? -1 : 0))
			.map((key) => {
				let item = menu_item[key]
				if (item.submenu) {
					return (
						<Menu.SubMenu key={item.title + menukey} icon={
							<FontAwesomeIcon icon={icons[item.id] ? icons[item.id] : faCircle} />
						} title={item.title}>
							{AppMenu(item.submenu, [...open, (item.title + menukey++)])}
						</Menu.SubMenu>
					)
				} else {
					if (user && user.user_role && user.user_role.user_role_id !== 1 && [].includes(item.path)) {
						return null
					}
					if (location.pathname.includes(item.path) && openMenu.length <= 0) {
						if (open.length === 0) {
							open.push(item.path)
						}
						setOpenMenu(open)
					}
					return (
						<Menu.Item key={item.path} icon={<FontAwesomeIcon icon={icons[item.id] ? icons[item.id] : faCircle} size="xs" className="mr-10" />}>
							<span>{item.title}</span>
							{(ledger_base_currency) && <Link to={item.path} />}
						</Menu.Item>
					)
				}
			}) : null
	}

	// Bottom menu
	const userMenu = (
		<Menu className="header_user_ledger_menu">
			<Menu.ItemGroup title="Current Ledger">
				{
					(ledger && ledger.map((item, index) => (
						(user.default_ledger_id === item.ledger_id) ? (
							<Menu.Item onClick={() => { handleChangeLedger(item.ledger_id, item.name) }} key={index} value={item.ledger_id} icon={!item.logo_url ? item.logo_url : faCircle}>
								<div className="d-flex">
									<div className="ledger_profile_photo" style={{ backgroundImage: `url(${item.logo_url})` }}></div>
									<div>
										<div>{item.name}</div>
										<div><small>{item.role_name}</small></div>
									</div>
								</div>
							</Menu.Item>
						) : null
					)))}
			</Menu.ItemGroup>

			{(ledger && ledger.length > 1) ? (
				<Menu.ItemGroup title="Ledger List">
					{(ledger && ledger.map((item, index) => (
						(user.default_ledger_id !== item.ledger_id) ? (
							<Menu.SubMenu onClick={() => { handleChangeLedger(item.ledger_id, item.name) }} key={index} value={item.ledger_id}>
								<div className="d-flex">
									<div className="ledger_profile_photo" style={{ backgroundImage: `url(${item.logo_url})` }}></div>
									<div>
										<div>{item.name}</div>
										<div><small>{item.role_name}</small></div>
									</div>
								</div>
							</Menu.SubMenu>
						) : null
					)))}
				</Menu.ItemGroup>
			) : null}

			<Divider style={{ margin: 7 }} />
			<Menu.Item key="logout" onClick={Logout}>
				{(localStorage.getItem('previous_token')) ? 'Back To SuperAdmin' : ('Logout')}
			</Menu.Item>
		</Menu>
	)
	return (
		<>
			<Sider width="280" trigger={null} collapsible collapsed={collapsed} className="sider_area">
				{ledger_base_currency ? (<Link to="/dashboard" className="logo">
					{collapsed ? <img width={30} src={mobileLogo} alt="" /> : <img src={logo} alt="" />}
				</Link>) : (<div className="logo mobile">{collapsed ? <img width={30} src={mobileLogo} alt="" /> : <img src={logo} alt="" />}</div>)}
				<div className="collapased_sidebar_menu">
					{(collapsed) ? (
						<MenuUnfoldOutlined className="trigger" onClick={() => { setCollapsed(false) }} />
					) : (
						<MenuFoldOutlined className="trigger" onClick={() => { setCollapsed(true) }} />
					)}
				</div>
				<Menu theme="dark" mode="inline" defaultOpenKeys={openMenu} selectedKeys={location.pathname}>
					{AppMenu(menu)}
					<div className='bottom_nav'>
						<Menu.Item>
							<Link to="/user-manual" className="first-step">
								<Tooltip title="Credebt Machine - User Manual"><QuestionCircleOutlined style={{ fontSize: '20px', color: '#fff' }} /></Tooltip></Link>
						</Menu.Item>
						<Popover content={userMenu} placement="topRight" className='p-0'>
							<Menu.Item className='d-flex w-100'>
								<UserOutlined style={{ fontSize: '18px', color: '#fff', lineHeight: '47px' }} />
								{(user && user.first_name && <span>{user.first_name} </span>)}
								{(user && user.last_name && <span>&nbsp;{user.last_name}</span>)}
								<div className='plus_icon'>
									<UpOutlined style={{ fontSize: '14px', verticalAlign: 'middle' }} />
								</div>
							</Menu.Item>
						</Popover>
					</div>
				</Menu>
			</Sider>
		</>
	)
})

export default SidebarComponent
