import React from 'react'
import { DeleteOutlined, EditOutlined, BankOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../store'
import { DateComparator } from '../../../../utils/GlobalFunction'
import { useParams } from 'react-router-dom'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal, openMapBankAccount } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	const { id } = useParams()
	return (
		<div className="action-column">
			{UserStore.checkPermission(41, 'role_mapping_edit') &&
				<Button title="Edit Role Mapping" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			}
			{(!UserStore.checkPermission(41, 'role_mapping_delete')) ? null : (
				(props.data.association_count > 0) ? (
					<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot delete this Role as transactions are associated. Please remove transactions manually to delete Role mapping."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					<Button title="Delete Role Mapping" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
				)
			)}
			{(parseInt(id) === props.data.entity_id) &&
				<Button title="Add Bank Accounts" type="text" onClick={() => { openMapBankAccount(props.data) }}><BankOutlined /></Button>
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Organisation / People Name',
		field: 'entity_name',
		valueGetter: (params) => (params.data && params.data.entity_type === "People") ? (params.data.people && (params.data.people.first_name + (params.data.people.last_name ? (" " + params.data.people.last_name) : ''))) : (params.data.organisation && params.data.organisation.organisation_name),
		width: 185
	}, {
		headerName: 'Role',
		field: 'role_name',
		valueGetter: (params) => (params.data && params.data.entity_type === "People") ? (params.data.people_role && params.data.people_role.role_name) : (params.data.role && params.data.role.role_name),
		filter: 'agSetColumnFilter',
		filterParams: { defaultToNothingSelected: true },
		width: 185
	}, {
		headerName: 'Related Organisation / People Name',
		field: 'related_entity_name',
		valueGetter: (params) => (params.data && params.data.related_entity_type === "People") ? (params.data.related_people && (params.data.related_people.first_name + (params.data.related_people.last_name ? (" " + params.data.related_people.last_name) : ''))) : (params.data.related_organisation && params.data.related_organisation.organisation_name),
		width: 185
	}, {
		headerName: 'Related Role',
		field: 'related_role_name',
		valueGetter: (params) => (params.data && params.data.related_entity_type === "People") ? (params.data.peoplemerge_role && params.data.peoplemerge_role.role_name) : (params.data.merge_role && params.data.merge_role.role_name),
		filter: 'agSetColumnFilter',
		filterParams: { defaultToNothingSelected: true },
		width: 185
	}, {
		headerName: 'Tags',
		field: 'tag_names',
		valueGetter: (params) => (params.data && params.data.tags_names) ? params.data.tags_names.map((item) => { return item }) : "",
		width: 150
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		width: 185
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
