import React, { useState } from 'react'
import { observer } from "mobx-react";
import FormComponent from "./FormComponent";
import { Button, Drawer, Form } from 'antd';
import useStore from '../../../../../store';
import { vsmNotify, vsmTrigger } from '../../../../../config/messages';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TriggerStore, TransactionFieldMappingStore, AllTransactionStore } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [formDataList, setFormDataList] = useState([])
	const [fetchMapping, setFetchMapping] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [value, setValue] = useState('0 0 * * *')

	// Make function call to add new record
	const handleSubmit = (data) => {
		setSaving(true)
		if (formDataList.includes('time_schedule')) {
			data.time_schedule = value
		}
		data.selected_models = data.selected_models && data.selected_models.filter(x => x.model === true)
		TriggerStore.AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmTrigger.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setFetchMapping(true)
		form.setFieldsValue({ selected_models: [] })
		TransactionFieldMappingStore.dropdown_list = null
		TriggerStore.currentModals = []
		AllTransactionStore.status_dropdown = null
		props.close()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Add New Trigger`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled || TriggerStore.loader} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				visible={props.visible}
				handleSubmit={handleSubmit}
				formDataList={formDataList}
				setFetchMapping={setFetchMapping}
				fetchMapping={fetchMapping}
				fetchStatus={fetchStatus}
				setFetchStatus={setFetchStatus}
				setFormDataList={setFormDataList}
				setDisabled={setDisabled}
				setValue={setValue}
				value={value}
			/>
		</Drawer>
	)
})

export default AddComponent
