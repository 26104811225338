import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, DatePicker, Radio, Input, Select, Card, Divider, Drawer, Spin, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { vsmNotify, vsmTransactionModels } from '../../../../../config/messages'
import JournalLines from './JournalLines';
import moment from 'moment';

const { RangePicker } = DatePicker
const currentDate = new Date()
const lastMonthDate = new Date()
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore, TransactionModelStore: { EditData, getRecordById, dropdown_type_list, getJournalLinesList, array_list, setArrayList, getAllMappingList, setFieldMappingList } } = useStore()
	const [saving, setSaving] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [mappingId, setMappingId] = useState(null)
	const [errorMessage, setErrorMessage] = useState(array_list === null || array_list.length === 0)
	const [editValues, setEditValues] = useState(null)
	const [tip, setTip] = useState('Loading')
	const [fetchType, setFetchType] = useState(true)
	const [isNewFields, setNewFields] = useState(false)
	const [isDate, setDate] = useState(false)

	// Made a fuction to call to edit record
	const handleSubmit = (data) => {
		let mapping_name = dropdown_type_list.find((item) => item.id === data.type_field_mapping_id)
		data.journal_lines = array_list
		data.mapping_name = mapping_name
		data.total_journal_lines = array_list.length
		data.id = editValues.id
		data.delete_ids = TransactionModelStore.deleted_list
		if (data.applied_on === 1) {
			data.from_date = form.getFieldsValue().date_range[0].format("YYYY-MM-DD")
			data.to_date = form.getFieldsValue().date_range[1].format("YYYY-MM-DD")
		}
		setSaving(true)
		setTip('Saving')
		EditData(data).then(() => {
			vsmNotify.success({
				message: vsmTransactionModels.edit
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setTip(null)
		})
	}

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			let check = dropdown_type_list && dropdown_type_list.find(item => item.id === editValues.type_field_mapping_id)
			form.setFieldsValue({
				type_field_mapping_id: check ? editValues.type_field_mapping_id : null,
				model_code: editValues.model_code,
				model_name: editValues.model_name,
				model_desc: editValues.model_desc,
				date_range: editValues.date_range,
			})
			setMappingId(editValues.type_field_mapping_id)
			setNewFields(false)
		}
	}, [editValues, form, dropdown_type_list, setDisabled, props.visible])

	// set data on refresh page
	useEffect(() => {
		if (props.id && props.visible) {
			setArrayList(null)
			getJournalLinesList({ model_id: props.id })
			getRecordById({ id: props.id }).then((data) => {
				let mapping = data.data.transaction_type_field_mapping
				TransactionModelStore.dropdown_type_list = mapping && [mapping]
				setEditValues(data.data)
				setSaving(false)
				setTip(null)
			}).catch(() => {
				vsmNotify.error({
					message: vsmTransactionModels.InvalidRecord
				})
				setArrayList(null)
				setFieldMappingList()
				props.close()
			})
		}
	}, [props, TransactionModelStore, getRecordById, setFieldMappingList, setArrayList, getJournalLinesList])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = useCallback(async () => {
		setMappingId(form.getFieldsValue().type_field_mapping_id)
		form.validateFields().then((d) => {
			if (!isNewFields || (form.getFieldsValue().confirm && form.getFieldsValue().confirm.toLowerCase() === "confirm")) {
				setDisabled(false)
			} else {
				setDisabled(true)
			}
		}).catch(d => {
			setErrorMessage(true)
			setDisabled(true)
		})
	}, [isNewFields, form])

	// Set the form values to edit journal
	useEffect(() => {
		if (isNewFields && props.visible) {
			handleChange()
		}
	}, [isNewFields, handleChange, props.visible])

	// handle Date Picker visibility
	const handleDatePicker = (event) => {
		if (event.target.value === 1) {
			setDate(true)
		} else {
			setDate(false)
		}
	}

	// Reset form and close edit form
	const close = () => {
		props.close()
		setArrayList(null)
		form.resetFields()
		setDisabled(true)
		setFieldMappingList()
		setSaving(true)
		setTip('Loading')
		setFetchType(true)
		setNewFields(false)
		setDate(false)
		TransactionModelStore.deleted_list = []
	}

	return (
		<>
			<Drawer
				title={`Edit Transaction Model - #${editValues && editValues.id} - ${editValues && editValues.model_code} - ${editValues && editValues.transaction_type_field_mapping ? editValues && editValues.transaction_type_field_mapping.mapping_name : ""}`}
				visible={props.visible}
				onClose={close}
				placement='right'
				width={'75%'}
				destroyOnClose={true}
				footer={[
					<div className="text-center" key="1">
						<Button form='editform' disabled={disabled || (array_list ? array_list.length <= 0 : true) || (form.getFieldsValue().type_field_mapping_id ? false : true)} className="mr-10" loading={saving} htmlType="submit" type="primary">Save</Button>
						<Button onClick={close}>Cancel</Button>
					</div>
				]}
			>
				<Spin size="large" spinning={saving} tip={tip}>
					<Card>
						<Form
							form={form}
							id="editform"
							onFinish={handleSubmit}
							layout='vertical'
							onChange={handleChange}
						>
							<Row gutter={24}>
								<Col span={8}>
									<Form.Item name="type_field_mapping_id" rules={vsmTransactionModels.validation.mapping_name} label="Trade Transaction" required tooltip='Trade Transaction is Trade Type, Transaction Type and Trade Transaction. The mapping helps to group set of models together and execute Transactions against these models.'>
										<Select showSearch placeholder="Select Trade Transactio"
											optionFilterProp="children"
											onChange={handleChange}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onFocus={() => fetchType && getAllMappingList().then(() => setFetchType(false))}
											notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
											allowClear
										>
											{
												(dropdown_type_list && dropdown_type_list.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.mapping_name}</Select.Option>
												)))
											}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="model_code" rules={vsmTransactionModels.validation.model_code} label="Code" required tooltip='Code (of a Transaction Model) is an mandatory parameter of a Transaction Model. Code is unique and alphanumerical.'>
										<Input id="model_code" placeholder="Code" maxLength={10} />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="model_name" rules={vsmTransactionModels.validation.model_name} label="Model Name" required tooltip='Transaction Model name is a mandatory parameter of a Transaction Model. Model Name must be unique. Transaction Model is a tool for creating Models, that are defined through the use of multiple Model Lines.'>
										<Input id="model_name" placeholder="Model Name" maxLength={255} />
									</Form.Item>
								</Col>
							</Row>

							<Form.Item name="model_desc" rules={vsmTransactionModels.validation.model_description} label="Model Description" required tooltip='Model Description is used to further describe the Model details and its purpose.'>
								<Input.TextArea placeholder="Model Description" maxLength={1000} />
							</Form.Item>

							<JournalLines currentView={"edit"} data={mappingId} errorMessage={errorMessage} setErrorMessage={setErrorMessage} handleChange={handleChange} setNewFields={setNewFields} />
							<Divider />

							{(isNewFields) ? (
								<div className="mt-15">
									<Form.Item initialValue={2} name="applied_on">
										<Radio.Group className="ml-auto mr-auto" buttonStyle="solid" onChange={handleDatePicker}>
											<Radio.Button key="1" value={0}>Apply to all past data</Radio.Button>
											<Radio.Button key="2" value={1}>Apply to data in specific date range</Radio.Button>
											<Radio.Button key="3" value={2}>Apply to New Data only</Radio.Button>
										</Radio.Group>
									</Form.Item>
									{(isDate) ? (
										<Form.Item name="date_range" initialValue={[moment(lastMonthDate), moment(currentDate)]}>
											<RangePicker onChange={handleChange} className="ml-auto mr-auto" allowClear={false} />
										</Form.Item>
									) : (
										null
									)}
									<Form.Item name="confirm">
										<Input placeholder="Type 'CONFIRM' as this action cannot be undone." />
									</Form.Item>
								</div>
							) : (
								null
							)}
						</Form>
					</Card>
				</Spin>
			</Drawer>
		</>
	)
})

export default EditComponent
