import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class aETRReportsStore {
    list_data = null
    agGrid = null
    financial_year_list = null
    org_list = null
    batch_list = null
    formData = null
    internalChildStatement = null
    setting_value = null
    initialValues = {
        generate_on: 'all_unarchive_data',
        audit_group_on: 'day',
        date_selection: 'range',
        date_x_value: 2,
        date_x_value_type: 'day',
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        // Default sorting
        this.agGrid.columnApi.applyColumnState({
            state: [{ colId: 'traded_on', sort: 'desc' }],
            defaultState: { sort: null },
        });
        let columnConfig = this.setting_value && this.setting_value.data && this.setting_value.data
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: columnConfig })
        }
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('aETR_report_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params
        // this.setFilter(params.api)
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay();
        }
    }

    columnsConfig = (payload) => {
        return Axios.post('/report/table/grid/save', payload).then(({ data }) => {
            return data
        }).catch((err) => {
            // let errors = [];
            // Object.keys(data.errors).forEach((name) => {
            //     errors.push({ name, errors: data.errors[name] });
            // });
            // data.errors = errors;
            // return Promise.reject(data);
        });
    }

    getSetting = (code, payload) => {
        return Axios.post(`/report/get/table/grid/${code}`, payload).then(({ data }) => {
            this.setting_value = data
            return data.data
        }).catch((err) => {
            if (err) {
                // let errors = [];
                // Object.keys(data.errors).forEach((name) => {
                //     errors.push({ name, errors: data.errors[name] });
                // });
                // data.errors = errors;
                // return Promise.reject(data);
            }
        });
    }

    getFinancialYearList = () => {
        return Axios.get(`report/financial/year`).then(({ data }) => {
            this.financial_year_list = data.data
            return data
        })
    }

    getOrgList = () => {
        Axios.get(`report/a-etr/organisation/list`).then(({ data }) => {
            this.org_list = data.data.org
            return data.data.org
        })
    }

    getBatchListFromOrg = (orgID) => {
        var payload = {
            organisations: [orgID]
        }
        return Axios.post(`report/a-etr/batch/list`, payload).then(({ data }) => {
            this.batch_list = data.data.batches

            return data
        })
    }

    getaETRPreviewDetails = (payload) => {
        this.formData = payload
        return Axios.post(`report/a-etr/internal/statement`, this.formData).then(({ data }) => {
            data.data.pop()
            return data
        })
    }

    getaETRChildDetails = (data) => {
        let payload = { ...this.formData, ...data }
        return Axios.post(`report/a-etr/organisation/statement`, payload).then(({ data }) => {
            return data
        })
    }

    getInternalChildStatement = (data) => {
        let payload = { ...this.formData, ...data }
        return Axios.post(`/report/a-etr/internal/child/statement`, payload).then(({ data }) => {
            data.data.pop()
            data.data.forEach((item) => {
                item.etr_type = (item.etr_type === 1 || item.etr_type === 'fixed') ? 'a-ETR' : 'f-ETR'
            })
            this.internalChildStatement = data.data
            return data.data
        })
    }

    getOrganisationChildStatement = (data) => {
        let payload = { ...this.formData, ...data }
        return Axios.post(`/report/a-etr/organisation/child/statement`, payload).then(({ data }) => {
            data.data.pop()
            data.data.forEach((item) => {
                item.etr_type = (item.etr_type === 1 || item.etr_type === 'fixed') ? 'a-ETR' : 'f-ETR'
            })
            this.internalChildStatement = data.data
            return data.data
        })
    }
}

decorate(aETRReportsStore, {
    list_data: observable,
    agGrid: observable,
    initialValues: observable,
    financial_year_list: observable,
    org_list: observable,
    batch_list: observable,
    internalChildStatement: observable,
    setting_value: observable,
    setupGrid: action,
    onGridChanged: action,
    onFilterChanged: action,
    getaETRPreviewDetails: action,
    getFinancialYearList: action,
    getOrgList: action,
    getBatchListFromOrg: action,
    getInternalChildStatement: action,
    getSetting: action
})