import React from 'react'
import { CloseCircleOutlined, EyeOutlined, DownloadOutlined, ImportOutlined, DeleteOutlined, InfoCircleOutlined, UpCircleTwoTone } from '@ant-design/icons'
import { Button, Progress, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { DateComparator, globalStatus } from '../../../../utils/GlobalFunction'
import { Link, useHistory } from 'react-router-dom'
import useStore from '../../../../store'
import NumberFormat from 'react-number-format'

export const ActionRenderer = (props) => {
	const { openViewDrawer, openCancelModal, openDeleteModal, openVerifyDrawer } = props.agGridReact.props.frameworkComponents
	const { UserStore, ImportStore } = useStore()
	const history = useHistory()
	var disabledDelete = [6, 7].includes(props.data.status)
	var deleteButton = [3, 5].includes(props.data.status)

	const redirectTo = ((id) => {
		let ids = [id]
		history.push({
			pathname: '/reports/bank-import-summary',
			state: {
				isFromImport: true,
				importIds: JSON.stringify(ids)
			}
		})
	})
	return (
		<div className="action-column">
			{/* Go to VerifyScreen */}
			<Button title="View Import Summary" type="text" onClick={() => redirectTo(props.data.id)} disabled={![1, 2].includes(props.data.status)}><UpCircleTwoTone /></Button>
			{
				(props.data && !props.data.autoidentified_tr_id && !props.data.problematic_tr_id) ? (
					UserStore.checkPermission(76, 'edit') && (
						<Button title="Verify Transaction" type="text" disabled><FontAwesomeIcon className={!props.data.problematic_tr_id ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
					)
				) : (UserStore.checkPermission(76, 'edit') && (
					<Button title="Verify Transaction" type="text" onClick={() => { openVerifyDrawer(props.data) }}><FontAwesomeIcon className={!props.data.problematic_tr_id ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
				))
			}
			{UserStore.checkPermission(75, 'view') && (
				<Button title="View Logs" type="text" onClick={() => { openViewDrawer(props.data) }}><EyeOutlined /></Button>
			)}
			<Button title="Go to Imported Transactions" type="text"><Link to={`/cash-transactions/view-all/${props.data.id}`}><ImportOutlined /></Link></Button>
			<Button title="Download Import File with Log" type="text" onClick={() => ImportStore.downloadFile(props.data, 'cash')}><DownloadOutlined /></Button>
			{
				!disabledDelete && (
					<>
						{!deleteButton ?
							UserStore.checkPermission(75, 'delete') && (
								<Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
							)
							:
							UserStore.checkPermission(75, 'cancel') && (
								<Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openCancelModal(props.data) }}><CloseCircleOutlined /></Button>
							)}
					</>
				)
			}

		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerCheckboxSelection: true,
		headerCheckboxSelectionFilteredOnly: true,
		checkboxSelection: (params) => {
			return params.data.status === 1
		},
		filter: false,
		sortable: false,
		floatingFilter: false,
		width: 45,
		suppressMenu: true,
		pinned: 'left'
	}, {
		headerName: '# ID',
		field: 'id',
		headerTooltip: '# ID',
		tooltipField: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Import Name',
		headerTooltip: 'Import Name',
		field: 'import_name',
		tooltipField: 'import_name',
		width: 220
	}, {
		headerName: 'Bank',
		headerTooltip: 'Bank',
		field: 'bank_account',
		tooltipField: 'bank_account',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Account / IBAN',
		headerTooltip: 'Account / IBAN',
		field: 'bankAccount_number',
		tooltipField: 'bankAccount_number',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Imported By',
		headerTooltip: 'Imported By',
		field: 'imported_by',
		tooltipField: 'imported_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 100
	}, {
		headerName: 'Imported On',
		headerTooltip: 'Imported On',
		tooltipField: 'created_at',
		field: 'created_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],

			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 134
	}, {
		headerName: 'Transactions Count',
		headerTooltip: 'Transactions Count',
		field: 'total_transactions',
		filter: 'agSetColumnFilter',
		cellRendererFramework: function (data) {
			var newValue = data.data.total_transactions && data.data.total_transactions.split('/')
			return data.data && (
				<>
					<div className='progressBar'>
						<span>
							<h3><NumberFormat value={newValue && newValue.length > 0 && newValue[0]} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} /></h3>
							<h3>{data.data.total_transactions_percentage}%</h3>
						</span>
						<Progress percent={data.data.total_transactions_percentage} width={25} showInfo={false} size="small" />
					</div>
				</>
			)
		},
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150,
		height: 50
	}, {
		headerName: 'Status',
		headerTooltip: 'Status',
		field: 'status_text',
		filter: 'agSetColumnFilter',
		cellRendererFramework: function (data) {
			return (
				<span style={{ color: [5, 7].includes(data.data.status) ? 'red' : '' }}>{globalStatus('cash_import', 'key', data.data.status)}</span>
			)
		},
		filterParams: {
			defaultToNothingSelected: true,
		},
		tooltipValueGetter: function (data) {
			return globalStatus('cash_import', 'key', data.data.status)
		},
		width: 80
	}, {
		headerName: 'Duration',
		headerTooltip: 'Duration',
		field: 'run_time',
		tooltipValueGetter: function (data) {
			return data.data.run_time
		},
		cellRendererFramework: function (data) {
			return (
				data.data && (
					<div>
						<span>{data.data.run_time}</span>
						{data.data.exception && (
							<Tooltip title={data.data.exception}>
								<InfoCircleOutlined className="ml-10" size="small" />
							</Tooltip>
						)}
					</div>)
			)
		},
		width: 100
	}, {
		headerName: 'Actions',
		headerTooltip: "Actions",
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 200
	}]
}
