import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DateComparator } from '../../../../utils/GlobalFunction';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import useStore from '../../../../store';
import NumberFormat from 'react-number-format';
import PriviewChildAETRComponent from './PriviewChildAETRComponent';

const PreviewAETRDetailsComponent = observer((props) => {
    const { aETRReportsStore } = useStore()
    const [PriviewChildAETR, setPriviewChildAETR] = useState(false)
    const gridOptions = {
        columnDefs: [{
            headerName: '#',
            headerTooltip: 'SR#',
            field: '',
            filter: 'agNumberColumnFilter',
            width: 46,
            valueFormatter: function (params) {
                if (params.data.organisation !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            },
            tooltipValueGetter: function (params) {
                if (params.data.date !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            }
        }, {
            headerName: 'Organisation',
            headerTooltip: 'Organisation',
            field: 'organisation',
            tooltipField: 'organisation',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 150
        }, {
            headerName: 'Batch',
            headerTooltip: 'Batch',
            tooltipField: 'batch',
            field: 'batch',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 88
        }, {
            headerName: 'Type',
            headerTooltip: 'Type',
            field: 'etr_type',
            tooltipField: 'etr_type',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 56
        }, {
            headerName: 'Months',
            headerTooltip: 'Months',
            field: 'months',
            tooltipField: 'months',
            cellRendererFramework: (params) => (
                <div style={styles.cellsInt}>
                    <NumberFormat value={params.data.months} displayType={'text'} />
                </div>
            ),
            width: 90,
        }, {
            headerName: 'Traded',
            headerTooltip: 'Traded',
            tooltipField: 'traded_on',
            field: 'traded_on',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 95
        }, {
            headerName: 'Value',
            headerTooltip: 'Value',
            field: 'an_value',
            tooltipField: 'an_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.an_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 90
        }, {
            headerName: 'PTV',
            headerTooltip: 'PTV',
            field: 'ao_ltv',
            tooltipValueGetter: (params) => {
                return params.data.organisation !== 'Total' ? params.data.ao_ltv ? `${params.data.ao_ltv}%` : '100%' : null
            },
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.organisation !== 'Total' ? params.data.ao_ltv ? params.data.ao_ltv : '100' : null} suffix="%" displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={params.data.ao_ltv ? 2 : 1} />
                </div>
            ),
            width: 60
        }, {
            headerName: 'Face Value',
            headerTooltip: 'Face Value',
            field: 'face_value',
            tooltipField: 'face_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.face_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100
        }, {
            headerName: 'Deposit',
            headerTooltip: 'Deposit',
            field: 'aq_deposit',
            tooltipField: 'aq_deposit',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.aq_deposit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100
        }, {
            headerName: 'Ccy',
            headerTooltip: 'Ccy',
            field: 'currency',
            tooltipField: 'currency',
            filterParams: {
                defaultToNothingSelected: true,
            },
            cellStyle: { textAlign: 'center' },
            width: 50
        }, {
            headerName: 'Instalment',
            headerTooltip: 'Instalment',
            field: 'payment_each_month',
            tooltipField: 'payment_each_month',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.payment_each_month} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Principal',
            headerTooltip: 'Principal',
            field: 'principal_amt',
            tooltipField: 'principal_amt',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.principal_amt ? params.data.principal_amt : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Income',
            headerTooltip: 'Income',
            field: 'income_amt',
            tooltipField: 'income_amt',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.income_amt ? params.data.income_amt : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Profit',
            headerTooltip: 'Profit',
            field: 'profit',
            tooltipField: 'profit',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.profit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 90,
        }, {
            headerName: 'Receipts',
            headerTooltip: 'Receipts',
            field: 'income_value',
            tooltipField: 'income_value',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.income_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Outstanding',
            headerTooltip: 'Outstanding',
            field: 'outstanding',
            tooltipField: 'outstanding',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.outstanding} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Actions',
            headerTooltip: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            sortable: false,
            filter: false,
            pinned: 'right',
            hide: (props.type !== 'inStatement') ? true : false,
            width: 115,
        }]
    }

    const styles = {
        cells: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '50%'
        },
        cellsInt: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50%'
        }
    }

    const onCellClicked = (data) => {
        if (data.data.organisation !== 'Total' && props.type !== 'inStatement') {
            let payload = {
                organisations: data.data.org_id,
                batches: data.data.batch,
                tte_id: data.data.tte_id,
            }
            aETRReportsStore.getInternalChildStatement(payload).then(() => {
                setPriviewChildAETR(true)
            })
        }
    }

    const closePriviewChildAETR = (data) => {
        aETRReportsStore.internalChildStatement = null
        setPriviewChildAETR(false)
    }
    // props.rowData.pop()
    let list_data = props.rowData
    let pinnedBottomRowData = [];
    let totalValue = {
        organisation: "Total",
        face_value: 0,
        an_value: 0,
        aq_deposit: 0,
        income_value: 0,
        payment_each_month: 0,
        principal_amt: 0,
        outstanding: 0,
        profit: 0,
        income_amt: 0
    }
    let filterModel = aETRReportsStore.agGrid?.api?.getFilterModel();
    let isEmpty = filterModel ? Object.keys(filterModel).length === 0 : true;
    if (!isEmpty) {
        aETRReportsStore.agGrid && aETRReportsStore.agGrid.api.forEachNodeAfterFilter((rowNode, index) => {
            let currentObj = rowNode.data;
            totalValue['face_value'] += currentObj['face_value'];
            totalValue['aq_deposit'] += currentObj['aq_deposit'];
            totalValue['an_value'] += currentObj['an_value'];
            totalValue['income_value'] += currentObj['income_value'];
            totalValue['payment_each_month'] += currentObj['payment_each_month'];
            totalValue['principal_amt'] += currentObj['principal_amt'];
            totalValue['outstanding'] += currentObj['outstanding'];
            totalValue['profit'] += currentObj['profit'];
            totalValue['income_amt'] += currentObj['income_amt'];
        });
    }
    else {
        props.rowData && props.rowData.map((currentObj, index) => {
            totalValue['face_value'] += currentObj['face_value'];
            totalValue['aq_deposit'] += currentObj['aq_deposit'];
            totalValue['an_value'] += currentObj['an_value'];
            totalValue['income_value'] += currentObj['income_value'];
            totalValue['payment_each_month'] += currentObj['payment_each_month'];
            totalValue['principal_amt'] += currentObj['principal_amt'];
            totalValue['outstanding'] += currentObj['outstanding'];
            totalValue['profit'] += currentObj['profit'];
            totalValue['income_amt'] += currentObj['income_amt'];
            return null
        });
    }
    pinnedBottomRowData.push(totalValue)
    let columns = []
    let column = gridOptions.columnDefs
    let columnConfig = aETRReportsStore && aETRReportsStore.setting_value && aETRReportsStore.setting_value.data
    if (columnConfig && columnConfig !== 'null') {
        let columnData = columnConfig
        let cols = column.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        columnData.forEach(element => {
            cols = column.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = column
    }

    const processCellForClipboard = useCallback((params) => {
        if (typeof params.value === 'object') {
            if (params.value?.date) {
                return params.value?.date + ', ' + params.value?.value
            } else {
                return params.value?.value
            }
        } else {
            return params.value ? params.value : 0
        }
    }, []);

    useEffect(() => {
        let payload = {
            "request_type": "type"
        }
        aETRReportsStore.getSetting(101, payload)
    }, [aETRReportsStore])

    return aETRReportsStore.setting_value && (
        <div className="ag-theme-alpine grid_wrapper mt-10">
            <AgGridReact
                rowData={list_data}
                modules={AllModules}
                columnDefs={columns}
                defaultColDef={LocalGridConfig.defaultColDef}
                columnTypes={LocalGridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                onGridReady={aETRReportsStore.setupGrid}
                onColumnResized={aETRReportsStore.onGridChanged}
                onColumnMoved={aETRReportsStore.onGridChanged}
                gridOptions={LocalGridConfig.options}
                onFilterChanged={aETRReportsStore.onFilterChanged}
                onSortChanged={aETRReportsStore.onGridChanged}
                processCellForClipboard={processCellForClipboard}
                onCellDoubleClicked={onCellClicked}
                pinnedBottomRowData={pinnedBottomRowData}
            />
            {PriviewChildAETR ? <PriviewChildAETRComponent visible={PriviewChildAETR} close={closePriviewChildAETR} /> : null}
        </div>
    );
})

export default PreviewAETRDetailsComponent;