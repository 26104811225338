import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from '../../config/LocalGridConfig'

export default class RoleManagementStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	dropdown_role_list = []

	editValues = null
	deleteValues = null
	permission_list = null
	modifyPermissionValues = null
	agGrid = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('user_role_management_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('user_role_management_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get records
	getList = (payload = { ledger_id: 1 }) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.post(`role/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (item.users) {
						item.updated_by = item.users.first_name + '' + item.users.last_name
					}
				})
			}
			this.list_data = data.data
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
			return data
		})
	}

	//call api to get permissions list
	getPermissionsList = (formdata) => {
		return Axios.post('permission/list', formdata).then(({ data }) => {
			this.permission_list = data.data
			return data
		}).catch(() => {
			this.permission_list = null
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`role/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`role/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`role/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(RoleManagementStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	agGrid: observable,
	dropdown_role_list: observable,
	permission_list: observable,
	modifyPermissionValues: observable,
	getList: action,
	getPermissionsList: action,
	AddData: action,
	EditData: action,
	DeleteData: action,
	onGridChanged: action,
})
