import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form, Divider } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const TransactionDateElement = observer(({ onChange }) => {

	return (
		<div className="w-100">
			<Form.List name='transaction_dates'>
				{(fields, { add, remove }) => (
					fields.map((field, index) => {
						return (
							<div className="w-100" key={field.key}>
								{(index > 0) && (<Divider style={{ marginTop: -10 }}>OR</Divider>)}
								<Row gutter={10} className="no-wrap">
									<Col className="min-w100 max-w100">
										<InputComponent
											onChange={onChange} type="select" name={[field.name, 'transaction_date_type']} key={[field.key, 'transaction_date_type']}
											placeholder="Select Date Field"
											options={{
												values: [
													{ value: 'issued_on', text: 'Issued On' },
													{ value: 'posted_on', text: 'Posted On' },
													{ value: 'traded_on', text: 'Traded On' },
													{ value: 'settled_on', text: 'Settled On' },
													{ value: 'closed_on', text: 'Closed On' },
													{ value: 'forwarded_on', text: 'Forwarded On' },
												],
											}}
										/>
									</Col>
									<Col flex={1}>
										<InputComponent
											onChange={onChange}
											type="date_range" className="w-100"
											name={[field.name, 'transaction_date_value']} key={[field.key, 'transaction_date_value']}
											tooltip="Select date field and choose specific date to generate report"
										/>
									</Col>
									<Col className="d-flex">
										{(fields.length > 1) && (<Button type="danger" onClick={() => remove(field.name)} icon={<MinusOutlined />}></Button>)}
										{(index === (fields.length - 1)) && (<Button className="ml-5" type="primary" onClick={() => add()} icon={<PlusOutlined />}></Button>)}
									</Col>

								</Row>
							</div>
						)
					})
				)}
			</Form.List >
		</div>
	)
})

export default TransactionDateElement
