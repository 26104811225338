import React, { useState, useEffect } from 'react'
import { Form, Button } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../../store'
import { vsmTransactionItems, vsmNotify } from '../../../../../config/messages'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState()
	const { AllTransactionStore, TransactionItemsStore: { EditData, editValues } } = useStore()

	// Make function call to edit existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.transaction_id = editValues.transaction_id || null
		data.add_address = data.add_address ? 1 : 0

		Object.keys(props.itemPayload).forEach(item => {
			data[item] = props.itemPayload[item]
		})
		// For a-ETR Only
		if (data.asset_type) {
			data.asset_type = data.asset_type === 1 ? 'fixed' : 'current'
		}

		EditData(data).then(() => {
			close()
			if (props.handleApiCall) { props.handleApiCall() }
			vsmNotify.success({
				message: vsmTransactionItems.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			AllTransactionStore.vatRate_dropdown = editValues && [...AllTransactionStore.vatRate_dropdown, editValues.vat_rate]
			form.setFieldsValue({
				batch: editValues.batch,
				asset_number: editValues.asset_number,
				deposit_received: editValues.deposit_received,
				asset_type: editValues.asset_type,
				eot: editValues.eot,
				item_name: editValues.item_name,
				quantity: editValues.quantity ? editValues.quantity.toString() : null,
				unit_price: editValues.unit_price,
				value: editValues.value,
				rm_price: editValues.rm_price,
				serial_no: editValues.serial_no,
				classification: editValues.classification,
				description: editValues.description,
				manufacturer: editValues.manufacturer,
				site_number: editValues.site_number,
				tax_settings_id: editValues.tax_settings_id,
				tax_type_inc_exc: editValues.tax_type_inc_exc,
				dd_amount_exc_vat: editValues.dd_amount_exc_vat,
				address_1: editValues.address_1,
				address_2: editValues.address_2,
				address_3: editValues.address_3,
				city: editValues.city,
				state_county: editValues.state_county,
				postal_code: editValues.postal_code,
				country_id: editValues.country && editValues.country.id,
				add_address: editValues.add_address ? true : false
			})
		}
	}, [editValues, props.visible, AllTransactionStore, form])

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return editValues ? (
		<FormComponent
			tab={props.tab}
			form={form}
			form_id="editform"
			title={`Edit Transaction Items - #${editValues.id} ${editValues.asset_number ? "- " + editValues.asset_number : ''}`}
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			currentValues={editValues}
			formButtons={[
				<div key="1" className="d-flex justify-content-center" >
					<Button key="1" form='editform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div>
			]}
		/>
	) : null
})

export default EditComponent
