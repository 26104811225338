import React, { Component } from 'react'
import { Button, Card, Divider, Form, Input, Tooltip, Drawer, Affix } from 'antd'
import { observer } from 'mobx-react'
import { InfoCircleOutlined } from '@ant-design/icons';
import useStore from '../../../../../store';
import { vsmAlgorithm, vsmNotify } from '../../../../../config/messages';
import AlgorithmBuilder from '../../../../../components/AlgorithmBuilder';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { UI } from 'formulize'
import $ from 'jquery'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AlgorithmsStore } = useStore()

	class AddComponent extends Component {
		field_list = null

		constructor(props) {
			super(props)
			this.state = {
				formula: {
					component: 'Value',
					value: { component: "Custom" }
				},
				formula_status: 'error',
				saving: false,
				disabled: true,
				fields: [],
				field_list: this.props.AlgoStore.transaction_field_list
			}
		}

		// Handle submit and call function to save new record
		handleSubmit = (data) => {
			data.algorithm_function = "=trade_transaction.face_value*trade*100"
			data.algorithm_json = this.props.AlgoStore.setFormula(this.state.formula)
			data.used_tte_fields = this.state.fields
			data.used_algorithms = 1
			data.last_parse_result = "Success"
			data.status = true
			this.props.AlgoStore.AddData(data).then(() => {
				vsmNotify.success({
					message: vsmAlgorithm.add
				})
				this.close()
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			})
		}

		// check for valid form values then accordingly make save button disable/enable
		handleChange = () => {
			form.validateFields().then((data) => {
				this.setState({ disabled: false })
			}).catch(e => {
				this.setState({ disabled: true })
			})
		}

		// reset form and close edit form
		close = () => {
			this.props.close()
		}

		setFormula = (formula) => {
			var readable_format = this.props.AlgoStore.createReadableFormula(formula)
			form.setFieldsValue({
				readable_format: readable_format.formula
			})
			this.setState({
				formula,
				formula_status: readable_format.status,
				fields: readable_format.fields
			})
		}

		componentDidMount() {
			this.props.AlgoStore.getAllTransactionFields().then(data => {
				this.setState({ field_list: data })
				this.field_list = data
			})
			window.onmousemove = logMouseMove;

			function logMouseMove(event) {
				let e = event || window.event;
				window.x = e.clientX
				window.y = e.clientY
			}
			var temp_interval = setInterval(function () {
				if ($('.sticky_fields').length > 0) {
					clearInterval(temp_interval)
					var stickyTop = $('.sticky_fields').offset().top;

					$(window).scroll(function () {
						var windowTop = $(window).scrollTop();

						if (stickyTop < windowTop) {
							$('.sticky_fields').css('top', (windowTop - stickyTop) + 'px');
						} else {
							$('.sticky_fields').css('top', '0');
						}
					});
				}
			}, 200)
		}

		onDragEnd = (result) => {
			if (result.destination) {
				var target = $("[data-formulize='" + result.destination.droppableId + "']")
				if (target.length > 0) {
					const formulize = new UI(target, {})
					var position = null
					if ($(':hover').last().offset()) {
						position = {
							x: window.x - $(':hover').last().offset().left,
							y: window.y - ($(':hover').last().offset().top - $(document).scrollTop())
						};
					}
					var $element = $("[data-rbd-draggable-id='" + result.draggableId + "']").clone()
					$element.attr('style', '')
					formulize.insert($element[0], position)
					setTimeout(() => {
						var readable_format = this.props.AlgoStore.getParsedArithmeticFormula(formulize.getData())
						form.setFieldsValue({
							readable_format: readable_format
						})
					}, 100)
				}
			}
		}

		getItemStyle = (isDragging, draggableStyle) => ({
			// styles we need to apply on draggables
			...draggableStyle
		});

		// available field search function
		handleSearch = (event) => {
			if (this.field_list) {
				let data = this.field_list
				if (event.target.value) {
					const searchedData = data.filter(character => {
						return character.field_name.toLowerCase().includes(event.target.value.toLowerCase());
					});
					this.setState({ field_list: searchedData })
				} else {
					this.setState({ field_list: data })
				}
			}
		}

		render() {
			return (
				<>
					<Drawer
						title="New Algorithm Details"
						visible={this.props.visible}
						onClose={this.close}
						placement='right'
						width={'75%'}
						destroyOnClose={true}
						footer={[
							<div className="text-center">
								<Button form='addform' disabled={this.state.disabled || Object.keys(this.state.formula).length <= 0 || this.state.formula_status === 'error'} className="mr-20" loading={this.state.saving} type="primary" htmlType="submit">Save</Button>
								<Button onClick={this.close}>Cancel</Button>
							</div>
						]}
					>
						<Form form={this.props.form} id="addform"
							layout="vertical"
							onChange={this.handleChange}
							onFinish={this.handleSubmit}
							className="builder_wrapper"
						>
							<DragDropContext onDragEnd={this.onDragEnd} >
								<div className="d-flex">
									<div className="w-100">
										<Form.Item name="algorithm_name" rules={vsmAlgorithm.validation.algorithm_name} label="Algorithm Name" required tooltip='Algorithms is a tool for building a finite sequence of defined mathematical calculations optionally embedded within logical expressions. Algorithms are used in Transaction Models to calculate values for Journal Entries. Every Algorithm has to have its own unique Id and a Label.'>
											<Input placeholder="Algorithm Name" maxLength={100} />
										</Form.Item>

										<Form.Item name="algorithm_desc" rules={vsmAlgorithm.validation.algorithm_desc} label="Algorithm Description" required tooltip='More details about Algorithm, i.e. what it does and what it depends on, etc.'>
											<Input.TextArea placeholder="Algorithm Description" maxLength={1000} />
										</Form.Item>
										<h3>
											<span>Algorithm Builder</span>
											<small>
												<Tooltip placement="topLeft" title="Main Algorithm Builder with different combination of conditions and arithmetic values and formulas to run. This is core of algorithm and will generate values based on field inputs in Transactions.">
													<InfoCircleOutlined size={10} className="ml-10" />
												</Tooltip>
											</small>
										</h3>
										<AlgorithmBuilder main={this.state.formula} formula={this.state.formula} setFormula={this.setFormula} />

										<Form.Item name="readable_format" label="Readable Format" required className="mt-20 custom_higlight" validateStatus={this.state.formula_status} tooltip='It text-string formatted representation of the Algorithm. It can be easily readable by users.'>
											<Input.TextArea disabled placeholder="Algorithm Box	(Automatically fill algorithm in this box)" />
										</Form.Item>
									</div>
									<div style={{ 'minWidth': "280px", 'width': "280px", 'paddingLeft': '24px' }}>
										<Affix offsetTop={80} offsetBottom={80}>
											<Card>
												<h3 className="text-center pt-5 pb-5">
													<span>Available Fields for use</span>
													<small>
														<Tooltip placement="topLeft" title="All Published fields are available here. You can drag them into the Arithmetic formula builder available in “Value Block” in Algorithm Builder.">
															<InfoCircleOutlined size={10} className="ml-10" />
														</Tooltip>
													</small>
												</h3>
												<div className="text-center pt-10 pb-10">
													<Input
														placeholder="Search"
														onChange={value => { this.handleSearch(value) }}
													/>
												</div>
												<Divider className="mt-0 mb-10" />
												<div className="formulize-field-wrapper">
													<Droppable droppableId="droppable">
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
															>
																{this.state.field_list && this.state.field_list.length > 0 ? this.state.field_list.map((item, index) => (
																	<Draggable
																		key={item.id}
																		draggableId={'draggable_' + item.id}
																		index={index}>
																		{(provided, snapshot) => {
																			return (
																				<a
																					className={"w50  draggable_" + item.id}
																					data-value={JSON.stringify({ item: item.id })}
																					ref={provided.innerRef}
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																					style={{
																						...this.getItemStyle(
																							snapshot.isDragging,
																							provided.draggableProps.style,
																						), 'display': 'block'
																					}}>
																					<div className={`formulize-custom ${snapshot.isDragging ? 'dragging' : ''}`}>
																						{item.field_name}
																						{provided.placeholder}
																					</div>
																				</a>
																			)
																		}}
																	</Draggable>
																)) : <div className="text-center">No Record Found</div>}
															</div>
														)}
													</Droppable>
												</div>
												<div className="text-center mt-10">
													You can Drag fields in Arithmetic Formula Builder from here
												</div>
											</Card>
											<div className="mt-10">
												<h4>
													<span>Algorithm Parsed</span>
													<Tooltip placement="topLeft" title="This is status of Algorithm whether it is Parsed successfully or not with all given formulas and conditions.">
														<InfoCircleOutlined size={10} className="ml-10" />
													</Tooltip>
												</h4>
												<div className={(Object.keys(this.state.formula).length > 0 && this.state.formula_status === "success") ? "text-success parsed_wrapper" : "text-danger parsed_wrapper"}>
													{Object.keys(this.state.formula).length <= 0 ? 'Parse Error that must be resolved' : this.props.AlgoStore.custom_error_msg}
												</div>
											</div>
										</Affix>
									</div>
								</div>
							</DragDropContext>


							<Divider />
						</Form>
					</Drawer >
				</>
			)
		}
	}

	return (
		<AddComponent form={form} AlgoStore={AlgorithmsStore} {...props} />
	)

})

export default AddComponent
