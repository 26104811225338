import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { Button } from 'antd'
import { vsmCommon } from '../../../../../config/messages'
import { EyeOutlined } from '@ant-design/icons'
import useStore from '../../../../../store'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { Link } from 'react-router-dom'
import moment from 'moment'

const ListComponent = observer((props) => {
	const { openViewModal, selectFilter, dateFilter, } = props
	const { AuditLogStore, AuditLogStore: { setupGrid, getFilteredData, onFilterChanged, list_data, onGridChanged } } = useStore()

	useEffect(() => {
		getFilteredData(selectFilter, dateFilter && moment(dateFilter).format("YYYY-MM-DD"))
	}, [getFilteredData, selectFilter, dateFilter])

	const ActionRenderer = (prop) => {
		const { openViewModal } = prop.agGridReact.props.frameworkComponents
		return (
			<div className="action-column">
				<Button type="text" title="View Audit log" onClick={() => openViewModal(prop.data.id)} ><EyeOutlined /></Button>
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'id',
			headerTooltip: '# ID',
			tooltipField: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'User Type',
			headerTooltip: 'User Type',
			tooltipField: 'user_type',
			field: 'user_type',
			width: 130
		}, {
			headerName: 'User Name',
			headerTooltip: 'User Name',
			tooltipField: 'updated_by',
			field: 'updated_by',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 120
		}, {
			headerName: 'Event',
			headerTooltip: 'Event',
			field: 'event',
			tooltipField: 'event',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 110
		}, {
			headerName: 'Auditable Type',
			headerTooltip: 'Auditable Type',
			field: 'auditable_type',
			tooltipField: 'auditable_type',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 120
		}, {
			headerName: 'Auditable Id',
			headerTooltip: 'Auditable Id',
			tooltipField: "auditable_id",
			field: 'auditable_id',
			cellRendererFramework: function (data) {
				return (data.data) ?
					(AuditLogStore.module_path[data.data.auditable_type]) ? <Link to={AuditLogStore.module_path[data.data.auditable_type]}>{data.data.auditable_id}</Link>
						: <div>{data.data.auditable_id}</div> : null
			},
			width: 100
		}, {
			headerName: 'URL',
			headerTooltip: 'URL',
			tooltipField: 'url',
			field: 'url',
			width: 220
		}, {
			headerName: 'IP Address',
			headerTooltip: 'IP Address',
			tooltipField: 'ip_address',
			field: 'ip_address',
			width: 120
		}, {
			headerName: 'User Agent',
			headerTooltip: 'User Agent',
			tooltipField: "user_agent",
			field: 'user_agent',
			width: 180
		}, {
			headerName: 'Tags',
			headerTooltip: 'Tags',
			tooltipField: 'tags',
			field: 'tags',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 110
		}, {
			headerName: 'Last Updated On',
			headerTooltip: 'Last Updated On',
			tooltipField: 'updated_at',
			field: 'updated_at',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
			},
			width: 185
		}, {
			headerName: 'Old values',
			headerTooltip: 'Old values',
			tooltipField: 'old_values',
			field: 'old_values',
			hide: true,
			width: 110
		}, {
			headerName: 'New values',
			headerTooltip: 'New values',
			tooltipField: 'new_values',
			field: 'new_values',
			hide: true,
			width: 110
		}, {
			headerName: 'Actions',
			headerTooltip: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 55
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('audit_log')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openViewModal
				}}
				onGridReady={setupGrid}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
