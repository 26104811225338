import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Checkbox, Tooltip, Form, Col, DatePicker, Spin, Row, Divider, Button } from 'antd'
import { InfoCircleOutlined, PhoneOutlined, GlobalOutlined, EnvironmentFilled } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent'
import { vsmAllTransactions, vsmOrganisations, vsmTransactionItems, vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import moment from 'moment';
import { default as JournalLogsComponent } from '../../Administration/TransactionModels/component/JournalLines/component/ListComponent';
import { default as ManualJournalAddComponent } from '../../Administration/ManualJournalLog/components/AddComponent'
// import { default as TransactionItemsAddComponent } from '../../Administration/TransactionItems/components/AddComponent'
// import TableComponent from '../elements/ItemsTabel';

const ViewaETRComponent = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	// const [addItemModal, setAddItemModal] = useState(false)
	// const [fetchRole, setFetchRole] = useState(true)
	const [fetchMapping, setFetchMapping] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [fetchVatRate, setFetchVatRate] = useState(true)
	// const [roleRejectedKeys, updateRoleRejectedKeys] = useState([])
	// const [itemPayload, setItemPayload] = useState(null)
	const [isChecked, setChecked] = useState(true)
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchManufacturer, setFetchManufacturer] = useState(true)
	const [fetchClassification, setFetchClassification] = useState(true)
	// const [disabledButton, setDisabledButton] = useState(true)
	const { OrgBankAccountStore, UserStore, OrganisationStore, TransactionItemsStore, AllTransactionStore: { getMappingList, getStatusList, getVatRateList, dropdownLodaing, checkBox_dropdown, checkBox_dropdown_val, depedentList, mapping_dropdown, vatRate_dropdown, status_dropdown, csvModals } } = useStore()
	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore,
		user: UserStore
	}
	const currentDate = new Date()

	// Open form for add manual journal entry
	const openAddModal = () => setAddModal(true)

	// Close form for add manual journal entry
	const closeAddModal = () => setAddModal(false)

	const handleChange = () => {
		props.form.validateFields().then((data) => {
			// setDisabledButton(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				// setDisabledButton(true)
			}
		})
	}

	const handleValue = (value, key) => {
		const fields = props.form.getFieldsValue()
		const { transaction_items } = fields
		if (transaction_items[key].quantity && transaction_items[key].unit_price) {
			Object.assign(transaction_items[key], { value: parseFloat(transaction_items[key].quantity) * parseFloat(transaction_items[key].unit_price) })
			props.form.setFieldsValue({ transaction_items })
		} else {
			Object.assign(transaction_items[key], { value: parseFloat(0) })
			props.form.setFieldsValue({ transaction_items })
		}
	}

	useEffect(() => {
		// setFetchRole(true)
		setFetchMapping(true)
		setFetchStatus(true)
		setFetchVatRate(true)
	}, [])

	useEffect(() => {
		if (props.tab === "add") {
			props.form.setFieldsValue({ model: csvModals })
		}
		if (store.organisation.dropdown_orgRole_list) {
			let rejected_keys = [];
			store.organisation.dropdown_orgRole_list.forEach((item) => {
				if (!['Leasee', 'Originator'].includes(item.role_name)) {
					rejected_keys.push(item.id)
				}
			})
		}
	}, [csvModals, props.tab, props.form, store.organisation.dropdown_orgRole_list])

	useEffect(() => {
		let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
		let payload = {}
		props.csvColumn && props.csvColumn.forEach((item) => {
			if (inCSV_Columns_payload.includes(item.field_name)) {
				switch (item.field_name) {
					case 'Asset Type':
						props.form.setFieldsValue({ asset_type: parseInt(item.default_value) })
						payload.asset_type = item.default_value ? parseInt(item.default_value) : item.default_value
						break;
					case 'Sell Rate':
						props.form.setFieldsValue({ sell_rate: item.default_value ? parseFloat(item.default_value * 100).toFixed(2) : item.default_value * 100 })
						payload.sell_rate = item.default_value
						break;
					case 'Period':
						props.form.setFieldsValue({ period: item.default_value })
						break;
					case 'Period Type':
						props.form.setFieldsValue({ period_type: parseInt(item.default_value) })
						break;
					case 'Tax Type Inc or Exc':
						props.form.setFieldsValue({ tax_type: parseInt(item.default_value) })
						payload.tax_type_inc_exc = item.default_value
						break;
					case 'EDSO':
						props.form.setFieldsValue({ EDSO: item.default_value })
						break;
					case 'Repair and Maintenance':
						props.form.setFieldsValue({ repair_maintenance: item.default_value })
						payload.rm_price = item.default_value
						break;
					case 'EoT Value':
						props.form.setFieldsValue({ eot: item.default_value })
						payload.eot = item.default_value
						break;
					case 'Batch ID':
						props.form.setFieldsValue({ batch_id: item.default_value })
						payload.batch = item.default_value
						break;
					case 'Exchange Ref ID':
						props.form.setFieldsValue({ exchange_ref_id: item.default_value ? item.default_value : '' })
						break;
					case 'Reference':
						props.form.setFieldsValue({ reference: item.default_value ? item.default_value : '' })
						break;
					case 'Tax ID On Sales':
						props.form.setFieldsValue({ sales_vat_rate_id: parseInt(item.default_value) })
						break;
					case 'Current Period':
						props.form.setFieldsValue({ current_period: item.default_value })
						break;
					case 'Period Number Days':
						props.form.setFieldsValue({ period_number_days: item.default_value })
						break;
					case 'Period Days Passed':
						props.form.setFieldsValue({ period_days_passed: item.default_value })
						break;

					default:
						break;
				}
			}
			// setItemPayload(payload)
		})
		payload.tax_settings_id = props.extraField
		// props.form.setFieldsValue({ add })
		if (props.form.getFieldValue('add_address')) {
			setChecked(true)
		} else {
			setChecked(false)
		}
	}, [props.csvColumn, props.form, props.extraField])

	return (
		<>
			<Row gutter={24}>
				<Col span={9}>
					<Row gutter={24}>
						<Col span={24}>
							{/* Organisation */}
							<InputComponent
								required
								disabled={props.tab !== "add" || !props.roleId}
								type="select"
								label="Organisation"
								name="entity_id"
								// placeholder="Select Organisation"
								tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
								rules={vsmOrganisations.validation.organisation}
								onChange={props.onChange}
								notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: depedentList && depedentList.entity,
									value_key: "id",
									text_key: "entity_name",
								}}
							/>
						</Col>
						<Col span={14}>
							<InputComponent
								required disabled
								type="select"
								label="Trade Transaction"
								name="type_field_mapping_id"
								// placeholder="Select Trade Transaction"
								tooltip="Select Trade Transaction Name which is combination of Trade Type & Transaction Type. It Defines which fields and models will be generated on imported transactions."
								rules={vsmAllTransactions.validation.mapping}
								onChange={props.onChange}
								onFocus={() => fetchMapping && getMappingList().then(() => setFetchMapping(false))}
								notFoundContent={fetchMapping ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: mapping_dropdown,
									value_key: "id",
									text_key: "mapping_name"
								}}
							/>
						</Col>
						<Col span={10}>
							<InputComponent
								required
								type="select"
								disabled={props.tab !== "add"}
								label="Repayment Schedule"
								name="repayment_schedule"
								placeholder="Repayment Schedule"
								tooltip="Currency is a medium of exchange for goods and services. Select one which is base currency for this transaction."
								onChange={props.onChange}
								options={{
									values: [{ value: '1', text: 'Monthly Scheduled Repayment' }, { value: '2', text: 'Monthly Interest Only' }],
								}}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={10}>
					{props.orgData && <CardComponent props={props.orgData} />}
				</Col>
			</Row>
			<Divider />
			<Row gutter={24}>
				{/* Currency */}
				<Col span={2}>
					<InputComponent
						required disabled={props.tab !== "add"}
						type="select"
						label="Currency"
						name="currency_id"
						// placeholder="Select Currency"
						tooltip="Currency is a medium of exchange for goods and services. Select one which is base currency for this transaction."
						rules={vsmAllTransactions.validation.currency}
						initialValue={store.user.ledger_base_currency.id}
						onChange={props.onChange}
						options={{
							values: store.bank.dropdown_currency_list,
							value_key: "id",
							text_key: "currency_code"
						}}
					/>
				</Col>

				{/* Face value */}
				<Col span={2}>
					<InputComponent
						className='text-right'
						required
						disabled={props.tab !== "add"}
						label="Face Value"
						name="face_value"
						// placeholder="Face value"
						tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
						// rules={vsmAllTransactions.validation.face_value}
						onChange={props.onChange}
					/>
				</Col>
				{/* PTV or LTV */}
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						disabled={props.tab !== "add"}
						label="PTV%"
						name="ltv_value"
						placeholder="PTV%"
						tooltip={"PTV should contain Dot (Decimal upto 2 places) and Numeric Values only. Other special characters and Alphabets are not allowed."}
						rules={vsmAllTransactions.validation.ltv_value}
						onChange={props.onChange}
					/>
				</Col>

				{/* Status */}
				<Col span={2}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Status"
						name="status_id"
						// placeholder="Select Status"
						tooltip="Status is an attribute of a Transaction, it tells actual status of Transaction at the moment."
						rules={vsmAllTransactions.validation.status}
						onChange={props.onChange}
						initialValue={1}
						onFocus={() => props.tab === "edit" && (fetchStatus && getStatusList().then(() => setFetchStatus(false)))}
						notFoundContent={props.tab === "edit" && (fetchStatus ? <Spin size="small" /> : "No Record Found.")}
						options={{
							values: status_dropdown,
							value_key: "id",
							text_key: "status_name"
						}}
					/>
				</Col>

				{/* Vat Rate */}
				<Col span={3}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Purchase VAT Rate"
						name="tax_settings_id"
						// placeholder="Select VAT Rate"
						tooltip="purchase VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						rules={vsmAllTransactions.validation.vat_rate}
						onChange={props.onChange}
						onFocus={() => fetchVatRate && getVatRateList().then(() => setFetchVatRate(false))}
						notFoundContent={fetchVatRate ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vatRate_dropdown,
							value_key: "id",
							text_key: { key: ["tax_name", " - ", "tax_value"] },
						}}
					/>
				</Col>

				{/* Posted On */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Posted On"
						name="posted_on"
						// placeholder="Select Posted Date"
						tooltip="The posted date is the day, month, and year when the trade transaction is Posted."
						rules={vsmAllTransactions.validation.posted_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>

				{/* Traded On */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Traded On"
						name="traded_on"
						// placeholder="Select Traded Date"
						tooltip="The traded date is the day, month, and year when the trade transaction is Traded."
						rules={vsmAllTransactions.validation.traded_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>

				{/* Issued on */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Issued On"
						name="issued_on"
						placeholder="Select Issued Date"
						tooltip="The Issued date is the day, month, and year when the trade transaction is Issued."
						rules={vsmAllTransactions.validation.issued_date}
						onChange={props.onChange}
					/>
				</Col>

				{/* Additional Field Values */}
				{/* {
				<Card className="ant-col ant-form-item-control">
					<Row gutter={24}> */}
				<Col span={3}>
					<InputComponent
						type="radio_button"
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						options={{
							values: [{ value: 1, text: 'a-ETR' }, { value: 2, text: 'f-ETR' }]
						}}
						label='Asset Type'
						name='asset_type'
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						className='text-right'
						required
						onChange={props.onChange}
						disabled={props.tab !== "add"}
						label='Sell Rate (%)'
						name='sell_rate'
						// placeholder='Sell Rate(%)'
						rules={vsmTransactionReport.validation.sell_rate}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						label='Period'
						name='period'
						// placeholder='Period'
						rules={vsmTransactionReport.validation.period}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						disabled={props.tab !== "add"}
						type='select'
						label='Period Type'
						name='period_type'
						// placeholder='Select Period Type'
						options={{
							values: [{ value: 1, text: 'Days' }, { value: 2, text: 'Weeks' }, { value: 3, text: 'Months' }, { value: 4, text: 'Years' }]
						}}
						rules={vsmTransactionReport.validation.period_type}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						type="radio_button"
						label='Tax type'
						name='tax_type'
						options={{
							values: [{ value: 1, text: 'Inc' }, { value: 2, text: 'Exc' }]
						}}
					/>
				</Col>
				{/* <Col span={2}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						label='EDSO'
						name='EDSO'
						// placeholder='EDSO'
						rules={vsmTransactionReport.validation.EDSO}
					/>
				</Col> */}
				<Col span={2}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						label='R&M' className='text-right'
						tooltip='Repair and Maintenance'
						name='repair_maintenance'
						// placeholder='Repair and Maintenance'
						rules={vsmTransactionReport.validation.repair_maintenance}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required className='text-right'
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						label='EoT'
						name='eot'
						// placeholder='EoT'
						rules={vsmTransactionReport.validation.eot}
					/>
				</Col>
				<Col span={3}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						onChange={props.onChange}
						label='Batch ID'
						name='batch_id'
						// placeholder='Batch ID'
						rules={vsmTransactionReport.validation.batch_id}
					/>
				</Col>
				{/* <Col span={3}>
					<InputComponent
						onChange={props.onChange}
						disabled={props.tab !== "add"}
						label='Exchange Ref ID'
						name='exchange_ref_id'
					// placeholder='Exchange Ref ID'
					/>
				</Col> */}
				<Col span={2}>
					<InputComponent
						onChange={props.onChange}
						disabled={props.tab !== "add"}
						label='Reference'
						name='reference'
					// placeholder='Reference'
					/>
				</Col>
				<Col span={3}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Sales Vat Rate"
						name="sales_vat_rate_id"
						// placeholder="Select Vat Rate"
						tooltip="Sales VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						rules={vsmAllTransactions.validation.sales_vat_rate}
						onChange={props.onChange}
						onFocus={() => fetchVatRate && getVatRateList().then(() => setFetchVatRate(false))}
						notFoundContent={fetchVatRate ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vatRate_dropdown,
							value_key: "id",
							text_key: { key: ["tax_name", " - ", "tax_value"] },
						}}
					/>
				</Col>

				<Col span={3}>
					<InputComponent
						type='checkbox'
						disabled={props.tab !== "add"}
						checked={props.customStartDate}
						label={'Asset Repayment Start Date'}
						name='is_custom_start_date'
						onChange={e => props.setCustomStartDate(e.target.checked)}
					/>
				</Col>

				{props.customStartDate &&
					<Col span={3}>
						<DatePickerComponent
							required
							disabled={props.tab !== "add"}
							label="Start Date"
							name="start_date"
							placeholder="Select Start Date Date"
							tooltip="The Start Date is the day, month, and year when the trade transaction was started."
							rules={vsmAllTransactions.validation.start_date}
							onChange={props.onChange}
						/>
					</Col>
				}
			</Row>
			{/* 	</Card> 
			} */}

			{/* Transaction Items */}
			{
				<Card className="ant-col ant-form-item-control mt-20" title='Assets Details:'>
					<Form.List name="transaction_items">
						{(fields, { add, remove }) => {
							return (
								<div>
									{props.form.getFieldValue('transaction_items') && props.form.getFieldValue('transaction_items').length ?
										fields.map((field, index) => (
											<div key={field.key}>
												<Row gutter={24}>
													<Col span={24}>
														<Row gutter={24}>
															<Col span={2}>
																<InputComponent
																	name={[field.name, "asset_number"]} key={[field.key, 'asset_number']} label="Asset Number" placeholder="Asset Number" onChange={handleChange} disabled
																	tooltip="An asset number uniquely identifies the product. An asset number can be represented by either a license number for software or a service tag for a hardware appliance."
																	rules={vsmTransactionItems.validation.assetnumber} autoComplete="off"
																/>
															</Col>
															<Col span={2}>
																<InputComponent
																	className='text-right' name={[field.name, "deposit_received"]} key={[field.key, 'deposit_received']} label="Deposit Received" placeholder="Deposit Received" onChange={handleChange} disabled
																	tooltip="A customer deposit could be money that a company receives from a customer prior to the company earning."
																	rules={vsmTransactionItems.validation.deposit} autoComplete="off"
																	maxLength={100}
																/>
															</Col>
															<Col span={3}>
																<InputComponent
																	name={[field.name, "item_name"]} key={[field.key, 'item_name']} label="Asset Name" placeholder="Asset Name" onChange={handleChange} disabled
																	tooltip="An item is one of a collection or list of objects."
																	rules={vsmTransactionItems.validation.item} autoComplete="off"
																	maxLength={100}
																/>
															</Col>
															<Col span={4}>
																<InputComponent
																	name={[field.name, "serial_no"]} key={[field.key, 'serial_no']} label="Serial Number" placeholder="Serial Number" onChange={handleChange} disabled
																	tooltip="A serial number is a unique identifier assigned incrementally or sequentially to an item, to uniquely identify it."
																	rules={vsmTransactionItems.validation.serialnumber} autoComplete="off"
																	maxLength={100}
																/>
															</Col>
															<Col span={2}>
																<InputComponent
																	name={[field.name, "site_number"]} key={[field.key, 'site_number']} label="Site No" placeholder="Site No" onChange={handleChange} disabled
																	tooltip="The site number is the unique identifier for your Grid. You will most commonly see the site number used within your Access Domain."
																	rules={vsmTransactionItems.validation.sitenumber} autoComplete="off"
																	maxLength={100}
																/>
															</Col>
															<Col span={3}>
																<InputComponent
																	required name={[field.name, "quantity"]} key={[field.key, 'quantity']} label="Quantity" placeholder="Quantity" onChange={(e) => { handleValue(e, field.key); handleChange() }} disabled
																	tooltip="The amount or number of something, especially that can be measured."
																	rules={vsmTransactionItems.validation.quantity} autoComplete="off"
																/>
															</Col>
															<Col span={2}>
																<InputComponent
																	className='text-right' required name={[field.name, "unit_price"]} key={[field.key, 'unit_price']} label="Unit Price" placeholder="Unit Price" onChange={(e) => { handleValue(e, field.key); handleChange() }} disabled
																	tooltip="Unit price is the price for a single unit of measure of a product sold in more or less than the single unit."
																	rules={vsmTransactionItems.validation.unitprice} autoComplete="off"
																/>
															</Col>
															<Col span={2}>
																<InputComponent
																	className='text-right' name={[field.name, "value"]} key={[field.key, 'value']} label="Value" placeholder="Value" onChange={handleChange}
																	tooltip="Value is the worth in goods, services or money of an object or person." disabled={true} autoComplete="off"
																/>
															</Col>

															<Col span={3}>
																<InputComponent
																	name={[field.name, "manufacturer"]} key={[field.key, 'manufacturer']} type="autocomplete" label="Supplier/Manufacturer" placeholder="Supplier/Manufacturer" disabled
																	tooltip="A person or company that makes goods for sale." onChange={handleChange}
																	rules={vsmTransactionItems.validation.manufacturer} autoComplete="off"
																	onFocus={() => fetchManufacturer && TransactionItemsStore.getManufacturer().then(() => setFetchManufacturer(false))}
																	notFoundContent={fetchManufacturer ? <Spin size="small" /> : "No Record Found."}
																	options={TransactionItemsStore.manufacturer_list}
																	maxLength={100}
																/>
															</Col>
															<Col span={3}>
																<InputComponent
																	required type="autocomplete" name={[field.name, "classification"]} key={[field.key, 'classification']} label="Asset Classification" placeholder="Asset Classification" disabled
																	tooltip="Classification is the list of industry sectors." onChange={handleChange}
																	rules={vsmTransactionItems.validation.classification} autoComplete="off"
																	onFocus={() => fetchClassification && TransactionItemsStore.getClassification().then(() => setFetchClassification(false))}
																	notFoundContent={fetchClassification ? <Spin size="small" /> : "No Record Found."}
																	options={TransactionItemsStore.classification_list}
																	maxLength={100}
																/>
															</Col>
															<Col span={3}>
																<InputComponent
																	name={[field.name, "dd_amount_exc_vat"]} key={[field.key, 'dd_amount_exc_vat']} label="DD Amount ex VAT" placeholder="DD Amount ex VAT" onChange={handleChange} disabled
																	tooltip="Direct Debits give a company permission to take money from your bank account on an agreed date."
																	rules={vsmTransactionItems.validation.ddamount} autoComplete="off"
																/>
															</Col>
															<Col span={6}>
																<InputComponent
																	type="textarea" label="Description" name={[field.name, "description"]} key={[field.key, 'description']} placeholder="Description" onChange={handleChange} disabled
																	tooltip="The purpose of journal description is to mention  the financial transactions of a business, to be used for the future reconciling of accounts."
																	rules={vsmTransactionItems.validation.description}
																	maxLength={1000} autoComplete="off"
																/>
															</Col>
														</Row>
													</Col>
												</Row>
												<Divider className='mb-20' />
											</div>
										))
										: 'No Assets Details found.'}

									{props.form.getFieldValue('transaction_items') && props.form.getFieldValue('transaction_items').length ?
										<Col span={24}>
											<Form.Item className="mb-0 text-center" wrapperCol={{ offset: 11 }}>
												<Form.Item className="mb-0" name="add_address" disabled>
													<Checkbox /* onChange={addAddress} */ checked={isChecked} disabled>Add Address</Checkbox>
												</Form.Item>
											</Form.Item>
										</Col>
										: null
									}
								</div>
							)
						}}
					</Form.List>
					{
						isChecked && props.form.getFieldValue('transaction_items') && props.form.getFieldValue('transaction_items').length ? (
							<Card hidden={!isChecked} className='mb-20 mt-10'>
								<Row gutter={24}>
									<Col span={3}>
										<InputComponent
											required label="Address 1" name="address_1" disabled
											placeholder="Address Line 1" onChange={handleChange}
											tooltip="A contact point used to send physical forms of communication to the organisation."
											rules={vsmOrganisations.validation.address_1}
											maxLength={1000}
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											label="Address 2" name="address_2" disabled
											placeholder="Address Line 2" onChange={handleChange}
											tooltip="A contact point used to send physical forms of communication to the organisation."
											rules={vsmOrganisations.validation.address_2and3}
											maxLength={1000}
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											label="Address 3" name="address_3" disabled
											placeholder="Address Line 3" onChange={handleChange}
											tooltip="A contact point used to send physical forms of communication to the organisation."
											rules={vsmOrganisations.validation.address_2and3}
											maxLength={1000}
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											allowClear type="autocomplete" name="city" label="City" disabled
											placeholder="City" onChange={handleChange}
											options={OrganisationStore.cityList}
											tooltip="It is a large human settlement place."
											rules={vsmOrganisations.validation.city}
											onFocus={() => fetchCity && OrganisationStore.getCityList().then(() => setFetchCity(false))}
											notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
											autoComplete="off"
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											allowClear type="autocomplete" label="State/County" name="state_county" disabled
											placeholder="State/County" onChange={handleChange}
											options={OrganisationStore.stateCountyList}
											tooltip="State is defined as a territory with its own government and borders within a larger country."
											rules={vsmOrganisations.validation.state}
											onFocus={() => fetchState && OrganisationStore.getStateCountyList().then(() => setFetchState(false))}
											notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
											autoComplete="off"
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											label="Postal Code" name="postal_code" disabled
											placeholder="Postal Code" onChange={handleChange}
											tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
											rules={vsmOrganisations.validation.postal_code}
											maxLength={16}
										/>
									</Col>
									<Col span={3}>
										<InputComponent
											required allowClear type="select" label="Country" placeholder="Country" disabled
											name="country_id" onChange={handleChange}
											options={{
												values: OrganisationStore.dropdown_countries_list,
												value_key: 'id',
												text_key: { key: ["iso_code_alpha2", " - ", "country_name"] }
											}}
											rules={vsmOrganisations.validation.country}
											tooltip="A country is a political state, nation, or territory which is controlled."
											onFocus={() => fetchCountry && OrganisationStore.getAllCountries().then(() => setFetchCountry(false))}
											notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
											autoComplete="off"
										/>
									</Col>
								</Row>
							</Card>
						) : (null)
					}
					{/* <Button className='d-flex ml-auto mr-auto' key="1" form='viewform' disabled={disabledButton} htmlType="submit" type="primary">Save</Button> */}
				</Card>
			}

			{/* Select Models to Apply on Transaction */}
			<div className={props.csvColumn && props.csvColumn.length > 0 ? "mt-25" : ""}>
				{
					csvModals && csvModals.length > 0 && (
						<Card
							title={<div>
								<span>Select Models to Apply on Transaction</span>
								<Tooltip placement="topRight" title={"Model list is displayed based on selected mapping."}>
									<InfoCircleOutlined className="ml-10" />
								</Tooltip>
							</div>}
							className="ant-col ant-form-item-control"
						>
							<CheckboxComponent
								name="model"
								options={csvModals}
								disabled={props.tab !== "add"}
								onChange={props.onChange}
								dropdown={checkBox_dropdown}
								dropdown_val={checkBox_dropdown_val}
							/>
						</Card>
					)
				}
			</div>
			{/* journal logs table */}
			{
				props.tab === "view" && (
					<div className="mt-20 mb-10">
						<div className="d-flex justify-space-between">
							<h3><span>Journal Logs :</span></h3>
							<Button className="mt-5 mb-5" onClick={openAddModal}>Add Manual Journal Entry</Button>
						</div>
						<JournalLogsComponent close={props.close} openParentCashViewDrawer={props.openParentCashViewDrawer} currentView={"verify"} />
						<ManualJournalAddComponent visible={addModal} close={closeAddModal} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} />
					</div>
				)
			}

			{/* transactions items table */}
			{/* {
				(props.hasItems && props.tab === "view") ? (
					<div className="mt-20 mb-10">
						<div className="d-flex justify-space-between">
							<h3><span>Assets Details :</span></h3>
							<Button className="mt-5 mb-5" onClick={openAddItemModal}>Add</Button>
						</div>
						<TableComponent data={itemsTable} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} itemPayload={itemPayload} tab={'view'} />
						<TransactionItemsAddComponent visible={addItemModal} close={closeAddItemModal} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} tab={'view'} itemPayload={itemPayload} />
					</div>
				) : null
			} */}
		</>
	)
})

export default ViewaETRComponent

const DatePickerComponent = ({ label, required, rules, name, tooltip, initialValue, ...rest }) => {
	return (
		<Form.Item label={label} required={required} tooltip={tooltip}>
			<Form.Item name={name} initialValue={initialValue} noStyle rules={rules}>
				<DatePicker {...rest} className={'d-flex w-100'} />
			</Form.Item>
		</Form.Item>
	)
}

const CheckboxComponent = ({ name, options, dropdown, onChange, disabled }) => {
	return (options && options.length >= 0) ? (
		disabled ? (
			<Form.Item name={name}>
				<Checkbox.Group
					valuePropName="checked"
					options={options}
					onChange={onChange}
					disabled={disabled}
				/>
			</Form.Item>
		) : (
			<Form.List name={name} initialValue={options} >
				{
					(fields) => (
						<Row gutter={24}>
							{fields.map((field, index) => {
								return options[index] && (
									<Col span={12} key={field.key}>
										<Row gutter={24}>
											<Col span={12}>
												<Form.Item
													valuePropName="checked"
													noStyle
													name={[field.name, "model"]}
													key={[field.key, "model"]}
													initialValue={index >= 0 && options[index].selected ? true : false}
												>
													<Checkbox disabled={disabled}>{index >= 0 && options[index].model_name}</Checkbox>
												</Form.Item>
											</Col>
											<Col span={12}>
												<InputComponent
													required
													type="select" disabled={disabled}
													initialValue={"Current Date"}
													options={{
														values: dropdown,
													}}
													onChange={onChange}
													name={[field.name, 'field']}
													key={[field.key, 'field']}
												// placeholder="Select DB Field"
												/>
											</Col>
										</Row>
									</Col>
								)
							})}
						</Row>
					)
				}
			</Form.List>
		)
	) : null
}

const CardComponent = ({ props }) => {
	return (
		<Card size="small" className="mb-20">
			<h3><b>{props.organisation_name} ({props.trade_name ? props.trade_name + ' - ' : ''}{props.global_countries ? props.global_countries.country_name : ''})</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						{(props.multiple_phone && props.multiple_phone.length) ? (
							<tr>
								<td style={{ width: '30px' }}><PhoneOutlined /></td>
								<td>{props.multiple_phone.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{props.website ? (
							<tr>
								<td><GlobalOutlined /></td>
								<td>{props.website && props.website}</td>
							</tr>
						) : null}
						{props.addresses ? (
							<tr>
								<td><EnvironmentFilled /></td>
								<td><div>
									{props.addresses.address_1}
									{props.addresses.address_2 && (', ' + props.addresses.address_2)}
									{props.addresses.address_3 && (', ' + props.addresses.address_3)}
									{props.addresses.city && (', ' + props.addresses.city)}
									{props.addresses.state_county && (', ' + props.addresses.state_county)}
									{props.addresses.countries && (', ' + props.addresses.countries.country_name)}
									{props.addresses.postal_code && (', ' + props.addresses.postal_code)}
								</div>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</Card>
	)
}
