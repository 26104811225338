import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, Drawer, Spin, Divider } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import useStore from '../../../../store'
import RepeaterElement from '../../Classifications/elements/RepeaterElement'
import CustomFieldsElement from '../elements/CustomFieldsElement'
import { vsmCashTransactions, vsmClassifications, vsmNotify } from '../../../../config/messages'

const FilterComponent = observer((props) => {
	const [form] = Form.useForm()
	const [fetchAccounts, setFetchAccounts] = useState(true)
	const [fetchTag, setFetchTag] = useState(true)
	const [fetchType, setFetchType] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchBanks, setFetchBanks] = useState(true)
	const [fetchClassfication, setFetchClassification] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [formDataList, setFormDataList] = useState([])
	const [fetchOrgRole, setFetchOrgRole] = useState(true)
	const [selectedRoleID, setSelectedRoleID] = useState()
	const { CashTransactionStore, CTEImportStore, LedgerBankAccountStore, ClassificationStore, SavedReportStore, OrgBankAccountStore, RoleMappingStore } = useStore()

	// Make function call to filter new record
	const handleSubmit = (data) => {
		setSaving(true)
		var filterData = {}

		if (form.getFieldValue('entity_type') !== 0) {
			filterData.role_data = (form.getFieldValue('entity_type') === 1) ?
				(SavedReportStore.main_organisation_roles && SavedReportStore.main_organisation_roles.filter(item => form.getFieldValue('role_id') && form.getFieldValue('role_id').includes(item.id)))
				: (SavedReportStore.main_people_roles && SavedReportStore.main_people_roles.filter(item => form.getFieldValue('role_id') && form.getFieldValue('role_id').includes(item.id)))

			filterData.entity_data = (form.getFieldValue('entity_type') === 1) ?
				(SavedReportStore.main_organisation && SavedReportStore.main_organisation.filter(item => form.getFieldValue('entity_id') && form.getFieldValue('entity_id').includes(item.organisation_id)))
				: (SavedReportStore.main_people && SavedReportStore.main_people.filter(item => form.getFieldValue('entity_id') && form.getFieldValue('entity_id').includes(item.people_id)))
		}
		if (form.getFieldValue('related_entity_type') !== 0) {
			filterData.related_entity_data = (form.getFieldValue('related_entity_type') === 1) ?
				(SavedReportStore.related_organisation && SavedReportStore.related_organisation.filter(item => form.getFieldValue('related_entity_id') && form.getFieldValue('related_entity_id').includes(item.organisation_id)))
				: (SavedReportStore.related_people && SavedReportStore.related_people.filter(item => form.getFieldValue('related_entity_id') && form.getFieldValue('related_entity_id').includes(item.people_id)))

			filterData.related_role_data = (form.getFieldValue('related_entity_type') === 1) ?
				(SavedReportStore.related_organisation_role && SavedReportStore.related_organisation_role.filter(item => form.getFieldValue('related_role_id') && form.getFieldValue('related_role_id').includes(item.id)))
				: (SavedReportStore.related_people_role && SavedReportStore.related_people_role.filter(item => form.getFieldValue('related_role_id') && form.getFieldValue('related_role_id').includes(item.id)))
		}
		CashTransactionStore.advanceFilter = data
		CashTransactionStore.filterData = filterData
		CashTransactionStore.agGrid = CashTransactionStore.agGridLocal
		delete data["tag"]
		if (form.getFieldValue('entity_type') === 0) { delete data["entity_type"] }
		if (form.getFieldValue('related_entity_type') === 0) { delete data["related_entity_type"] }
		var dataCheck = CashTransactionStore.formatAdvanceFilter(data)
		if (dataCheck.isValid) {
			close()
			CashTransactionStore.getListLocal(dataCheck.data).then(() => {
			}).catch(e => {
				setDisabled(true)
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		} else {
			setSaving(false)
			vsmNotify.error({
				message: "Please select atleast one field"
			})
		}
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			setFetchAccounts(true)
			setFetchTag(true)
			setFetchType(true)
			setFetchCurrency(true)
			setFetchBanks(true)
			setFetchClassification(true)
			LedgerBankAccountStore.getDynamicFieldList()
			setSelectedRoleID(CashTransactionStore.advanceFilter && CashTransactionStore.advanceFilter.role_id)
			let data = CashTransactionStore.advanceFilter
			if (data) {
				form.setFieldsValue(data)
			} else {
				form.setFieldsValue({
					customer_reference: [null],
					bank_reference: [null],
					cte_fields: [{ operator: "=" }]
				})
			}
			setLoading(false)
		}
	}, [props.visible, CashTransactionStore, LedgerBankAccountStore, form])

	//set organization switch case
	const getFormDataList = useCallback(() => {
		var FormDataList = []
		switch (form.getFieldValue('entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'main_people_role', 'main_people']
				break;
			case 1:
				FormDataList = [...FormDataList, 'role_id', 'entity_id']
				break;
			default:
				FormDataList = [...FormDataList]
		}

		switch (form.getFieldValue('related_entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'related_people_role', 'related_people']
				break;
			case 1:
				FormDataList = [...FormDataList, 'related_role_id', 'related_entity_id']
				break;
			default:
				FormDataList = [...FormDataList]
		}
		return FormDataList
	}, [form])

	useEffect(() => {
		if (props.visible) {
			var data = getFormDataList()
			if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
				setFormDataList(data)
			}
		}
	}, [getFormDataList, setFormDataList, formDataList, props.visible])

	const getOrgRelatedData = useCallback((data = {}) => {
		let main_entity = form.getFieldValue('entity_type') === 1 ? "organisation" : (form.getFieldValue('entity_type') === 2 ? "people" : "all")
		let related_entity = form.getFieldValue('related_entity_type') === 1 ? "organisation" : (form.getFieldValue('related_entity_type') === 2 ? "people" : "all")
		let payload = { main_entity, related_entity }
		if (form.getFieldValue('entity_type') !== 0) {
			if (main_entity === 'people') {
				let main_people_role = form.getFieldValue('role_id')
				main_people_role = (main_people_role && main_people_role.length > 0) ? main_people_role : null
				let main_people = form.getFieldValue('entity_id')
				main_people = (main_people && main_people.length > 0) ? main_people : null
				payload = { ...payload, main_people_role, main_people }
			} else {
				let main_organisation_role = form.getFieldValue('role_id')
				main_organisation_role = (main_organisation_role && main_organisation_role.length > 0) ? main_organisation_role : null
				let main_organisation = form.getFieldValue('entity_id')
				main_organisation = (main_organisation && main_organisation.length > 0) ? main_organisation : null
				payload = { ...payload, main_organisation_role, main_organisation }
			}
		}
		if (form.getFieldValue('related_entity_type') !== 0) {
			if (related_entity === 'people') {
				let related_people_role = form.getFieldValue('related_role_id')
				related_people_role = (related_people_role && related_people_role.length > 0) ? related_people_role : null
				payload = { ...payload, related_people_role }
			} else {
				let related_organisation_role = form.getFieldValue('related_role_id')
				related_organisation_role = (related_organisation_role && related_organisation_role.length > 0) ? related_organisation_role : null
				payload = { ...payload, related_organisation_role }
			}
		}
		payload = { ...payload, ...data }
		SavedReportStore.getDependentList(payload)
	}, [form, SavedReportStore])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		setSelectedRoleID(form.getFieldValue('role_id'))
		await setFormDataList(getFormDataList())
		form.validateFields().then((data) => {
			var count = 0
			var dynamic_field = {}
			if (form.getFieldValue('cte_fields')) {
				form.getFieldValue('cte_fields').forEach((x, index) => {
					if (x) { dynamic_field['cte_fields.' + index + '.name'] = x.id }
				})
			}
			var other_field = form.getFieldsValue(['tag', 'role_id', 'entity_id', 'main_people_role',
				'main_people', 'related_role_id', 'related_entity_id', 'related_people_role', "related_people",
				'banks', 'bank_account_id', 'cte_transaction_classification_id', 'cte_transactions_type_id',
				'currency_id', 'bank_reference', 'customer_reference', 'exchange_ref_id'
			])
			Object.values({ ...dynamic_field, ...other_field }).map(item => (item && item.length > 0 ? count++ : null))
			if (count >= 1) { setDisabled(false) }
			else { setDisabled(true) }
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// Reset filter
	const reset = () => {
		close()
		CashTransactionStore.advanceFilter = null
		CashTransactionStore.agGrid = CashTransactionStore.agGridServer
		CashTransactionStore.setupGrid(CashTransactionStore.agGrid);
		CashTransactionStore.filterData = null
	}

	// reset form and close filter form
	const close = () => {
		props.close()
		setDisabled(true)
		form.resetFields()
		setSelectedRoleID()
		setFetchOrgRole(true)
		CashTransactionStore.filterData = null
	}

	// api call on tag change
	const handleTagChange = () => {
		var values = form.getFieldsValue(["tag", "entity_type", "related_entity_type"])
		if (values.tag && values.tag.length > 0) {
			SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation")
			var payload = {
				"tag_id": values.tag,
				"entity_type": values.entity_type,
				"related_entity_type": values.related_entity_type
			}
			CashTransactionStore.getTagDetail(payload).then(async (data) => {
				setSelectedRoleID(data.roles.map(item => item.id))
				await form.setFieldsValue({
					entity_id: data.entity.map(item => item && item.id),
					role_id: data.roles.map(item => item && item.id),
					related_entity_id: data.related_entity.map(item => item && item.id),
					related_role_id: data.related_roles.map(item => item && item.id)
				})
				getOrgRelatedData()
			})
		} else { form.resetFields(['role_id', 'entity_id', 'main_people_role', 'main_people', 'related_role_id', 'related_entity_id', 'related_people_role', 'related_people']) }
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Apply Advance Filter`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="filterform" loading={saving} htmlType="submit" type="primary">Apply</Button>
					<Button disabled={!CashTransactionStore.advanceFilter} className="ml-10" onClick={reset} >Clear</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="filterform"
					onChange={handleChange}
					onFinish={handleSubmit}
					labelCol={{ span: 6 }}
				>

					{/* Entity Type */}
					<InputComponent
						type="radio_button" label="Entity Type" name="entity_type" initialValue={0}
						onChange={(e) => {
							if (e.target.value !== 0) { getOrgRelatedData() }
							setFetchOrgRole(true)
							if (e.target.value === 0) { form.resetFields(['related_role_id', 'related_entity_id']) }
							form.resetFields(['role_id', 'entity_id'])
							handleChange()
						}}
						options={{
							values: [
								{ value: 0, text: 'All' },
								{ value: 1, text: 'Organisation' },
								{ value: 2, text: 'People' },
							]
						}}
					/>

					{/* Related Entity Type */}
					<InputComponent
						type="radio_button" label="Related Entity Type" name="related_entity_type" initialValue={0}
						onChange={(e) => {
							if (e.target.value !== 0) { getOrgRelatedData() }
							if (e.target.value === 0) { form.resetFields(['role_id', 'entity_id']) }
							form.resetFields(['related_role_id', 'related_entity_id'])
							handleChange()
						}}
						options={{
							values: [
								{ value: 0, text: 'All' },
								{ value: 1, text: 'Organisation' },
								{ value: 2, text: 'People' },
							]
						}}
					/>

					{/* Tag */}
					<InputComponent
						type="select" mode='multiple' label="Tag/s" name="tag" placeholder="Select One or More Tags"
						tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						onChange={() => { handleTagChange(); handleChange() }}
						onFocus={() => fetchTag && RoleMappingStore.getAllTagsList().then(() => setFetchTag(false))}
						notFoundContent={fetchTag ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: RoleMappingStore.dropdown_tags_list,
							value_key: 'id',
							text_key: 'tag_name',
						}}
					/>

					{(formDataList.includes('role_id')) && (
						<InputComponent
							allowClear type="select" label="Org. Role" name="role_id"
							placeholder={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0 ? "Please select Related Entity Type first Organisation or People" : "Select Organisation Role"}
							tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
							mode='multiple'
							onChange={(data) => {
								getOrgRelatedData()
								setSelectedRoleID(data)
								form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
								handleChange()
							}}
							disabled={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0}
							onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
							notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.role_data && props.page === "Filter" ?
									CashTransactionStore.filterData.role_data.map((item) => item && {
										id: item.id, role_name: item.role_name
									}) : SavedReportStore.main_organisation_roles,
								value_key: 'id',
								text_key: 'role_name',
							}}
						/>
					)}

					{(formDataList.includes('entity_id')) && (
						<InputComponent
							allowClear type="select" label="Organisation" name="entity_id"
							placeholder={selectedRoleID ? "Select Organisation" : "Please select Org. Role first"}
							disabled={!selectedRoleID}
							tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
							mode='multiple'
							onChange={() => {
								getOrgRelatedData()
								form.resetFields(['related_role_id', 'related_entity_id'])
								handleChange()
							}}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.entity_data && props.page === "Filter" ? CashTransactionStore.filterData.entity_data.map((item) => item && {
									organisation_id: item.organisation_id, organisation_name: item.organisation_name
								}) : SavedReportStore.main_organisation,
								value_key: 'organisation_id',
								text_key: 'organisation_name',
							}}
						/>
					)}

					{(formDataList.includes('main_people_role')) && (
						<InputComponent
							allowClear type="select" label="People Role" name="role_id"
							placeholder={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0 ? "Please select Related Entity Type first Organisation or People" : "Select People Role"} mode='multiple'
							tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
							onChange={(data) => {
								getOrgRelatedData()
								setSelectedRoleID(data)
								form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
								handleChange()
							}}
							disabled={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0}
							onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
							notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.role_data && props.page === "Filter" ?
									CashTransactionStore.filterData.role_data.map((item) => item && {
										id: item.id, role_name: item.role_name
									}) : SavedReportStore.main_people_roles,
								value_key: "id",
								text_key: "role_name",
							}}
						/>
					)}

					{(formDataList.includes('main_people')) && (
						<InputComponent
							allowClear type="select" label="People" name="entity_id"
							placeholder={selectedRoleID ? "Select People" : "Please select People Role first"}
							disabled={!selectedRoleID} mode='multiple'
							tooltip="People is an entity (for example a Person) that has (or had in the past) – directly or indirectly - any business relationship with Credebt Exchange or with another Organisation"
							onChange={() => {
								getOrgRelatedData()
								form.resetFields(['related_role_id', 'related_entity_id'])
								handleChange()
							}}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.entity_data && props.page === "Filter" ? CashTransactionStore.filterData.entity_data.map((item) => item && {
									people_id: item.people_id, people_name: item.people_name
								}) : SavedReportStore.main_people,
								value_key: "people_id",
								text_key: "people_name",
							}}
						/>
					)}

					{(formDataList.includes('related_role_id')) && (
						<InputComponent
							allowClear type="select" label="Rel. Org. Role" name="related_role_id"
							placeholder={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0 ? "Please select Entity Type first Organisation or People" : (selectedRoleID ? "Select Related Organisation Role" : "Please select Org. Role first")}
							disabled={!selectedRoleID}
							tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
							mode='multiple'
							onChange={() => {
								getOrgRelatedData()
								form.resetFields(['related_entity_id'])
								handleChange()
							}}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.related_role_data && props.page === "Filter" ? CashTransactionStore.filterData.related_role_data.map((item) => item && {
									id: item.id, role_name: item.role_name
								}) : SavedReportStore.related_organisation_role,
								value_key: 'id',
								text_key: 'role_name',
							}}
						/>
					)}

					{(formDataList.includes('related_entity_id')) && (
						<InputComponent
							allowClear type="select" label="Rel. Organisation" name="related_entity_id"
							placeholder={selectedRoleID ? "Select Related Organisation" : "Please select Org. Role first"}
							disabled={!selectedRoleID}
							tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
							mode='multiple'
							onChange={handleChange}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.related_entity_data && props.page === "Filter" ? CashTransactionStore.filterData.related_entity_data.map((item) => item && {
									organisation_id: item.organisation_id, organisation_name: item.organisation_name
								}) : SavedReportStore.related_organisation,
								value_key: 'organisation_id',
								text_key: 'organisation_name',
							}}
						/>
					)}

					{(formDataList.includes('related_people_role')) && (
						<InputComponent
							allowClear type="select" label="Rel. People Role" name="related_role_id"
							placeholder={form.getFieldValue("related_entity_type") === 0 || form.getFieldValue("entity_type") === 0 ? "Please select Entity Type first Organisation or People" : (selectedRoleID ? "Select Related People Role" : "Please select Org. Role first")}
							tooltip="People Role Relation is the connection between the Roles played by two different People."
							disabled={!selectedRoleID} mode='multiple'
							onChange={() => {
								getOrgRelatedData()
								form.resetFields(['related_entity_id'])
								handleChange()
							}}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.related_role_data && props.page === "Filter" ? CashTransactionStore.filterData.related_role_data.map((item) => item && {
									id: item.id, role_name: item.role_name
								}) : SavedReportStore.related_people_role,
								value_key: "id",
								text_key: "role_name",
							}}
						/>
					)}

					{(formDataList.includes('related_people')) && (
						<InputComponent
							allowClear type="select" label="Rel. People" name="related_entity_id"
							placeholder={selectedRoleID ? "Select Related People" : "Please select Rel. People Role first"}
							tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
							disabled={!selectedRoleID} mode='multiple'
							onChange={handleChange}
							options={{
								values: CashTransactionStore.filterData && CashTransactionStore.filterData.related_entity_data && props.page === "Filter" ? CashTransactionStore.filterData.related_entity_data.map((item) => item && {
									people_id: item.people_id, people_name: item.people_name
								}) : SavedReportStore.related_people,
								value_key: "people_id",
								text_key: "people_name",
							}}
						/>
					)}

					<InputComponent
						type="select" mode='multiple' label="Bank/s" name="banks" placeholder="Select Bank/s"
						tooltip="A name or code given to a cash account that indicates the account's purpose."
						onChange={handleChange}
						onFocus={() => fetchBanks && OrgBankAccountStore.getBanksNameList().then(() => setFetchBanks(false))}
						notFoundContent={fetchBanks ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: OrgBankAccountStore.bankNameList,
							value_key: 'id',
							text_key: 'name',
						}}
					/>

					<Divider>AND</Divider>

					<InputComponent
						type="select" mode='multiple' label="Bank Account/s" name="bank_account_id" placeholder="Select Bank Account/s"
						tooltip="A name or code given to a cash account that indicates the account's purpose."
						onChange={handleChange}
						onFocus={() => fetchAccounts && CTEImportStore.getBankList().then(() => setFetchAccounts(false))}
						notFoundContent={fetchAccounts ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: CTEImportStore.bank_list,
							value_key: 'id',
							text_key: 'bankAccount_names',
						}}
					/>

					<Divider>AND</Divider>

					{/* Cash Transaction Classification */}
					<InputComponent
						type="select" mode='multiple' label="Cash Transaction Classification/s" name="cte_transaction_classification_id" placeholder="Select Cash Transaction Classification/s"
						tooltip="Classifications are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						onChange={handleChange}
						onFocus={() => fetchClassfication && ClassificationStore.getClassfications().then(() => setFetchClassification(false))}
						notFoundContent={fetchClassfication ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.classificationList,
							value_key: 'id',
							text_key: 'transaction_class_name',
						}}
					/>

					<Divider>AND</Divider>

					{/* Transaction Type */}
					<InputComponent
						type="select" mode='multiple' label="Transaction Type/s" name="cte_transactions_type_id" placeholder="Select Transaction Type/s"
						tooltip="Cash Transaction Type indicates the type of cash transaction as defined by the bank. The parameter can be obtained - if available - from the Transaction Type or Transaction Details, i.e. Direct Debit, Transfer, etc.."
						onChange={handleChange}
						onFocus={() => fetchType && ClassificationStore.getCteTypes().then(() => setFetchType(false))}
						notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.cteTypesList,
							value_key: 'id',
							text_key: 'type_name',
						}}
					/>

					<Divider>AND</Divider>

					{/* Currency */}
					<InputComponent
						type="select" mode='multiple' label="Currency/s	" name="currency_id" placeholder="Select Currency/s"
						tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
						onChange={handleChange}
						onFocus={() => fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: OrgBankAccountStore.dropdown_currency_list,
							value_key: 'id',
							text_key: 'currency_code',
						}}
					/>

					<Divider>AND</Divider>

					{/* Exchange Reference ID */}
					<InputComponent
						label="Exchange Ref ID" name="exchange_ref_id" placeholder="Enter Exchange Reference ID"
						tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
						maxLength={100}
						rules={vsmCashTransactions.validation.exchange_ref_id}
					/>

					<Divider>AND</Divider>

					{/* Bank Reference */}
					<RepeaterElement
						label="Bank Reference"
						name="bank_reference"
						placeholder="Enter Bank Reference"
						tooltip="Bank Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmClassifications.validation.bank_reference}
					/>

					<Divider>AND</Divider>

					{/* Customer Reference */}
					<RepeaterElement
						label="Customer Reference"
						name="customer_reference"
						placeholder="Enter Customer Reference"
						tooltip="Customer Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmClassifications.validation.customer_reference}
					/>

					<Divider>AND</Divider>

					{/* Custom Fields */}
					<CustomFieldsElement
						onChange={handleChange}
						initialValue={"="}
						form={form}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default FilterComponent
