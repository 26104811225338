import React from 'react'
import Moment from 'moment'
import NumberFormat from 'react-number-format'

export const DateComparator = (filterLocalDateAtMidnight, cellValue) => {
	//using moment js
	var dateAsString = Moment(cellValue).format('DD/MM/YYYY')
	var dateParts = dateAsString.split("/")
	var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
		return 0
	}

	if (cellDate < filterLocalDateAtMidnight) {
		return -1
	}

	if (cellDate > filterLocalDateAtMidnight) {
		return 1
	}
}

// export const defaultData = {
// 	44513401: 'EUR Exchange',
// 	44513402: 'LDC',
// 	44513403: 'Investor',
// 	44513404: 'Clearing',
// 	44513407: 'Supplier'
// }

export const defaultData = {
	receipts: {
		44513401: 'EUR Exchange ',
		44513402: 'LDC',
		44513403: 'Investor',
		44513404: 'Clearing',
		// 44513404:  'Clearing EUR',
		44513407: 'Supplier',
		73796558: ' Clearing GBP',
		44513406: ' Clearing USD',
		44513405: ' Investor USD',
		73119157: ' GBP Clearing',
		33049957: 'Investor',
		46049401: 'Trade Credebt',
		46049402: ' Trade Credebt'
	},
	payments: {
		44513401: 'Exchange',
		// 44513401: ' Exchange EUR',
		44513402: 'LDC',
		44513403: 'Investor',
		// 44513403 :'LDC EUR',
		44513404: 'Current',
		// 44513404: ' Clearing EUR',
		// 44513404: ' Clearing LDC EUR',
		44513406: 'USD Clearing',
		44513407: 'Supplier',
		// 44513407: ' Operating '
		46049401: ' Trade Credebt',
		73119157: ' Exchange',
		33049957: 'Investor',
		73796558: ' Clearing',

	}
}

export const OrgOriginatorID = 13

export const sequenceAccArray = [44513404, 44513403, 44513402, 44513402, 44513401, 73796558, 73119157, 33049957, 44513405, 44513406, 44513407, 46049400, 46049401, 46049402,]

export const globalStatus = (module_name, param_type, param) => {
	let cash_transaction_status = {
		0: 'Pending',
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified',
		6: "Internal / Identified"
	}
	let reconcile_status = {
		0: 'Pending(Identified)',
		1: 'Partially Reconciled',
		2: 'Fully Reconciled'
	}
	let cash_import = {
		1: 'Finished',
		2: 'Partially Finished',
		3: 'In Queue',
		4: 'In Progress',
		5: 'Failed with Error',
		6: 'Canceled',
		7: 'Deleted',
	}
	let cash_import_log = {
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified',
	}
	// let reconcile_transaction_status = {
	// 	0: "Pending(Identified)",
	// 	1: "Partially Reconciled",
	// 	2: "Fully Reconciled"
	// }
	let reconcile_transaction_status = {
		0: 'Pending',
		1: 'Internal / Identified ',
		2: 'Internal / Reconciled',
		3: 'Payment / Identified',
		4: 'Payment / Recognised',
		5: 'Receipts  / Identified',
		6: 'Receipts / Recognised',
		9: 'Pending',
		10: 'Pending',
		21: 'Charges',
		22: 'Interests',
	}
	let ppsn_document_type = {
		1: 'Legal Correspondence',
		2: 'P21 (Tax Balancing Statement)',
		3: 'Tax Assessment',
		4: 'Notice of Tax Credits',
		5: 'Payslip',
		6: 'P60',
		7: 'P45'
	}
	switch (module_name) {
		case 'cash_transaction_status':
			return getStatusValue(cash_transaction_status, param_type, param)
		case 'reconcile_status':
			return getStatusValue(reconcile_status, param_type, param)
		case 'cash_import':
			return getStatusValue(cash_import, param_type, param)
		case 'cash_import_log':
			return getStatusValue(cash_import_log, param_type, param)
		case 'reconcile_transaction_status':
			return getStatusValue(reconcile_transaction_status, param_type, param)

		case 'ppsn_document_type':
			return getStatusValue(ppsn_document_type, param_type, param)

		default:
			return null
	}
}

const getStatusValue = (array, param_type, param) => {
	if (param_type === 'key') {
		return array[param]
	} else {
		return parseInt(Object.keys(array).find((x) => array[x] === param ? x : false))
	}
}

export const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
	)
}

export const CurrencySorting = (value1, value2) => {
	if (value1 === null && value2 === null) {
		return 0;
	}
	if (value1 === null) {
		return -1;
	}
	if (value2 === null) {
		return 1;
	}
	return value1 - value2;
}

export const convertTextToID = (text_array, main_array, text_key, id_key) => {
	let new_array = []
	if (text_array && text_array.values && text_array.values.length > 0) {
		text_array.values.forEach(x => {
			var temp = main_array.find(y => y[text_key] === x)
			if (x && temp) { new_array.push(temp[id_key]) } else {
				insertAt(new_array, 0, x)
			}
		})
	}
	return new_array
}

function insertAt(array, index, ...elementsArray) {
	array.splice(index, 0, ...elementsArray);
}

// module path
export const relatedOrgPath = "/organisations/all-organisations/role-mapping"
export const relatedPeoplePath = "/people/all-people/role-mapping"
export const orgBanksPath = "/organisations/all-organisations/bank-accounts"
export const peopleBanksPath = "/people/all-people/bank-accounts"
export const allTransactionPath = "/trade-transactions/all-transactions"
export const cashTransactionPath = "/cash-transactions/all-transaction"
export const peoplePhotoURL = process.env.React_APP_API_URL + 'storage/peoplephoto/'

export const convertError = (formatedErrors) => {
	formatedErrors.forEach((x) => {
		if (x.name.indexOf(".") !== -1) {
			x.name = x.name.split(".");
			x.name.forEach((e, i) => {
				if (!isNaN(parseInt(e))) {
					x.name[i] = parseInt(e);
				}
			});
		}
	});
	return formatedErrors
}
