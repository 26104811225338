import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button } from 'antd'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import useStore from '../../../../store'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import Unauthorized from '../../../Unauthorized'
import RecordPerPage from '../../../../components/RecordPerPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const Roles = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { PeopleRolesStore, UserStore } = useStore()

	// Open form for add new People
	const openAddModal = () => {
		setAddModal(true)
	}

	// Close form for close new People
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing People and set values to form
	const openEditModal = (data) => {
		PeopleRolesStore.setEditValues(data)
		setEditModal(true)
	}

	// Close form of edit People
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing People
	const openDeleteModal = (data) => {
		PeopleRolesStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('ppl_role_grid')
		PeopleRolesStore.agGrid.api.setFilterModel(null)
		PeopleRolesStore.agGrid.api.setSortModel(null)
		PeopleRolesStore.agGrid.columnApi.resetColumnState()
		PeopleRolesStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.People_roles.path} />
			{(!UserStore.checkPermission(44, 'list')) ? <Unauthorized /> : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.People_roles.title}
					extra={[
						UserStore.checkPermission(44, 'add') && <Button key="1" onClick={openAddModal}>New Role</Button>,

						<RecordPerPage key="2" defaultValue={PeopleRolesStore.per_page + ' per page'} onChange={PeopleRolesStore.setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					<AddComponent tab={"new"} visible={addModal} close={closeAddModal} />
					<EditComponent tab={"edit"} visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
				</PageHeader>
			)}
		</>
	)
})

export default Roles
