import React from 'react'
import { Switch, Tooltip } from 'antd'
import useStore from '../../../../store'

const SwitchComponent = (props) => {
	const { UserStore: { user } } = useStore()
	var defaultChecked = user.user_role.user_role_id === 2 ? props.values.ledger_status : props.values.status

	return (
		<div>
			{
				(props.values && user.user_role.user_role_id === 1 && props.values.ledgers_currency_count > 0) ?
					<Tooltip color={"red"} title={"Sorry, you cannot deactivate this Currency, as it is associated with ledger’s base currency."}>
						<Switch
							size='small'
							disabled
							defaultChecked={defaultChecked}
							onChange={(val) => props.onSwitchChange(val, props.values)}
						/>
					</Tooltip>
					:
					<Switch
						size='small'
						defaultChecked={defaultChecked}
						onChange={(val) => props.onSwitchChange(val, props.values)}
					/>
			}
		</div>
	)
}

export default SwitchComponent
