import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import useStore from '../../../store'
import ContentLoader from '../../../components/ContentLoader'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { Button, PageHeader } from 'antd'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import RecordPerPage from '../../../components/RecordPerPage'
import PageNotFound from '../../PageNotFound'
import Unauthorized from '../../Unauthorized'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import ActivateComponent from './component/ActivateComponent'
import DeactivateComponent from './component/DeactivateComponent'
import EditComponent from './component/EditComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const BankAccounts = observer(() => {
	const { id } = useParams()
	const [loading, setLoading] = useState('pending')
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [activateModal, setActivateModal] = useState(false)
	const [deactivateModal, setDeactivateModal] = useState(false)
	const [accountId, setAccountId] = useState(null)
	const { PeopleStore, PeopleBankAccountStore, UserStore } = useStore()

	// set id on edit drawer
	const setId = (id) => setAccountId(id)

	// Open form for add new bank account
	const openAddModal = () => {
		setAddModal(true)
	}

	// Close form for add new bank account
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing bank account and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		setEditModal(true)
	}

	// Open form for edit bank account
	const closeEditModal = () => setEditModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		PeopleBankAccountStore.setCurrentValues(data)
		PeopleBankAccountStore.agGrid.api.refreshCells({ force: true })
		if (!checked) {
			setDeactivateModal(true)
		} else {
			setActivateModal(true)
		}
	}

	// Close confirmation alert for activate bank account
	const closeActivateModal = () => setActivateModal(false)

	// Close confirmation alert for deactivate bank account
	const closeDeactivateModal = () => setDeactivateModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('ppl_bank_account_grid')
		PeopleBankAccountStore.agGrid.api.setFilterModel(null)
		PeopleBankAccountStore.agGrid.api.setSortModel(null)
		PeopleBankAccountStore.agGrid.columnApi.resetColumnState()
		PeopleBankAccountStore.agGrid.api.onFilterChanged(null)
	}

	useEffect(() => {
		var payload = { people_id: id }
		PeopleStore.getRecordDetail(payload).then(data => {
			setLoading(200)
		}).catch(data => {
			if (data.status === 404) {
				setLoading(404)
			}
			if (data.status === 403) {
				setLoading(403)
			}
		})
		PeopleBankAccountStore.getList(payload)
	}, [PeopleStore, id, PeopleBankAccountStore])

	if (loading === 'pending') {
		return <ContentLoader />
	}

	if (loading === 200) {
		return (
			<>
				<BreadcrumbComponent items={[...BreadcrumbConfig.People_BankAccounts.path,
				{ name: `${PeopleStore.peopleValues && PeopleStore.peopleValues.first_name + ' ' + (PeopleStore.peopleValues.last_name ? PeopleStore.peopleValues.last_name : '')}` }, { name: 'Bank Accounts' }
				]} />
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Organisations_BankAccounts.title}
					extra={[
						UserStore.checkPermission(41, 'bank_accounts_add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={PeopleBankAccountStore.per_page + ' per page'} onChange={PeopleBankAccountStore.setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} onSwitchChange={onSwitchChange} />
					<AddComponent visible={addModal} close={closeAddModal} parent_id={id} />
					<EditComponent visible={editModal} close={closeEditModal} parent_id={id} accountId={accountId} setId={setId} />
					<ActivateComponent visible={activateModal} close={closeActivateModal} />
					<DeactivateComponent visible={deactivateModal} close={closeDeactivateModal} />
				</PageHeader>
			</>
		)
	} else {
		if (loading === 404) {
			return (
				<PageNotFound />
			)
		}
		if (loading === 403) {
			return (
				<Unauthorized />
			)
		}

	}

})

export default BankAccounts
