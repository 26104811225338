import React, { useCallback, useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Form, PageHeader } from 'antd';
import useStore from '../../../../store';
import ViewElement from "../elements/ViewElement";
import { useParams } from 'react-router';
import ContentLoader from '../../../../components/ContentLoader';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig';

const ViewComponent = observer((props) => {

	const [form] = Form.useForm()
	const { id } = useParams()
	const { PeopleStore, UserStore } = useStore()
	const [loading, setLoading] = useState('pending')

	const handleViewDetailCall = useCallback(() => {
		setLoading('pending')
		PeopleStore.getViewPplDetail(id).then(data => {
			setLoading(200)
		}).catch(data => {
			if (data.status === 404) {
				setLoading(404)
			}
			if (data.status === 403) {
				setLoading(403)
			}
		})
	}, [PeopleStore, id])

	useEffect(() => {
		handleViewDetailCall()
	}, [handleViewDetailCall])

	if (loading === 'pending') {
		return <ContentLoader />
	}
	if (loading === 200) {
		return (
			<>
				<BreadcrumbComponent items={[...BreadcrumbConfig.view_people.path, { name: `${PeopleStore.peopleValues && PeopleStore.peopleValues.name}` }]} />
				<PageHeader
					title={PeopleStore.peopleValues && PeopleStore.peopleValues.name}
					onBack={() => window.history.back()}
					extra={[
						<div>
							<ul>
								<li>Total Transactions: {PeopleStore.peopleValues && PeopleStore.peopleValues.transactions_count}</li>
								<li>Total Transaction Value: {PeopleStore.peopleValues && PeopleStore.peopleValues.transactions_total + ' ' + UserStore.ledger_base_currency.currency_code}</li>
							</ul>
						</div>
					]}
				>
					<ViewElement
						form={form}
						id={id}
						peopleValues={PeopleStore.peopleValues}
						handleViewDetailCall={handleViewDetailCall}
					/>
				</PageHeader>
			</>
		)
	} else {
		if (loading === 404) {
			return (
				<PageNotFound />
			)
		}
		if (loading === 403) {
			return (
				<Unauthorized />
			)
		}

	}
})

export default ViewComponent
