import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, PageHeader } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import AddComponent from './components/AddComponent'
import ListComponent from './components/ListComponent'
import DeleteComponent from './components/DeleteComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import EditComponent from './components/EditComponent'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const Classifications = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [classificationId, setClassificationId] = useState(null)
	const { ClassificationStore: { per_page, agGrid, setDeleteValues, setPageSize }, UserStore } = useStore()

	// set id on edit drawer
	const setId = (id) => setClassificationId(id)

	// Open form for add new regular expression
	const openAddModal = () => setAddModal(true)

	// Close form for close new regular expression
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing regular expression and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		setEditModal(true)
	}

	// Close form of edit regular expression
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing Classification
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('cte_classification_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Classifications.path} />
			{(!UserStore.checkPermission(80, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Classifications.title}
					extra={[
						UserStore.checkPermission(80, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent classificationId={classificationId} setId={setId} visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
				</PageHeader>
			)}
		</>
	)
})

export default Classifications
