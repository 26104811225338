import Axios from "axios"
import { decorate, observable, action } from "mobx"
import GridConfig from "../../../config/GridConfig"
import { convertTextToID } from "../../../utils/GlobalFunction"

export default class ManualJournalLogStore {
	list_data = null
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	reverseValues = null
	deleteValues = null
	agGrid = null

	filter_model = []
	filter_journal = []
	filter_transaction = []
	mainDropdown = null
	dropdown = [{}]

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to reverse
	setReverseValues = (data) => {
		this.reverseValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	getFilter = (payload) => {
		return Axios.get(`JournalLog/filter/get/${payload}`).then(data => {
			if (payload === "model") { this.filter_model = data.data.data }
			if (payload === "journal") { this.filter_journal = data.data.data }
			if (payload === "transaction") { this.filter_transaction = data.data.data }
			return data.data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// get all records with limited data
	getAllRecords = (payload, index) => {
		return Axios.post(`JournalLog/getdropdown/list`, payload).then(({ data }) => {
			if (!this.mainDropdown) { this.mainDropdown = data.data }
			var arr = Object.assign({}, this.dropdown)
			arr[index] = data.data
			this.dropdown = arr
			return data.data
		}).catch(({ data }) => { return data })
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		let columnConfig = localStorage.getItem('manual_journal_log_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('manual_journal_log_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('manual_journal_log_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }
				if (filter_data.final_filter['model.model_code']) {
					filter_data.final_filter['model.model_code'].values = convertTextToID(filter_data.final_filter['model.model_code'], this.filter_model, 'model_code', 'id')
				}
				if (filter_data.final_filter['model.model_name']) {
					filter_data.final_filter['model.model_name'].values = convertTextToID(filter_data.final_filter['model.model_name'], this.filter_model, 'model_name', 'id')
				}
				if (filter_data.final_filter['journal.journal_code']) {
					filter_data.final_filter['journal.journal_code'].values = convertTextToID(filter_data.final_filter['journal.journal_code'], this.filter_journal, 'journal_code', 'id')
				}
				if (filter_data.final_filter['journal.journal_label']) {
					filter_data.final_filter['journal.journal_label'].values = convertTextToID(filter_data.final_filter['journal.journal_label'], this.filter_journal, 'journal_label', 'id')
				}
				if (filter_data.final_filter['transaction_type.type_name']) {
					filter_data.final_filter['transaction_type.type_name'].values = convertTextToID(filter_data.final_filter['transaction_type.type_name'], this.filter_transaction, 'type_name', 'id')
				}
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('manual_journal_log_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

	// call api to get records
	getList = (payload = {}) => {
		return Axios.post(`JournalLog/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					item.created_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page

			return data
		})
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`JournalLog/add`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`JournalLog/update/${formdata.id}`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call reverse api
	ReverseData = (formdata) => {
		return Axios.post(`JournalLog/reverse`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`JournalLog/delete/${formdata.id}`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(ManualJournalLogStore, {
	list_data: observable,
	total: observable,
	mainDropdown: observable,
	current_page: observable,
	per_page: observable,
	filter_model: observable,
	filter_journal: observable,
	filter_transaction: observable,
	editValues: observable,
	reverseValues: observable,
	deleteValues: observable,
	agGrid: observable,
	dropdown: observable,
	getList: action,
	EditData: action,
	ReverseData: action,
	setEditValues: action,
	setReverseValues: action,
	setDeleteValues: action,
	DeleteData: action,
	setupGrid: action,
	setPageSize: action,
	onFilterChanged: action,
	getAllRecords: action,
	getFilter: action,
	onGridChanged: action,
})
