import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import useStore from '../../../store'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import Unauthorized from '../../Unauthorized'
import RecordPerPage from '../../../components/RecordPerPage'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import ImportComponent from './component/ImportComponent'
import { default as MapAddComponent } from '../RoleMapping/component/AddComponent'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'
import { FileExcelOutlined } from '@ant-design/icons'

const People = observer(() => {
	let history = useHistory()
	const { PeopleStore, UserStore } = useStore()
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [deleteDrawer, setDeleteDrawer] = useState(false)
	const [mapDrawer, setMapDrawer] = useState(false)
	const [importDrawer, setImportDrawer] = useState(useLocation().search === '?import=1' && (UserStore.checkPermission(41, 'import_role_bank') || UserStore.checkPermission(41, 'import_people')))

	history.listen((location, action) => {
		if (location.search === '?import=1') {
			if (UserStore.checkPermission(41, 'import_role_bank') || UserStore.checkPermission(41, 'import_people')) {
				setImportDrawer(true)
			}
		}
	})

	//open add drawer
	const openAddDrawer = () => {
		setAddDrawer(true)
	}

	//close add drawer
	const closeAddDrawer = () => setAddDrawer(false)

	//open edit drawer
	const openEditDrawer = (data) => {
		var payload = { people_id: data.id }
		PeopleStore.getRecordDetail(payload)
		setEditDrawer(true)
	}

	//close edit drawer
	const closeEditDrawer = () => setEditDrawer(false)

	//open delet drawer
	const openDeleteDrawer = (data) => {
		setDeleteDrawer(true)
		PeopleStore.setDeleteValues(data)
	}

	//close delet drawer
	const closeDeleteDrawer = () => setDeleteDrawer(false)

	//Open Drawer to import data
	const importData = () => setImportDrawer(true)

	//Close import Drawer
	const closeImportDrawer = () => {
		setImportDrawer(false)
		history.replace(history.location.pathname)
	}

	//open bank account listing page
	const openBankAccounts = (data) => {
		history.push(`/people/all-people/bank-accounts/${data.id}`)
	}

	//open bank role listing page
	const openRoleListing = (data) => {
		history.push(`/people/all-people/role-mapping/${data.id}`)
	}

	const openViewDrawer = (data) => {
		history.push(`/people/all-people/view-people/${data.id}`)
	}

	//open map drawer
	const openMapDrawer = () => {
		PeopleStore.peopleValues = null
		setMapDrawer(true)
	}

	//close map drawer
	const closeMapDrawer = () => setMapDrawer(false)

	//Menu to show export Organisations and Organisations Roles
	const menu = (
		<Menu>
			{UserStore.checkPermission(41, 'export_people') && <Menu.Item key="people" onClick={PeopleStore.ExportPeople}>People</Menu.Item>}
			{UserStore.checkPermission(41, 'export_role_bank') && <Menu.Item key="people_roles" onClick={PeopleStore.ExportPeopleRoleMappint}>People Roles</Menu.Item>}
		</Menu>
	)

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.People.path} />
			{(!UserStore.checkPermission(41, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.People.title}
					extra={[
						(UserStore.checkPermission(41, 'import_people') || UserStore.checkPermission(41, 'import_role_bank')) && <Button key="1" onClick={importData}>Import</Button>,

						(!UserStore.checkPermission(41, 'export_people') && !UserStore.checkPermission(41, 'export_role_bank')) ? null :
							(
								(<Dropdown key="2" overlay={menu}><Button key="2" title='Export' onClick={e => e.preventDefault()}><FileExcelOutlined /></Button></Dropdown>)
							),

						(!UserStore.checkPermission(41, 'add')) ? null : (<Button key="3" onClick={openMapDrawer}>Map People with Current Ledger</Button>),

						UserStore.checkPermission(41, 'add') && <Button key="4" onClick={openAddDrawer}>Add People</Button>,

						<RecordPerPage key="5" defaultValue={PeopleStore.per_page + ' per page'} onChange={PeopleStore.setPageSize} />,

						<Button key="6" title="Reset" onClick={PeopleStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='7' />
					]}
				>
					<ListComponent openDeleteDrawer={openDeleteDrawer} openEditDrawer={openEditDrawer} openRoleListing={openRoleListing} openBankAccounts={openBankAccounts} openViewDrawer={openViewDrawer} />
					<AddComponent visible={addDrawer} close={closeAddDrawer} />
					<EditComponent visible={editDrawer} close={closeEditDrawer} />
					<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
					<ImportComponent visible={importDrawer} close={closeImportDrawer} importData={importData} />
					<MapAddComponent visible={mapDrawer} close={closeMapDrawer} callList={() => PeopleStore.setupGrid(PeopleStore.agGrid)} params='MapWithCurrentLedger' />
				</PageHeader>
			)}
		</>
	)
})

export default People
