import React, { useState } from 'react'
import { Form, Button, Modal, Input, Select, InputNumber, Spin, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { vsmTaxSettings, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import { observer } from 'mobx-react'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [add_tax_type_form] = Form.useForm()
	const { TaxSettingStore: { AddData, taxtype_list, AddTaxType, getAllTaxTypes }, UserStore } = useStore()
	const { UserStore: { user } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fetchTaxType, setFetchTaxType] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTaxSettings.add
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			if ((form.getFieldsValue().tax_value === "") || (form.getFieldsValue().tax_value === null) || (form.getFieldsValue().tax_value <= 0)) {
				form.setFieldsValue({
					tax_value: 0
				})
				setDisabled(false)
			} else {
				if (form.getFieldsValue().tax_value > 99.99) {
					form.setFieldsValue({
						tax_value: 99.99
					})
				}
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	// For adding tax type, only for super admin
	const addItem = (e) => {
		add_tax_type_form.validateFields().then((data) => {
			var payload = {
				enum_value: add_tax_type_form.getFieldsValue().enum_value,
				enum_type: "tax_type"
			}
			AddTaxType(payload).then(() => {
				getAllTaxTypes()
				add_tax_type_form.resetFields()
			}).catch(e => {
				if (e.errors) { add_tax_type_form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		})
	}

	return (
		<Modal
			centered
			title="New Tax Settings"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={650}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="tax_type" rules={vsmTaxSettings.validation.tax_type} label="Tax Type" required tooltip='Tax Type can be used to group taxes for easier identification, i.e. : VAT, Sales, Turnover.'>
							{
								(user.id !== 1) ? (
									<Select placeholder="Select Tax Type" onChange={handleChange}
										onFocus={() => fetchTaxType && getAllTaxTypes().then(() => setFetchTaxType(false))}
										notFoundContent={fetchTaxType ? <Spin size="small" /> : "No Record Found."}>
										{
											(taxtype_list && taxtype_list.map((item, index) => (
												<Select.Option key={index} value={item}>{item}</Select.Option>
											)))
										}
									</Select>
								) : (
									<Select
										placeholder="Add New Tax Type"
										onChange={handleChange}
										onFocus={() => fetchTaxType && getAllTaxTypes().then(() => setFetchTaxType(false))}
										notFoundContent={fetchTaxType ? <Spin size="small" /> : "No Record Found."}
										dropdownRender={(!UserStore.checkPermission(33, 'add_tax_type')) ? null : (
											menu => (
												<div>
													{menu}
													<Form form={add_tax_type_form} id="addtaxtypeform" className="add_item_dropdown_wrapper">
														<Form.Item name="enum_value" className="w-100 mb-0" rules={vsmTaxSettings.validation.enum_value}>
															<Input maxLength={50} />
														</Form.Item>
														<Button htmlType="button" className="ml-10" type="primary" form='addtaxtypeform' onClick={addItem}>
															<PlusOutlined /> Add item
														</Button>
													</Form>
												</div>
											)
										)}
									>
										{
											(taxtype_list && taxtype_list.map((item, index) => (
												<Select.Option key={index} value={item}>{item}</Select.Option>
											)))
										}
									</Select>
								)
							}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="tax_name" rules={vsmTaxSettings.validation.tax_name} label="Tax Name" required tooltip='Tax Name is Name of Tax that can be applied to different type of transactions when they are created. Taxes are defined based on Government regulations in your country and are linked with Journals, i.e. Sales Vat, Tax on Goods, No Tax.'>
							<Input placeholder="Enter Tax Name" maxLength={50} autoComplete="off" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="tax_value" initialValue={0} rules={vsmTaxSettings.validation.tax_value} label="Tax Value" required tooltip='Tax Value is a Percentage value defined by Government to be used or applied on different type of goods or services (transactions).'>
							<InputNumber min={0} max={99.99} step={0.01} formatter={value => `${value}%`} parser={value => value.replace('%', '')} onChange={handleChange} autoComplete="off" className='w-100' />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
})

export default AddComponent
