import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Select, Checkbox, Tooltip, Card, Typography, Spin, Row, Col } from 'antd'
import useStore from '../../../../../../store'
import { observer } from 'mobx-react'
import { vsmNotify, vsmTransactionFieldMapping } from '../../../../../../config/messages'
import { InfoCircleOutlined, PlusOutlined, MinusOutlined, MenuOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fields, setFields] = useState(null);
	const [fetchType, setFetchType] = useState(true)
	const [fetchSubType, setFetchSubType] = useState(true)
	const { TransactionFieldMappingStore, TransactionTypeStore, TransactionSubTypeStore } = useStore()

	const store = {
		Mapping: TransactionFieldMappingStore,
		Type: TransactionTypeStore,
		SubType: TransactionSubTypeStore,
		Fields: TransactionFieldMappingStore
	}
	var field_list = store.Fields.transaction_field_list

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = store.Mapping.editValues.id
		data.selected_tte_fields = fields.filter(x => (x !== null))
		if (data.selected_tte_fields.length <= 0) {
			data.selected_tte_fields = null
		}
		store.Mapping.EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmTransactionFieldMapping.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit & // call api for select dropdowns
	useEffect(() => {
		if (store.Mapping.editValues) {
			if (!fields) {
				let type = store.Mapping.editValues.transaction_type
				let subtype = store.Mapping.editValues.transaction_sub_type
				store.Type.dropdown_list = type && [type]
				store.SubType.dropdown_list = subtype && [subtype]
				form.setFieldsValue({
					mapping_name: store.Mapping.editValues.mapping_name,
					transactions_type_id: store.Mapping.editValues.transactions_type_id,
					transactions_sub_type_id: store.Mapping.editValues.transactions_sub_type_id,
					friendly_name: store.Mapping.editValues.friendly_name,
					has_items: store.Mapping.editValues.has_items,
				})
				store.Mapping.editValues.selected_tte_fields.map(Number)
				setFields(store.Mapping.editValues.selected_tte_fields.map(x => x = (x !== 0) ? x : null))
			}
		}
	}, [store, form, fields])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = async () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		setDisabled(true)
		form.resetFields()
		setFetchType(true)
		setFetchSubType(true)
	}

	// set values of multiple dropdown
	function handleSelectChange(value, i) {
		const values = [...fields];
		values[i] = value;
		setFields(values);
		handleChange();
	}

	// add dropdown field
	function handleAdd() {
		const values = [...fields];
		values.unshift(null);
		setFields(values);
	}

	// remove dropdown field
	function handleRemove(i) {
		const values = [...fields];
		values.splice(i, 1);
		setFields(values);
	}

	// set array on drag end
	const onSortEnd = ({ oldIndex, newIndex }) => {
		setFields(arrayMove(fields, oldIndex, newIndex))
	};

	// set menu icon component
	const DragHandle = SortableHandle(() => <div className="mr-20"><MenuOutlined /></div>)

	// set SortableItem component for sort
	const SortableItem = SortableElement(({ value }) => (
		<div className="fields_draggable_wrapper">
			<DragHandle />
			<Select showSearch allowClear defaultValue={value.value} className="w-100" placeholder="Select Transaction Field"
				optionFilterProp="children"
				onChange={(val) => handleSelectChange(val, value.index)}
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
			>
				{
					(field_list && field_list.map((item, i) => (
						(!fields.includes(item.id) || value.value === item.id) ? (
							<Select.Option key={i} value={item.id}>{item.field_name}</Select.Option>
						) : null
					)))
				}
			</Select>
			{
				(field_list && field_list.length > 1) ? (
					<div className="d-flex ml-10">
						{(fields.length > 1) && (
							<Button type="primary" danger onClick={() => { handleRemove(value.index) }}><MinusOutlined /></Button>
						)}
						{(value.index === 0) && (fields.length < 50) &&
							(fields.length < field_list.length) &&
							(
								<Button className="ml-5" type="primary" onClick={() => { handleAdd() }}><PlusOutlined /></Button>
							)
						}
					</div>
				) : null
			}

		</div>
	));

	// set SortableList component for Sortable container
	const SortableList = SortableContainer(({ items }) => (
		<div className="fields_draggable_container">
			{
				items.map((value, index) => (
					<SortableItem key={index} index={index} value={{ value, index }} />
				))
			}
		</div>
	))

	return store.Mapping.editValues ? (
		<Modal
			centered
			title={`Map Trade Type, Transaction Type and Trade Transaction`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="transactions_type_id" rules={vsmTransactionFieldMapping.validation.type} label="Trade Type" required tooltip='Trade Type is a main category of Trade (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Trade Types can further be classified by Transaction Type.'>
							<Select showSearch allowClear placeholder="Select Trade Type"
								optionFilterProp="children"
								onChange={handleChange}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onFocus={() => fetchType && store.Type.getAllRecords().then(() => setFetchType(false))}
								notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
							>
								{
									(store.Type.dropdown_list && store.Type.dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.type_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="transactions_sub_type_id" rules={vsmTransactionFieldMapping.validation.sub_type} label="Transaction Type" required tooltip='Transaction Type is used to further classify Transactions and allow for trade transaction of specific Transaction Models against specific Trade Types and Transaction Types.'>
							<Select showSearch allowClear placeholder="Select Transaction Types"
								optionFilterProp="children"
								onChange={handleChange}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								onFocus={() => fetchSubType && store.SubType.getAllRecords().then(() => setFetchSubType(false))}
								notFoundContent={fetchSubType ? <Spin size="small" /> : "No Record Found."}
							>
								{
									(store.SubType.dropdown_list && store.SubType.dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.sub_type_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="mapping_name" rules={vsmTransactionFieldMapping.validation.mapping_name} label="Trade Transaction Name" required tooltip='Trade Transaction is used to give unique custom name of trade type and transaction type combination. It is kind of label that will be used for models and transactions.'>
							<Input placeholder="Trade Transaction Name" maxLength={100} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="friendly_name" rules={vsmTransactionFieldMapping.validation.friendly_name} label="Friendly Name" required tooltip='Friendly Name is used to give unique custom name of trade type and transaction type combination. It is kind of label that will be used for models and transactions.'>
							<Input placeholder="Friendly Name" maxLength={100} />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item name="has_items" valuePropName="checked" value="1" tooltip='Check this checkbox if you want to associate this combination of Type and Sub Type with Transaction Items to use while importing or creating new Transaction.'>
					<Checkbox>Can contain transaction items</Checkbox>
				</Form.Item>
			</Form>

			<Card hoverable={false}>
				<div className="d-flex justify-content-center align-items-center">
					<Typography.Text required>Select one or more Transaction Fields to associate</Typography.Text>
					<Tooltip title="Add up to 50 Transaction Fields to associate them with this combination of Type and Sub Type. All field values will be generated and stored in same order while importing or creating transaction under same Type/Sub Type.">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</div>
				{
					(fields) && <SortableList useDragHandle={true} items={fields} onSortEnd={onSortEnd} />
				}
			</Card>
		</Modal>
	) : null
})

export default EditComponent
