import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { Button, Drawer, PageHeader } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useCallback, useRef } from 'react'
import NumberFormat from 'react-number-format'
import LocalGridConfig from '../../../../config/LocalGridConfig'
import useStore from '../../../../store'

const PreviewDetailAuditTable = observer((props) => {
	const { SavedReportStore } = useStore()
	const gridRef = useRef();
	let columnDefs = [{
		headerName: 'Group On',
		field: 'group_on',
		filter: false,
		sortable: false,
		pinned: 'left',
		valueFormatter: function (params) {
			let _temp = params.data.group_on.split('-')
			if (props.FYyear && _temp.length > 1) {
				if (params.node.rowIndex < 9) {
					return props.FYyear + '-W0' + (params.node.rowIndex + 1)
				} else {
					return + props.FYyear + '-W' + (params.node.rowIndex + 1)
				}
			} else {
				return params.data.group_on
			}
		}
	}]
	let list_data = []
	let list_object = {}
	let PinnedData = []
	if (props.AccountDetail) {

		Object.keys(props.AccountDetail).forEach((group) => {
			if (group === 'columns') {
				Object.keys(props.AccountDetail[group]).forEach((HeaderGroup, index) => {
					if (HeaderGroup !== 'all_receipt_sum' && HeaderGroup !== 'all_peyment_sum' && HeaderGroup !== 'opening_balance') {
						if (props.DataType === null || props.DataType === HeaderGroup) {
							let coldef = {
								headerName: HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' '),
								children: []
							}
							let sub_columns = props.AccountDetail[group][HeaderGroup]
							sub_columns && sub_columns.forEach(Header => {
								coldef.children.push({
									headerName: Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' '),
									headerGroupComponentFramework: (params) => {
										return (
											<div className="d-flex align-items-center h-100 cs_pointer">{Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}</div>
										)
									},
									field: `${HeaderGroup}.${Header}`,
									width: 80,
									cellRendererFramework: (params) => (
										(params.data && params.data[HeaderGroup] && params.data[HeaderGroup][Header]) ? (
											<div style={styles.cells}>
												<NumberFormat value={params.data[HeaderGroup][Header]} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
											</div>
										) : <div style={styles.cells}>0.00</div>
									),
									filter: false,
									sortable: false,
								})
							})
							columnDefs.push(coldef)
						}
					}
				})
			} else {
				var s_group = group.split('_')
				if (s_group[0] < 10) { s_group[0] = '0' + s_group[0] }
				var c_group = s_group.join('-')
				list_object[group] = {
					group_on: c_group,
					payments: props.AccountDetail[group].payments || {},
					receipts: props.AccountDetail[group].receipts || {},
					external: props.AccountDetail[group].external || {}
				}
			}
		})

		let list_total = {
			group_on: 'Total',
			group_range: 'Total',
			account_number: 'Total',
		}
		Object.keys(list_object).forEach((key) => {
			Object.keys(list_object[key]).forEach(account => {
				list_total[account] = list_total[account] || {}
				if (!['group_on', 'group_range', 'account_number'].includes(account)) {
					Object.keys(list_object[key][account]).forEach(value => {
						list_total[account][value] = list_total[account][value] || 0
						list_total[account][value] += list_object[key][account][value]
					})
				}

			})
		})

		if (props.AccountDetail.columns.all_peyment_sum || props.AccountDetail.columns.all_receipt_sum) {
			let diffrence_value = {
				group_on: 'Check',
				group_range: 'Check',
				account_number: 'Check',
			}
			let payments = {}
			let receipts = {}
			Object.keys(list_total).forEach(value => {
				if (!['group_on', 'group_range', 'account_number'].includes(value)) {
					Object.keys(list_total[value]).forEach(key => {
						if (!['main', 'errors'].includes(key)) {
							if (value === 'receipts') {
								Object.keys(props.AccountDetail.columns.all_receipt_sum).forEach(_key => {
									if (key === _key) {
										let temppp = list_total[value][key].toFixed(6) - props.AccountDetail.columns.all_receipt_sum[key].toFixed(6)
										receipts[_key] = temppp.toFixed(6)
									}
								})
							} else if (value === 'payments') {
								Object.keys(props.AccountDetail.columns.all_peyment_sum).forEach(_key => {
									if (key === _key) {
										let temppp = props.AccountDetail.columns.all_peyment_sum[key].toFixed(6) - list_total[value][key].toFixed(6)
										payments[_key] = temppp.toFixed(6)
									}
								})
							}
						}
					})
				}
			})
			diffrence_value.payments = payments
			diffrence_value.receipts = receipts
			PinnedData.push(diffrence_value)
		}

		PinnedData.push(list_total)

		let prev_key = null
		let display_key = ''
		Object.keys(list_object)
			.sort((a, b) => {
				if (['month', 'week'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
					var _a = a.split('_')
					var _b = b.split('_')
					_a[1] = parseInt(_a[1])
					_a[0] = parseInt(_a[0])
					_b[1] = parseInt(_b[1])
					_b[0] = parseInt(_b[0])
					if ((_a[1] < _b[1]) || (_a[1] === _b[1] && _a[0] < _b[0])) {
						return -1;
					}
					if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {
						return 1;
					}
				} else if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
					var dateA = new Date(a), dateB = new Date(b)
					return dateA - dateB
				} else {
					if (a < b) { return -1; }
					if (a > b) { return 1; }
				}
				return 0;
			})
			.forEach((key) => {
				let new_key = ''
				if (prev_key) {
					if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						let date = new Date(prev_key)
						while (date < new Date(key)) {
							date = new Date(date.setDate(date.getDate() + 1))
							list_data.push({
								group_on: moment(date).format('YYYY-MM-DD'),
								group_range: moment(date).format('YYYY-MM-DD'),
							})
						}
					}
					if (['week', 'month'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						let max_limit = 12 // month
						if (SavedReportStore.previewReportValues.audit_group_on === 'week') {
							max_limit = SavedReportStore.max_week_count // week
						}
						new_key = prev_key
						while (new_key !== key && key !== 'Total') {
							var _key = new_key.split('_')
							_key[1] = parseInt(_key[1])
							_key[0] = parseInt(_key[0])
							var _temp = _key[0] + 1
							if (_key[0] >= max_limit) {
								new_key = (1) + '_' + (_key[1] + 1)
								display_key = '01-' + (_key[1] + 1)
							} else {
								if (_temp < 10) { _temp = '0' + _temp }
								new_key = (_key[0] + 1) + '_' + _key[1]
								display_key = _temp + '-' + _key[1]
							}
							if (new_key !== key) {
								list_data.push({
									group_on: display_key,
									group_range: new_key,
								})
							}
						}
					}
					if (['year'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						new_key = parseInt(prev_key)
						while (new_key !== parseInt(key) && key !== 'Total') {
							new_key = new_key + 1
							if (new_key !== parseInt(key)) {
								list_data.push({
									group_on: new_key,
									group_range: new_key,
								})
							}
						}
					}

				}
				list_data.push(list_object[key])
				prev_key = key
			})
	}

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '100%'
		}
	}

	const onBtExportExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel({
			processCellCallback: (params) => {
				const { value } = params
				if (value) {
					if (value.toString().includes('-')) {
						let _temp = params.column.colId === "group_on" && params.value.split('-')
						if (props.FYyear && _temp.length > 1) {
							if (params.node.rowIndex < 9) {
								return props.FYyear + '-W0' + (params.node.rowIndex + 1)
							} else {
								return + props.FYyear + '-W' + (params.node.rowIndex + 1)
							}
						} else {
							return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
						}
					} else {
						return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
					}
				} else {
					return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
				}
			}
		});
	}, [props.FYyear]);

	const onBtExportCSV = useCallback((params) => {
		gridRef.current.api.exportDataAsCsv({
			processCellCallback: (params) => {
				const { value } = params
				if (value) {
					if (value.toString().includes('-')) {
						let _temp = params.column.colId === "group_on" && params.value.split('-')
						if (props.FYyear && _temp.length > 1) {
							if (params.node.rowIndex < 9) {
								return props.FYyear + '-W0' + (params.node.rowIndex + 1)
							} else {
								return + props.FYyear + '-W' + (params.node.rowIndex + 1)
							}
						} else {
							return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
						}
					} else {
						return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
					}
				} else {
					return (params.value === 0 || params.value === null || params.value === undefined) ? '0.00' : params.value
				}
			}
		})
	}, [props.FYyear]);

	return (
		<Drawer
			onClose={props.close}
			title={props.title}
			destroyOnClose={true}
			visible={props.AccountDetail ? true : false}
			onCancel={props.close}
			// onBodyScroll={props.onGridReady}
			width="75%"
		>

			<PageHeader
				title={SavedReportStore.previewReportValues.name}
				extra={[
					<>
						<Button
							key="2"
							onClick={onBtExportCSV}
						>Export CSV</Button>
						<Button
							key="1"
							onClick={onBtExportExcel}
						>Export Excel</Button>
					</>
				]}>
				{props.AccountDetail && (
					<div className="ag-theme-alpine reduce_height cs_grid grid_wrapper">
						<AgGridReact
							ref={gridRef}
							className={"customHeadheight"}
							rowData={list_data}
							pinnedBottomRowData={PinnedData}
							modules={AllModules}
							columnDefs={columnDefs}
							onGridReady={props.onGridReady}
							defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false, autoHeight: false }}
							enableRangeSelection={true}
							rowHeight={30}
						// onColumnResized={SavedReportStore.onChildnGridChanged}
						// onColumnMoved={SavedReportStore.onChildnGridChanged}
						// onColumnPinned={SavedReportStore.onChildnGridChanged}
						// onSortChanged={SavedReportStore.onChildnGridChanged}
						/>
					</div>
				)}
			</PageHeader>
		</Drawer>
	)
})

export default PreviewDetailAuditTable
