import React, { useState } from 'react'
import { Card, Form, Input, Button, Popover } from 'antd'
import useStore from '../store'
import { vsmAuth, vsmNotify } from '../config/messages'
import { Link, useHistory } from 'react-router-dom'

const ResetPassword = (props) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const { UserStore: { doForgotPassword } } = useStore()
    const [tooltip, setTooltip] = useState(false)
    let history = useHistory()

    // make function call to reset password
    const resetPassword = (data) => {
        setLoading(true)
        doForgotPassword(data).then(() => {
            history.push('/')
            vsmNotify.success({
                message: vsmAuth.reset_success
            })
        }).catch(errors => {
            form.setFields(errors.errors)
            setLoading(false)
        })
    }

    // password criteria tool tip
    const passwordTooltip = (
        <div>
            <div>at least 1 numeric character</div>
            <div>at least 1 special character</div>
            <div>at least 1 uppercase letter</div>
            <div>at least 8  character</div>
        </div>
    );

    // handle password tool tip visiblility
    const handleChangePassword = (e) => {
        form.validateFields(['password']).then(() => {
            setTooltip(false)
        }).catch(() => {
            setTooltip(true)
        })
    }

    return (
        <div className="login__wrapper">
            <Form form={form} onFinish={resetPassword}>
                <Card title="Forget Password" className="w400">
                    <Form.Item name="Key" initialValue={props.match.params.id} style={{ display: 'none' }}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Popover placement="topRight" content={passwordTooltip} visible={tooltip}>
                        <Form.Item name="password" rules={vsmAuth.validation.password} hasFeedback >
                            <Input.Password placeholder="New Password" onBlur={() => setTooltip(false)} onChange={handleChangePassword} onFocus={handleChangePassword} />
                        </Form.Item>
                    </Popover>
                    <Form.Item name="confirm_password" rules={vsmAuth.validation.confirmpassword} hasFeedback >
                        <Input.Password placeholder="Confirm Password" onBlur={() => setTooltip(false)} onChange={handleChangePassword} />
                    </Form.Item>
                    <div className="text-center">
                        <Button loading={loading} htmlType="submit" block type="primary">Reset Password</Button>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Link to="/" type="link" className="p-0 mt-10"><b>Back to login</b></Link>
                    </div>
                </Card>
            </Form>
        </div>
    )
}

export default ResetPassword
