import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class TriggerStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	deleteValues = null
	agGrid = null
	loader = false
	currentModals = []

	initialValues = {
		selected_models: [],
	}

	checkBox_dropdown = [
		{ id: 0, text: "Current Date", value: "current date" },
		{ id: 1, text: "Issued On", value: "issued on" },
		{ id: 2, text: "Posted On", value: "posted on" },
		{ id: 3, text: "Traded On", value: "traded on" },
		{ id: 4, text: "Settled On", value: "settled on" },
		{ id: 5, text: "Closed On", value: "closed on" },
		{ id: 6, text: "Forwarded On", value: "forwarded on" }
	]

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('trigger_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('trigger_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.post(`trigger/list/`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.updated_by = item.user ? (item.user.first_name + " " + item.user.last_name) : null
				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
		})
	}

	getModalList = (id) => {
		return Axios.post(`trigger/model/list`, { mapping_id: id }).then(({ data }) => {
			return data.data
		})
	}

	ReadData = (id) => {
		return Axios.get(`trigger/read/${id}`).then(({ data }) => {
			this.editValues = data.data
			return data.data
		})
	}

	AddData = (payload) => {
		return Axios.post(`trigger/add`, payload).then((data) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	EditData = (payload) => {
		return Axios.post(`trigger/edit/${payload.id}`, payload).then((data) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	DeleteData = (id) => {
		return Axios.get(`trigger/delete/${id}`).then((data) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	GetFormDataList = (form) => {
		var FormDataList = []
		var tempData = form.getFieldValue('when_to_execute')
		if (tempData) {
			if (tempData.includes('Import') || tempData.includes('New Transaction')) {
				FormDataList = [...FormDataList, 'mapping']
			}

			if (tempData.includes('Transaction Status Change')) {
				FormDataList = [...FormDataList, 'transaction_status']
			}

			if (tempData.includes('Timely Schedule')) {
				FormDataList = [...FormDataList, 'time_schedule']
			}
		}
		return FormDataList
	}

}

decorate(TriggerStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	agGrid: observable,
	initialValues: observable,
	checkBox_dropdown: observable,
	loader: observable,
	currentModals: observable,
	getList: action,
	setEditValues: action,
	setDeleteValues: action,
	setupGrid: action,
	onFilterChanged: action,
	getModalList: action,
	AddData: action,
	EditData: action,
	DeleteData: action,
	ReadData: action,
	GetFormDataList: action,
	setPageSize: action,
	onGridChanged: action,
})
