import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { MenuOutlined } from '@ant-design/icons'
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import useStore from '../../../../../store';
import { vsmLedgerMapBank } from '../../../../../config/messages';

const RegularExpressionElement = observer(({ onChange, regexNote, form }) => {
	const { LedgerBankAccountStore: { regexList, fieldList } } = useStore()

	const onSortEnd = async ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove(form.getFieldValue('regular_expression'), oldIndex, newIndex);
			await form.setFieldsValue({ regular_expression: newData })
			onChange()
		}
	}

	const DragHandle = sortableHandle(() => (
		<MenuOutlined style={{ fontSize: '1.5em', cursor: 'pointer', color: '#999', marginTop: 5 }} />
	));

	const SortableContainer = sortableContainer(props => <div {...props} className="w-100" />);

	const SortableItem = sortableElement(({ fields, add, remove, field, i }) => {
		return (
			<tr className="fields_draggable_tr">
				<td className="w50"><DragHandle /></td>
				<td className="w50">For</td>
				<td>
					<Row gutter={16}>
						<Col span={8}>
							<InputComponent
								name={[field.name, 'field']} key={[field.key, 'field']}
								type="select"
								placeholder=" Select Field"
								onChange={onChange}
								options={{
									values: fieldList,
									value_key: "value",
									text_key: "key",
									className: "w-100"
								}}
								rules={vsmLedgerMapBank.validation.field}
								autoComplete="off"
							/>
						</Col>
						<Col>
							<InputComponent
								type="radio_button"
								name={[field.name, 'type']} key={[field.key, 'type']}
								initialValue="inbound"
								onChange={onChange}
								options={{
									values: [
										{ value: 'inbound', text: 'Inbound' },
										{ value: 'outbound', text: 'Outbound' },
									]
								}}
							/>
						</Col>
					</Row>
					<InputComponent
						onChange={onChange}
						type="select" mode="multiple"
						name={[field.name, "regex_id"]} key={[field.key, 'regex_id']}
						placeholder="Select Regular Expression/s"
						options={{
							values: regexList,
							value_key: "id",
							text_key: "regex_name",
						}}
						rules={vsmLedgerMapBank.validation.regex}
						note={regexNote && regexNote[field.name] && regexNote[field.name].map(item => <div>{item}</div>)}
					/>
				</td>
				<td className="w100">
					{(fields && fields.length > 1) && (
						<Button className="ml-10" type="danger" onClick={async () => {
							await remove(field.name)
							onChange()
						}} icon={<MinusOutlined />}></Button>
					)}
					{(i === (fields.length - 1)) && (
						<Button className="ml-10" type="primary" onClick={async () => {
							await add()
							onChange()
						}} icon={<PlusOutlined />}></Button>
					)}
				</td>
			</tr>
		)
	})

	return (
		<SortableContainer
			useDragHandle
			onSortEnd={onSortEnd}
		>
			<Form.List name="regular_expression">
				{
					(fields, { add, remove }) => {
						return (
							<table cellSpacing={30} className="w-100 cs_light_border_table">
								<tbody>
									{fields && fields.map((field, index) => {
										return (
											<SortableItem fields={fields} add={add} i={index} remove={remove} field={field} key={field.key} index={index} />
										)
									})}
								</tbody>
							</table>
						)
					}
				}
			</Form.List>
		</SortableContainer>
	)
})

export default RegularExpressionElement
