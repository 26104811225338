import React, { useEffect, useMemo, useState } from 'react'
import { Form, Button, Input, Select, Tooltip, Drawer, Col, Checkbox, Divider, Spin, Row, Switch } from 'antd'
import { InfoCircleOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { vsmNotify, vsmLedgers } from '../../../../../config/messages';
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { default as OrganisationAddComponent } from '../../../../OrganisationManagement/AllOrganisations/component/AddComponent';
import { default as AddLedgerAdminComponent } from './AddLedgerAdminComponent';
import InputComponent from '../../../../../components/InputComponent';
import debounce from 'lodash/debounce'
import OrgDetailComponent from '../../../../OrganisationManagement/RoleMapping/component/OrgDetailComponent';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrganisationStore, LedgerStore: { AddData, global_organisations_list, ledger_admin_list, setOrganisationValues, getAllGlobalOrganisationList, organisation_values, user, getAllLedgerAdminList, initialValues, clone_ledger_list, getCloneLedgerList }, LedgerStore, UserStore } = useStore()
	const [saving, setSaving] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [addModal, setAddModal] = useState(false)
	const [addUserModal, setAddUserModal] = useState(false)
	const [fetchOrgnization, setFetchOrgnization] = useState(false)
	const [fetchLedgerAdmin, setFetchLedgerAdmin] = useState(true)
	const [fetchCloneLedger, setFetchCloneLedger] = useState(true)
	const [cloneLedger, setCloneLedger] = useState(false)
	const [searchOrgKey, setSearchOrgKey] = useState()

	const store = {
		Organisation: OrganisationStore
	}

	// Open form for add new Organisation
	const openAddModal = () => {
		store.Organisation.getAllCountries()
		setAddModal(true)
	}

	// Close form for close new Organisation
	const closeAddModal = (action = 'cancel') => {
		setAddModal(false)
		if (action === "add") {
			getAllGlobalOrganisationList().then(data => {
				form.setFieldsValue({
					organisation_id: data.last_inserted_id
				})
			})
		}
	}

	// Open form for add new Ledger admin
	const openAddUserModal = () => {
		if (fetchLedgerAdmin) { getAllLedgerAdminList().then(() => setFetchLedgerAdmin(false)) }
		setAddUserModal(true)
	}

	// Close form for add new Ledger admin
	const closeAddUserModal = () => {
		setAddUserModal(false)
		setDisabled(false)
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		if (user) {
			data.first_name = user.first_name
			data.last_name = user.last_name
			data.email = user.email
		}
		if (form.getFieldValue('clone_ledger')) {
			data.clone = form.getFieldValue('clone_ledger')
			data.ledger_id = data.clone_data_DD
			data.clone_data = convertCloneData(data)
		}
		setSaving(true)
		AddData(data).then(() => {
			UserStore.initiatAppOptions()
			close()
			vsmNotify.success({
				message: vsmLedgers.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const convertCloneData = (value) => {
		var tempData = []
		value.select_clone_data.map((module) => {
			module.map((item) => {
				if (item.is_selected === 1) {
					tempData.push(item.value)
				}
				return item
			})
			return module
		})
		value.select_clone_data = tempData
		return tempData
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form.validateFields().then((data) => {
			if (form.getFieldValue('clone_ledger')) {
				var temp = convertCloneData(data)
				if (temp.length > 0) {
					setDisabled(false)
				} else {
					setDisabled(true)
				}
			} else {
				setDisabled(false)
			}
		}).catch(e => {
			setDisabled(true)
		})
	}, 500)

	// get organisation data
	const handleOrganisationChange = (data) => {
		if (data) {
			OrganisationStore.getOrgCardValue(data, "org").then((data) => {
				setOrganisationValues(data)
			})
		} else {
			setOrganisationValues(null)
		}
	}

	const SelectAndDisableCheckbox = (actions, index) => {
		if (actions[index]) {
			actions[index].is_selected = 1
			return SelectAndDisableCheckbox(actions, index - 1)
		} else {
			return false
		}
	}

	// reset form and close add form
	const close = () => {
		LedgerStore.initialValues.select_clone_data = []
		form.resetFields()
		setDisabled(true)
		setCloneLedger(false)
		setOrganisationValues(null)
		setFetchOrgnization(false)
		setFetchLedgerAdmin(true)
		setSearchOrgKey(null)
		props.close()
	}

	useEffect(() => {
		if (props.visible) {
			LedgerStore.initialValues.select_clone_data.push(
				[{ label: 'Tax Settings', value: 'tax_settings', is_selected: 0 }, { label: 'Journal Groups', value: 'journal_groups', is_selected: 0 }, { label: 'Journals', value: 'journals', is_selected: 0 }, { label: 'Transaction Type', value: 'transaction_type', is_selected: 0 }, { label: 'Transaction sub-Type', value: 'transaction_sub_type', is_selected: 0 }, { label: 'Transaction Fields', value: 'transaction_fields', is_selected: 0 }, { label: 'Transaction Type Mapping', value: 'type_field_mapping', is_selected: 0 }, { label: 'Algorithms', value: 'algorithms', is_selected: 0 }, { label: 'Transaction Models', value: 'transaction_model', is_selected: 0 }],

				[{ label: 'Currency Settings', value: 'ledgers_ex_rate_setting', is_selected: 0 }, { label: 'Currency History', value: 'ledgers_ex_rate_history', is_selected: 0 }, { label: 'Ledger Bank Account', value: 'ledgers_bank_account', is_selected: 0 }],

				[{ label: 'Regular Expressions', value: 'regular_expression', is_selected: 0 }, { label: 'Classification Categories', value: 'transaction_classifications', is_selected: 0 }],

				[{ label: 'Organisation Roles', value: 'organisations_roles', is_selected: 0 }, { label: 'Organisations Role Mapping', value: 'organisations_role_mapping', is_selected: 0 }, { label: 'Organisation’s Bank Accounts', value: 'org_bank_account', is_selected: 0 }, { label: 'Organisation Status', value: 'organisations_status', is_selected: 0 }],

				[{ label: 'People Roles', value: 'people_role', is_selected: 0 }, { label: 'People Role Mapping', value: 'people_role_mapping', is_selected: 0 }, { label: 'People’s Bank Accounts', value: 'people_bank_account', is_selected: 0 }]
			)
		}
	}, [LedgerStore, props])

	// search string for organisation dropdown
	const handleOrgSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchOrgKey(val);
			if (val && val.length >= 1) {
				setFetchOrgnization(true)
				getAllGlobalOrganisationList({ search_for: val }).finally(() => setFetchOrgnization(false))
			}
		};

		return debounce(loadOptions, 500);
	}, [getAllGlobalOrganisationList])

	return (
		<>
			<Drawer
				title="Add New Ledger"
				visible={props.visible}
				onClose={close}
				footer={[
					<div key="1" className="d-flex justify-content-center">
						<Button key="1" disabled={disabled} form='addLedgerform' loading={saving} htmlType="submit" type="primary">Save</Button>
						<Button key="2" htmlType="button" onClick={close} className='ml-10'>Cancel</Button>
					</div>
				]}
				width={'60%'}
			>
				<Form form={form} id="addLedgerform" onFinish={handleSubmit}
					onChange={handleChange}
					initialValues={initialValues}
					layout="vertical"
				>
					<Form.Item name="name" rules={vsmLedgers.validation.name} label="Ledger Name" required tooltip='Ledger Name is one of the basic parameters of a Organisation Ledger. Name must be unique.'>
						<Input id="name" placeholder="Ledger Name" maxLength={100} />
					</Form.Item>

					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label="Organisation" required tooltip='Each Ledger is associated with one organisation to stores data'>
								<Form.Item noStyle name="organisation_id" rules={vsmLedgers.validation.organisation}>
									<Select showSearch placeholder="Select Organisation"
										notFoundContent={fetchOrgnization ? <Spin size="small" /> : (searchOrgKey) ? "No Record Found." : null}
										optionFilterProp="children"
										onChange={(data) => {
											handleChange(data)
											handleOrganisationChange(data)
										}}
										filterOption={false}
										onSearch={handleOrgSearch}
										allowClear
									>
										{
											(global_organisations_list && global_organisations_list.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.organisation_name}</Select.Option>
											)))
										}
									</Select>
								</Form.Item>
								<PlusCircleTwoTone className="ml-10" onClick={openAddModal} />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Ledger Admin" required tooltip='The Ledger Admin is a special user account used for ledger administration.'>
								<Form.Item noStyle name="ledger_admin" rules={vsmLedgers.validation.ledger_admin}>
									<Select showSearch placeholder="Select Ledger Admin"
										onFocus={() => fetchLedgerAdmin && getAllLedgerAdminList().then(() => setFetchLedgerAdmin(false))}
										notFoundContent={fetchLedgerAdmin ? <Spin size="small" /> : "No Record Found."}
										optionFilterProp="children"
										onChange={handleChange}
										filterOption={(input, option) =>
											option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										allowClear
									>
										{
											(ledger_admin_list && ledger_admin_list.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.first_name + " " + item.last_name + ' (' + item.email + ')'}</Select.Option>
											)))
										}
									</Select>
								</Form.Item>
								<PlusCircleTwoTone className="ml-10" onClick={openAddUserModal} />
							</Form.Item>
						</Col>

						<Col span={24}>
							{
								(organisation_values) && (
									<Row>
										<Col flex={1}>
											<OrgDetailComponent data={organisation_values} />
										</Col>
									</Row>
								)
							}
						</Col>

						<Col span={24}>
							<Form.Item className="mb-0">
								<Form.Item name="send_mail" valuePropName="checked" value="1">
									<Checkbox>Send an email to Ledger Admin to setup new ledger</Checkbox>
								</Form.Item>
							</Form.Item>
						</Col>

						<Col span={24}>
							<div className="mb-10">Note: Make sure you have selected correct organisation, Once saved, You won't able to edit it.</div>
						</Col>

						<Col>
						<Form.Item className="mb-0">
							<Form.Item label="API Access Status" name='api_access' valuePropName="checked">
								<Switch onChange={handleChange}/>
							</Form.Item>
						</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item name="clone_ledger" valuePropName="checked" className="mb-0" tooltip='If you want to clone Master data from any ledger to this new ledger, You can select this checkbox.'>
								<Checkbox onChange={(e) => {
									handleChange()
									setCloneLedger(e.target.checked)
								}}>Do you want to clone data from existing Ledger?</Checkbox>
							</Form.Item>
						</Col>
					</Row>

					{cloneLedger && (
						<>
							<Divider />

							<InputComponent
								required={cloneLedger ? true : false}
								label="Clone Data From Ledger"
								type="select"
								name="clone_data_DD"
								tooltip="You need to select Ledger From which you want to copy selected Master data."
								placeholder="Select Ledger"
								onChange={handleChange}
								rules={cloneLedger ? vsmLedgers.validation.clone_data : [{ required: false }]}
								onFocus={() => fetchCloneLedger && getCloneLedgerList().then(() => setFetchCloneLedger(false))}
								notFoundContent={fetchCloneLedger ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: clone_ledger_list,
									value_key: "id",
									text_key: "name"
								}}
							/>

							<div className="d-flex justify-space-between w-100">
								<Form.List name="select_clone_data">
									{(fields) => (
										<div className='w-100'>
											<div className="mb-10">Copy Selected Data</div>
											{
												fields && fields.map(field => {
													let modules = form.getFieldValue('select_clone_data')
													let module = modules[field.name]
													return (
														<div>
															{module && module.map((action, index) => {
																var dis = false
																if (module[index + 1] && module[index + 1].is_selected === 1) { dis = true }
																return (
																	<div>
																		<Form.Item name={[field.name, index, 'is_selected']} className='m-0'>
																			<Checkbox
																				value={action.value}
																				disabled={dis}
																				checked={action.is_selected ? true : false}
																				onChange={() => {
																					action.is_selected = action.is_selected ? 0 : 1
																					if (action.is_selected) {
																						SelectAndDisableCheckbox(module, index)
																					}
																					form.setFieldsValue({ select_clone_data: modules })
																				}}
																			>{action.label}</Checkbox>
																		</Form.Item>
																	</div>
																)
															})}
															<Divider />
														</div>
													)
												})
											}
										</div>
									)}
								</Form.List>
								<Tooltip placement="topRight" title="We have two types of Master data : TTE Data (From Tax Settings to Algorithms) and CTE Data (Regex and Classifications). If any of checkbox is selected in group, all above checkboxes will be selected automatically and disabled. As they are all dependent. Selected data will be copied from selected ledger to new ledger.">
									<InfoCircleOutlined className="ml-10" />
								</Tooltip>
							</div>

						</>
					)}

				</Form>

			</Drawer>

			{(addModal) && <OrganisationAddComponent visible={addModal} close={closeAddModal} />}
			{(addUserModal) && <AddLedgerAdminComponent visible={addUserModal} form={form} close={closeAddUserModal} />}
		</>
	)
})

export default AddComponent
