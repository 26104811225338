import React from 'react'
import { observer } from 'mobx-react'
import { Table } from 'antd';
import { CurrencyFormat } from '../../../../../../utils/GlobalFunction';

const DetailTableComponent = observer((props) => {

	const verifyColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
		}, {
			title: 'Role',
			dataIndex: 'role_name',
		}, {
			title: 'Organisation',
			dataIndex: 'entity_name',
		}, {
			title: 'Related Role',
			dataIndex: 'related_role_name',
		}, {
			title: 'Related Organisation',
			dataIndex: 'related_entity_name',
		}, {
			title: 'Date',
			dataIndex: 'date',
		}, {
			title: 'Amount',
			key: 'amount',
			align: "right",
			render: (data) => {
				return data.amount && <CurrencyFormat value={data.amount ? data.amount : 0} />
			}
		}
	];

	return (
		<Table
			pagination={false}
			dataSource={props.list_data}
			columns={verifyColumns}
			rowKey="index"
			bordered
		/>
	)
})

export default DetailTableComponent
