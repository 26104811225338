import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Col, Row, Tooltip, Divider } from 'antd'
import { observer } from 'mobx-react'
import { PlusOutlined, MinusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import useStore from '../../../../store'
import { vsmAllTransactions } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'

const FilterComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionFieldStore, AllTransactionStore: { setupGrid, setAdvanceFilter, agGrid, advance_filter } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fieldLength, setFieldLength] = useState(1)
	const [advanceFilter, setFilter] = useState(null)

	const Store = { Field: TransactionFieldStore }

	var field_list = Store.Field.dropdown_list

	// Make function call to filter existing records
	const handleSubmit = (data) => {
		setSaving(true)
		data.advance_filter = data.advance_filter ? data.advance_filter.map((item) => (
			item.items.filter(x => (x.id && x.value))
		)) : null;
		data.advance_filter = data.advance_filter && data.advance_filter.filter(function (el) {
			return el.length > 0;
		});

		if (data.advance_filter) {
			setAdvanceFilter(data.advance_filter);
			close();
			setupGrid(agGrid);
		} else {
			close();
		}
		setSaving(false)
	}

	// set form values
	useEffect(() => {
		if (props.visible) {
			form.resetFields()
			let filter = advance_filter ? advance_filter.map((item) => ({ items: item })) : [{ items: [null] }];
			setFieldLength(filter.reduce((a, item) => a + item.items.length, 0))
			setFilter(filter)
			form.setFieldsValue({ advance_filter: filter });
		}
	}, [advance_filter, form, props])

	// Make apply button enable / disable
	const handleChange = async () => {
		let filter = await form.getFieldValue('advance_filter')
		await setFilter(filter)
		let length = filter.reduce((a, item) => a + item.items.length, 0)
		setFieldLength(length)
		let check = filter.map((item) => ({ items: item.items.filter(x => (x.id && x.value)) }))
		check = check.reduce((a, item) => a + item.items.length, 0)
		form.validateFields().then((data) => {
			if (check > 0) {
				setDisabled(false)
			} else { setDisabled(true) }
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// Reset filter
	const reset = () => {
		form.resetFields()
		setAdvanceFilter(null)
		setFieldLength(1)
		setFilter(null)
		close()
		setupGrid(agGrid);
	}

	// close filter form
	const close = () => {
		props.close()
		setDisabled(true)
	}

	return (
		<Modal
			centered
			title={
				<div className="d-flex">
					<div className="mr-5">Apply Advance Filter</div>
					<small>
						<Tooltip className="mt-5" title="In Advance Filter you can select one or multiple field values as a OR condition to filter data."><InfoCircleOutlined /></Tooltip>
					</small>
				</div>
			}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='filterform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Apply</Button>,
				<Button key="2" htmlType="button" onClick={reset}>Reset</Button>,
				<Button key="3" htmlType="button" onClick={close}>Cancel</Button>
			]}
			width={600}
		>
			<Form form={form} id="filterform" onChange={handleChange} onFinish={handleSubmit}>
				<Form.List name='advance_filter'>
					{
						(fields, { add, remove }) => (
							<>
								{
									fields.map((field, index) => {
										return (
											<div className="w-100" key={field.key}>
												{(index > 0) && (<Divider style={{ marginTop: 5, marginBottom: 5 }}>AND</Divider>)}
												<div>
													{
														(fields.length > 1 && index !== 0) && (
															<div className="text-right mb-10">
																<Button type="danger" onClick={() => { remove(field.name); handleChange() }}>Remove Block</Button>
															</div>
														)
													}
													<Form.List name={[field.name, 'items']}>
														{
															(items, { add, remove }) => (
																<>
																	{
																		items.map((field, i) => {
																			let options = {}
																			if (advanceFilter && advanceFilter[index]) {
																				let filter = advanceFilter[index].items[i]
																				if (filter && filter.id) {
																					options = { rules: vsmAllTransactions.validation.value }
																				}
																			}

																			return (
																				<div className="w-100" key={field.key}>
																					{/* {(index > 0) && (<Divider style={{ marginTop: 0 }}>OR</Divider>)} */}
																					<Row gutter={6}>
																						<Col span={10}>
																							<InputComponent
																								required
																								type="select"
																								placeholder="Select Transaction Field"
																								onChange={handleChange}
																								options={{
																									values: field_list,
																									value_key: "id",
																									text_key: "field_name"
																								}}
																								name={[field.name, 'id']}
																								key={[field.key, 'id']}
																							/>
																						</Col>
																						<Col span={3}>
																							<InputComponent
																								required
																								type="select"
																								placeholder="Select Operator"
																								onChange={handleChange}
																								options={{
																									values: [
																										{ text: "=", value: "=" }
																									]
																								}}
																								initialValue={"="}
																								name={[field.name, 'operator']}
																								key={[field.key, 'operator']}
																							/>
																						</Col>
																						<Col span={7}>
																							<InputComponent
																								// required
																								placeholder="Value"
																								onChange={handleChange}
																								{...options}
																								name={[field.name, 'value']}
																								key={[field.key, 'value']}
																							/>
																						</Col>
																						<Col span={2} className="text-center">
																							{
																								(items.length > 1) && (<Button type="danger" onClick={async () => { await remove(field.name); handleChange() }} icon={<MinusOutlined />}></Button>)
																							}
																						</Col>
																						<Col span={2} className="text-center">
																							{(fieldLength < 12) && (i === (items.length - 1)) && (
																								<Button type="primary" onClick={async () => { await add(); handleChange() }} block icon={<PlusOutlined />}></Button>
																							)}
																						</Col>
																					</Row>
																				</div>
																			)
																		})
																	}
																</>
															)
														}
													</Form.List>
												</div>
											</div>
										)
									})
								}
								{
									(fieldLength < 12) && (
										<Divider style={{ marginTop: 10 }}><Button type="primary" onClick={async () => { await add({ items: [null] }); handleChange() }} block icon={<PlusOutlined />}>(AND)</Button></Divider>
									)
								}
							</>
						)
					}
				</Form.List>

			</Form>
		</Modal >
	)
})

export default FilterComponent
