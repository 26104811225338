import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import NumberFormat from 'react-number-format'
import { Button, Drawer, Form, Spin } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import moment from 'moment'

const LiveAuditReport = observer((props) => {
    const [form] = Form.useForm()
    const { TransactionModelStore, TransactionModelStore: { live_report_list_data, setupLiveReportGrid, onLiveReportGridChanged, getLiveReportList } } = useStore()
    const [journalLinesBS, setJournalLinesBS] = useState([])
    const [journalLinesPL, setJournalLinesPL] = useState([])
    const [loading, setLoading] = useState(true)

    const close = () => {
        props.close()
        TransactionModelStore.live_report_list_data = null
    }

    const gridOptions = {
        columnDefs: [{
            headerName: 'Code',
            headerTooltip: 'Code',
            field: 'code',
            filter: 'agNumberColumnFilter',
            tooltipField: 'code',
            pinned: 'left',
            width: 80
        }, {
            headerName: 'Description',
            headerTooltip: 'Description',
            field: 'journal_label',
            tooltipField: 'journal_label',
            width: 180
        }, {
            headerName: 'Debit',
            field: 'debit',
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            cellRendererFramework: (params) => (params.data) ? (
                <NumberFormat value={params.data.debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
            ) : ""
        }, {
            headerName: 'Credit',
            field: 'credit',
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            width: 110,
            cellRendererFramework: (params) => (params.data) ? (
                <NumberFormat value={params.data.credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
            )
                : ""
        }, {
            headerName: 'Posting',
            field: 'posting',
            type: 'rightAligned',
            filter: 'agNumberColumnFilter',
            width: 110,
            cellRendererFramework: (params) => (params.data) ? (
                <NumberFormat value={params.data.posting} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
            ) : ""
        }]
    }

    const handleChange = (data) => {
        TransactionModelStore.live_report_list_data = null
        setLoading(true)
        let payload = {
            from_date: moment(data[0]).format('YYYY-MM-DD'),
            to_date: moment(data[1]).format('YYYY-MM-DD')
        }
        getLiveReportList(payload)
    }

    let columns = []
    let columnConfig = localStorage.getItem('live_report_agGrid')
    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    useEffect(() => {
        let tempJournalLinesBS = live_report_list_data ? live_report_list_data.journalLinesBS ? Object.keys(live_report_list_data.journalLinesBS).length ? Object.values(live_report_list_data?.journalLinesBS) : [] : [] : []
        let totalJournalLinesBS = {
            code: "", journal_label: 'Total', debit: 0, credit: 0, posting: 0,
        }
        tempJournalLinesBS.length && tempJournalLinesBS.forEach((item) => {
            totalJournalLinesBS['debit'] += item['debit']
            totalJournalLinesBS['credit'] += item['credit']
            totalJournalLinesBS['posting'] += item['posting']
        })
        tempJournalLinesBS.push(totalJournalLinesBS)
        setJournalLinesBS(tempJournalLinesBS)


        let journalLinesPL = live_report_list_data ? live_report_list_data.journalLinesPL ? Object.keys(live_report_list_data.journalLinesPL).length ? Object.values(live_report_list_data?.journalLinesPL) : [] : [] : []
        let totalJournalLinesPL = {
            code: "", journal_label: 'Total', debit: 0, credit: 0, posting: 0,
        }
        journalLinesPL.length && journalLinesPL.forEach((item) => {
            totalJournalLinesPL['debit'] += item['debit']
            totalJournalLinesPL['credit'] += item['credit']
            totalJournalLinesPL['posting'] += item['posting']
        })
        journalLinesPL.push(totalJournalLinesPL)
        setJournalLinesPL(journalLinesPL)
        if (live_report_list_data && (live_report_list_data.length || Object.keys(live_report_list_data).length)) {
            setLoading(false)
        }
        if (live_report_list_data && live_report_list_data.StartDate && live_report_list_data.EndDate) {
            form.setFieldsValue({ date_range: [moment(live_report_list_data.StartDate), moment(live_report_list_data.EndDate)] })
        }
    }, [form, live_report_list_data])

    return (
        <Drawer
            title="Live Audit Report"
            visible={props.visible}
            onClose={close}
            className='live_audit_report'
            placement='right'
            width={'100%'}
            destroyOnClose={true}
            footer={[
                <div className="text-center" key="1">
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
            extra={
                <Form form={form} style={{ width: '1675px' }}>
                    <InputComponent
                        label="Date Range"
                        placeholder={['Start Date', 'End Date']}
                        picker={"week"} format='YYYY-MM-DD'
                        type="date_range" name="date_range" onChange={handleChange}
                        tooltip="Used to fetch the reports between specific date range."
                    />
                </Form>
            }
        >
            <Spin spinning={loading} tip={"Loading"}>
                <div className="ag-theme-alpine grid_wrapper">
                    <div className="preview_table_header">
                        <div>EUR - B/S {live_report_list_data && live_report_list_data?.StartDate} to {live_report_list_data && live_report_list_data?.EndDate}</div>
                    </div>
                    <div className="mb-0">
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                domLayout="autoHeight"
                                rowData={journalLinesBS}
                                modules={AllModules}
                                columnDefs={gridOptions.columnDefs}
                                defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
                                columnTypes={LocalGridConfig.columnTypes}
                                overlayNoRowsTemplate={vsmCommon.noRecord}
                                gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                                onGridReady={setupLiveReportGrid}
                                onColumnResized={onLiveReportGridChanged}
                                onColumnMoved={onLiveReportGridChanged}
                                onColumnPinned={onLiveReportGridChanged}
                                onSortChanged={onLiveReportGridChanged}
                            />
                        </div>
                    </div>

                    <div className="preview_table_header">
                        <div>EUR - P&L {live_report_list_data && live_report_list_data?.StartDate} to {live_report_list_data && live_report_list_data?.EndDate}</div>
                    </div>
                    <div className="mb-0">
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                domLayout="autoHeight"
                                rowData={journalLinesPL}
                                modules={AllModules}
                                columnDefs={gridOptions.columnDefs}
                                defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
                                columnTypes={LocalGridConfig.columnTypes}
                                overlayNoRowsTemplate={vsmCommon.noRecord}
                                gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                                onGridReady={setupLiveReportGrid}
                                onColumnResized={onLiveReportGridChanged}
                                onColumnMoved={onLiveReportGridChanged}
                                onColumnPinned={onLiveReportGridChanged}
                                onSortChanged={onLiveReportGridChanged}
                            />
                        </div>
                    </div>
                </div>
            </Spin>
        </Drawer>
    )
})

export default LiveAuditReport