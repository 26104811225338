import { Button, Divider, Drawer, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages'
import PageNotFound from '../../../PageNotFound'
import Unauthorized from '../../../Unauthorized'
import useStore from '../../../../store'
import FormComponent from './FormComponent'

const EditComponent = observer((props) => {
	const history = useHistory()
	const [form] = useForm()
	const [saving, setSaving] = useState(true)
	const [tip, setTip] = useState('Loading')
	const [isDisabled, setDisabled] = useState(false)
	const { SavedReportStore, UserStore } = useStore()
	const [formDataList, setFormDataList] = useState([])
	const defaultFormDataList = ['report_type', 'name', 'code', 'description']
	const [isInitiated, setInitiation] = useState(false)
	const [manualOpeningAndClosing,setManualOpeningAndClosing] = useState(false)

	const InitaitePreviewReport = useCallback(async () => {
		await setFormDataList(SavedReportStore.previewReportValues.form_list)		
		var previewReportValues = SavedReportStore.formatFormData(SavedReportStore.previewReportValues, false)		
		await form.setFieldsValue(previewReportValues)
	}, [SavedReportStore, form])

	useEffect(() => {
		if (!isInitiated && props.report_id && props.visible) {
			setInitiation(true)
			SavedReportStore.ReadReport(props.report_id).then(data => {
				setManualOpeningAndClosing(JSON.parse(data.data?.generated_on_options)?.manual_balance_open_close)
				InitaitePreviewReport()
				setSaving(false)
				setTip(null)
			}).catch(({ response }) => {
				if (response && response.status === 404) {
					setSaving('404')
				}
				if (response && response.status === 403) {
					setSaving('403')
				}
			})
		}

	}, [InitaitePreviewReport, SavedReportStore, isInitiated, props.report_id, props.visible, history])

	const handleSubmit = (data) => {		
		setSaving(true)
		setTip('Saving')
		data.action = "save_report"
		data.form_list = formDataList
		data.manual_balance_open_close = manualOpeningAndClosing ? true : false
		data.id = SavedReportStore.previewReportValues.id
		SavedReportStore.EditData(data).then(data => {
			vsmNotify.success({
				message: vsmTransactionReport.edit
			})
			props.close()
			if (SavedReportStore.my_records === 'my') {
				SavedReportStore.getList({}, UserStore.user.id);
			} else {
				SavedReportStore.getList();
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setTip(null)
		})


	}

	const close = () => {
		props.close()
		props.setReportID(null)
		SavedReportStore.previewReportValues = null
		setInitiation(false)
		setSaving(true)
		setTip('Loading')
	}
	
	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={'Edit Report - ' + (SavedReportStore.previewReportValues ? SavedReportStore.previewReportValues.name : '')}
			destroyOnClose={true}
			footer={[
				<div className="text-center">
					{form.getFieldValue('report_type') === 'trade' && UserStore.checkPermission(47, 'preview_data') && <Button className="mr-10" htmlType="button" disabled={isDisabled}
						onClick={() => {
							SavedReportStore.setPreviewReportValues({
								...SavedReportStore.previewReportValues,
								...form.getFieldsValue(),
								form_list: formDataList
							})
							props.openPreviewDataModal(props.report_id)
						}}
					>Preview Data</Button>}
					{UserStore.checkPermission(47, 'preview_report') && <Button className="mr-10" htmlType="button" disabled={isDisabled}
						onClick={() => {
							SavedReportStore.setPreviewReportValues({
								...SavedReportStore.previewReportValues,
								...form.getFieldsValue(),
								form_list: formDataList
							})
							props.openPreviewReportModal(props.report_id)
						}}
					>Preview Report</Button>}
					{UserStore.checkPermission(47, 'add') && <Button form='addeditform' className="mr-10" htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>}
					<Button onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={saving === true} tip={tip}>
				{
					(saving === '404') ? (
						<PageNotFound />
					) : (
							(saving === '403') ? (
								<Unauthorized />
							) : (
									<div className="w-100" style={{ minHeight: '200px' }}>
										{(SavedReportStore.previewReportValues) ? (
											<>
												<Divider className="pb-5" />
												<FormComponent initialValues={SavedReportStore.initialValues} defaultFormDataList={defaultFormDataList} form={form} formDataList={formDataList} setFormDataList={setFormDataList} handleSubmit={handleSubmit}
													setDisabled={setDisabled} setManualOpeningAndClosing={setManualOpeningAndClosing} manualOpeningAndClosing={manualOpeningAndClosing}
												/>
											</>
										) : (null)}
									</div>
								)
						)
				}

			</Spin>
		</Drawer>
	)
})

export default EditComponent
