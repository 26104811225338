import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button } from 'antd'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import useStore from '../../../../store'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import ActivateComponent from './component/ActivateComponent'
import DeactivateComponent from './component/DeactivateComponent'
import EditComponent from './component/EditComponent'
import FieldComponent from './component/FieldsComponent'
import Unauthorized from '../../../Unauthorized'
import CloneFieldComponent from './component/CloneFieldComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const LedgersBankAccounts = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [activateModal, setActivateModal] = useState(false)
	const [deactivateModal, setDeactivateModal] = useState(false)
	const [cloneModal, setCloneModal] = useState(false)
	const [mapFields, setMapFields] = useState(false)
	const { LedgerBankAccountStore, LedgerBankAccountStore: { per_page, setEditValues, setMapBankValues, setCurrentValues, setPageSize, getBanksNameList, getBankAccountTypeList, getLedgerCurrencyList, agGrid }, UserStore } = useStore()

	// Open form for add new bank account
	const openAddModal = () => {
		getBanksNameList()
		getLedgerCurrencyList()
		getBankAccountTypeList()
		setAddModal(true)
	}

	// Close form for add new bank account
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing bank account and set values to form
	const openEditModal = (data) => {
		getBanksNameList()
		getLedgerCurrencyList()
		getBankAccountTypeList()
		setEditValues(data)
		setEditModal(true)
	}

	// Close form for edit bank account
	const closeEditModal = () => setEditModal(false)

	// Open form for clone field map bank account
	const openCloneModal = (data) => {
		setMapBankValues(data)
		setCloneModal(true)
	}

	// Close form for clone field map bank account
	const closeCloneModal = () => setCloneModal(false)

	// open form field drawer for manually map
	const openFieldModal = (data, get_data = true) => {
		setMapBankValues(data, get_data)
		setMapFields(true)
	}

	// Close form field drawer for manually map
	const closeFieldModal = () => setMapFields(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		setCurrentValues(data)
		agGrid.api.refreshCells({ force: true });
		if (!checked) {
			setDeactivateModal(true)
		} else {
			setActivateModal(true)
		}
	}

	// Close confirmation alert for activate bank account
	const closeActivateModal = () => setActivateModal(false)

	// Close confirmation alert for deactivate bank account
	const closeDeactivateModal = () => setDeactivateModal(false)

	const handleReset = async () => {
		await localStorage.removeItem('users_grid')
		LedgerBankAccountStore.agGrid.api.setFilterModel(null)
		LedgerBankAccountStore.agGrid.api.setSortModel(null)
		LedgerBankAccountStore.agGrid.columnApi.resetColumnState()
		LedgerBankAccountStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.LedgersBankAccount.path} />
			{(!UserStore.checkPermission(73, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.LedgersBankAccount.title}
					extra={[
						UserStore.checkPermission(73, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openCloneModal={openCloneModal} openFieldModal={openFieldModal} onSwitchChange={onSwitchChange} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<ActivateComponent visible={activateModal} close={closeActivateModal} />
					<DeactivateComponent visible={deactivateModal} close={closeDeactivateModal} />
					<CloneFieldComponent visible={cloneModal} openFieldModal={openFieldModal} close={closeCloneModal} />
					<FieldComponent visible={mapFields} close={closeFieldModal} />
				</PageHeader>
			)}
		</>
	)
})

export default LedgersBankAccounts
