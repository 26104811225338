import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Drawer, Spin } from 'antd'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import moment from 'moment';
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'
import { OrgOriginatorID } from '../../../../utils/GlobalFunction'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [relatedTo, setRelatedTo] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [orgId, setOrgId] = useState(null)
	const [roleId, setRoleId] = useState(null)
	const [relatedRoleId, setRelatedRoleId] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const [csvPayload, setCsvPayload] = useState(null)
	const [mapping, setMapping] = useState(null)
	const [customStartDate, setCustomStartDate] = useState(false)

	const { OrgBankAccountStore, OrganisationStore, AllTransactionStore, PeopleStore, AllTransactionStore: { AddData, getDependentList, getStatusList, getCsvModals } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.posted_on = moment(data.posted_on).format("YYYY-MM-DD")
		data.traded_on = moment(data.traded_on).format("YYYY-MM-DD")
		data.issued_on = moment(data.issued_on).format("YYYY-MM-DD")
		data.is_custom_start_date = customStartDate ? true : false
		if (data.start_date && customStartDate) {
			data.start_date = moment(data.start_date).format("YYYY-MM-DD")
		}
		data.model = data.model ? data.model.filter(x => (x.model === true)) : null
		data.model = data.model && data.model.map((item) => ({ model: item.id, field: item.field }));
		data.related_entity_type = "Ledger"
		data.used_on = 0
		let currentNewDate = new Date()
		let curentData = moment(currentNewDate).format("YYYY-MM-DD")
		data.edso = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
		// Diff Traded_On - current (in months)
		data.current_period = Math.ceil(moment(curentData).diff(moment(data.traded_on), 'months', true))

		// data.period * data.period_type (Count in days)
		if (data.traded_on === moment().format('YYYY-MM-DD')) {
			data.period_number_days = '0'
		} else {
			data.period_number_days = data.period * (data.period_type === 1 ? 1 : data.period_type === 2 ? 7 : data.period_type === 3 ? 30 : data.period_type === 4 ? 365 : 1)
		}

		// diff Traded_On - current (in Days)
		data.period_days_passed = moment(curentData).diff(moment(data.traded_on), 'days', true)

		let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
		let csv_columns = []

		csvPayload.forEach((item) => {
			if (inCSV_Columns_payload.includes(item.field_name)) {
				switch (item.field_name) {
					case 'Asset Type':
						item.default_value = data.asset_type
						delete data.asset_type
						break;
					case 'Sell Rate':
						item.default_value = data.sell_rate.toString() ? data.sell_rate ? (data.sell_rate / 100).toString() : '0' : item.default_value
						delete data.sell_rate
						break;
					case 'Period':
						item.default_value = data.period.toString() ? data.period ? data.period.toString() : '0' : item.default_value
						break;
					case 'Period Type':
						item.default_value = data.period_type
						break;
					case 'Tax Type Inc or Exc':
						item.default_value = data.tax_type
						delete data.tax_type
						break;
					case 'EDSO':
						item.default_value = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
						break;
					case 'Repair and Maintenance':
						item.default_value = data.repair_maintenance.toString() ? data.repair_maintenance ? data.repair_maintenance.toString() : '0' : item.default_value
						delete data.repair_maintenance
						break;
					case 'EoT Value':
						item.default_value = data.eot.toString() ? data.eot ? data.eot.toString() : '0' : item.default_value
						delete data.eot
						break;
					case 'Batch ID':
						item.default_value = data.batch_id ? data.batch_id.toString() ? data.batch_id ? data.batch_id.toString() : '0' : null : item.default_value
						// delete data.batch_id
						break;
					case 'Exchange Ref ID':
						item.default_value = data.exchange_ref_id ? data.exchange_ref_id.toString() ? data.exchange_ref_id ? data.exchange_ref_id.toString() : '0' : null : item.default_value
						delete data.exchange_ref_id
						break;
					case 'Reference':
						item.default_value = (data.reference && data.reference.length) ? data.reference ? data.reference.toString() ? data.reference ? data.reference.toString() : '0' : null : item.default_value : null
						delete data.reference
						break;
					case 'Tax ID On Sales':
						item.default_value = data.sales_vat_rate_id.toString() ? data.sales_vat_rate_id ? data.sales_vat_rate_id.toString() : '0' : item.default_value
						delete data.sales_vat_rate_id
						break;
					case 'Current Period':
						// item.default_value = data.current_period.toString() // dynamic
						item.default_value = '1' // Static
						break;
					case 'Period Number Days':
						item.default_value = data.period_number_days.toString()
						break;
					case 'Period Days Passed':
						item.default_value = data.period_days_passed.toString()
						break;

					default:
						break;
				}
				csv_columns.push(item)
			}
		})
		data.csv_columns = csv_columns
		setSaving(true)
		if (data.transaction_items) {
			data.transaction_items.forEach((item, index) => {
				if (!item.id) {
					csvPayload && csvPayload.forEach((CSVdata) => {
						if (inCSV_Columns_payload.includes(CSVdata.field_name)) {
							switch (CSVdata.field_name) {
								case 'Asset Type':
									item.asset_type = CSVdata.default_value === 1 ? 'fixed' : 'current'
									break;
								case 'Batch ID':
									item.batch = CSVdata.default_value
									break;
								case 'EoT Value':
									item.eot = CSVdata.default_value
									break;
								case 'Repair and Maintenance':
									item.rm_price = CSVdata.default_value
									break;
								case 'Sell Rate':
									item.sell_rate = CSVdata.default_value
									break;
								case 'Tax Type Inc or Exc':
									item.tax_type_inc_exc = CSVdata.default_value
									break;
								default:
									break;
							}
						}
					})
					item.tax_settings_id = data.tax_settings_id
					item.classification = item.classification ? item.classification.toString() : item.classification
					item.months = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
					delete data.transaction_items.add_address
				} else {
					item.months = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
				}
			})
		} else {
			data.transaction_items = null
		}
		delete data.period
		delete data.period_type
		delete data.current_period
		delete data.period_days_passed
		delete data.period_number_days
		if (data.address_1 && data.country_id) {
			data.common_address = {
				address_1: data.address_1,
				address_2: data.address_2,
				address_3: data.address_3,
				city: data.city,
				state_county: data.state_county,
				postal_code: data.postal_code,
				country_id: data.country_id
			}
			delete data.address_1
			delete data.address_2
			delete data.address_3
			delete data.city
			delete data.state_county
			delete data.postal_code
			delete data.country_id
			data.add_address = 1
		} else {
			data.common_address = null
			data.add_address = 0
		}
		AddData(data).then(() => {
			close({ loadAPI: true })
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.add
			})
		}).catch(e => {
			e.errors.forEach((error) => {
				if (JSON.stringify(error.name) === JSON.stringify(['csv_columns', 8, 'default_value'])) {
					error['name'] = 'reference'
					return error
				}
			})
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			getStatusList()
			store.bank.getCurrencyList()
			setLoading(false)
		}
	}, [getStatusList, store.bank, props.visible])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		var role_id = OrgOriginatorID
		if (role_id !== roleId) {
			setOrgData(null)
			setOrgId(null)
			await form.resetFields(['entity_id'])
		}
		var mapping_id = form.getFieldValue("type_field_mapping_id")
		var entity_id = form.getFieldValue("entity_id")
		if (mapping_id && (mapping_id !== mapping)) {
			setMapping(mapping_id)
			getCsvModals(form.getFieldValue("type_field_mapping_id")).then(data => {
				setCsvPayload(data.data.field)
			})
		}

		if (role_id && entity_id && (role_id !== roleId || (orgId !== entity_id))) {
			getDependentList({ role_id: role_id, entity_id: entity_id })
		} else if (role_id && role_id !== roleId) {
			getDependentList({ role_id: role_id })
		}

		if (entity_id && (orgId !== entity_id)) {
			OrganisationStore.getOrgCardValue(entity_id, "org").then((data) => {
				setOrgData(data)
			})
		}
		setOrgId(entity_id)
		setRoleId(role_id)

		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		})
	}

	// Reset form and close add form
	const close = (data) => {
		props.close(data)
		setDisabled(true)
		setRelatedTo(null)
		setOrgData(null)
		setRoleId(null)
		setRelatedRoleId(null)
		setCsvColumn(null)
		AllTransactionStore.csvModals = null
		PeopleStore.rPeopleCardValue = null
		form.resetFields()
	}

	useEffect(() => {
		form.setFieldsValue({
			role_id: OrgOriginatorID,
			type_field_mapping_id: 1,
			asset_type: 1,
			tax_type: 1,
			repair_maintenance: 0,
			eot: 0
		})
	}, [form])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Add Trade Transaction`}
			destroyOnClose={true}
			className='remove-error-text'
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="addform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout="vertical"
					initialValues={AllTransactionStore.transactionItems}
				>
					<FormFieldComponent
						form={form}
						onChange={handleChange}
						tab={"add"}
						entity_type={1}
						related_to={relatedTo}
						orgData={orgData}
						roleId={roleId}
						relatedRoleId={relatedRoleId}
						csvColumn={csvColumn}
						customStartDate={customStartDate}
						setCustomStartDate={setCustomStartDate}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default AddComponent
