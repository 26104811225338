import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import RecordPerPage from '../../../components/RecordPerPage'
import AddComponent from './components/AddComponent'
import ListComponent from './components/ListComponent'
import DeleteComponent from './components/DeleteComponent'
import CancelComponent from './components/CancelComponent'
import VerifyComponent from './components/VerifyComponent'
import ViewImportLogComponent from './components/ViewImportLogs'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const CTEImport = observer((props) => {
	const { CTEImportStore, UserStore } = useStore()
	const [openImportDrawer, setOpenImportDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [cancelModal, setCancelModal] = useState(false)
	const [importId, setImportId] = useState(null)
	const [verifyDrawer, setVerifyDrawer] = useState(false)
	const [reprocessing, setReprocessing] = useState(false)
	const [autoRefresh, setAutoRefresh] = useState()

	//open drawer for imoport
	const openOpenImportDrawer = () => {
		CTEImportStore.setImportedIds([])
		CTEImportStore.imported_data_status = null
		CTEImportStore.store_import_ids = []
		CTEImportStore.apiCallLimit = 1
		setOpenImportDrawer(true)
	}

	// close drawer for import
	const closeOpenImportDrawer = () => setOpenImportDrawer(false)

	const setId = (id) => setImportId(id)

	// open drawer for view
	const openViewDrawer = (data) => {
		setViewDrawer(true)
		setId(data.id)
		CTEImportStore.setViewValues(data)
	}

	// close drawer for view
	const closeViewDrawer = () => {
		CTEImportStore.viewLog_list = null
		setViewDrawer(false)
	}

	//open modal for delete
	const openDeleteModal = (data) => {
		CTEImportStore.setDeletevalues(data)
		setDeleteModal(true)
	}

	//close modal for delete
	const closeDeleteModal = () => setDeleteModal(false)

	//open modal for cancel
	const openCancelModal = (data) => {
		CTEImportStore.setCancelvalues(data)
		setCancelModal(true)
	}

	//close modal for cancel
	const closeCancelModal = () => setCancelModal(false)

	const openVerifyDrawer = (data) => {
		setVerifyDrawer(true)
		CTEImportStore.setVerifyValues({ ...data, data_from: 'outer_list' })
	}

	const closeVerifyDrawer = () => {
		setVerifyDrawer(false)
		CTEImportStore.getList()
	}

	const reprocessIdentificatoin = () => {
		setReprocessing(true)
		CTEImportStore.ReprocessData().then(() => {
			setReprocessing(false)
		})
	}

	// auto refresh call
	const handleAutoRefresh = () => {
		if (!autoRefresh) {
			CTEImportStore.getList();
			var temp = setInterval(
				function () { CTEImportStore.getList(); }
				, 3000);
			setAutoRefresh(temp);
		}
	}

	// stop autoRefresh
	const handleStopAutoRefresh = () => {
		if (autoRefresh) {
			clearInterval(autoRefresh)
			setAutoRefresh(null)
		}
	}

	// clear interval on refresh
	useEffect(() => {
		return () => {
			if (autoRefresh) {
				clearInterval(autoRefresh)
				setAutoRefresh(null)
			}
		};
	}, [autoRefresh])

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('cte_import_grid')
		CTEImportStore.agGrid.api.setFilterModel(null)
		CTEImportStore.agGrid.api.setSortModel(null)
		CTEImportStore.agGrid.columnApi.resetColumnState()
		CTEImportStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.CTEImport.path} />
			{(!UserStore.checkPermission(75, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.CTEImport.title}
					extra={[
						(autoRefresh) ?
							(
								<Button key="1" onClick={handleStopAutoRefresh}>Stop Auto Refresh</Button>
							) :
							(
								<Button key="1" onClick={handleAutoRefresh}>Auto Refresh</Button>
							),

						UserStore.checkPermission(75, 'reprocess_imports') && UserStore.user && UserStore.user.user_role && [1, 2].includes(UserStore.user.user_role.user_role_id) && <Button loading={reprocessing} key="2" onClick={reprocessIdentificatoin}>Reprocess Identification For Selected Imports</Button>,

						UserStore.checkPermission(75, 'add') && <Button key="3" onClick={openOpenImportDrawer}>Import New CSV</Button>,

						<RecordPerPage key="4" defaultValue={CTEImportStore.per_page + ' per page'} onChange={CTEImportStore.setPageSize} />,

						<Button key="5" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,

						<EnterFullscreen key='6' />
					]}
				>
					<ListComponent openViewDrawer={openViewDrawer} openDeleteModal={openDeleteModal} openCancelModal={openCancelModal} openVerifyDrawer={openVerifyDrawer} />
					<AddComponent visible={openImportDrawer} close={closeOpenImportDrawer} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<CancelComponent visible={cancelModal} close={closeCancelModal} />
					<VerifyComponent visible={verifyDrawer} close={closeVerifyDrawer} />
					<ViewImportLogComponent importId={importId} setId={setId} visible={viewDrawer} close={closeViewDrawer} />
				</PageHeader>
			)}
		</>
	)
})

export default CTEImport
