import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import useStore from '../../../../store'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmTransactionReport, vsmNotify } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { SavedReportStore: { DeleteData, deleteValues }, UserStore } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			props.close()
			UserStore.setUserOptions(localStorage.getItem('token'))
			vsmNotify.success({
				message: vsmTransactionReport.delete
			})
		}).catch(e => {
			setSaving(false)
			if (e.errors) { form.setFields(e.errors) }
		})
	}

	return deleteValues ? (
		<Modal
			centered
			title={`Delete Report - #${deleteValues.id} - ${deleteValues.name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Please make sure you want to remove this saved report, you can not undo this action.</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
