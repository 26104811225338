import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { DeleteOutlined, EditOutlined, RetweetOutlined, BankOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Tooltip, Image } from 'antd'
import GridConfig from '../../../../config/GridConfig'
import useStore from '../../../../store'
import { vsmCommon } from '../../../../config/messages'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal, openRoleListing, openBankAccounts, openViewDrawer } = props
	const { UserStore, OrganisationStore, OrganisationStore: { setupGrid } } = useStore()

	const ActionRenderer = (prop) => {

		return (
			<div className="action-column">
				{UserStore.checkPermission(35, 'view') && <Button type="text" title="View Organisation" onClick={() => { openViewDrawer(prop.data) }}><EyeOutlined /></Button>}
				{
					(UserStore.checkPermission(35, 'edit') && UserStore.user.user_role && prop.data && (UserStore.user.user_role.user_role_id === 1 || prop.data.creator_ledger_id === UserStore.user.default_ledger_id)) ? (
						<Button title="Edit Organisation" type="text" onClick={() => { openEditModal(prop.data) }}><EditOutlined /></Button>
					) : null
				}
				{(UserStore.checkPermission(35, 'delete') && prop.data.id !== 1) && (
					(UserStore.user.user_role.user_role_id === 1 || UserStore.user.id === prop.data.created_by) && (
						(prop.data.transactions !== null) ? (
							<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot delete this Organisation as it is associated with Transactions."} >
								<Button type="text" disabled><DeleteOutlined /></Button>
							</Tooltip>
						) : (
							(prop.data.associated_ledgers === null) ? (
								<Button title="Delete Organisation" type="text" onClick={() => { openDeleteModal(prop.data) }}><DeleteOutlined /></Button>
							) : (
								(prop.data.organisation_role_mapping_count > 0 || prop.data.related_organisation_role_mapping_count > 0) ? (
									<Tooltip placement="topRight" color={"red"} title={"Sorry, you cannot delete this Organisation as it is associated with Role."} >
										<Button type="text" disabled><DeleteOutlined /></Button>
									</Tooltip>
								) : (
									<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot delete this Organisation as it is associated with Ledger."} >
										<Button type="text" disabled><DeleteOutlined /></Button>
									</Tooltip>
								)
							)
						)
					)
				)}
				{UserStore.checkPermission(35, 'bank_accounts_list') &&
					<Button title={"Bank Accounts"} type="text" onClick={() => { openBankAccounts(prop.data) }}><BankOutlined /></Button>
				}
				{UserStore.checkPermission(35, 'role_mapping_list') &&
					<Button title={"Role Mapping"} type="text" onClick={() => { openRoleListing(prop.data) }}><RetweetOutlined /></Button>
				}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			checkboxSelection: (params) => (params.data && params.data.logo_url) ? true : false,
			filter: false,
			sortable: false,
			floatingFilter: false,
			width: 55,
			suppressMenu: true,
			pinned: 'left'
		}, {
			headerName: '# ID',
			headerTooltip: '# ID',
			field: 'id',
			tooltipField: 'id',
			filter: 'agNumberColumnFilter',
			width: 80
		}, {
			headerName: 'Organisation Name',
			headerTooltip: 'Organisation Name',
			tooltipField: 'organisation_name',
			field: 'organisation_name',
			width: 150
		}, {
			headerName: 'Trade Name',
			headerTooltip: 'Trade Name',
			field: 'trade_name',
			tooltipField: 'trade_name',
			width: 150
		}, {
			headerName: 'Role (Associated Organisation)',
			headerTooltip: 'Role (Associated Organisation)',
			field: 'organisation_role_mapping',
			tooltipValueGetter: (params) => (params.data && params.data.organisation_role_mapping && params.data.organisation_role_mapping.length > 0) ?
				(params.data.organisation_role_mapping.map((item, i) => { return item.role_name + "(" + item.organisation_name + ")" })) : "",
			valueGetter: (params) => (params.data && params.data.organisation_role_mapping && params.data.organisation_role_mapping.length > 0) ?
				(params.data.organisation_role_mapping.map((item, i) => { return item.role_name + "(" + item.organisation_name + ")" })) : "",
			filter: false,
			sortable: false,
			width: 200
		}, {
			headerName: 'Registration Number',
			headerTooltip: 'Registration Number',
			tooltipField: 'registration_number',
			field: 'registration_number',
			width: 150
		}, {
			headerName: 'Country',
			headerTooltip: 'Country',
			field: 'global_countries.country_name',
			tooltipField: 'global_countries.country_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					OrganisationStore.getCountryFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.country_name)]);
					})
				}
			},
			width: 130
		}, {
			headerName: 'Associated Ledger',
			headerTooltip: 'Associated Ledger',
			field: 'associated_ledgers.name',
			tooltipField: 'associated_ledgers.name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					OrganisationStore.getAssociatedLedgerFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.name)]);
					})
				}
			},
			width: 130
		}, {
			headerName: 'Created By Ledger',
			headerTooltip: 'Created By Ledger',
			tooltipField: 'related_ledgers.name',
			field: 'related_ledgers.name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					OrganisationStore.getCreatedByLedgerFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.name)]);
					})
				}
			},
			width: 130
		}, {
			headerName: 'Status',
			headerTooltip: 'Status',
			tooltipField: 'status.status_name',
			field: 'status.status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					OrganisationStore.getStatusFilter().then((data) => {
						params.success([...data.data.map(x => x.status_name)]);
					})
				}
			},
			width: 130
		}, {
			headerName: 'Logo',
			headerTooltip: 'Logo',
			field: 'logo_url',
			cellRendererFramework: function (data) {
				return (data.data && data.data.logo_url) ? <div className="text-center"><Image style={{ height: '46px', width: 'auto' }} src={process.env.React_APP_API_URL + data.data.logo_url} alt="" /></div> : ""
			},
			filter: false,
			sortable: false,
			width: 150
		}, {
			headerName: 'Exchange Ref ID',
			headerTooltip: 'Exchange Ref ID',
			tooltipField: 'exchange_ref_id',
			field: 'exchange_ref_id',
			width: 150
		}, {
			headerName: 'Last Updated On',
			headerTooltip: 'Last Updated On',
			field: 'updated_at',
			tooltipField: 'updated_at',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 185
		}, {
			headerName: 'Actions',
			headerTooltip: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			width: 185,
			filter: false,
			sortable: false,
			pinned: 'right',
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('organisation_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openDeleteModal, openRoleListing, openBankAccounts, openViewDrawer
				}}
				onGridReady={setupGrid}
				gridOptions={{ ...GridConfig.options }}
				onColumnResized={OrganisationStore.onGridChanged}
				onColumnMoved={OrganisationStore.onGridChanged}
				onColumnPinned={OrganisationStore.onGridChanged}
				onDragStopped={OrganisationStore.onGridChanged}
				rowSelection={'multiple'}
				suppressRowClickSelection={true}
			/>
		</div>
	)
})

export default ListComponent
