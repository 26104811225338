import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Card, PageHeader, Divider, Button, Checkbox, Tooltip, Row, Col, Upload, message, Spin } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import useStore from '../../../../store'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import { vsmImport, vsmNotify } from '../../../../config/messages'
import { InfoCircleOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import sampleImport from '../../../../assets/import-sample-import.csv'
import sampleItem from '../../../../assets/Import-sample-item.csv'
import Unauthorized from '../../../Unauthorized'
import debounce from 'lodash/debounce'
import { DownloadOutlined } from '@ant-design/icons';


const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { UserStore, ImportStore, ImportStore: { getAllMappingList, setModalList, checkBox_dropdown, dropdown_list, executeTransactions, getSelectedModels, modal_list, dbField_list } } = useStore()
	const [fileList, setFileList] = useState([])
	const [extraFile, setExtraFile] = useState([])
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState()
	const [loading, setLoading] = useState(false)
	const [csvHeaders, setCSVHeaders] = useState([])
	const [rejectedFields, setRejectedFields] = useState([])
	const [autoSelection, setAutoSelection] = useState(null)
	const [acceptedDates, setAcceptedDates] = useState([])
	const [readFile, setReadFile] = useState(null)
	const [mapping, setMapping] = useState(null)
	let history = useHistory()
	let successRecords = false
	let errorRecords = false

	// handle submit api call
	const handleSubmit = (data) => {
		setSaving(true)
		data.csv_headers = data.csv_headers && data.csv_headers.filter(x => (x.csv_value && x.value))
		if (data.csv_headers.length <= 0) { data.csv_headers = null }
		let custom_field_mapping = data.csv_headers && data.csv_headers.reduce(function (r, e) {
			r[e.csv_value] = e.value;
			return r;
		}, {});

		data.selected_modals = data.selected_modals ? data.selected_modals.filter(x => (x.modal === true)) : null
		data.selected_modals = data.selected_modals && data.selected_modals.map((item) => ({ model: item.id, field: item.field_value }));
		let item_map = data.items_mapping_column_name && data.items_mapping_column_name.split(":").pop()

		const formData = new FormData();
		formData.append('import_filename', fileList[0]);
		formData.append('type_field_mapping_id', data.mapping_id);
		formData.append('import_name', data.import_name);
		formData.append('selected_models', data.selected_modals ? JSON.stringify(data.selected_modals) : '');
		formData.append('items_mapping_column_name', item_map);
		formData.append('custom_field_mapping', JSON.stringify(custom_field_mapping));
		extraFile.length > 0 && formData.append('item_filename', extraFile[0]);
		executeTransactions(formData).then(() => {
			close()
			vsmNotify.success({
				message: vsmImport.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		setModalList()
		getAllMappingList()
		ImportStore.list_data = null
	}, [getAllMappingList, setModalList, ImportStore])

	// Set the form values to on change
	useEffect(() => {
		form.setFieldsValue({ selected_modals: modal_list })
	}, [modal_list, form])

	// check for valid form values then accordingly make save button disable / enable
	const handlChange = (data) => {
		let array = form.getFieldValue("csv_headers")
		if (array && array.length > 0) {
			setAutoSelection(array)
		}
		handleSetExtraFields();
		handleValidateFields();
	}

	const handleValidateFields = async () => {
		let array = form.getFieldValue("csv_headers")
		let check = array && array.filter(x => (x.csv_value && x.value))
		if (array) {
			let fields = array.map((item) => (item.value));
			setRejectedFields(fields)
		}
		form.validateFields().then((data) => {
			if (check && check.length <= 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// based on mapping set extra fields
	const handleSetExtraFields = async () => {
		var tempId = form.getFieldValue("mapping_id");
		var map = mapping && mapping.id
		if (tempId && (tempId !== map)) {
			setLoading(true)
			handleRemoveExtraFile()
			var item = dropdown_list.find(item => item.id === tempId)
			setMapping(item)
			var payload = { id: tempId }
			handleForce(readFile, null)
			await getSelectedModels(payload).then((data) => {
				handleForce(readFile, data)
			})
			handleValidateFields()
			setLoading(false)
		}
	}

	// make array of csv column & auto selection
	const handleForce = (data = readFile, db_list = dbField_list) => {
		var temp = []
		var tempValue = []
		var accepted_keys = []
		if (data) {
			data.map((value, index) => {
				if (value && value.trim()) {
					var val = (index + 10).toString(36).toUpperCase() + ":" + value
					var column = value.toUpperCase().trim()
					var field = column !== "CCY" && (db_list && db_list.field.find(item => item.value.toUpperCase().includes(column)))
					var check = tempValue.find(item => field && item.value && item.value.includes(field.key))
					temp.push({ value: val, text: val })
					tempValue.push({ csv_value: val, value: field ? (check ? null : field.key) : null })
					if (field) {
						var field_name = field.value.split(":")
						if (field_name[1]) { accepted_keys.push(field_name[1]) }
					}
					return value;
				}
				return null
			})
		}
		var models = form.getFieldValue("selected_modals")
		models = models && models.filter(x => x && x)
		if (models && models.length > 0) {
			models.forEach((x) => {
				var date = null;
				if (x.selected_date) {
					date = accepted_keys.length > 0 ? (accepted_keys.find(item => item.toLowerCase().includes(x.selected_date.replace(/_/g, ' ').trim()))) : null
				}
				x.field_value = date ? date : "Current Date"
				x.modal = x.selected
			})
		}
		setAcceptedDates(accepted_keys)
		setCSVHeaders(temp)
		setAutoSelection(tempValue)
		setReadFile(data)
		form.setFieldsValue({ csv_headers: tempValue });
	};

	// check validation before upload file
	const beforeFileUpload = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 50;
		if (!isCSV) {
			message.error('You can only upload CSV file!');
			setDisabled(true)
			return true;
		} else if (!isLt20M) {
			message.error('Please upload CSV file with size of less then 50 MB.');
			setDisabled(true)
			return true;
		} else {

			const reader = new FileReader();
			reader.onload = e => {
				var text = e.target.result;
				var arr = text.split(/\n/).filter(Boolean);

				let columns = arr[0].split(',')
				columns = columns.map(name => name.toLowerCase().trim());

				let faceValue = columns.includes("face value")
				let condition1 = columns.includes("organisation exchange id for role")
				let condition2 = columns.includes("role mapping id")
				let condition3 = true
				let array = ["Type", "Organisation Name", "Organisation Register Country", "Organisation Register Number", "Role", "Related Type", "Related Organisation Name", "Related Organisation Register Country", "Related Role"]
				array.forEach((item) => { condition3 = columns.includes(item.toLowerCase()) })

				if (condition1 || condition2 || condition3) {
					if (faceValue) {
						setFileList([file])
						if (!form.getFieldValue("import_name")) {
							let name = file.name.replace(/.csv/g, '').substring(0, 85) + " " + file.lastModified
							form.setFieldsValue({ import_name: name })
						}
						handleForce(arr[0].split(','))
						handleValidateFields()
					} else {
						message.error('Face value cannot be blank.');
						setDisabled(true)
					}
				} else {
					message.error('There is no mapping available for organisation or role in CSV, Please make sure that you have Exchange ID OR All details for Organisation and Related Organisation with role OR Role Mapping ID in CSV.');
					setDisabled(true)
				}
			}
			reader.readAsText(file);
			return false
		}
	}

	// on file change set status
	const handleFileChange = debounce(info => {
		if (info.fileList) {
			if ((info.file.status && info.file.status === 'error') || info.fileList.length <= 0) {
				setDisabled(true)
			} else {
				handleValidateFields()
			}
		} else {
			setDisabled(true)
		}
	}, 500)

	// remove file data
	const handleRemoveFile = () => {
		setFileList([]);
		setCSVHeaders([]);
		setReadFile(null);
		setAcceptedDates([]);
		var selected_modals = form.getFieldValue("selected_modals")
		selected_modals = selected_modals.length > 0 && selected_modals.map(function (x) { x.modal = false; x.field_value = "Current Date"; return x });
		form.setFieldsValue({ csv_headers: [], selected_modals });
	}

	// check validation before upload extra file
	const beforeExtraFileUpload = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 50;
		if (!isCSV) {
			message.error('You can only upload CSV file!');
			setDisabled(true)
			return true;
		} else if (!isLt20M) {
			message.error('Please upload CSV file with size of less then 50 MB.');
			setDisabled(true)
			return true;
		} else {
			setExtraFile([file])
			return false
		}
	}

	// remove extra file
	const handleRemoveExtraFile = () => {
		setExtraFile([]);
		form.resetFields(["extrafile"])
	}

	// close add form
	const close = () => {
		history.push('/administration/trade/import-log');
		setFileList([])
		setExtraFile([])
		setDisabled(true)
		setCSVHeaders([])
		setRejectedFields([])
		setAutoSelection(null)
		setAcceptedDates([])
		setReadFile(null)
		setMapping(null)
		form.resetFields()
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Import_AddComponent.path} />
			{(!UserStore.checkPermission(32, 'list') || !UserStore.checkPermission(21, 'add')) ? (<Unauthorized />) : (
				<PageHeader title={BreadcrumbConfig.Import_AddComponent.title}
					extra={[
						<Button title='Download Sample File' onClick={() => (window.location.href = sampleImport)}><DownloadOutlined /></Button>
					]}>
					<Spin spinning={loading}>
						<Form form={form}
							id="addform"
							onFinish={handleSubmit}
							onChange={handlChange}
							layout='vertical'
						>

							<Row gutter={24}>
								<Col span={16}>
									<DraggerComponent
										required
										name="import_filename"
										placeholder="Drag your CSV file here or select by clicking here"
										tooltip="To start your import easily you can drag CSV file or you can upload file directly choosing from files."
										rules={vsmImport.validation.file}
										fileList={fileList}
										beforeUpload={beforeFileUpload}
										onChange={handleFileChange}
										removeFile={handleRemoveFile}
									/>
								</Col>

								<Col span={8}>
									<InputComponent
										required
										name="import_name"
										label='Import Name'
										maxLength={100}
										placeholder="Give your Import Name - Default will be Filename + Timestamp"
										tooltip="Give some name to import to identify it later on. By default it will take filename + current timestamp combination."
										rules={vsmImport.validation.import_name}
									/>

									<InputComponent
										required
										type="select"
										label='Trade Transaction Name'
										options={{
											values: dropdown_list && dropdown_list,
											value_key: "id",
											text_key: "mapping_name"
										}}
										placeholder="Select Trade Transaction Name"
										name="mapping_id"
										rules={vsmImport.validation.mapping_id}
										tooltip="Select Trade Transaction Name Name which is combination of Trade Type & Transaction Type. It Defines which fields and models will be generated on imported transactions."
										onChange={handlChange}
									/>
								</Col>
							</Row>

							<div className={modal_list && modal_list.length > 0 ? "d-flex justify-content-center mb-20" : "d-flex justify-content-center mt-10"}>
								{
									modal_list && modal_list.length > 0 && (
										<>
											<Card
												title="Select Models to Apply on Imported Transaction"
												className="ant-col ant-form-item-control"
											>
												<CheckboxComponent
													name="selected_modals"
													// rules={vsmImport.validation.model}
													options={modal_list}
													dropdown={checkBox_dropdown}
													accepted_key={acceptedDates}
													rejected_keys={["Issued On", "Posted On", "Traded On", "Settled On", "Closed On", "Forwarded On"]}
													onChange={handlChange}
													temp={form.getFieldValue("selected_modals")}
												/>
											</Card>
											<div className="align-self-center">
												<Tooltip placement="topRight" title={"Based on your mapping selection, Models will display. Selected models will be applied on each transaction to generate journal logs for those transactions."}>
													<InfoCircleOutlined className="ml-10" />
												</Tooltip>
											</div>
										</>
									)
								}
							</div>

							{
								mapping && mapping.has_items && (
									<>
										<div className="text-right mr-25"><a href={sampleItem}>Download Sample File</a></div>
										<DraggerComponent
											required
											name="extrafile"
											placeholder="Please upload Items CSV File here"
											tooltip="To start your import easily you can drag CSV file or you can upload file directly choosing from files."
											rules={vsmImport.validation.extrafile}
											fileList={extraFile}
											beforeUpload={beforeExtraFileUpload}
											onChange={handleFileChange}
											removeFile={handleRemoveExtraFile}
										/>
										<Card className="mb-20" title={
											<h3>
												<span>Please select common mapping column between main file and items file</span>
												<small>
													<Tooltip title="One of the important column when importing data with items. This field will be used as common field between two files to link them together. This column name should be same in both files.">
														<InfoCircleOutlined className="ml-10" />
													</Tooltip>
												</small>
											</h3>
										}>
											<InputComponent
												required
												type="select"
												options={{
													values: csvHeaders && csvHeaders
												}}
												placeholder="Select mapping column for Items"
												name="items_mapping_column_name"
												rules={vsmImport.validation.items_mapping}
												onChange={handlChange}
											/>
										</Card>
									</>
								)
							}

							{
								((fileList && fileList.length > 0) || mapping) && (
									<Card className="mb-20" title={
										<h3>
											<span>Columns Mapping with Fields</span>
											<small>
												<Tooltip title="Map CSV columns against Database fields, to make import more accurate. By default matched columns are selected already. T: Defines main Transaction Data and FV: Defines Additional Fields.">
													<InfoCircleOutlined className="ml-10" />
												</Tooltip>
											</small>
										</h3>
									}>
										{
											(csvHeaders && csvHeaders.length > 0 && dbField_list && dbField_list.field.length > 0) ? (
												<Row gutter={24}>
													<Col md={{ span: 15 }}>
														<Row gutter={24}>
															<Col span={10}>
																<h3 className='text-center'>CSV Columns</h3>
															</Col>
															<Col span={10}>
																<h3 className='text-center'>DB Fields</h3>
															</Col>
														</Row>
														<Form.List name='csv_headers'>
															{
																(fields) => {
																	return fields.map((field, index) => {
																		return (
																			<Row gutter={24} key={field.key}>
																				<Col span={10}>
																					<InputComponent
																						required
																						// type="select"
																						disabled={true}
																						options={{
																							values: csvHeaders,
																						}}
																						name={[field.name, 'csv_value']}
																						key={[field.key, 'csv_value']}
																						placeholder="Select CSV Field"
																					/>
																				</Col>
																				<Col span={10}>
																					<InputComponent
																						required
																						type="select"
																						allowClear
																						options={{
																							values: dbField_list && dbField_list.field,
																							value_key: 'key',
																							text_key: 'value',
																							rejected_keys: rejectedFields,
																							accepted_key: [form.getFieldValue("csv_headers")[index].value]
																						}}
																						onChange={handlChange}
																						name={[field.name, 'value']}
																						key={[field.key, 'value']}
																						placeholder="Select DB Field"
																					/>
																				</Col>
																			</Row>
																		)
																	})
																}
															}
														</Form.List>
													</Col>
													<Col md={{ span: 9 }}>
														<Card>
															<div className="mb-10">
																<span className="text-center text-success mb-10">
																	Based on Mapping, Following Field values will be automatically imported
																</span>
																<Divider />
																{autoSelection && autoSelection.map((item, index) => {
																	var field = dbField_list && dbField_list.field.find((val) => val.key === item.value)
																	var column = item.csv_value.toUpperCase().trim()
																	var val;
																	if (column.includes("ROLE MAPPING ID") || column.includes("CCY")) { val = item.csv_value }
																	if (field) { val = field.value }
																	if (val) {
																		successRecords = true
																		return (
																			<span key={index}>
																				<span className="text-success">{val}</span><br />
																			</span>
																		)
																	}
																	return null
																})}
																{!successRecords && (<span>Please select at least one field in DB Fields</span>)}
															</div>
															<Divider />
															<div className="mt-10">
																<span className="text-center text-danger mb-10">
																	Following Fields will not imported as they are not available in CSV or not selected in Columns mapping
																</span>
																<Divider />
																{autoSelection && autoSelection.map((item, index) => {
																	var column = item.csv_value.toUpperCase().trim()
																	if (!(column.includes("ROLE MAPPING ID") || column.includes("CCY"))) {
																		if (!item.value) {
																			errorRecords = true
																			return (
																				<span key={index}>
																					<span className="text-danger">{item.csv_value}</span><br />
																				</span>
																			)
																		}
																	}
																	return null
																})}
																{!errorRecords && (<span>All fields are generated</span>)}
															</div>
														</Card>
													</Col>
												</Row>
											) : (
												<div className="text-danger">There are no fields associated with Selected mapping, To import file you need at least one field in mapping.</div>
											)
										}
									</Card>
								)
							}
							<Divider />
						</Form>
						<div className="text-center">
							<Button disabled={disabled || !(csvHeaders && csvHeaders.length > 0 && dbField_list && dbField_list.field.length > 0)} form="addform" loading={saving} htmlType="submit" type="primary">Start Import in Background</Button>
							<Button onClick={close} className="ml-20">Close</Button>
						</div>
					</Spin>
				</PageHeader>
			)}
		</>
	)
})

export default AddComponent

const DraggerComponent = ({ fileList, removeFile, beforeUpload, onChange, name, rules, required, placeholder, tooltip, ...rest }) => {
	return (
		<Form.Item required={required}>
			<Form.Item className="full-width-dragger w-100" name={name} rules={rules}>
				<Upload.Dragger
					accept=".csv"
					showUploadList={true}
					multiple={false}
					fileList={fileList}
					onRemove={removeFile}
					beforeUpload={beforeUpload}
					onChange={onChange}
				>
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined />
					</p>
					<p className="ant-upload-text">{placeholder}</p>
				</Upload.Dragger>
			</Form.Item>
			<Tooltip placement="topRight" title={tooltip}>
				<InfoCircleOutlined className="ml-10" />
			</Tooltip>
		</Form.Item>
	)
}


const CheckboxComponent = ({ name, temp, accepted_key, rejected_keys, options, dropdown, onChange }) => {
	return (options && temp && options.length === temp.length) ? (
		<Form.List name={name}>
			{
				(fields) => {
					return fields.map((field, index) => {
						return options[index] && (
							<Row gutter={24} key={field.key}>
								<Col span={10}>
									<Form.Item
										valuePropName="checked"
										noStyle
										name={[field.name, "modal"]}
										key={[field.key, "modal"]}
									>
										<Checkbox>{index >= 0 && options[index].model_name}</Checkbox>
									</Form.Item>
								</Col>
								<Col span={10}>
									<InputComponent
										required
										type="select"
										initialValue={"Current Date"}
										options={{
											values: dropdown,
											accepted_key: accepted_key,
											rejected_keys: rejected_keys
										}}
										onChange={onChange}
										name={[field.name, 'field_value']}
										key={[field.key, 'field_value']}
										placeholder="Select DB Field"
									/>
								</Col>
							</Row>
						)
					})
				}
			}
		</Form.List>
	) : null
}
