import React, { useEffect, useState } from 'react'
import { Form, Button, Drawer, Tooltip, Card, Row, Col, Spin } from 'antd'
import { observer } from 'mobx-react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { vsmNotify, vsmLedgerMapBank } from '../../../../../config/messages'
import InputComponent from '../../../../../components/InputComponent';
import RegularExpressionElement from '../elements/RegularExpressionElement';
import useStore from '../../../../../store';
import DynamicFieldElement from '../elements/DynamicFieldElement';
import debounce from 'lodash/debounce';

const FieldComponent = observer((props) => {
	const [form] = Form.useForm()
	const [add_field_form] = Form.useForm()
	const { TransactionModelStore, LedgerBankAccountStore: { AddMapBankData, AddDynamicField, getRegexList, getFieldList, getDynamicFieldList, getRecordById, initialValues, mapBankValues, regexList } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [regexNote, setRegexNote] = useState()
	const [fetchJournal, setFetchJournal] = useState(true)
	const [loading, setLoading] = useState(false)
	const [editValues, setEditValues] = useState()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.id = mapBankValues.id
		data.dynamic_field = data.dynamic_field.filter(x => x && x.id && x.name);
		setSaving(true)
		AddMapBankData(data).then(() => {
			vsmNotify.success({
				message: vsmLedgerMapBank.save
			})
			close()
		}).catch(e => {
			if (e.errors) {
				form.setFields(e.errors)
			}
		}).finally(() => setSaving(false))
	}

	// Handle add field and call function to save new record
	const handleAddField = (data) => {
		add_field_form.validateFields().then(() => {
			AddDynamicField(data).then(() => {
				add_field_form.resetFields()
			}).catch(e => {
				if (e.errors) { add_field_form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		})
	}

	// set the form values to add/edit
	useEffect(() => {
		if (props.visible) {
			setFetchJournal(true)
			getRegexList()
			getFieldList()
			getDynamicFieldList()
			if (mapBankValues && mapBankValues.get_data) {
				setLoading(true)
				setFetchJournal(false)
				TransactionModelStore.getAllJournalsList()
				getRecordById({ id: mapBankValues.id }).then((data) => {
					setEditValues(data.data)
					setLoading(false)
				}).catch((data) => { setLoading(false) })
			}
		}
	}, [getRegexList, getRecordById, getFieldList, getDynamicFieldList, TransactionModelStore, mapBankValues, form, props])

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			let data = JSON.parse(editValues.field_mapping_json)
			var field = []
			if (data.hasOwnProperty("field") && data.field)
				Object.entries(data.field).forEach(([key, value]) => {
					var obj = { id: parseInt(key), name: value }
					field.push(obj)
				});
			data.dynamic_field = field.length > 0 ? field : [null]
			data.regular_expression = editValues.regular_expression.length > 0 ? editValues.regular_expression : [null]
			data.mapped_journal_id = editValues.mapped_journal_id
			form.setFieldsValue(data)
		}
	}, [editValues, form, props])

	const validateFields = () => {
		form.validateFields().then(async (data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(async () => {
		var regex = form.getFieldValue('regular_expression')
		var string = form.getFieldValue('string')
		if (string && regex) {
			var original_value = form.getFieldValue('regular_expression')
			var notes = []
			regex && regex.map((item, index) => {
				var temp = []
				item.regex_id && item.regex_id.map((id, i) => {
					var regexobj = regexList.find(item => item.id === id)
					try {
						var re = new RegExp(regexobj.regex_value, "g");
						var check = string.match(re)
						if (check) { temp.push(re + " " + (i + 1) + ": " + check.join(", ")) }
						if (!check) { temp.push(re + " " + (i + 1) + ": Not Found") }
					} catch { temp.push("/" + regexobj.regex_value + "/g " + (i + 1) + ": Invalid Regex") }
					return null
				})
				original_value[index].note = temp
				notes[index] = temp
				return null
			})
			setRegexNote(notes)
			await form.setFields(original_value)
		}
		validateFields()
	}, 200)

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setRegexNote(null)
		setFetchJournal(true)
		setEditValues(null)
	}

	return (
		<Drawer
			destroyOnClose={true}
			title={<span>Map Bank Import file Fields <small><Tooltip title="Map Import file Column names with each field below. CSV Column name is required for : Transaction Details, Face Value (Credit), Posted Date."><InfoCircleOutlined /></Tooltip></small></span>}
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="text-center">
					<Button disabled={disabled} form='fieldform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div>
			]}
			width="60%"
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form form={form} id="fieldform"
					onFinish={handleSubmit}
					initialValues={initialValues}
					layout="vertical"
				>

					<Row gutter={24}>
						<Col span={24}>
							<InputComponent
								required label="Transaction Details" name="transaction_details" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.with_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>

						<Col span={24} className="ant-form-item-label">
							<label className="ant-form-item-required">Face Value</label>
						</Col>
						<Col span={12}>
							<InputComponent
								name="credit" placeholder="Credit Column Name"
								rules={vsmLedgerMapBank.validation.with_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
								note={[<span key="1" style={{ fontSize: "12px" }}>If there is only one column with Plus and Minus sign, Enter only Credit column name.</span>]}
							/>
						</Col>
						<Col span={12}>
							<InputComponent
								name="debit" placeholder="Debit Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>
						{/* <small>If there is only one column with Plus and Minus sign, Enter only Credit column name</small> */}

						<Col span={12}>
							<InputComponent
								label="Balance" name="balance" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>

						<Col span={12}>
							<InputComponent
								required label="Posted Date" name="posted_on" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.with_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>

						<Col span={12}>
							<InputComponent
								label="Value Date" name="value_on" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
								note={[<span key="1" style={{ fontSize: "12px" }}>If Value Date field is Blank then it will be same as Posted Date field</span>]}
							/>
						</Col>

						<Col span={12}>
							<InputComponent
								label="Exchange Ref ID" name="exchange_ref_id" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>

						<Col span={12}>
							<InputComponent
								label="Customer Reference" name="customer_reference" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>


						<Col span={12}>
							<InputComponent
								label="Bank Reference" name="bank_reference" placeholder="CSV Column Name"
								rules={vsmLedgerMapBank.validation.without_required}
								maxLength={100}
								autoComplete="off"
								onChange={handleChange}
							/>
						</Col>
					</Row>

					<DynamicFieldElement
						form={form}
						onChange={handleChange}
						add_field_form={add_field_form}
						handleAddField={handleAddField}
					/>

					<Card title={
						<span>Select Regular Expression for one or multiple fields below
								<small className="ml-5">
								<Tooltip title="Select regular expression for each of below for all values you want to extract from Transaction Details Column using Regular Expression."><InfoCircleOutlined /></Tooltip>
							</small>
						</span>
					}>
						<InputComponent
							onChange={handleChange}
							name="string" placeholder="Enter Sample String to Verify Result of each Expression selected below (Not Required)"
							tooltip="You can test Each Regular Expression by adding sample Transaction Details over here, and result will be displayed below every regular expression dropdown if it is selected."
							maxLength={500}
							autoComplete="off"
						/>

						<Form.Item className="mb-10">
							<div className="w-100">
								<RegularExpressionElement regexNote={regexNote} onChange={handleChange} form={form} />
							</div>
						</Form.Item>
					</Card>

					<Card className="mt-20" title={
						<span>Define Journal for Cash to Trade Reconciliation
						<small className="ml-5">
								<Tooltip title="Selected Journal will be used to map transaction between cash and trade transaction for reconciliation process."><InfoCircleOutlined /></Tooltip>
							</small>
						</span>
					}>
						<InputComponent
							required name="mapped_journal_id"
							type="select" placeholder="Select Journal"
							onChange={handleChange}
							rules={vsmLedgerMapBank.validation.journal}
							onFocus={() => fetchJournal && TransactionModelStore.getAllJournalsList().then(() => setFetchJournal(false))}
							notFoundContent={fetchJournal ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: TransactionModelStore.journal_list,
								value_key: "id",
								text_key: { key: ["journal_code", " - ", "journal_label"] },
							}}
							autoComplete="off"
						/>
					</Card>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default FieldComponent
