import React, { useState, useEffect } from 'react'
import { Form, Button, Input, Select, Col, Row, Drawer, Switch } from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons';
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { vsmNotify, vsmLedgers } from '../../../../../config/messages'
import { default as AddLedgerAdminComponent } from './AddLedgerAdminComponent';
import OrgDetailComponent from '../../../../OrganisationManagement/RoleMapping/component/OrgDetailComponent';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { LedgerStore: { EditData, editValues, ledger_admin_list, getRecordById, setOrganisationValues, organisation_values, user }, OrganisationStore, UserStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [addUserModal, setAddUserModal] = useState(false)

	// Open form for add Ledger admin
	const openAddUserModal = () => {
		setAddUserModal(true)
	}

	// Close form for add Ledger admin
	const closeAddUserModal = () => setAddUserModal(false)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		if (user) {
			data.first_name = user.first_name
			data.last_name = user.last_name
			data.email = user.email
		}
		data.id = editValues.id
		data.organisation_id = editValues.organisation_id
		EditData(data).then(() => {
			UserStore.initiatAppOptions()
			close()
			vsmNotify.success({
				message: vsmLedgers.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({
				name: editValues.name,
				organisation_id: editValues.organisation ? editValues.organisation.organisation_name : null,
				ledger_admin: editValues.super_user_id,
				api_access: editValues.api_access
			})
			OrganisationStore.getOrgCardValue(editValues.organisation_id, "org").then((data) => {
				setOrganisationValues(data)
			})
		}
	}, [editValues, form, props, getRecordById, setOrganisationValues, ledger_admin_list, OrganisationStore])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setOrganisationValues(null)
	}

	return editValues ? (
		<Drawer
			title={`Edit Ledger - #${editValues.id} - ${editValues.name}`}
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='editLedgerform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" htmlType="button" onClick={close} className='ml-10'>Cancel</Button>
				</div>
			]}
			placement='right'
			width={'75%'}
		>
			<Form form={form} id="editLedgerform" onFinish={handleSubmit}
				onChange={handleChange}
				layout="vertical"
			>
				<Form.Item name="name" rules={vsmLedgers.validation.name} label="Ledger Name" required tooltip='Ledger Name is one of the basic parameters of a Organisation Ledger. Name must be unique.'>
					<Input id="name" placeholder="Ledger Name" maxLength={100} />
				</Form.Item>

				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="organisation_id" rules={vsmLedgers.validation.organisation} label="Organisation" required tooltip='Each Ledger is associated with one organisation to stores data'>
							<Input placeholder="Organisation" disabled />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label="Ledger Admin" required tooltip='The Ledger Admin is a special user account used for ledger administration.'>
							<Form.Item noStyle name="ledger_admin" rules={vsmLedgers.validation.ledger_admin}>
								<Select showSearch placeholder="Select Ledger Admin"
									optionFilterProp="children"
									onChange={handleChange}
									filterOption={(input, option) =>
										option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									allowClear
								>
									{
										(ledger_admin_list && ledger_admin_list.map((item, index) => (
											<Select.Option key={index} value={item.id}>{item.first_name + " " + item.last_name + ' (' + item.email + ')'}</Select.Option>
										)))
									}
								</Select>
							</Form.Item>
							<PlusCircleTwoTone className="ml-10" onClick={openAddUserModal} />
						</Form.Item>
					</Col>

					<Col>
						<Form.Item className="mb-0">
							<Form.Item label="API Access Status" name='api_access' valuePropName="checked">
								<Switch onChange={handleChange} />
							</Form.Item>
						</Form.Item>
					</Col>

					<Col span={24}>
						{
							(organisation_values) && (
								<Row>
									<Col flex={1}>
										<OrgDetailComponent data={organisation_values} />
									</Col>
								</Row>
							)
						}
					</Col>

				</Row>
				{(addUserModal) && <AddLedgerAdminComponent visible={addUserModal} close={closeAddUserModal} form={form} handleChange={handleChange} />}
			</Form>
		</Drawer>
	) : null
})

export default EditComponent
