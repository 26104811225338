import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Modal, Button } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import { vsmManualJournalLog, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import RepeaterElement from '../elements/RepeaterElement';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [remainVal, setRemainVal] = useState(0)
	const { ManualJournalLogStore, ManualJournalLogStore: { AddData, getAllRecords } } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.journal_entry = data.journal_entry.filter(x => {
			if (!x.model_id) { x.model_id = null }
			if (!x.transactions_type_id) { x.transactions_type_id = null }
			return x && x.journal_id && x.amount
		});
		data.transaction_id = props.transactionId || null
		setSaving(true)
		AddData(data).then(() => {
			close()
			if (props.handleApiCall) { props.handleApiCall() }
			vsmNotify.success({
				message: vsmManualJournalLog.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		if (props.visible) {
			getAllRecords(null, 0)
		}
	}, [props.visible, getAllRecords])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		let journal_entry = form.getFieldsValue('journal_entry').journal_entry;
		let totalCredit = 0
		let totalDebit = 0
		let totalValue = 0
		journal_entry && journal_entry.forEach((item) => {
			if (item.type === "Debit") {
				totalDebit += parseFloat(item.amount)
			} else if (item.type === "Credit") {
				totalCredit += parseFloat(item.amount)
			}
		})
		totalValue = totalCredit - totalDebit
		setRemainVal(totalCredit - totalDebit)
		form.validateFields().then((data) => {
			if (totalValue === 0) {
				setDisabled(false)
			} else {
				setDisabled(true)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const handleDependent = (index) => {
		var val = form.getFieldValue("journal_entry")
		var payload = null
		if (val && val[index]) {
			payload = {
				model_id: val[index].model_id,
				journal_id: val[index].journal_id,
				transactions_type_id: val[index].transactions_type_id
			}
		}
		getAllRecords(payload, index).then(async (val) => {
			if (payload.model_id && !val.model.find(x => x.id === payload.model_id)) {
				form.resetFields([["journal_entry", index, "model_id"]])
			}
			if (payload.transactions_type_id && !val.transaction.find(x => x.id === payload.transactions_type_id)) {
				form.resetFields([["journal_entry", index, "transactions_type_id"]])
			}
			if (payload.journal_id && !val.journal.find(x => x.id === payload.journal_id)) {
				form.resetFields([["journal_entry", index, "journal_id"]])
			}
			await handleChange()
		})
	}

	// reset form and close add form
	const close = () => {
		setRemainVal(0)
		props.close()
		form.resetFields()
		setDisabled(true)
		ManualJournalLogStore.dropdown = [{}]
		ManualJournalLogStore.mainDropdown = null
	}

	return (
		<Modal
			centered
			className='remove-error-text'
			title={"Manual Journal Entry"}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='addform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
			width={props.transactionId ? 700 : 950}
		>
			<Form form={form}
				id={"addform"}
				layout="vertical"
				onChange={handleChange}
				onFinish={handleSubmit}
				autoComplete="off"
			>

				<RepeaterElement
					onChange={handleChange}
					remainVal={remainVal}
					handleDependent={handleDependent}
					name="journal_entry"
					transactionId={props.transactionId}
					form={form}
				/>

				<InputComponent
					required type="textarea" label="Description" name="description" placeholder="Description"
					tooltip="The purpose of journal description is to mention  the financial transactions of a business, to be used for the future reconciling of accounts."
					rules={vsmManualJournalLog.validation.description}
					maxLength={1000}
					autoComplete="off"
				/>

			</Form>
		</Modal>
	)
})

export default AddComponent
