import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../config/LocalGridConfig"

export default class PeopleBankAccountStore {
	list_data = []
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	currentValues = null
	agGrid = null

	fetchBankNames = true
	fetchBankTypes = true
	fetchCurrencies = true
	dropdown_all_bank_list = null

	// set form values to active / deactive bank account
	setCurrentValues = (data) => {
		this.currentValues = data
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set currency dropdown values to edit & view
	setCurrency = (data) => {
		this.dropdown_currency_list = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('ppl_bank_account_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('ppl_bank_account_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay()
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay()
		}
	}

	// call api to get record detail
	getRecordById = (id, payload) => {
		return Axios.post(`organisations/bankaccount/read/${id}`, payload).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel(filter)
			var sort = this.agGrid.api.getSortModel(sort)
		}
		payload.mapping_type = 2
		return Axios.post(`organisations/bankaccount/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					item.published = item.status ? "Active" : "Deactive"
					item.bank_type = item.bank_account_type && item.bank_account_type.name ? item.bank_account_type.name : null
				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.api.setSortModel(sort)
			}
			return data
		})
	}

	// Call add api
	AddData = (formdata) => {
		formdata.mapping_type = 2
		return Axios.post(`organisations/bankaccount/add`, formdata).then(({ data }) => {
			this.getList({ people_id: formdata.people_id })
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		formdata.mapping_type = 2
		return Axios.post(`organisations/bankaccount/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList({ people_id: formdata.people_id })
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call activate / deactivate api
	ToggleData = (formdata, payload, params) => {
		payload.mapping_type = 2
		params.mapping_type = 2
		return Axios.post(`organisations/bankaccount/archive/${formdata.id}`, payload).then(({ data }) => {
			this.getList(params)
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call api to get all banks list
	getAllBanksList = (people_id) => {
		return Axios.post(`organisations/bankaccount/get/${people_id}`, { mapping_type: 2 }).then(({ data }) => {
			this.dropdown_all_bank_list = data.data
			return data
		})
	}

}

decorate(PeopleBankAccountStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	agGrid: observable,
	fetchBankNames: observable,
	fetchBankTypes: observable,
	fetchCurrencies: observable,
	dropdown_all_bank_list: observable,
	setPageSize: action,
	setupGrid: action,
	onFilterChanged: action,
	getList: action,
	setEditValues: action,
	getAllBanksList: action,
	ToggleData: action,
	EditData: action,
	AddData: action,
	getRecordById: action,
	onGridChanged: action,
})
