import React from 'react'
import { EyeFilled, FileTextFilled } from '@ant-design/icons'
import { Button } from 'antd'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import NumberFormat from 'react-number-format';

export const ActionRenderer = (props) => {
	const { openSetViewImportLogDetails, openViewDrawer } = props.agGridReact.props.frameworkComponents
	return (
		<div className="action-column">
			<Button type="text" onClick={() => { openViewDrawer(props.data.id) }}><EyeFilled /></Button>
			<Button type="text" onClick={() => { openSetViewImportLogDetails(props.data) }}><FileTextFilled className={props.data.error_in_model ? "redVerifyIcon" : "greenVerifyIcon"} /></Button>
		</div>
	)
}

const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
	)
};

export const gridOptions = {
	columnDefs: [{
		headerName: '#Id',
		field: 'id',
		width: 80
	}, {
		headerName: 'Exchange ID',
		field: 'exchange_ref_id',
		width: 130
	}, {
		headerName: 'Transaction Ref',
		field: 'reference',
		width: 150
	}, {
		headerName: 'Traded',
		field: 'traded_on',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Posted',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		type: 'rightAligned',
		cellRendererFramework: function (data) {
			return data.data && <CurrencyFormat value={data.data.face_value} />
		},
		width: 130
	}, {
		headerName: 'Status',
		field: 'status.status_name',
		width: 130
	}, {
		headerName: 'Logs',
		field: 'error',
		type: 'actionColumn',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Valid", "Error"]
		},
		width: 90
	}],
}
