import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import useStore from '../../../../store'
import { vsmPplBankAccount, vsmNotify } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'

const ActivateComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleBankAccountStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { id } = useParams()

	// Make delete button enable / disable
	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	// Make function call to activate exixting record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = PeopleBankAccountStore.currentValues.id
		var payload = { status: 1 }
		var params = { people_id: id }
		PeopleBankAccountStore.ToggleData(data, payload, params).then(() => {
			vsmNotify.success({
				message: vsmPplBankAccount.activated
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false) })
	}

	// reset form and close activate form
	const close = () => {
		props.close()
		form.resetFields()
	}

	return PeopleBankAccountStore.currentValues ? (
		<Modal
			centered
			title={`Activate Bank Account - #${PeopleBankAccountStore.currentValues.id} - ${PeopleBankAccountStore.currentValues.banks.name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='activateform' loading={saving} htmlType="submit" type="primary" disabled={disabled}>Activate</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="activateform" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to Activate this Bank account?</h3>
						<InputComponent placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} name="confirm" />
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default ActivateComponent
