import { Button, PageHeader, Drawer, Row, Col, Form } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import useStore from '../../../../../store'
import ContentLoader from '../../../../../components/ContentLoader'
import InputComponent from '../../../../../components/InputComponent'
import ListComponent from './ListComponent'



const History = observer((props) => {
    const { FXCurrenciesStore, FXCurrenciesStore: { agGridInner } } = useStore()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState("date")
    const [filter, setFilter] = useState("everyone");
    const handleChange = () => {
        setCurrentTab(form.getFieldValue("filters"))
    }
    const handleFilter = (data) => {
        setFilter(data)
    }

    useEffect(() => {
        setLoading(true)
        setCurrentTab('date')
        FXCurrenciesStore.getViewHistoryList().then((data) => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }, [form, props, FXCurrenciesStore])

    const close = () => {
        props.setHistory(false)

    }
    const exportCsv = () => {
        var params = {
            columnKeys: ['fx_rate_date', 'gbp', 'usd'],
        }
        agGridInner && agGridInner.api.exportDataAsCsv(params)
    }
    return (
        <>
            <Drawer
                visible={props.visible}
                onClose={close}
                width={'68%'}
                title={<b>View History</b>}
                className='header-setClass'
                placement='right'
                destroyOnClose={true}
                extra={[
                    <>
                        <Form form={form} id="addform" className='data-add'>
                            <Row gutter={12}>
                                <Col>
                                    <InputComponent
                                        label={'Generate On'}
                                        required type="radio_button" name="filters" initialValue="date" onChange={() => { handleChange(); form.resetFields(["date", "date_range"]) }}
                                        tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
                                        options={{
                                            values: [
                                                { value: 'date', text: 'Date' },
                                                { value: 'date-range', text: 'Date Range' },
                                            ]
                                        }}
                                    />
                                </Col>
                                <Col>
                                    {currentTab === 'date' ?
                                        <Col>
                                            <InputComponent
                                                label={'Date'}
                                                type="date" name="date"
                                                onChange={handleFilter}
                                                tooltip={'Select specific date to fetch history for.'}
                                                required
                                                style={{ width: '120px' }}
                                            />
                                        </Col>
                                        :
                                        <Col>
                                            <InputComponent
                                                style={{ width: '220px' }}
                                                label={'Date Range'}
                                                type="date_range" name="date_range"
                                                onChange={handleFilter}
                                                tooltip="Used to fetch the history between specific date range."
                                                required

                                            />
                                        </Col>
                                    }
                                </Col>
                                <Col>
                                    <Button key="1" onClick={exportCsv} className='mr-30' title='Export Csv'>Export as CSV</Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ]}
            >
                {!loading ?
                    <PageHeader>
                        <ListComponent filter={filter} form={form} setLoading={setLoading} />
                    </PageHeader> : <ContentLoader />
                }
            </Drawer>
        </>
    )
})

export default History
