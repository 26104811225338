import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button, Drawer, Spin } from 'antd'
import ListComponent from './ListComponent'
import useStore from '../../../../../store'
import { vsmReconciliation, vsmNotify } from '../../../../../config/messages'

const SuggestionComponent = observer((props) => {
	const { ReconciliationStore } = useStore()
	const [loading, setLoading] = useState(true)
	const [currentValues, setCurrentvalues] = useState(null)
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)

	const handleSubmit = () => {
		let selected = ReconciliationStore.agGrid2Inner.api.getSelectedRows()
		selected = selected.map(item => item.id)
		let tteSelected = ReconciliationStore.agGridInner.api.getSelectedRows()
		let tte_selected_ids = tteSelected.map(item => item.id)
		let cte_selected_ids = tteSelected.map(item => item.cte_id)
		if (selected.length > 0) {
			let data = { "journal_log_id": currentValues.journal_log_id, "cte_id": currentValues.cte_id, "tte_transaction_ids": selected }
			let journalLogPayload = { "cte_transaction_ids": ReconciliationStore.selectedIds, "tte_transaction_ids": tte_selected_ids, "log_cte_ids": cte_selected_ids }
			setSaving(true)
			ReconciliationStore.SuggestionSelectData(data, journalLogPayload).then(() => {
				close()
				vsmNotify.success({
					message: vsmReconciliation.reconcile
				})
			}).catch(e => {
				if (e.errors) { }
			}).finally(() => setSaving(false))
		}
	}

	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			ReconciliationStore.getSuggestionById(ReconciliationStore.innerSelectedIds).then((data) => {
				setCurrentvalues(data.other_ids)
				setLoading(false)
			}).catch(() => setLoading(false))
		}

	}, [ReconciliationStore, props.visible])

	const close = () => {
		props.close()
		if (ReconciliationStore.agGrid2Inner) { ReconciliationStore.agGrid2Inner.api.deselectAll() }
		setDisabled(true)
		setCurrentvalues(null)
		ReconciliationStore.suggestion_list = null
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} loading={saving} type="primary" onClick={handleSubmit}>Select</Button>
					<Button className="ml-10" onClick={close}>Back to List</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<PageHeader
					title="Remaining Reconcile Cash Transaction"
				>
					<ListComponent setDisabled={setDisabled} />
				</PageHeader>
			</Spin>
		</Drawer>
	)
})

export default SuggestionComponent
