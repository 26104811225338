import React from 'react'
import { observer } from "mobx-react";
import { Image, Row, Col } from 'antd'
import useStore from '../../../../store';
import RelatedOrganisationList from "../../../PeopleManagement/AllPeople/elements/ListElement/RelatedOrganization";
import RelatedPeopleList from "../../../PeopleManagement/AllPeople/elements/ListElement/RelatedPeople";
import TradeTransactions from "../../../PeopleManagement/AllPeople/elements/ListElement/TradeTransactions";
import CashTransactions from "../../../PeopleManagement/AllPeople/elements/ListElement/CashTransactions";
import Banks from "../../../PeopleManagement/AllPeople/elements/ListElement/Banks";

const ViewElement = observer((props) => {
	const { OrganisationStore: { viewOrgValues } } = useStore()

	var arrays = [], size = 2;
	var a = viewOrgValues.entity_attributes

	if (a && a.length) {
		for (let i = 0; i < a.length; i += size)
			arrays.push(a.slice(i, i + size));
	}

	return (
		<>
			<div class="ant-table ant-table-bordered custom-table">
				<div class="ant-table-container ">
					<div class="ant-table-content ant-table-small">
						<table>
							<tbody className="ant-table-tbody">
								<tr>
									<th width="150px">Organisation Name:</th>
									<td>{viewOrgValues.organisation_name && viewOrgValues.organisation_name}</td>
									<th width="150px">Registration Number:</th>
									<td>{viewOrgValues.registration_number && viewOrgValues.registration_number}</td>
									<td width="150px" rowspan="4">{viewOrgValues.logo_url && <Image src={process.env.React_APP_API_URL + viewOrgValues.logo_url} alt="" />}</td>
								</tr>
								<tr>
									<th>Parent Organisation:</th>
									<td>{viewOrgValues.parent && viewOrgValues.parent.organisation_name}</td>
									<th>FY Start Month:</th>
									<td>{viewOrgValues.fy_start_month}</td>
								</tr>
								<tr>
									<th>Trade Name:</th>
									<td>{viewOrgValues.trade_name && viewOrgValues.trade_name}</td>
									<th>Tax Clearance Access No:</th>
									<td>{viewOrgValues.revenue_access_number && viewOrgValues.revenue_access_number}</td>
								</tr>
								<tr>
									<th>Registration Country:</th>
									<td>{viewOrgValues.global_countries && viewOrgValues.global_countries.country_name}</td>
									<th>Organisation Currency:</th>
									<td>{viewOrgValues.global_currency && viewOrgValues.global_currency.currency_code}</td>
								</tr>
								<tr>
									<th>VAT No:</th>
									<td>{viewOrgValues.vat_number && viewOrgValues.vat_number}</td>
									<th>NACE Code:</th>
									<td colspan="2">{viewOrgValues.global_nace_sections && viewOrgValues.global_nace_sections.code}</td>
								</tr>
								<tr>
									<th>Status:</th>
									<td>{viewOrgValues.status && viewOrgValues.status.status_name}</td>
									<th>Website:</th>
									<td colspan="4">{viewOrgValues.website && viewOrgValues.website}</td>
								</tr>
								{
									arrays && arrays.length > 0 && arrays.map((item) => {
										return (
											<tr>
												{item.map((val, i) => (
													<>
														<th>{val.attribute_name}:</th>
														<td colspan={i === 1 && '2'}>{val.attribute_value}</td>
													</>
												))}
											</tr>
										)
									})
								}
							</tbody>
						</table>

						<table>
							<tbody className="ant-table-tbody">
								<tr>
									<th>Description:</th>
								</tr>
								<tr>
									<td>{viewOrgValues.description}</td>
								</tr>
							</tbody>
						</table>
						<Row>
							{(viewOrgValues.mutiple_addresses && viewOrgValues.mutiple_addresses.length > 0) &&
								<Col span={14}>
									<table>
										<tbody className="ant-table-tbody">
											{
												viewOrgValues.mutiple_addresses.map((item, index) => {
													return (
														<>
															<tr>
																<th>{item.address_type}:</th>
																<th width="150px">City:</th>
																<td>{item.city}</td>
															</tr>
															<tr>
																<td>{item.address_1}</td>
																<th>State/Country:</th>
																<td>{item.state_county}</td>
															</tr>
															<tr>
																<td>{item.address_2}</td>
																<th>Country:</th>
																<td>{item.countries && item.countries.country_name}</td>
															</tr>
															<tr>
																<td>{item.address_3}</td>
																<th>Postal Code:</th>
																<td>{item.postal_code}</td>
															</tr>
														</>
													)
												})
											}
										</tbody>
									</table>
								</Col>
							}
							{
								viewOrgValues.multiple_phone && viewOrgValues.multiple_phone.length > 0 &&
								<Col span={10}>
									<table>
										<tbody className="ant-table-tbody">
											{
												viewOrgValues.multiple_phone && viewOrgValues.multiple_phone.map((item, index) => {
													var header = null
													if (index === 0) {
														header = <tr><th width="150px" colspan="2">Phone:</th></tr>
													}
													return (
														<>
															{header}
															<tr>
																<th>{item.contact_for}:</th>
																<td>{item.contact_value}</td>
															</tr>
														</>
													)
												})
											}
										</tbody>
									</table>
								</Col>
							}
						</Row>
					</div>
				</div>
			</div>


			<RelatedOrganisationList id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<RelatedPeopleList id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<TradeTransactions id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<CashTransactions id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<Banks id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

		</>
	)
})

export default ViewElement
