import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(24, 'edit') &&
				<Button title="Edit Journal" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			}
			{
				(props.data.model_journalline_count > 0) ? (
					UserStore.checkPermission(24, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Journal as it is associated with Models."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					props.data.count_in_ledger_bank_account_count > 0
				) ? (
					(UserStore.checkPermission(24, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Journal as it is associated with Ledger Bank."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>)
				) : (
					UserStore.checkPermission(24, 'delete') &&
					<Button title="Delete Journal" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
				)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		headerTooltip: '# ID',
		tooltipField: 'id',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80,
	}, {
		headerName: 'Code',
		headerTooltip: 'Code',
		field: 'journal_code',
		tooltipField: 'journal_code',
		width: 80,
	}, {
		headerName: 'Type',
		headerTooltip: 'Type',
		field: 'journal_type',
		filter: 'agSetColumnFilter',
		tooltipField: 'journal_type',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 80,
	}, {
		headerName: 'Journal Group',
		headerTooltip: 'Journal Group',
		field: 'journal_groups.journal_group_name',
		tooltipField: 'journal_groups.journal_group_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 200,
	}, {
		headerName: 'Journal Label',
		headerTooltip: 'Journal Label',
		tooltipField: 'journal_label',
		field: 'journal_label',
		width: 150,
	}, {
		headerName: 'Tax Settings',
		headerTooltip: 'Tax Settings',
		tooltipField: 'tax_settings.tax_name',
		field: 'tax_settings.tax_name',
		width: 150,
	}, {
		headerName: 'Journal Description',
		headerTooltip: 'Journal Description',
		field: 'journal_desc',
		tooltipField: 'journal_desc',
		hide: true,
		width: 150,
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		field: 'updated_by',
		tooltipField: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 200,
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		tooltipField: 'updated_at',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		width: 185,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115,
	}]
}
