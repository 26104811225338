import React, { useState } from 'react'
import { Form, Button, Input, Col, Row, Modal } from 'antd'
import useStore from '../../../../../store'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmAlgorithm, vsmNotify } from '../../../../../config/messages'

const UnpublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AlgorithmsStore: { TogglepublishData, statusValues, setupGrid, agGrid, unpublish_list } } = useStore()
	const [saving, setSaving] = useState()
	const [unpublishButton, ToggleUnpublishButton] = useState(true)

	// Make function call to unpublish existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = statusValues.id
		data.status = false
		delete data["unpublish"]
		TogglepublishData(data).then(() => {
			vsmNotify.success({
				message: vsmAlgorithm.unpublish
			})
			close()
			setupGrid(agGrid)
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			ToggleUnpublishButton(true)
		})
	}

	// Make unpublish button enable / disable
	const handleFieldChange = () => {
		if (form.getFieldsValue().unpublish.toLowerCase() === "unpublish") {
			ToggleUnpublishButton(false)
		} else {
			ToggleUnpublishButton(true)
		}
	}

	// reset form and close unpublish form
	const close = () => {
		form.resetFields()
		ToggleUnpublishButton(true)
		props.close()
	}

	return statusValues ? (
		<Modal
			centered
			title={`Unpublish Algorithm - #${statusValues.id} - ${statusValues.algorithm_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='unpublishform' loading={saving} htmlType="submit" type="primary" danger disabled={unpublishButton}>Unpublish</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="unpublishform" onFinish={handleSubmit}>
				<Row>
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Please note that Unpublishing Algorithm will not affect any existing Transaction. It will be disabled and cannot be used for new Transactions. This action will disable all Transaction Models, Fields and Algorithms associated with this Algorithm directly or indirectly.</h3>
						<span>
							<b>Associated Transaction Model:</b>
							<ul>
								{
									(
										unpublish_list && unpublish_list.model.length > 0 ?
											(unpublish_list.model.map((item, index) => (
												<li key={index}>{item.model_name}</li>
											))) : <li>{''}</li>
									)
								}
							</ul>
						</span>
						<span>
							<b>Associated Transaction Fields :</b>
							<ul>
								{
									(
										unpublish_list && unpublish_list.tte_field.length > 0 ?
											(unpublish_list.tte_field.map((item, index) => (
												<li key={index}>{item.field_name}</li>
											))) : <li>{''}</li>
									)
								}
							</ul>
						</span>
						<span>
							<b>Associated Algorithms :</b>
							<ul>
								{
									(
										unpublish_list && unpublish_list.length > 0 ?
											(unpublish_list.algorithm.map((item, index) => (
												<li key={index}>{item.algorithm_name}</li>
											))) : <li>{''}</li>
									)
								}
							</ul>
						</span>
						<Form.Item style={{ marginBottom: 0 }} name="unpublish">
							<Input placeholder='Type "UNPUBLISH" to Confirm' onChange={handleFieldChange} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default UnpublishComponent
