import React, { useState } from 'react'
import { Form, Button, Drawer } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmClassifications, vsmNotify } from '../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [commonError, setCommonError] = useState(null)
	const { ClassificationStore: { AddData } } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.customer_reference = data.customer_reference.filter(x => x);
		data.bank_reference = data.bank_reference.filter(x => x);
		data.transaction_details = data.transaction_details.filter(x => x);
		setSaving(true)
		AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmClassifications.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			let values = form.getFieldsValue(["cte_transactions_type_id", "currency_id", "banks", "bank_account_id", "entity_type", "related_entity_type", "role_id", "related_role_id", "customer_reference", "bank_reference", "transaction_details"])
			let haserror = true;
			if (form.getFieldValue("transaction_is") === "Any") {
				Object.keys(values).forEach(item => {
					if (["customer_reference", "bank_reference", "transaction_details"].includes(item)) {
						if (values[item].filter(x => x !== null && x !== "" && x !== undefined).length > 0) {
							haserror = false
							return false
						}
					} else {
						if (values[item] && values[item].length > 0) {
							haserror = false
							return false
						}
					}
				})
				setCommonError(haserror && "Please select any one classification rule.")
				setDisabled(haserror)
			} else {
				setCommonError(null)
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		setDisabled(true)
		form.resetFields()
		setCommonError(null)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`New Classification`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Form
				form={form} id="addform"
				onChange={handleChange}
				onFinish={handleSubmit}
				layout='vertical'
			>
				<FormComponent
					form={form}
					visible={props.visible}
					onChange={handleChange}
					commonError={commonError}
				/>
			</Form>
		</Drawer>

	)
})

export default AddComponent
