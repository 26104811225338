import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import NumberFormat from 'react-number-format'
import { Drawer, Spin, Typography } from 'antd'
import PreviewCashReportTable from './PreviewCashReportTable'
import NotIdentifiedDetailComponent from './NotIdentifiedDetailComponent'
import { defaultData, DateComparator, globalStatus } from '../../../../utils/GlobalFunction'

const ListComponent = observer(({ childData, setSaving }) => {
	const { BankAuditReportStore, BankAuditReportStore: { setupGrid, listData, PreviewReport, getNotIdentifiedData, onGridChangedPaymentsReceipts } } = useStore()
	const [previewReport, setPreviewReport] = useState(null)
	const [openTransaction, SetTransaction] = useState(false)
	const [modeType, setModeType] = useState(null)
	const [mainBankAcc, setMainBankAcc] = useState(null)
	const [weekNo, setWeekNo] = useState(null)
	const [dataVal, setDataval] = useState(false)
	const [txType, setTxType] = useState(false)
	const { Title } = Typography;
	const [dataExternal, setdataExternal] = useState(false)
	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		}
	}

	const columnDefs = [{

		headerName: "Wk",
		field: 'group_on',
		suppressMenu: true,
		children: [
			{
				headerName: '',
				field: 'group_on',
				suppressMenu: true,
				filter: false,
				sortable: false,
				pinned: 'left',
				width: 82,

				valueFormatter: function (params) {
					if (!["Check", "Total"].includes(params.data.group_on)) {
						if (params.node.rowIndex < 9) {
							return `${params.data.group_on.split('-')[1]}-W0` + (params.node.rowIndex + 1)
						} else {
							return `${params.data.group_on.split('-')[1]}-W` + (params.node.rowIndex + 1)
						}
					}
					else {
						return params.data.group_on
					}
				},
				valueGetter: function (params) {
					if (!["Check", "Total"].includes(params.data.group_on)) {
						if (params.node.rowIndex < 9) {
							return `${params.data.group_on.split('-')[1]}-W0` + (params.node.rowIndex + 1)
						} else {
							return `${params.data.group_on.split('-')[1]}-W` + (params.node.rowIndex + 1)
						}
					}
					else {
						return params.data.group_on
					}
				},
				tooltipValueGetter: function (params) {
					if (!["Check", "Total"].includes(params.data.group_on)) {
						if (params.node.rowIndex < 9) {
							return `${params.data.group_on.split('-')[1]}-W0` + (params.node.rowIndex + 1)
						} else {
							return `${params.data.group_on.split('-')[1]}-W` + (params.node.rowIndex + 1)
						}
					}
					else {
						return params.data.group_on
					}
				}
			}
		]


	}]



	let list_data = []
	let list_object = {}
	let PinnedData = []

	const cellClicked = (data) => {
		SetTransaction(true)
		setMainBankAcc(data.bank_account_number)
		setWeekNo(data.week)
		if (data.week !== "Check") {
			if (['payments', 'receipts'].includes(data.HeaderGroup)) {
				let payload = {
					report_type: "audit-payment-receipt-transactions",
					generate_on: childData.generate_on,
					audit_group_on: "week",
					action: "preview_child_payment_receipt_report",
					bank_account_number: data.bank_account_number,
					reconciliation_ac_num: data.reconciliation_ac_num,
					group_range: data.week,
					mode_type: data.HeaderGroup_by === 'receipts' ? 'receipt' : 'payment'
				}
				if (childData.generate_on === 'financial_year') {
					payload['financial_year'] = childData.financial_year
				} else if (childData.generate_on === 'date_range') {
					payload['date_selection'] = 'range'
					payload['date_range'] = childData.date_range
				}
				PreviewReport(payload).then(data => {
					if (payload.reconciliation_ac_num === 'external') {
						setdataExternal(true)
					}
					data.forEach(item => {
						item.reconciliation_status = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status_name)
					})
					setPreviewReport(data)

				}).catch(e => {
					// if (e.errors) { props.form.setFields(e.errors) }
				})
			} else if (data.HeaderGroup === 'error') {
				let tempPayload = {
					report_type: "audit-errors-transactions",
					generate_on: childData.generate_on,
					audit_group_on: "week",
					action: "preview_child_errors_report",
					bank_account_number: data.bank_account_number,
					reconciliation_ac_num: data.reconciliation_ac_num,
					group_range: data.week,
					mode_type: data.HeaderGroup_by === 'receipts' ? 'receipt' : 'payment',
				}
				if (childData.generate_on === 'financial_year') {
					tempPayload['financial_year'] = childData.financial_year
				} else if (childData.generate_on === 'date_range') {
					tempPayload['date_selection'] = childData.date_range
				}
				getNotIdentifiedData(tempPayload).then(data => {
				}).catch(e => {
					// if (e.errors) { props.form.setFields(e.errors) }
				})
			}
		} else if (data.week === "Check") {
			setModeType(data.week)
			let tempPayload = {
				report_type: "audit-errors-transactions",
				generate_on: childData.generate_on,
				audit_group_on: "week",
				action: "preview_child_errors_report",
				bank_account_number: data.bank_account_number,
				reconciliation_ac_num: data.reconciliation_ac_num,
				// group_range: data.week,
				mode_type: data.HeaderGroup_by === 'receipts' ? 'receipt' : 'payment',
				is_check: true,
			}
			if (childData.generate_on === 'financial_year') {
				tempPayload['financial_year'] = childData.financial_year
			} else if (childData.generate_on === 'date_range') {
				tempPayload['date_selection'] = childData.date_range
			}
			getNotIdentifiedData(tempPayload).then(data => {
			}).catch(e => {
				// if (e.errors) { props.form.setFields(e.errors) }
			})
			setTxType(data.HeaderGroup_by === 'receipts' ? 'receipt' : 'payment')
		}
		if (data.week !== "Check") {
			setTxType(data.HeaderGroup_by === 'receipts' ? 'receipt' : 'payment')
			setModeType(data.HeaderGroup)
		}

	}

	if (listData && Object.keys(listData).length) {
		Object.keys(listData).forEach((acc_no) => {
			let tempAccObj = listData[acc_no]
			Object.keys(tempAccObj).forEach((group) => {
				if (group === 'columns') {
					Object.keys(tempAccObj[group]).forEach((HeaderGroup, index) => {
						let sub_columns = tempAccObj[group][HeaderGroup]
						if (['receipts'].includes(HeaderGroup)) {
							sub_columns && sub_columns.forEach((Header, index) => {

								let coldef = {
									headerName: index !== 0 ? defaultData[HeaderGroup][Header] ? defaultData[HeaderGroup][Header] : '' : `${acc_no && acc_no.replace('e', '')} - ${tempAccObj.currency?.code}`,
									field: acc_no && acc_no.replace('e', ''),
									resizable: false,
									children: []
								}

								coldef.children.push({
									headerName: Header === 'main' ? `${HeaderGroup && HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}` : `${Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}`,
									headerGroupComponentFramework: (params) => {
										return (
											<div className="d-flex align-items-center h-100 cs_pointer">{Header === 'main' ? HeaderGroup && HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}</div>
										)
									},
									headerComponentParams: {
										template:
											`<span class="ag-cell-label-container ${Header === 'errors' ? 'border' : ''}">` +
											`<span ref="eText" class="ag-header-cell-text" style=width:8ch;margin-top:-14px;margin-right:35px role="columnheader"></span>` +
											`<span style=position:absolute;left:7px;bottom:2px;font-size:11px>${tempAccObj[group]?.account_number_wise_currency_code?.[Header] ? tempAccObj[group]?.account_number_wise_currency_code?.[Header] : ''}</span>` +
											'</span>'
									},

									cellStyle: Header === 'errors' && { 'border-right-color': 'black' },
									field: `${HeaderGroup}.${Header}`,
									width: 106,
									cellRendererFramework: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											<div style={styles.cells} onDoubleClick={() => {
												let payload = {
													HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
													HeaderGroup_by: 'receipts',
													bank_account_number: acc_no && acc_no.replace('e', ''),
													reconciliation_ac_num: Header,
													week: params.data.group_on
												}
												params.data?.[acc_no]?.[HeaderGroup]?.[Header] && !['Total'].includes(params?.data?.group_on) && cellClicked(payload)
											}}>
												<NumberFormat value={params.data?.[acc_no]?.[HeaderGroup]?.[Header]} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
											</div>
										) : !['Check'].includes(params?.data?.group_on) ? <div style={styles.cells} onDoubleClick={() => {
											let payload = {
												HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
												HeaderGroup_by: 'receipts',
												bank_account_number: acc_no && acc_no.replace('e', ''),
												reconciliation_ac_num: Header,
												week: params.data.group_on
											}
											!['Total', 'Check'].includes(params?.data?.group_on) && cellClicked(payload)
										}}>0.00</div> : ['errors', 'main'].includes(Header) ? null : <div style={styles.cells}>0.00</div>
									),
									valueGetter: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											params.data?.[acc_no]?.[HeaderGroup]?.[Header]) : !['Check'].includes(params?.data?.group_on) ? 0.00 : ['errors', 'main'].includes(Header) ? null : 0.00
									),
									tooltipValueGetter: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											params.data?.[acc_no]?.[HeaderGroup]?.[Header]) : !['Check'].includes(params?.data?.group_on) ? '0.00' : ['errors', 'main'].includes(Header) ? null : '0.00'
									),
									filter: false,
									sortable: false,
								})
								columnDefs.push(coldef)
							})
						}
						else if (['payments'].includes(HeaderGroup)) {
							let sub_columns = tempAccObj[group][HeaderGroup]
							sub_columns && sub_columns.forEach((Header, index) => {
								let coldef = {
									headerName: index !== 0 ? defaultData[HeaderGroup][Header] ? defaultData[HeaderGroup][Header] : '' : `${acc_no && acc_no.replace('e', '')} - ${tempAccObj.currency?.code}`,
									field: acc_no && acc_no.replace('e', ''),
									children: []

								}

								coldef.children.push({
									headerName: Header === 'main' ? `${HeaderGroup && HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}` : `${Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}`,
									headerGroupComponentFramework: (params) => {
										return (
											<div className="d-flex align-items-center h-100 cs_pointer">{Header === 'main' ? HeaderGroup && HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}</div>
										)
									},
									headerComponentParams: {
										template:
											`<span class="ag-cell-label-container ${Header === 'errors' ? 'border' : ''}">` +
											`<span ref="eText" class="ag-header-cell-text" style=style=width:8ch;margin-top:-14px;margin-right:35px; role="columnheader"></span>` +
											`<span style=position:absolute;left:7px;bottom:2px;font-size:11px>${tempAccObj[group]?.account_number_wise_currency_code?.[Header] ? tempAccObj[group]?.account_number_wise_currency_code?.[Header] : ''}</span>` +
											'</span>'
									},

									cellStyle: Header === 'errors' && { 'border-right-color': 'black' },
									field: `${HeaderGroup}.${Header}`,
									width: 106,
									cellRendererFramework: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											<div style={styles.cells} onDoubleClick={() => {
												let payload = {
													HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
													HeaderGroup_by: 'payments',
													bank_account_number: acc_no && acc_no.replace('e', ''),
													reconciliation_ac_num: Header,
													week: params.data.group_on
												}
												params.data?.[acc_no]?.[HeaderGroup]?.[Header] && !['Total'].includes(params?.data?.group_on) && cellClicked(payload)
											}}>
												<NumberFormat value={params.data?.[acc_no]?.[HeaderGroup]?.[Header]} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
											</div>
										) : !['Check'].includes(params?.data?.group_on) ? <div style={styles.cells} onDoubleClick={() => {
											let payload = {
												HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
												HeaderGroup_by: 'payments',
												bank_account_number: acc_no && acc_no.replace('e', ''),
												reconciliation_ac_num: Header,
												week: params.data.group_on
											}
											!['Total', 'Check'].includes(params?.data?.group_on) && cellClicked(payload)
										}}>0.00</div> : ['errors', 'main'].includes(Header) ? null : <div style={styles.cells}>0.00</div>
									),
									valueGetter: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											params.data?.[acc_no]?.[HeaderGroup]?.[Header]) : !['Check'].includes(params?.data?.group_on) ? 0.00 : ['errors', 'main'].includes(Header) ? null : 0.00
									),
									tooltipValueGetter: (params) => (
										(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
											params.data?.[acc_no]?.[HeaderGroup]?.[Header]) : !['Check'].includes(params?.data?.group_on) ? '0.00' : ['errors', 'main'].includes(Header) ? null : '0.00'
									),
									filter: false,
									sortable: false,
								})
								columnDefs.push(coldef)
							})
						} else if (['external'].includes(HeaderGroup)) {
							let sub_columns = tempAccObj[group][HeaderGroup]
							sub_columns && sub_columns.forEach((Header, index) => {
								let coldef = {
									headerName: index !== 0 ? defaultData[HeaderGroup][Header] ? defaultData[HeaderGroup][Header] : '' : `${acc_no && acc_no.replace('e', '')} - ${tempAccObj.currency?.code}`,
									field: acc_no && acc_no.replace('e', ''),
									children: []
								}
								if (['External'].includes(Header)) {
									coldef.children.push({
										headerName: Header === 'main' ? `${HeaderGroup && HeaderGroup.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}` : `${Header.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')}`,
										headerComponentParams: {
											template:
												`<span class="ag-cell-label-container ${Header === 'errors' ? 'border' : 'border'}">` +
												`<span ref="eText" class="ag-header-cell-text" style=width:100%; role="columnheader"></span>` +
												'</span>'
										},
										cellStyle: Header === 'External' && { 'border-right-color': 'black' },
										field: `${HeaderGroup}.${Header} `,
										width: 106,
										cellRendererFramework: (params) => (
											(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
												<div style={styles.cells}/*  onDoubleClick={() => {
													let payload = {
														HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
														HeaderGroup_by: 'external',
														bank_account_number: acc_no && acc_no.replace('e', ''),
														reconciliation_ac_num: Header,
														week: params.data.group_on
													}
													params.data?.[acc_no]?.[HeaderGroup]?.[Header] && !['Total'].includes(params?.data?.group_on) && cellClicked(payload)
												}} */>
													<NumberFormat value={params.data?.[acc_no]?.[HeaderGroup]?.[Header]} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												</div>
											) : !['Check'].includes(params?.data?.group_on) ? <div style={styles.cells} /* onDoubleClick={() => {
												let payload = {
													HeaderGroup: Header === 'errors' ? 'error' : HeaderGroup,
													HeaderGroup_by: 'receipts',
													bank_account_number: acc_no && acc_no.replace('e', ''),
													reconciliation_ac_num: Header,
													week: params.data.group_on
												}
												!['Total', 'Check'].includes(params?.data?.group_on) && cellClicked(payload)
											}} */>0.00</div> : ['errors', 'main'].includes(Header) ? null : <div style={styles.cells}>0.00</div>
										),
										valueGetter: (params) => (
											(params.data && params.data?.[acc_no] && params.data?.[acc_no]?.[HeaderGroup]?.[Header]) ? (
												params.data?.[acc_no]?.[HeaderGroup]?.[Header]) : !['Check'].includes(params?.data?.group_on) ? 0.00 : ['errors', 'main'].includes(Header) ? null : 0.00
										),

										filter: false,
										sortable: false,
									})
								}
								columnDefs.push(coldef)
							})
						}
					})
				} else {
					if (group && 'currency' !== group) {
						var s_group = group.split('_')
						if (s_group[0] < 10) { s_group[0] = '0' + s_group[0] }
						var c_group = s_group.join('-')

						// comperision acc
						if (Object.keys(list_object).length && Object.keys(list_object).includes(group)) {
							let cloneObj = { ...list_object[group] }
							cloneObj[acc_no] = {
								payments: tempAccObj[group].payments || {},
								receipts: tempAccObj[group].receipts || {},
								external: tempAccObj[group].external || {},
							}
							list_object[group] = cloneObj
						} else {
							list_object[group] = {
								group_on: c_group,
								[acc_no]: {
									payments: tempAccObj[group].payments || {},
									receipts: tempAccObj[group].receipts || {},
									external: tempAccObj[group].external || {}
								}
							}
						}
					}
				}
			})
		})
	} else {
		list_data = []
		PinnedData = []
	}
	let prev_key = null
	let display_key = ''
	if (Object.keys(list_object).length && Object.keys(listData).length && BankAuditReportStore.max_week) {
		Object.keys(list_object)
			.sort((a, b) => {
				var _a = a.split('_')
				var _b = b.split('_')
				_a[1] = parseInt(_a[1])
				_a[0] = parseInt(_a[0])
				_b[1] = parseInt(_b[1])
				_b[0] = parseInt(_b[0])

				if ((_a[1] < _b[1]) || (_a[1] === _b[1] && _a[0] < _b[0])) {

					return -1;

				}
				if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {

					return 1;
				}
				return 0;
			})
			.forEach(key => {
				let new_key = ''
				if (prev_key) {
					new_key = prev_key
					while (new_key !== key && key !== 'Total') {
						var _key = new_key.split('_')
						_key[1] = parseInt(_key[1])
						_key[0] = parseInt(_key[0])
						var _temp = _key[0] + 1
						if (_key[0] >= Number(BankAuditReportStore.max_week)) {
							new_key = (1) + '_' + (_key[1] + 1)
							display_key = '01-' + (_key[1] + 1)
						} else {
							if (_temp < 10) { _temp = '0' + _temp }
							new_key = (_key[0] + 1) + '_' + _key[1]
							display_key = _temp + '-' + _key[1]
						}
						if (new_key !== key) {
							list_data.push({
								group_on: display_key,
								group_range: new_key,
							})
						}
					}
				}
				list_data.push(list_object[key])
				prev_key = key
			})
	}

	if (list_data && list_data.length) {
		let list_total = {
			group_on: 'Total',
			group_range: 'Total',
			account_number: 'Total',
		}

		list_data.forEach((ele) => {
			Object.keys(ele).forEach((item) => {
				list_total[item] = list_total[item] || {}
				if (!['group_on', 'group_range', 'account_number'].includes(item)) {
					Object.keys(ele[item]).forEach(subItem => {
						list_total[item][subItem] = list_total[item][subItem] || {}
						Object.keys(ele[item][subItem]).forEach(lastItem => {
							list_total[item][subItem][lastItem] = list_total[item][subItem][lastItem] || 0
							list_total[item][subItem][lastItem] += Number(ele[item][subItem][lastItem])
						})
					})
				}
			})
		})

		let diffrence_value = {
			group_on: 'Check',
			group_range: 'Check',
			account_number: 'Check',
		}
		if (Object.keys(listData).length) {
			Object.keys(listData).forEach((main_acc_number) => {
				diffrence_value[main_acc_number] = diffrence_value[main_acc_number] || {}
				diffrence_value[main_acc_number]['receipts'] = {}
				diffrence_value[main_acc_number]['payments'] = {}
				Object.keys(listData[main_acc_number]).forEach((week_key) => {
					if (week_key === 'columns') {
						Object.keys(listData[main_acc_number][week_key]).forEach((columns_sum_key) => {
							if (['all_receipt_sum', 'all_peyment_sum'].includes(columns_sum_key)) {
								Object.keys(listData[main_acc_number][week_key][columns_sum_key]).forEach((lastStage) => {
									if ('all_receipt_sum' === columns_sum_key) {
										diffrence_value[main_acc_number]['receipts'][lastStage] = list_total[main_acc_number]['receipts'][lastStage]?.toFixed(6) - listData[main_acc_number][week_key][columns_sum_key][lastStage]?.toFixed(6)
									} else if ('all_peyment_sum' === columns_sum_key) {
										diffrence_value[main_acc_number]['payments'][lastStage] = list_total[main_acc_number]['payments'][lastStage]?.toFixed(6) - listData[main_acc_number][week_key][columns_sum_key][lastStage]?.toFixed(6)
									}
								})
							}
						})
					}
				})
			})
		}
		PinnedData.push(list_total)
		PinnedData.push(diffrence_value)
	}

	const closeNotIdentifiedDrawer = () => {
		BankAuditReportStore.not_identified_data_from = null
		BankAuditReportStore.not_identified_data_to = null
		setModeType(null)
		SetTransaction(false)
	}
	let total = 0;

	let gridOptions = {
		columnDefs: [
			{ headerName: '# ID', field: 'id', headerTooltip: "# ID", filter: 'agNumberColumnFilter', tooltipField: "id", pinned: 'left', width: 64 },
			{
				headerName: 'Posted Date', headerTooltip: "Posted Date", field: 'date', filter: 'agDateColumnFilter', width: 85,
				tooltipField: "date",
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					comparator: DateComparator
				}
			},
			{
				headerName: 'Transaction Details', tooltipField: "transaction_details", field: 'transaction_details', headerTooltip: "Transaction Details",
				width: 155,
				valueGetter: (params) => (params.data && params.data.transaction_details) ? params.data.transaction_details : "",
			},
			{
				headerName: 'Transaction Type', tooltipField: "transactions_type", headerTooltip: "Transaction Type", field: 'transactions_type', filter: 'agSetColumnFilter',
				width: 80,
				filterParams: {
					defaultToNothingSelected: true,
				},
				valueGetter: (params) => (params.data && params.data.transactions_type) ? params.data.transactions_type : "",
			},
			{
				headerName: `${modeType ? modeType.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : 'Amount (Debit / Credit)'}`, field: 'amount', filter: 'agNumberColumnFilter',
				headerTooltip: `${modeType ? modeType.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : 'Amount (Debit / Credit)'}`,
				width: 80,
				tooltipField: "amount",
				cellRendererFramework: (params) => (params.data) ? (
					<div style={styles.cells}>
						<NumberFormat value={params.data.amount} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				) : null
			},
			{
				headerName: 'Reconciled Bank', field: 'reconciled_bank', filter: 'agNumberColumnFilter',
				headerTooltip: "Reconciled Bank",
				tooltipField: "reconciled_bank",
				width: 80,
				cellRendererFramework: (params) => (params.data) ? (
					<NumberFormat value={params.data.reconciled_bank} displayType={'text'} />
				) : null
			}, {
				headerName: 'Currency', field: 'currency_code', filter: 'agSetColumnFilter',
				tooltipField: "currency_code",
				headerTooltip: "Currency",
				width: 51,
				filterParams: {
					defaultToNothingSelected: true,
				}
			}, {
				headerName: 'Organisation / People Name',
				headerTooltip: 'Organisation / People Name',
				field: 'entity_name',
				tooltipField: 'entity_name',
				filter: 'agSetColumnFilter',
				width: 180
			}, {
				headerName: 'Role',
				headerTooltip: 'Role',
				field: 'role_id',
				tooltipField: 'role_id',
				filter: 'agSetColumnFilter',
				width: 120
			}, {
				headerName: 'Related Organisation / People Name',
				headerTooltip: 'Related Organisation / People Name',
				field: 'related_entity_name',
				tooltipField: 'related_entity_name',
				filter: 'agSetColumnFilter',
				width: 180
			}, {
				headerName: 'Related Role',
				headerTooltip: 'Related Role',
				field: 'related_role_id',
				tooltipField: 'related_role_id',
				filter: 'agSetColumnFilter',
				width: 120
			}, {
				headerName: 'Status',
				headerTooltip: 'Status',
				field: 'reconciliation_status',
				tooltipField: 'reconciliation_status',
				filter: 'agSetColumnFilter',
				width: 144
			}
		]
	}
	if (previewReport) {
		previewReport.map(item => {
			if (item.amount) {
				total = total + item.amount
			}
			return item
		})
	}

	let columns = []
	let columnConfig = null /* As null for not showing child data */
	if (columnConfig) {
		let data = columnConfig
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	const getContextMenuItems = (data) => {
		var result = [
			'copy',
			'copyWithHeaders',
			'paste',
			'separator',
			{
				name: 'Export',
				icon: `<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>`,
				subMenu: [
					{
						name: 'CSV Export',
						action: (datav) => {
							data.api && data.api.exportDataAsCsv({
								columnGroups: true

							})
						},
					},
					{
						name: 'Excel Export (.xlsx)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								columnGroups: true

							})
						},
					},
					{
						name: 'Excel Export (.xml)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								exportMode: 'xml',
								suppressTextAsCDATA: true,
								columnGroups: true

							})
						},
					}
				],
			}
		]
		return result
	}

	return (
		<>
			<div className="ag-theme-alpine grid_wrapper ag-top-data" id='for_border'>
				<AgGridReact
					rowData={list_data}
					onGridReady={setupGrid}
					modules={AllModules}
					columnDefs={columnDefs}
					defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
					overlayNoRowsTemplate={vsmCommon.noRecord}
					gridOptions={{ ...LocalGridConfig.options, floatingFiltersHeight: 0, pagination: false }}
					suppressRowClickSelection={true}
					pinnedBottomRowData={PinnedData}
					skipColumnGroupHeaders={true}
					getContextMenuItems={getContextMenuItems}
				/>
			</div>
			{['payments', 'receipts'].includes(modeType) ?
				<Drawer
					visible={openTransaction}
					onClose={() => {
						setdataExternal(null)
						setPreviewReport(null)
						SetTransaction(false)
						setModeType(null)
					}}
					title={`Account Number - ${mainBankAcc} - (${modeType && modeType.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')})`}
					destroyOnClose={true}
					onCancel={() => {
						setdataExternal(null)
						setPreviewReport(null)
						SetTransaction(false)
					}}
					width={"75%"}
				>
					<Spin spinning={!previewReport ? true : false} size="large">
						{(previewReport && Array.isArray(previewReport) && !dataExternal) ? <>
							<div className="" style={{ height: 'calc(100vh - 120px)', marginBottom: 15 }}>
								<div className="ag-theme-alpine grid_wrapper reduce_height">
									<AgGridReact
										rowData={previewReport}
										modules={AllModules}
										columnDefs={columns}
										defaultColDef={{ ...LocalGridConfig.defaultColDef, floatingFilter: true }}
										overlayNoRowsTemplate={vsmCommon.noRecord}
										onGridReady={BankAuditReportStore.setupGridPaymentsReceipts}
										gridOptions={{ ...LocalGridConfig.options, pagination: false }}
										onColumnResized={onGridChangedPaymentsReceipts}
										onColumnMoved={onGridChangedPaymentsReceipts}
										onColumnPinned={onGridChangedPaymentsReceipts}
										onSortChanged={onGridChangedPaymentsReceipts}
										rowHeight={30}
									/>
								</div>
								<Typography className='text-right mb-0 pt-5'>
									<Title level={5}>Total: {Number(total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Title>
								</Typography>
							</div>
						</> : <PreviewCashReportTable rowData={previewReport} modeType={modeType} />}
					</Spin>
				</Drawer>
				: <NotIdentifiedDetailComponent visible={openTransaction} dataValno={dataVal} setDatavalno={setDataval} close={closeNotIdentifiedDrawer} mainBankAcc={mainBankAcc} modeType={modeType} week={weekNo} setSavingvalue={setSaving} txType={txType} seletedYear={childData?.financial_year ? childData?.financial_year : childData?.date_range} />}
		</>
	)
})

export default ListComponent;