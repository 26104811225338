import React, { useState } from 'react'
import { observer } from "mobx-react";
import { Button, Card, PageHeader } from 'antd';
import useStore from '../../../../../../store';
import ListComponent from "./ListComponent";
import AddComponent from "../../../../../CashTransaction/CashTransactions/components/AddComponent";
import EditComponent from "../../../../../CashTransaction/CashTransactions/components/EditComponent";
import ViewComponent from "../../../../../CashTransaction/CashTransactions/components/ViewComponent";
import DeleteComponent from "../../../../../CashTransaction/CashTransactions/components/DeleteComponent";
import VerifyComponent from "../../../../../CashTransaction/Import/components/VerifyComponent";
import { cashTransactionPath } from '../../../../../../utils/GlobalFunction'

const CashTransactionList = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [editModal, setEditModal] = useState(false)
	const [viewModal, setViewModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [verifyDrawer, setVerifyDrawer] = useState(false)
	const { LedgerBankAccountStore, CashTransactionStore, CTEImportStore } = useStore()

	// Open form for add new cash transaction
	const openAddModal = () => {
		LedgerBankAccountStore.getDynamicFieldList()
		setAddModal(true)
	}

	// Close form for close new cash transaction
	const closeAddModal = () => setAddModal(false)

	// set id for edit / view drawer
	const setId = (id) => setTransactionId(id)

	// Open form for edit existing cash transaction and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		LedgerBankAccountStore.getDynamicFieldList()
		setEditModal(true)
	}

	// Close form of edit cash transaction
	const closeEditModal = () => setEditModal(false)

	// Open form for view existing cash transaction and set values to form
	const openViewModal = (data) => {
		setId(data.id)
		LedgerBankAccountStore.getDynamicFieldList()
		setViewModal(true)
	}

	// Close form of view cash transaction
	const closeViewModal = () => setViewModal(false)

	// Open confirmation alert before deleting existing cash transaction
	const openDeleteModal = (data) => {
		CashTransactionStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	const openVerifyDrawer = (data) => {
		setVerifyDrawer(true)
		CTEImportStore.setVerifyValues({ ...data, data_from: 'inner_list' })
	}

	const closeVerifyDrawer = () => {
		setVerifyDrawer(false)
		CashTransactionStore.setupGrid(CashTransactionStore.agGrid)
	}

	// view all
	const viewAll = () => {
		let id = props.currentTab === "people" ? `${props.id}:P` : `${props.id}:O`
		localStorage.setItem("cash_transaction_related_org_filter", id)
		window.open(`${cashTransactionPath}`, "_blank");
	}

	return (
		<>
			<Card className="mb-10">
				<PageHeader
					title="Cash Transactions"
					extra={[
						<Button key="1" onClick={viewAll}>View All</Button>,
						<Button key="2" onClick={openAddModal}>New</Button>
					]}
				>
					<ListComponent openEditModal={openEditModal} openViewModal={openViewModal} openDeleteModal={openDeleteModal} openVerifyDrawer={openVerifyDrawer} />
					<AddComponent visible={addModal} close={closeAddModal} handleViewDetailCall={props.handleViewDetailCall} />
					<EditComponent transactionId={transactionId} setId={setId} visible={editModal} close={closeEditModal} handleViewDetailCall={props.handleViewDetailCall} />
					<ViewComponent transactionId={transactionId} setId={setId} visible={viewModal} close={closeViewModal} handleViewDetailCall={props.handleViewDetailCall} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} handleViewDetailCall={props.handleViewDetailCall} />
					<VerifyComponent visible={verifyDrawer} close={closeVerifyDrawer} handleViewDetailCall={props.handleViewDetailCall} />
				</PageHeader>
			</Card>
		</>
	)
})

export default CashTransactionList
