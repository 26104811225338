import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { globalStatus } from "../../utils/GlobalFunction";

export default class SummaryReportStore {

    summary_list_data = null
    not_identified_data = null
    not_identified_data_from = null
    not_identified_data_to = null
    summary_total = null
    financial_year_list = null
    agGrid = null
    agGridNotIdentifiedLeft = null
    agGridNotIdentifiedRight = null
    account_number_array = []
    dropdown_ledger_org_list = null
    fetching = false
    initialValues = {
        generate_on: 'all_unarchive_data',
        audit_group_on: 'day',
        date_selection: 'range',
        date_x_value: 2,
        date_x_value_type: 'day',
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = localStorage.getItem('summary_report_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
    }

    // Setup grid and set column size to autosize
    setupGridNotIdentifiedLeft = (params) => {
        this.agGridNotIdentifiedLeft = params
    }

    // Setup grid and set column size to autosize
    setupGridNotIdentifiedRight = (params) => {
        this.agGridNotIdentifiedRight = params
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('summary_report_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Set column width after resizing colums
    onGridChangedLeft = (params, leftRef) => {
        localStorage.setItem('summary_report_grid_left', JSON.stringify(leftRef.current.columnApi.getColumnState()))
    }

    // Set column width after resizing colums
    onGridChangedRight = (params, rightRef) => {
        localStorage.setItem('summary_report_grid_right', JSON.stringify(rightRef.current.columnApi.getColumnState()))
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params
        // this.setFilter(params.api)
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay();
        }
    }

    // Filter function for no record found message
    onLeftFilterChanged = (params) => {
        this.agGridNotIdentifiedLeft = params
        // this.setFilter(params.api)
        if (this.agGridNotIdentifiedLeft && this.agGridNotIdentifiedLeft.api.rowModel.rowsToDisplay.length === 0) {
            this.agGridNotIdentifiedLeft.api.showNoRowsOverlay();
        }
        if (this.agGridNotIdentifiedLeft && this.agGridNotIdentifiedLeft.api.rowModel.rowsToDisplay.length > 0) {
            this.agGridNotIdentifiedLeft.api.hideOverlay();
        }
    }

    // Filter function for no record found message
    onRightFilterChanged = (params) => {
        this.agGridNotIdentifiedRight = params
        // this.setFilter(params.api)
        if (this.agGridNotIdentifiedRight && this.agGridNotIdentifiedRight.api.rowModel.rowsToDisplay.length === 0) {
            this.agGridNotIdentifiedRight.api.showNoRowsOverlay();
        }
        if (this.agGridNotIdentifiedRight && this.agGridNotIdentifiedRight.api.rowModel.rowsToDisplay.length > 0) {
            this.agGridNotIdentifiedRight.api.hideOverlay();
        }
    }

    getFinancialYearList = () => {
        return Axios.get(`report/financial/year`).then(({ data }) => {
            this.financial_year_list = data.data

            return data
        })
    }

    getSummaryPreviewDetails = (formData) => {
        return Axios.post(`report/preview/audit_details`, formData).then(({ data }) => {
            var temp = []
            let temp_data = {}
            let seqArray = [44513401, 44513402, 44513403, 44513404, 33049957, 73119157, 73796558, 44513405, 44513406, 44513407, 46049400, 46049401, 46049402, 30002151, 30004413, 50008788, 50389897, 70002178, 70002186, 70002194, 70003085, 80007870, 90002208, 90002216]
            seqArray.forEach(acc_no => {
                Object.keys(data.data).forEach((api_acc) => {
                    if (acc_no === Number(api_acc)) {
                        temp_data[' ' + api_acc] = data.data[api_acc]
                    }
                })
            })
            Object.keys(temp_data).forEach((item) => {
                let tempValue = temp_data[item]
                tempValue['interbank_recognised_difference'] = tempValue['bank_to_bank_reconciliation_count'] - tempValue['identified_count'];
                tempValue['payment_difference'] = tempValue['external_payment_count'] - tempValue['external_payment_recognised'];
                tempValue['external_difference'] = tempValue['external_receipt_count'] - tempValue['external_receipt_recognised'];
            })
            Object.keys(temp_data).forEach((item) => {
                let tempValue = temp_data[item]
                temp.push({
                    account_number: item,
                    closing_balance: tempValue.closing_balance,
                    currency_code: tempValue.currency_code,
                    opening_balance: tempValue.opening_balance,
                    receipts: tempValue.receipts,
                    payments: tempValue.payments,
                })
            })
            this.summary_total = temp
            this.summary_list_data = temp_data

            return data
        })
    }

    // call api to list organisations without current ledger
    getOrgCurrentLedgerMapNotIdentifiedData = (payload) => {
        this.dropdown_ledger_org_list = null
        if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
            this.fetching = true
            return Axios.post(`organisationswithledgerrolemapping/get`, payload).then(({ data }) => {
                this.dropdown_ledger_org_list = data.data
                return data
            }).catch(({ response: { data } }) => {
                return data
            }).finally(() => { this.fetching = false })
        }
        else {
            return Promise.resolve({ data: null })
        }
    }

    getNotIdentifiedData = (formData, type) => {
        return Axios.post(`report/not/identified`, formData).then(({ data }) => {
            if (data.data) {
                data.data.forEach((item) => {
                    item.reconciliation_status = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status)
                    this.account_number_array.push(item.bank_account_number)
                })
            }
            this.account_number_array = [...new Set(this.account_number_array)];
            if (type === 'from') {
                this.not_identified_data_from = data.data
            } else if (type === 'to') {
                this.not_identified_data_to = data.data
            } else {
                this.not_identified_data_from = data.data
                this.not_identified_data_to = data.data
            }
            return data
        })
    }

    manualVerification = (formData) => {
        if ([1, 2].includes(formData.reconciliation_status)) {
            let lrm_id = formData.to_org_ppl === "organisation" ? this.dropdown_ledger_org_list.filter((_x => _x.id === formData.organisation_name)) : this.dropdown_ledger_org_list.filter((_x => _x.id === formData.people_name))
            let customPayload = {
                cte_ids: formData.from_accounts,
                entity_type: formData.to_org_ppl === "organisation" ? 1 : 2,
                entity_id: formData.to_org_ppl === "organisation" ? formData.organisation_name : formData.people_name,
                reconciliation_status: formData.reconciliation_status,
                lrm_id: lrm_id[0].lrm_id
            }
            return Axios.post('report/manually/identified_update', customPayload).then((data) => {
                return data
            }).catch(({ response: { data } }) => {
                return Promise.reject(data)
            })
        } else {
            return Axios.post('report/manually/identified', formData).then((data) => {
                return data
            }).catch(({ response: { data } }) => {
                return Promise.reject(data)
            })
        }
    }
}

decorate(SummaryReportStore, {
    financial_year_list: observable,
    initialValues: observable,
    list_data: observable,
    agGrid: observable,
    summary_list_data: observable,
    summary_total: observable,
    agGridNotIdentifiedLeft: observable,
    agGridNotIdentifiedRight: observable,
    not_identified_data: observable,
    not_identified_data_from: observable,
    not_identified_data_to: observable,
    account_number_array: observable,
    fetching: observable,
    dropdown_ledger_org_list: observable,
    getFinancialYearList: action,
    getSummaryPreviewDetails: action,
    setupGrid: action,
    onGridChanged: action,
    onFilterChanged: action,
    setupGridNotIdentifiedLeft: action,
    setupGridNotIdentifiedRight: action,
    getNotIdentifiedData: action,
    onLeftFilterChanged: action,
    onRightFilterChanged: action,
    manualVerification: action,
    onGridChangedLeft: action,
    onGridChangedRight: action,
    getOrgCurrentLedgerMapNotIdentifiedData: action
})