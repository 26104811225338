import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import useStore from '../../../../store'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import EditComponent from './component/EditComponent'
import ViewComponent from './component/ViewComponent'
import UnpublishComponent from './component/UnpublishComponent'
import PublishComponent from './component/PublishComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import { vsmAlgorithm, vsmNotify } from '../../../../config/messages'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const Algorithms = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [id, setID] = useState(null)
	const [viewModal, setViewModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [unpublishModal, setUnpublishModal] = useState(false)
	const [publishModal, setPublishModal] = useState(false)
	const { AlgorithmsStore: { setDeleteValues, setStatusValues, per_page, setPageSize, getUnpublishRelation, getPublishRelation, TogglepublishData, agGrid }, UserStore } = useStore()

	// open add modal function
	const gotoAddScreen = () => {
		setAddModal(true)
	}

	// close add modal function
	const closAddScreen = () => setAddModal(false)

	// open edit modal function
	const gotoEditScreen = (id) => {
		setID(id)
		setEditModal(true)
	}

	// close edit modal function
	const closeEditScreen = () => setEditModal(false)

	// open view modal function
	const gotoViewScreen = (id) => {
		setID(id)
		setViewModal(true)
	}

	//close view modal function
	const closeViewScreen = () => setViewModal(false)

	// Open confirmation alert before deleting existing Journal Type
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// Close unpublish modal
	const closeUnpublishModal = () => { setUnpublishModal(false) }

	// Close publish modal
	const closePublishModal = () => { setPublishModal(false) }


	const onSwitchChange = (checked, data) => {
		setStatusValues(data)
		if (!checked) {
			agGrid.api.refreshCells({ force: true })
			getUnpublishRelation(data)
			setUnpublishModal(true)
		} else {
			getPublishRelation(data, handlePublish, setPublishModal)
		}
	}

	// Handle Publish and call function to publish record
	const handlePublish = (data) => {
		let formdata = {
			id: data.id,
			status: true
		}
		TogglepublishData(formdata).then(() => {
			vsmNotify.success({
				message: vsmAlgorithm.publish
			})
		}).catch(e => {
			agGrid.api.refreshCells({ force: true })
		})
	}

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('algorithm_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Algorithms.path} />
			{(!UserStore.checkPermission(27, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Algorithms.title}
					extra={[
						UserStore.checkPermission(27, 'add') && <Button key="1" onClick={gotoAddScreen}>New</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent gotoEditScreen={gotoEditScreen} gotoViewScreen={gotoViewScreen} openDeleteModal={openDeleteModal} onSwitchChange={onSwitchChange} />
					{addModal && <AddComponent visible={addModal} close={closAddScreen} />}
					{editModal && <EditComponent id={id} visible={editModal} close={closeEditScreen} />}
					{viewModal && <ViewComponent id={id} visible={viewModal} close={closeViewScreen} />}
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
					<PublishComponent visible={publishModal} close={closePublishModal} />
				</PageHeader>
			)}
		</>
	)
})

export default Algorithms
