import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmPeopleRoleMapping, vsmNotify } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleStore, PeopleRoleMappingStore } = useStore()
	const [saving, setSaving] = useState(false)

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = PeopleRoleMappingStore.deleteValues.id
		PeopleRoleMappingStore.DeleteData(data, PeopleStore.peopleValues.id).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmPeopleRoleMapping.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	const close = () => {
		props.close()
		form.resetFields()
		setSaving(false)
	}

	return PeopleRoleMappingStore.deleteValues ? (
		<Modal
			centered
			title={`Delete Role Mapping with ${PeopleStore.peopleValues && PeopleStore.peopleValues.first_name} ${PeopleStore.peopleValues && (PeopleStore.peopleValues.last_name ? PeopleStore.peopleValues.last_name : '')}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger >Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to remove this Role Mapping?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
