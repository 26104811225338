import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import AddComponent from './components/AddComponent'
import EditComponent from './components/EditComponent'
import ListComponent from './components/ListComponent'
import DeleteComponent from './components/DeleteComponent'
import StatusComponent from './components/StatusComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import { vsmNotify, vsmUserManagement } from '../../../config/messages'
import RestoreUserComponent from './components/RestoreUserComponent'
import ViewLedgerComponent from './components/ViewLedgerComponent'
import { useHistory } from 'react-router-dom'
import PageNotFound from '../../PageNotFound'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import IPSettings from './components/IPSettings'
import EnterFullscreen from '../../../components/EnterFullscreen'

const UserManagement = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [userStatus, setUerStatus] = useState(false)
	const [restoreUser, setRestoreUser] = useState(false)
	const [viewLedger, setViewLedger] = useState(false)
	const [addApiSettingModal, setApiSettingModal] = useState(false)
	const { UserManagementStore, UserStore, IPSettingsStore } = useStore()
	const history = useHistory()
	const Store = {
		UserManagement: UserManagementStore,
		User: UserStore
	}

	// Open form for add new User
	const openAddModal = useCallback(() => {
		setAddModal(true)
	}, [setAddModal])

	history.listen((location, action) => {
		if (location.search === '?add=1' && UserStore.checkPermission(64, 'add')) {
			openAddModal(true)
		}
	})

	useEffect(() => {
		if (history.location.search === '?add=1' && UserStore.checkPermission(64, 'add')) {
			openAddModal(true)
		}
	}, [history, openAddModal, UserStore])

	// Close form for add new User
	const closeAddModal = () => {
		setAddModal(false)
		history.replace(history.location.pathname)
	}

	// Open form for edit existing User and set values to form
	const openEditModal = (data) => {
		Store.UserManagement.getRoleList()
		Store.UserManagement.setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit User
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing user
	const openDeleteModal = (data) => {
		Store.UserManagement.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		if (!checked) {
			Store.UserManagement.agGrid.api.refreshCells({ force: true });
			Store.UserManagement.setStatusValues(data)
			setUerStatus(true)
		} else {
			handleActiveStatus(data)
			setUerStatus(false)
		}
	}

	//handle Status and call function to active status
	const handleActiveStatus = (data) => {
		var payload = {
			id: data.id,
			Status: 1
		}
		Store.UserManagement.ActivateDeactivateDate(payload).then(() => {
			vsmNotify.success({
				message: vsmUserManagement.activeUser
			})
		}).catch(e => { })
	}

	const closeStatsModal = () => setUerStatus(false)

	// Open confirmation alert before restoring the deleted user
	const openRestoreUser = (data) => {
		Store.UserManagement.setRestoreValues(data)
		setRestoreUser(true)
	}

	// close confirmation alert of restore user
	const closeRestoreUser = () => setRestoreUser(false)

	const openViewLedger = (data) => {
		var payload = {
			id: data.id
		}
		Store.UserManagement.getViewLedgerList(payload)
		setViewLedger(true)
	}

	const closeViewLedger = () => setViewLedger(false)

	const getArchivedUser = () => {
		Store.UserManagement.changeArchiveValue(true)
	}

	const getAllUser = () => {
		Store.UserManagement.changeArchiveValue(false)
	}

	// open API setting drawer

	const openAPISettingDrawer = (data) => {
		setApiSettingModal(true)
		IPSettingsStore.setUserValues(data)
	}

	//close API setting drawer
	const closeApiSettingDrawer = () => setApiSettingModal(false)

	const menu = (
		<Menu onClick={handleMenuClick}>
			{UserStore.checkPermission(64, 'status') && <Menu.Item key="approve">Activate</Menu.Item>}
			{UserStore.checkPermission(64, 'status') && <Menu.Item key="disapprove">Deactivate</Menu.Item>}
			{UserStore.checkPermission(64, 'archive') && <Menu.Item key="delete">Archive</Menu.Item>}
			{UserStore.checkPermission(64, 'user_restore') && <Menu.Item key="unarchive">Unarchive</Menu.Item>}
		</Menu>
	);

	function handleMenuClick(e) {
		if (e.key) {
			Store.UserManagement.BulkAction(e.key)
		}
	}

	const handleReset = async () => {
		await localStorage.removeItem('users_grid')
		UserManagementStore.agGrid.api.setFilterModel(null)
		UserManagementStore.agGrid.api.setSortModel(null)
		UserManagementStore.agGrid.columnApi.resetColumnState()
		UserManagementStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.UserManagement.path} />
			{(!UserStore.checkPermission(64, 'list')) ? (<PageNotFound />) : (
				<PageHeader
					className={"has_export_spinner " + UserStore.fullscreen_class}
					title={BreadcrumbConfig.UserManagement.title}
					extra={[
						<>
							{
								Store.UserManagement.archive === true ? (
									<Button key="1" onClick={getAllUser}>Show All User</Button>
								) : (
									<Button key="1" onClick={getArchivedUser}>Show Archived User</Button>
								)
							}
							{UserStore.checkPermission(64, 'bulk_action') && (
								UserStore.checkPermission(64, 'user_restore') ||
								UserStore.checkPermission(64, 'archive') ||
								UserStore.checkPermission(64, 'status')
							) && <Dropdown.Button style={{ marginLeft: 10, marginRight: 10 }} key="2" overlay={menu}>Bulk Action</Dropdown.Button>}
							{UserStore.checkPermission(64, 'add') && <Button key="3" onClick={openAddModal}>New</Button>}
							<RecordPerPage key="4" defaultValue={Store.UserManagement.per_page + ' per page'} onChange={Store.UserManagement.setPageSize} />
							<Button key="5" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>
							<EnterFullscreen key='6' />
						</>
					]}>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal}
						openViewLedger={openViewLedger}
						onSwitchChange={onSwitchChange} openRestoreUser={openRestoreUser}
						openAPISettingDrawer={openAPISettingDrawer}
					/>
					{addModal && <AddComponent visible={addModal} close={closeAddModal} />}
					{editModal && <EditComponent visible={editModal} close={closeEditModal} />}
					{deleteModal && <DeleteComponent visible={deleteModal} close={closeDeleteModal} />}
					{userStatus && <StatusComponent visible={userStatus} close={closeStatsModal} />}
					{restoreUser && <RestoreUserComponent visible={restoreUser} close={closeRestoreUser} />}
					{viewLedger && <ViewLedgerComponent visible={viewLedger} close={closeViewLedger} />}
					{addApiSettingModal && <IPSettings visible={addApiSettingModal} close={closeApiSettingDrawer} />}
				</PageHeader>
			)}
		</>
	)
})

export default UserManagement
