import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Card, Select, Divider, Button, Upload, message } from 'antd'
import useStore from '../../../../store'
import { vsmImport, vsmNotify } from '../../../../config/messages'


const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OldImportStore: { getAllMappingList, dropdown_list, executeTransactions } } = useStore()
	const [fileList, updateFileList] = useState([]);
	const [saving, setSaving] = useState()

	// handle submit api call
	const handleSubmit = (data) => {
		const formData = new FormData();
		formData.append('file', fileList[0]);
		formData.append('ledger_id', 1);
		formData.append('mapping_id', form.getFieldsValue().mapping_id);
		setSaving(true)
		executeTransactions(formData).then(() => {
			vsmNotify.success({
				message: vsmImport.add
			})
			updateFileList([])
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		getAllMappingList()
	}, [getAllMappingList])

	const prop = {
		fileList,
		beforeUpload: file => {
			let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
			if (!isCSV) {
				message.error(`You can only upload CSV file!`);
				return true;
			}
			else {
				updateFileList([file])
				return false
			}
		}
	};

	return (
		<>
			<Card>
				<Form form={form}
					labelCol={{ span: 6 }}
					id="addform"
					onFinish={handleSubmit}
				>
					<Form.Item label="Transaction Type Mapping" required>
						<Form.Item name="mapping_id" noStyle rules={vsmImport.validation.mapping_id}>
							<Select showSearch placeholder="Select Transaction Type Mapping"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									(dropdown_list && dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.mapping_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Form.Item>
					<Form.Item label="Import File" required>
						<Upload
							accept=".csv"
							fileList={fileList}
							onRemove={() => updateFileList([])}
							name="file"
							showUploadList={true}
							multiple={false}
							headers={{
								Authorization: 'Bearer ' + localStorage.getItem('token')
							}}
							{...prop}>
							<Button>Upload File</Button>
						</Upload>
					</Form.Item>
					<Divider />
				</Form>
				<div className="text-center">
					<Button disabled={fileList.length === 0} form="addform" loading={saving} htmlType="submit" type="primary">Submit</Button>
				</div>
			</Card>
		</>
	)
})

export default AddComponent
