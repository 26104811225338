import { Card, Col, Row } from 'antd'
import moment from 'moment'
import React from 'react'
import InputComponent from './InputComponent'

const CreateFormFields = ({ fields, column, onChange }) => {

	const Element = (item, index) => {
		if (item.field_groups) {
			let group = item
			return (
				<Card title={group.field_groups} key={index}>
					<CreateFormFields fields={group.fields} column={2} onChange={onChange} />
				</Card>
			)
		} else {
			let field = item
			let attr = {
				rules: [
					{ required: true, message: 'The Field cannot be empty.' }
				]
			}
			if (field.field_type === 'number') {
				attr.className = 'w-100'
				attr.rules.push({
					pattern: /^([0-9]+|[0-9]+.[0-9]{1,2})$/, message: 'The Field does not contains proper value.'
				})
				attr.min = 0
			}
			if (field.field_type === 'percentage') {
				attr.className = 'w-100'
				attr.type = 'text'
				attr.suffix = "%"
				attr.rules.push({
					pattern: /^([0-9]+|[0-9]+.[0-9]{1,2})$/, message: 'The Field does not contains proper value.'
				})
			}
			if (field.field_type === 'url') {
				attr.type = 'text'
				attr.rules.push({
					type: 'url', message: 'The Field does not contains proper url.'
				})
			}
			if (field.field_type === 'time') {
				attr.className = 'w-100'
				attr.format = "HH:mm"
				attr.initialValue = moment(field.setting_value, 'HH:mm')
			}

			if (field.field_type === 'select' || field.field_type === 'select_multiple') {
				attr.className = 'w-100'
				attr.options = {
					values: field.json_dropdown
				}
				if (field.field_type === 'select_multiple') {
					field.field_type = 'select'
					attr.mode = "multiple"
				}
			}

			if (field.field_type === 'checkbox') {
				attr.defaultChecked = field.setting_value
				attr.valuePropName = field.setting_value && field.setting_value !== '0' ? "checked" : false
			}


			return (
				<InputComponent
					required
					key={index}
					type={field.field_type}
					label={field.setting_label}
					name={field.setting_key}
					initialValue={field.setting_value}
					onChange={onChange}
					{...attr}
				/>
			)
		}
	}

	const ElementLoop = fields ? (
		fields.map((item, index) => {
			if (column) {
				return (
					<Col key={index} span={24 / column}>{Element(item)}</Col>
				)
			}
			return <div key={index}>{Element(item)}</div>
		})
	) : null

	if (column) {
		return (
			<Row gutter={24}>{ElementLoop}</Row>
		)
	} else {
		return ElementLoop
	}
}

export default CreateFormFields
