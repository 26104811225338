import React from 'react'
import { observer } from 'mobx-react'
import { Table, Tooltip } from 'antd';
import { EyeFilled, DisconnectOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { CurrencyFormat } from '../../../../utils/GlobalFunction';
import moment from 'moment'

const TableComponent = observer((props) => {

	// action component
	const ActionHandle = ({ data }) => {
		return (
			<div className="d-flex">
				<Tooltip title="Unlink">
					<Button size="small" type="text" onClick={() => { props.openUnlinkModal(data) }}><DisconnectOutlined /></Button>
				</Tooltip>
				<Tooltip title="View">
					<Button size="small" type="text" onClick={() => {
						if (props.parentOpenTransViewDrawer) {
							props.close()
							props.parentOpenTransViewDrawer(data)
						} else {
							props.openTransViewDrawer(data)
						}
					}}>
						<EyeFilled />
					</Button>
				</Tooltip>
			</div>
		)
	};

	const Columns = [
		{
			title: '# ID',
			dataIndex: 'id',
		}, {
			title: 'Mapping Type',
			dataIndex: 'mapping_name',
		}, {
			title: 'Transaction Ref',
			dataIndex: 'cte_transaction_id',
		}, {
			title: 'Journal',
			dataIndex: 'journal_type',
		}, {
			title: 'Face Value',
			key: 'face_value',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.face_value ? data.face_value : 0} />
			},
		}, {
			title: 'Journal Value',
			key: 'debit_value',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.debit_value ? data.debit_value : 0} />
			},
		}, {
			title: 'Traded On',
			dataIndex: 'traded_on',
		}, {
			title: 'Posted On',
			dataIndex: 'posted_on',
		}, {
			title: 'Reconciliation On',
			key: 'reconciled_on',
			render: (data) => {
				return data.reconciled_on ? moment(data.reconciled_on).format('DD-MM-YYYY') : null
			},
		}, {
			title: 'Exchange ID',
			dataIndex: 'exchange_ref_id',
		}, {
			title: 'Actions',
			key: 'actions',
			render: (data) => <ActionHandle data={data} />
		},
	]

	return (
		<Table
			size='small'
			pagination={false}
			dataSource={props.data}
			columns={Columns}
			rowKey="index"
			bordered
			scroll={{ x: 1300 }}
		/>
	)
})

export default TableComponent
