import React from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Row } from 'antd'
import InputComponent from "../../../../../../components/InputComponent";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmIpSettings } from "../../../../../../config/messages";

const AllowedIPElements = observer(({ onChange }) => {
    return (
        <Form.List name='ip_address'>
            {
                (fields, { add, remove }) => {
                    return fields.map((field, index) => {
                        return (
                            <Row className="w-100" key={field.key} gutter={20} style={{ flexFlow: 'row' }}>
                                <Col flex={1}>
                                    <InputComponent
                                        name={[field.name]}
                                        key={[field.key]}
                                        onChange={onChange}
                                        placeholder="Enter IP Address"
                                        rules={vsmIpSettings.validation.ip_address}
                                        tooltip='Allowed IPs page allows defining IP addresses from which it is allowed to sign in.'
                                    />
                                </Col>

                                <Col>
                                    <div className="d-flex">
                                        {
                                            (fields.length > 1) && (
                                                <Button type="danger" onClick={() => {
                                                    remove(field.name)
                                                    onChange()
                                                }} icon={<MinusOutlined />}></Button>
                                            )
                                        }
                                        {(index === (fields.length - 1)) && (fields.length < 9) && (
                                            <Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
                                                await add()
                                                onChange()
                                            }} icon={<PlusOutlined />} />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                }
            }
        </Form.List>

    )
})

export default AllowedIPElements