import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button } from 'antd'
import ListComponent from './components/ListComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import RecordPerPage from '../../../components/RecordPerPage'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import AddComponent from './components/AddComponent'
import EditComponent from './components/EditComponent'
import DeleteComponent from './components/DeleteComponent'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const RoleManagement = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const { RoleManagementStore, UserStore } = useStore()
	const Store = {
		RoleManagement: RoleManagementStore,
	}

	// Open form for add new Role
	const openAddModal = () => {
		setAddModal(true)
	}

	// Close form for add new Role
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing Role and set values to form
	const openEditModal = (data) => {
		Store.RoleManagement.setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit Role
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing user
	const openDeleteModal = (data) => {
		Store.RoleManagement.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	const handleReset = async () => {
		await localStorage.removeItem('users_grid')
		RoleManagementStore.agGrid.api.setFilterModel(null)
		RoleManagementStore.agGrid.api.setSortModel(null)
		RoleManagementStore.agGrid.columnApi.resetColumnState()
		RoleManagementStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.RoleManagement.path} />
			{(!UserStore.checkPermission(66, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={"has_export_spinner " + UserStore.fullscreen_class}
					title={BreadcrumbConfig.RoleManagement.title}
					extra={[
						UserStore.checkPermission(66, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={Store.RoleManagement.per_page + ' per page'} onChange={Store.RoleManagement.setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
				</PageHeader>
			)}
		</>
	)
})

export default RoleManagement
