import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Table, Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format';
import useStore from '../../../../store';
import { default as TransactionItemsEditComponent } from '../../Administration/TransactionItems/components/EditComponent'
import { default as TransactionItemsDeleteComponent } from '../../Administration/TransactionItems/components/DeleteComponent'

const TableComponent = observer((props) => {
	const [editDrawer, setEditDrawer] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { UserStore, TransactionItemsStore } = useStore()

	// Currency formatter component
	const CurrencyFormat = (prop) => {
		return (
			<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
		)
	};

	const Columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		}, {
			title: 'Batch',
			key: 'batch',
			render: (data) => {
				return data.batch ? data.batch : null
			},
		}, {
			title: 'Asset Number',
			key: 'asset_number',
			render: (data) => {
				return data.asset_number ? data.asset_number : null
			},
		}, {
			title: 'Deposit Received',
			key: 'deposit_received',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.deposit_received ? data.deposit_received : 0} />
			},
		}, {
			title: 'Asset Type',
			key: 'asset_type',
			render: (data) => {
				return data.asset_type ? data.asset_type : null
			},
		}, {
			title: 'Months',
			key: 'months',
			render: (data) => {
				return data.months ? data.months : null
			},
		}, {
			title: 'Period Type',
			key: 'period_type',
			render: (data) => {
				return data.period_type ? data.period_type : null
			},
		}, {
			title: 'Value',
			key: 'value',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.value ? data.value : 0} />
			},
		}, {
			title: 'Sell Rate',
			key: 'sell_rate',
			render: (data) => {
				return data.sell_rate ? data.sell_rate : null
			},
		}, {
			title: 'EOT',
			key: 'eot',
			render: (data) => {
				return data.eot ? data.eot : null
			},
		}, {
			title: 'Asset Name',
			key: 'item_name',
			render: (data) => {
				return data.item_name ? data.item_name : null
			},
		}, {
			title: 'Quantity',
			key: 'quantity',
			render: (data) => {
				return data.quantity ? data.quantity : null
			},
		}, {
			title: 'Unit Price',
			key: 'unit_price',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.unit_price ? data.unit_price : 0} />
			},
		}, {
			title: 'Repair & Maintenance Price',
			key: 'rm_price',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.rm_price ? data.rm_price : 0} />
			},
		}, {
			title: 'Serial Number',
			key: 'serial_no',
			render: (data) => {
				return data.serial_no ? data.serial_no : null
			},
		}, {
			title: 'Asset Classification',
			key: 'classification',
			render: (data) => {
				return data.classification ? data.classification : null
			},
		}, {
			title: 'Description',
			key: 'description',
			render: (data) => {
				return data.description ? data.description : null
			},
		}, {
			title: 'Supplier/Manufacturer',
			key: 'manufacturer',
			render: (data) => {
				return data.manufacturer ? data.manufacturer : null
			},
		}, {
			title: 'Address',
			key: 'address_1',
			render: (data) => {
				var val = (data.address_1 ? data.address_1 : "") +
					(data.address_2 ? ", " + data.address_2 : "") +
					(data.address_3 ? ", " + data.address_3 : "") +
					(data.city ? ", " + data.city : "") +
					(data.state_county ? ", " + data.state_county : "") +
					(data.country ? (', ' + data.country.country_name) : '') +
					(data.postal_code ? ", " + data.postal_code : "")
				return val ? val : null
			},
		}, {
			title: 'Country',
			key: 'country.country_name',
			render: (data) => {
				return data.country ? data.country.country_name : null
			},
		}, {
			title: 'Site No',
			key: 'site_number',
			render: (data) => {
				return data.site_number ? data.site_number : null
			},
		}, {
			title: 'VAT Rate',
			key: 'vat_rate.tax_name',
			render: (data) => {
				return data.vat_rate ? data.vat_rate.tax_name : null
			},
		}, {
			title: 'Tax Type',
			key: 'tax_type_inc_exc',
			render: (data) => {
				return data.tax_type_inc_exc ? parseInt(data.tax_type_inc_exc) === 1 ? "Inclusive" : "Exclusive" : null
			},
		}, {
			title: 'DD Amount ex VAT',
			key: 'dd_amount_exc_vat',
			align: "right",
			render: (data) => {
				return <CurrencyFormat value={data.dd_amount_exc_vat ? data.dd_amount_exc_vat : 0} />
			},
		},
		{
			title: 'Action',
			dataIndex: '',
			key: 'x',
			render: (data) => {
				return (
					<div className="action-column d-flex">
						{(UserStore.checkPermission(22, 'edit')) && <Button title="Edit Transaction Items" type="text" onClick={() => { openEditDrawer(data) }}><EditOutlined /></Button>}
						{(UserStore.checkPermission(22, 'delete')) && <Button title="Delete Transaction Items" type="text" onClick={() => { openDeleteModal(data) }}><DeleteOutlined /></Button>}
					</div>
				)
			},
		},
	];

	// open drawer for edit
	const openEditDrawer = (data) => {
		TransactionItemsStore.setEditValues(data)
		setEditDrawer(true)
	}

	// close drawer for edit
	const closeEditDrawer = () => setEditDrawer(false)

	// Open confirmation alert before deleting existing transactions
	const openDeleteModal = (data) => {
		TransactionItemsStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	return (
		<>
			<Table
				size="small"
				className="transaction_items_table"
				pagination={false}
				dataSource={props.data}
				columns={Columns}
				rowKey="index"
				bordered
				scroll={{ x: 1300 }}
			/>
			<TransactionItemsEditComponent tab={props.tab} visible={editDrawer} close={closeEditDrawer} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} itemPayload={props.itemPayload} />
			<TransactionItemsDeleteComponent visible={deleteModal} close={closeDeleteModal} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} />
		</>
	)
})

export default TableComponent
