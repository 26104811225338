import React, { useState } from 'react'
import { observer } from "mobx-react";
import { Button, Card, PageHeader } from 'antd';
import useStore from '../../../../../../store';
import ListComponent from "./ListComponent";
import { default as PeopleRolemappingAddComponent } from "../../../../RoleMapping/component/AddComponent";
import { default as PeopleRolemappingEditComponent } from "../../../../RoleMapping/component/EditComponent";
import { default as PeopleRolemappingDeleteComponent } from "../../../../RoleMapping/component/DeleteComponent";
import { default as PeopleRolemappingAddBankComponent } from "../../../../RoleMapping/component/AddBankComponent";
import AddComponent from "../../../../../OrganisationManagement/RoleMapping/component/AddComponent";
import EditComponent from "../../../../../OrganisationManagement/RoleMapping/component/EditComponent";
import DeleteComponent from "../../../../../OrganisationManagement/RoleMapping/component/DeleteComponent";
import AddBankComponent from "../../../../../OrganisationManagement/RoleMapping/component/AddBankComponent";
import { relatedOrgPath, relatedPeoplePath } from '../../../../../../utils/GlobalFunction'

const RelatedOrganisationList = observer((props) => {
	const [addPplDrawer, setAddPplDrawer] = useState(false)
	const [addOrgDrawer, setAddOrgDrawer] = useState(false)
	const [editPplDrawer, setEditPplDrawer] = useState(false)
	const [editOrgDrawer, setEditOrgDrawer] = useState(false)
	const [deletePplDrawer, setDeletePplDrawer] = useState(false)
	const [deleteOrgDrawer, setDeleteOrgDrawer] = useState(false)
	const [mapBankPplDrawer, setMapBankPplDrawer] = useState(false)
	const [mapBankOrgDrawer, setMapBankOrgDrawer] = useState(false)
	const [mappingId, setMappingId] = useState(null)
	const { PeopleRoleMappingStore, PeopleBankAccountStore, RoleMappingStore, OrgBankAccountStore } = useStore()

	// set id on edit drawer
	const setId = (id) => setMappingId(id)

	//open organisation role mapping drawer
	const openAddOrgDrawer = () => setAddOrgDrawer(true)

	//close organisation role mapping drawer
	const closeAddOrgDrawer = () => setAddOrgDrawer(false)

	// Open organisation form for edit existing Organisation and set values to form
	const openEditOrgModal = (data) => {
		setId(data.id)
		setEditOrgDrawer(true)
	}

	// Close organisation form of edit Organisation
	const closeEditOrgModal = () => setEditOrgDrawer(false)

	// Open to add Bank Accounts Organisation
	const openMapBankOrgAccount = (data) => {
		OrgBankAccountStore.getAllBanksList(props.id)
		RoleMappingStore.setEditValues(data)
		setMapBankOrgDrawer(true)
	}

	// Close Bank Account Modal Organisation
	const closeBankOrgModal = () => setMapBankOrgDrawer(false)

	// Open confirmation alert before deleting existing Organisation
	const openDeleteOrgModal = (data) => {
		RoleMappingStore.setDeleteValues(data)
		setDeleteOrgDrawer(true)
	}

	// Close confirmation alert for deleting record Organisation
	const closeDeleteOrgModal = () => setDeleteOrgDrawer(false)

	//open people role mapping drawer
	const openAddPplDrawer = () => setAddPplDrawer(true)

	//close people role mapping drawer
	const closeAddPplDrawer = () => setAddPplDrawer(false)

	// Open people form for edit existing Organisation and set values to form
	const openEditPplModal = (data) => {
		setId(data.id)
		setEditPplDrawer(true)
	}

	// Close people form of edit Organisation
	const closeEditPplModal = () => setEditPplDrawer(false)

	// Open to add Bank Accounts People
	const openMapBankPplAccount = (data) => {
		PeopleBankAccountStore.getAllBanksList(props.id)
		PeopleRoleMappingStore.setEditValues(data)
		setMapBankPplDrawer(true)
	}

	// Close Bank Account Modal People
	const closeBankPplModal = () => setMapBankPplDrawer(false)

	// Open confirmation alert before deleting existing people
	const openDeletePplModal = (data) => {
		PeopleRoleMappingStore.setDeleteValues(data)
		setDeletePplDrawer(true)
	}

	// Close confirmation alert for deleting record people
	const closeDeletePplModal = () => setDeletePplDrawer(false)

	// view all
	const viewAll = () => {
		if (props.currentTab === "people") {
			window.open(`${relatedPeoplePath}/${props.id}`, "_blank");
		} else {
			window.open(`${relatedOrgPath}/${props.id}`, "_blank");
		}
	}

	return (
		<>
			<Card className="mb-10 mt-10">
				<PageHeader
					title="Related Organisation"
					extra={[
						<Button key="1" onClick={viewAll}>View All Role Mapping</Button>,
						<Button key="2" onClick={() => { props.currentTab === "people" ? openAddPplDrawer() : openAddOrgDrawer() }}>New</Button>
					]}
				>
					<ListComponent currentTab={props.currentTab} openEditModal={props.currentTab === "people" ? openEditPplModal : openEditOrgModal}
						openDeleteModal={props.currentTab === "people" ? openDeletePplModal : openDeleteOrgModal}
						openMapBankAccount={props.currentTab === "people" ? openMapBankPplAccount : openMapBankOrgAccount}
					/>
					<PeopleRolemappingAddComponent visible={addPplDrawer} close={closeAddPplDrawer} relatedToTab={"Organisation"} parent_id={props.id} handleViewDetailCall={props.handleViewDetailCall} />
					<PeopleRolemappingEditComponent visible={editPplDrawer} close={closeEditPplModal} parent_id={props.id} mappingId={mappingId} setId={setId} handleViewDetailCall={props.handleViewDetailCall} />
					<PeopleRolemappingDeleteComponent visible={deletePplDrawer} close={closeDeletePplModal} handleViewDetailCall={props.handleViewDetailCall} />
					{mapBankPplDrawer && <PeopleRolemappingAddBankComponent visible={mapBankPplDrawer} close={closeBankPplModal} handleViewDetailCall={props.handleViewDetailCall} />}
					<AddComponent visible={addOrgDrawer} close={closeAddOrgDrawer} relatedToTab={"Organisation"} parent_id={props.id} handleViewDetailCall={props.handleViewDetailCall} />
					<EditComponent visible={editOrgDrawer} close={closeEditOrgModal} parent_id={props.id} mappingId={mappingId} setId={setId} handleViewDetailCall={props.handleViewDetailCall} />
					<DeleteComponent visible={deleteOrgDrawer} close={closeDeleteOrgModal} handleViewDetailCall={props.handleViewDetailCall} />
					{mapBankOrgDrawer && <AddBankComponent visible={mapBankOrgDrawer} close={closeBankOrgModal} handleViewDetailCall={props.handleViewDetailCall} />}
				</PageHeader>
			</Card>
		</>
	)
})

export default RelatedOrganisationList
