import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Form, Row, Col, Spin, Drawer, Typography } from 'antd'
import JournalLines from './JournalLines';
import useStore from '../../../../../store';
import { vsmNotify, vsmTransactionModels } from '../../../../../config/messages';
import VerifyTransactionModal from './VerifyTransactionModalComponent';
import InputComponent from '../../../../../components/InputComponent';
const { Paragraph } = Typography;

const VerifyComponent = observer((props) => {
	const [form] = Form.useForm()
	const [verify, setVerify] = useState(false)
	const [unverify, setUnverify] = useState(false)
	const [saving, setSaving] = useState(true)
	const [tip, setTip] = useState('Loading')
	const [disabled, setDisabled] = useState(true)
	const [inputDisabled, setInputDisabled] = useState(true)
	const [activeType, setActiveType] = useState("")
	const [verifyModal, setVerifyModal] = useState(false)
	const [verifyValues, setVerifyValues] = useState(null)
	const { TransactionModelStore: { getJournalLinesList, getRecordById, getFieldTypeList, setArrayList, verifyData, setTransactionList, fieldType_list, executeByField, VerifyAllTransaction }, UserStore } = useStore()

	// Handle verify and call api function to verify / unverify record
	const handleVerify = (current) => {
		current === "verify" ? setVerify(true) : setUnverify(true)
		let data = {
			model_id: verifyValues.id,
			is_tested: current === "verify" ? 1 : 0
		}
		verifyData(data).then(() => {
			vsmNotify.success({
				message: current === "verify" ? vsmTransactionModels.verify : vsmTransactionModels.unverify
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			current === "verify" ? setVerify(false) : setUnverify(false)
		})
	}

	// submit button for api call on update result button
	const handleSubmit = (data) => {
		data.model_id = verifyValues.id
		let check = data.field_values.map((item) => ({ id: item.id, value: item.default_value }))
		data.field_values = check
		setSaving(true)
		setTip('Saving')
		executeByField(data).then(() => {
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	// set data on refresh page
	useEffect(() => {
		if (props.id && props.visible) {
			getFieldTypeList({ model_id: props.id })
			getJournalLinesList({ model_id: props.id })
			getRecordById({ id: props.id }).then((data) => {
				setSaving(false)
				setVerifyValues(data.data)
				setTransactionList()
				setTip(null)
			}).catch(() => {
				vsmNotify.error({
					message: vsmTransactionModels.InvalidRecord
				})
				setArrayList(null)
				setTransactionList()
				props.close()
			})
		}
	}, [props, getRecordById, setTransactionList, getJournalLinesList, getFieldTypeList, setArrayList])

	// set data on refresh page
	useEffect(() => {
		if (props.id && props.visible) {
			form.setFieldsValue({
				field_values: fieldType_list
			})
		}
	}, [props, fieldType_list, form])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// set journalline list & transaction list empty and close verify form
	const close = () => {
		props.close()
		setArrayList(null)
		setTransactionList()
		setActiveType("")
		setInputDisabled(true)
		setVerifyValues(null)
		setSaving(true)
		setTip('Loading')
		setDisabled(true)
	}

	// Open verify transaction modal
	const openVerifyModal = () => {
		setVerifyModal(true)
	}

	// Close verify transaction modal & set close modal effect
	const closeVerifyModal = () => {
		setVerifyModal(false)
		setInputDisabled(true)
		setDisabled(true)
	}

	return (
		<>
			<Drawer
				title={`Verify Transaction Model - #${verifyValues && verifyValues.id} - ${verifyValues && verifyValues.model_code} - ${verifyValues && verifyValues.transaction_type_field_mapping ? verifyValues && verifyValues.transaction_type_field_mapping.mapping_name : ""}`}
				visible={props.visible}
				onClose={close}
				placement='right'
				width={'75%'}
				destroyOnClose={true}
				extra={[
					<Paragraph style={{ marginRight: '40px', marginBottom: '0px' }}>Total {verifyValues && verifyValues.executed_on_tte_count ? verifyValues.executed_on_tte_count : 0} Transaction model executed</Paragraph>
				]}
				footer={[
					<div className="text-center mt-20" key="1">
						{verifyValues && verifyValues.is_tested ? (
							(!UserStore.checkPermission(26, 'mark_as_verified_unverified')) ? null : (
								<>
									<Button disabled={true} className="mr-10 btn-success">Mark Model as Verified</Button>
									<Button className="mr-10" loading={verify} onClick={() => { handleVerify("unverify") }}>Mark Model as Unverified</Button>
								</>
							)
						) : ((!UserStore.checkPermission(26, 'mark_as_verified_unverified')) ? null : (
							<>
								<Button className="mr-10" loading={unverify} onClick={() => { handleVerify("verify") }}>Mark Model as Verified</Button>
								<Button disabled={true} className="mr-10 btn-danger">Mark Model as Unverified</Button>
							</>
						))
						}
						<Button onClick={() => { close() }}>Cancel</Button>
					</div>
				]}
			>
				<Spin size="large" spinning={saving} tip={tip}>
					<div className="ag-theme-alpine grid_wrapper">
						<div className="mb-20">
							<Row gutter={24}>
								<Col span={4} />
								<Col span={20}>
									<Button className="mr-10" onClick={() => VerifyAllTransaction(verifyValues.id)}>Verify All Transactions</Button>
									{UserStore.checkPermission(26, 'verify_existing_transaction') && <Button className="mr-10" type={activeType === "modal" ? "primary" : ""} onClick={() => { openVerifyModal(); setActiveType("modal") }}>Verify With Existing Transaction</Button>}
									{UserStore.checkPermission(26, 'verify_manual_field_transaction') && <Button type={activeType === "button" ? "primary" : ""} onClick={() => { setInputDisabled(false); setDisabled(false); setActiveType("button") }}>Verify By Entering Fields Manually</Button>}
								</Col>
							</Row>
						</div>

						<Row gutter={24}>
							<Col span={4} className="cs_full_height_group">
								<Form form={form} id="verifyform"
									onFinish={handleSubmit}
									onChange={handleChange}
									labelCol={{ span: 24 }}
								>
									<h3 className="mb-10">
										<span>Transaction Model Field Values</span>
										<small>
											<Tooltip title="Transaction Model Field Values is Panel containing multiple Transaction Fields and values for same. This is very important to generate Debit, Credit and Posting values in Right panel. All Fields in Left panel are required. ">
												<InfoCircleOutlined className="ml-5" />
											</Tooltip>
										</small>
									</h3>
									<Form.List name='field_values'>
										{
											(fields, { add, remove }) => {
												return fields && fields.map((field, index) => {
													var item = form.getFieldValue("field_values")[field.name]
													return (
														<div key={index}>
															{
																(item.field_type === "Flag" || item.field_type === "Date") ? (
																	item.field_type === "Flag" ? (
																		<InputComponent
																			type="radio_button"
																			label={item.field_name}
																			key={[field.key, "default_value"]}
																			name={[field.name, "default_value"]}
																			placeholder={item.field_name}
																			disabled={inputDisabled}
																			options={{
																				values: [
																					{ value: 'true', text: 'True' },
																					{ value: 'false', text: 'False' }
																				]
																			}}
																		/>
																	) : (
																		<InputComponent
																			type="date"
																			label={item.field_name}
																			key={[field.key, "default_value"]}
																			name={[field.name, "default_value"]}
																			placeholder={item.field_name}
																			// defaultValue={item.default_value && moment(item.default_value)}
																			disabled={inputDisabled}
																		/>
																	)
																) : (
																	<InputComponent
																		label={item.field_name}
																		key={[field.key, "default_value"]}
																		name={[field.name, "default_value"]}
																		placeholder={item.field_name}
																		disabled={inputDisabled}
																		rules={vsmTransactionModels.validation.value}
																	/>
																)}
														</div>
													)
												})
											}
										}
									</Form.List>
									<div className="affix_update_button text-center">
										<Button loading={saving} htmlType="submit" disabled={disabled || (fieldType_list && fieldType_list.length <= 0)} className="mr-10" type="primary">Update Result</Button>
									</div>
								</Form>
							</Col>

							<Col span={20}>
								<div className="tablesize_overflow">
									<JournalLines currentView={"verify"} page="Transaction Modal" />
								</div>
							</Col>
						</Row>
					</div>
				</Spin>
				<VerifyTransactionModal visible={verifyModal} verifyValues={verifyValues} close={closeVerifyModal} />
			</Drawer>
		</>
	)
})

export default VerifyComponent
