import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import useStore from '../../../../../../store'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmTransactionFieldMapping, vsmNotify } from '../../../../../../config/messages'

const UnpublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionFieldMappingStore: { TogglepublishData, unpublishValues } } = useStore()
	const [saving, setSaving] = useState()
	const [unpublishButton, ToggleUnpublishButton] = useState(true)

	// Make function call to unpublish existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = unpublishValues.id
		data.status = false
		delete data["unpublish"]
		TogglepublishData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionFieldMapping.unpublish
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			ToggleUnpublishButton(true)
		})
	}

	// Make unpublish button enable / disable
	const handleFieldChange = () => {
		if (form.getFieldsValue().unpublish.toLowerCase() === "unpublish") {
			ToggleUnpublishButton(false)
		} else {
			ToggleUnpublishButton(true)
		}
	}

	// set the button disabled
	useEffect(() => {
		ToggleUnpublishButton(true)
	}, [unpublishValues, form])

	return (
		<Modal
			title={`Unpublish Trade Transaction`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='unpublishform' loading={saving} htmlType="submit" type="primary" danger disabled={unpublishButton}>Unpublish</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					ToggleUnpublishButton(true)
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="unpublishform" onFinish={handleSubmit}>
				<Row>
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Please note that unpublishing Trade Transaction will not affect any existing transactions, It will be disabled and cannot be used for new transactions. This action will disable all Transaction Models associated with this Trade Transaction.</h3>

						<Form.Item style={{ marginBottom: 0 }} name="unpublish">
							<Input placeholder='Type "UNPUBLISH" to Confirm' onChange={handleFieldChange} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
})

export default UnpublishComponent
