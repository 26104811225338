import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import useStore from '../../../../store'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmAuth } from '../../../../config/messages'

const ResetPassworkComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState(true)
	const { UserManagementStore, UserStore } = useStore()
	const Store = {
		UserManagement: UserManagementStore,
		User: UserStore
	}

	const handleSubmit = () => {
		setSaving(true)
		var payload = {
			email: Store.UserManagement.resetValues.email
		}
		Store.User.doResetPassword(payload).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmAuth.forgot_success
			})
			form.resetFields()
			setSaving(true)
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))

	}

	useEffect(() => {
		if (props.visible) {
			setSaving(false)
		}
	}, [props.visible])

	return Store.UserManagement.resetValues ? (
		<Modal
			centered
			title={`Reset Password - #${Store.UserManagement.resetValues.id} - ${Store.UserManagement.resetValues.first_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='resetPassform' loading={saving} htmlType="submit" type="primary" danger>Yes</Button>,
				<Button key="2" disabled={saving === true} htmlType="button" onClick={() => {
					form.resetFields()
					props.close()
					setSaving(true)
				}}>No</Button>
			]}
		>
			<Form form={form} id="resetPassform" onFinish={handleSubmit}
				labelCol={{ span: 6 }}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Reset Password ?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default ResetPassworkComponent
