import React from 'react'
import { Form, Modal } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmFXCurrencies } from '../../../../config/messages'

const FormComponent = observer(({ title, visible, close, formButtons, form, form_id, setDisabled, handleSubmit }) => {

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		// form.setFieldsValue({ currency_code: form.getFieldValue('currency_code').toUpperCase(), })
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	return (
		<Modal
			centered
			title={title}
			visible={visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={formButtons}
		>
			<Form form={form}
				id={form_id}
				layout="vertical"
				onChange={handleChange}
				onFinish={handleSubmit}
			>
				<InputComponent
					required label="Currency Name" name="currency_name" placeholder="Currency Name"
					tooltip="Currency is a medium of exchange for goods and services."
					rules={vsmFXCurrencies.validation.currency_name}
					autoComplete="off"
					maxLength={100}
				/>
				<InputComponent
					required label="Code" name="currency_code" placeholder="Code"
					tooltip="Currency codes are the three-letter alphabetic codes that represent the various currencies used throughout the world."
					rules={vsmFXCurrencies.validation.currency_code}
					autoComplete="off"
				/>
				<InputComponent
					required label="Symbol" name="currency_symbol" placeholder="Symbol"
					tooltip="A currency symbol or currency sign is a graphic symbol used as a shorthand for a currency's name, especially in reference to amounts of money."
					rules={vsmFXCurrencies.validation.currency_symbol}
					autoComplete="off" maxLength={1}
				/>
			</Form>
		</Modal>
	)
})

export default FormComponent
