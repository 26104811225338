import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class TransactionItemsStore {

	editValues = null
	deleteValues = null

	classification_list = null
	manufacturer_list = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	getClassification = () => {
		return Axios.get(`trade/transaction-items/classification/get`).then(data => {
			this.classification_list = data.data.data
			return data.data
		}).catch(({ response: { data } }) => {
			this.classification_list = null
			return data
		})
	}

	getManufacturer = () => {
		return Axios.get(`trade/transaction-items/manufacturer/get`).then(data => {
			this.manufacturer_list = data.data.data
			return data.data
		}).catch(({ response: { data } }) => {
			this.classification_list = null
			return data
		})
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`trade/transaction-items/add`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`trade/transaction-items/edit/${formdata.id}`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`trade/transaction-items/delete/${formdata.id}`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(TransactionItemsStore, {
	editValues: observable,
	deleteValues: observable,
	classification_list: observable,
	manufacturer_list: observable,
	AddData: action,
	EditData: action,
	DeleteData: action,
	setEditValues: action,
	setDeleteValues: action,
	getClassification: action,
	getManufacturer: action
})
