import React, { useCallback, useEffect, useState } from 'react'
import { Form, Row, Col, Spin } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmCashTransactions } from '../../../../config/messages'
import useStore from '../../../../store'
import TableComponent from '../elements/ReconciledTable'
import moment from 'moment'

const FormComponent = observer(({ visible, onFinish, onChange, tab, openUnlinkModal, openTransViewDrawer, form, parentOpenTransViewDrawer, close }) => {
	// const [add_field_form] = Form.useForm()
	const [formDataList, setFormDataList] = useState([])
	const [fetchAccounts, setFetchAccounts] = useState(true)
	// const [fetchTag, setFetchTag] = useState(true)
	const [fetchType, setFetchType] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchClassfication, setFetchClassification] = useState(true)
	// const [saving, setSaving] = useState(false)
	// const [fetchOrgRole, setFetchOrgRole] = useState(true)
	const { CashTransactionStore, ClassificationStore, CTEImportStore, OrgBankAccountStore, /*SavedReportStore, LedgerBankAccountStore, RoleMappingStore */ } = useStore()

	// set the form values to edit
	useEffect(() => {
		if (visible) {
			setFetchAccounts(true)
			// setFetchTag(true)
			setFetchType(true)
			setFetchCurrency(true)
			setFetchClassification(true)
			// form.setFieldsValue({ cte_fields: [null] })
		}
	}, [form, visible])

	//set organization switch case
	const getFormDataList = useCallback(() => {
		var FormDataList = []
		switch (form.getFieldValue('entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'main_people_role', 'main_people']
				break;
			default:
				FormDataList = [...FormDataList, 'role_id', 'entity_id']
		}

		switch (form.getFieldValue('related_entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'related_people_role', 'related_people']
				break;
			default:
				FormDataList = [...FormDataList, 'related_role_id', 'related_entity_id']
		}
		return FormDataList
	}, [form])

	// Set the form values to edit
	useEffect(() => {
		let editValues = CashTransactionStore.editValues
		if (CashTransactionStore.editValues && visible) {
			let bank = editValues.ledgers_bank && { id: editValues.bank_account_id, bankAccount_names: editValues.ledgers_bank.name + " - " + (editValues.ledgers_bank.iban ? editValues.ledgers_bank.iban : editValues.ledgers_bank.account_number) }
			CTEImportStore.bank_list = bank && [bank]
			form.setFieldsValue({
				bank_account_id: editValues.bank_account_id,
				cte_transaction_classification_id: editValues?.cte_transaction_classification_id,
				cte_transactions_type_id: editValues.cte_transactions_type_id,
				transaction_details: editValues.transaction_details,
				face_value: editValues.credit ? "credit" : "debit",
				amount: editValues.credit ? editValues.credit : editValues.debit,
				currency_id: editValues.currency_id,
				posted_on: editValues.posted_on ? moment(editValues.posted_on) : null,
				value_on: editValues.value_on ? moment(editValues.value_on) : null,
				balance: editValues.balance,
				customer_reference: editValues.customer_reference,
				bank_reference: editValues.bank_reference,
				note: editValues?.note
			})
		}
	}, [visible, CTEImportStore, CashTransactionStore.editValues, form])

	useEffect(() => {
		if (visible) {
			var data = getFormDataList()
			if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
				setFormDataList(data)
			}
		}
	}, [getFormDataList, setFormDataList, CashTransactionStore.editValues, formDataList, visible])

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		onChange()
	}

	const setCurrency = () => {
		let seleted_bank = form.getFieldValue('bank_account_id')
		OrgBankAccountStore.dropdown_currency_list = CTEImportStore.bank_list && [CTEImportStore.bank_list.filter(_x => _x.id === seleted_bank)[0]?.currency]
		form.setFieldsValue({
			currency_id: OrgBankAccountStore.dropdown_currency_list && OrgBankAccountStore.dropdown_currency_list[0].id
		})
	}

	return (
		<Form
			form={form}
			id={"addeditform"}
			onFinish={onFinish}
			layout="vertical"
		>
			<Row gutter={12}>
				{/* Bank Account */}
				<Col span={8}>
					<InputComponent
						required type="select" label="Bank Account" name="bank_account_id" placeholder="Select Bank Account"
						tooltip="A name or code given to a cash account that indicates the account's purpose."
						rules={vsmCashTransactions.validation.bank_account}
						onChange={() => { handleChange(); setCurrency() }}
						onFocus={() => fetchAccounts && CTEImportStore.getBankList().then(() => setFetchAccounts(false))}
						notFoundContent={fetchAccounts ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: CTEImportStore.bank_list,
							value_key: 'id',
							text_key: 'bankAccount_names',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Posted Date */}
				<Col span={4}>
					<InputComponent
						required type="date" label="Posted Date" name="posted_on"
						tooltip="The posted date is the day, month, and year when a transaction takes place. It is the date on which funds are taken or added to an account."
						onChange={handleChange}
						rules={vsmCashTransactions.validation.posted_date}
						disabled={tab === "view"}
						className="w-100"
						disabledDate={(current) => current.isAfter(moment().subtract(0, "day"))}
					/>
				</Col>

				{/* Transaction Type */}
				<Col span={4}>
					<InputComponent
						required type="select" mode="tags" max={1} label="Transaction Type" name="cte_transactions_type_id" placeholder="Select Transaction Type"
						tooltip="Cash Transaction Type indicates the type of cash transaction as defined by the bank. The parameter can be obtained - if available - from the Transaction Type or Transaction Details, i.e. Direct Debit, Transfer, etc.."
						rules={vsmCashTransactions.validation.trans_type}
						onChange={(value) => { handleChange(); if (typeof value !== 'string' && value.length > 1) { value.pop(); } }}
						onFocus={() => fetchType && ClassificationStore.getCteTypes().then(() => setFetchType(false))}
						notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.cteTypesList,
							value_key: 'id',
							text_key: 'type_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Face Value */}
				<Col span={8}>
					<Row gutter={6} className="no-wrap" style={{ alignItems: 'end' }}>
						<Col span={13}>
							<InputComponent
								required type="radio_button" name="face_value" initialValue="debit"
								label={'Face Value'}
								onChange={handleChange}
								options={{
									values: [
										{ value: 'debit', text: 'Debit' },
										{ value: 'credit', text: 'Credit' },
									]
								}}
								disabled={tab === "view"}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								name="amount" onChange={handleChange} placeholder="Enter Amount" className='text-right'
								tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
								rules={vsmCashTransactions.validation.amount}
								disabled={tab === "view"}
							/>
						</Col>
					</Row>
				</Col>

				{/* Cash Transaction Classification */}
				<Col span={3}>
					<InputComponent
						type="select" label="Classification" name="cte_transaction_classification_id" placeholder="Select Classification Classification"
						tooltip="Classifications are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) Classifications against Organisations stored in the system’s database."
						// rules={vsmCashTransactions.validation.cash_trans_classification}
						onChange={handleChange}
						onFocus={() => fetchClassfication && ClassificationStore.getClassfications().then(() => setFetchClassification(false))}
						notFoundContent={fetchClassfication ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.classificationList,
							value_key: 'id',
							text_key: 'transaction_class_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				<Col span={3}>
					<InputComponent
						required type="select" label="Currency" name="currency_id" placeholder="Select Currency"
						tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
						rules={vsmCashTransactions.validation.currency}
						onChange={handleChange}
						onFocus={() => fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: OrgBankAccountStore.dropdown_currency_list,
							value_key: 'id',
							text_key: 'currency_code',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Transaction Details */}
				<Col span={5}>
					<InputComponent
						required label="Transaction Details" name="transaction_details" placeholder="Enter Transaction Details"
						tooltip="Transaction Details is details given by bank in bank statement containing important information about transaction."
						rules={vsmCashTransactions.validation.trans_details}
						onChange={handleChange}
						disabled={tab === "view"}
						maxLength={1000}
					/>
				</Col>

				{/* Balance */}
				<Col span={3}>
					<InputComponent
						label="Balance" name="balance" placeholder="Enter Balance" className='text-right'
						tooltip="Balance per bank is the value of the initial state of the cash account. Just for Reference purpose."
						onChange={handleChange}
						rules={vsmCashTransactions.validation.balance}
						disabled={tab === "view"}
					/>
				</Col>
				{/* Value Date */}
				<Col span={4}>
					<InputComponent
						type="date" label="Value Date" name="value_on"
						tooltip="The value date is the date when the payment is actually paid/received by the customer. If not, the value date by default remains the same as the posted date."
						// note="If Value Date field is Blank then it will be same as Posted Date field."
						onChange={handleChange}
						disabled={tab === "view"}
						className="w-100"
						disabledDate={(current) => current.isAfter(moment().subtract(0, "day"))}
					/>
				</Col>

				{/* Customer Reference */}
				<Col span={3}>
					<InputComponent
						label="Customer Reference"
						name="customer_reference"
						placeholder="Enter Customer Reference"
						tooltip="Customer Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmCashTransactions.validation.customer_reference} onChange={handleChange}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Bank Reference */}
				<Col span={3}>
					<InputComponent
						label="Bank Reference"
						name="bank_reference"
						placeholder="Enter Bank Reference"
						tooltip="Bank Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmCashTransactions.validation.bank_reference} onChange={handleChange}
						disabled={tab === "view"}
					/>
				</Col>
				<Col span={8}>
					<InputComponent
						type='textarea'
						label="Manual Notes"
						name="note"
						placeholder="Enter Manual Notes"
						tooltip="While adding Transaction you can add Manual Notes to add information for this transaction item."
						onChange={handleChange}
						disabled={tab === "view"}
					/>
				</Col>
			</Row>

			{/* <div style={{ width: "440px", display: 'inline-block', }}>
				<DynamicFieldElement
					form={form}
					saving={saving}
					onChange={handleChange}
					add_field_form={add_field_form}
					handleAddField={handleAddField}
					disabled={tab === "view"}
				/>
			</div> */}

			{/* Reconciled Table */}
			{
				tab === "view" && (
					<div className="mt-20 mb-10">
						<h3>
							<span>Reconciled With Trade Transactions</span>
						</h3>
						<TableComponent openUnlinkModal={openUnlinkModal} close={close} parentOpenTransViewDrawer={parentOpenTransViewDrawer} openTransViewDrawer={openTransViewDrawer} data={CashTransactionStore.editValues && CashTransactionStore.editValues.child_transactions_journal_logs} />
					</div>
				)
			}
		</Form >
	)
})

export default FormComponent
