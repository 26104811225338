import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import NumberFormat from 'react-number-format';

const PreviewSummaryDetailsComponent = observer(() => {

	const { SummaryReportStore } = useStore()

	const rowData = []
	const rowHeaders = [{ key: 'opening_balance', value: 'Opening Balance' }, { key: 'payments', value: 'Outbound Payments' }, { key: 'receipts', value: 'Inbound Receipts' }, { key: 'closing_balance', value: 'Closing Credebt Balance' }, { key: 'total_ledger_balance', value: 'Closing Bank Balance' }, { key: 'bank_to_bank_reconciliation_count', value: 'Interbank #' }, { key: 'identified_count', value: 'Recognised #' }, { key: 'interbank_recognised_difference', value: 'Internal Unrecognised #' }, { key: 'external_payment_sum', value: 'External Payments' }, { key: 'external_payment_count', value: 'External Payments #' }, { key: 'external_payment_recognised', value: 'External Payments Recognised #' }, { key: 'payment_difference', value: 'External Payments Unrecognised #' }, { key: 'external_receipt_sum', value: 'External Receipts' }, { key: 'external_receipt_count', value: 'External Receipts #' }, { key: 'external_receipt_recognised', value: 'External Receipts Recognised #' }, { key: 'external_difference', value: 'External Receipts Unrecognised #' }, { key: 'charges_count', value: 'Charges #' }, { key: 'interests_count', value: 'Interests #' }, { key: 'errors_count', value: 'Errors #' }, /* { key: 'classification_recognised', value: 'Classification Recognised' }, { key: 'not_identified', value: 'Identify Data' }, */ { key: 'total_cte_count', value: 'Total' }, /* { key: 'verify_data', value: 'Verify Data' }, { key: 'verify_data_for_classification', value: 'Verify Data For Classification' } */ { key: 'recognised_data_count', value: 'Recognised Data #' }, { key: 'pending_data_count', value: 'Unrecognised #' }]
	var newObj = []
	var payload = {}

	rowHeaders.forEach((rowHeaders) => {
		payload = { account_number: rowHeaders.value }
		rowData.push(payload)
	})
	rowData.forEach((item) => {
		var total = 0
		if (SummaryReportStore.summary_list_data && Object.keys(SummaryReportStore.summary_list_data).length) {
			Object.keys(SummaryReportStore.summary_list_data).forEach((key) => {
				let tempItem = SummaryReportStore.summary_list_data[key]
				let header_index = rowHeaders.filter(x => x.value === item.account_number)
				if (header_index) {
					if (header_index[0].key === 'opening_balance') {
						item[key] = { 'date': tempItem['opening_balance_first_date'], value: tempItem[header_index[0]['key']] }
					}
					else if (header_index[0].key === 'closing_balance') {
						item[key] = { 'date': tempItem['closing_balance_last_date'], value: tempItem[header_index[0]['key']] }
					}
					else {
						item[key] = tempItem[header_index[0]['key']]
					}
					if (header_index[0].key !== 'opening_balance_first_date' && header_index[0].key !== 'closing_balance_last_date') {
						total += Number(tempItem[header_index[0]['key']])
					}
				}
			})
		}
		item['total'] = total
	})

	Object.keys(rowData[0]).forEach((item) => {
		if (item !== 'account_number' && item !== 'total') {
			newObj.push(item)
		}
	})

	const styles = {
		number_cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		},
		count_cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: '50%'
		},
		date_cell: {
			lineHeight: '2px',
			fontSize: '9px',
			marginTop: '5px'
		}
	}

	ModuleRegistry.registerModules([
		ClientSideRowModelModule,
		CsvExportModule,
		ExcelExportModule,
		MenuModule,
		RangeSelectionModule,
		ClipboardModule,
	]);

	const processCellForClipboard = useCallback((params) => {
		if (typeof params.value === 'object') {
			if (params.value?.date) {
				return params.value?.date + ', ' + params.value?.value
			} else {
				return params.value?.value
			}
		} else {
			return params.value ? params.value : 0
		}
	}, []);

	const getContextMenuItems = (data) => {
		var result = [
			'copy',
			'copyWithHeaders',
			'paste',
			'separator',
			{
				name: 'Export',
				icon: `<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>`,
				subMenu: [
					{
						name: 'CSV Export',
						action: (datav) => {
							data.api && data.api.exportDataAsCsv({
								processCellCallback: function (cell) {
									if (typeof cell.value === 'object') {
										if (cell.value?.date) {
											return cell.value?.date + ', ' + cell.value?.value
										} else {
											return cell.value?.value
										}
									} else {
										return cell.value
									}
								},
							})
						},
					},
					{
						name: 'Excel Export (.xlsx)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								processCellCallback: function (cell) {
									if (typeof cell.value === 'object') {
										if (cell.value?.date) {
											return cell.value?.date + ', ' + cell.value?.value
										} else {
											return cell.value?.value
										}
									} else {
										return cell.value
									}
								},
							})
						},
					},
					{
						name: 'Excel Export (.xml)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								exportMode: 'xml',
								suppressTextAsCDATA: true,
								processCellCallback: function (cell) {
									if (typeof cell.value === 'object') {
										if (cell.value?.date) {
											return cell.value?.date + ', ' + cell.value?.value
										} else {
											return cell.value?.value
										}
									} else {
										return cell.value
									}
								},
							})
						},
					}
				],

			}

		]
		return result
	}

	return (
		<div className="ag-theme-alpine grid_wrapper mt-10 reduce_height ag_table_grid_header" style={{ width: "100%", height: '729px' }}>
			<AgGridReact
				rowData={rowData}
				className='custom_style'
				modules={AllModules}
				onGridReady={SummaryReportStore.setupGrid}
				onColumnResized={SummaryReportStore.onGridChanged}
				onColumnMoved={SummaryReportStore.onGridChanged}
				onColumnPinned={SummaryReportStore.onGridChanged}
				onSortChanged={SummaryReportStore.onGridChanged}
				enableRangeSelection={true}
				// rowHeight={35}
				getRowHeight={params => ['Opening Balance', 'Closing Credebt Balance'].includes(params.node.data.account_number) ? 35 : 30}
				processCellForClipboard={processCellForClipboard}
				getContextMenuItems={getContextMenuItems}
			>
				<AgGridColumn headerName='Bank Account' field='account_number' pinned='left' width={'200px'} resizable={true} suppressMenu={true} className='' />

				{newObj.map((item) => {
					return <AgGridColumn field={item} type='rightAligned' width={'100px'} resizable={true} suppressMenu={true}
						cellRendererFramework={(params) => {
							let isNumber = !['Interbank #', 'Recognised #', 'Identify Data', 'Verify Data', 'External Payments #', 'External Payments Recognised #', 'External Receipts Recognised #', 'Internal Unrecognised #', 'External Payments Unrecognised #', 'External Receipts Unrecognised #', 'Classification Recognised', 'Verify Data For Classification', 'Total', 'External Receipts #', 'Charges #', 'Interests #', 'Errors #', 'Recognised Data #', 'Unrecognised #'].includes(params.data.account_number) ? true : false
							if (params.data.account_number === "Opening Balance" || params.data.account_number === 'Closing Credebt Balance') {
								return (
									<div style={isNumber ? styles.number_cells : styles.count_cells}>
										<span style={styles.date_cell}>{params.value.date}</span>
										{isNumber ? <NumberFormat value={params.value.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /> : <NumberFormat value={params.value.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} />}
									</div>
								)
								//return params.value
							} else {
								return (
									<div style={isNumber ? styles.number_cells : styles.count_cells}>
										{isNumber ? <NumberFormat value={params.value ? params.value : '0.00'} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /> : <NumberFormat value={params.value ? params.value : 0} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} />}
									</div>
								)
							}
						}} />
				})}
				<AgGridColumn field='total' pinned='right' type='rightAligned' width={'120px'}
					suppressMenu={true}
					cellRendererFramework={(params) => {
						let isNumber = !['Interbank #', 'Recognised #', 'Identify Data', 'Verify Data', 'External Payments Recognised #', 'External Payments #', 'External Receipts Recognised #', 'Internal Unrecognised #', 'External Payments Unrecognised #', 'External Receipts Unrecognised #', 'Classification Recognised', 'Verify Data For Classification', 'Total', 'External Receipts #', 'Charges #', 'Interests #', 'Errors #', 'Recognised Data #', 'Unrecognised #'].includes(params.data.account_number) ? true : false
						return (
							<div style={isNumber ? styles.number_cells : styles.count_cells}>
								{isNumber ? <NumberFormat value={params.value ? params.value : '0.00'} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /> : <NumberFormat value={params.value ? params.value : 0} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} />}
							</div>
						)
					}}
					resizable={true} />
			</AgGridReact>
		</div>
	);
})

export default PreviewSummaryDetailsComponent;