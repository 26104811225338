import React, { useEffect, useState } from 'react'
import { Alert, Card, Checkbox, Form, Spin, Tooltip, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmClassifications } from '../../../../config/messages'
import RepeaterElement from '../elements/RepeaterElement'
import useStore from '../../../../store'

const FormComponent = observer(({ visible, commonError, onChange, form }) => {
	const [fetchRole, setFetchRole] = useState(true)
	const [fetchType, setFetchType] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchAccounts, setFetchAccounts] = useState(true)
	const { ClassificationStore, OrgBankAccountStore, OrganisationStore } = useStore()

	// set the form values to edit
	useEffect(() => {
		if (visible) {
			setFetchRole(true)
			setFetchType(true)
			setFetchCurrency(true)
			setFetchAccounts(true)
			form.setFieldsValue({ customer_reference: [null], bank_reference: [null], transaction_details: [null] })
		}
	}, [form, visible])

	// bank accounts dropdown depends on currency & banks
	const handleBankAccount = () => {
		setFetchAccounts(true)
		const payload = {
			"bank_ids": form.getFieldValue('banks'),
			"currency_ids": form.getFieldValue('currency_id')
		}
		ClassificationStore.getBankAccounts(payload).then(() => setFetchAccounts(false))
		form.resetFields(['bank_account_id'])
	}

	return (
		<>
			<Row gutter={24}>
				<Col span={12}>
					{/* Class Name */}
					<InputComponent
						required label="Class Name"
						name="transaction_class_name" placeholder="Class Name"
						tooltip="Classification Class Name are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						rules={vsmClassifications.validation.class_name}
						autoComplete="off"
						maxLength={100}
					/>
				</Col>
				<Col span={12}>
					{/* Code */}
					<InputComponent
						required label="Code"
						name="code" placeholder="Code"
						tooltip="Code (of Cash Transaction Classification) is a 3-letter code indicating the type of cash transaction. The parameter is available in Transaction Details (one of the basic parameters of cash transaction). e.g. IFI - Investor RPA Receipt, TRF - transfer, CHK - cheque."
						rules={vsmClassifications.validation.code}
						autoComplete="off"
						maxLength={10}
					/>
				</Col>
			</Row>

			{/* Description */}
			<InputComponent
				type="textarea" required label="Description"
				name="description" placeholder="Description"
				tooltip="Classification Description are used to describe Cash Transaction Classification class name in more details."
				rules={vsmClassifications.validation.description}
				autoComplete="off"
				maxLength={1000}
			/>

			{/* Classification Rules */}
			<Card title={
				<span>Classification Rules
					<small className="ml-5">
						<Tooltip title="Classification Rules are set of instructions set for cash transactions which will help system to identify different classification while importing new cash transaction. Based on these rules, classification will be done. If no classification found for particular transaction, It will be counted as unidentified transaction. If you do not select any rule then all cash transactions will fall into it and you may need to select correct classification manually if system identified multiple classifications.  Multiple selection in dropdown will be considered as OR, Each row will considered as AND. All of following rules if selected then must match to get identified correctly. Checkboxes are optional identification rules and if it is not matched, transaction will go in Partial Classified Transaction.">
							<InfoCircleOutlined />
						</Tooltip>
					</small>
				</span>
			}>

				{commonError && <Alert style={{ marginBottom: 15 }} message={commonError} type="error" showIcon />}
				<Row gutter={24}>
					<Col span={12}>
						{/* Transaction Type/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Transaction Type/s are" name="cte_transactions_type_id"
							placeholder="Select Transaction Type/s"
							onChange={onChange}
							onFocus={() => fetchType && ClassificationStore.getCteTypes().then(() => setFetchType(false))}
							notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: ClassificationStore.cteTypesList,
								value_key: 'id',
								text_key: 'type_name',
							}}
						/>
					</Col>
					<Col span={12}>
						{/* Currency/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Currency/s are" name="currency_id"
							placeholder="Select Currency/s"
							onChange={() => { handleBankAccount(); onChange() }}
							onFocus={() => fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false))}
							notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrgBankAccountStore.dropdown_currency_list,
								value_key: 'id',
								text_key: 'currency_code',
							}}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						{/* Bank/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Bank/s are" name="banks"
							placeholder="Select Bank/s"
							onChange={() => { handleBankAccount(); onChange() }}
							onFocus={() => fetchAccounts && ClassificationStore.getBankAccounts().then(() => setFetchAccounts(false))}
							notFoundContent={fetchAccounts ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: ClassificationStore.bankAccountsList && ClassificationStore.bankAccountsList.banks,
								value_key: 'id',
								text_key: 'name',
							}}
						/>
					</Col>
					<Col span={12}>
						{/* Bank Account/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Bank Account/s are" name="bank_account_id"
							placeholder="Select Bank Account/s"
							onChange={onChange}
							onFocus={() => fetchAccounts && ClassificationStore.getBankAccounts({
								"bank_ids": form.getFieldValue('banks'),
								"currency_ids": form.getFieldValue('currency_id')
							}).then(() => setFetchAccounts(false))}
							notFoundContent={fetchAccounts ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: ClassificationStore.bankAccountsList && ClassificationStore.bankAccountsList.banks_accounts,
								value_key: 'id',
								text_key: 'bankAccount_names',
							}}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						{/* Entity Type/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Entity Type/s are" name="entity_type"
							placeholder="Select Entity Type/s"
							options={{
								values: [{ value: 1, text: 'Organisation' }, { value: 2, text: 'People' }]
							}}
							onChange={onChange}
						/>
					</Col>
					<Col span={12}>
						{/* Related Entity Type/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Related Entity Type/s are" name="related_entity_type"
							placeholder="Select Related Entity Type/s"
							options={{
								values: [{ value: 1, text: 'Organisation' }, { value: 2, text: 'People' }]
							}}
							onChange={onChange}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						{/* Entity Role/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Entity Role/s are" name="role_id"
							placeholder="Select Entity Role/s"
							onChange={onChange}
							onFocus={() => fetchRole && OrganisationStore.getOrgRoles().then(() => setFetchRole(false))}
							notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrganisationStore.dropdown_orgRole_list,
								value_key: 'id',
								text_key: 'role_name',
							}}
						/>
					</Col>
					<Col span={12}>
						{/* Related Entity Role/s */}
						<InputComponent
							type="select" mode="multiple"
							label="If Related Entity Role/s are" name="related_role_id"
							placeholder="Select Related Entity Role/s"
							onChange={onChange}
							onFocus={() => fetchRole && OrganisationStore.getOrgRoles().then(() => setFetchRole(false))}
							notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrganisationStore.dropdown_orgRole_list,
								value_key: 'id',
								text_key: 'role_name',
							}}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						{/* Customer Reference */}
						<RepeaterElement
							label="If Customer Reference has"
							name="customer_reference"
							placeholder="String to match"
							rules={vsmClassifications.validation.customer_reference}
						/>
					</Col>
					<Col span={12}>
						{/* Bank Reference */}
						<RepeaterElement
							label="If Bank Reference has"
							name="bank_reference"
							placeholder="String to match"
							rules={vsmClassifications.validation.bank_reference}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						{/*Transaction Details */}
						<RepeaterElement
							label="If Transaction Details has"
							name="transaction_details"
							placeholder="String to match"
							rules={vsmClassifications.validation.transaction_details}
						/>
					</Col>
					{/* <Col span={12}></Col> */}
				</Row>

				{/* Transaction */}
				<InputComponent
					type="radio_button"
					label="If Transaction is" name="transaction_is"
					initialValue="Any"
					options={{
						values: [
							{ value: 'Any', text: 'Any' },
							{ value: 'Inbound', text: 'Inbound' },
							{ value: 'Outbound', text: 'Outbound' },
						]
					}}
				/>

				<Card title="Optional Rules (If not matched will be considered as Partial Match) :">
					<Form.Item>
						<Form.Item name="is_checked" noStyle>
							<Checkbox.Group options={ClassificationStore.checkboxList} />
						</Form.Item>
					</Form.Item>
				</Card>
			</Card>
		</>
	)
})

export default FormComponent
