import React from 'react'
import { Form, Modal } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmOrgStatus } from '../../../../config/messages'

const FormComponent = observer(({ title, visible, close, formButtons, form, form_id, setDisabled, handleSubmit }) => {

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	return (
		<Modal
			centered
			title={title}
			visible={visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={formButtons}
		>
			<Form form={form}
				id={form_id}
				layout='vertical'
				onChange={handleChange}
				onFinish={handleSubmit}
			>
				<InputComponent
					required label="Status Name" name="status_name" placeholder="Status Name"
					tooltip="Status name would define whether that particular status is active or inactive."
					rules={vsmOrgStatus.validation.status_name}
					maxLength={100}
					autoComplete="off"
				/>

				<InputComponent
					required label="Code" name="status_code" placeholder="Code"
					tooltip="The code is the text which could be used instead of the status name."
					rules={vsmOrgStatus.validation.status_code}
					maxLength={100}
					autoComplete="off"
				/>
			</Form>
		</Modal>
	)
})

export default FormComponent
