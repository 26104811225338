import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import GridConfig from '../../../../config/GridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { CurrencyFormat } from '../../../../utils/GlobalFunction'
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const ListComponent = observer((props) => {
	const { openVerifyDrawer, openViewModal, openEditModal, openDeleteModal, openFilterModal } = props
	const { UserStore, CashTransactionStore } = useStore()

	const ActionRenderer = (prop) => {
		return prop.data && (
			<div className="action-column">
				{UserStore.checkPermission(76, 'edit') && ![1, 2, 4, 5].includes(prop.data.status) ? (
					<Button title="Verifying Mode" type="text" disabled><FontAwesomeIcon className={![4, 5].includes(prop.data.status) ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
				) : (
					UserStore.checkPermission(76, 'edit') && (<Button title="Verifying Mode" type="text" onClick={() => { openVerifyDrawer(prop.data) }}><FontAwesomeIcon className={![4, 5].includes(prop.data.status) ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>)
				)}
				{UserStore.checkPermission(76, 'view') && (<Button title="View Cash Transaction" type="text" onClick={() => { openViewModal(prop.data) }}><EyeOutlined /></Button>)}
				{UserStore.checkPermission(76, 'edit') && (<Button title="Edit Cash Transaction" type="text" onClick={() => { openEditModal(prop.data) }}><EditOutlined /></Button>)}
				{UserStore.checkPermission(76, 'delete') && (<Button title="Delete Cash Transaction" type="text" onClick={() => { openDeleteModal(prop.data) }}><DeleteOutlined /></Button>)}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			checkboxSelection: true,
			filter: false,
			sortable: false,
			floatingFilter: false,
			width: 40,
			suppressMenu: true,
			pinned: 'left'
		}, {
			headerName: '# ID',
			headerTooltip: '# ID',
			tooltipField: 'id',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'Bank Account',
			headerTooltip: 'Bank Account',
			field: 'ledgers_bank.account_number',
			tooltipValueGetter: (params) => (params.data && params.data.ledgers_bank) ? params.data.ledgers_bank.account_number ? params.data.ledgers_bank.account_number : params.data.ledgers_bank.iban : '',
			valueGetter: (params) => (params.data && params.data.ledgers_bank) ? params.data.ledgers_bank.account_number ? params.data.ledgers_bank.account_number : params.data.ledgers_bank.iban : '',
			width: 90,
		}, {
			headerName: 'Date',
			headerTooltip: 'Date',
			field: 'posted_on',
			tooltipField: 'posted_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 90
		}, {
			headerName: 'Transaction Details',
			headerTooltip: 'Transaction Details',
			field: 'transaction_details',
			tooltipField: 'transaction_details',
			width: 175
		}, {
			headerName: 'Transaction Type',
			headerTooltip: 'Transaction Type',
			field: 'cash_transactions_types.type_name',
			tooltipField: 'cash_transactions_types.type_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getTransactionTypesFilter().then((data) => {
						params.success([...data.data.data.map(x => x.type_name)])
					})
				}
			},
			width: 90
		}, {
			headerName: 'Debit (+)',
			headerTooltip: 'Debit (+)',
			field: 'debit',
			type: 'rightAligned',
			filter: "agNumberColumnFilter",
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
			},
			tooltipValueGetter: function (data) {
				if (data.data && data.data.debit) {
					return data.data.debit
				} else {
					return '0.00'
				}
			},
			width: 91
		}, {
			headerName: 'Credit (-)',
			headerTooltip: 'Credit (-)',
			field: 'credit',
			type: 'rightAligned',
			filter: "agNumberColumnFilter",
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
			},
			tooltipValueGetter: function (data) {
				if (data.data && data.data.credit) {
					return data.data.credit
				} else {
					return '0.00'
				}
			},
			width: 91
		}, {
			headerName: 'Ledger Balance',
			headerTooltip: 'Ledger Balance',
			field: 'balance',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			tooltipValueGetter: function (data) {
				if (data.data && data.data.balance) {
					return data.data.balance
				} else {
					return '0.00'
				}
			},
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.balance ? data.data.balance : 0} />
			},
			width: 99
		}, {
			headerName: 'Cleared Status',
			field: 'cleared_status',
			headerTooltip: 'Cleared Status',
			tooltipValueGetter: (params) => params.data && params.data.transaction_cleared_status_value && params.data.transaction_cleared_status_value.cleared_status ? params.data.transaction_cleared_status_value.field_value : null,
			valueGetter: (params) => params.data && params.data.transaction_cleared_status_value && params.data.transaction_cleared_status_value.cleared_status ? params.data.transaction_cleared_status_value.field_value : null,
			filter: false,
			sortable: false,
			width: 100
		}, {
			headerName: 'Cheque Number',
			field: 'cheque_number',
			headerTooltip: 'Cheque Number',
			tooltipValueGetter: function (params) {
				if (params.data && params.data.transaction_cheque_number_value && params.data.transaction_cheque_number_value.cheque_number) {
					return params.data.transaction_cheque_number_value.field_value
				} else {
					return null
				}
			},
			filter: false,
			sortable: false,
			valueGetter: (params) => params.data && params.data.transaction_cheque_number_value && params.data.transaction_cheque_number_value.cheque_number ? params.data.transaction_cheque_number_value.field_value : null,
			width: 100
		}, {
			headerName: 'Value Date',
			headerTooltip: 'Value Date',
			field: 'value_on',
			tooltipField: 'value_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 90
		}, {
			headerName: 'Customer Reference',
			headerTooltip: 'Customer Reference',
			field: 'customer_reference',
			tooltipField: 'customer_reference',
			width: 127
		}, {
			headerName: 'Bank Reference',
			headerTooltip: 'Bank Reference',
			field: 'bank_reference',
			tooltipField: 'bank_reference',
			width: 110
		}, {
			headerName: 'Reconciliation Account Number',
			headerTooltip: 'Reconciliation Account Number',
			field: 'reconcile_cross_bank_account_number.account_number',
			tooltipField: 'reconcile_cross_bank_account_number.account_number',
			width: 80,
			hide: true
		}, {
			headerName: 'Reconciliation CTE ID',
			headerTooltip: 'Reconciliation CTE ID',
			field: 'reconciliation_cte_id',
			tooltipField: 'reconciliation_cte_id',
			width: 70,
			hide: true
		}, {
			headerName: 'Identified Category',
			headerTooltip: 'Identified Category',
			field: 'cte_transaction_classifications.transaction_class_name',
			filter: 'agSetColumnFilter',
			tooltipField: 'cte_transaction_classifications.transaction_class_name',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getCatagoryFilter().then((data) => {
						params.success([null, ...data.data.data.map(x => x.transaction_class_name)])
					})
				}
			},
			hide: true,
			width: 100
		}, {
			headerName: 'Organisation / People Name',
			headerTooltip: 'Organisation / People Name',
			field: 'entity_name',
			tooltipValueGetter: function (params) {
				if (params.data && params.data.entity_type && parseInt(params.data.entity_type) === 2) {
					return params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))
				} else {
					return params.data.organisations && params.data.organisations.organisation_name
				}
			},
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))) : (params.data.organisations && params.data.organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getOrgPplFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			hide: true,
			width: 180
		}, {
			headerName: 'Role',
			headerTooltip: 'Role',
			field: 'role_name',
			tooltipValueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getRoleFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			hide: true,
			width: 120
		}, {
			headerName: 'Related Organisation / People Name',
			headerTooltip: 'Related Organisation / People Name',
			field: 'related_entity_name',
			tooltipValueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getOrgPplFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			hide: true,
			width: 180
		}, {
			headerName: 'Related Role',
			field: 'related_role_name',
			headerTooltip: 'Related Role',
			tooltipValueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getRoleFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			hide: true,
			width: 120
		}, {
			headerName: 'Status',
			headerTooltip: 'Status',
			field: 'reconciliation_status_table',
			filter: 'agSetColumnFilter',
			tooltipField: 'reconciliation_status_table',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				// values: ['Pending(Identified)', 'Partially Reconciled', 'Fully Reconciled']
				values: ['Pending', 'Internal / Identified ', 'Internal / Reconciled', 'Payment / Identified', 'Payment / Recognised', 'Receipts  / Identified', 'Receipts / Recognised', 'Pending', 'Pending', 'Charges', 'Interests']
			},
			width: 144
		},
		/*{
			headerName: 'Bank',
			headerTooltip: 'Bank',
			field: 'ledgers_bank.name',
			filter: 'agSetColumnFilter',
			tooltipField: 'ledgers_bank.name',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getBankFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.name)]);
					})
				}
			},
			hide: true,
			width: 100
		},{
			headerName: 'Currency',
			headerTooltip: 'Currency',
			field: 'global_currency.currency_code',
			filter: 'agSetColumnFilter',
			tooltipValueGetter: function (params) {
				if (params.data && params.data.global_currency) {

					return params.data.global_currency.currency_code
				} else {
					return ''
				}
			},
			valueGetter: (params) => (params.data && params.data.global_currency) ? params.data.global_currency.currency_code : "",
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getCurrencyFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.currency_code)])
					})
				}
			},
			hide: true,
			width: 75
		},  {
			headerName: 'Exchange ID',
			field: 'exchange_ref_id',
			headerTooltip: 'Exchange ID',
			tooltipField: 'exchange_ref_id',
			hide: true,
			width: 100
		},   {
			headerName: 'Status',
			headerTooltip: 'Status',
			field: 'status_name',
			tooltipField: 'status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Pending', 'Imported / added', 'Auto Identified', 'Manually Verified', 'Problematic / Unidentified', 'Problematic / Identified', 'Internal / Identified']
			},
			hide: true,
			width: 144
		},  */{
			headerName: 'Actions',
			headerTooltip: "Actions",
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 150
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('cte_transaction_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper" /* style={{ paddingBottom: '42px' }} */>
			{
				CashTransactionStore.advanceFilter ? (
					<>
						<div></div>
						<AgGridReact
							rowData={CashTransactionStore.list_data_local}
							modules={AllModules}
							columnDefs={columns}
							defaultColDef={LocalGridConfig.defaultColDef}
							columnTypes={LocalGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							frameworkComponents={{
								ActionRenderer, openDeleteModal, openEditModal, openViewModal, openFilterModal, openVerifyDrawer
							}}
							onGridReady={CashTransactionStore.setupGridLocal}
							gridOptions={LocalGridConfig.options}
							onColumnResized={CashTransactionStore.onGridChanged}
							onColumnMoved={CashTransactionStore.onGridChanged}
							onColumnPinned={CashTransactionStore.onGridChanged}
							onFilterChanged={CashTransactionStore.onFilterChanged}
							onSortChanged={CashTransactionStore.onFilterChanged}
							rowSelection={'multiple'}
							suppressRowClickSelection={true}
						/>
					</>
				) : (
					<AgGridReact
						modules={AllModules}
						columnDefs={columns}
						defaultColDef={GridConfig.defaultColDef}
						columnTypes={GridConfig.columnTypes}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						frameworkComponents={{
							ActionRenderer, openDeleteModal, openEditModal, openViewModal, openFilterModal, openVerifyDrawer
						}}
						onGridReady={CashTransactionStore.setupGrid}
						gridOptions={{ ...GridConfig.options, cacheBlockSize: CashTransactionStore.per_page }}
						onColumnResized={CashTransactionStore.onGridChanged}
						onColumnMoved={CashTransactionStore.onGridChanged}
						onColumnPinned={CashTransactionStore.onGridChanged}
						rowSelection={'multiple'}
						suppressRowClickSelection={true}
					/>
				)
			}
		</div>
	)
})

export default ListComponent
