import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import { DateComparator } from '../../../../utils/GlobalFunction'
import GridConfig from '../../../../config/GridConfig'
import useStore from '../../../../store'
import { Spin } from 'antd'
import ViewComponent from '../../../TradeTransaction/AllTransactions/component/ViewComponent'

const PreviewDetailTableElement = observer((props) => {
	const { SavedReportStore, SavedReportStore: { previewChildReportList } } = useStore()
	const [openViewDrawer, setViewOpenDrawer] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	let gridOptions = {
		columnDefs: [
			{ headerName: 'Transaction ID', field: 'transaction_id', filter: 'agNumberColumnFilter', width: 80, },
			{
				headerName: 'Organisation', field: 'entity_name', filter: 'agSetColumnFilter', filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						SavedReportStore.getOrgFilter('entity').then((data) => {
							params.success([null, ...data.data.map(x => x.entity_name)]);
						})
					}
				},
				width: 160,
			},
			{
				headerName: 'Related Organisation', field: 'related_entity_name', filter: 'agSetColumnFilter', filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						SavedReportStore.getOrgFilter('related_entity').then((data) => {
							params.success([null, ...data.data.map(x => x.related_entity_name)]);
						})
					}
				},
				width: 150,
			},
			{
				headerName: 'Face Value', field: 'face_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
				width: 80,
				cellRendererFramework: (params) => (params.data) ? (
					<NumberFormat value={params.data.face_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
				) : null
			},
			{
				headerName: 'Journal Log Date', field: 'journal_log_date',
				filter: 'agDateColumnFilter',
				width: 90,
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					comparator: DateComparator
				}
			},
		]
	}
	let Headers = [];

	if (previewChildReportList) {
		if (previewChildReportList[0]?.periods && previewChildReportList?.length > 0) {
			var period_columns = []
			previewChildReportList[0].periods.forEach((x, i) => {
				var temp = {
					headerName: x.period_name,
					children: [
						{
							headerName: 'Debit', field: 'periods.' + i + '.debit', type: 'rightAligned', filter: 'agNumberColumnFilter',
							width: 75,
							cellRendererFramework: (params) => (params.data && params.data.periods) ? (
								<NumberFormat value={params.data.periods[i].debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
							) : null
						},
						{
							headerName: 'Credit', field: 'periods.' + i + '.credit', type: 'rightAligned', filter: 'agNumberColumnFilter',
							width: 75,
							cellRendererFramework: (params) => (params.data && params.data.periods) ? (
								<NumberFormat value={params.data.periods[i].credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
							) : null
						},
						{
							headerName: 'Posting', field: 'periods.' + i + '.posting', type: 'rightAligned',
							width: 75,
							filter: false,
							cellRendererFramework: (params) => (params.data && params.data.periods) ? (
								<NumberFormat value={params.data.periods[i].posting} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
							) : null

						}
					]
				}
				period_columns.push(temp)
			})
			Headers = period_columns;
		}
		else {
			Headers = [
				{
					headerName: 'Debit', field: 'debit', type: 'rightAligned', filter: 'agNumberColumnFilter',
					width: 75,
					cellRendererFramework: (params) => (params.data) ? (
						<NumberFormat value={params.data.debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					) : null
				},
				{
					headerName: 'Credit', field: 'credit', type: 'rightAligned', filter: 'agNumberColumnFilter',
					width: 75,
					cellRendererFramework: (params) => (params.data) ? (
						<NumberFormat value={params.data.credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					) : null
				},
				{
					headerName: 'Posting', field: 'posting', type: 'rightAligned',
					width: 75,
					filter: false,
					cellRendererFramework: (params) => (params.data) ? (
						<NumberFormat value={params.data.posting} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					) : null
				}
			]
		}
	}

	const setId = (id) => setTransactionId(id)

	const cellOpenViewDrawer = (data) => {
		setId(data.data.transaction_id)
		setViewOpenDrawer(true)
	}

	const closeViewDrawer = () => {
		setViewOpenDrawer(false)
	}

	if (Headers) {
		SavedReportStore.previewDetailGrid = [...gridOptions.columnDefs, ...Headers]
		return (
			<div className="" style={{ height: 'calc(100vh - 113px)', marginBottom: 0, paddingBottom: 0 }}>
				<div className="ag-theme-alpine grid_wrapper">
					<AgGridReact
						modules={AllModules}
						serverSideInfiniteScroll={true}
						columnDefs={SavedReportStore.previewDetailGrid}
						defaultColDef={{ ...GridConfig.defaultColDef, sortable: false }}
						columnTypes={GridConfig.columnTypes}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						onGridReady={props.onGridReady}
						gridOptions={{ ...GridConfig.options, pagination: true, cacheBlockSize: SavedReportStore.previewDetailGrid_per_page }}
						pinnedBottomRowData={SavedReportStore.PreviewDetailPinnedRow}
						onColumnResized={SavedReportStore.onChildnGridChanged}
						onColumnMoved={SavedReportStore.onChildnGridChanged}
						onColumnPinned={SavedReportStore.onChildnGridChanged}
						onSortChanged={SavedReportStore.onChildnGridChanged}
						onCellDoubleClicked={cellOpenViewDrawer}
					/>
				</div>
				<ViewComponent transactionId={transactionId} setId={setId} visible={openViewDrawer} close={closeViewDrawer} />
			</div>
		)
	} else {
		return (
			<div className="d-flex justify-content-center">
				<Spin spinning={!Headers} />
			</div>
		)
	}
})

export default PreviewDetailTableElement
