import React, { useEffect } from 'react'
import { UI } from 'formulize'
import $ from 'jquery'
import { Droppable } from 'react-beautiful-dnd';

const ArithmeticComponent = (props) => {

	var random = Math.floor(1000000 + Math.random() * 9000000)
	const { formula, handleChange } = props

	if (!formula.formula_id) {
		formula.formula_id = "formula_" + formula.key + '_' + random
	}

	useEffect(() => {
		const getParsedArithmeticFormula = (formula) => {
			if (formula) {
				if (formula.operator) {
					return `(###${getParsedArithmeticFormula(formula.operand1)}###${formula.operator}###${getParsedArithmeticFormula(formula.operand2)}###)`
				} else if (formula.value) {
					if (formula.value.type === 'unit') {
						return formula.value.unit
					} else {
						if (formula.value.type === 'item') {
							return (`|||${formula.value.item.item}|||`)
						}
					}
				}
			}
			return null
		}

		const setBuilderValue = (formulize, arr_formula) => {
			arr_formula.map(item => {
				if (item.includes('|||')) {
					var value = item.split('|||')
					var $element = $(".draggable_" + value[1]).clone()
					$element.attr('style', '')
					formulize.insert($element[0])
				} else {
					formulize.insert(item)
				}
				return null
			})
		}
		var target = $("[data-formulize='" + formula.formula_id + "']")

		if (target.length > 0) {
			const formulize = new UI(target, {
				input: (value) => {
					if (JSON.stringify(value) !== JSON.stringify(formula.value)) {
						handleChange(value)
					}
					$(".formulize-bracket + .formulize-operator, .formulize-bracket + .formulize-cursor + .formulize-operator").each((index, item) => {
						if ($(item).text().trim() === '-') {
							if ($(item).prev().hasClass('formulize-bracket') && $(item).prev().text().trim() === '(') {
								$(`<div class="formulize-item formulize-unit zero_element"><span class="formulize-prefix formulize-decimal-highlight">0</span></div>`).insertBefore(item)
							} else {
								if ($(item).prev().hasClass('formulize-cursor')) {
									if ($(item).prev().prev().hasClass('formulize-bracket') && $(item).prev().prev().text().trim() === '(') {
										$(`<div class="formulize-item formulize-unit zero_element"><span class="formulize-prefix formulize-decimal-highlight">0</span></div>`).insertBefore(item)
									}
								}
							}
						}
						$(".formulize-bracket + .formulize-unit").each((index, item) => {
							if ($(item).find('span').text().trim() === "0") {
								if ($(item).next().hasClass('formulize-cursor')) {
									if ($(item).next().next().text().trim() !== '-') {
										$(item).remove()
									}
								} else {
									if ($(item).next().text().trim() !== '-') {
										$(item).remove()
									}
								}
							}
						})
					})
				}
			})
			$(".view_wrapper .formulize-cursor").remove()
			if (JSON.stringify(formulize.getData()) !== JSON.stringify(formula.value)) {
				var str_formula = getParsedArithmeticFormula(formula.value)
				if (str_formula) {
					var arr_formula = str_formula.split('###')
					setBuilderValue(formulize, arr_formula)
				}
			}
		}
	}, [formula, random, handleChange]);

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver ? 'lightgrey' : 'white'
	});

	return (
		<Droppable droppableId={formula.formula_id}>
			{(provided, snapshot) => {
				return (
					<div ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
						{...provided.droppableProps}
						data-formulize={formula.formula_id}
					></div>
				)
			}}
		</Droppable>
	)
}

export default ArithmeticComponent
