import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button, Result } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import useStore from '../../../store'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import ActivateComponent from './component/ActivateComponent'
import DeactivateComponent from './component/DeactivateComponent'
import EditComponent from './component/EditComponent'
import ContentLoader from '../../../components/ContentLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const BankAccounts = observer(() => {
	let history = useHistory()
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [activateModal, setActivateModal] = useState(false)
	const [deactivateModal, setDeactivateModal] = useState(false)
	const [loading, setLoading] = useState('pending')
	const [accountId, setAccountId] = useState(null)
	const { OrganisationStore, OrgBankAccountStore, OrgBankAccountStore: { per_page, agGrid, getList, setCurrentValues, setPageSize }, UserStore } = useStore()
	const { id } = useParams()

	// set id on edit drawer
	const setId = (id) => setAccountId(id)

	// Open form for add new bank account
	const openAddModal = () => {
		setAddModal(true)
	}

	// Close form for add new bank account
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing bank account and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		setEditModal(true)
	}

	// Open form for edit bank account
	const closeEditModal = () => setEditModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		setCurrentValues(data)
		agGrid.api.refreshCells({ force: true })
		if (!checked) {
			setDeactivateModal(true)
		} else {
			setActivateModal(true)
		}
	}

	// Close confirmation alert for activate bank account
	const closeActivateModal = () => setActivateModal(false)

	// Close confirmation alert for deactivate bank account
	const closeDeactivateModal = () => setDeactivateModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('org_bank_account_grid')
		OrgBankAccountStore.agGrid.api.setFilterModel(null)
		OrgBankAccountStore.agGrid.api.setSortModel(null)
		OrgBankAccountStore.agGrid.columnApi.resetColumnState()
		OrgBankAccountStore.agGrid.api.onFilterChanged(null)
	}

	useEffect(() => {
		if (loading === 'pending') {
			OrgBankAccountStore.list_data = null
			var payload = { organisation_id: id }
			OrganisationStore.getRecordDetail(payload).then(data => {
				setLoading(200)
			}).catch(data => {
				if (data.status === 404) {
					setLoading(404)
				}
				if (data.status === 403) {
					setLoading(403)
				}
			})
			getList(payload)
		}
	}, [getList, OrganisationStore, OrgBankAccountStore, id, loading])

	if (loading === 'pending') {
		return <ContentLoader />
	}
	if (loading === 200) {
		return (
			<>
				<BreadcrumbComponent items={[...BreadcrumbConfig.Organisations_BankAccounts.path, { name: `${OrganisationStore.organisationValues && OrganisationStore.organisationValues.organisation_name}` }, { name: 'Bank Accounts' }]} />
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Organisations_BankAccounts.title}
					extra={[
						UserStore.checkPermission(35, 'bank_accounts_add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} onSwitchChange={onSwitchChange} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} parent_id={id} accountId={accountId} setId={setId} />
					<ActivateComponent visible={activateModal} close={closeActivateModal} />
					<DeactivateComponent visible={deactivateModal} close={closeDeactivateModal} />
				</PageHeader>
			</>
		)
	} else {
		if (loading === 404) {
			return (
				<Result
					status="404"
					title="404"
					subTitle="Sorry, the page you visited does not exist."
					extra={<Button type="primary" onClick={() => {
						history.goBack()
					}}>Go Back</Button>}
				/>
			)
		}
		if (loading === 403) {
			return (
				<Result
					status="403"
					title="403"
					subTitle="Sorry, you are not authorized to access this page."
					extra={<Button type="primary" onClick={() => {
						history.goBack()
					}}>Go Back</Button>}
				/>
			)
		}

	}

})

export default BankAccounts
