import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

export default function ConvertComponent(props) {
    const [convert, ToggleConvert] = useState(true);

    const changeString = (str) => {
        return str.replace(/[\S]/g, '*');
    };

    return (
        <Row className='coverter-style'>
            <Col flex={1}>{convert ? changeString(props.values.secret) : props.values.secret}</Col>
            <Col>
                <Button
                    onClick={() => {
                        ToggleConvert(!convert);
                    }}
                >{!convert ? <EyeOutlined /> : <EyeInvisibleOutlined />}</Button>
            </Col>
        </Row>
    );
}
