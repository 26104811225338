import React, { useState } from 'react'
import { Form, Button, Modal, Input } from 'antd'
import { vsmNotify, vsmTransactionSubTypes } from '../../../../../../config/messages'
import useStore from '../../../../../../store'

const AddComponent = (props) => {
	const [form] = Form.useForm()
	const { TransactionSubTypeStore: { AddData } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionSubTypes.add
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return (
		<Modal
			centered
			title="New Transactions Type"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Form.Item name="sub_type_name" rules={vsmTransactionSubTypes.validation.sub_type_name} label="Transactions Type name" required tooltip='Transaction Type is used to further classify Transactions and allow for mapping of specific Transaction Models against specific Trade Types and Transaction Type.'>
					<Input placeholder="Transactions Type name" maxLength={100} />
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default AddComponent


