import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import FormComponent from "./FormComponent";
import useStore from '../../../../../store';
import { Button, Drawer, Form } from 'antd';
import { vsmNotify, vsmTrigger } from '../../../../../config/messages';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TriggerStore, TransactionFieldMappingStore, AllTransactionStore } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [formDataList, setFormDataList] = useState([])
	const [fetchMapping, setFetchMapping] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const defaultTime = '0 0 * * *'
	const [value, setValue] = useState(defaultTime)

	// Make function call to add new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = props.id
		if (formDataList.includes('time_schedule')) {
			data.time_schedule = value || defaultTime
		}
		data.selected_models = data.selected_models && data.selected_models.filter(x => x.model === true)
		TriggerStore.EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmTrigger.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setFetchMapping(true)
		TriggerStore.editValues = null
		form.setFieldsValue({ selected_models: [] })
		TransactionFieldMappingStore.dropdown_list = null
		AllTransactionStore.status_dropdown = null
		props.close()
	}

	useEffect(() => {
		if (props.visible) {
			TriggerStore.ReadData(props.id).then(async (data) => {
				var temp = defaultTime
				data.when_to_execute && data.when_to_execute.forEach((x, index) => {
					if (typeof (x) === 'object') {
						temp = x
						data.when_to_execute.splice(index, 1)
					}
				})
				await setValue(temp.time_schedule)
				await setDisabled(true)
				AllTransactionStore.status_dropdown = null
				form.setFieldsValue({
					trigger_name: data.trigger_name,
					what_to_execute: data.what_to_execute,
					mapping: data.type_field_mapping_id,
					transaction_status: data.tte_status_id,
					selected_models: data.selected_models,
					when_to_execute: data.when_to_execute,
				})
				if (data.type_field_mapping_id || data.tte_status_id || data.when_to_execute.includes('Timely Schedule')) {
					TriggerStore.getModalList(data.type_field_mapping_id || null).then((list) => {
						TriggerStore.currentModals = list
						list && list.forEach(x => {
							var temp = data.selected_models && data.selected_models.find(y => x.id === y.id)
							x.date = temp ? temp.date : 'current date'
							if (temp) {
								x.model = true
							}
						})
						form.setFieldsValue({ selected_models: list })
					})
				}
				setFormDataList(TriggerStore.GetFormDataList(form))
			})
		}
	}, [props.visible, TriggerStore, form, props.id, setValue, AllTransactionStore])

	return TriggerStore.editValues && (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit Trigger #${TriggerStore.editValues.id} ${TriggerStore.editValues.trigger_name}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled || TriggerStore.loader} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				visible={props.visible}
				handleSubmit={handleSubmit}
				formDataList={formDataList}
				setFetchMapping={setFetchMapping}
				fetchMapping={fetchMapping}
				fetchStatus={fetchStatus}
				setFetchStatus={setFetchStatus}
				setFormDataList={setFormDataList}
				setDisabled={setDisabled}
				setValue={setValue}
				value={value}
			/>
		</Drawer>
	)
})

export default EditComponent
