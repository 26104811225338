import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class LedgerBankAccountStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	currentValues = null
	mapBankValues = null
	agGrid = null
	currencyList = null
	bankAccountType = null
	bankNameList = null
	regexList = null
	fieldList = null
	dynamicFieldList = null
	bankAccountsList = null

	// initial value for form
	initialValues = {
		transaction_details: null,
		credit: null,
		debit: null,
		balance: null,
		posted_on: null,
		value_on: null,
		exchange_ref_id: null,
		customer_reference: null,
		bank_reference: null,
		regular_expression: [null],
		dynamic_field: [null]
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setCurrentValues = (data) => {
		this.currentValues = data
	}

	// set form values to map bank
	setMapBankValues = (data, get_data = true) => {
		data.get_data = get_data
		this.mapBankValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('ledger_bank_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('ledger_bank_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call for bank name list for auto populate
	getBanksNameList = () => {
		return Axios.get(`global/banks/get`).then(({ data }) => {
			var i;
			var arrayObj = data.data
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['name'];
				delete arrayObj[i].key1;
			}
			this.bankNameList = arrayObj
			return data
		}).catch((data) => {
			this.bankNameList = null
			return Promise.reject(data)
		})
	}

	// call for clone bank accounts dropdown list
	getBankAccountsList = () => {
		return Axios.get(`bankaccount/bankaccountlist/${this.mapBankValues.id}`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					if (item.account_number) {
						item.bankAccount_names = item.banks.name + " - " + item.account_number
					} else {
						item.bankAccount_names = item.banks.name + " - " + item.iban
					}
				});
			}
			this.bankAccountsList = data.data
			return data
		}).catch((data) => {
			this.bankAccountsList = null
			return Promise.reject(data)
		})
	}

	// call for regex dropdown list
	getRegexList = () => {
		return Axios.get(`bankaccount/regexlist`).then(({ data }) => {
			this.regexList = data.data
			return data
		}).catch((data) => {
			this.regexList = null
			return Promise.reject(data)
		})
	}

	// call for field dropdown list
	getFieldList = () => {
		return Axios.get(`bankaccount/fieldslist`).then(({ data }) => {
			this.fieldList = data.data
			return data
		}).catch((data) => {
			this.fieldList = data.data
			return Promise.reject(data)
		})
	}

	// call for currency list
	getCurrencyList = () => {
		return Axios.get(`globalcurreny/list`).then(({ data }) => {
			this.currencyList = data.data
			return data
		}).catch((data) => {
			this.currencyList = null
			return Promise.reject(data)
		})
	}

	// call for currency list
	getLedgerCurrencyList = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
			this.currencyList = data.data
			return data
		}).catch((data) => {
			this.currencyList = null
			return Promise.reject(data)
		})
	}

	// call for bank account types list
	getBankAccountTypeList = () => {
		return Axios.get(`bankaccount/bankAccountType`).then(({ data }) => {
			this.bankAccountType = data.data
			return data
		}).catch((data) => {
			this.bankAccountType = null
			return Promise.reject(data)
		})
	}

	// call to get record particular ID
	getRecordById = (formdata) => {
		return Axios.post(`bankaccount/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = (payload = { organisation_id: 1 }) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.api.getSortModel()
		}
		this.list_data = null
		return Axios.post(`bankaccount/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					item.published = item.status ? "Active" : "Deactive"
					item.bank_type = item.bank_account_type && item.bank_account_type.name ? item.bank_account_type.name : null
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('ledger_bank_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid) {
					this.agGrid.api.setFilterModel(filter)
					this.agGrid.api.setSortModel(sort)
				}
			}
		})
	}

	// call for dynamic field dropdown list
	getDynamicFieldList = () => {
		return Axios.get(`bankaccount/getctefields`).then(({ data }) => {
			this.dynamicFieldList = data.data
			return data
		}).catch((data) => {
			this.dynamicFieldList = null
			return Promise.reject(data)
		})
	}

	// call for dynamic field dropdown list
	AddDynamicField = (formdata) => {
		return Axios.post(`bankaccount/addctefield`, formdata).then(({ data }) => {
			this.getDynamicFieldList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call clone map bank api
	CloneMapBankData = (formdata) => {
		return Axios.post(`bankaccount/clonebankaccount/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call add map bank api
	AddMapBankData = (formdata) => {
		return Axios.post(`bankaccount/mapbankimport/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`bankaccount/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`bankaccount/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call activate / deactivate api
	TogggleData = (formdata, payload) => {
		return Axios.post(`bankaccount/status/${formdata.id}`, payload).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(LedgerBankAccountStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	currentValues: observable,
	agGrid: observable,
	bankAccountType: observable,
	currencyList: observable,
	bankNameList: observable,
	regexList: observable,
	fieldList: observable,
	mapBankValues: observable,
	initialValues: observable,
	bankAccountsList: observable,
	dynamicFieldList: observable,
	getList: action,
	AddData: action,
	EditData: action,
	AddMapBankData: action,
	setEditValues: action,
	setMapBankValues: action,
	setCurrentValues: action,
	DeleteData: action,
	setupGrid: action,
	onFilterChanged: action,
	setPageSize: action,
	getCurrencyList: action,
	TogggleData: action,
	getBankAccountTypeList: action,
	getBanksNameList: action,
	getRegexList: action,
	getFieldList: action,
	getDynamicFieldList: action,
	getBankAccountsList: action,
	AddDynamicField: action,
	CloneMapBankData: action,
	getRecordById: action,
	getLedgerCurrencyList: action,
	onGridChanged: action,
})
