import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import EditComponent from './component/EditComponent'
import ArchiveComponent from './component/ArchiveComponent'
import RestoreComponent from './component/RestoreComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import { vsmNotify } from '../../../../config/messages'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const Ledgers = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [archiveModal, setArchiveModal] = useState(false)
	const [restoreModal, setRestoreModal] = useState(false)
	const { UserStore, LedgerStore, LedgerStore: { per_page, setPageSize, setEditValues, setUnpublishValues, agGrid, TogglepublishData, getAllLedgerAdminList, archive, changeArchiveValue, setOrganisationValues, doProxyLogin } } = useStore()

	// Open form for add new ledger
	const openAddModal = () => {
		setAddModal(true)
		setOrganisationValues(null)
	}

	// Close form for add new ledger
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing ledger and set values to form
	const openEditModal = (data) => {
		getAllLedgerAdminList()
		setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit ledger
	const closeEditModal = () => setEditModal(false)

	// do Proxy Login
	const handleProxyLogin = (data) => {
		let formdata = {
			id: data.super_user_id
		}
		doProxyLogin(formdata).then(data => {
			UserStore.initiatAppOptions()
		})
	}

	// Open Archive Modal
	const openArchiveModal = (data) => {
		setEditValues(data)
		setArchiveModal(true)
	}

	// Close Archive Modal
	const closeArchiveModal = () => setArchiveModal(false)

	// Open confirmation alert before restoring existing legder field
	const openRestoreModal = (data) => {
		setEditValues(data)
		setRestoreModal(true)
	}

	// Close confirmation alert for restoring record
	const closeRestoreModal = () => setRestoreModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		if (!checked) {
			agGrid.api.refreshCells({ force: true });
			setUnpublishValues(data)
			handleUnpublish(data)
		} else {
			handlePublish(data)
		}
	}

	// Handle Publish and call function to enable record
	const handlePublish = (data) => {
		let formdata = {
			id: data.id,
			status: 1
		}
		TogglepublishData(formdata).then((data) => {
			vsmNotify.success({
				message: data.message
			})
		}).catch(e => {
			vsmNotify.error({
				message: e
			})
		})
	}

	// Handle Unpublish and call function to disable record
	const handleUnpublish = (data) => {
		let formdata = {
			id: data.id,
			status: 0
		}
		TogglepublishData(formdata).then((data) => {
			vsmNotify.success({
				message: data.message
			})
		}).catch(e => {
			vsmNotify.error({
				message: e
			})
		})
	}

	// display archive data
	const archiveLedgers = () => {
		changeArchiveValue(true)
	}

	// display unarchive data
	const unarchiveLedgers = () => {
		changeArchiveValue(false)
	}

	const handleReset = async () => {
		await localStorage.removeItem('ledger_grid')
		LedgerStore.agGrid.api.setFilterModel(null)
		LedgerStore.agGrid.api.setSortModel(null)
		LedgerStore.agGrid.columnApi.resetColumnState()
		LedgerStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.AllLedgers.path} />
			{(!UserStore.checkPermission(68, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.AllLedgers.title}
					extra={[
						(archive === false) ? (
							<Button key="1" onClick={archiveLedgers}>Show Archived Ledgers</Button>
						) : (
							<Button key="1" onClick={unarchiveLedgers}>Show Unarchived Ledgers</Button>
						),
						UserStore.checkPermission(68, 'add') && <Button key="2" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openArchiveModal={openArchiveModal} openRestoreModal={openRestoreModal} onSwitchChange={onSwitchChange} handleProxyLogin={handleProxyLogin} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<ArchiveComponent visible={archiveModal} close={closeArchiveModal} />
					<RestoreComponent visible={restoreModal} close={closeRestoreModal} />
				</PageHeader>
			)}
		</>
	)
})

export default Ledgers
