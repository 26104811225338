import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DateComparator } from '../../../../utils/GlobalFunction';
import NumberFormat from 'react-number-format';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon, vsmNotify } from '../../../../config/messages';
import useStore from '../../../../store';
import { Button, Drawer } from 'antd';
import PriviewChildAETRComponent from './PriviewChildAETRComponent';
import { TableOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

const PreviewAETRChildStatementComponent = observer((props) => {
    const { aETRReportsStatementStore, aETRReportsStore } = useStore()
    const [PriviewChildAETRSt, setPriviewChildAETRSt] = useState(false)
    const [type, setType] = useState('inStatement')

    const gridOptions = {
        columnDefs: [{
            headerName: '#',
            headerTooltip: 'SR#',
            field: '',
            filter: 'agNumberColumnFilter',
            width: 40,
            valueGetter: function (params) {
                if (params.data.date !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            },
            tooltipValueGetter: function (params) {
                if (params.data.date !== "Total") {
                    if (params.node.rowIndex < 9) {
                        return '0' + (params.node.rowIndex + 1)
                    } else {
                        return params.node.rowIndex + 1
                    }
                }
            }
        }, {
            headerName: 'Payment Date',
            headerTooltip: 'Payment Date',
            field: 'date',
            tooltipField: 'date',
            filter: 'agDateColumnFilter',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
            width: 90
        }, {
            headerName: 'Credit',
            headerTooltip: 'Credit',
            field: 'credit',
            tooltipField: 'credit',
            width: 100,
            cellClass: 'numberType',
            cellRendererFramework: function (params) {
                return (
                    <div style={styles.cells}>
                        <NumberFormat value={params.data.credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                    </div>
                )
            }
        }, {
            headerName: 'Debit',
            headerTooltip: 'Debit',
            field: 'debit',
            tooltipField: 'debit',
            width: 100,
            cellClass: 'numberType',
            cellRendererFramework: function (params) {
                return (
                    <div style={styles.cells}>
                        <NumberFormat value={params.data.debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                    </div >
                )
            }
        }, {
            headerName: 'Balance',
            headerTooltip: 'Balance',
            field: 'balance',
            tooltipField: 'balance',
            cellClass: 'numberType',
            cellRendererFramework: (params) => {
                return (
                    <div style={styles.cells}>
                        <NumberFormat value={params.data.balance} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                    </div>
                )
            },
            width: 100,
        }, {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'status',
            tooltipField: 'status',
            width: 80,
            cellRendererFramework: (params) => {
                return (
                    params.value.toUpperCase()
                )
            }
        }, {
            headerName: 'Income Value',
            headerTooltip: 'Income Value',
            field: 'income',
            tooltipField: 'income',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.income} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 100,
        }, {
            headerName: 'Principal',
            headerTooltip: 'Principal',
            field: 'principal_amt',
            tooltipField: 'principal_amt',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.principal_amt} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 95,
        }, {
            headerName: 'Profit',
            headerTooltip: 'Profit',
            field: 'profit',
            tooltipField: 'profit',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.profit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 94,
        }, {
            headerName: 'Total',
            field: 'total',
            headerTooltip: 'Total',
            tooltipField: 'total',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.total} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 105,
        }, {
            headerName: 'Outstanding',
            field: 'outstanding',
            headerTooltip: 'Outstanding',
            tooltipField: 'outstanding',
            cellClass: 'numberType',
            cellRendererFramework: (params) => (
                <div style={styles.cells}>
                    <NumberFormat value={params.data.outstanding} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                </div>
            ),
            width: 95,
        }]
    }

    const close = () => {
        aETRReportsStatementStore.setting_value = null
        props.close()
    }

    const onCellClicked = (data) => {
        let payload = {
            batches: data.data.used_batches ? data.data.used_batches : data.data.batch,
            tte_id: data.data.tte_id ? data.data.tte_id : null
        }
        aETRReportsStore.getOrganisationChildStatement(payload).then((data) => {
            setPriviewChildAETRSt(data)
        })
    }

    const PriviewChildAETRClose = () => {
        setPriviewChildAETRSt(false)
        setType('inStatement')
    }

    const styles = {
        cells: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '50%'
        }
    }

    let list_data = props.rowData.data;
    let pinnedBottomRowData = [];
    if (props.rowData.data && props?.rowData.data?.length > 0) {
        let pinData = props.rowData.data.filter(x => x.date === "Total");
        if (pinData.length > 0) {
            pinnedBottomRowData = [pinData[0]];
            list_data = props.rowData.data.filter(x => x.date !== "Total");
        }
    }

    const excelStyles = [{
        id: 'numberType',
        numberFormat: {
            format: '0.00',
        },
    }]

    const setColumnSetting = (params) => {
        let payload = {
            request_type: "id",
            id: aETRReportsStatementStore.setting_value.id,
            table_grid: (params === 'undo') ? null : JSON.stringify(aETRReportsStatementStore.agGrid.columnApi.getColumnState())
        }
        aETRReportsStatementStore.columnsConfig(payload).then(() => {
            if (params === 'undo') {
                localStorage.removeItem('aETR_statement_report_grid')
                aETRReportsStatementStore.agGrid.api.setFilterModel(null)
                aETRReportsStatementStore.agGrid.columnApi.resetColumnState()
                aETRReportsStatementStore.agGrid.api.onFilterChanged(null)
                vsmNotify.success({
                    message: "Grid reset successfully."
                })
            } else {
                vsmNotify.success({
                    message: "Grid saved successfully."
                })
            }
        })
    }


    let column = gridOptions.columnDefs
    let columns = []
    let columnConfig = aETRReportsStatementStore && aETRReportsStatementStore.setting_value && aETRReportsStatementStore.setting_value.data
    if (columnConfig) {
        let data = columnConfig
        let cols = column.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = column.find(x => x.field === element.colId)

            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = column
    }

    useEffect(() => {
        let payload = {
            "request_type": "type"
        }
        aETRReportsStatementStore.getSetting(103, payload)
    }, [aETRReportsStatementStore])

    return aETRReportsStatementStore.setting_value && (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'57%'}
            title={`${props.rowData.org_name} - ${props.rowData.batch_id}`}
            destroyOnClose={true}
            closable={false}
            extra={[
                <>
                    <Button key="2" title="Save Column Settings" type="primary" onClick={() => setColumnSetting('save')}><TableOutlined /></Button>
                    <Button key="3" title="Reset" type="primary" onClick={() => setColumnSetting('undo')} className="ml-10"><FontAwesomeIcon icon={faUndo} /></Button>
                </>
            ]}
            footer={[
                <div className="text-center" key="div">
                    <Button key="2" onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <div className="ag-theme-alpine grid_wrapper">
                <AgGridReact
                    rowData={list_data}
                    modules={AllModules}
                    columnDefs={columns}
                    defaultColDef={LocalGridConfig.defaultColDef}
                    columnTypes={LocalGridConfig.columnTypes}
                    overlayNoRowsTemplate={vsmCommon.noRecord}
                    onGridReady={aETRReportsStatementStore.setupGrid}
                    onColumnResized={aETRReportsStatementStore.onGridChanged}
                    onColumnMoved={aETRReportsStatementStore.onGridChanged}
                    gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                    onFilterChanged={aETRReportsStatementStore.onFilterChanged}
                    onSortChanged={aETRReportsStatementStore.onGridChanged}
                    rowSelection={'single'}
                    onCellDoubleClicked={onCellClicked}
                    pinnedBottomRowData={pinnedBottomRowData}
                    excelStyles={excelStyles}
                />
            </div>
            {PriviewChildAETRSt ? <PriviewChildAETRComponent visible={PriviewChildAETRSt} close={PriviewChildAETRClose} type={type} /> : null}
        </Drawer>
    );
})

export default PreviewAETRChildStatementComponent;