import { Form, Button, Modal, Row, Col, Upload, message, Radio } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmOrganisations, vsmNotify } from '../../../../config/messages'
import simpleImportOrganisations from '../../../../assets/import-simple-organisations.csv'
import advanceImportOrganisations from '../../../../assets/import-advance-organisations.csv'
import sampleImportOrganisationsRoles from '../../../../assets/import-sample-organisations-roles.csv'
import sampleImportOrgPeopleMapping from '../../../../assets/import-sample-Org-People Mapping.csv'
import Unauthorized from '../../../Unauthorized'
const { Dragger } = Upload

const ImportComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fileList, setFileList] = useState([])
	const [successModal, setSuccessModal] = useState(false)
	const [roleSuccessModal, setRoleSuccessModal] = useState(false)
	const [successCount, setSuccessCount] = useState()
	const [errorFileURL, setErrorFileURL] = useState()
	const { UserStore, OrganisationStore: { ImportOrgFile, ImportOrgRolesFile } } = useStore()
	const [importType, setImportType] = useState(
		UserStore.checkPermission(36, 'organistion_import') ? 1 : 2
	)

	// call function to import orgnaisations file
	const handleOrgSubmit = (data) => {
		setSaving(true)
		const formData = new FormData()
		formData.append('file', fileList[0])
		ImportOrgFile(formData).then((res) => {
			close(true)
			if (res) {
				setSuccessCount(res.success_count)
				setErrorFileURL(res.csv_link)
				setSuccessModal(true)
			}
			if (res.success_count !== 0) {
				vsmNotify.success({
					message: vsmOrganisations.import
				})
			}
		}).catch(e => {
			if (e.errors && e.errors.file && e.errors.file[0]) {
				message.error(e.errors.file[0])
			}

			if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
				message.error(e.errors[0].errors[0])
			}
		}).finally(() => setSaving(false))
	}

	// call function to import orgnaisation roles file
	const handleRoleSubmit = (data) => {
		setSaving(true)
		const formData = new FormData()
		formData.append('file', fileList[0])
		ImportOrgRolesFile(formData).then((res) => {
			close(true)
			if (res) {
				setSuccessCount(res.success_count)
				setErrorFileURL(res.csv_link)
				setRoleSuccessModal(true)
			}
			if (res.success_count !== 0) {
				vsmNotify.success({
					message: vsmOrganisations.importRole
				})
			}
		}).catch(e => {
			if (e.errors && e.errors.file && e.errors.file[0]) {
				message.error(e.errors.file[0])
			}

			if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
				message.error(e.errors[0].errors[0])
			}
		}).finally(() => setSaving(false))
	}

	// reset form and close import form
	const close = (close = false) => {
		if (!saving || close) {
			setImportType(1)
			props.close()
			form.resetFields()
			setFileList([])
			setDisabled(true)
			setSuccessCount()
			setErrorFileURL()
			setSaving(false)
		}
	}

	// call for file type and size
	const beforeUpload = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === ''
		let isLt20M = file.size / 1024 / 1024 < 20
		if (!isCSV) {
			message.error('You can only upload CSV file!')
			setDisabled(true)
			return true
		} else if (!isLt20M) {
			message.error('File must be smaller then 20MB')
			setDisabled(true)
			return true
		} else {
			setFileList([file])
			return false
		}
	}

	// handle file change to uploaded file
	const handleFileChange = info => {
		if (info.fileList) {
			if ((info.file.status && info.file.status === 'error') || info.fileList.length <= 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		} else {
			setDisabled(true)
		}
	}

	return (
		<>
			<Modal title={
				(importType === 1) ? ('Import Organisations') : ((importType === 2) ? 'Import Organisations Roles' : null)
			} destroyOnClose={true}
				centered
				visible={props.visible}
				onCancel={() => !saving && close()}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1"
						form={(importType === 1) ? ('importorgform') : ((importType === 2) ? ('importroleform') : null)}
						disabled={disabled}
						loading={saving}
						htmlType="submit"
						type="primary"
					>Import</Button>,
					<Button key="2" htmlType="button" disabled={saving} onClick={() => { close() }}>Cancel</Button>
				]}
				width={700}>
				{
					(UserStore.checkPermission(36, 'role_import') && UserStore.checkPermission(36, 'organistion_import')) && (
						<Radio.Group buttonStyle="solid" optionType="button" className="mb-20"
							value={importType}
							onChange={(e) => {
								setImportType(e.target.value)
							}}
						>
							<Radio.Button key="1" value={1}>Organisation</Radio.Button>
							<Radio.Button key="2" value={2}>Role Mapping</Radio.Button>
						</Radio.Group>
					)
				}
				{
					(!UserStore.checkPermission(36, 'role_import') && !UserStore.checkPermission(36, 'organistion_import')) ? (
						<Unauthorized />
					) : (
						(importType === 1) ? (
							<Form form={form} id="importorgform" onFinish={handleOrgSubmit} encType="multipart/form-data"
								acceptCharset="UTF-8">
								<Row gutter={24}>
									<Col span={12}>
										<Dragger
											accept=".csv"
											height={300}
											fileList={fileList}
											onRemove={() => setFileList([])}
											beforeUpload={beforeUpload}
											onChange={handleFileChange}
											name="file"
											showUploadList={true}
											multiple={false}
											action={process.env.React_APP_API_URL + 'organisation/checkvalidfile/'}
											headers={{
												Authorization: 'Bearer ' + localStorage.getItem('token')
											}}
										>
											<p className="ant-upload-drag-icon">
												<InboxOutlined />
											</p>
											<p className="ant-upload-text">Drag your file here or click to upload</p>
										</Dragger>
									</Col>
									<Col span={12}>
										<h3>Please make sure file match following criteria:</h3>
										<ul>
											<li>File format should be CSV.</li>
											<li>Maximum 20 MB file size allowed.</li>
											<li>Minimum 1 record and Maximum 20000 Records can be there.</li>
											<li>File should have first row for header with exact name.</li>
											<li>Column count should match with sample file.</li>
											<li>All data required in all column for successful entry, otherwise that row entry will be ignored.</li>
										</ul>
										<span>Download sample file here.</span>
										<div className="d-flex">
											<a href={simpleImportOrganisations} className="mr-5" download rel="noopener noreferrer">Simple Import</a>
											-
											<a href={advanceImportOrganisations} className="ml-5" download rel="noopener noreferrer">Advance Import</a>
										</div>
									</Col>
								</Row>
							</Form>
						) : ((importType === 2) ? (
							<Form form={form} id="importroleform" onFinish={handleRoleSubmit} encType="multipart/form-data"
								acceptCharset="UTF-8">
								<Row gutter={24}>
									<Col span={12}>
										<Col>
											<Dragger
												accept=".csv"
												height={300}
												fileList={fileList}
												onRemove={() => setFileList([])}
												beforeUpload={beforeUpload}
												onChange={handleFileChange}
												name="file"
												showUploadList={true}
												multiple={false}
												action={process.env.React_APP_API_URL + 'organisation/checkvalidfile/'}
												headers={{
													Authorization: 'Bearer ' + localStorage.getItem('token')
												}}
											>
												<p className="ant-upload-drag-icon">
													<InboxOutlined />
												</p>
												<p className="ant-upload-text">Drag your file here or click to upload</p>
											</Dragger>
										</Col>
									</Col>
									<Col span={12}>
										<h3>Please make sure file match following criteria:</h3>
										<ul>
											<li>File format should be CSV.</li>
											<li>Maximum 20 MB file size allowed.</li>
											<li>Minimum 1 record and Maximum 20000 Records can be there.</li>
											<li>File should have first row for header with exact name.</li>
											<li>Column count should match with sample file.</li>
											<li>Related Organisation/People and Related Role is optional based on Relate To value</li>
											<li>Bank Account Details are optional</li>
										</ul>
										<a href={sampleImportOrganisationsRoles} download rel="noopener noreferrer">Download sample file here.</a>
										<div><a href={sampleImportOrgPeopleMapping} download rel="noopener noreferrer">Download sample file for Org/People Mapping.</a></div>
									</Col>
								</Row>
							</Form>
						) : null)
					)
				}
			</Modal >

			{/* Modal for successfully created organisations records, after importing file */}
			<Modal title="Import Organisations"
				centered
				visible={successModal}
				onCancel={() => {
					close()
					setSuccessModal(false)
				}}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1" htmlType="button" onClick={() => {
						close()
						setSuccessModal(false)
					}}>Close</Button>
				]}
			>
				<h3 className="text-center">
					{successCount} Organisations Records Successfully Created !<br />

				</h3>
				{
					errorFileURL && (
						<h3 className="text-center">
							You can download same file with status note.<br />
							<a href={errorFileURL} rel="noopener noreferrer">Download file here</a>
						</h3>
					)
				}
			</Modal>

			{/* Modal for successfully created organisation roles records, after importing file */}
			<Modal title="Import Organisations Roles"
				centered
				visible={roleSuccessModal}
				onCancel={() => {
					close()
					setRoleSuccessModal(false)
				}}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1" htmlType="button" onClick={() => {
						close()
						setRoleSuccessModal(false)
					}}>Close</Button>
				]}
			>
				<h3 className="text-center">
					{successCount} Organisations Roles Successfully created !<br />
				</h3>
				{
					errorFileURL && (
						<h3 className="text-center">
							You can download same file with status note.<br />
							<a href={errorFileURL} rel="noopener noreferrer">Download file here</a>
						</h3>
					)
				}
			</Modal>
		</>
	)
})

export default ImportComponent
