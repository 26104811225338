import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import NumberFormat from 'react-number-format'
import { Button, Drawer, Spin } from 'antd'
import SubDaysComponent from './SubDaysComponent'

const DaysListComponent = observer((props) => {
	const { QueryBankVsCashStore, QueryBankVsCashStore: { daysSetupGrid, days_list_data, singleGetChildList, bankAccList } } = useStore()
	const [subDaysDrawer, setSubDaysDrawer] = useState(false)
	const [spin, setSpin] = useState(false)
	const [title, setTitle] = useState(null)

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		},
		red_bg: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%',
			backgroundColor: '#e52e2e',
			margin: '0 -15px',
			padding: '0 15px'
		}
	}

	const gridOptions = {
		columnDefs: [{
			headerName: 'Date',
			headerTooltip: 'Date',
			field: 'month',
			tooltipField: 'month',
			filter: false,
			width: 93
		}, {
			headerName: 'Closing Bank Balance',
			headerTooltip: 'Closing Bank Balance',
			field: 'bank_closing',
			tooltipField: 'bank_closing',
			filter: false,
			cellRendererFramework: function (params) {
				return (
					<div style={styles.cells}>
						<NumberFormat value={params.data.bank_closing ? params.data.bank_closing : 0.00 ? params.data.bank_closing : '0'} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				)
			},
			width: 133
		}, {
			headerName: 'Closing Credebt Balance',
			headerTooltip: 'Closing Credebt Balance',
			field: 'cash_closing',
			tooltipField: 'cash_closing',
			filter: false,
			cellRendererFramework: function (params) {
				return (
					<div style={(params.data.cash_closing ? Math.ceil(Number(params.data.cash_closing).toFixed(2)) : 0.00) !== (params.data.bank_closing ? Math.ceil(Number(params.data.bank_closing).toFixed(2)) : 0.00) ? styles.red_bg : styles.cells}>
						<NumberFormat value={params.data.cash_closing ? Math.ceil(Number(params.data.cash_closing).toFixed(2)) : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				)
			},
			width: 131
		}, {
			headerName: 'Difference',
			headerTooltip: 'Difference',
			field: '',
			tooltipValueGetter: function (params) {
				let cash_closing = params.data.cash_closing ? parseFloat(params.data.cash_closing).toFixed(2) : 0.00
				let bank_closing = params.data.bank_closing ? parseFloat(params.data.bank_closing).toFixed(2) : 0.00
				return (cash_closing - bank_closing).toFixed(0)

			},
			filter: false,
			cellRendererFramework: function (params) {
				let cash_closing = params.data.cash_closing ? parseFloat(params.data.cash_closing).toFixed(2) : 0.00
				let bank_closing = params.data.bank_closing ? parseFloat(params.data.bank_closing).toFixed(2) : 0.00
				return (
					<div>
						<NumberFormat value={cash_closing - bank_closing} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} />
					</div>
				)
			},
			width: 120
		}]
	}

	const closeSubDaysComponent = () => {
		setSubDaysDrawer(false)
		setSpin(true)
		if (props.type === 'main') {
			QueryBankVsCashStore.getList(null)
		} else {
			QueryBankVsCashStore.getChildList(null)
		}
		QueryBankVsCashStore.singleGetChildListPayload = null
		QueryBankVsCashStore.singleDaysListData = null
	}

	let columns = []
	let columnConfig = localStorage.getItem('days_query_bank_cash')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}
	const onCellClicked = (params) => {
		setTitle(`View ${bankAccList.find(bank => bank.id === props.bankID).name} - ${bankAccList.find(bank => bank.id === props.bankID).account_number} - ${params.data.month}`)
		setSubDaysDrawer(true)
		let payload = {
			report_type: "bank_vs_cash",
			bank_account_id: props.bankID,
			month: params.data.month,
			action: "transaction_detail_view",
		}
		singleGetChildList(payload)
	}

	useEffect(() => {
		if (days_list_data) {
			setSpin(false)
		}
	}, [days_list_data])

	return (
		<>
			{props.type !== 'main' ?
				<Drawer
					visible={props.visible}
					onClose={props.close}
					title={props.title && props.title}
					placement='right'
					width={'50%'}
					destroyOnClose={true}
					footer={[
						<div key="1" className="d-flex justify-content-center">
							<Button className="ml-10" onClick={props.close}>Close</Button>
						</div>
					]}
				>
					<Spin size="large" spinning={spin} tip={"Loading"}>
						<div className="ag-theme-alpine grid_wrapper no_data_height" style={{ height: days_list_data ? days_list_data.length * 30 + 35 : 70, minHeight: '65px', maxHeight: 'calc(100vh - 156px)' }}>
							<AgGridReact
								rowData={days_list_data}
								modules={AllModules}
								columnDefs={columns}
								defaultColDef={LocalGridConfig.defaultColDef}
								columnTypes={LocalGridConfig.columnTypes}
								overlayNoRowsTemplate={vsmCommon.noRecord}
								onGridReady={daysSetupGrid}
								gridOptions={LocalGridConfig.options}
								pagination={false}
								filter={false}
								floatingFiltersHeight={0}
								suppressRowClickSelection={true}
								onCellDoubleClicked={onCellClicked}
								pinnedBottomRowData={false}
							/>
						</div>
					</Spin>
				</Drawer>
				:
				<Spin size="large" spinning={spin} tip={"Loading"}>
					<div className="ag-theme-alpine grid_wrapper no_data_height" style={{ height: days_list_data ? days_list_data.length * 30 + 35 : 70, minHeight: '65px', maxHeight: 'calc(100vh - 100px)' }}>
						<AgGridReact
							rowData={days_list_data}
							modules={AllModules}
							columnDefs={columns}
							defaultColDef={LocalGridConfig.defaultColDef}
							columnTypes={LocalGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={daysSetupGrid}
							gridOptions={LocalGridConfig.options}
							pagination={false}
							filter={false}
							floatingFiltersHeight={0}
							suppressRowClickSelection={true}
							onCellDoubleClicked={onCellClicked}
							pinnedBottomRowData={false}
						/>
					</div>
				</Spin>
			}
			{subDaysDrawer && <SubDaysComponent visible={subDaysDrawer} close={closeSubDaysComponent} title={title} bankID={props.bankID} />}
		</>
	)
})

export default DaysListComponent;