import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../../components/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmLedgerMapBank } from '../../../../../config/messages';
import useStore from '../../../../../store';

const DynamicFieldElement = observer(({ onChange, handleAddField, form, add_field_form }) => {
	const { LedgerBankAccountStore: { dynamicFieldList } } = useStore()
	const [, setField] = useState()

	useEffect(() => {
		setField(form.getFieldValue('dynamic_field'))
	}, [form, setField])

	return (
		<div className="w-100">
			<Form.List name='dynamic_field'>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							return (
								<div className="w-100" key={field.key}>
									<Row gutter={24}>
										<Col span={8} className="ant-form-item-label">
											<InputComponent
												name={[field.name, 'id']} key={[field.key, 'id']}
												type="select" allowClear
												placeholder="Select or Add Field"
												onChange={(data) => {
													onChange()
													setField(form.getFieldValue('dynamic_field'))
												}}
												options={{
													values: dynamicFieldList,
													value_key: "id",
													text_key: "field_name",
													accepted_key: form.getFieldValue('dynamic_field')[index] && [form.getFieldValue('dynamic_field')[index].id],
													rejected_keys: form.getFieldValue('dynamic_field') && form.getFieldValue('dynamic_field').map((item) => item && item.id)
												}}
												autoComplete="off"
												dropdownRender={menu => (
													<div>
														{menu}
														<Form form={add_field_form} onFinish={handleAddField} id="addfieldform" className="add_item_dropdown_wrapper">
															<InputComponent
																name='field_name'
																placeholder="Add Field"
																rules={vsmLedgerMapBank.validation.with_required}
																maxLength={50}
																autoComplete="off"
																onChange={onChange}
															/>
															<Button htmlType="submit" className="ml-10" type="primary" form='addfieldform'>
																<PlusOutlined />
															</Button>
														</Form>
													</div>
												)}
											/>
										</Col>
										<Col flex={1}>
											<InputComponent
												name={[field.name, 'name']} key={[field.key, 'name']}
												placeholder="CSV Column Name"
												rules={(form.getFieldValue('dynamic_field')[index] && form.getFieldValue('dynamic_field')[index].id) ? vsmLedgerMapBank.validation.with_required : [{ required: false }]}
												maxLength={100}
												autoComplete="off"
												onChange={onChange}
											/>
										</Col>
										<Col>
											<div>
												{(fields.length > 1) && (
													<Button type="danger" onClick={() => { remove(field.name); onChange() }} icon={<MinusOutlined />}></Button>
												)}
												{(index === (fields.length - 1) && (fields.length < 12)) && (
													<Button className={index > 0 && "ml-10"} type="primary" onClick={() => { add(); onChange() }} icon={<PlusOutlined />}></Button>
												)}
											</div>
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default DynamicFieldElement
