import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form, Card } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { MenuOutlined } from '@ant-design/icons'
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { vsmOrganisations } from '../../../../config/messages';

const RatingElement = observer(({ onChange, form }) => {
	const { OrganisationStore: { rating_map_list } } = useStore()

	const onSortEnd = async ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove([].concat(form.getFieldValue('rating_table')), oldIndex, newIndex);
			await form.setFieldsValue({ rating_table: newData })
		}
	}

	const DragHandle = sortableHandle(() => (
		<MenuOutlined style={{ fontSize: '1.5em', cursor: 'pointer', color: '#999', marginTop: 5 }} />
	));

	const SortableContainer = sortableContainer(props => <div {...props} className="w-100" />);

	const handleRatingMap = (data, index) => {
		if (data) {
			form.setFields([
				{
					name: ["rating_table", index, "ratting_json"],
					value: rating_map_list.filter(x => x.id === data).map(x => ({ short: x.short_rating, long: x.long_rating }))
				}
			])
		}
	}

	const SortableItem = sortableElement(({ fields, add, remove, field, i }) => {
		var map = form.getFieldValue(["rating_table", i, "mapping_type"])
		const [mapping, setMapping] = useState(map ? map : 1)

		return (
			<div className={(i > 0) ? 'mt-20' : null} style={{ zIndex: 9999 }}>
				<Row gutter={10} style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
					<Col><DragHandle /></Col>
					<Col flex={1}>
						<Card>
							<InputComponent
								onChange={async (e) => { await handleRatingMap(e, i); await onChange() }} type="select"
								label="Select Rating[To Map]" required rules={vsmOrganisations.validation.rating}
								name={[field.name, 'global_exchange_rating_id']} key={[field.key, 'global_exchange_rating_id']}
								placeholder="Select rating to map from the general library"
								tooltip="Ratings to address a corporation’s financial instruments and the corporations itself."
								options={{
									values: rating_map_list,
									value_key: "id",
									text_key: "description",
								}}
							/>
							<InputComponent
								required type="radio_button" label="Mapping"
								name={[field.name, "mapping_type"]} key={[field.key, 'mapping_type']}
								initialValue={1}
								onChange={async (e) => { await setMapping(e.target.value); await handleRatingMap(form.getFieldValue(["rating_table", i, "global_exchange_rating_id"]), field.key); await onChange() }}
								options={{
									values: [
										{ value: 1, text: 'Short' },
										{ value: 2, text: 'Long' },
									]
								}}
							/>
							<Form.Item required label="Rating">
								<Form.List name={[field.name, 'ratting_json']}
									key={[field.key, 'ratting_json']} initialValue={[null]}>
									{
										(fields, { add, remove }) => {
											return (
												<div className="w-100">
													{
														fields.map((field, index) => {
															return (
																<div className="w-100" key={field.key}>
																	<Row gutter={10} style={{ flexFlow: 'row' }}>
																		{(mapping === 1) && (
																			<>
																				<Col span={10}>
																					{(index === 0) && (
																						<InputComponent
																							required
																							placeholder="Short Rating"
																							name={[field.name, 'short']}
																							key={[field.key, 'short']}
																							autoComplete="off"
																							onChange={onChange}
																							rules={vsmOrganisations.validation.short_rating}
																						/>
																					)}
																				</Col>
																				<Col flex={1}>
																					<InputComponent
																						placeholder="Long Rating"
																						name={[field.name, 'long']}
																						key={[field.key, 'long']}
																						autoComplete="off"
																						onChange={onChange}
																						rules={vsmOrganisations.validation.long_rating}
																					/>
																				</Col>
																			</>
																		)}
																		{(mapping === 2) && (
																			<>
																				<Col span={10}>
																					{(index === 0) && (
																						<InputComponent
																							placeholder="Long Rating"
																							name={[field.name, 'long']}
																							key={[field.key, 'long']}
																							autoComplete="off"
																							onChange={onChange}
																							rules={vsmOrganisations.validation.long_rating}
																						/>
																					)}
																				</Col>
																				<Col flex={1}>
																					<InputComponent
																						required
																						placeholder="Short Rating"
																						name={[field.name, 'short']}
																						key={[field.key, 'short']}
																						autoComplete="off"
																						onChange={onChange}
																						rules={vsmOrganisations.validation.short_rating}
																					/>
																				</Col>
																			</>
																		)}
																		<Col>
																			<div className="d-flex">
																				{
																					(fields.length > 1) && (
																						<Button type="danger" onClick={async () => {
																							await remove(field.name)
																							await onChange()
																						}} icon={<MinusOutlined />}></Button>
																					)
																				}
																				{(index === (fields.length - 1)) && (
																					<Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
																						await add()
																						await onChange()
																					}} icon={<PlusOutlined />} />
																				)}
																			</div>
																		</Col>
																	</Row>
																</div>
															)
														})
													}
												</div>
											)
										}
									}
								</Form.List>
							</Form.Item>
							<InputComponent
								label="Date Range" type="date_range"
								name={[field.name, "date_range"]} key={[field.key, 'date_range']}
								onChange={onChange}
							/>
						</Card>
					</Col>
					<Col>
						{(fields && fields.length > 1) && (
							<Button type="danger" onClick={async () => {
								await remove(field.name)
								onChange()
							}} icon={<MinusOutlined />}></Button>
						)}
						{(i === (fields.length - 1)) && (
							<Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
								await add({ id: null })
								onChange()
							}} icon={<PlusOutlined />}></Button>
						)}
					</Col>
				</Row>
			</div>
		)
	})

	return (
		<div className="w-100">
			<SortableContainer
				useDragHandle
				onSortEnd={onSortEnd}
			>
				<Form.List name="rating_table" initialValue={[{ id: null }]}>
					{
						(fields, { add, remove }) => {
							return fields && fields.map((field, index) => {
								return (
									<SortableItem fields={fields} add={add} i={index} remove={remove} field={field} key={field.key} index={index} />
								)
							})
						}
					}
				</Form.List>
			</SortableContainer>
		</div >
	)
})

export default RatingElement
