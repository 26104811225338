import React from 'react'
import { observer } from 'mobx-react';
import { Button, Drawer } from 'antd'
import useStore from '../../../../../store';

const ViewImportLogDetailsComponent = observer((props) => {
	const { CTEImportStore: { logValues, viewLogValues, setLogValues } } = useStore()

	// Reset form values and close view form
	const close = () => {
		props.close()
		setLogValues(null)
	}

	return logValues && viewLogValues && (
		<Drawer
			centered
			title={`View Detailed Log for Transaction #${viewLogValues.id}`}
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button htmlType="button" onClick={close}>Okay</Button>
				</div>
			]}
			width={"75%"}
		>
			<ol style={{ listStyleType: 'decimal' }}>
				{
					logValues && typeof (logValues) === "object" ? (
						logValues.map(({ log, has_error }, index) => {
							return (
								<li key={index} className={has_error ? "text-danger" : null}>{log}</li>
							)
						})
					) : <span>{logValues}</span>
				}
			</ol>
		</Drawer>
	)
})

export default ViewImportLogDetailsComponent
