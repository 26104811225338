import React, { useState } from 'react'
import { observer } from "mobx-react";
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import DeleteComponent from './component/DeleteComponent';
import ListComponent from './component/ListComponent';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig';
import useStore from '../../../../store';
import Unauthorized from '../../../Unauthorized';
import { Button, PageHeader } from 'antd';
import RecordPerPage from '../../../../components/RecordPerPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen';

const Triggers = observer(() => {
	const { UserStore, TriggerStore } = useStore()
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [deleteDrawer, setDeleteDrawer] = useState(false)
	const [id, setId] = useState()

	//open drawer to add trigger
	const openAddDrawer = () => {
		setAddDrawer(true)
	}

	//close drawer for add trigger
	const closeAdddrawer = () => setAddDrawer(false)

	//open drawer to edit trigger
	const openEditDrawer = (data) => {
		setId(data.id)
		setEditDrawer(true)
	}

	//close drawer for add trigger
	const closeEditDrawer = () => setEditDrawer(false)

	//open drawer to delete trigger
	const openDeleteDrawer = (data) => {
		TriggerStore.setDeleteValues(data)
		setDeleteDrawer(true)
	}

	//close drawer to delete trigger
	const closeDeleteDrawer = () => setDeleteDrawer(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('trigger_grid')
		TriggerStore.agGrid.api.setFilterModel(null)
		TriggerStore.agGrid.api.setSortModel(null)
		TriggerStore.agGrid.columnApi.resetColumnState()
		TriggerStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Triggers.path} />
			{(!UserStore.checkPermission(83, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={"has_export_spinner " + UserStore.fullscreen_class}
					title={BreadcrumbConfig.Triggers.title}
					extra={[
						(UserStore.checkPermission(83, 'add')) && <Button key="1" onClick={openAddDrawer}>New</Button>,
						<RecordPerPage key="2" defaultValue={TriggerStore.per_page + ' per page'} onChange={TriggerStore.setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditDrawer={openEditDrawer} openDeleteDrawer={openDeleteDrawer} />
					<AddComponent visible={addDrawer} close={closeAdddrawer} />
					<EditComponent visible={editDrawer} close={closeEditDrawer} id={id} />
					<DeleteComponent visible={deleteDrawer} close={closeDeleteDrawer} />
				</PageHeader>
			)}
		</>
	)
})

export default Triggers
