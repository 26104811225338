import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input } from 'antd'
import useStore from '../../../../../../store'
import { observer } from 'mobx-react'
import { vsmNotify, vsmTransactionSubTypes } from '../../../../../../config/messages'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionSubTypeStore: { EditData, editValues } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionSubTypes.edit
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		if (editValues) {
			form.setFieldsValue({
				sub_type_name: editValues.sub_type_name,
			})
		}
	}, [editValues, form, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Transactions Type - #${editValues.id} - ${editValues.sub_type_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Form.Item name="sub_type_name" rules={vsmTransactionSubTypes.validation.sub_type_name} label="Transactions Type name" required tooltip='Transaction Type is used to further classify Transactions and allow for mapping of specific Transaction Models against specific Trade Types and Transaction Type.'>
					<Input placeholder="Transactions Type name" maxLength={100} />
				</Form.Item>
			</Form>
		</Modal>
	) : null
})

export default EditComponent
