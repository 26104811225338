import React, { useEffect, useState } from 'react'
import { Form, Button, Drawer } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmCashTransactions, vsmNotify } from '../../../../config/messages'
import moment from 'moment'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const { CashTransactionStore, SavedReportStore, CTEImportStore, OrgBankAccountStore } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.posted_on = data.posted_on && moment(data.posted_on).format("YYYY-MM-DD")
		data.value_on = data.value_on && moment(data.value_on).format("YYYY-MM-DD")
		// data.cte_fields = data.cte_fields.filter(x => x && x.cte_field_id && x.field_value);
		setSaving(true)
		if (Number(data.cte_transactions_type_id)) {
			data.cte_transactions_type_id = Number(data.cte_transactions_type_id)
		} else {
			data.cte_transactions_type_id = { id: null, type_name: data.cte_transactions_type_id[0] }
		}
		CashTransactionStore.AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmCashTransactions.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
		props.setIsUpdate(true)
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		SavedReportStore.main_organisation_roles = null
		SavedReportStore.main_people_roles = null
		SavedReportStore.main_organisation = null
		SavedReportStore.related_organisation = null
		SavedReportStore.related_organisation_role = null
		SavedReportStore.main_people = null
		SavedReportStore.related_people = null
		SavedReportStore.related_people_role = null
	}

	useEffect(() => {
		if (props.defaultCteData) {
			CTEImportStore.bank_list = [{ bankAccount_names: props.defaultCteData.bankName, id: props.defaultCteData.bankID }]
			SavedReportStore.main_organisation_roles = [{ id: 1, role_name: "Ledger Owner" }]
			SavedReportStore.main_organisation = [{ organisation_name: "Credebt Exchange Limited", organisation_id: 1 }]
			SavedReportStore.related_organisation_role = [{ role_name: "Ledger Owner", id: 1 }]
			SavedReportStore.related_organisation = [{ organisation_name: "Credebt Exchange Limited", organisation_id: 1 }]
			OrgBankAccountStore.dropdown_currency_list = [props.defaultCteData.global_currency]
			form.setFieldsValue({
				bank_account_id: props.defaultCteData.bankID,
				posted_on: moment(props.defaultCteData.posted_on),
				value_on: moment(props.defaultCteData.value_on),
				role_id: 1,
				entity_id: 1,
				related_role_id: 1,
				related_entity_id: 1,
				currency_id: props.defaultCteData.global_currency && props.defaultCteData.global_currency.id
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props.defaultCteData])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'45%'}
			title={`Add New Cash Transaction`}
			destroyOnClose={true}
			className='remove-error-text'
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				visible={props.visible}
				onChange={handleChange}
				onFinish={handleSubmit}
				tab={"add"}
			/>
		</Drawer>

	)
})

export default AddComponent
