import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'

const ListComponent = observer((props) => {
	const { openEditModal, openCloneModal, openFieldModal, onSwitchChange } = props
	const { LedgerBankAccountStore: { list_data, getList, setupGrid, onFilterChanged, onGridChanged }, UserStore } = useStore()

	var column = gridOptions.columnDefs
	if (!UserStore.checkPermission(73, 'status')) {
		column = gridOptions.columnDefs.filter(x => x.headerName !== 'Status')
	}

	useEffect(() => {
		getList()
	}, [getList])

	let columns = []
	let columnConfig = localStorage.getItem('ledger_bank_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = column.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = column.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = column
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openCloneModal, openFieldModal
				}}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSwitchChange={onSwitchChange}
				onSortChanged={onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
