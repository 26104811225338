import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react';
import useStore from '../../../../../store'
import { Button, Drawer, PageHeader } from 'antd'
import ListComponent from './ListComponent'
import ViewImportLogDetailsComponent from './ViewImportLogDetailsComponent'
import { useHistory } from 'react-router-dom';
import ContentLoader from '../../../../../components/ContentLoader';
import { vsmImport, vsmNotify } from '../../../../../config/messages';
import { default as AllTransactionViewComponent } from '../../../AllTransactions/component/ViewComponent';

const ViewImportLogComponent = observer((props) => {
	const [viewImportLogDetails, setViewImportLogDetails] = useState(false)
	const [loading, setLoading] = useState(true)
	const [importValues, setImportValues] = useState(null)
	const [transactionId, setTransactionId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	const { ImportStore, ImportStore: { getRecordById, getViewLogs, setViewLogValues, getLogDetails } } = useStore()
	const Store = { Import: ImportStore }
	let history = useHistory()

	// show error message & go back
	const invalidRecord = useCallback(() => {
		vsmNotify.error({
			message: vsmImport.InvalidRecord
		})
		history.goBack()
		setLoading(false)
	}, [history])

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			Store.Import.view_data = null
			setViewLogValues(null)
			getRecordById(props.importId).then((data) => {
				if (data) {
					getViewLogs(props.importId)
					setImportValues(data)
					setLoading(false)
				} else {
					invalidRecord()
				}
			}).catch((data) => {
				invalidRecord()
			})
		}

	}, [Store.Import, invalidRecord, getRecordById, setViewLogValues, getViewLogs, props.visible, props.importId])

	// set id for view component
	const setViewId = (id) => setTransactionId(id)

	// open drawer for view
	const openViewDrawer = (id) => {
		setViewDrawer(true)
		setViewId(id)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// open log detail drawer
	const openSetViewImportLogDetails = (data) => {
		setViewLogValues(data)
		getLogDetails(data.id)
		setViewImportLogDetails(true)
	}

	// close log detail drawer
	const closeSetViewImportLogDetails = () => setViewImportLogDetails(false)

	// Reset form id and close view form
	const close = () => {
		props.close()
		props.setId(null)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`View Log - #${importValues && importValues.id} ${importValues && importValues.import_name}`}
			destroyOnClose={true}
		>
			{
				!loading && importValues ?
					<PageHeader
						extra={[
							<Button type="primary" key="1"><a href={importValues.import_filename_link}>Download Import File with Status Column</a></Button>,
							<Button key="2" onClick={close}>Back to List</Button>
						]}
					>
						<ListComponent importValues={importValues} openSetViewImportLogDetails={openSetViewImportLogDetails} openViewDrawer={openViewDrawer} />
						<ViewImportLogDetailsComponent visible={viewImportLogDetails} close={closeSetViewImportLogDetails} />
						<AllTransactionViewComponent transactionId={transactionId} setId={setViewId} visible={viewDrawer} close={closeViewDrawer} />
					</PageHeader> : <ContentLoader />
			}
		</Drawer>
	)
})

export default ViewImportLogComponent
