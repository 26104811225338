import React from 'react'
import { Form, Select } from 'antd'
import useStore from '../../../store'

const TransactionComponent = (props) => {

	const { AlgorithmsStore: { transaction_field_list } } = useStore()

	const handleChange = (e) => {
		props.handleChange(e)
	}
	let extra = {}
	if (!props.formula.value) {
		if (props.formula.value === null || props.formula.value === undefined) {
			extra.validateStatus = 'error'
		} else if (typeof (props.formula.value) === 'string' && props.formula.value.trim() === '') {
			extra.validateStatus = 'error'
		}
	}

	let field_value = null
	transaction_field_list && transaction_field_list.length > 0 && transaction_field_list.forEach(x => {
		if (x.id === props.formula.value) { field_value = props.formula.value }
	})

	return (
		<Form.Item {...extra} >
			<Select showSearch defaultValue={field_value}
				value={field_value}
				className="w-100" placeholder="Select Transaction Field"
				onChange={handleChange}
				optionFilterProp="children"
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				allowClear
			>
				{
					transaction_field_list && transaction_field_list.length > 0 ? transaction_field_list.map((item, index) => {
						return <Select.Option key={index} value={item.id}>{item.field_name}</Select.Option>
					}) : null
				}
			</Select>
		</Form.Item>
	)
}

export default TransactionComponent
