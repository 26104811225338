import React, { useEffect, useState } from 'react'
import { Form, Button, Input, Select, Card, Divider, Drawer, Row, Col } from 'antd'
import { vsmNotify, vsmTransactionModels } from '../../../../../config/messages';
import useStore from '../../../../../store'
import { observer } from 'mobx-react';
import JournalLines from './JournalLines';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore: { AddData, dropdown_type_list, array_list, setArrayList, getAllMappingList, setFieldMappingList } } = useStore()
	const [saving, setSaving] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [mappingId, setMappingId] = useState(null)
	const [errorMessage, setErrorMessage] = useState(false)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const mapping_name = dropdown_type_list.find((item) => item.id === data.type_field_mapping_id)
		data.journal_lines = array_list
		data.mapping_name = mapping_name
		data.total_journal_lines = array_list.length
		setSaving(true)
		AddData(data).then(() => {
			vsmNotify.success({
				message: vsmTransactionModels.add
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		if (props.visible) {
			setArrayList(null)
			getAllMappingList()
		}
	}, [getAllMappingList, setArrayList, props.visible])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		setMappingId(form.getFieldsValue().type_field_mapping_id)
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setErrorMessage(true)
			setDisabled(true)
		})
	}

	// Reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setArrayList(null)
		setFieldMappingList()
	}

	return (
		<>
			<Drawer
				title="New Transaction Model"
				visible={props.visible}
				onClose={close}
				placement='right'
				width={'75%'}
				destroyOnClose={true}
				footer={[
					<div className="text-center" key="1">
						<Button form='addform' disabled={disabled || (array_list ? array_list.length <= 0 : true)} className="mr-10" loading={saving} htmlType="submit" type="primary">Save</Button>
						<Button onClick={() => { close() }}>Cancel</Button>
					</div>
				]}
			>
				<Card>
					<Form
						form={form}
						id="addform"
						onFinish={handleSubmit}
						layout='vertical'
						onChange={handleChange}
					>
						<Row gutter={24}>
							<Col span={8}>
								<Form.Item name="type_field_mapping_id" rules={vsmTransactionModels.validation.mapping_name} label="Trade Transaction" required tooltip='Trade Transaction is Trade Type, Transaction Type and Trade Transaction. The mapping helps to group set of models together and execute Transactions against these models.'>
									<Select showSearch placeholder="Select Trade Transaction"
										optionFilterProp="children"
										onChange={handleChange}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										allowClear
									>
										{
											(dropdown_type_list && dropdown_type_list.map((item, index) => (
												<Select.Option key={index} value={item.id}>{item.mapping_name}</Select.Option>
											)))
										}
									</Select>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="model_code" rules={vsmTransactionModels.validation.model_code} label="Code" required tooltip='Code (of a Transaction Model) is an mandatory parameter of a Transaction Model. Code is unique and alphanumerical.'>
									<Input id="model_code" placeholder="Code" maxLength={10} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name="model_name" rules={vsmTransactionModels.validation.model_name} label="Model Name" required tooltip='Transaction Model name is a mandatory parameter of a Transaction Model. Model Name must be unique. Transaction Model is a tool for creating Models, that are defined through the use of multiple Model Lines.'>
									<Input id="model_name" placeholder="Model Name" maxLength={255} />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item name="model_desc" rules={vsmTransactionModels.validation.model_description} label="Model Description" required tooltip='Model Description is used to further describe the Model details and its purpose.'>
							<Input.TextArea placeholder="Model Description" maxLength={1000} />
						</Form.Item>

						<JournalLines currentView={"add"} data={mappingId} errorMessage={errorMessage} setErrorMessage={setErrorMessage} handleChange={handleChange} />

						<Divider />
					</Form>
				</Card>
			</Drawer>
		</>
	)
})

export default AddComponent
