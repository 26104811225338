import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import { Button, PageHeader } from 'antd'
import useStore from '../../../store'
import RecordPerPage from '../../../components/RecordPerPage'
import ListComponent from './components/ListComponent'
import DeleteComponent from './components/DeleteComponent'
import AddComponent from './components/AddComponent'
import EditComponent from './components/EditComponent'
import PreviewReportComponent from './components/PreviewReportComponent'
import PreviewDataComponent from './components/PreviewDataComponent'
import Unauthorized from '../../Unauthorized'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const SavedReports = observer(() => {
	let history = useHistory()
	let location = useLocation()
	const [deleteModal, setDeleteModal] = useState(false)
	const [report_id, setReportID] = useState(false)
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [previewReportModal, setPreviewReportModal] = useState(false)
	const [previewDataModal, setPreviewDataModal] = useState(false)
	const { UserStore, SavedReportStore, SavedReportStore: { getList, per_page, setDeleteValues, setPageSize } } = useStore()

	history.listen((location, action) => {
		if (location.search !== '') {
			var tempSplit = location.search.split('=')
			if (tempSplit[0] && tempSplit[0] === '?preview_report' && tempSplit[1]) {
				openPreviewReportModal(tempSplit[1])
			}
		}
	})

	// Open form for add new User
	const openAddModal = () => {
		setAddModal(true)
		SavedReportStore.created_by_id = null
	}

	// Close form for add new User
	const closeAddModal = () => {
		setAddModal(false);
		SavedReportStore.previewReportValues = null;
	}


	// Open form for edit User
	const openEditModal = (id) => {
		setEditModal(true)
		setReportID(id)
	}

	// Close form for edit User
	const closeEditModal = () => setEditModal(false)

	// Open form for preview Reports Drawer
	const openPreviewReportModal = useCallback((id) => {
		setPreviewReportModal(true)
		setReportID(id)
	}, [setPreviewReportModal, setReportID])

	// Close form for preview Reports Drawer
	const closePreviewReportModal = (close_all = true) => {
		SavedReportStore.reportColumnSettings = null
		SavedReportStore.agGridList = null
		SavedReportStore.agGridOrganisationList = null
		setPreviewReportModal(false)
		if (close_all) {
			setAddModal(false)
			setEditModal(false)
		}
		history.replace(history.location.pathname)
	}

	// Open form for preview Reports Drawer
	const openPreviewDataModal = (id) => {
		setPreviewDataModal(true)
		setReportID(id)
	}

	// Close form for preview Reports Drawer
	const closePreviewDataModal = (close_all = true) => {
		setPreviewDataModal(false)
		if (close_all) {
			setAddModal(false)
			setEditModal(false)
			SavedReportStore.previewReportValues = null;
		}
	}

	// Open confirmation alert before deleting existing Role
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	const handleReset = async () => {
		await localStorage.removeItem('report_grid')
		SavedReportStore.agGrid.api.setFilterModel(null)
		SavedReportStore.agGrid.api.setSortModel(null)
		SavedReportStore.agGrid.columnApi.resetColumnState()
		SavedReportStore.agGrid.api.onFilterChanged(null)
	}

	useEffect(() => {
		SavedReportStore.previewReportValues = null
		if (location.search !== '') {
			var tempSplit = location.search.split('=')
			if (tempSplit[0] && tempSplit[0] === '?preview_report' && tempSplit[1]) {
				openPreviewReportModal(tempSplit[1])
			}
		}
	}, [SavedReportStore, location.search, openPreviewReportModal])

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.SavedReports.path} />
			{(!UserStore.checkPermission(47, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.SavedReports.title}
					extra={[
						<Button key="1" onClick={() => {
							if (SavedReportStore.my_records === 'all') {
								getList({}, UserStore.user.id); SavedReportStore.my_records = UserStore.user.id
							} else {
								getList({}, 'all'); SavedReportStore.my_records = 'all'
							}
						}}>{SavedReportStore.my_records === UserStore.user.id ? 'Show All Reports' : 'Show only My Reports'}</Button>,
						UserStore.checkPermission(47, 'add') && <Button key="2" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key="5" />
					]}
				>
					<ListComponent openPreviewReportModal={openPreviewReportModal} openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					{addModal && <AddComponent openPreviewDataModal={openPreviewDataModal} openPreviewReportModal={openPreviewReportModal} visible={addModal} close={closeAddModal} />}

					{editModal && <EditComponent report_id={report_id} setReportID={setReportID} openPreviewDataModal={openPreviewDataModal} openPreviewReportModal={openPreviewReportModal} visible={editModal} close={closeEditModal} />}

					{previewReportModal && <PreviewReportComponent addModal={addModal} editModal={editModal} openPreviewDataModal={openPreviewDataModal} report_id={report_id} setReportID={setReportID} visible={previewReportModal} close={closePreviewReportModal} />}

					{previewDataModal && <PreviewDataComponent addModal={addModal} editModal={editModal} report_id={report_id} openPreviewReportModal={openPreviewReportModal} setReportID={setReportID} visible={previewDataModal} close={closePreviewDataModal} />}
					{deleteModal && <DeleteComponent visible={deleteModal} close={closeDeleteModal} />}
				</PageHeader>
			)
			}
		</>
	)
})

export default SavedReports
