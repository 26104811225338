import React from 'react'
import { observer } from "mobx-react";
import { Col, Row, Table } from 'antd';
import RelatedOrganisationList from "./ListElement/RelatedOrganization";
import RelatedPeopleList from "./ListElement/RelatedPeople";
import TradeTransactions from "./ListElement/TradeTransactions";
import CashTransactions from "./ListElement/CashTransactions";
import Banks from "./ListElement/Banks";
import { TagOutlined, UserOutlined, CalendarOutlined, PaperClipOutlined, GlobalOutlined, PhoneOutlined, MailOutlined, FileOutlined } from '@ant-design/icons';

const ViewElement = observer((props) => {
	const { Column } = Table;

	const main_info = [
		{
			key: '1',
			label: <div><TagOutlined /> Title</div>,
			value: props.peopleValues && props.peopleValues.title,
		},
		{
			key: '2',
			label: <div><UserOutlined /> Name</div>,
			value: props.peopleValues && props.peopleValues.name,
		},
		{
			key: '3',
			label: <div><CalendarOutlined /> DOB</div>,
			value: props.peopleValues && props.peopleValues.date_of_birth,
		},
		{
			key: '4',
			label: <div><PaperClipOutlined /> PPSN Document Type</div>,
			value: props.peopleValues && props.peopleValues.ppsn_document_type,
		},
		{
			key: '5',
			label: <div><PaperClipOutlined /> PPS Number</div>,
			value: props.peopleValues && props.peopleValues.pps_number,
		},
		{
			key: '6',
			label: <div><GlobalOutlined /> Country</div>,
			value: props.peopleValues && props.peopleValues.addresses_global_countries && props.peopleValues.addresses_global_countries.country_name,
		},
	];

	const other_info = [
		{
			key: '1',
			label: <div><PhoneOutlined /> Phone</div>,
			value: props.peopleValues && props.peopleValues.phone.map(x => <div>{x.contact_value}</div>),
		},
		{
			key: '2',
			label: <div><MailOutlined /> Email</div>,
			value: props.peopleValues && props.peopleValues.email.map(x => <div>{x.contact_value}</div>),
		},
		{
			key: '3',
			label: <div><FileOutlined /> Note</div>,
			value: props.peopleValues && props.peopleValues.notes,
		},
		{
			key: '4',
			label: <div><PaperClipOutlined /> Former Last Name</div>,
			value: props.peopleValues && props.peopleValues.former_last_name,
		},
		{
			key: '5',
			label: <div><PaperClipOutlined /> Pronounced</div>,
			value: props.peopleValues && props.peopleValues.pronounced,
		},
	];

	return (
		<>
			<Row gutter={24} style={{ marginBottom: '10px' }}>
				<Col className="w-50">
					<Table dataSource={main_info} pagination={false} title={false} className="no_header" bordered>
						<Column dataIndex="label" key="label" width={'200px'} />
						<Column dataIndex="value" key="value" />
					</Table>
				</Col>

				<Col className="w-50">
					<Table dataSource={other_info} pagination={false} className="no_header" bordered>
						<Column dataIndex="label" key="label" width={'170px'} />
						<Column dataIndex="value" key="value" />
					</Table>
				</Col>
			</Row>

			<RelatedOrganisationList id={props.id} currentTab={"people"} handleViewDetailCall={props.handleViewDetailCall} />

			<RelatedPeopleList id={props.id} currentTab={"people"} handleViewDetailCall={props.handleViewDetailCall} />

			<TradeTransactions id={props.id} currentTab={"people"} handleViewDetailCall={props.handleViewDetailCall} />

			<CashTransactions id={props.id} currentTab={"people"} handleViewDetailCall={props.handleViewDetailCall} />

			<Banks id={props.id} currentTab={"people"} handleViewDetailCall={props.handleViewDetailCall} />

		</>
	)
})

export default ViewElement
