import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { PageHeader, Button, Menu, Dropdown } from 'antd'
import useStore from '../../../store'
import { useHistory, useLocation } from 'react-router-dom'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import ImportComponent from './component/ImportComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import MapOrgWithLedger from './component/MapOrgWithLedger'
import Unauthorized from '../../Unauthorized'
import { vsmNotify } from '../../../config/messages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FileImageOutlined } from '@ant-design/icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const Organisations = observer(() => {
	let history = useHistory()
	const { OrganisationStore: { per_page, agGrid, exportLogoLoading, setOrganisationValues, setEditValues, setDeleteValues, setPageSize, exportData, exportOrganisationRolesData, exportLoading, exportLogoPdf, handleReset }, UserStore } = useStore()
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [importModal, setImportModal] = useState(useLocation().search === '?import=1' && (UserStore.checkPermission(36, 'role_import') || UserStore.checkPermission(36, 'organistion_import')))
	const [mapModal, setMapModal] = useState(false)
	const [currentTab, setCurrentTab] = useState(null)
	const [transactionId, setTransactionId] = useState(null)

	history.listen((location, action) => {
		if (location.search === '?import=1') {
			if (UserStore.checkPermission(36, 'role_import') || UserStore.checkPermission(36, 'organistion_import')) {
				setImportModal(true)
			}
		}
	})

	// set id for edit / view drawer
	const setId = (id) => setTransactionId(id)

	// Open form for add new Organisation
	const openAddModal = (para = null, form = null) => {
		setCurrentTab({ para, form })
		setAddModal(true)
	}

	// Close form for close new Organisation
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing Organisation and set values to form
	const openEditModal = (data) => {
		setOrganisationValues(null)
		setId(data.id)
		setEditValues(data)
		setEditModal(true)
	}

	// Open Role Mapping List
	const openRoleListing = (data) => {
		history.push(`/organisations/all-organisations/role-mapping/${data.id}`)
	}

	// Open Bank Accounts List
	const openBankAccounts = (data) => {
		history.push(`/organisations/all-organisations/bank-accounts/${data.id}`)
	}

	// Close form of edit Organisation
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing Organisation
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	//Open Modal to import data
	const importData = () => setImportModal(true)

	//Close import modal
	const closeImportModal = () => {
		setImportModal(false)
		history.replace(history.location.pathname)
	}

	//Open Modal to Map Organisation with Current Ledger
	const mapOrgWithLedger = () => {
		setMapModal(true)
	}

	//Close Modal to Map Organisation with Current Ledger
	const closeMapModal = () => setMapModal(false)

	//Menu to show export Organisations and Organisations Roles
	const menu = (
		<Menu>
			{UserStore.checkPermission(35, 'export_organisation') && <Menu.Item key="organisations" onClick={() => exportData()}>Organisations</Menu.Item>}
			{UserStore.checkPermission(35, 'export_role') && <Menu.Item key="organisations_roles" onClick={() => exportOrganisationRolesData()}>Organisations Roles</Menu.Item>}
		</Menu>
	)

	const openViewDrawer = (data) => {
		history.push(`/organisations/all-organisations/view-organisation/${data.id}`)
	}

	// Open form for multiple Reconcile Cash Transaction with Trade Transaction’s Journal entry
	const exportLogoAsPdf = (data) => {
		if (agGrid) {
			let selected = agGrid.api.getSelectedRows()
			selected = selected.map(item => item.id)
			if (selected.length > 0) {
				exportLogoPdf({ selected_ids: selected })
				agGrid.api.deselectAll()
			}
			else { vsmNotify.error({ message: "Please select atleast one record to export." }) }
		}
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Organisations.path} />
			{(!UserStore.checkPermission(35, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Organisations.title}
					extra={[
						<Button key="1" onClick={mapOrgWithLedger}>Map - Add Organisation</Button>,
						(UserStore.checkPermission(36, 'role_import') || UserStore.checkPermission(36, 'organistion_import')) && <Button key="2" onClick={importData}>Import</Button>,
						(!UserStore.checkPermission(35, 'export_role') && !UserStore.checkPermission(35, 'export_organisation')) ? null : (<Dropdown key="4" overlay={menu}><Button loading={exportLoading} key="2" onClick={e => e.preventDefault()}>Export</Button></Dropdown>),
						UserStore.checkPermission(35, 'add') && <Button key="5" onClick={openAddModal}>Add Organisation</Button>,
						<RecordPerPage key="6" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" loading={exportLogoLoading} title="Export Logo as PDF" onClick={exportLogoAsPdf}><FileImageOutlined /></Button>,
						<Button key="7" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='8' />
					]}
				>
					<ListComponent openViewDrawer={openViewDrawer} openEditModal={openEditModal} openDeleteModal={openDeleteModal} openRoleListing={openRoleListing} openBankAccounts={openBankAccounts} />
					{addModal && <AddComponent visible={addModal} close={closeAddModal} currentTab={currentTab} />}
					<EditComponent transactionId={transactionId} setId={setId} visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<ImportComponent visible={importModal} close={closeImportModal} importData={importData} />
					{mapModal && <MapOrgWithLedger visible={mapModal} close={closeMapModal} mapData={mapOrgWithLedger} openAddModal={openAddModal} />}
				</PageHeader>
			)}
		</>
	)
})

export default Organisations
