import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import useStore from '../../../../store'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmUserManagement } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const { UserManagementStore } = useStore()
	const Store = {
		UserManagement: UserManagementStore,
	}

	// set the form values to delete
	useEffect(() => {
		if (Store.UserManagement.deleteValues) {
			form.setFieldsValue({
				name: Store.UserManagement.deleteValues.first_name,
			})
		}
	}, [Store.UserManagement.deleteValues, form])

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = Store.UserManagement.deleteValues.id
		Store.UserManagement.DeleteData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmUserManagement.deleteUser
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	return Store.UserManagement.deleteValues ? (
		<Modal
			centered
			title={`Delete User - #${Store.UserManagement.deleteValues.id} - ${Store.UserManagement.deleteValues.first_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to archive this User?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
