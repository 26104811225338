import { observer } from 'mobx-react'
import React, { useState } from 'react'
import useStore from '../../../../../store'
import { Form, Button, Modal, Input, Col } from 'antd'
import { vsmUserManagement } from '../../../../../config/messages'

const AddLedgerAdminComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { LedgerStore: { AddAdminData, addUser, ledger_admin_list } } = useStore()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)

		var user_data = {
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			id: 0
		};

		let email = ledger_admin_list && ledger_admin_list.find(item => item.email === data.email)
		if (email) {
			form.setFields([{ name: "email", errors: ["Email ID already exists."] }]);
			setDisabled(true)
			setSaving(false)
		}
		else {
			AddAdminData(data).then(() => {
				addUser(user_data)
				close()
				props.form.setFieldsValue({ ledger_admin: 0 })
				if (props.handleChange) { props.handleChange() }
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		}
	}

	// check for valid form values then accordingly make save button disable / enable & set value of user
	const handleChange = (value) => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return (
		<Modal
			centered
			title="Add Ledger Admin"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addLedgerAdminform' loading={saving} htmlType="submit" type="primary">Assign to Ledger</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addLedgerAdminform" onFinish={handleSubmit}
				labelCol={{ span: 6 }}
				onChange={handleChange}>

				<Form.Item name="email" rules={vsmUserManagement.validation.email} label="Email" required tooltip='Messages distributed by electronic means from one computer user to one or more recipients via a network.'>
					<Input placeholder="Email Address" maxLength={100} />
				</Form.Item>

				<Form.Item name="first_name" rules={vsmUserManagement.validation.first_name} label="First Name" required tooltip='A personal Name that is given to user/ person at birth or baptism and used before a family name.'>
					<Input placeholder="First Name" maxLength={100} />
				</Form.Item>

				<Form.Item name="last_name" rules={vsmUserManagement.validation.last_name} label="Last Name" required tooltip='It is the Name of the family to which a person/ user is born.'>
					<Input placeholder="Last Name" maxLength={100} />
				</Form.Item>

				<Col offset={6} span={19} className="mb-10">
					<label>Note: User will be saved and mapped with ledger only after you save ledger.</label>
				</Col>
			</Form>
		</Modal>
	)
})

export default AddLedgerAdminComponent
