import React, { createRef, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Col, Drawer, Form, Row, Spin, Tooltip } from 'antd';
import { AgGridReact as AgGridReactLeft } from '@ag-grid-community/react'
import { AgGridReact as AgGridReactRight } from '@ag-grid-community/react'
import { AllModules as AllModulesLeft } from "@ag-grid-enterprise/all-modules"
import { AllModules as AllModulesRight } from "@ag-grid-enterprise/all-modules"
import LeftLocalGridConfig from '../../../../config/LocalGridConfig';
import RitghLocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon, vsmCTEImport, vsmNotify } from '../../../../config/messages';
import { gridOptions as LeftGridOptinos } from '../Elements/LeftGridElement';
import { gridOptions as RightGridOptinos } from '../Elements/RightGridElement';
import useStore from '../../../../store';
import InputComponent from '../../../../components/InputComponent';
import debounce from "lodash/debounce";
import { PhoneOutlined, GlobalOutlined, EnvironmentFilled } from '@ant-design/icons';


const NotIdentifiedDetailComponent = observer(({ visible, close, formData }) => {
    const [form] = Form.useForm()
    const { SummaryReportStore, CTEImportStore, OrganisationStore, PeopleStore } = useStore()
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [varifySaving, setVarifySaving] = useState(false)
    const [fetchBankId, setFetchBankId] = useState(true)
    const [loadingLeft, setLoadingLeft] = useState(false)
    const [loadingRight, setLoadingRight] = useState(false)
    const [searchOrgKey, setSearchOrgKey] = useState();
    const [searchPplKey, setSearchPplKey] = useState();
    const [btnType, setBtnType] = useState();
    const [toType, setToType] = useState('organisation')
    const [orgId, setOrgId] = useState(null)
    const [pplId, setPplId] = useState(null)
    const [orgData, setOrgData] = useState(null)
    // const [pplData, setPplData] = useState(null)
    var leftRef = createRef();
    var rightRef = createRef();

    const closeDrawer = (type) => {
        setDisabled(true)
        setSaving(false)
        setFetchBankId(true)
        setLoadingLeft(false)
        setLoadingRight(false)
        if (type) {
            close(type)
        } else { close() }
    }

    const handleSearch = useMemo(() => {
        const loadOptions = (val) => {
            setSearchPplKey(val);
            SummaryReportStore.getOrgCurrentLedgerMapNotIdentifiedData({ search_for: val, mapping_type: 2 });
        };
        return debounce(loadOptions, 500);
    }, [SummaryReportStore]);

    // search string for dropdown
    const handleSearchOrg = useMemo(() => {
        const loadOptions = (val) => {
            setSearchOrgKey(val);
            SummaryReportStore.getOrgCurrentLedgerMapNotIdentifiedData({
                search_for: val,
                mapping_type: 1,
            });
        };
        return debounce(loadOptions, 500);
    }, [SummaryReportStore]);

    const onRowSelected = () => {
        let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
        let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
        if (![1, 2].includes(form.getFieldValue('reconciliation_status'))) {
            if ((tempLeftArray && tempLeftArray.length === 0) && (tempRightArray && tempRightArray.length === 0)) {
                setDisabled(true)
            } else if ((tempLeftArray && tempLeftArray.length) !== (tempRightArray && tempRightArray.length)) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        } else {
            if (tempLeftArray && tempLeftArray.length === 0) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }
    }

    const handleBankAccId = (ID, type, reconciliation_status) => {
        if (type === 'from') {
            setLoadingLeft(true)
            leftRef.current = null
            formData.bank_account_id = form.getFieldValue('from_bank_id')
        } else if (type === 'to') {
            setLoadingRight(true)
            rightRef.current = null
            formData.bank_account_id = form.getFieldValue('to_bank_id')
        }
        SummaryReportStore.not_identified_data = null
        formData.reconciliation_status = form.getFieldValue('reconciliation_status')

        if (reconciliation_status !== 'noAPICall') {
            SummaryReportStore.getNotIdentifiedData(formData, type).then(() => {
                if (type === 'from') {
                    setLoadingLeft(false)
                } else if (type === 'to') {
                    setLoadingRight(false)
                }
            })
        }
    }

    const handlChange = () => {
        var entity_id = form.getFieldValue("organisation_name")
        var role_id = form.getFieldValue("people_name")

        if (entity_id && (orgId !== entity_id)) {
            setOrgId(entity_id)
            OrganisationStore.getOrgCardValue(entity_id, "org").then((data) => {
                setOrgData(data)
            })
        } else if (role_id && (pplId !== role_id)) {
            setPplId(role_id)
            PeopleStore.getPeopleCardValue(role_id, 'people').then((data) => {
                setOrgData(data)
            })
        }

        form.validateFields().then((data) => {
            let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
            let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
            if (![1, 2].includes(form.getFieldValue('reconciliation_status'))) {
                if ((tempLeftArray && tempLeftArray.length === 0) && (tempRightArray && tempRightArray.length === 0)) {
                    setDisabled(true)
                } else if ((tempLeftArray && tempLeftArray.length) !== (tempRightArray && tempRightArray.length)) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            } else {
                if (tempLeftArray && tempLeftArray.length === 0) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const onFinish = (data) => {
        let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
        let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
        data.from_accounts = tempLeftArray.map((item) => {
            return item.data.id
        })
        data.to_accounts = tempRightArray && tempRightArray.length && tempRightArray.map((item) => {
            return item.data.id
        })
        if (btnType === 1) {
            setSaving(true)
        } else {
            setVarifySaving(true)
        }
        SummaryReportStore.manualVerification(data).then(() => {
            vsmNotify.success({
                message: vsmCTEImport.manualVerificationSuccess
            })
            if (btnType === 1) {
                setSaving(false)
                closeDrawer('apiLoad')
            } else {
                setVarifySaving(false)
                setLoadingLeft(true)
                setLoadingRight(true)
                // delete formData.bank_account_id
                SummaryReportStore.getNotIdentifiedData(formData, null).then(() => {
                    // form.resetFields()
                    setLoadingLeft(false)
                    setLoadingRight(false)
                })
            }
            SummaryReportStore.summary_list_data = null
            SummaryReportStore.getSummaryPreviewDetails(formData)
        }).catch(() => {
            setSaving(false)
            setVarifySaving(false)
        })

    }

    useEffect(() => {
        form.validateFields().then((data) => {
            if (![1, 2].includes(form.getFieldValue('reconciliation_status'))) {
                if ((leftRef.current.api.getSelectedNodes() && leftRef.current.api.getSelectedNodes().length === 0) && (rightRef.current.api.getSelectedNodes() && rightRef.current.api.getSelectedNodes().length === 0)) {
                    setDisabled(true)
                } else if ((leftRef.current.api.getSelectedNodes() && leftRef.current.api.getSelectedNodes().length) !== (rightRef.current.api.getSelectedNodes() && rightRef.current.api.getSelectedNodes().length)) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            } else {
                if (leftRef.current.api.getSelectedNodes() && leftRef.current.api.getSelectedNodes().length === 0) {
                    setDisabled(true)
                } else {
                    setDisabled(false)
                }
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }, [form, leftRef, rightRef])

    return (
        <Drawer
            visible={visible}
            onClose={closeDrawer}
            title={`Manual Verification/Identification`}
            placement='right'
            width={'100%'}
            className='remove-error-text'
            destroyOnClose={true}
            footer={[
                <div className="text-center" key='1'>
                    {disabled ? (
                        <>
                            <Tooltip key='1' className='mr-10' color='red' title='Atleast one and same number of transactions are selected both side.'>
                                <Button type='primary' disabled={disabled}>Verify and Close</Button>
                            </Tooltip>
                            <Button key='2' disabled={disabled} className='mr-10' type='primary' htmlType='submit' form='viewTransactions'>Verify</Button>
                        </>
                    ) : (
                        <>
                            <Button key='3' className='mr-10' type='primary' htmlType='submit' form='viewTransactions' loading={saving} onClick={() => setBtnType(1)}>Verify and Close</Button>
                            <Button key='4' className='mr-10' type='primary' htmlType='submit' form='viewTransactions' loading={varifySaving} onClick={() => setBtnType(2)}>Verify</Button>
                        </>
                    )}
                </div>
            ]}
        >
            <Form
                form={form}
                layout='vertical'
                id='viewTransactions'
                onFinish={onFinish}
                initialValues={{ reconciliation_status: 0, to_org_ppl: 'organisation' }}
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Row gutter={10}>
                            <Col span={7}>
                                <InputComponent
                                    // required
                                    placeholder="Select Bank Account"
                                    type="select"
                                    allowClear
                                    options={{
                                        values: CTEImportStore.bank_list,
                                        text_key: "bankAccount_names",
                                        value_key: "id",
                                        rejected_keys: [form.getFieldValue('to_bank_id')]
                                    }}
                                    label='From Bank Account'
                                    name='from_bank_id'
                                    onChange={(e) => { handleBankAccId(e, 'from'); handlChange() }}
                                    tooltip="A name or code given to a cash account that indicates the account's purpose."
                                    // rules={vsmCTEImport.validation.bank_name}
                                    maxLength={100}
                                    autoComplete="off"
                                    onFocus={() => fetchBankId && CTEImportStore.getBankListForNotIdentifiedData(SummaryReportStore.account_number_array).then(() => setFetchBankId(false))}
                                    notFoundContent={fetchBankId ? <Spin size="small" /> : "No Record Found."}
                                />
                            </Col>
                            <Col span={5}>
                                <InputComponent
                                    required
                                    type="select" filterOption={false}
                                    options={{
                                        values: [{ value: 0, text: 'All' }, { value: 1, text: 'External Receipt' }, { value: 2, text: 'External Payments' }, { value: 3, text: 'Internal' }]
                                    }}
                                    label='Type'
                                    name='reconciliation_status'
                                    onChange={(e) => { handleBankAccId(e, 'from', 'reconciliation_status'); handlChange() }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        {![1, 2].includes(form.getFieldValue('reconciliation_status')) &&
                            <Col span={7}>
                                <InputComponent
                                    // required
                                    placeholder="Select Bank Account"
                                    type="select"
                                    allowClear
                                    options={{
                                        values: CTEImportStore.bank_list,
                                        text_key: "bankAccount_names",
                                        value_key: "id",
                                        rejected_keys: [form.getFieldValue('from_bank_id')]
                                    }}
                                    label='To Bank Account'
                                    name='to_bank_id'
                                    onChange={(e) => { handleBankAccId(e, 'to'); handlChange() }}
                                    tooltip="A name or code given to a cash account that indicates the account's purpose."
                                    // rules={vsmCTEImport.validation.bank_name}
                                    maxLength={100}
                                    autoComplete="off"
                                    onFocus={() => fetchBankId && CTEImportStore.getBankListForNotIdentifiedData(SummaryReportStore.account_number_array).then(() => setFetchBankId(false))}
                                    notFoundContent={fetchBankId ? <Spin size="small" /> : "No Record Found."}
                                />
                            </Col>

                        }
                    </Col>
                </Row>
            </Form>
            <div style={{ height: 'calc(100% - 80px)' }}>
                <Row gutter={24} style={{ height: '100%' }}>
                    <Col span={12}>
                        {loadingLeft ? (<Spin spinning={loadingLeft} size='default' />) : (
                            <div className="ag-theme-alpine grid_wrapper">
                                <AgGridReactLeft
                                    ref={leftRef}
                                    rowData={SummaryReportStore.not_identified_data_from}
                                    modules={AllModulesLeft}
                                    columnDefs={LeftGridOptinos.columnDefs}
                                    defaultColDef={LeftLocalGridConfig.defaultColDef}
                                    columnTypes={LeftLocalGridConfig.columnTypes}
                                    overlayNoRowsTemplate={vsmCommon.noRecord}
                                    onGridReady={SummaryReportStore.setupGridNotIdentifiedLeft}
                                    gridOptions={LeftLocalGridConfig.options}
                                    onFilterChanged={SummaryReportStore.onLeftFilterChanged}
                                    // onColumnResized={(params) => { SummaryReportStore.onGridChangedLeft(params, leftRef) }}
                                    // onColumnMoved={(params) => { SummaryReportStore.onGridChangedLeft(params, leftRef) }}
                                    // onColumnPinned={(params) => { SummaryReportStore.onGridChangedLeft(params, leftRef) }}
                                    // onSortChanged={(params) => { SummaryReportStore.onGridChangedLeft(params, leftRef) }}
                                    suppressRowClickSelection={true}
                                    rowSelection={'multiple'}
                                    onRowSelected={onRowSelected}
                                />
                            </div>
                        )}
                    </Col>
                    {![1, 2].includes(form.getFieldValue('reconciliation_status')) ?
                        <Col span={12}>
                            {loadingRight ? (<Spin spinning={loadingRight} size='default' />) : (
                                <div className="ag-theme-alpine grid_wrapper">
                                    <AgGridReactRight
                                        ref={rightRef}
                                        rowData={SummaryReportStore.not_identified_data_to}
                                        modules={AllModulesRight}
                                        columnDefs={RightGridOptinos.columnDefs}
                                        defaultColDef={RitghLocalGridConfig.defaultColDef}
                                        columnTypes={RitghLocalGridConfig.columnTypes}
                                        overlayNoRowsTemplate={vsmCommon.noRecord}
                                        onGridReady={SummaryReportStore.setupGridNotIdentifiedRight}
                                        gridOptions={RitghLocalGridConfig.options}
                                        onFilterChanged={SummaryReportStore.onRightFilterChanged}
                                        // onColumnResized={(params) => { SummaryReportStore.onGridChangedRight(params, rightRef) }}
                                        // onColumnMoved={(params) => { SummaryReportStore.onGridChangedRight(params, rightRef) }}
                                        // onColumnPinned={(params) => { SummaryReportStore.onGridChangedRight(params, rightRef) }}
                                        // onSortChanged={(params) => { SummaryReportStore.onGridChangedRight(params, rightRef) }}
                                        suppressRowClickSelection={true}
                                        rowSelection={'multiple'}
                                        onRowSelected={onRowSelected}
                                    />
                                </div>
                            )}
                        </Col>
                        :
                        <Col span={12}>
                            <Form
                                form={form}
                                layout='vertical'
                                id='viewTransactions'
                                initialValues={{ to_org_ppl: 'organisation' }}
                                onFinish={onFinish}
                            >
                                <Row gutter={24}>
                                    <Col style={{ width: '250px' }}>
                                        <InputComponent
                                            required
                                            type="select"
                                            name={'to_org_ppl'}
                                            label={'To Organisation/People'}
                                            filterOption={false}
                                            onChange={(e) => { handlChange(); setToType(e); SummaryReportStore.dropdown_ledger_org_list = null; SummaryReportStore.fetching = false; setOrgData(null) }}
                                            options={{
                                                values: [{ value: 'organisation', text: 'Organisation' }, { value: 'people', text: 'People' }]
                                            }}
                                        />
                                    </Col>
                                    {toType === 'organisation' ?
                                        <>
                                            <Col style={{ width: '660px' }}>
                                                <InputComponent
                                                    required
                                                    type="select"
                                                    label="Organisation Name"
                                                    name="organisation_name"
                                                    placeholder="Organisation Name"
                                                    onChange={handlChange}
                                                    options={{
                                                        values: SummaryReportStore.dropdown_ledger_org_list,
                                                        value_key: "id",
                                                        text_key: "entity_name",
                                                    }}
                                                    tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
                                                    filterOption={false}
                                                    notFoundContent={
                                                        SummaryReportStore.fetching ? (
                                                            <Spin size="small" />
                                                        ) : searchOrgKey ? (
                                                            "No Record Found!"
                                                        ) : null
                                                    }
                                                    onSearch={handleSearchOrg}
                                                />
                                            </Col>
                                            {orgData && <Col span={14}><CardComponent props={orgData} /></Col>}
                                        </>
                                        :
                                        <>
                                            <Col style={{ width: '660px' }}>
                                                <InputComponent
                                                    required
                                                    type="select"
                                                    label="People Name"
                                                    name="people_name"
                                                    placeholder="People Name"
                                                    notFoundContent={
                                                        SummaryReportStore.fetching ? (
                                                            <Spin size="small" />
                                                        ) : searchPplKey ? (
                                                            "No Record Found."
                                                        ) : null
                                                    }
                                                    onSearch={handleSearch}
                                                    onChange={handlChange}
                                                    options={{
                                                        values: SummaryReportStore.dropdown_ledger_org_list,
                                                        value_key: "id",
                                                        text_key: "entity_name",
                                                    }}
                                                    tooltip="A personal Name that is given to user/ person at birth or baptism and used along with family name."
                                                />
                                            </Col>
                                            {orgData && <Col span={14}><PeopleCardComponent props={orgData} /></Col>}
                                        </>
                                    }
                                </Row>
                            </Form>
                        </Col>
                    }
                </Row>
            </div>
        </Drawer>
    );
})

const CardComponent = ({ props }) => {
    return (
        <Card size="small" className="mb-20">
            <h3><b>{props.organisation_name} ({props.trade_name ? props.trade_name + ' - ' : ''}{props.global_countries ? props.global_countries.country_name : ''})</b></h3>
            <div className="ant-table-content ant-table-small ant-table">
                <table>
                    <tbody className="ant-table-tbody">
                        {(props.multiple_phone && props.multiple_phone.length) ? (
                            <tr>
                                <td style={{ width: '30px' }}><PhoneOutlined /></td>
                                <td>{props.multiple_phone.map(x => x.contact_value).join(', ')}</td>
                            </tr>
                        ) : null}

                        {props.website ? (
                            <tr>
                                <td><GlobalOutlined /></td>
                                <td>{props.website && props.website}</td>
                            </tr>
                        ) : null}
                        {props.addresses ? (
                            <tr>
                                <td><EnvironmentFilled /></td>
                                <td><div>
                                    {props.addresses.address_1}
                                    {props.addresses.address_2 && (', ' + props.addresses.address_2)}
                                    {props.addresses.address_3 && (', ' + props.addresses.address_3)}
                                    {props.addresses.city && (', ' + props.addresses.city)}
                                    {props.addresses.state_county && (', ' + props.addresses.state_county)}
                                    {props.addresses.countries && (', ' + props.addresses.countries.country_name)}
                                    {props.addresses.postal_code && (', ' + props.addresses.postal_code)}
                                </div>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

const PeopleCardComponent = ({ props }) => {
    return (
        <Card size="small" className="mb-20 mr-25">
            <h3><b>{props.first_name + ' ' + (props.last_name ? props.last_name : '')} ({props.title ? props.title + ' - ' : ''}{props.pps_number ? props.pps_number : ''})</b></h3>
            <div className="ant-table-content ant-table-small ant-table">
                <table>
                    <tbody className="ant-table-tbody">
                        {(props.phone && props.phone.length) ? (
                            <tr>
                                <td style={{ width: '30px' }}><PhoneOutlined /></td>
                                <td>{props.phone.map(x => x.contact_value).join(', ')}</td>
                            </tr>
                        ) : null}

                        {(props.email && props.email.length) ? (
                            <tr>
                                <td style={{ width: '30px' }}><PhoneOutlined /></td>
                                <td>{props.email.map(x => x.contact_value).join(', ')}</td>
                            </tr>
                        ) : null}

                        {props.website ? (
                            <tr>
                                <td><GlobalOutlined /></td>
                                <td>{props.website && props.website}</td>
                            </tr>
                        ) : null}
                        {props.single_address ? (
                            <tr>
                                <td><EnvironmentFilled /></td>
                                <td>
                                    <div>
                                        {props.single_address.address_1}
                                        {props.single_address.address_2 && (', ' + props.single_address.address_2)}
                                        {props.single_address.address_3 && (', ' + props.single_address.address_3)}
                                        {props.single_address.city && (', ' + props.single_address.city)}
                                        {props.single_address.state_county && (', ' + props.single_address.state_county)}
                                        {props.single_address.countries && (', ' + props.single_address.countries.country_name)}
                                        {props.single_address.postal_code && (', ' + props.single_address.postal_code)}
                                    </div>
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}

export default NotIdentifiedDetailComponent;