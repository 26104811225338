import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'
import { DeleteOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { CurrencyFormat, DateComparator } from '../../../../../utils/GlobalFunction'
import GridConfig from '../../../../../config/GridConfig'

const ListComponent = observer((props) => {
	const { openEditModal, openReverseModal, openDeleteModal } = props
	const { ManualJournalLogStore, ManualJournalLogStore: { setupGrid } } = useStore()

	const ActionRenderer = (prop) => {
		const { openEditModal, openReverseModal, openDeleteModal } = prop.agGridReact.props.frameworkComponents
		const { UserStore } = useStore()

		return (
			<div className="action-column">
				{UserStore.checkPermission(84, 'edit') && (
					<Button title="Edit Manual Journal Entry" type="text" onClick={() => { openEditModal(prop.data) }}><EditOutlined /></Button>
				)}
				{UserStore.checkPermission(84, 'delete') && (
					<Button title="Delete Manual Journal Entry" type="text" onClick={() => { openDeleteModal(prop.data) }}><DeleteOutlined /></Button>
				)}
				{UserStore.checkPermission(84, 'reverse') && (
					<Button title="Reverse Manual Journal Entry" type="text" onClick={() => { openReverseModal(prop.data) }}><HistoryOutlined /></Button>
				)}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			headerTooltip: '# ID',
			tooltipField: 'id',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'Model Code',
			headerTooltip: 'Model Code',
			tooltipField: 'model.model_code',
			field: 'model.model_code',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					if (ManualJournalLogStore.filter_model.length === 0) {
						ManualJournalLogStore.getFilter("model").then((data) => {
							params.success([null, ...data.data.map(x => x.model_code)]);
						})
					} else {
						params.success([null, ...ManualJournalLogStore.filter_model.map(x => x.model_code)]);
					}
				}
			},
			width: 150
		}, {
			headerName: 'Model',
			headerTooltip: 'Model',
			field: 'model.model_name',
			tooltipField: 'model.model_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					if (ManualJournalLogStore.filter_model.length === 0) {
						ManualJournalLogStore.getFilter("model").then((data) => {
							params.success([null, ...data.data.map(x => x.model_name)]);
						})
					} else {
						params.success([null, ...ManualJournalLogStore.filter_model.map(x => x.model_name)]);
					}
				}
			},
			width: 150
		}, {
			headerName: 'Journal Code',
			headerTooltip: 'Journal Code',
			field: 'journal.journal_code',
			tooltipField: 'journal.journal_code',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					if (ManualJournalLogStore.filter_journal.length === 0) {
						ManualJournalLogStore.getFilter("journal").then((data) => {
							params.success([null, ...data.data.map(x => x.journal_code)]);
						})
					} else {
						params.success([null, ...ManualJournalLogStore.filter_journal.map(x => x.journal_code)]);
					}
				}
			},
			width: 150
		}, {
			headerName: 'Journal',
			headerTooltip: 'Journal',
			field: 'journal.journal_label',
			tooltipField: 'journal.journal_label',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					if (ManualJournalLogStore.filter_journal.length === 0) {
						ManualJournalLogStore.getFilter("journal").then((data) => {
							params.success([null, ...data.data.map(x => x.journal_label)]);
						})
					} else {
						params.success([null, ...ManualJournalLogStore.filter_journal.map(x => x.journal_label)]);
					}
				}
			},
			width: 150
		}, {
			headerName: 'Transaction ID',
			headerTooltip: 'Transaction ID',
			tooltipField: 'transaction.id',
			field: 'transaction.id',
			filter: 'agNumberColumnFilter',
			width: 150
		}, {
			headerName: 'Trade Type',
			headerTooltip: 'Trade Type',
			field: 'transaction_type.type_name',
			tooltipField: 'transaction_type.type_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ManualJournalLogStore.getFilter("transaction").then((data) => {
						params.success([null, ...data.data.map(x => x.type_name)]);
					})
				}
			},
			width: 150
		}, {
			headerName: 'Debit',
			headerTooltip: 'Debit',
			field: 'debit_value',
			tooltipField: 'debit_value',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.debit_value ? data.data.debit_value : 0} />
			},
			width: 80
		}, {
			headerName: 'Credit',
			headerTooltip: 'Credit',
			field: 'credit_value',
			tooltipField: 'credit_value',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.credit_value ? data.data.credit_value : 0} />
			},
			width: 80
		}, {
			headerName: 'Created By',
			headerTooltip: 'Created By',
			field: "created_by",
			tooltipField: 'created_by',
			width: 185
		}, {
			headerName: 'Last Updated On',
			headerTooltip: 'Last Updated On',
			field: 'created_at',
			tooltipField: 'created_at',
			filter: 'agDateColumnFilter',
			width: 185,
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				suppressAndOrCondition: true,
				comparator: DateComparator
			}
		}, {
			headerName: 'Actions',
			headerTooltip: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 115
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('manual_journal_log_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openDeleteModal, openReverseModal, openEditModal
				}}
				onGridReady={setupGrid}
				onSwitchChange={ManualJournalLogStore.onSwitchChange}
				onColumnResized={ManualJournalLogStore.onGridChanged}
				onColumnMoved={ManualJournalLogStore.onGridChanged}
				onColumnPinned={ManualJournalLogStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent
