import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Col, Drawer, Form, Row, Spin, Tooltip } from 'antd';
import { AgGridReact as AgGridReactLeft } from '@ag-grid-community/react'
import { AgGridReact as AgGridReactRight } from '@ag-grid-community/react'
import { AllModules as AllModulesLeft } from "@ag-grid-enterprise/all-modules"
import { AllModules as AllModulesRight } from "@ag-grid-enterprise/all-modules"
import LeftLocalGridConfig from '../../../../config/LocalGridConfig';
import RitghLocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon, vsmCTEImport, vsmNotify } from '../../../../config/messages';
import { gridOptions as LeftGridOptinos } from '../Elements/LeftGridElement';
import { gridOptions as RightGridOptinos } from '../Elements/RightGridElement';
import EditComponent from '../../../CashTransaction/CashTransactions/components/EditComponent';
import useStore from '../../../../store';
import InputComponent from '../../../../components/InputComponent';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons'


const NotIdentifiedDetailComponent = observer(({ visible, close, mainBankAcc, week, setDatavalno, dataValno, setSavingvalue, txType, seletedYear }) => {
    const [form] = Form.useForm()
    const { BankAuditReportStore, UserStore, CTEImportStore } = useStore()
    const [disabled, setDisabled] = useState(true)
    const [fromDateRange, setFromDateRange] = useState([]);
    const [toDateRange, setToDateRange] = useState([]);
    const [transactionId, setTransactionId] = useState(null)
    const [saving, setSaving] = useState(false)
    const [leftLoading, setLeftLoading] = useState(false)
    const [rightLoading, setRightLoading] = useState(false)
    const [fetchBankId, setFetchBankId] = useState(true)
    const [openEditCashTransaction, setOpenEditCashTransaction] = useState(false)

    var leftRef = useRef();
    var rightRef = useRef();

    const handleBankAccId = (type) => {
        if (type === 'from') {
            setLeftLoading(true)
            let fromBank = form.getFieldValue('from_date_range')
            BankAuditReportStore.not_identified_data_from = []
            let payload = {
                mode_type: txType,
                bank_account_number: mainBankAcc,
                start_date: moment(fromBank[0]).format('YYYY-MM-DD'),
                end_date: moment(fromBank[1]).format('YYYY-MM-DD')
            }
            BankAuditReportStore.getNotIdentifiedLeftData(payload).then(() => {
                setLeftLoading(false)
            })
        }
        if (type === 'to') {
            setRightLoading(true)
            BankAuditReportStore.not_identified_data_to = []
            let bankID = form.getFieldValue('to_bank_id')
            let dateFilter = form.getFieldValue('to_date_range')
            let payload = {
                main_account_number: mainBankAcc,
                mode_type: txType,
                bank_account_number: bankID,
                start_date: moment(dateFilter[0]).format('YYYY-MM-DD'),
                end_date: moment(dateFilter[1]).format('YYYY-MM-DD')
            }
            BankAuditReportStore.getNotIdentifiedRightData(payload).then(() => {
                CTEImportStore.getBankListForNotIdentifiedData(BankAuditReportStore.account_number_array);
                setRightLoading(false)
            })
        }
    }

    // set id for edit / view drawer
    const setId = (id) => setTransactionId(id)

    const openEditModal = (data) => {
        setId(data.id)
        setOpenEditCashTransaction(true)
    }

    const closeEditModal = (ifDataUpdateee) => {
        CTEImportStore.bank_list = null
        setFetchBankId(false)
        setOpenEditCashTransaction(false)
        if (ifDataUpdateee) {
            setLeftLoading(true)
            setRightLoading(true)
            setDatavalno(true)
            handleBankAccId('from')
            handleBankAccId('to')
        }
    }

    const ActionRenderer = (prop) => {
        return prop.data && (
            <div className="action-column">
                {UserStore.checkPermission(76, 'edit') && (<Button title="Edit Cash Transaction" type="text" onClick={() => { openEditModal(prop.data) }}><EditOutlined /></Button>)}
            </div>
        )
    }

    const closeDrawer = (data) => {
        BankAuditReportStore.account_number_array = []
        form.resetFields(['from_date_range', 'to_date_range', 'to_bank_id'])
        setFetchBankId(true)
        if (dataValno || (data.from_accounts && data.to_accounts)) {
            // call api here 
            setSavingvalue(true)
            BankAuditReportStore.listData = null
            BankAuditReportStore.getList()
            setDatavalno(false)
        }
        setDisabled(true)
        setSaving(false)
        close()
    }

    const onRowSelected = () => {
        let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
        let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
        if ((tempLeftArray && tempLeftArray.length === 0) && (tempRightArray && tempRightArray.length === 0)) {
            setDisabled(true)
        } else if ((tempLeftArray && tempLeftArray.length) !== (tempRightArray && tempRightArray.length)) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    const handleChange = () => {
        form.validateFields().then((data) => {
            let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
            let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
            if ((tempLeftArray && tempLeftArray.length === 0) && (tempRightArray && tempRightArray.length === 0)) {
                setDisabled(true)
            } else if ((tempLeftArray && tempLeftArray.length) !== (tempRightArray && tempRightArray.length)) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const onFinish = (data) => {
        let tempLeftArray = leftRef.current && leftRef.current.api && leftRef.current.api.getSelectedNodes()
        let tempRightArray = rightRef.current && rightRef.current.api && rightRef.current.api.getSelectedNodes()
        let payload = {}
        payload.from_accounts = tempLeftArray.map((item) => {
            return item.data.id
        })
        payload.to_accounts = tempRightArray.map((item) => {
            return item.data.id
        })
        setSaving(true)
        BankAuditReportStore.manualVerification(payload).then(() => {
            setSaving(false)
            closeDrawer(payload)
            vsmNotify.success({
                message: vsmCTEImport.manualVerificationSuccess
            })
        }).catch(() => {
            setSaving(false)
        })
    }

    const fromDisabledDate = current => {
        if (!fromDateRange || fromDateRange.length === 0) { return false }
        return moment(current).format("Y-M-D") === moment(fromDateRange[0]).format("Y-M-D")
    }

    const toDisabledDate = current => {
        if (!toDateRange || toDateRange.length === 0) { return false }
        return moment(current).format("Y-M-D") === moment(toDateRange[0]).format("Y-M-D")
    }

    useEffect(() => {
        if (week && week !== 'Check') {
            let temp_weekNo = week.split('-')
            let weekNo = moment().week(Number(temp_weekNo[0])).year(temp_weekNo[1]);
            const from_date = weekNo.startOf('week').format('YYYY-MM-DD');
            const to_date = weekNo.endOf('week').format('YYYY-MM-DD');
            form.setFieldsValue({
                from_date_range: [moment(from_date), moment(to_date)],
                to_date_range: [moment(from_date), moment(to_date)]
            })
        } else {
            let startDate;
            let endDate;
            if (typeof seletedYear === 'string') {
                startDate = moment(seletedYear.split('-')[seletedYear.split('-').length - 1]).startOf('year').format('YYYY-MM-DD')
                endDate = moment(seletedYear.split('-')[seletedYear.split('-').length - 1]).endOf('year').format('YYYY-MM-DD')
            } else if (typeof seletedYear === 'object') {
                startDate = seletedYear.from_date
                endDate = seletedYear.to_date
            } else {
                startDate = "2010-01-01"
                endDate = moment(new Date()).format("YYYY-MM-DD")
            }
            form.setFieldsValue({
                from_date_range: (startDate && endDate) ? [moment(startDate), moment(endDate)] : null,
                to_date_range: (startDate && endDate) ? [moment(startDate), moment(endDate)] : null
            })
        }
        form.validateFields().then((data) => {
            if ((leftRef.current.api.getSelectedNodes() && leftRef.current.api.getSelectedNodes().length === 0) && (rightRef.current.api.getSelectedNodes() && rightRef.current.api.getSelectedNodes().length === 0)) {
                setDisabled(true)
            } else if ((leftRef.current.api.getSelectedNodes() && leftRef.current.api.getSelectedNodes().length) !== (rightRef.current.api.getSelectedNodes() && rightRef.current.api.getSelectedNodes().length)) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }, [form, leftRef, rightRef, mainBankAcc, week, visible, seletedYear])

    return (
        <Drawer
            className='reduce-space remove-error-text'
            visible={visible}
            onClose={closeDrawer}
            title={
                <>
                    <h1 style={{ fontSize: '18px', fontWeight: '400', margin: '0px', padding: '0px' }}>Compare Banks</h1>
                    <p style={{ fontSize: '12px', fontWeight: '700', padding: '0px', margin: '0px' }}>{mainBankAcc}</p>
                </>
            }
            placement='right'
            width={'100%'}
            destroyOnClose={true}
            footer={[
                <div className="text-center" key='1'>
                    {disabled ? (
                        <Tooltip color='red' title='Atleast one and same number of transactions are selected both side.'>
                            <Button type='primary' disabled={disabled}>Verify</Button>
                        </Tooltip>
                    ) : (
                        <Button type='primary' htmlType='submit' form='viewTransactions' loading={saving}>Verify</Button>
                    )}

                </div>
            ]}
            extra={[
                <div className='tempDiv' style={{ width: '1680px' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        id='viewTransactions'
                        onFinish={onFinish}
                    >
                        <Row gutter={6}>
                            <Col span={8} >
                                <InputComponent
                                    required={week !== 'Check'} label="Date Range" picker={"week"} format='YYYY-MM-DD'
                                    type="date_range" name="from_date_range" onChange={(e) => { handleBankAccId('from'); handleChange() }}
                                    tooltip="Used to fetch the reports between specific date range."
                                    disabledDate={fromDisabledDate} allowClear={false}
                                    rules={week !== 'Check' ? [{ required: true, message: 'Select Date Range.' }] : null}
                                    onCalendarChange={val => setFromDateRange(val)}
                                />
                            </Col>
                            <Col span={5} style={{ marginLeft: '165px', paddingLeft: '40px' }}>
                                <InputComponent
                                    placeholder="Select Bank Account" type="select" allowClear label='To Bank Account' name='to_bank_id'
                                    options={{
                                        values: CTEImportStore.bank_list,
                                        text_key: "bankAccount_names",
                                        value_key: "account_number",
                                    }}
                                    onChange={(e) => { handleBankAccId('to'); handleChange() }}
                                    tooltip="A name or code given to a cash account that indicates the account's purpose."
                                    maxLength={100} autoComplete="off"
                                    onFocus={() => fetchBankId && CTEImportStore.getBankListForNotIdentifiedData(BankAuditReportStore.account_number_array).then(() => setFetchBankId(false))}
                                    notFoundContent={fetchBankId ? <Spin size="small" /> : "No Record Found."}
                                />
                            </Col>
                            <Col span={8} style={{ paddingRight: '0px', paddingLeft: '12px' }}>
                                <InputComponent
                                    required={week !== 'Check'} label="Date Range" picker={"week"} format='YYYY-MM-DD'
                                    type="date_range" name="to_date_range" onChange={(e) => { handleBankAccId('to'); handleChange() }}
                                    tooltip="Used to fetch the reports between specific date range."
                                    disabledDate={toDisabledDate} allowClear={false}
                                    rules={week !== 'Check' ? [{ required: true, message: 'Select Date Range.' }] : null}
                                    onCalendarChange={val => setToDateRange(val)}
                                />

                            </Col>
                        </Row>
                    </Form>
                </div>
            ]}
        >
            <div style={{ height: '100%' }}>
                <Row gutter={15} style={{ height: '100%' }}>
                    <Col span={12}>
                        {leftLoading ? <Spin size="large" spinning={leftLoading} tip={"Loading"}></Spin> :
                            <div className="ag-theme-alpine grid_wrapper">
                                <AgGridReactLeft
                                    ref={leftRef}
                                    rowData={BankAuditReportStore.not_identified_data_from}
                                    modules={AllModulesLeft}
                                    columnDefs={LeftGridOptinos.columnDefs}
                                    defaultColDef={LeftLocalGridConfig.defaultColDef}
                                    columnTypes={LeftLocalGridConfig.columnTypes}
                                    overlayNoRowsTemplate={vsmCommon.noRecord}
                                    onGridReady={BankAuditReportStore.setupGridNotIdentifiedLeft}
                                    gridOptions={LeftLocalGridConfig.options}
                                    frameworkComponents={{ ActionRenderer }}
                                    // onFilterChanged={BankAuditReportStore.onGridChangedLeft}
                                    suppressRowClickSelection={true}
                                    rowSelection={'multiple'}
                                    onRowSelected={onRowSelected}
                                />
                            </div>
                        }
                    </Col>

                    <Col span={12}>
                        {rightLoading ? <Spin size="large" spinning={rightLoading} tip={"Loading"}></Spin> :
                            <div className="ag-theme-alpine grid_wrapper">
                                <AgGridReactRight
                                    ref={rightRef}
                                    rowData={BankAuditReportStore.not_identified_data_to}
                                    modules={AllModulesRight}
                                    columnDefs={RightGridOptinos.columnDefs}
                                    defaultColDef={RitghLocalGridConfig.defaultColDef}
                                    frameworkComponents={{ ActionRenderer }}
                                    columnTypes={RitghLocalGridConfig.columnTypes}
                                    overlayNoRowsTemplate={vsmCommon.noRecord}
                                    onGridReady={BankAuditReportStore.setupGridNotIdentifiedRight}
                                    gridOptions={RitghLocalGridConfig.options}
                                    // onFilterChanged={BankAuditReportStore.onGridChangedRight}
                                    suppressRowClickSelection={true}
                                    rowSelection={'multiple'}
                                    onRowSelected={onRowSelected}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            {openEditCashTransaction && <EditComponent transactionId={transactionId} setId={setId} visible={openEditCashTransaction} close={closeEditModal} tab={'notid'} />}
        </Drawer>
    );
})

export default NotIdentifiedDetailComponent;