import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'

const ListComponent = observer((props) => {
	const { ReconciliationStore, ReconciliationStore: { suggestion_list, setupGrid2Inner, onFilterChanged } } = useStore()

	return (
		<div className="" style={{ height: '70vh', marginBottom: 24 }}>
			<div className="ag-theme-alpine grid_wrapper">
				<AgGridReact
					rowData={suggestion_list}
					modules={AllModules}
					columnDefs={gridOptions.columnDefs}
					defaultColDef={LocalGridConfig.defaultColDef}
					columnTypes={LocalGridConfig.columnTypes}
					overlayNoRowsTemplate={vsmCommon.noRecord}
					onColumnResized={ReconciliationStore.onChildnGridChanged}
					onColumnMoved={ReconciliationStore.onChildnGridChanged}
					onColumnPinned={ReconciliationStore.onChildnGridChanged}
					onSortChanged={ReconciliationStore.onChildnGridChanged}
					onGridReady={setupGrid2Inner}
					gridOptions={LocalGridConfig.options}
					onFilterChanged={onFilterChanged}
					pagination={false}
					rowSelection={'single'}
					onSelectionChanged={
						function (event) {
							let selected = event.api.getSelectedRows()
							if (selected.length > 0) {
								props.setDisabled(false)
							} else { props.setDisabled(true) }
							return true
						}}
				/>
			</div>
		</div>
	)
})

export default ListComponent
