import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmPeople, vsmNotify } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleStore } = useStore()
	const [saving, setSaving] = useState(false)

	const close = () => {
		props.close()
		form.resetFields()
		setSaving(false)
	}

	// Make function call to delete existing record
	const handleSubmit = () => {
		setSaving(true)
		PeopleStore.DeleteData(PeopleStore.deleteValues.id).then(() => {
			close()
			vsmNotify.success({
				message: vsmPeople.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	return PeopleStore.deleteValues ? (
		<Modal
			centered
			title={`Delete Import - #${PeopleStore.deleteValues.id} - ${PeopleStore.deleteValues.people_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger >Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to delete the people?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
