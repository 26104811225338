import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Card, Button, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import useStore from '../store'

const ActiveUserWidget = observer(() => {
	const { DashboardStore: { getUserCount, count_activeuser, setDaysUserCount, days_activeuser } } = useStore()

	// get count of active users
	useEffect(() => {
		getUserCount()
	}, [getUserCount])

	return (
		<Card className="widget_wrapper">
			<div className="counter__widget">
				<div className="counter__widget__icon"><Avatar size="large" icon={<UserOutlined />} /></div>
				<div className="counter__widget__description">
					<div className="counter__widget__name">ACTIVE USERS</div>
					<div className="counter__widget__counts">{count_activeuser}</div>
				</div>
			</div>
			<div className="counter__widget__footer">
				<div className="counter__widget__buttons">
					<Button onClick={() => setDaysUserCount(7)} type="link" size="small" className={"counter__widget__button " + (days_activeuser === 7 ? "cs_active" : "")}>7 Days</Button>
					<Button onClick={() => setDaysUserCount(30)} type="link" size="small" className={"counter__widget__button " + (days_activeuser === 30 ? "cs_active" : "")}>30 Days</Button>
					<Button onClick={() => setDaysUserCount(365)} type="link" size="small" className={"counter__widget__button " + (days_activeuser === 365 ? "cs_active" : "")}>12 Months</Button>
				</div>
			</div>
		</Card>
	)
})

export default ActiveUserWidget
