import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Divider, Space, Row, Col } from 'antd'
import useStore from '../../../../../store'
import { observer } from 'mobx-react'
import { vsmNotify, vsmJournals } from '../../../../../config/messages'
import moment from 'moment';
import InputComponent from '../../../../../components/InputComponent'

const currentDate = new Date()
const lastMonthDate = new Date()
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { JournalsStore, JournalGroupStore, TaxSettingStore } = useStore()
	const store = {
		Journals: JournalsStore,
		JournalGroup: JournalGroupStore,
		TaxSetting: TaxSettingStore
	}
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(false)
	const [isDate, setDate] = useState(false)
	const [isNonEditable, setNonEditable] = useState(true)
	const [isNewFields, setNewFields] = useState(false)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = store.Journals.editValues.id
		data.journal_type = form.getFieldValue('journal_group_id') && store.JournalGroup.dropdown_list.find(item => item.id === form.getFieldValue('journal_group_id')).journal_type
		delete data["confirm"]
		store.Journals.EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournals.edit
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		setNonEditable(true)
		setDate(false)
		setNewFields(false)
		setDisabled(true)
		if (store.Journals.editValues) {
			form.setFieldsValue({
				id: store.Journals.editValues.id,
				journal_group_id: store.Journals.editValues.journal_group_id,
				journal_label: store.Journals.editValues.journal_label,
				journal_code: store.Journals.editValues.journal_code,
				journal_desc: store.Journals.editValues.journal_desc,
				tax_setting_id: store.Journals.editValues.tax_setting_id,
			})
		}
	}, [store.Journals.editValues, form, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			var openNewField = form.getFieldsValue().tax_setting_id !== store.Journals.editValues.tax_setting_id
			if (form.getFieldsValue().tax_setting_id !== store.Journals.editValues.tax_setting_id) {
				setNewFields(true)
			}
			if (openNewField) {
				if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
					setDisabled(false)
				} else {
					setDisabled(true)
				}
			} else {
				setDisabled(false)
			}
		}).catch(d => {
			if (d.errorFields && d.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(false)
		props.close()
	}

	const handleDatePicker = (event) => {
		if (event.target.value === "data_in_specific_date_range") {
			setDate(true)
		} else {
			setDate(false)
		}
	}

	const editAnyway = () => {
		setNonEditable(false)
	}

	const showAdditonalConfiguration = () => {
		handleChange()
		if (!isNonEditable) {
			setNewFields(true)
		}
	}

	return store.Journals.editValues ? (
		<Modal
			centered
			title={`Edit Journal - #${store.Journals.editValues.id} - ${store.Journals.editValues.journal_label}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={650}
		>
			<Form
				form={form}
				id="editform"
				onFinish={handleSubmit}
				layout='vertical'
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required type="select" label="Journal Group" name="journal_group_id" placeholder="Select Journal Group"
							tooltip="Journal Groups is a library of custom-defined groups of Journals , example: Assets , Liabilities."
							rules={vsmJournals.validation.journal_groups}
							onChange={handleChange}
							options={{
								values: store.JournalGroup.dropdown_list,
								value_key: 'id',
								text_key: { key: ["journal_group_name", " - ", "journal_type"] }
							}}
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required
							label="Code"
							name="journal_code"
							placeholder="Code"
							tooltip="Code (of a Journal) is a mandatory parameter of a Journal."
							rules={vsmJournals.validation.journal_code}
							onChange={handleChange}
							maxLength={10}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required
							label="Label"
							name="journal_label"
							placeholder="label"
							tooltip="A Journal is an account against which Journal Entries are stored, e.g. in accounts this would be one nominal account in the chart of accounts."
							rules={vsmJournals.validation.journal_label}
							onChange={handleChange}
							maxLength={100}
						/>
					</Col>
				</Row>



				<InputComponent
					required
					type="textarea"
					label="Description"
					name="journal_desc"
					placeholder="Description"
					tooltip="It is used to further describe the Journal details and its purpose."
					rules={vsmJournals.validation.journal_desc}
					onChange={handleChange}
					maxLength={1000}
					autoSize={{ minRows: 3 }}
				/>

				<InputComponent
					required type="select" label="Tax Setting" name="tax_setting_id" placeholder="Select Tax Settings"
					tooltip="Taxes are defined based on Government regulations in your country and are linked with Journals, i.e. Sales Vat, Tax on Goods, No Tax."
					rules={vsmJournals.validation.tax_settings_id}
					onChange={showAdditonalConfiguration}
					options={{
						values: store.TaxSetting.dropdown_list,
						value_key: 'id',
						text_key: { key: ["tax_name", " - ", "tax_value", "%"] }
					}}
					disabled={isNonEditable}
					note={[
						<div key="1">If you edit Tax Value, you will need to select some additional configuration too. <Button type="link" onClick={editAnyway} style={{ padding: 0, height: "auto" }}>Click here</Button> to edit it anyway.</div>
					]}
				/>

				{(isNewFields) ? (
					<>
						<Divider />
						<Space direction="vertical">
							<label> New Tax Value will applied on : </label>
							<InputComponent
								type="radio_button"
								name="change_to_new_data"
								initialValue="new_data_only"
								onChange={handleDatePicker}
								options={{
									values: [
										{ value: 'all_past_data', text: 'Apply to all past data' },
										{ value: 'data_in_specific_date_range', text: 'Apply to data in specific date range' },
										{ value: 'new_data_only', text: 'Apply to New Data only' },
									]
								}}
							/>
						</Space>

						{(isDate) ? (
							<InputComponent
								type="date_range"
								name="date_range"
								initialValue={[moment(lastMonthDate), moment(currentDate)]}
								onChange={handleChange}
								className="ml-auto mr-auto" allowClear={false}
							/>
						) : (
							null
						)}
						<InputComponent
							name="confirm"
							placeholder="Type 'CONFIRM' as this action cannot be undone."
						/>
					</>
				) : (
					null
				)}
			</Form>
		</Modal >
	) : null
})

export default EditComponent
