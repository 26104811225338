import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal } = props
	const { PeopleRolesStore } = useStore()

	useEffect(() => {
		PeopleRolesStore.getList()
	}, [PeopleRolesStore])

	var column = gridOptions.columnDefs
	let columns = []
	let columnConfig = localStorage.getItem('ppl_role_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = column.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = column.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = column
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={PeopleRolesStore.list_data}
				modules={AllModules}
				columnDefs={column}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openDeleteModal
				}}
				onGridReady={PeopleRolesStore.setupGrid}
				onColumnResized={PeopleRolesStore.onGridChanged}
				onColumnMoved={PeopleRolesStore.onGridChanged}
				onColumnPinned={PeopleRolesStore.onGridChanged}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={PeopleRolesStore.onFilterChanged}
				onSortChanged={PeopleRolesStore.onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
