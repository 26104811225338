import React, { useCallback, useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Form, PageHeader } from 'antd';
import useStore from '../../../../store';
import { useParams } from 'react-router';
import ContentLoader from '../../../../components/ContentLoader';
import PageNotFound from '../../../PageNotFound';
import Unauthorized from '../../../Unauthorized';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig';
import ViewElement from '../elements/ViewElement';
import { globalStatus } from '../../../../utils/GlobalFunction';

const ViewOrganisation = observer((props) => {
	const [form] = Form.useForm()
	const { id } = useParams()
	const { PeopleStore, OrganisationStore } = useStore()
	const [loading, setLoading] = useState('pending')

	const handleViewDetailCall = useCallback(() => {
		setLoading('pending')
		OrganisationStore.organisationValues = null
		OrganisationStore.viewOrgValues = null
		OrganisationStore.getViewOrgDetail(id).then(data => {
			if (data.cash_transactions.length) {
				data.cash_transactions.forEach((item) => {
					item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
				});
			}
			OrganisationStore.viewOrgValues = data
			PeopleStore.list_related_ORG = data.related_organisations
			PeopleStore.list_related_Ppl = data.related_peoples
			PeopleStore.list_trade_transaction = data.trade_transactions
			PeopleStore.list_cash_transaction = data.cash_transactions
			PeopleStore.list_banks = data.banks
			setLoading(200)
		}).catch(data => {
			if (data.status === 404) {
				setLoading(404)
			}
			if (data.status === 403) {
				setLoading(403)
			}
		})
	}, [PeopleStore, OrganisationStore, id])

	useEffect(() => {
		handleViewDetailCall()
	}, [handleViewDetailCall])

	if (loading === 'pending') {
		return <ContentLoader />
	}
	if (loading === 200) {
		return OrganisationStore.viewOrgValues ? (
			<>
				<BreadcrumbComponent items={[...BreadcrumbConfig.view_Organisations.path, { name: `${OrganisationStore.viewOrgValues.organisation_name}` }]} />
				<PageHeader
					title={`View Organisation - #${OrganisationStore.viewOrgValues.id} - ${OrganisationStore.viewOrgValues.organisation_name}`}
					onBack={() => window.history.back()}
					extra={[
						<div className="d-flex mt-10">
							<span>Total Transaction Value: <b>{OrganisationStore.viewOrgValues.transactions_total}</b></span>
							<div className="ml-10 mr-10">|</div>
							<span>Total Transactions: <b>{OrganisationStore.viewOrgValues.transactions_count}</b></span>
						</div>
					]}
				>
					<ViewElement
						form={form}
						id={id}
						handleViewDetailCall={handleViewDetailCall}
					/>
				</PageHeader>
			</>
		) : null
	} else {
		if (loading === 404) {
			return (
				<PageNotFound />
			)
		}
		if (loading === 403) {
			return (
				<Unauthorized />
			)
		}

	}
})

export default ViewOrganisation
