import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './components/ListComponent'
import EditComponent from './components/EditComponent'
import DeleteComponent from './components/DeleteComponent'
import AddComponent from './components/AddComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import Unauthorized from '../../../Unauthorized'
import ReverseComponent from './components/ReverseComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const ManualJournalLog = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [reverseModal, setReverseModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { UserStore, ManualJournalLogStore } = useStore()

	// Open form for add manual journal entry
	const openAddModal = () => setAddModal(true)

	// Close form for add manual journal entry
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing manual journal entry and set values to form
	const openEditModal = (data) => {
		ManualJournalLogStore.setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit manual journal entry
	const closeEditModal = () => setEditModal(false)

	// Open form for reverse existing manual journal entry and set values to form
	const openReverseModal = (data) => {
		ManualJournalLogStore.setReverseValues(data)
		setReverseModal(true)
	}

	// Open form for reverse manual journal entry
	const closeReverseModal = () => setReverseModal(false)

	// Open confirmation alert before deleting existing manual journal entry
	const openDeleteModal = (data) => {
		ManualJournalLogStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('manual_journal_log_grid')
		ManualJournalLogStore.agGrid.api.setFilterModel(null)
		ManualJournalLogStore.agGrid.api.setSortModel(null)
		ManualJournalLogStore.agGrid.columnApi.resetColumnState()
		ManualJournalLogStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Manual_Journal_Log.path} />
			{(!UserStore.checkPermission(84, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Manual_Journal_Log.title}
					extra={[
						(UserStore.checkPermission(84, 'add')) && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={ManualJournalLogStore.per_page + ' per page'} onChange={ManualJournalLogStore.setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openReverseModal={openReverseModal} openDeleteModal={openDeleteModal} />
					<AddComponent visible={addModal} close={closeAddModal} handleApiCall={props.handleApiCall} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<ReverseComponent visible={reverseModal} close={closeReverseModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
				</PageHeader>
			)
			}
		</>
	)
})

export default ManualJournalLog
