import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import { Button, Col, Form, PageHeader, Row } from 'antd';
import useStore from '../../../store';
import moment from 'moment';
import InputComponent from '../../../components/InputComponent';
import DateSelectionElement from '../SavedReports/elements/DateSelectionElement';
import { vsmNotify, vsmTransactionReport } from '../../../config/messages';
import PreviewAETRDetailsComponent from './components/PreviewAETRDetailsComponent';
import PreviewAETRChildStatementComponent from './components/PreviewAETRChildStatementComponent';
import { TableOutlined } from '@ant-design/icons'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddComponent from './components/AddTransaction';

const aETRReports = observer(() => {
    const [form] = Form.useForm()
    const { UserStore, aETRReportsStore, AllTransactionStore } = useStore()
    const [formDataList, setFormDataList] = useState([])
    const [saving, setSaving] = useState(true)
    const [savingChild, setSavingChild] = useState(false)
    const [openStatementDrawer, setOpenStatementDrawer] = useState(false)
    const [org_ID, setOrg_ID] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    const [DateRange, SetDateRange] = useState([]);
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)
    const [ORGANISATION, SETORGANISATION] = useState(false)
    const [PreviewReport, setPreviewReport] = useState(null)
    const [PreviewChildReport, setPreviewChildReport] = useState(null)
    const [buttonType, setButtonType] = useState(null)
    const [type, setType] = useState(null)
    const [addDrawer, setAddDrawer] = useState(false)
    const typeChange = ['organisations', 'batches', 'generate_on', 'audit_group_on']
    const [organisations, setOrganisations] = useState(null)

    const getFormDataList = useCallback(() => {
        var FormDataList = []
        FormDataList = [...FormDataList, ...typeChange]
        if (!ORGANISATION) {
            SETORGANISATION(true)
            aETRReportsStore.getOrgList()
        }
        switch (form.getFieldValue('generate_on')) {
            case 'yesterday':
                FormDataList = [...FormDataList];
                break;
            case 'last_week':
                FormDataList = [...FormDataList];
                break;
            case 'this_month':
                FormDataList = [...FormDataList];
                break;
            case 'this_quarter':
                FormDataList = [...FormDataList];
                break;
            case 'this_year':
                FormDataList = [...FormDataList];
                break;
            case 'date_range':
                FormDataList = [...FormDataList, 'date_range'];
                break;
            case 'single':
                FormDataList = [...FormDataList, 'single_date'];
                if (!form.getFieldValue('date')) {
                    form.setFieldsValue({ date: moment(new Date(), 'YYYY-MM-DD') });
                }
                break;
            case 'this':
                FormDataList = [...FormDataList, 'date_x_value_type'];
                break;
            case 'last':
                FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type'];
                if (!form.getFieldValue('date_x_value')) {
                    form.setFieldsValue({ date_x_value: 1 });
                }
                break;
            case 'financial_year':
                FormDataList = [...FormDataList, 'financial_year']
                if (!GENERATEONFINANCIAL) {
                    SETGENERATEONFINANCIAL(true)
                    aETRReportsStore.getFinancialYearList()
                }
                break;
            // case 'periods':
            //     FormDataList = [...FormDataList, 'period_selection'];
            //     switch (form.getFieldValue('period_selection')) {
            //         case 'custom':
            //             FormDataList = [...FormDataList, 'period_date_range'];
            //             break;
            //         case 'auto':
            //             FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type'];
            //             break;
            //         default:
            //     }
            //     break;
            default:
        }
        return FormDataList
    }, [GENERATEONFINANCIAL, aETRReportsStore, form, typeChange, ORGANISATION])

    const onFinish = (data, newButtonType) => {
        if (data.date) {
            data.single_date = moment(data.date).format('YYYY-MM-DD')
        }
        if (data.single_date) {
            data.single_date = data.single_date.format('YYYY-MM-DD')
        }
        if (data.date_range && data.date_range.length) {
            var payload = {
                from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
                to_date: moment(data.date_range[1]).format('YYYY-MM-DD')
            }
            data.date_range = payload
        }

        aETRReportsStore.formData = data
        if (!newButtonType && buttonType === 2) {
            openStateMentDetails(data)
        } else {
            setPreviewReport(false)
            setSaving(true)
            aETRReportsStore.getaETRPreviewDetails(data).then(({ data }) => {
                data.forEach((item) => {
                    item.etr_type = (item.etr_type === 1 || item.etr_type === 'fixed') ? 'a-ETR' : 'f-ETR'
                })
                setPreviewReport(data)
                setSaving(false)
            }).catch(() => {
                setSaving(false)
            })
        }
    }

    const handleChange = async () => {
        await setFormDataList(getFormDataList())
        form.validateFields().then((data) => {
            let newORG = data.organisations
            if (newORG && (organisations !== newORG)) {
                setOrganisations(newORG)
                onFinish(data, 1)
            }
            if (!newORG) {
                onFinish(data, 1)
            }
            setDisabled(false)
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const disabledDate = current => {
        if (!DateRange || DateRange.length === 0) { return false }
        return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
    }

    const handleOrgSearch = (orgID) => {
        if (orgID) {
            setOrg_ID(orgID)
            aETRReportsStore.getBatchListFromOrg(orgID)
        } else {
            form.resetFields(['batches'])
            setOrg_ID(false)
        }
    }

    // open drawer for add
    const openAddDrawer = () => {
        setAddDrawer(true)
        AllTransactionStore.mapping_dropdown = [{ id: 1, mapping_name: "a-ETR Trade", status: true, has_items: true }]
    }

    // close drawer for add
    const closeAddDrawer = (data) => {
        // form.getFieldsValue(['organisations', 'batches', 'generate_on'])
        if (data.loadAPI) {
            setSaving(true)
            setPreviewReport(false)
            let payload = form.getFieldsValue()
            aETRReportsStore.getaETRPreviewDetails(payload).then(({ data }) => {
                data.forEach((item) => {
                    item.etr_type = (item.etr_type === 1 || item.etr_type === 'fixed') ? 'a-ETR' : 'f-ETR'
                })
                setPreviewReport(data)
                setSaving(false)
            }).catch(() => {
                setSaving(false)
            })
        }
        setAddDrawer(false)
        AllTransactionStore.mapping_dropdown = null
    }

    const openStateMentDetails = (data) => {
        setSavingChild(true)
        aETRReportsStore.getaETRChildDetails(data).then((data) => {
            setSavingChild(false)
            setPreviewChildReport(data)
        }).catch(() => {
            setSavingChild(false)
        })
        setOpenStatementDrawer(true)
    }

    const closeStateMentDetails = () => {
        setPreviewChildReport(null)
        setOpenStatementDrawer(false)
        setType('Inmain')
    }

    const setColumnSetting = (params) => {
        let payload = {
            request_type: "id",
            id: aETRReportsStore.setting_value.id,
            table_grid: (params === 'undo') ? null : JSON.stringify(aETRReportsStore.agGrid.columnApi.getColumnState())
        }
        aETRReportsStore.columnsConfig(payload).then(() => {
            if (params === 'undo') {
                localStorage.removeItem('aETR_report_grid')
                aETRReportsStore.agGrid.api.setFilterModel(null)
                aETRReportsStore.agGrid.api.setSortModel(null)
                aETRReportsStore.agGrid.columnApi.resetColumnState()
                aETRReportsStore.agGrid.api.onFilterChanged(null)
                vsmNotify.success({
                    message: "Grid reset successfully."
                })
            } else {
                vsmNotify.success({
                    message: "Grid updated successfully."
                })
            }
        })
    }

    useEffect(() => {
        var data = getFormDataList()
        if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
            setFormDataList(data)
        }
    }, [getFormDataList, setFormDataList, formDataList])

    useEffect(() => {
        let payload = { generate_on: "all_unarchive_data" }
        aETRReportsStore.getaETRPreviewDetails(payload).then(({ data }) => {
            data.forEach((item) => {
                item.etr_type = (item.etr_type === 1 || item.etr_type === 'fixed') ? 'a-ETR' : 'f-ETR'
            })
            setPreviewReport(data)
            setSaving(false)
        }).catch(() => {
            setSaving(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.a_ETRReports.path} />
            <PageHeader
                className={UserStore.fullscreen_class + 'a_ETR_CSS'}
                title={BreadcrumbConfig.a_ETRReports.title}
                extra={[
                    <>
                        <Button key="1" type="primary" onClick={openAddDrawer}>Add Transaction</Button>
                        <Button key="2" title="Save Column Settings" type="primary" onClick={() => setColumnSetting('save')} disabled={!PreviewReport}><TableOutlined /></Button>
                        <Button key="3" title="Reset" type="primary" onClick={() => setColumnSetting('undo')} disabled={!PreviewReport}><FontAwesomeIcon icon={faUndo} /></Button>
                    </>
                ]}
            >
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={aETRReportsStore.initialValues}
                    onFinish={onFinish}
                    id='previewAETR'
                >
                    <Row gutter={12}>
                        {formDataList.includes('organisations') && (
                            <Col span={4}>
                                <InputComponent
                                    label="Organisation/s"
                                    type="select"
                                    allowClear
                                    name="organisations"
                                    placeholder="All"
                                    onChange={(e) => { handleOrgSearch(e); handleChange(); form.resetFields(['batches']) }}
                                    tooltip="An Organisation refers to the particular firm, institution or company to fetch their reports."
                                    options={{
                                        values: aETRReportsStore.org_list,
                                        value_key: "org_id",
                                        text_key: "org_name",
                                    }}
                                />
                            </Col>
                        )}

                        {formDataList.includes('batches') && (
                            <Col span={2}>
                                <InputComponent
                                    label="Batch"
                                    type="select"
                                    allowClear
                                    name="batches"
                                    placeholder="All"
                                    onChange={handleChange}
                                    tooltip="An Organisation refers to the particular firm, institution or company to fetch their reports."
                                    options={{
                                        values: aETRReportsStore.batch_list,
                                        value_key: "id",
                                        text_key: "value",
                                    }}
                                    disabled={!org_ID}
                                />
                            </Col>
                        )}

                        {formDataList.includes('generate_on') && (
                            <Col /* span={9} */>
                                <InputComponent
                                    required
                                    type="radio_button"
                                    label="Generate On"
                                    name="generate_on"
                                    onChange={handleChange}
                                    options={{
                                        values: [
                                            { value: 'all_unarchive_data', text: 'All' },
                                            { value: 'today', text: 'Today' },
                                            { value: 'yesterday', text: 'Yesterday' },
                                            { value: 'single', text: 'Date' },
                                            { value: 'this', text: 'This' },
                                            { value: 'last', text: 'Last' },
                                            { value: 'financial_year', text: 'Financial Year' },
                                            { value: 'date_range', text: 'Date Range' },
                                            { value: 'periods', text: 'Period' }
                                        ],
                                        rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
                                    }}
                                    tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
                                    rules={vsmTransactionReport.validation.generate_on}
                                />
                            </Col>
                        )}

                        <Col span={form.getFieldValue('generate_on') === 'last' && 6}>
                            <DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} />
                        </Col>

                        <Col>
                            <Button form='previewAETR' className="mt-25" htmlType="submit" type="primary" disabled={isDisabled} loading={saving} onClick={() => { setButtonType(1) }}>Preview</Button>
                        </Col>

                        {org_ID && <Col><Button form='previewAETR' className="mt-25" type="primary" htmlType="submit" disabled={isDisabled} onClick={() => { setButtonType(2) }} loading={savingChild}>Statement</Button></Col>}

                    </Row>

                </Form>
                {PreviewReport ? <PreviewAETRDetailsComponent rowData={PreviewReport} org_ID={org_ID} type={type} /> : null}
                {PreviewChildReport ? <PreviewAETRChildStatementComponent visible={openStatementDrawer} close={closeStateMentDetails} rowData={PreviewChildReport} /> : null}
                {addDrawer && <AddComponent visible={addDrawer} close={closeAddDrawer} entity_type={1} />}
            </PageHeader>
        </>
    );
})

export default aETRReports;