import React, { useState } from 'react'
import { Card, Form, Input, Button } from 'antd'
import useStore from '../store'
import { vsmAuth, vsmNotify } from '../config/messages'
import { Link, useHistory } from 'react-router-dom'

const ForgetPassword = () => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const { UserStore: { sendForgotPasswordLink } } = useStore()
	let history = useHistory()

	// make function call to send link to email
	const sendLink = (data) => {
		setLoading(true)
		sendForgotPasswordLink(data).then(() => {
			history.push('/')
			vsmNotify.success({
				message: vsmAuth.forgot_success
			})
		}).catch(errors => {
			form.setFields(errors.errors)
			setLoading(false)
		})
	}

	return (
		<div className="login__wrapper">
			<Form form={form} onFinish={sendLink}>
				<Card title="Forget Password" className="w400">
					<Form.Item name="email" rules={vsmAuth.validation.email} hasFeedback>
						<Input placeholder="Email Address" />
					</Form.Item>
					<div className="text-center">
						<Button loading={loading} htmlType="submit" block type="primary">Reset Password</Button>
					</div>
					<div className="d-flex justify-content-end">
						<Link to="/" type="link" className="p-0 mt-10"><b>Back to login</b></Link>
					</div>
				</Card>
			</Form>
		</div>
	)
}

export default ForgetPassword
