import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { Form, Button, Input, Select, Checkbox, Row, Col, Card, Spin, Drawer } from 'antd'
import ResetPasswordComponent from './ResetPasswordComponent'
import useStore from '../../../../store'
import { vsmNotify, vsmUserManagement } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { UserManagementStore, UserStore } = useStore()
	const Store = {
		UserManagement: UserManagementStore,
		User: UserStore
	}
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [resetPass, setResetPass] = useState(false)
	const [hidePermissionTable, setHidePermissionTable] = useState(true)
	const [enablePermission, setEnablePermission] = useState(true)
	const [SelectedLedger, setSelectedLedger] = useState(null)
	const [fetchUserGroup, setFetchUserGroup] = useState(true)
	const [dealMakerGroup, setDealMakerGroup] = useState(false)

	//Open confirmation alert before reseting the password of existing user
	const openResetPass = () => {
		Store.UserManagement.setResetPassword(Store.UserManagement.editValues)
		setResetPass(true)
	}

	// Close confirmation alert for reseting password record
	const closeResetPass = () => setResetPass(false)

	// reset form and close edit form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setHidePermissionTable(false)
		setEnablePermission(true)
		props.close()
	}

	// check for valid form values then accordingly make save button disable / enable & set value of user
	const handleChange = async (value) => {
		setSelectedLedger(form.getFieldValue('ledger'))
		if (form.getFieldValue('ledger') !== 1 && form.getFieldValue('role') === 1) {
			await form.setFieldsValue({ role: null })
		}
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const handleChangeForRole = (data) => {
		if (form.getFieldValue('role')) {
			setEnablePermission(false)
			var payload = {
				role_id: form.getFieldValue('role')
			}
			UserManagementStore.getPermissionsList(payload).then((data) => {
				form.setFieldsValue({
					permissions: data.data
				})
				setEnablePermission(false)
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		} else {
			setEnablePermission(true)
			setHidePermissionTable(true)
		}
	}

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		var actions = {}
		if (data.permissions) {
			data.permissions.map((item) => {
				item.actions.map(action => {
					if (action.is_selected === 1) {
						if (!actions[item.id]) { actions[item.id] = [] }
						actions[item.id].push(action.id)
					}
					return null
				})
				return null
			})
			data.permissions = actions
		} else {
			data.permissions = null
		}
		data.dm_user_group_status = data.dm_user_group_status && data.dm_user_group_status === true ? 1 : 0
		data.id = Store.UserManagement.editValues.id
		Store.UserManagement.EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmUserManagement.editUser
			})
			setEnablePermission(true)
			setHidePermissionTable(false)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		if (Store.UserManagement.editValues) {
			form.setFieldsValue({
				id: Store.UserManagement.editValues.id,
				first_name: Store.UserManagement.editValues.first_name,
				last_name: Store.UserManagement.editValues.last_name,
				role: Store.UserManagement.editValues.user_role ? Store.UserManagement.editValues.user_role.user_role_id : null,
				email: Store.UserManagement.editValues.email,
				password: "Admin@123",
				ledger: Store.UserManagement.editValues.default_ledger_id,
				user_group_id: Store.UserManagement.editValues.user_groups && Store.UserManagement.editValues.user_groups.id,
				dm_user_group_status: Store.UserManagement.editValues.user_groups && Store.UserManagement.editValues.user_groups.id ? true : false,
			})
			setDealMakerGroup(Store.UserManagement.editValues.user_groups && Store.UserManagement.editValues.user_groups.id ? true : false)
			setSelectedLedger(Store.UserManagement.editValues.default_ledger_id)
			var payload = {
				user_id: Store.UserManagement.editValues.id
			}
			Store.UserManagement.getPermissionsList(payload).then((data) => {
				form.setFieldsValue({
					permissions: data.data
				})
			})
			if (Store.UserManagement.editValues.user_role && Store.UserManagement.editValues.user_role.user_role_id) {
				setEnablePermission(false)
			}
		}
	}, [Store.UserManagement, form, props, Store.User])

	const handleModifyPermissions = () => {
		setHidePermissionTable(true)
	}

	const handleDealMakerGroup = (e) => {
		setDealMakerGroup(e.target.checked)
	}

	return Store.UserManagement.editValues ? (
		<>
			<ResetPasswordComponent visible={resetPass} close={closeResetPass} />
			<Drawer
				centered
				title={`Edit User - #${Store.UserManagement.editValues.id} - ${Store.UserManagement.editValues.first_name}`}
				visible={props.visible}
				className='permission-drawer'
				onClose={close}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
					<Button key="2" htmlType="button" className='ml-10' onClick={() => { close() }}>Cancel</Button>
				]}
				width={'45%'}
			>
				<Form form={form} id="editform" onFinish={handleSubmit}
					onChange={handleChange}
					layout={'vertical'}
				>

					<Row gutter={24}>
						<Col span={8}>
							<Form.Item name="email" rules={vsmUserManagement.validation.email} label="Email" required extra={[
								UserStore.checkPermission(64, 'reset_password') && <span key="1"><Button type="link" onClick={openResetPass} style={{ padding: 0, height: "auto" }}>Reset Password</Button></span>
							]} tooltip='Messages distributed by electronic means from one computer user to one or more recipients via a network.'>
								<Input placeholder="Email Address" maxLength={100} />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="first_name" rules={vsmUserManagement.validation.first_name} label="First Name" required tooltip='A personal Name that is given to user/ person at birth or baptism and used before a family name.'>
								<Input placeholder="First Name" maxLength={100} />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="last_name" rules={vsmUserManagement.validation.last_name} label="Last Name" required tooltip='It is the Name of the family to which a person/ user is born.'>
								<Input placeholder="Last Name" maxLength={100} />
							</Form.Item>
						</Col>

						<Col span={10}>
							<InputComponent
								disabled
								required type="select" label="Ledger" name='ledger'
								options={{
									values: [{ value: Store.UserManagement.editValues.default_ledger_id, text: Store.UserManagement.editValues.default_ledger_name }]
								}}
								tooltip="Ledger stores data for every Transaction. Select Ledger you want to associate this user with."
							/>
						</Col>

						{((Store.UserManagement.editValues.user_role && Store.UserManagement.editValues.user_role.user_role_id === 1) || Store.UserManagement.editValues.id === 1) ? (
							<Col span={10}>
								<Form.Item name="role" label="Role in Ledger" tooltip='The function assumed or played by a person associated with group of permissions.'>
									<Select disabled placeholder="Select Roles">
										{
											Store.UserManagement.dropdown_role_list && Store.UserManagement.dropdown_role_list.map((item, index) => {
												if (item.id === 1) {
													return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
												}
												return null
											})
										}
									</Select>
								</Form.Item>
							</Col>
						) : (
							<Col span={10}>
								<Form.Item name="role" rules={vsmUserManagement.validation.role} label="Role in Ledger" required extra={[
									([Store.User.user.id, ...Store.User.superUser].includes(Store.UserManagement.editValues.id)) ? (null) : (
										(
											(Store.User.user.user_role.user_role_id && [1, 2].includes(Store.User.user.user_role.user_role_id)) || UserStore.checkPermission(66, 'edit')
										) ? (
											<span key="1">
												<Button
													type="link"
													disabled={enablePermission}
													onClick={handleModifyPermissions}
													style={{ padding: 0, height: "auto" }}
												>Modify Permission</Button>
											</span>
										) : null
									)
								]} tooltip='The function assumed or played by a person associated with group of permissions.'>
									<Select
										disabled={[Store.User.user.id, ...Store.User.superUser].includes(Store.UserManagement.editValues.id)} placeholder="Select Roles"
										onChange={(data) => {
											handleChangeForRole(data)
											handleChange(data)
										}}
										showSearch allowClear
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}>
										{
											Store.UserManagement.dropdown_role_list && Store.UserManagement.dropdown_role_list.map((item, index) => {
												if (item.id === 1) {
													if (SelectedLedger === 1 && Store.User.user && Store.User.user.user_role && [1].includes(Store.User.user.user_role.user_role_id)) {
														return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
													}
												} else if (item.id === 2) {
													if (Store.User.user && Store.User.user.user_role && [1, 2].includes(Store.User.user.user_role.user_role_id)) {
														return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
													}
												} else {
													return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
												}
												return null
											})
										}
									</Select>
								</Form.Item>
							</Col>
						)}
					</Row>

					<Row gutter={24}>
						<Col span={12}>
							<InputComponent name="dm_user_group_status" type='checkbox' onChange={(e) => { handleChange(); handleDealMakerGroup(e) }} label="Grant access of Deal Maker System" valuePropName={'checked'} tooltip="Add User Group for DealMaker" />
						</Col>
						{dealMakerGroup ? (
							<Col span={12}>
								<InputComponent
									required
									onChange={handleChange}
									name='user_group_id'
									label="User Group"
									type="select"
									placeholder="Select User Group"
									showSearch
									optionFilterProp="children"
									onFocus={() => fetchUserGroup && UserManagementStore.getUserGroup().then(() => setFetchUserGroup(false))}
									notFoundContent={fetchUserGroup ? <Spin size="small" /> : "No Record Found."}
									filterOption={(input, option) =>
										option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									options={{
										values: UserManagementStore.dropdown_user_group || ((Store.UserManagement.editValues && Store.UserManagement.editValues.user_groups) && [{
											id: Store.UserManagement.editValues.user_groups.id,
											group_name: Store.UserManagement.editValues.user_groups.group_name,
										}]),
										value_key: 'id',
										text_key: 'group_name'
									}}
									rules={vsmUserManagement.validation.user_group_id}
								/>
							</Col>
						) : null}
					</Row>
					{(hidePermissionTable) ? (
						<>
							<Row>
								<Col span={5}>Features</Col>
								<Col span={19}>Actions</Col>
							</Row>

							<Form.List name="permissions">
								{
									(fields => {
										return (
											fields && fields.map(field => {
												var value = form.getFieldValue('permissions')[field.key]
												var isDisabled = false
												value.actions.map((action, index) => {
													if (action.action_name === 'list' && action.is_selected !== 1) {
														isDisabled = true
													}
													return null
												})
												var all_checked = 0
												value.actions.map((action, index) => {
													if (action.is_selected === 1) {
														all_checked++
													}
													return null
												})
												return (
													<div key={field.key}>
														{value.actions.length > 0 && (
															<Card className="mt-10">
																<Row gutter={24}>
																	<Col span={5} style={{ borderRight: '1px solid #f0f0f0' }}><b>{value.title}</b></Col>
																	<Col span={19}>
																		<Checkbox checked={all_checked === value.actions.length} onChange={(e) => {
																			var temp = form.getFieldValue('permissions')

																			temp[field.key].actions.map((action, index) => {
																				temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																				return null
																			})
																			form.setFieldsValue({
																				permissions: temp
																			})
																		}}>Check all</Checkbox>

																		{value.actions.map((action, index) => {
																			var temp = action.action_name.replace(/_/g, ' ')
																			return (
																				<div key={index} className='mr-10 mb-10' style={{ display: 'inline-block' }}>
																					<Form.Item
																						noStyle
																						name={[field.name, 'actions', index, 'is_selected']}
																					>
																						<Checkbox disabled={isDisabled && action.action_name !== 'list'} checked={action.is_selected === 1 ? true : false} value={action.id} onChange={(e) => {
																							var temp = form.getFieldValue('permissions')
																							temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																							if (temp[field.key].actions[index].action_name === 'list' && !e.target.checked) {
																								temp[field.key].actions.map((action, index) => {
																									temp[field.key].actions[index].is_selected = 0
																									return null
																								})
																							}

																							form.setFieldsValue({
																								permissions: temp
																							})
																						}}>{temp.charAt(0).toUpperCase() + temp.slice(1)}</Checkbox>
																					</Form.Item>
																				</div>
																			)
																		})}
																	</Col>
																</Row>
															</Card>
														)}
													</div>
												)
											})
										)
									})
								}
							</Form.List>
						</>
					) : null}
				</Form>
			</Drawer>
		</>
	) :
		null
})

export default EditComponent
