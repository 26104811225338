import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import useStore from '../../../../../store'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const ListComponent = observer((props) => {
    const { FXCurrenciesStore, FXCurrenciesStore: { viewHistory_list } } = useStore()
    const gridRef = useRef(props.filter)
    
    useEffect(() => {
        if (FXCurrenciesStore.agGridLocal) {
            gridRef.current = props.filter
            FXCurrenciesStore.agGridLocal && FXCurrenciesStore.agGridLocal.api.onFilterChanged()
        }
    }, [props.filter, FXCurrenciesStore])

    const columnData = [
        {
            headerName: 'Date',
            field: 'fx_rate_date',
            filter: 'agDateColumnFilter',
            width: '85',
            headerTooltip: "Date",
            tooltipField: 'fx_rate_date',
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;

                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateParts = dateAsString.split('-');
                    const year = Number(dateParts[0]);
                    const month = Number(dateParts[1]) - 1;
                    const day = Number(dateParts[2]);
                    const cellDate = new Date(year, month, day);

                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                }
            },

        },
        {
            headerName: 'GBP',
            headerTooltip: "GBP",
            tooltipField: 'gbp',
            filter: 'agNumberColumnFilter',
            field: 'gbp',
            cellRendererFramework: (data) => {
                return <NumberFormat value={data.value} decimalScale={4} thousandSeparator={true} displayType='text' fixedDecimalScale={true} />
            },
            valueGetter: (data) => {
                return Number(data.data.gbp)
            },
            width: '65'
        },
        {
            headerName: 'USD',
            headerTooltip: "USD",
            tooltipField: 'usd',
            filter: 'agNumberColumnFilter',
            cellRendererFramework: (data) => {
                return <NumberFormat value={data.value} displayType='text' thousandSeparator={true} decimalScale={4} fixedDecimalScale={true} />
            },
            valueGetter: (data) => {
                return Number(data.data.usd)
            },
            field: 'usd',
            width: '65'
        },

    ]
    const isExternalFilterPresent = () => {
        // if ageType is not everyone, then we are filtering
        return gridRef.current !== 'everyone';
    };

    const doesExternalFilterPass = (node) => {
        let date = props.form.getFieldValue("date")
        let date_range = props.form.getFieldValue("date_range")
        let dateCondition = date && node.data.fx_rate_date === moment(date).format('YYYY-MM-DD')
        let dateRangeCondition = date_range && node.data.fx_rate_date >= moment(date_range[0]).format('YYYY-MM-DD') && node.data.fx_rate_date <= moment(date_range[1]).format('YYYY-MM-DD')
        if (gridRef.current) {
            if (date && !date_range) {
                return dateCondition
            } else if (date_range && !date) {
                return dateRangeCondition
            } else {
                return true
            }
        } else { return true }
    };

    return (
        <div className='ag-theme-alpine grid_wrapper' style={{ height: '102%' }} >
            <AgGridReact
                rowData={viewHistory_list}
                columnDefs={columnData}
                defaultColDef={LocalGridConfig.defaultColDef}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                onGridReady={FXCurrenciesStore.setupGridInner}
                gridOptions={LocalGridConfig.options}
            />
        </div>)
})

export default ListComponent