import { Affix, Image } from 'antd'
import React, { useState } from 'react'
import InputComponent from '../components/InputComponent'
import UserManualConfig from '../config/UserManualConfig'
import useStore from '../store'

const UserManual = () => {
	const { UserStore } = useStore()
	const [container, setContainer] = useState(null);

	const getUserManualIndex = (data, parent_count = null) => (
		<ul className="index__ul">{
			data.map((item, index) => (
				<span key={index}>
					<li onClick={() => {
						document.getElementById(`index__li_${parent_count ? ((parent_count + 1) + '_' + (index + 1)) : (index + 1)}`).scrollIntoView({
							behavior: "smooth"
						});
					}} className={`index__li index__li_${parent_count ? ((parent_count + 1) + '_' + (index + 1)) : (index + 1)}`} title={parent_count ? `${parent_count + 1}.${index + 1} ${UserManualConfig[parent_count].title} > ${item.title}` : (
						`${index + 1}. ${item.title} ${item.tip ? '(' + item.tip + ')' : ''}`
					)}>
						{parent_count ? `${parent_count + 1}.${index + 1} ${UserManualConfig[parent_count].title} > ${item.title} ${item.tip ? '(' + item.tip + ')' : ''}` : (
							`${index + 1}. ${item.title} ${item.tip ? '(' + item.tip + ')' : ''}`
						)}
					</li>
					{item.child && <li >{getUserManualIndex(item.child, index)}</li>}
				</span>
			))
		}</ul>
	)

	const displayImage = (x) => x && x.image.map((img, i) => (
		<Image key={i} className="content__img" src={img.image} alt="" />
	))


	const getUserManualContent = (data, parent_count = null) => (
		<ul className="content__ul">{
			data && data.map((item, index) => {
				return (
					<li key={parent_count + '_' + index} className="content__li" id={`index__li_${parent_count ? ((parent_count + 1) + '_' + (index + 1)) : (index + 1)}`}>
						<div className="content__li__title">
							{parent_count ? `${parent_count + 1}.${index + 1} ${UserManualConfig[parent_count].title} > ${item.title}` : (
								`${index + 1}. ${item.title} ${item.tip ? '(' + item.tip + ')' : ''}`
							)}
						</div>
						<ol className="content__text__ul" type="a">
							{item.text && item.text.map((text, i) => ( // Loop For Level One
								<span key={parent_count + '_' + index + '_' + i}>
									{(typeof (text) === 'object') ? (!Array.isArray(text)) ? (
										displayImage(text)
									) : (
											<ol key={i} className="content__text__ul" type="i">
												{text && text.map((txt, i) => ( // Loop For Level Two

													<span key={parent_count + '_' + index + '_' + i}>
														{(typeof (txt) === 'object') ? (!Array.isArray(txt)) ? (
															displayImage(txt)
														) : (
																<ul key={i} className="content__text__ul" type="disc">
																	{txt && txt.map((x, i) => ( // Loop For Level Three

																		<span key={parent_count + '_' + index + '_' + i}>
																			{(typeof (x) === 'object') ? (!Array.isArray(x)) ? (
																				displayImage(x)
																			) : (
																					<ul key={i} className="content__text__ul" type="circle">
																						{x && x.map((y, i) => ( // Loop For Level Four

																							<span key={parent_count + '_' + index + '_' + i}>
																								{(typeof (y) === 'object') ? (!Array.isArray(y)) ? (
																									displayImage(y)
																								) : (
																										<ol className="content__text__ul" type="1">
																											{y && y.map((z, i) => ( // Loop For Level Five

																												<span key={parent_count + '_' + index + '_' + i}>
																													{(typeof (z) === 'object') ? (!Array.isArray(z)) ? (
																														displayImage(z)
																													) : (
																															<ol className="content__text__ul" type="i">
																																{z && z.map((z1, i) => ( // Loop For Level Five
																																	<li key={parent_count + '_' + index + '_' + i} className="content__text__li">{z1}</li>
																																))}
																															</ol>
																														) : (<li className="content__text__li">{z}</li>)}
																												</span>

																											))}
																										</ol>
																									) : (<li className="content__text__li">{y}</li>)}
																							</span>

																						))}
																					</ul>
																				) : (<li className="content__text__li">{x}</li>)}

																		</span>
																	))}
																</ul>
															) : (<li className="content__text__li">{txt}</li>)}
													</span>

												))}
											</ol>
										) : (<li className="content__text__li">{text}</li>)}
								</span>

							))}
						</ol>

						{
							item.image && item.image.map((img, i) => (
								<li key={i} className="content__img__wrapper">
									<div className="content__img__caption">{img.caption}</div>
									<Image className="content__img" src={img.image} alt="" />
								</li>
							))
						}
						{ item.child && getUserManualContent(item.child, index)}
					</li >
				)
			})
		}</ul >
	)

	const user_manual_content = getUserManualContent(UserManualConfig)

	const user_manual_index = getUserManualIndex(UserManualConfig)


	const HandleAutoComplete = (data = [], parent = null) => {
		return data && data.map((item, index) => {
			if (parent === null) {
				if (item.child) {
					return {
						value: (index + 1) + '. ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`,
						label: <div style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 1)', fontSize: '14px' }} onClick={() => handleChange('index__li_' + (index + 1))} >{(index + 1) + '. ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`}</ div>,
						options: HandleAutoComplete(item.child, { index: (index + 1), title: item.title })
					}
				} else {
					return {
						value: (index + 1) + '. ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`,
						label: <div onClick={() => handleChange('index__li_' + (index + 1))} >{(index + 1) + '. ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`}</div>
					}
				}

			} else {
				return {
					value: (parent.index) + '.' + (index + 1) + ' ' + parent.title + ' > ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`,
					label: <div onClick={() => handleChange('index__li_' + (parent.index) + '_' + (index + 1))} >{(parent.index) + '.' + (index + 1) + ' ' + parent.title + ' > ' + item.title + `${item.tip ? '(' + item.tip + ')' : ''}`}</div>
				}
			}
		})
	}

	const handleChange = (data) => {
		data && document.getElementById(data) && document.getElementById(data).scrollIntoView({
			behavior: "smooth"
		});
	}

	return (
		<>

			<div className={`user__manual__wrapper ${!UserStore.token ? 'p-25' : ''}`}>
				<Affix offsetTop={25}>
					<div className="user__manual__index pr-10" ref={setContainer}>
						<Affix offsetTop={0} target={() => container}>
							<InputComponent
								type="autocomplete"
								options={HandleAutoComplete(UserManualConfig)}
								placeholder="Search"
							/>
						</Affix>
						{user_manual_index}
					</div>
				</Affix>
				<div className="user__manual__content mt-20">
					{user_manual_content}
				</div>
			</div>
		</>
	)
}

export default UserManual