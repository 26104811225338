import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import AddComponent from './component/AddComponent'
import DeleteComponent from './component/DeleteComponent'

const OldImport = observer((props) => {
	const { OldImportStore: { getAllMappingList } } = useStore()

	// Set the form values to refresh
	useEffect(() => {
		getAllMappingList()
	}, [getAllMappingList])


	return (
		<>
			<BreadcrumbComponent items={[...BreadcrumbConfig.OldImport.path]} />
			<PageHeader
				title="Old Import"
			>
				<AddComponent />
				<DeleteComponent />

			</PageHeader>
		</>
	)
})

export default OldImport
