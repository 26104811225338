import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class OldImportStore {

	dropdown_list = null

	// call api to get all mapping list
	getAllMappingList = () => {
		Axios.get(`transactionmodel/typefieldmappinglist`).then(({ data }) => {
			this.dropdown_list = data
		})
	}

	// call excecute transactions api
	executeTransactions = (formdata) => {
		return Axios.post(`import/transactionsByMapping`, formdata).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	deleteMapping = (formdata) => {
		return Axios.post(`import/deleteByMapping/`, formdata).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(OldImportStore, {
	dropdown_list: observable,
	getAllMappingList: action,
	executeTransaction: action
})
