import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../config/LocalGridConfig'
import useStore from '../../../../store'
import { vsmCommon } from '../../../../config/messages'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal, openMapBankAccount } = props
	const { RoleMappingStore: { list_data, setupGrid, onFilterChanged, onGridChanged } } = useStore()

	var column = gridOptions.columnDefs
	let columns = []
	let columnConfig = localStorage.getItem('org_role_mapping_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = column.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = column.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = column
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={column}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openDeleteModal, openMapBankAccount
				}}
				onGridReady={setupGrid}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
