import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import useStore from '../../../../../store'
import { vsmCommon } from '../../../../../config/messages'

const ListComponent = observer((props) => {
	const { openSetViewImportLogDetails, openViewDrawer, importValues } = props
	const { ImportStore, ImportStore: { view_data, setupGrid, onFilterChanged } } = useStore()

	return (
		<div className="ag-theme-alpine grid_wrapper pb-40">
			<table className="cs-table-border mb-10">
				<tr>
					<th>Total</th>
					<td>{importValues.total_transactions}</td>
					<th>Imported</th>
					<td>{importValues.imported_transactions}</td>
				</tr>
				<tr>
					<th>Error in data</th>
					<td>{importValues.error_count_in_data}</td>
					<th>Error in models</th>
					<td>{importValues.error_count_in_models}</td>
				</tr>
			</table>
			<AgGridReact
				rowData={view_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openSetViewImportLogDetails, openViewDrawer
				}}
				onColumnResized={ImportStore.onChildnGridChanged}
				onColumnMoved={ImportStore.onChildnGridChanged}
				onColumnPinned={ImportStore.onChildnGridChanged}
				onSortChanged={ImportStore.onChildnGridChanged}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				pagination={false}
				onFilterChanged={onFilterChanged}
			/>
		</div>
	)

})

export default ListComponent
