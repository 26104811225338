import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Layout, Spin } from 'antd';
import SidebarComponent from './SidebarComponent';
import { useHistory, useLocation } from 'react-router-dom';
import useStore from '../store';
import { RouterConfig } from '../config/RouterConfig';
import debounce from 'lodash/debounce'
import $ from 'jquery'

const { Content } = Layout;

const LayoutComponent = observer(({ children }) => {

	const { UserStore: { user, token, loading, ledger_base_currency } } = useStore()

	const [setting, setSetting] = useState(null)
	const [internalLoader, setInternalLoader] = useState(true)
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)

	let history = useHistory()
	let location = useLocation()

	// check route & set the route setting accordingly
	useEffect(() => {
		if (!isScriptLoaded && location.pathname === '/user-manual') {
			window.onscroll = debounce(function () {
				if (location.pathname === '/user-manual') {
					var test1 = document.querySelectorAll('.content__li')
					var test2 = '.index__li_1'

					document.querySelectorAll('.index__li').forEach(el => {
						el.classList.remove("active");
					});
					test1.forEach(x => {
						if (window.scrollY >= 150 && x.offsetTop - window.scrollY) {
							if (test2 === null) { test2 = '.' + x.getAttribute('id') }
							else if ((x.offsetTop - window.scrollY) <= 200) {
								test2 = '.' + x.getAttribute('id')
							}
						}
					})
					document.querySelector(test2) && document.querySelector(test2).classList.add('active')
					document.querySelector(test2) && document.querySelector(test2).scrollIntoView({
						block: "center",
						behavior: "smooth"
					})
				}
			}, 500)
			setIsScriptLoaded(true)
		}
		if (!loading) {
			const redirectDefault = RouterConfig.find(x => x.default)
			const redirectAuthDefault = RouterConfig.find(x => x.authdefault)
			const redirectLedgerDefault = RouterConfig.find(x => x.ledgerDefault)

			let path = RouterConfig.find(x => x.path === location.pathname.trimRight('/'))

			if (path && path.auth && token === null) {
				history.replace(redirectDefault.path)
			} else if (user && user.user_role && user.user_role.user_role_id === 2 && !ledger_base_currency) {
				if (history.location.pathname !== redirectLedgerDefault.path) {
					if (redirectLedgerDefault.path) {
						history.replace(redirectLedgerDefault.path)
					}
				}
			} else if (path && !path.auth && token && !path.errorpage) {
				history.replace(redirectAuthDefault.path)
			}

		} else {
			setInternalLoader(true)
		}
		let route = RouterConfig.find(x => x.path === location.pathname.trimRight('/'))

		if (route && route.setting) {
			setSetting(route.setting)
		} else {
			if (setting === null) { setSetting({ header: true, nav: true }) }
		}

		setTimeout(() => {
			setInternalLoader(false)
		}, 500)

	}, [loading, location, token, history, setting, ledger_base_currency, user, isScriptLoaded])

	useEffect(() => {
		$(function () {
			if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
				$('body').addClass('safari-mac');
			}
		});
	}, [])


	return (loading || internalLoader) ? (
		<div className="fullscreen__spinner">
			<Spin size="large" />
		</div>
	) : (
		<Layout className="main__layout__wrapper">
			{
				(token && setting && setting.nav && (
					<SidebarComponent />
				))
			}

			<Layout className="site-layout">
				{/* {
					(token && setting && setting.header && (
						<HeaderComponent logout={Logout} user={user} collapsed={collapsed} setCollapsed={setCollapsed} />
					))
				} */}
				{
					(token && setting && (setting.header || setting.nav)) ? (
						<Content
							className="site-layout-background"
							style={{
								paddingTop: 15,
								paddingLeft: 24,
								paddingRight: 24,
								paddingBottom: 24,
								minHeight: 280,
								height: '100%'
							}}
						>
							{children}
						</Content>
					) : (<>{children}</>)
				}

			</Layout>
		</Layout>
	)
})

export default LayoutComponent
