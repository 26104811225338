import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent';
import { vsmTransactionReport } from '../../../../config/messages';

const DateRangeIterationElement = observer(({ onChange }) => {

	return (
		<Form.List name='period_date_range'>
			{
				(fields, { add, remove }) => {
					return fields.map((field, index) => {
						return (
							<div className="w-100" key={field.key}>
								<Row gutter={24}>
									<Col span={16}>
										<InputComponent
											type="date_range" name={[field.name]} key={[field.key, 'date_range']} className="w-100" onChange={onChange}
											rules={vsmTransactionReport.validation.period_date_range}
										/>
									</Col>
									<Col>
										<div className="d-flex justify-space-between">
											{(fields.length > 2) && (
												<Button className="mr-10" type="danger" onClick={() => {
													remove(field.name)
													onChange()
												}} icon={<MinusOutlined />}></Button>
											)}
											{(index === (fields.length - 1) && (fields.length < 12)) && (
												<Button type="primary" onClick={() => {
													add()
													onChange()
												}} icon={<PlusOutlined />}></Button>
											)}
										</div>
									</Col>
								</Row>
							</div>
						)
					})
				}
			}
		</Form.List>
	)
})

export default DateRangeIterationElement
