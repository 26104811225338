import React, { useState } from 'react'
import { Form, Button, Modal, Input, Radio, Select, Col, AutoComplete, Row } from 'antd'
import useStore from '../../../../../store';
import { vsmBankAccount, vsmNotify } from '../../../../../config/messages';
import { observer } from 'mobx-react';


const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { LedgerBankAccountStore: { AddData, bankNameList, bankAccountType, currencyList } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [currentTab, setCurrentTab] = useState("account")

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmBankAccount.add
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable & set value
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
		}).catch(e => {
			if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
			setDisabled(true)
		})
	}

	// set field vaule null based on tab selection
	const changeMapwith = async () => {
		await setCurrentTab(form.getFieldValue("tab"))
		form.setFieldsValue({
			account_number: null,
			// sort_code: null,
			iban: null,
			swift_bic: null
		})
		handleChange();
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setCurrentTab("account")
	}

	return (
		<Modal
			destroyOnClose={true}
			centered
			title="Add New Bank Account"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				onChange={handleChange}
				layout="vertical"
			>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="bank_name" rules={vsmBankAccount.validation.bank_name} label="Bank Name" required tooltip='Bank name (for a Cash Account) is the name of a banking or cash processing organisation that operates a specific cash account.'>
							<AutoComplete
								options={bankNameList}
								placeholder="Bank Name"
								filterOption={(inputValue, option) =>
									option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
								}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="bank_account_type_id" rules={vsmBankAccount.validation.bank_ac_type} label="Bank Account Type" required tooltip="A name or code given to a cash account that indicates the account's purpose.">
							<Select placeholder="Select Bank Account Type" onChange={handleChange}>
								{
									(bankAccountType && bankAccountType.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item name="currency_id" rules={vsmBankAccount.validation.currency} label="Currency" required tooltip="Base Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger.">
							<Select showSearch placeholder="Select Currency"
								optionFilterProp="children"
								onChange={handleChange}
								filterOption={(input, option) =>
									option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								allowClear
							>
								{
									(currencyList && currencyList.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.currency_code}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item name="tab" initialValue="account">
							<Radio.Group buttonStyle="solid">
								<Radio.Button value="account">Account Number</Radio.Button>
								<Radio.Button value="iban">IBAN Number</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
					{
						currentTab === "account" ?
							<>
								<Col span={12}>
									<Form.Item name="account_number" rules={vsmBankAccount.validation.account_num} label="Account Number" required tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN).">
										<Input
											maxLength={50}
											placeholder="Account Number" />
									</Form.Item>
								</Col>
								{/* <Col span={12}>
									<Form.Item name="sort_code" rules={vsmBankAccount.validation.sort_code} label="Sort Code" required tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.).">
										<Input
											maxLength={50}
											placeholder="Sort Code" />
									</Form.Item>
								</Col> */}
							</>
							:
							<>
								<Col span={12}>
									<Form.Item name="iban" rules={vsmBankAccount.validation.iba_num} label="IBAN" required tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account.">
										<Input
											placeholder="International Bank Account Number" />
									</Form.Item>
								</Col>
							</>
					}
					<Col span={12}>
						<Form.Item name="swift_bic" rules={vsmBankAccount.validation.swift_or_bic_code} label="Swift or BIC Code" required tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC).">
							<Input
								maxLength={50}
								placeholder="Swift or BIC Code" />
						</Form.Item>
					</Col>
				</Row>
			</Form>

		</Modal>
	)
})


export default AddComponent
