import { observer } from "mobx-react-lite"
import React from "react"
import useStore from "../../../../store"
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'

const PreviewPeopleReportTableElement = observer((props) => {
    const { SavedReportStore } = useStore()

    let gridOptions = {
        columnDefs: [
            { headerName: '# ID', field: 'id', filter: 'agNumberColumnFilter', pinned: 'left', width: 80, },

            {
                headerName: 'People Name', field: 'organisation_name',
                width: 170,
                valueGetter: (params) => (params.data) ? params.data.first_name + ' ' + (params.data.last_name ? params.data.last_name : null) : "",
            },

            {
                headerName: 'Title', field: 'title',
                width: 170,
                valueGetter: (params) => (params.data && params.data.title) ? params.data.title : "",
            },

            {
                headerName: 'People Role', field: 'role',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                // valueGetter: (params) => (params.data && params.data.people_roles) ? params.data.role : "",
                valueGetter: (params) => (
                    params.data && params.data.people_roles && params.data.people_roles.map(x => x.role_name).join(', ')
                ),
            },

            {
                headerName: 'Phone', field: 'phone',
                filter: false,
                sorting: false,
                width: 170,
                tooltipValueGetter: (params) => {
                    return params.data && params.data.phone && params.data.phone.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
                },
                valueGetter: (params) => (
                    params.data && params.data.phone && params.data.phone.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
                ),
            },

            {
                headerName: 'Email', field: 'email',
                width: 170,
                filter: false,
                sorting: false,
                tooltipValueGetter: (params) => {
                    return params.data && params.data.email && params.data.email.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
                },
                valueGetter: (params) => (
                    params.data && params.data.email && params.data.email.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
                ),
            },

            {
                headerName: 'PPS Number', field: 'pps_number',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.pps_number) ? params.data.pps_number : "",
            },

            {
                headerName: 'Exchange Ref Id', field: 'exchange_ref_id',
                width: 170,
                valueGetter: (params) => (params.data && params.data.exchange_ref_id) ? params.data.exchange_ref_id : "",
            },

            {
                headerName: 'Bank Name', field: 'bank_name',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_name) ? params.data.bank_name : "",
            },

            {
                headerName: 'Bank Account Type', field: 'bank_account_type_name',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_account_type_name) ? params.data.bank_account_type_name : "",
            },

            {
                headerName: 'Currency', field: 'currency',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.currency) ? params.data.currency : "",
            },

            {
                headerName: 'Account Number', field: 'account_number',
                width: 170,
                valueGetter: (params) => (params.data && params.data.account_number) ? params.data.account_number : "",
            },

            {
                headerName: 'IBAN Number', field: 'iban_number',
                width: 170,
                valueGetter: (params) => (params.data && params.data.iban_number) ? params.data.iban_number : "",
            },

            {
                headerName: 'Sort Code', field: 'sort_code',
                width: 170,
                valueGetter: (params) => (params.data && params.data.sort_code) ? params.data.sort_code : "",
            },

            {
                headerName: 'Swift or BIC Code', field: 'swift_bic_code',
                width: 170,
                valueGetter: (params) => (params.data && params.data.swift_bic_code) ? params.data.swift_bic_code : "",
            },

            {
                headerName: 'Bank Status', field: 'bank_status',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_status) ? params.data.bank_status : "",
            },
        ]
    }

    // useEffect(() => {
    //     if (SavedReportStore.previewReportValues) {
    //         SavedReportStore.getColumnSetting(SavedReportStore.previewReportValues.id)
    //     }
    // }, [SavedReportStore])
    let columns = []
    let columnConfig = SavedReportStore.reportColumnSettings
    if (columnConfig) {
        let data = columnConfig
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    if (props.rowData && Array.isArray(props.rowData)) {
        return (
            <div className="" style={{ height: '70vh', marginBottom: 24 }}>
                <div className="ag-theme-alpine grid_wrapper">
                    <AgGridReact
                        rowData={props.rowData}
                        modules={AllModules}
                        columnDefs={columns}
                        defaultColDef={{ ...LocalGridConfig.defaultColDef }}
                        columnTypes={LocalGridConfig.columnTypes}
                        overlayNoRowsTemplate={vsmCommon.noRecord}
                        onGridReady={props.onGridReady}
                        gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                        onColumnResized={SavedReportStore.onChildnGridChanged}
                        onColumnMoved={SavedReportStore.onChildnGridChanged}
                        onColumnPinned={SavedReportStore.onChildnGridChanged}
                        onSortChanged={SavedReportStore.onChildnGridChanged}
                    />
                </div>
            </div>
        )

    } else {
        return null
    }
})

export default PreviewPeopleReportTableElement