import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import moment from 'moment'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	var disabled = props.data.disable_action
	return (
		<div className="action-column">
			{UserStore.checkPermission(80, 'edit') && (
				<Button title="Edit Cash Transaction Classification" disabled={disabled} type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			)}
			{(props.data && props.data.cash_transactions_count > 0) ? (
				UserStore.checkPermission(80, 'delete') && (
					<Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Cash Transaction Classification as it has some Transactions associated with it."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>)
			) : (UserStore.checkPermission(80, 'delete') && (
				<Button title="Delete Cash Transaction Classification" disabled={disabled} type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
			))
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		headerTooltip: '# ID',
		tooltipField: 'id',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Transaction Class Name',
		headerTooltip: 'Transaction Class Name',
		tooltipField: 'transaction_class_name',
		field: 'transaction_class_name',
		width: 200
	}, {
		headerName: 'Code',
		headerTooltip: 'Code',
		tooltipField: 'code',
		field: 'code',
		width: 120
	}, {
		headerName: 'Description',
		headerTooltip: 'Description',
		field: 'description',
		tooltipField: 'description',
		width: 220
	}, {
		headerName: 'No. of Transactions',
		headerTooltip: 'No. of Transactions',
		field: 'cash_transactions_count',
		tooltipField: 'cash_transactions_count',
		filter: 'agNumberColumnFilter',
		width: 80
	}, {
		headerName: 'Last Updated By',
		headerTooltip: 'Last Updated By',
		field: 'updated_by',
		tooltipField: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		headerTooltip: 'Last Updated On',
		field: 'updated_at',
		tooltipField: 'updated_at',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
			// use inRangeInclusive: true for the range filter to include the selected
			// from and to dates. Setting it false would fetch only the inbetween dates
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: function (filterLocalDateAtMidnight, cellValue) {
				//using moment js
				var dateAsString = moment(cellValue).format('DD/MM/YYYY');
				var dateParts = dateAsString.split("/");
				var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

				if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
					return 0
				}

				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				}

				if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
			}
		},
		width: 185
	}, {
		headerName: 'Actions',
		headerTooltip: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
