import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../../../store'
import { PageHeader, Button, Tooltip, Alert } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import DeleteComponent from './component/DeleteComponent'
import { vsmTransactionModels } from '../../../../../../config/messages';
import EditComponent from './component/EditComponent';

const JournalLines = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const { TransactionModelStore: { setJournalLinesValues, array_list } } = useStore()

	// Open form for add new Journal Line
	const openAddModal = () => setAddModal(true)

	// Close form for add new Journal Line
	const closeAddModal = () => setAddModal(false)

	// go to edit screen
	const openEditModel = (data) => {
		setJournalLinesValues(data)
		setEditModal(true)
	}

	//close edit modal
	const closeEditmodel = () => setEditModal(false)

	// Open delete journal line modal
	const openDeleteModal = (data) => {
		setJournalLinesValues(data)
		setDeleteModal(true)
	}

	// Close journal line modal
	const closeDeleteModal = () => setDeleteModal(false)

	return (
		props.currentView !== "verify" ? (
			<>
				<PageHeader
					title={"Journal Lines"}
					subTitle={<Tooltip title="Multiple Transaction Model Journal Lines - together - form a single Transaction Model that is used during processing of selected data associated with a Transaction."><InfoCircleOutlined /></Tooltip>}
					extra={[
						<Button disabled={props.data ? false : true} key="1" onClick={openAddModal}>Add New Journal Line</Button>
					]}
				>
					{props.errorMessage && array_list && array_list.length <= 0 && <Alert className="mb-10" message={vsmTransactionModels.journal_line} type="error" showIcon />}
					<ListComponent currentView={props.currentView} openEditModel={openEditModel} openDeleteModal={openDeleteModal} />
					<AddComponent data={props.data} visible={addModal} close={closeAddModal} handleChange={props.handleChange} />
					<EditComponent data={props.data} visible={editModal} close={closeEditmodel} handleChange={props.handleChange} setNewFields={props.setNewFields} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} setErrorMessage={props.setErrorMessage} handleChange={props.handleChange} />
				</PageHeader>
			</>
		) : (
			<>
				<ListComponent currentView={props.currentView} openDeleteModal={openDeleteModal} page={props.page} />
			</>
		)
	)
})

export default JournalLines
