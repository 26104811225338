import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import { Button, Col, Form, PageHeader, Row, Tooltip } from 'antd';
import useStore from '../../../store';
import InputComponent from '../../../components/InputComponent';
import DateSelectionElement from '../SavedReports/elements/DateSelectionElement';
import moment from 'moment';
import { vsmTransactionReport } from '../../../config/messages';
import PreviewSummaryDetailsComponent from './components/PreviewSummaryDetailsComponent';
import { useLocation } from 'react-router-dom';
import NotIdentifiedDetailComponent from './components/NotIdentifiedDetailComponent';

const SummaryReport = observer((props) => {
    const [form] = Form.useForm()
    const location = useLocation();
    const { UserStore, SummaryReportStore, CTEImportStore } = useStore()
    const [formDataList, setFormDataList] = useState([])
    const [saving, setSaving] = useState(false)
    const [identified, setIdentified] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    const [DateRange, SetDateRange] = useState([]);
    const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)
    const [PreviewReport, setPreviewReport] = useState(null)
    const [openNotIdentified, setOpenNotIdentified] = useState(false)
    const [buttonType, setButtonType] = useState(null)
    const [formData, setFormData] = useState([])
    const typeChange = ['generate_on', 'audit_group_on']

    const getFormDataList = useCallback(() => {
        var FormDataList = []
        FormDataList = [...FormDataList, ...typeChange]
        switch (form.getFieldValue('generate_on')) {
            case 'date_range':
                FormDataList = [...FormDataList, 'date_selection']
                switch (form.getFieldValue('date_selection')) {
                    case 'range':
                        FormDataList = [...FormDataList, 'date_range']
                        break;
                    case 'single':
                        FormDataList = [...FormDataList, 'date']
                        break;
                    case 'auto':
                        FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
                        break;
                    default:
                }
                break;
            case 'financial_year':
                FormDataList = [...FormDataList, 'financial_year']
                if (!GENERATEONFINANCIAL) {
                    SETGENERATEONFINANCIAL(true)
                    SummaryReportStore.getFinancialYearList()
                }
                break;
            default:
        }
        return FormDataList
    }, [GENERATEONFINANCIAL, SummaryReportStore, form, typeChange])

    const onFinish = (data) => {
        if (data.date) {
            data.date = moment(data.date).format('YYYY-MM-DD')
        }

        if (data.date_range && data.date_range.length) {
            var payload = {
                from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
                to_date: moment(data.date_range[1]).format('YYYY-MM-DD')
            }
            data.date_range = payload
            data.date_selection = 'range'
        }
        if (buttonType === 2) {
            setIdentified(true)
            setFormData(data)
            SummaryReportStore.getNotIdentifiedData(data, null).then(() => {
                setOpenNotIdentified(true)
                setIdentified(false)
            }).catch(() => {
                setIdentified(false)
                setOpenNotIdentified(false)
            })
        } else if (buttonType === 1) {
            setSaving(true)
            setFormData(data)
            SummaryReportStore.getSummaryPreviewDetails(data).then(({ data }) => {
                setPreviewReport(data)
                setSaving(false)
            }).catch(() => {
                setSaving(false)
            })
        }
    }

    const handleChange = async () => {
        await setFormDataList(getFormDataList())
        form.validateFields().then((data) => {
            setDisabled(false)
        }).catch(e => {
            if (e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const disabledDate = current => {
        if (!DateRange || DateRange.length === 0) { return false }
        return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
    }

    // close NotIdentifiedDrawer
    const closeNotIdentifiedDrawer = (type) => {
        setOpenNotIdentified(false)
        if (type) {
            setPreviewReport(null)
        }
    }

    useEffect(() => {
        if (location && location.state) {
            if (location.state.isFromImport) {
                form.setFieldsValue({ generate_on: 'custom' })
                let payload = {
                    import_ids: location.state.importIds ? JSON.parse(location.state.importIds) : null,
                    generate_on: form.getFieldValue('generate_on')
                }
                setSaving(true)
                SummaryReportStore.getSummaryPreviewDetails(payload).then(({ data }) => {
                    setPreviewReport(data)
                    setSaving(false)
                }).catch(() => {
                    setSaving(false)
                })
            }
        }
    }, [CTEImportStore.store_import_ids, SummaryReportStore, form, location, props])

    useEffect(() => {
        var data = getFormDataList()
        if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
            setFormDataList(data)
        }
    }, [getFormDataList, setFormDataList, formDataList])

    useEffect(() => {
        setSaving(true)
        if (SummaryReportStore.summary_list_data) {
            setPreviewReport(SummaryReportStore.summary_list_data)
            setSaving(false)
        }
        setSaving(false)
    }, [SummaryReportStore.summary_list_data, PreviewReport])

    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.BankImportSummary.path} />
            <PageHeader
                className={UserStore.fullscreen_class}
                title={BreadcrumbConfig.BankImportSummary.title}
                style={{ paddingBottom: '1px' }}
            >
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={SummaryReportStore.initialValues}
                    onFinish={onFinish}
                    id='previewSummary'
                >
                    <Row gutter={10}>
                        {formDataList.includes('generate_on') && (
                            <Col>
                                <InputComponent
                                    required
                                    type="radio_button"
                                    label="Generate On"
                                    name="generate_on"
                                    onChange={handleChange}
                                    options={{
                                        values: [{ value: 'all_unarchive_data', text: 'All Data' }, { value: 'date_range', text: 'Date Range' }, { value: 'financial_year', text: 'Financial Year' }, { value: 'periods', text: 'Period' }, (location?.state?.isFromImport) ? !['all_unarchive_data', 'date_range', 'financial_year', 'periods'].includes(form.getFieldValue('generate_on')) ? { value: 'custom', text: 'Manual Import' } : null : null],
                                        rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
                                    }}
                                    tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
                                    rules={vsmTransactionReport.validation.generate_on}
                                />
                            </Col>
                        )}

                        <Col>
                            {(formDataList.includes('date_selection')) && (
                                <DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} />
                            )}

                            {(formDataList.includes('financial_year')) && (
                                <div className="w200">
                                    <InputComponent
                                        label="Financial Year"
                                        required type="select" name="financial_year" placeholder="Financial Year" onChange={handleChange}
                                        options={{
                                            values: SummaryReportStore.financial_year_list,
                                        }}
                                        tooltip="User can select the financial year to fetch reports in that particular range."
                                        rules={vsmTransactionReport.validation.financial_year}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col>
                            <Button form='previewSummary' className="mt-25" htmlType="submit" type="primary" disabled={isDisabled} loading={saving} onClick={() => { setButtonType(1) }}>View</Button>
                        </Col>

                        {<Col>
                            <Tooltip title='View Not Identified Transactions'>
                                <Button form='previewSummary' className="mt-25" type="primary" htmlType="submit" disabled={isDisabled} loading={identified} onClick={() => { setButtonType(2) }}>Verify/Identify</Button>
                            </Tooltip>
                        </Col>}
                    </Row>

                </Form>
                {PreviewReport && (<PreviewSummaryDetailsComponent rowData={PreviewReport} />)}
                {openNotIdentified && (<NotIdentifiedDetailComponent visible={openNotIdentified} close={closeNotIdentifiedDrawer} formData={formData} />)}
            </PageHeader>
        </>
    );
})

export default SummaryReport;