import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { vsmNotify, vsmJournalGroups } from '../../../../../config/messages'
import InputComponent from '../../../../../components/InputComponent'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { JournalGroupStore: { EditData, editValues, dropdown_list } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [parentSelection, setParentSelection] = useState(false)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournalGroups.edit
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		if (props.visible && editValues) {
			//Parent Group Field is only required when parent_id == 0
			setParentSelection(editValues.parent_id === 0)
			var parent = dropdown_list && dropdown_list.find(x => x.id === editValues.parent_id)
			form.setFieldsValue({
				journal_group_name: editValues.journal_group_name,
				journal_type: editValues.journal_type,
				parent_id: parent ? parent.id : null,
			})
		}
	}, [editValues, form, dropdown_list, setParentSelection, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		let label = form.getFieldValue('parent_id') && dropdown_list.find(item => item.id === form.getFieldValue('parent_id'))
		form.getFieldValue('parent_id') && form.setFieldsValue({
			'journal_type': label.journal_type
		})
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			if (d.errorFields && d.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close edit form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setParentSelection(false)
		props.close()
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Journal Group - #${editValues.id} - ${editValues.journal_group_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				(editValues.parent_id === 0) ? (null) :
					(
						<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>
					),
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required type="select" label="Journal Group" name="parent_id" placeholder="Select Parent Group"
							tooltip="Journal Group is a top level group of Journals that can be used as a Parent for other Journal Groups. For example: Assets, Liabilities, Income, Expenses."
							{...(!parentSelection) ? { rules: vsmJournalGroups.validation.parent_id } : {}}
							onChange={handleChange}
							options={{
								values: dropdown_list,
								value_key: 'id',
								text_key: 'journal_group_name',
								rejected_keys: dropdown_list && dropdown_list.map(x => x.parent_id !== 0 ? x.id : null)
							}}
							disabled={parentSelection}
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required label="Journal Subgroup" name="journal_group_name" placeholder="Journal Subgroup Name"
							tooltip="Journal Subgroups is a further classification of defined Journal Groups, with accordance to specific Journal common properties. For example: Assets group can have two Subgroups defined: Current Assets and Fixed Assets."
							{...(!parentSelection) ? { rules: vsmJournalGroups.validation.journal_group_name } : {}}
							onChange={handleChange}
							disabled={parentSelection}
						/>
					</Col>
				</Row>

				<InputComponent
					type="radio_button" label="Journal Type" name="journal_type"
					tooltip="There are two Predefined Journal Types: P&L [Profit & Loss] and B/S [Balance Sheet]. Assign appropriate Journal Group for your Journal Subgroup and Journal Type will be selected automatically."
					onChange={handleChange}
					options={{
						values: [
							{ value: 'B/S', text: 'B/S' },
							{ value: 'P&L', text: 'P&L' },
						]
					}}
					disabled={parentSelection}
				/>
			</Form>
		</Modal >
	) : null
})

export default EditComponent
