import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../config/LocalGridConfig"
import moment from 'moment';

export default class FXCurrenciesStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	bulkEditValues = null
	exchangeRates = null
	exchangeRateValues = null
	deleteValues = null
	currentValues = null
	agGrid = null
	agGridInner = null
	agGridLocal = null
	currencyList = null
	viewHistory_list = null
	viewValues = null
	exportLoading = false
	selectedIds = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to exchange rate
	setExchangeRateValues = (data) => {
		this.exchangeRateValues = data
	}

	// set form values to set exchange rates
	setExchangeRates = (data) => {
		this.exchangeRates = data
	}

	// set form values to bulk edit
	setBulkEditValues = (data) => {
		this.bulkEditValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// set form values for activate and deactivate
	setCurrentValues = (data) => {
		this.currentValues = data
	}

	// set form values to View History
	setViewValues = (data) => {
		this.viewValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid
	setupGrid = (params) => {
		this.agGrid = params
		const sortModel = [
			{ colId: 'id', sort: 'asc' }
		];
		this.agGrid && this.agGrid.api.setSortModel(sortModel)
		let columnConfig = localStorage.getItem('fx_currency_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('fx_currency_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('fx_currency_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('fx_currency_grid', columnConfig)
		}
	}

	// Setup inner grid
	setupGridInner = (params) => {
		this.agGridLocal = this.agGridInner = params
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// Filter function for no record found message
	onFilterChangedInner = (params) => {
		this.agGridLocal = params
		if (this.agGridLocal && this.agGridLocal.api.rowModel.rowsToDisplay.length === 0) {
			this.agGridLocal.api.showNoRowsOverlay()
		}
		if (this.agGridLocal && this.agGridLocal.api.rowModel.rowsToDisplay.length > 0) {
			this.agGridLocal.api.hideOverlay()
		}
	}

	// call for currency list
	getCurrencyList = () => {
		return Axios.get(`globalcurreny/list`).then(({ data }) => {
			this.currencyList = data.data
		}).catch(() => {
			this.currencyList = null
		})
	}

	// call for currency list
	getExchangeRates = (formdata) => {
		return Axios.get(`globalcurreny/exchangerate/${formdata.currency_code}`).then(({ data }) => {
			this.exchangeRates = data.data
			return data.data
		}).catch(() => {
			this.exchangeRates = null
		})
	}

	// call to get record particular ID
	getRecordById = (formdata) => {
		return Axios.get(`globalcurreny/read/${formdata}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.get(`globalcurreny/list`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {

					item.ledger_status = item.currency_settings ? item.currency_settings.status : false
					item.ledger_published = item.currency_settings ? (item.currency_settings.status ? "Active" : "Deactive") : "Deactive"
					item.published = item.status ? "Active" : "Deactive"
				    item.currency_id_wise_day_value =item.currency_id_wise_day_value.length ? item.currency_id_wise_day_value[0].day_value :'1.0000'
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to view exchange rate history
	getViewHistoryList = () => {
		return Axios.get(`getcurrency/history`).then(({ data }) => {
			this.viewHistory_list = data
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to import history file
	ImportFile = (formdata) => {
		const options = { headers: { Accept: '' } }
		return Axios.post(`globalcurreny/import`, formdata, options).then(({ data }) => {
			this.getList()
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call API to view exchange rate history
	ExchangeData = (formdata) => {
		return Axios.post(`globalcurreny/exchangeratehistory/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call API to Bulk Edit Manual Rates
	BulkEditRates = (formdata) => {
		return Axios.post(`globalcurreny/bulkeditrate`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call API to Configure Exchange Rate
	AddExchangeRateData = (formdata) => {
		return Axios.post(`globalcurreny/addexchangerate/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`globalcurreny/add`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`globalcurreny/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`globalcurreny/delete/${formdata.id}`).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call activate / deactivate api
	ToggleData = (formdata, payload) => {
		return Axios.post(`globalcurreny/status/${formdata.id}`, payload).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// export data as a csv
	exportData = async () => {
		this.exportLoading = true;
		var params = {
			columnKeys: ['fx_rate_date', 'currency_code.currency_code', 'day_value'],
			fileName: '1 - exchange-rate-history-export-' + moment().format("YYYY-MM-DD hh:mm:s"),
		};
		await this.agGridInner.api.paginationSetPageSize(this.total)
		await this.agGridInner.api.exportDataAsCsv(params)
		await this.agGridInner.api.paginationSetPageSize(this.per_page)
		this.exportLoading = false;
	}

}

decorate(FXCurrenciesStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	bulkEditValues: observable,
	exchangeRates: observable,
	exchangeRateValues: observable,
	deleteValues: observable,
	currentValues: observable,
	agGrid: observable,
	currencyList: observable,
	initialValues: observable,
	viewHistory_list: observable,
	getViewHistoryList: observable,
	viewValues: observable,
	exportLoading: observable,
	selectedIds: observable,
	agGridInner: observable,
	agGridLocal: observable,
	getList: action,
	ExchangeData: action,
	AddData: action,
	EditData: action,
	setEditValues: action,
	setBulkEditValues: action,
	setCurrentValues: action,
	DeleteData: action,
	setupGrid: action,
	onFilterChanged: action,
	setPageSize: action,
	getCurrencyList: action,
	ToggleData: action,
	getRecordById: action,
	getExchangeRates: action,
	BulkEditRates: action,
	setExchangeRateValues: action,
	setExchangeRates: action,
	ImportFile: action,
	setViewValues: action,
	exportData: action,
	setupGridInner: action,
	onGridChanged: action,
	onChildnGridChanged: action,
})
