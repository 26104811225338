import React from 'react'
import { Card } from 'antd'
import { observer } from 'mobx-react'

const PplDetailComponent = observer((props) => {
	return (
		<Card size="small" className="mb-20">
			<h3><b>{props.data.first_name + ' ' + (props.data.last_name ? props.data.last_name : '')}</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						<tr>
							<td className="w150">Title</td>
							<td className="w25">:</td>
							<td>{props.data.title && props.data.title}</td>
						</tr>
						<tr>
							<td>PPS Number</td>
							<td>:</td>
							<td>{props.data.pps_number && props.data.pps_number}</td>
						</tr>
						<tr>
							<td>Phone</td>
							<td>:</td>
							<td>{props.data.phone && props.data.phone.map(x => x.contact_value).join(', ')}</td>
						</tr>
						<tr>
							<td>Email</td>
							<td>:</td>
							<td>{props.data.email && props.data.email.map(x => x.contact_value).join(', ')}</td>
						</tr>
						<tr>
							<td>Address</td>
							<td>:</td>
							<td>
								{props.data.single_address &&
									<div>
										{props.data.single_address.address_1}
										{props.data.single_address.address_2 && (', ' + props.data.single_address.address_2)}
										{props.data.single_address.address_3 && (', ' + props.data.single_address.address_3)}
										{props.data.single_address.city && (', ' + props.data.single_address.city)}
										{props.data.single_address.state_county && (', ' + props.data.single_address.state_county)}
										{props.data.single_address.countries && (', ' + props.data.single_address.countries.country_name)}
										{props.data.single_address.postal_code && (', ' + props.data.single_address.postal_code)}
									</div>
								}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Card>
	)
})

export default PplDetailComponent
