import React, { useState } from 'react'
import { Form, Button, Modal, Spin, Row, Col } from 'antd'
import { vsmJournalGroups, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import InputComponent from '../../../../../components/InputComponent'

const AddComponent = (props) => {
	const [form] = Form.useForm()
	const { JournalGroupStore: { AddData, dropdown_list, getAllRecords } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fetchJournal, setFetchJournal] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournalGroups.add
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable & set value of journal type
	const handleChange = () => {
		let label = form.getFieldValue('parent_id') && dropdown_list.find(item => item.id === form.getFieldValue('parent_id'))
		form.getFieldValue('parent_id') && form.setFieldsValue({
			'journal_type': label.journal_type
		})
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
		setFetchJournal(true)
	}

	return (
		<Modal
			centered
			title="New Journal Group"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout="vertical"
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required type="select" label="Journal Group" name="parent_id" placeholder="Select Parent Journal Group"
							tooltip="Journal Group is a top level group of Journals that can be used as a Parent for other Journal Groups. For example: Assets, Liabilities, Income, Expenses."
							rules={vsmJournalGroups.validation.parent_id}
							onChange={handleChange}
							onFocus={() => fetchJournal && getAllRecords().then(() => setFetchJournal(false))}
							notFoundContent={fetchJournal ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: dropdown_list,
								value_key: 'id',
								text_key: 'journal_group_name',
								rejected_keys: dropdown_list && dropdown_list.map(x => x.parent_id !== 0 ? x.id : null)
							}}
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required label="Journal Subgroup" name="journal_group_name" placeholder="Journal Subgroup Name"
							tooltip="Journal Subgroups is a further classification of defined Journal Groups, with accordance to specific Journal common properties. For example: Assets group can have two Subgroups defined: Current Assets and Fixed Assets."
							rules={vsmJournalGroups.validation.journal_group_name}
							onChange={handleChange}
						/>
					</Col>
				</Row>

				<InputComponent
					type="radio_button" label="Journal Type" name="journal_type"
					tooltip="There are two Predefined Journal Types: P&L [Profit & Loss] and B/S [Balance Sheet]. Assign appropriate Journal Group for your Journal Subgroup and Journal Type will be selected automatically."
					onChange={handleChange}
					options={{
						values: [
							{ value: 'B/S', text: 'B/S' },
							{ value: 'P&L', text: 'P&L' },
						]
					}}
				/>
			</Form>
		</Modal>
	)
}


export default AddComponent
