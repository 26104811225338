import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Table } from 'antd';
import useStore from '../../../../../../store';
import { vsmNotify, vsmReconciliation } from '../../../../../../config/messages';
import { CurrencyFormat } from '../../../../../../utils/GlobalFunction';

const ListComponent = observer((props) => {
	const { ReconciliationStore, ReconciliationStore: { journalLogs, SelectData } } = useStore()
	const [saving, setSaving] = useState(false)

	// suggestion function
	const handleSuggestion = (data) => {
		ReconciliationStore.innerSelectedIds = { "journalID": data.id, "CTEID": data.cte_id }
		props.openSuggestionDrawer()
	}

	// select api call function
	const handleSelect = (data) => {
		let tteSelected = ReconciliationStore.agGridInner.api.getSelectedRows()
		let tte_selected_ids = tteSelected.map(item => item.id)
		let cte_selected_ids = tteSelected.map(item => item.cte_id)
		let journalLogPayload = { "cte_transaction_ids": ReconciliationStore.selectedIds, "tte_transaction_ids": tte_selected_ids, "log_cte_ids": cte_selected_ids }
		setSaving(true)
		SelectData(data, journalLogPayload).then(() => {
			vsmNotify.success({
				message: vsmReconciliation.reconcile
			})
		}).catch(e => {
			if (e.errors) { }
		}).finally(() => setSaving(false))
	}

	// action component
	const ActionHandle = ({ data }) => {
		return (
			data.reconciliation_to === "suggestion" ? (
				<Button size="small" type="primary" onClick={() => { handleSuggestion(data) }}>{data.reconciliation_to}</Button>
			) : (
				data.reconciliation_to === "view" ? (
					<Button size="small" type="primary" onClick={() => { props.openViewModal(data.cte_id) }}>{data.reconciliation_to + " #" + data.cte_id}</Button>
				) : (<Button disabled={saving} size="small" type="primary" onClick={() => { handleSelect(data) }}>{data.reconciliation_to}</Button>)
			)
		)
	};

	const verifyColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Date',
			dataIndex: 'date',
		},
		{
			title: 'TTE Transaction ID',
			dataIndex: 'transaction_id',
		},
		{
			title: 'Journal Type',
			dataIndex: 'journal_type',
		},
		{
			title: 'Code',
			dataIndex: 'code',
		},
		{
			title: 'Journal Label',
			dataIndex: 'journal_label',
		},
		{
			title: 'Mapped Value',
			key: 'mapped_value',
			render: (data) => {
				return data.mapped_value
			}
		},
		{
			title: 'Debit(+)',
			key: 'debit',
			align: "right",
			render: (data) => {
				if (data.debit) {
					return <CurrencyFormat value={data.debit} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
		{
			title: 'Credit(-)',
			key: 'credit',
			align: "right",
			render: (data) => {
				if (data.credit) {
					return <CurrencyFormat value={data.credit} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
		{
			title: 'Posting',
			key: 'posting',
			align: "right",
			render: (data) => {
				if (data.posting) {
					return <CurrencyFormat value={data.posting} />
				} else {
					return <CurrencyFormat value={0} />
				}
			},
		},
		{
			title: 'Reconciled To',
			key: 'reconciliation_to',
			render: (data) => <ActionHandle data={data} />
		}
	];

	return (
		<Table
			size='small'
			pagination={false}
			dataSource={journalLogs}
			columns={verifyColumns}
			rowKey="index"
			bordered
			// scroll={{ x: 1300 }}
			summary={pageData => {
				let totalDebit = 0;
				let totalCredit = 0;

				props.currentView === "verify" && journalLogs && journalLogs.length > 0 && pageData.forEach(({ debit, credit }) => {
					totalDebit += debit ? parseFloat(debit) : 0;
					totalCredit += credit ? parseFloat(credit) : 0;
				});

				let totalPosting = Math.round((parseFloat(totalDebit) - parseFloat(totalCredit)) * 100) / 100

				return props.currentView === "verify" && journalLogs && journalLogs.length > 0 && (
					<>
						<Table.Summary.Row>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
							<Table.Summary.Cell>
								<div className="text-right"><CurrencyFormat value={totalDebit} /></div>
							</Table.Summary.Cell>
							<Table.Summary.Cell>
								<div className="text-right"><CurrencyFormat value={totalCredit} /></div>
							</Table.Summary.Cell>
							<Table.Summary.Cell>
								<div className="text-right"><CurrencyFormat value={totalPosting} /></div>
							</Table.Summary.Cell>
							<Table.Summary.Cell></Table.Summary.Cell>
						</Table.Summary.Row>
					</>
				);
			}}
		/>
	)
})

export default ListComponent
