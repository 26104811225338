import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'

const ListComponent = observer((props) => {
	const { openTransactionList, list_data } = props
	const { ReconciliationStore, ReconciliationStore: { selectedIds, setupGridInner, getJournalLogsById, onFilterChanged } } = useStore()

	return (
		<div className="" style={{ height: '70vh', marginBottom: 24 }}>
			<div className="ag-theme-alpine grid_wrapper">
				<AgGridReact
					rowData={list_data}
					modules={AllModules}
					columnDefs={gridOptions.columnDefs}
					defaultColDef={LocalGridConfig.defaultColDef}
					columnTypes={LocalGridConfig.columnTypes}
					overlayNoRowsTemplate={vsmCommon.noRecord}
					frameworkComponents={{
						openTransactionList
					}}
					onColumnResized={ReconciliationStore.onChildnGridChanged}
					onColumnMoved={ReconciliationStore.onChildnGridChanged}
					onColumnPinned={ReconciliationStore.onChildnGridChanged}
					onSortChanged={ReconciliationStore.onChildnGridChanged}
					onGridReady={setupGridInner}
					gridOptions={LocalGridConfig.options}
					onFilterChanged={onFilterChanged}
					rowSelection={'multiple'}
					suppressRowClickSelection={true}
					onSelectionChanged={
						function (event) {
							let selected = event.api.getSelectedRows()
							let tte_selected_ids = selected.map(item => item.id)
							let cte_selected_ids = selected.map(item => item.cte_id)
							if (selected.length > 0) {
								let data = {
									"cte_transaction_ids": selectedIds,
									"tte_transaction_ids": tte_selected_ids,
									"log_cte_ids": cte_selected_ids
								}
								getJournalLogsById(data)
							} else { ReconciliationStore.journalLogs = null }
							return true
						}}
				/>
			</div>
		</div>
	)
})

export default ListComponent
