import React, { useEffect, useState } from 'react'
import { Form, Button, Drawer, Spin } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import { vsmClassifications, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [editValues, setEditValues] = useState(null)
	const [commonError, setCommonError] = useState(null)
	const { ClassificationStore, OrgBankAccountStore, OrganisationStore } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.customer_reference = data.customer_reference.filter(x => x);
		data.bank_reference = data.bank_reference.filter(x => x);
		data.transaction_details = data.transaction_details.filter(x => x);
		setSaving(true)
		data.id = editValues.id
		ClassificationStore.EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmClassifications.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			ClassificationStore.getRecordById({ id: props.classificationId }).then((data) => {
				setEditValues(data.data)
				ClassificationStore.cteTypesList = data.data.rules.cte_transactions_type_id
				OrgBankAccountStore.dropdown_currency_list = data.data.rules.currency_id
				OrgBankAccountStore.bankNameList = data.data.rules.banks
				var temp_role = data.data.rules.role_id ? data.data.rules.role_id : []
				var temp_related_role = data.data.rules.related_role_id ? data.data.rules.related_role_id : []
				OrganisationStore.dropdown_orgRole_list = [...temp_role, ...temp_related_role]
				ClassificationStore.setBankAccountList({ banks: data.data.rules.banks, banks_accounts: data.data.rules.bank_account_id })
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmClassifications.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props, ClassificationStore, OrgBankAccountStore, OrganisationStore])

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			let data = editValues.rules
			form.setFieldsValue({
				transaction_class_name: editValues.transaction_class_name,
				code: editValues.code,
				description: editValues.description,
				cte_transactions_type_id: data.cte_transactions_type_id ? data.cte_transactions_type_id.map(item => item && item && item.id) : [],
				currency_id: data.currency_id ? data.currency_id.map(item => item && item.id) : [],
				banks: data.banks ? data.banks.map(item => item && item.id) : [],
				bank_account_id: data.bank_account_id ? data.bank_account_id.map(item => item && item.id) : [],
				entity_type: data.entity_type ? data.entity_type : [],
				related_entity_type: data.related_entity_type ? data.related_entity_type : [],
				role_id: data.role_id ? data.role_id.map(item => item && item.id) : [],
				related_role_id: data.related_role_id ? data.related_role_id.map(item => item && item.id) : [],
				customer_reference: data.customer_reference ? data.customer_reference : [null],
				bank_reference: data.bank_reference ? data.bank_reference : [null],
				transaction_details: data.transaction_details ? data.transaction_details : [null],
				transaction_is: data.transaction_is,
				is_checked: data.is_checked,
			})
		}
	}, [editValues, props.visible, form])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			let values = form.getFieldsValue(["cte_transactions_type_id", "currency_id", "banks", "bank_account_id", "entity_type", "related_entity_type", "role_id", "related_role_id", "customer_reference", "bank_reference", "transaction_details"])
			let haserror = true;
			if (form.getFieldValue("transaction_is") === "Any") {
				Object.keys(values).forEach(item => {
					if (["customer_reference", "bank_reference", "transaction_details"].includes(item)) {
						if (values[item].filter(x => x !== null && x !== "" && x !== undefined).length > 0) {
							haserror = false
							return false
						}
					} else {
						if (values[item] && values[item].length > 0) {
							haserror = false
							return false
						}
					}
				})
				setCommonError(haserror && "Please select any one classification rule.")
				setDisabled(haserror)
			} else {
				setCommonError(null)
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		props.setId(null)
		setEditValues(null)
		setDisabled(true)
		form.resetFields()
		setCommonError(null)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit Classification - #${props.classificationId} - ${editValues && editValues.transaction_class_name}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="editform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="editform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout='vertical'
				>
					<FormComponent
						form={form}
						visible={props.visible}
						onChange={handleChange}
						commonError={commonError}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
