import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'antd'
import { vsmTransactionItems, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'
import FormComponent from './FormComponent'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { TransactionItemsStore: { AddData } } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.transaction_id = props.transactionId || null
		data.add_address = data.add_address ? 1 : 0
		setSaving(true)

		Object.keys(props.itemPayload).forEach(item => {
			data[item] = props.itemPayload[item]
		})
		// For a-ETR Only
		if (data.asset_type) {
			data.asset_type = data.asset_type === 1 ? 'fixed' : 'current'
		}
		AddData(data).then(() => {
			close()
			if (props.handleApiCall) { props.handleApiCall() }
			vsmNotify.success({
				message: vsmTransactionItems.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return (
		<FormComponent
			tab={props.tab}
			form={form}
			form_id="addform"
			title="Transaction Items"
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			formButtons={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" form='addform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div>
			]}
		/>
	)
})

export default AddComponent
