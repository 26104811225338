import React, { useState } from 'react'
import { Card, Form, Input, Button, Alert, Popover } from 'antd'
import useStore from '../store'
import { vsmAuth, vsmNotify } from '../config/messages'
import { Link } from 'react-router-dom'

const Login = () => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const { UserStore: { doLogin } } = useStore()
	const [commonError, setCommonError] = useState(null)
	const [tooltip, setTooltip] = useState(false)

	// make function call to login
	const handleSubmit = (data) => {
		setLoading(true)
		doLogin(data).then(() => {
			vsmNotify.success({
				message: vsmAuth.success
			})
		}).catch(errors => {
			if (errors && errors.notify) { setCommonError(errors.notify) }
			if (errors && errors.errors) { form.setFields(errors.errors) }
			setLoading(false)
		})
	}

	// password criteria tool tip
	const passwordTooltip = (
		<div>
			<div>at least 1 numeric character</div>
			<div>at least 1 special character</div>
			<div>at least 1 uppercase letter</div>
			<div>at least 8  character</div>
		</div>
	);

	// handle password tool tip visiblility
	const handleChangePassword = (e) => {
		form.validateFields(['password']).then(() => {
			setTooltip(false)
		}).catch(() => {
			setTooltip(true)
		})
	}

	return (
		<div className="login__wrapper">
			<Form form={form} className="w400" onFinish={handleSubmit}>
				<Card title="Login">
					{commonError && <Alert style={{ marginBottom: 15 }} message={commonError} type="error" showIcon />}
					<Form.Item name="email" rules={vsmAuth.validation.email} hasFeedback>
						<Input placeholder="Email Address" />
					</Form.Item>
					<Popover placement="topRight" content={passwordTooltip} visible={tooltip}>
						<Form.Item name="password" rules={vsmAuth.validation.password} hasFeedback >
							<Input.Password placeholder="Password" onBlur={() => setTooltip(false)} onChange={handleChangePassword} onFocus={handleChangePassword} />
						</Form.Item>
					</Popover>
					<div className="text-center">
						<Button loading={loading} htmlType="submit" block type="primary">LOGIN</Button>
					</div>
					<div className="d-flex justify-content-end">
						<Link to="/forget-password" type="link" className="p-0 mt-10"><b>Forgot Password?</b></Link>
					</div>
				</Card>
			</Form>
		</div>
	)
}

export default Login
