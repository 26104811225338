import React from 'react'
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import PageNotFound from '../pages/PageNotFound';
import ForgetPassword from "../pages/ForgetPassword";
import JournalGroups from "../pages/TradeTransaction/Administration/JournalGroups";
import TaxSettings from "../pages/TradeTransaction/Administration/TaxSettings";
import TransactionFields from "../pages/TradeTransaction/Administration/TransactionFields";
import Algorithms from "../pages/TradeTransaction/Administration/Algorithms";
import { default as AlgorithmsAddComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/AddComponent';
import { default as AlgorithmsEditComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/EditComponent';
import { default as AlgorithmsViewComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/ViewComponent';
import ResetPassword from "../pages/ResetPassword";
import UserManual from '../pages/UserManual';
import TransactionTypes from '../pages/TradeTransaction/Administration/TransactionType/TransactionTypes';
import TransactionSubTypes from '../pages/TradeTransaction/Administration/TransactionType/TransactionSubTypes';
import TransactionFieldMapping from '../pages/TradeTransaction/Administration/TransactionType/TransactionFieldMapping';
import Journals from '../pages/TradeTransaction/Administration/Journals';
import TransactionModels from '../pages/TradeTransaction/Administration/TransactionModels';
import { default as TransactionModalAddComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/AddComponent';
import { default as TransactionModalEditComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/EditComponent';
import { default as TransactionModalVerifyComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/VerifyComponent';
import Import from '../pages/TradeTransaction/Import';
import ImportAddComponent from '../pages/TradeTransaction/Import/component/AddComponent'
import Organisations from "../pages/OrganisationManagement/AllOrganisations";
import Roles from "../pages/OrganisationManagement/Administration/Roles";
import Ledgers from "../pages/Setup/Ledgers/AllLedgers";
import { default as LedgersAddComponent } from '../pages/Setup/Ledgers/AllLedgers/component/AddComponent';
import { default as LedgersEditComponent } from '../pages/Setup/Ledgers/AllLedgers/component/EditComponent';
import LedgersSettings from "../pages/Setup/Ledgers/LedgerSettings";
import UserManagement from '../pages/Setup/UserManagement';
import RoleManagement from '../pages/Setup/RoleManagement'
import LedgersBankAccounts from '../pages/Setup/Ledgers/BankAccounts'
import RoleMapping from "../pages/OrganisationManagement/RoleMapping";
import BankAccounts from "../pages/OrganisationManagement/BankAccounts";
import Organisation_Status from '../pages/OrganisationManagement/OrganisationStatus';
import SavedReports from '../pages/Reports/SavedReports';
import { default as SavedReportsAddComponent } from '../pages/Reports/SavedReports/components/AddComponent';
import AuditLogs from "../pages/Setup/Ledgers/AuditLogs"
import OldImport from '../pages/TradeTransaction/OldImport';
import AllTransactions from '../pages/TradeTransaction/AllTransactions';
import PreviewReportComponent from '../pages/Reports/SavedReports/components/PreviewReportComponent';
import PreviewDataComponent from '../pages/Reports/SavedReports/components/PreviewDataComponent';
import { default as EditReportComponent } from '../pages/Reports/SavedReports/components/EditComponent';
import RegularExpression from '../pages/CashTransaction/RegularExpression';
import CashTransactions from '../pages/CashTransaction/CashTransactions'
import Classifications from '../pages/CashTransaction/Classifications';
import Reconciliation from '../pages/CashTransaction/Reconciliation';
import CTEImport from '../pages/CashTransaction/Import'
import People from '../pages/PeopleManagement/AllPeople'
import PeopleBankAccounts from '../pages/PeopleManagement/BankAccounts'
import PeopleRoleMapping from '../pages/PeopleManagement/RoleMapping'
import PeopleRoles from '../pages/PeopleManagement/Administration/Roles'
import FXCurrencies from '../pages/Administration/FXCurrencies'
import ViewPeople from '../pages/PeopleManagement/AllPeople/component/ViewComponent'
import ViewOrganisation from '../pages/OrganisationManagement/AllOrganisations/component/ViewComponent';
import ManualJournalLog from '../pages/TradeTransaction/Administration/ManualJournalLog';
import Triggers from "../pages/TradeTransaction/Administration/Triggers";
import GlobalSetting from '../pages/Setup/GlobalSetting'
import CashToCashReconciliation from '../pages/CashTransaction/CashToCashReconciliation';
import SummaryReport from '../pages/Reports/SummaryReport';
import aETRReports from '../pages/Reports/aETRReports';
import QueryBankVsCash from '../pages/CashTransaction/QueryBankVsCash';
import BankAuditReport from '../pages/Reports/BankAuditReport';
import FxGainlossChildReport from '../pages/Reports/FxGainlossChildReport';

export const RouterConfig = [
	{
		title: 'Login',
		path: '/',
		component: Login,
		default: true
	},
	{
		title: 'User Manual',
		path: '/user-manual',
		component: UserManual,
		auth: true,
	},
	{
		title: 'Forget Password',
		path: '/forget-password',
		component: ForgetPassword,
		default: true
	},
	{
		title: 'Reset Password',
		path: '/reset/:id',
		component: (props) => <ResetPassword {...props} />,
		default: true
	},
	{
		title: 'Dashboard',
		path: '/dashboard',
		component: Dashboard,
		authdefault: true,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Journal Groups',
		path: '/administration/trade/journal-groups',
		component: JournalGroups,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Tax Settings',
		path: '/administration/trade/tax-settings',
		component: TaxSettings,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Fields',
		path: '/administration/trade/transaction-fields',
		component: TransactionFields,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Journals',
		path: '/administration/trade/journals',
		component: Journals,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Types',
		path: '/administration/trade/trade-transaction/trade-type',
		component: TransactionTypes,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Sub Types',
		path: '/administration/trade/trade-transaction/transaction-type',
		component: TransactionSubTypes,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Field Mapping',
		path: '/administration/trade/trade-transaction/trade-mapping',
		component: TransactionFieldMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: ' Trade to Transaction Map',
		path: '/administration/trade/transaction-model',
		component: TransactionModels,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: ' Trade to Transaction Map',
		path: '/administration/trade/transaction-model/new',
		component: TransactionModalAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: ' Trade to Transaction Map',
		path: '/administration/trade/transaction-model/edit/:id',
		component: TransactionModalEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: ' Trade to Transaction Map',
		path: '/administration/trade/transaction-model/verify/:id',
		component: TransactionModalVerifyComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/administration/trade/algorithms',
		component: Algorithms,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/administration/trade/algorithms/new',
		component: AlgorithmsAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/administration/trade/algorithms/edit/:id',
		component: AlgorithmsEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/administration/trade/algorithms/view/:id',
		component: AlgorithmsViewComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import',
		path: '/administration/trade/import-log',
		component: Import,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import Trade Transaction',
		path: '/trade-transactions/import-batch',
		component: ImportAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import Trade Transaction',
		path: '/administration/trade/import-log/new',
		component: ImportAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Organisations',
		path: '/organisations/all-organisations',
		component: Organisations,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Role Mapping',
		path: '/organisations/all-organisations/role-mapping/:id',
		component: RoleMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'User & Role Management',
		path: '/setup/all-users',
		component: UserManagement,
		auth: true,
		setting: { header: true, nav: true }
	}, {
		title: 'User Roles',
		path: '/setup/manage-user-profiles',
		component: RoleManagement,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Listing',
		path: '/organisations/all-organisations/bank-accounts',
		component: BankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Accounts',
		path: '/organisations/all-organisations/bank-accounts/:id',
		component: BankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'roles',
		path: '/organisations/administration/roles',
		component: Roles,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Organisation Status',
		path: '/organisations/administration/status',
		component: Organisation_Status,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/all-ledgers',
		component: Ledgers,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/add-ledgers',
		component: LedgersAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/all-ledgers/edit/:id',
		component: LedgersEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledger Settings',
		path: '/setup/ledger-settings',
		component: LedgersSettings,
		ledgerDefault: true,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers Bank Accounts',
		path: '/setup/bank-accounts',
		component: LedgersBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Saved Reports',
		path: '/reports/saved-reports',
		component: SavedReports,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Import Summary',
		path: '/reports/bank-import-summary',
		component: SummaryReport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Asset Management',
		path: '/reports/asset-management',
		component: aETRReports,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'FX-Gain-Loss',
		path: '/reports/fx-gain-loss',
		component: FxGainlossChildReport,
		authdefault: true,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Create Reports',
		path: '/reports/saved-reports/new',
		component: SavedReportsAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Audit Logs',
		path: '/setup/audit-logs',
		component: AuditLogs,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Transactions',
		path: '/trade-transactions/view-all',
		component: AllTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Old Import',
		path: '/trade-transactions/old-import',
		component: OldImport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Reports',
		path: '/reports/saved-reports/preview-report',
		component: PreviewReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Transactions',
		path: '/trade-transactions/view-all/:id',
		component: AllTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Reports',
		path: '/reports/saved-reports/preview-report/:report_id',
		component: PreviewReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Data',
		path: '/reports/saved-reports/preview-data',
		component: PreviewDataComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Data',
		path: '/reports/saved-reports/preview-data/:report_id',
		component: PreviewDataComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Reports',
		path: '/reports/saved-reports/edit-report',
		component: EditReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Reports',
		path: '/reports/saved-reports/edit-report/:report_id',
		component: EditReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Audit',
		path: '/cash-transactions/bank-audit',
		component: BankAuditReport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Formula',
		path: '/administration/cash/transaction-formula',
		component: RegularExpression,
		auth: true,
		setting: { header: true, nav: true }
	}, {
		title: 'Cash Transactions',
		path: '/cash-transactions/view-all',
		component: CashTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Cash Transactions',
		path: '/cash-transactions/view-all/:id',
		component: CashTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Codes Analysis',
		path: '/administration/cash/bank-codes-analysis',
		component: Classifications,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Cash to Trade Reconciliation',
		path: '/administration/cash/cash-to-trade-reconciliation',
		component: Reconciliation,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank-to-Bank Reconciliation',
		path: '/administration/cash/bank-to-bank-reconciliation',
		component: CashToCashReconciliation,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Vs Cash',
		path: '/administration/cash/bank-cash',
		component: QueryBankVsCash,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import Batch',
		path: '/cash-transactions/import-batch',
		component: CTEImport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'People Management',
		path: '/people/all-people',
		component: People,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Role Mapping',
		path: '/people/all-people/role-mapping/:id',
		component: PeopleRoleMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Listing',
		path: '/people/all-people/bank-accounts',
		component: PeopleBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Accounts',
		path: '/people/all-people/bank-accounts/:id',
		component: PeopleBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'User Roles',
		path: '/people/roles',
		component: PeopleRoles,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'FX Currencies Management',
		path: '/administration/currencies',
		component: FXCurrencies,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View Organisation',
		path: '/organisations/all-organisations/view-organisation/:id',
		component: ViewOrganisation,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View People',
		path: '/people/all-people/view-people/:id',
		component: ViewPeople,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Manual Journal Log',
		path: '/administration/trade/manual-journal-log',
		component: ManualJournalLog,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Triggers',
		path: '/administration/trade/triggers',
		component: Triggers,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Global Setting',
		path: '/setup/global-settings',
		component: GlobalSetting,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Page Not Found',
		path: '/',
		component: PageNotFound,
		errorpage: true,
		setting: { header: true, nav: true }
	},
]
