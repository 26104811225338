import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Drawer, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'
import moment from 'moment'

const EditComponent = observer((props) => {
	const [form] = useForm()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [editValues, setEditValues] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [relatedTo, setRelatedTo] = useState(null)
	const [entityType, setEntityType] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const [customStartDate, setCustomStartDate] = useState(false)
	const { OrgBankAccountStore, OrganisationStore, AllTransactionStore, AllTransactionStore: { EditData,
		getRecordById, getCsvModals, setStatus, setVatRate, setMappingList, getVatRateList,
		setDependent } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore
	}

	// Handle submit and call function to save edited record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.posted_on = moment(data.posted_on).format("YYYY-MM-DD")
		data.traded_on = moment(data.traded_on).format("YYYY-MM-DD")
		data.model = data.model ? data.model.filter(x => (x.model === true)) : null
		data.model = data.model && data.model.map((item) => ({ model: item.id, field: item.field }));
		if ([1, 112].includes(data.type_field_mapping_id)) {
			let currentNewDate = new Date()
			let curentData = moment(currentNewDate).format("YYYY-MM-DD")

			// Diff Traded_On - current (in months)
			data.current_period = Math.ceil(moment(curentData).diff(moment(data.traded_on), 'months', true))

			// data.period * data.period_type (Count in days)
			if (data.traded_on === moment().format('YYYY-MM-DD')) {
				data.period_number_days = '0'
			} else {
				data.period_number_days = data.period * (data.period_type === 1 ? 1 : data.period_type === 2 ? 7 : data.period_type === 3 ? 30 : data.period_type === 4 ? 365 : 1)
			}

			// diff Traded_On - current (in Days)
			data.period_days_passed = moment(curentData).diff(moment(data.traded_on), 'days', true)
			if (data.csv_columns.find(_x => _x.field_name === 'EDSO').default_value) {
				data.edso = data.csv_columns.find(_x => _x.field_name === 'EDSO')?.default_value
			} else {
				data.edso = null
			}
			if (data.csv_columns.find(_x => _x.field_name === 'Batch ID').default_value) {
				data.batch_id = data.csv_columns.find(_x => _x.field_name === 'Batch ID')?.default_value
			} else {
				data.batch_id = null
			}
			let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
			let temp_csv_columns = []
			csvColumn.forEach((item) => {
				if (inCSV_Columns_payload.includes(item.field_name)) {
					switch (item.field_name) {
						case 'Asset Type':
							item.default_value = data.asset_type
							delete data.asset_type
							break;
						case 'Sell Rate':
							item.default_value = data.sell_rate.toString() ? data.sell_rate ? (data.sell_rate / 100).toString() : '0' : item.default_value
							delete data.sell_rate
							break;
						case 'Period':
							item.default_value = data.period.toString() ? data.period ? data.period.toString() : '0' : item.default_value
							break;
						case 'Period Type':
							item.default_value = data.period_type
							break;
						case 'Tax Type Inc or Exc':
							item.default_value = data.tax_type
							delete data.tax_type
							break;
						case 'EDSO':
							item.default_value = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
							break;
						case 'Repair and Maintenance':
							item.default_value = data.repair_maintenance.toString() ? data.repair_maintenance ? data.repair_maintenance.toString() : '0' : item.default_value
							delete data.repair_maintenance
							break;
						case 'EoT Value':
							item.default_value = data.eot.toString() ? data.eot ? data.eot.toString() : '0' : item.default_value
							delete data.eot
							break;
						case 'Batch ID':
							item.default_value = data.batch_id ? data.batch_id.toString() ? data.batch_id ? data.batch_id.toString() : '0' : null : item.default_value
							// delete data.batch_id
							break;
						case 'Exchange Ref ID':
							item.default_value = data.exchange_ref_id ? data.exchange_ref_id.toString() ? data.exchange_ref_id ? data.exchange_ref_id.toString() : '0' : null : item.default_value
							delete data.exchange_ref_id
							break;
						case 'Reference':
							item.default_value = (data.reference && data.reference.length) ? data.reference ? data.reference.toString() ? data.reference ? data.reference.toString() : '0' : null : item.default_value : null
							delete data.reference
							break;
						case 'Tax ID On Sales':
							item.default_value = data.sales_vat_rate_id.toString() ? data.sales_vat_rate_id ? data.sales_vat_rate_id.toString() : '0' : item.default_value
							delete data.sales_vat_rate_id
							break;
						case 'Current Period':
							// item.default_value = data.current_period.toString() // dynamic
							item.default_value = '1'
							delete data.current_period
							break;
						case 'Period Number Days':
							item.default_value = data.period_number_days.toString()
							delete data.period_number_days
							break;
						case 'Period Days Passed':
							item.default_value = data.period_days_passed.toString()
							delete data.period_days_passed
							break;
						default:
							break;
					}
					temp_csv_columns.push(item)
				} else {
					temp_csv_columns.push(item)
				}
			})
			data.csv_columns = temp_csv_columns

			// Transaction Items
			if (data.transaction_items) {
				data.transaction_items.forEach((item, index) => {
					if (!item.id) {
						csvColumn && csvColumn.forEach((CSVdata) => {
							if (inCSV_Columns_payload.includes(CSVdata.field_name)) {
								switch (CSVdata.field_name) {
									case 'Asset Type':
										item.asset_type = CSVdata.default_value === 1 ? '1' : '0'
										break;
									case 'Batch ID':
										item.batch = CSVdata.default_value
										break;
									case 'EoT Value':
										item.eot = CSVdata.default_value
										break;
									case 'Repair and Maintenance':
										item.rm_price = CSVdata.default_value
										break;
									case 'Sell Rate':
										item.sell_rate = CSVdata.default_value
										break;
									case 'Tax Type Inc or Exc':
										item.tax_type_inc_exc = CSVdata.default_value
										break;
									default:
										break;
								}
								// return CSVdata;
							}
						})
						item.months = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
						item.classification = item.classification ? item.classification.toString() : item.classification
						item.tax_settings_id = data.tax_settings_id
					} else {
						item.months = data.period_type === 1 ? Math.ceil(data.period / 30) : data.period_type === 2 ? Math.ceil((data.period * 7) / 30) : data.period_type === 3 ? data.period : data.period_type === 4 ? Math.ceil(data.period * 12) : 1
					}
				})
			} else {
				data.transaction_items = null
			}
			delete data.period
			delete data.period_type
			if (data.address_1 && data.country_id) {
				data.common_address = {
					address_1: data.address_1,
					address_2: data.address_2,
					address_3: data.address_3,
					city: data.city,
					state_county: data.state_county,
					postal_code: data.postal_code,
					country_id: data.country_id
				}
				delete data.address_1
				delete data.address_2
				delete data.address_3
				delete data.city
				delete data.state_county
				delete data.postal_code
				delete data.country_id
				data.add_address = 1
			} else {
				data.common_address = null
				data.add_address = 0
			}
		}

		EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.edit
			})
		}).catch(e => {
			e.errors && e.errors.forEach((error) => {
				if (JSON.stringify(error.name) === JSON.stringify(['csv_columns', 16, 'default_value']) || JSON.stringify(error.name) === JSON.stringify(['csv_columns', 8, 'default_value'])) {
					error['name'] = 'reference'
					return error
				}
			})
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			getRecordById({ id: props.transactionId }).then((data) => {
				setEditValues(data.data)
				setDependent(data.data)
				setMappingList([data.data.type_field_mapping])
				setStatus([data.data.status])
				setVatRate([data.data.tax_setting])
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmAllTransactions.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props.visible, props.transactionId, getRecordById, setDependent, setMappingList, setStatus, setVatRate, store.organisation])

	useEffect(() => {
		if (editValues && props.visible) {
			getCsvModals(editValues.type_field_mapping_id, "view", editValues.selected_models)
			getVatRateList()
		}
	}, [getCsvModals, props.visible, editValues, getVatRateList])

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			store.bank.setCurrency([editValues.global_currency])
			setCustomStartDate(editValues?.is_custom_start_date)
			form.setFieldsValue({
				role_id: editValues.role_id,
				related_role_id: editValues.related_role_id,
				entity_id: editValues.entity_id ? editValues.entity_id : null,
				related_entity_type: editValues.related_entity_type,
				related_entity_id: editValues.related_entity_id ? editValues.related_entity_id : null,
				type_field_mapping_id: editValues.type_field_mapping_id,
				currency_id: parseInt(editValues.currency_id),
				face_value: editValues.face_value.replace(/,/g, ''),
				status_id: editValues.status_id,
				tax_settings_id: editValues.tax_settings_id,
				posted_on: editValues.posted_on && moment(editValues.posted_on),
				traded_on: editValues.traded_on && moment(editValues.traded_on),
				issued_on: editValues?.issued_on && moment(editValues.issued_on),
				start_date: editValues?.start_date && moment(editValues.start_date),
				csv_columns: editValues.csv_columns,
				model: editValues.selected_models,
				repayment_schedule: editValues?.repayment_schedule,
				ltv_value: editValues?.ltv_value,
				transaction_items: editValues.transactions_items && editValues.transactions_items.length ? editValues.transactions_items : AllTransactionStore.transactionItems.transaction_items
			})
			if ([1, 112].includes(form.getFieldValue('type_field_mapping_id'))) {
				let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
				csvColumn && csvColumn.forEach((item) => {
					if (inCSV_Columns_payload.includes(item.field_name)) {
						switch (item.field_name) {
							case 'Asset Type':
								form.setFieldsValue({ asset_type: parseInt(item.default_value) })
								break;
							case 'Sell Rate':
								form.setFieldsValue({ sell_rate: item.default_value ? parseFloat(item.default_value * 100).toFixed(2) : item.default_value * 100 })
								break;
							case 'Period':
								form.setFieldsValue({ period: item.default_value })
								break;
							case 'Period Type':
								form.setFieldsValue({ period_type: parseInt(item.default_value) })
								break;
							case 'Tax Type Inc or Exc':
								form.setFieldsValue({ tax_type: parseInt(item.default_value) })
								break;
							case 'EDSO':
								form.setFieldsValue({ EDSO: item.default_value })
								break;
							case 'Repair and Maintenance':
								form.setFieldsValue({ repair_maintenance: item.default_value ? item.default_value : 0 })
								break;
							case 'EoT Value':
								form.setFieldsValue({ eot: item.default_value ? item.default_value : 0 })
								break;
							case 'Batch ID':
								form.setFieldsValue({ batch_id: item.default_value })
								break;
							case 'Exchange Ref ID':
								form.setFieldsValue({ exchange_ref_id: item.default_value ? item.default_value : '' })
								break;
							case 'Reference':
								form.setFieldsValue({ reference: item.default_value ? item.default_value : '' })
								break;
							case 'Tax ID On Sales':
								form.setFieldsValue({ sales_vat_rate_id: parseInt(item.default_value) })
								break;
							case 'Current Period':
								form.setFieldsValue({ current_period: item.default_value })
								break;
							case 'Period Number Days':
								form.setFieldsValue({ period_number_days: item.default_value })
								break;
							case 'Period Days Passed':
								form.setFieldsValue({ period_days_passed: item.default_value })
								break;

							default:
								break;
						}
					}
				})
			} else {
				form.setFieldsValue({
					csv_columns: editValues.csv_columns
				})
			}
			for (let index = 0; index < editValues.transactions_items.length; index++) {
				const element = editValues.transactions_items[index];
				if (element.add_address === 1) {
					form.setFieldsValue({
						add_address: element.add_address ? true : false,
						address_1: element.address_1,
						address_2: element.address_2,
						address_3: element.address_3,
						city: element.city,
						state_county: element.state_county,
						postal_code: element.postal_code,
						country_id: element.country && element.country.id && element.country.id
					})
					break;
				}
			}
			setCsvColumn(editValues.csv_columns)
			setEntityType(editValues.entity_type)
			setRelatedTo(editValues.related_entity_type)
			setOrgData(editValues.entity_type === 2 ? editValues.peoples : editValues.organisations)
			setRelatedOrgData(editValues.related_entity_type === "People" ? editValues.related_peoples : editValues.related_organisations)
		}
	}, [editValues, OrganisationStore, store.bank, props.visible, form, AllTransactionStore.transactionItems.transaction_items, csvColumn])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		})
	}

	// Reset form and close edit form
	const close = () => {
		props.close()
		props.setId(null)
		setEditValues(null)
		setDisabled(true)
		setCsvColumn(null)
		setEntityType(null)
		setRelatedTo(null)
		setRelatedOrgData(null)
		setOrgData(null)
		AllTransactionStore.csvModals = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit Trade Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			className={editValues && [1, 112].includes(editValues.type_field_mapping_id) ? 'remove-error-text' : ''}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="editform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="editform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout="vertical"
					initialValues={AllTransactionStore.transactionItems}
				>
					<FormFieldComponent
						form={form}
						onChange={handleChange}
						currentValues={editValues}
						tab={"edit"}
						entity_type={entityType}
						related_to={relatedTo}
						orgData={orgData}
						relatedOrgData={relatedOrgData}
						csvColumn={csvColumn}
						customStartDate={customStartDate}
						setCustomStartDate={setCustomStartDate}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
