import React from 'react'
import { CurrencyFormat, DateComparator } from '../../../../../utils/GlobalFunction'

export const gridOptions = {
	columnDefs: [{
		checkboxSelection: true,
		filter: false,
		sortable: false,
		floatingFilter: false,
		width: 70,
		suppressMenu: true,
		pinned: 'left',
	}, {
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left'
	}, {
		headerName: 'Exchange ID',
		field: 'exchange_ref_id',
	}, {
		headerName: 'Transaction Ref',
		field: 'transaction_ref',
	}, {
		headerName: 'Traded',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Posted',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		type: 'rightAligned',
		cellRendererFramework: (data) => {
			return data.data && <CurrencyFormat value={data.data.face_value ? data.data.face_value : 0} />
		}
	}, {
		headerName: 'Outstanding',
		field: 'outstanding',
		type: 'rightAligned',
		cellRendererFramework: (data) => {
			return data.data && <CurrencyFormat value={data.data.outstanding ? data.data.outstanding : 0} />
		}
	}]
}
