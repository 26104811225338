import React, { useState } from 'react'
import { Form, Button, Modal, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { vsmNotify, vsmJournals } from '../../../../../config/messages'
import useStore from '../../../../../store'
import InputComponent from '../../../../../components/InputComponent'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { JournalsStore: { AddData }, JournalGroupStore, TaxSettingStore } = useStore()
	const store = {
		JournalGroup: JournalGroupStore,
		TaxSetting: TaxSettingStore
	}
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.journal_type = form.getFieldValue('journal_group_id') && store.JournalGroup.dropdown_list.find(item => item.id === form.getFieldValue('journal_group_id')).journal_type
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournals.add
			})
			setDisabled(true)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable / enable & set value of journal type
	const handleChange = (value) => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return (
		<Modal
			centered
			title="New Journal"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form
				form={form}
				id="addform"
				onFinish={handleSubmit}
				layout='vertical'
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required type="select" label="Journal Group" name="journal_group_id" placeholder="Select Journal Group"
							tooltip="Journal Groups is a library of custom-defined groups of Journals , example: Assets , Liabilities."
							rules={vsmJournals.validation.journal_groups}
							onChange={handleChange}
							options={{
								values: store.JournalGroup.dropdown_list,
								value_key: 'id',
								text_key: { key: ["journal_group_name", " - ", "journal_type"] }
							}}
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required
							label="Code"
							name="journal_code"
							placeholder="Code"
							tooltip="Code (of a Journal) is a mandatory parameter of a Journal."
							rules={vsmJournals.validation.journal_code}
							onChange={handleChange}
							maxLength={10}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required
							label="Label"
							name="journal_label"
							placeholder="label"
							tooltip="A Journal is an account against which Journal Entries are stored, e.g. in accounts this would be one nominal account in the chart of accounts."
							rules={vsmJournals.validation.journal_label}
							onChange={handleChange}
							maxLength={100}
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required type="select" label="Tax Setting" name="tax_setting_id" placeholder="Select Tax Settings"
							tooltip="Taxes are defined based on Government regulations in your country and are linked with Journals, i.e. Sales VAT, Tax on Goods, No Tax."
							rules={vsmJournals.validation.tax_settings_id}
							onChange={handleChange}
							options={{
								values: store.TaxSetting.dropdown_list,
								value_key: 'id',
								text_key: { key: ["tax_name", " - ", "tax_value", "%"] }
							}}
						/>
					</Col>
				</Row>

				<InputComponent
					required
					type="textarea"
					label="Description"
					name="journal_desc"
					placeholder="Description"
					tooltip="It is used to further describe the Journal details and its purpose."
					rules={vsmJournals.validation.journal_desc}
					onChange={handleChange}
					maxLength={1000}
					autoSize={{ minRows: 3 }}
				/>

			</Form>
		</Modal >
	)
})

export default AddComponent
