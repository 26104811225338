import React from 'react'
import { FileTextFilled, EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import useStore from '../../../../../store'
import { CurrencyFormat, DateComparator } from '../../../../../utils/GlobalFunction'

export const ActionRenderer = (props) => {
	const { openSetViewImportLogDetails, openVerifyDrawer, openViewDrawer } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{/* Go to VerifyScreen */}
			{[2, 4, 5].includes(props.data.status) ? (
				UserStore.checkPermission(76, 'edit') && (
					<Button type="text" title="Verifying Mode" onClick={() => { openVerifyDrawer(props.data) }}><FontAwesomeIcon className={![4, 5].includes(props.data.status) ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
				)
			) : (
				UserStore.checkPermission(76, 'edit') && (
					<Button type="text" title="Verifying Mode" disabled><FontAwesomeIcon className={props.data.status !== 4 ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
				)
			)}
			<Button type="text" title="View Detailed Log" onClick={() => { openSetViewImportLogDetails(props.data) }}><FileTextFilled className={props.data.error_in_model ? "redVerifyIcon" : "greenVerifyIcon"} /></Button>
			{UserStore.checkPermission(76, 'view') && (
				<Button type="text" title="View Cash Transaction" onClick={() => { openViewDrawer(props.data.id) }}><EyeOutlined /></Button>
			)}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Date',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185,
	}, {
		headerName: 'Transaction Details',
		field: 'transaction_details',
		tooltipField: 'transaction_details',
		width: 220
	}, {
		headerName: 'Transaction Type',
		field: 'cash_transactions_types.type_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Amount (Debit)',
		field: 'debit',
		type: 'rightAligned',
		cellRendererFramework: function (data) {
			return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
		},
		width: 110
	}, {
		headerName: 'Amount (Credit)',
		field: 'credit',
		type: 'rightAligned',
		cellRendererFramework: function (data) {
			return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
		},
		width: 110
	}, {
		headerName: 'Bank ref',
		field: 'bank_reference',
		width: 110
	}, {
		headerName: 'Classification',
		field: 'cte_transaction_classifications.transaction_class_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 100
	}, {
		headerName: 'Organisation / People Name',
		field: 'entity_name',
		valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))) : (params.data.organisations && params.data.organisations.organisation_name)),
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Role',
		field: 'role_name',
		valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Related Organisation / People Name',
		field: 'related_entity_name',
		valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Related Role',
		field: 'related_role_name',
		valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Status',
		field: 'status_text',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 110
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 100
	}]
}
