import Axios from "axios"
import { decorate, observable, action } from "mobx"
import GridConfig from "../../config/GridConfig"
import { convertError, globalStatus } from "../../utils/GlobalFunction"

export default class PeopleStore {
	list_data = []
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	agGrid = null
	agGridRelOrg = null
	deleteValues = null
	viewValues = null
	peopleValues = null
	rPeopleValues = null
	dropdown_pplRole_list = null
	dropdown_RpplRole_list = null
	dropdown_RPpl_list = null
	drowpdown_people_list = null
	fetching = false
	list_related_ORG = []
	list_related_Ppl = []
	list_trade_transaction = []
	list_cash_transaction = []
	list_banks = []
	peopleCardValue = null
	rPeopleCardValue = null

	initialValues = {
		phone: [{ id: null, contact_for: 'Primary' }],
		email: [{ id: null, contact_for: 'Primary' }],
	}

	//set delete values
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	//set view people values
	setViewValues = (data) => {
		this.viewValues = data
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	getFilter = (params) => {
		if (localStorage.getItem('params')) {
			var temp = JSON.parse(localStorage.getItem('params'))
			if (temp.PEOPLE) {
				params = { "request": temp.PEOPLE }
			}
		}
		return params
	}

	setFilter = (param) => {
		if (localStorage.getItem('params')) {
			var temp = JSON.parse(localStorage.getItem('params'))
			localStorage.setItem("params",
				JSON.stringify({ ...temp, "PEOPLE": { "filter": param.getFilterModel(), "sort": param.getSortModel() } })
			)
		} else {
			localStorage.setItem("params",
				JSON.stringify({ "PEOPLE": { "filter": param.getFilterModel(), "sort": param.getSortModel() } })
			)
		}
	}

	// reset all the server side filters
	handleReset = () => {
		localStorage.removeItem('people_grid')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		let columnConfig = localStorage.getItem('people_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
		let param = this.getFilter(params)
		if (param && param.request) {
			this.agGrid.api.setFilterModel(param.request.filter)
			this.agGrid.api.setSortModel(param.request.sort)
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('people_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('people_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('people_grid', columnConfig)
		}
	}

	// Setup grid and set column size to autosize
	setupGridRelatedOrganisation = (params) => {
		this.agGridRelOrg = params
	}

	// Setup grid and set column size to autosize
	setupGridRelatedPeople = (params) => {
		this.agGridRelPpl = params
	}

	// Setup grid and set column size to autosize
	setupGridTradeTransactions = (params) => {
		this.agGridTradeTransaction = params
	}

	// Setup grid and set column size to autosize
	setupGridCashTransactions = (params) => {
		this.agGridCashTransaction = params
	}

	// Setup grid and set column size to autosize
	setupGridBanks = (params) => {
		this.agGridBanks = params
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('people_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				var payload = {
					filter_data: params.request.filterModel,
					sort_data: params.request.sortModel,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.setFilter(params.api)
				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('people_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

	getList = (payload = {}) => {
		return Axios.post(`global/people/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.people_name = (item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')

					item.created_by = item.user && (item.user.first_name ? item.user.first_name : '') + ' ' + (item.user.last_name ? item.user.last_name : '')
					item.all_address = item.address
					item.address = (item.all_address && item.all_address.length > 0) ? (
						(item.all_address[0].address_1 ? item.all_address[0].address_1 + ', ' : '') +
						(item.all_address[0].address_2 ? item.all_address[0].address_2 + ', ' : '') +
						(item.all_address[0].address_3 ? item.all_address[0].address_3 + ', ' : '') +
						(item.all_address[0].city ? item.all_address[0].city + ', ' : '') +
						(item.all_address[0].state_county ? item.all_address[0].state_county + ', ' : '') +
						((item.all_address[0].countries && item.all_address[0].countries.country_name)
							? item.all_address[0].countries.country_name : '')
					) : null


				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	getRecordDetail = (formdata, setVariable = true) => {
		return Axios.get(`global/people/read/${formdata.people_id}`).then(({ data }) => {
			if (setVariable) {
				if (data.data.cash_transactions && data.data.cash_transactions.length) {
					data.data.cash_transactions.forEach((item, index) => {
						item.srno = index + 1
						item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
					});
				}

				if (data.data.address && data.data.address.length) {
					data.data.address.forEach((item) => {
						item.city = item.city ? item.city : undefined
						item.state_county = item.state_county ? item.state_county : undefined
					});
				}
				this.peopleValues = data.data
			}
			return data
		}).catch(({ response: { data } }) => {
			this.peopleValues = null
			return Promise.reject(data)
		})
	}

	// get related organisation record details
	getRPplRecordDetail = (data, setVariable = true) => {
		return Axios.get(`global/people/read/${data.people_id}`).then(({ data }) => {
			if (setVariable) {
				this.rPeopleValues = data.data
			}
			return data.data
		}).catch(({ response: { data } }) => {
			this.rPeopleValues = null
			return Promise.reject(data)
		})
	}

	setPeopleValues = (data) => {
		this.peopleValues = data
	}

	// set related organisation values
	setRPeopleValues = (data) => {
		this.rPeopleValues = data
	}

	AddData = (data) => {
		return Axios.post(`global/people/add`, data).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	EditData = (data, id) => {
		return Axios.post(`global/people/edit/${id}`, data).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	DeleteData = (people_id) => {
		return Axios.get(`global/people/delete/${people_id}`).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ImportPeopleFile = (formdata) => {
		const options = {
			headers: { Accept: '' },
		}
		return Axios.post(`people/import`, formdata, options).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			} return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ImportPeopleRoleFile = (formdata) => {
		const options = {
			headers: { Accept: '' },
		}
		return Axios.post(`people/role/bank/import`, formdata, options).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			} return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	getPeopleRoles = () => {
		return Axios.post(`global/people/role/dropdown/list`).then(({ data }) => {
			this.dropdown_pplRole_list = data.data
			return data
		})
	}

	getRelatedPeopleRoles = (id) => {
		return Axios.post(`global/people/role/dropdown/list`, id).then(({ data }) => {
			this.dropdown_RpplRole_list = data.data
			return data
		})
	}

	// call api to get all organisations list
	getAllRPeople = (payload) => {
		this.dropdown_RPpl_list = null
		if ((payload.people_name && payload.people_name.length >= 1) || payload.selected_id) {
			this.fetching = true
			return Axios.get(`global/people/dropdown/list`, payload).then(({ data }) => {
				this.dropdown_RPpl_list = data.data
				return data
			}).catch(({ response: { data } }) => {
				return data
			}).finally(() => { this.fetching = false })
		} else {
			return Promise.resolve({ data: null })
		}
	}

	// call api to get all organisations list
	getPeopleSearchList = (payload) => {
		this.dropdown_RPpl_list = null
		if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
			this.fetching = true
			return Axios.post(`organisations/get`, payload).then(({ data }) => {
				this.dropdown_RPpl_list = data.data
				return data
			}).catch(({ response: { data } }) => {
				return data
			}).finally(() => { this.fetching = false })
		} else {
			return Promise.resolve({ data: null })
		}
	}

	ExportPeople = () => {
		return Axios.get(`people/export`).then(({ data }) => {
			if (data.data && data.data.csv_link) {
				window.location.href = data.data.csv_link
			}
			return data
		}).catch(() => {
			return Promise.reject([])
		})
	}

	ExportPeopleRoleMappint = () => {
		return Axios.get(`people/role/bank/export`).then(({ data }) => {
			if (data.data && data.data.csv_link) {
				window.location.href = data.data.csv_link
			}
			return data
		}).catch(() => {
			return Promise.reject([])
		})
	}

	getViewPplDetail = (data) => {
		return Axios.get(`people/view/details/${data}`).then(({ data }) => {
			if (data.data) {
				data.data.ppsn_document_type = globalStatus('ppsn_document_type', 'key', data.data.ppsn_document_type)
			}
			this.peopleValues = data.data
			this.ViewPeopleList()
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	ViewPeopleList = () => {
		this.list_related_ORG = this.peopleValues && this.peopleValues.related_organisations

		this.list_related_Ppl = this.peopleValues && this.peopleValues.related_peoples

		this.list_trade_transaction = this.peopleValues && this.peopleValues.trade_transactions

		this.list_cash_transaction = this.peopleValues && this.peopleValues.cash_transactions

		this.list_banks = this.peopleValues && this.peopleValues.banks
	}

	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!['photo_url', 'date_of_birth', 'add_address'].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		})
		return formData
	}

	getAllPeople = (payload) => {
		this.drowpdown_people_list = null
		if ((payload.search_for && payload.search_for.length >= 1) || payload.selected_id) {
			this.fetching = true
			return Axios.post(`organisations/current-ledger/get`, payload).then(({ data }) => {
				this.drowpdown_people_list = data.data
				return data
			}).catch(({ response: { data } }) => {
				return data
			}).finally(() => { this.fetching = false })
		} else {
			return Promise.resolve({ data: null })
		}
	}

	getPeopleCardValue = (id, type) => {
		return Axios.post(`global/card/read/${id}`, { entity_type: 2 }).then(({ data }) => {
			if (type === 'relatedPeople') {
				this.rPeopleCardValue = data.data
			} else if (type === 'people') {
				this.peopleCardValue = data.data
			}
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

}

decorate(PeopleStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	agGrid: observable,
	agGridRelOrg: observable,
	agGridRelPpl: observable,
	agGridTradeTransaction: observable,
	agGridCashTransaction: observable,
	agGridBanks: observable,
	deleteValues: observable,
	peopleValues: observable,
	rPeopleValues: observable,
	initialValues: observable,
	fetching: observable,
	dropdown_pplRole_list: observable,
	viewValues: observable,
	list_related_ORG: observable,
	list_related_Ppl: observable,
	list_trade_transaction: observable,
	list_cash_transaction: observable,
	list_banks: observable,
	drowpdown_people_list: observable,
	dropdown_RpplRole_list: observable,
	peopleCardValue: observable,
	rPeopleCardValue: observable,
	setPageSize: action,
	getList: action,
	setupGrid: action,
	setupGridRelatedOrganisation: action,
	setupGridRelatedPeople: action,
	setupGridTradeTransactions: action,
	setupGridCashTransactions: action,
	setupGridBanks: action,
	setDeleteValues: action,
	setViewValues: action,
	getRecordDetail: action,
	getRPplRecordDetail: action,
	setPeopleValues: action,
	setRPeopleValues: action,
	AddData: action,
	EditData: action,
	DeleteData: action,
	ImportPeopleFile: action,
	ImportPeopleRoleFile: action,
	getPeopleRoles: action,
	getRelatedPeopleRoles: action,
	getAllRPeople: action,
	ViewPeopleList: action,
	getViewPplDetail: action,
	JsonToFormData: action,
	ExportPeople: action,
	ExportPeopleRoleMappint: action,
	getAllPeople: action,
	getPeopleSearchList: action,
	getPeopleCardValue: action,
	onGridChanged: action,
	onChildnGridChanged: action,
	handleReset: action,
})
