import React from 'react'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import useStore from '../../../../../../store'
import { DateComparator } from '../../../../../../utils/GlobalFunction'

export const ActionRenderer = (props) => {
	const { openEditModal, openViewModal, openDeleteModal, openVerifyDrawer } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(76, 'edit') && ![1, 2, 4, 5].includes(props.data.status) ? (
				<Button title="Verifying Mode" type="text" disabled><FontAwesomeIcon className={![4, 5].includes(props.data.status) ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
			) : (
				UserStore.checkPermission(76, 'edit') && (<Button title="Verifying Mode" type="text" onClick={() => { openVerifyDrawer(props.data) }}><FontAwesomeIcon className={![4, 5].includes(props.data.status) ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>)
			)}
			{UserStore.checkPermission(76, 'view') && (<Button title="View Cash Transaction" type="text" onClick={() => { openViewModal(props.data) }}><EyeOutlined /></Button>)}
			{UserStore.checkPermission(76, 'edit') && (<Button title="Edit Cash Transaction" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>)}
			{UserStore.checkPermission(76, 'delete') && (<Button title="Delete Cash Transaction" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>)}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Date',
		field: 'date',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Transaction Details',
		field: 'transaction_details',
		width: 150
	}, {
		headerName: 'Transaction Type',
		field: 'type_name',
		width: 150
	}, {
		headerName: 'Debit (+)',
		field: 'debit',
		width: 110
	}, {
		headerName: 'Credit (-)',
		field: 'credit',
		width: 110
	}, {
		headerName: 'Identified Category',
		field: 'transaction_class_name',
		width: 150
	}, {
		headerName: 'Role',
		field: 'rolename',
		width: 150
	}, {
		headerName: 'Related Role',
		field: 'related_rolename',
		width: 150
	}, {
		headerName: 'Status',
		field: 'status_name',
		width: 110
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
