import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../store'
import { vsmBankAccount, vsmNotify } from '../../../../../config/messages'

const DeactivateComponent = observer((props) => {
	const [form] = Form.useForm()
	const { LedgerBankAccountStore: { TogggleData, currentValues } } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to Deactivate existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = currentValues.id
		var payload = { status: 0 }
		TogggleData(data, payload).then(() => {
			vsmNotify.success({
				message: vsmBankAccount.deactivated
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false) })
	}

	// reset form and close deactivate form
	const close = () => {
		props.close()
		form.resetFields()
	}

	return currentValues ? (
		<Modal
			centered
			title={`Deactivate Bank Account - #${currentValues.id} - ${currentValues.banks.name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<>
					<Button key="1" form='deactivateform' loading={saving} htmlType="submit" type="primary" danger>Deactivate</Button>
					<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
				</>
			]}
		>
			<Form form={form} id="deactivateform" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to Deactivate this Bank account, you will not able to use it for any cash transactions?</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default DeactivateComponent
