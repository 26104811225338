import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { Form, Button, Modal, Col, Row } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmLedgerDetails } from '../../../../../config/messages';

const RestoreFinancialYearComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const { LedgerDetailStore: { RestoreData, restoreValues } } = useStore()

	// Make function call to restore record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = restoreValues.id
		data.data_status = 1
		RestoreData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmLedgerDetails.restore
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	const close = () => {
		props.close()
		form.resetFields()
	}

	return restoreValues ? (
		<Modal
			centered
			title={`Restore Data #FY - ${restoreValues.start_date} to ${restoreValues.end_date}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='restoreform' loading={saving} htmlType="submit" type="primary">Restore</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="restoreform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to Restore all transaction data of selected Financial Year? (It might take some time, We will send email to Ledger admin once it is fully restored)</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default RestoreFinancialYearComponent
