import Axios from "axios"
import { decorate, observable, action } from "mobx"
import moment from "moment"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class AuditLogStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	agGrid = null
	auditValues = null
	exportLoading = false
	userList = null

	module_path = {
		"Journal": "/administration/trade/journals",
		"JournalGroups": "/administration/trade/journal-groups",
		"TaxSetting": "/administration/trade/tax-settings",
		"TransactionFields": "/administration/trade/transaction-fields",
		"TransactionModel": "/administration/trade/transaction-model",
		"TransactionType": "/administration/trade/trade-transaction/trade-type",
		"TransactionSubType": "/administration/trade/trade-transaction/transaction-type",
		"TransactionTypeFieldMapping": "/administration/trade/trade-transaction/trade-mapping",
		"AlgorithmsManagement": "/administration/trade/algorithms",
		"GlobalOrganisations": "/organisations/all-organisations",
		"OrganisationsRoles": "/organisations/administration/roles",
		"Transactions": "/trade-transactions/view-all",
		"CashTransactions": "/cash-transactions/view-all",
		"CteTransactionClassifications": "/administration/cash/bank-codes-analysis",
		"Reconciliation": "/cash-transactions/cash-reconciliation",
		"CteRegexManager": "/administration/cash/transaction-formula",
		"GlobalPeople": "/people/all-people",
		"PeopleRole": "/people/roles",
		"ExchangeRateHistory": "/administration/currencies",
		"GlobalCurrency": "/administration/currencies",
		"Report": "/reports/saved-reports",
		"GlobalOrgStatus": "/organisations/administration/status",
		"Role": "/setup/manage-user-profiles",
		"User": "/setup/all-users",
		"LedgersBankAccounts": "/setup/bank-accounts",
		"Ledgers": "/setup/all-ledgers",
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('audit_log')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('audit_log', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	getFilteredData = (roleName, date) => {
		if (this.agGrid) {
			this.agGrid.api.showLoadingOverlay()
		}
		var payload = {
			user_id: roleName,
			date: date
		}
		this.getList(payload).finally(() => {
			this.agGrid && this.agGrid.api && this.agGrid.api.hideOverlay()
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.api.getSortModel()
		}
		return Axios.post(`audit/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('audit_log')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid) {
					this.agGrid.api.setFilterModel(filter)
					this.agGrid.api.setSortModel(sort)
				}
			}
			return data
		})
	}

	// export data as a csv
	exportData = async () => {
		this.exportLoading = true;
		var params = {
			columnKeys: ['id', 'user_type', 'updated_by', 'event', 'auditable_type', 'auditable_id', 'url', 'old_values', 'new_values', 'ip_address', 'user_agent', 'tags', 'updated_at'],
			fileName: 'auditlog-export-' + moment().format("YYYY-MM-DD hh:mm:s"),
		};
		await this.agGrid.api.paginationSetPageSize(this.total)
		await this.agGrid.api.exportDataAsCsv(params)
		await this.agGrid.api.paginationSetPageSize(this.per_page)
		this.exportLoading = false;
	}

	//  get organisation record details
	getRecordDetail = (data) => {
		return Axios.get(`audit/${data}`).then(({ data }) => {
			this.auditValues = data.data
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call for user list
	getUserList = () => {
		return Axios.get(`audit/auditusers`).then(({ data }) => {
			this.userList = data.data
			return data
		}).catch((data) => {
			this.userList = null
			return Promise.reject(data)
		})
	}

}

decorate(AuditLogStore, {
	list_data: observable,
	total: observable,
	module_path: observable,
	current_page: observable,
	per_page: observable,
	agGrid: observable,
	auditValues: observable,
	exportLoading: observable,
	userList: observable,
	getList: action,
	setupGrid: action,
	setPageSize: action,
	getRecordDetail: action,
	exportData: action,
	onFilterChanged: action,
	getUserList: action,
	onGridChanged: action,
	getFilteredData: action,
})
