import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class LedgerDetailStore {
	list_data = null

	editValues = null
	restoreValues = null
	currency_list = null
	timezone_list = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to restore
	setRestoreValues = (data) => {
		this.restoreValues = data
	}

	// call api to get all currency list
	getAllCurrencyList = () => {
		return Axios.get(`ledgers/getCurrency`).then(({ data }) => {
			this.currency_list = data.data
			return data
		}).catch((data) => {
			this.currency_list = null
			return Promise.reject(data)
		})
	}

	// call api to get all timezone list
	getAllTimeZoneList = () => {
		return Axios.get(`ledgers/getTimezone`).then(({ data }) => {
			this.timezone_list = data.data
			return data
		}).catch((data) => {
			this.timezone_list = null
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		return Axios.get(`ledgers/financialyear/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.year = item.start_date + " - " + item.end_date
				});
			}
			this.list_data = data.data
			return data
		})
	}

	// call to get record for particular ledger ID
	getRecordById = (formdata) => {
		return Axios.get(`ledgers/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call to get record for particular organisation ID
	getOrganisationByID = (formdata) => {
		return Axios.get(`organisations/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata, id) => {
		return Axios.post(`ledgers/ledersDetailUpdate/${id}`, formdata, id).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call api to archive/restore Financial year
	RestoreData = (formdata) => {
		return Axios.post(`ledgers/financialyear/archive/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(LedgerDetailStore, {
	list_data: observable,
	current_page: observable,
	editValues: observable,
	restoreValues: observable,
	unpublishValues: observable,
	timezone_list: observable,
	currency_list: observable,
	getList: action,
	EditData: action,
	setEditValues: action,
	setRestoreValues: action,
	setUnpublishValues: action,
	getAllTimeZoneList: action,
	getAllCurrencyList: action,
	RestoreData: action,
	setPageSize: action,
	TogglepublishData: action,
})
