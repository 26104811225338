import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, PageHeader, Row, Spin } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import useStore from '../../../store'
import ListComponent from './components/ListComponent'
import { observer } from 'mobx-react'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import InputComponent from '../../../components/InputComponent'
import { vsmQueryBankVsCash, vsmTransactionReport } from '../../../config/messages'
import DateSelectionElement from '../../Reports/SavedReports/elements/DateSelectionElement'
import moment from 'moment'
import DaysListComponent from './components/DayListComponent'

const QueryBankVsCash = observer(() => {
	const [form] = Form.useForm()
	const { UserStore, QueryBankVsCashStore, QueryBankVsCashStore: { getBankAccList, getList, list_data, getFYear, fYear, bankAccList, days_list_data } } = useStore()
	const [bankAcc, setBankAcc] = useState(true)
	const [fyYear, setFYYear] = useState(true)
	const [DateRange, SetDateRange] = useState([]);
	const [formDataList, setFormDataList] = useState([])
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [bankID, setBankID] = useState(null)
	const [fYearType, setFYearType] = useState(fYear[0]?.value)
	const typeChange = ['generate_on', 'audit_group_on']
	const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)

	const getFormDataList = useCallback(() => {
		var FormDataList = []
		FormDataList = [...FormDataList, ...typeChange]
		switch (form.getFieldValue('generate_on')) {
			case 'date_range':
				FormDataList = [...FormDataList, 'date_selection']
				switch (form.getFieldValue('date_selection')) {
					case 'range':
						FormDataList = [...FormDataList, 'date_range']
						break;
					case 'single':
						FormDataList = [...FormDataList, 'date']
						break;
					case 'auto':
						FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
						break;
					default:
				}
				break;
			case 'financial_year':
				FormDataList = [...FormDataList, 'financial_year']
				if (!GENERATEONFINANCIAL) {
					SETGENERATEONFINANCIAL(true)
					getFYear()
				}
				break;
			default:
		}
		return FormDataList
	}, [GENERATEONFINANCIAL, getFYear, form, typeChange])

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	const handleSubmit = (data) => {
		QueryBankVsCashStore.getChildListPayload = null
		QueryBankVsCashStore.listTempPayload = null
		QueryBankVsCashStore.singleGetChildListPayload = null
		if (data.date_range && data.date_range.length) {
			var payload = {
				from_date: moment(data.date_range[0]).format('YYYY-MM-DD'),
				to_date: moment(data.date_range[1]).format('YYYY-MM-DD')
			}
			data.date_range = payload
		}
		QueryBankVsCashStore.list_data = null
		QueryBankVsCashStore.days_list_data = null
		data.report_type = 'bank_vs_cash'
		data.action = 'list_view'
		setSaving(true)
		if (data.generate_on === 'all') {
			setFYearType('all')
		} else {
			setFYearType(null)
		}
		getList(data).then(() => {
		}).catch((e) => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const disabledDate = current => {
		if (!DateRange || DateRange.length === 0) { return false }
		return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
	}

	useEffect(() => {
		if (!form.getFieldValue('bank_account_id')) {
			QueryBankVsCashStore.list_data = null
			QueryBankVsCashStore.days_list_data = null
			QueryBankVsCashStore.getChildListPayload = null
			QueryBankVsCashStore.listTempPayload = null
		}
	}, [QueryBankVsCashStore.list_data, form, QueryBankVsCashStore.listTempPayload, QueryBankVsCashStore.getChildListPayload, QueryBankVsCashStore.days_list_data])

	useEffect(() => {
		var data = getFormDataList()
		if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
			setFormDataList(data)
		}
	}, [getFormDataList, setFormDataList, formDataList])

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.QueryBankCash.path} />
			<PageHeader
				className={UserStore.fullscreen_class + ' query_bank remove-error-text'}
				title={BreadcrumbConfig.QueryBankCash.title}
				extra={[
					<div className='d-flex' style={{ width: '1400px' }}>
						<Form layout='inline' key='1'
							form={form}
							id="addform"
							onFinish={handleSubmit}
							initialValues={{
								financial_year: fYear[0]?.value,
								generate_on: 'all',
								audit_group_on: 'day',
								date_selection: 'range',
								date_x_value: 2,
								date_x_value_type: 'day',
							}}
						>
							<Row>
								<Col>
									<InputComponent
										style={{ width: '260px' }}
										required allowClear type="select" label="Bank Account" name="bank_account_id"
										placeholder={"Select Bank Account"}
										tooltip="Select Bank Account"
										onChange={(e) => { handleChange(); setBankID(e) }}
										onFocus={() => bankAcc && getBankAccList().then(() => setBankAcc(false))}
										notFoundContent={bankAcc ? <Spin size="small" /> : "No Record Found."}
										filterSort={(optionA, optionB) =>
											(optionA.children ? optionA.children : '').toLowerCase().localeCompare((optionB.children ? optionB.children : '').toLowerCase())
										}
										options={{
											values: bankAccList,
											value_key: 'id',
											text_key: { key: ["name", " - ", "account_number"] },
										}}
										rules={vsmQueryBankVsCash.validation.bank_acc}
									/>
								</Col>
								{formDataList.includes('generate_on') && (
									<Col>
										<InputComponent
											required
											type="radio_button"
											label="Generate On"
											name="generate_on"
											onChange={handleChange}
											options={{
												values: [{ value: 'all', text: 'All Data' }, { value: 'date_range', text: 'Date Range' }, { value: 'financial_year', text: 'Financial Year' }, { value: 'periods', text: 'Period' }],
												rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
											}}
											tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
											rules={vsmTransactionReport.validation.generate_on}
										/>
									</Col>
								)}
								{(formDataList.includes('date_selection')) && (
									<DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} tab={'inQuery'} />
								)}
								{(formDataList.includes('financial_year')) && (
									<Col>
										<InputComponent
											// style={{ width: '200px' }}
											type="select" label="Financial Year" name="financial_year"
											placeholder={"Select Financial Year"}
											tooltip="Select Financial Year"
											onChange={handleChange}
											onFocus={() => fyYear && getFYear().then(() => setFYYear(false))}
											notFoundContent={fyYear ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: fYear,
												value_key: 'value',
												text_key: 'text',
											}}
										/>
									</Col>
								)}
								<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Submit</Button>
							</Row>
						</Form>
					</div>
				]}
			>
				{list_data ?
					<ListComponent bank_ID={bankID} fYearType={fYearType} />
					: days_list_data ? <DaysListComponent type='main' bankID={bankID} /> :
						<p style={{ position: 'absolute', top: '50px', fontSize: '20px', textAlign: 'center', width: '100%', height: 'calc(100% - 30px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0' }}>
							Please Select Bank Account and Generated On then Click on Submit to see the report.
						</p>
				}
			</PageHeader>
		</>
	)
})

export default QueryBankVsCash