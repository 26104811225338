import { decorate, observable, action } from "mobx"
import Axios from "axios"
import { vsmNotify } from "../config/messages"
export default class UserStore {

	user = null
	superUser = null
	ledger = null
	ledger_base_currency = null
	token = null
	menu = null
	loading = true
	open_login = false
	permissions = null
	highlight_user_guide = false
	list_fullscreen = false
	fullscreen_class = ''

	toggleListFullscreen = () => {
		this.list_fullscreen = this.list_fullscreen ? false : true
		this.fullscreen_class = this.list_fullscreen ? 'full_screen_wrapper' : ''
	}

	constructor() {
		this.initiatAppOptions()
	}

	// set base url & add interceptors to axios
	setAxiosDefaults = () => {
		Axios.defaults.baseURL = process.env.React_APP_API_URL
	}

	// set base url & add interceptors to axios
	setAxiosInterceptors = () => {
		Axios.interceptors.response.use((data) => {
			return data
		}, (data) => {
			if (data.response) {
				if (data.response.status === 401) {
					this.clearLocalStorage()
					this.open_login = true
				} else if (data.response.status === 500) {
					let tempObj = data.response.data.message.split("response:")
					let tempJSON = tempObj && tempObj.length && JSON.parse(tempObj[1])
					if (tempJSON && tempJSON.code === 9) {
						vsmNotify.error({
							message: tempJSON.message
						})
					} else {
						vsmNotify.error({
							message: "Something went wrong. For more detail [500]"
						})
					}
				} else if (data.response.status === 422) {
					if (data.response.data.errors.general) {
						data.response.data.errors.general.map((err) => (
							vsmNotify.error({
								message: err
							})
						))
					}
				}
			} else {
				vsmNotify.error({
					message: "Network Problem."
				})
				data.errors = null
			}
			return Promise.reject(data)
		})
	}

	// set axios default setting for api call
	initiatAppOptions = () => {
		this.loading = true
		this.setAxiosDefaults()

		let token = localStorage.getItem('token')
		if (token && token !== 'undefined') {

			Axios.defaults.headers = {
				Accept: 'application/json',
				Authorization: 'Bearer ' + token
			}
			this.setUserOptions(token)
		} else {
			this.setUser(null, null)
			this.loading = false
		}

	}

	// make a api call to get current user & accessible menu
	setUserOptions = (token) => {
		Axios.post('me').then(({ data }) => {
			this.setUser(data.user, token)
			this.superUser = data.super_user_id
			this.ledger = data.ledger
			this.ledger_base_currency = data.ledger_base_currency
			var menu = data.menu
			this.loading = false
			this.permissions = data.permissions
			if (data.favorite_reports && data.favorite_reports.length > 0) {
				if (menu[46]) {
					menu[46]['submenu']["0_0"] = { id: "0_0", icon: 'faStar', title: "Favourite Menu", path: "", parent_id: 46, sequence_no: 10000, submenu: {} }
					data.favorite_reports.forEach(item => {
						menu[46]['submenu']["0_0"].submenu["0_" + item.id] = {
							id: "0_" + item.id,
							title: item.name,
							path: `/reports/saved-reports?preview_report=${item.id}`,
							parent_id: "0_0",
							sequence_no: 10001
						}
					})
				}
			}
			this.menu = menu
		}).catch(e => {
			this.clearLocalStorage()
			this.initiatAppOptions()
		})
	}

	// set authenticated user to mobx store
	setUser = (user, token) => {
		this.user = user
		this.token = token
	}

	// call api for login
	doLogin = (data) => {
		return Axios.post('login', data).then(({ data }) => {
			localStorage.setItem('token', data.access_token)
			this.initiatAppOptions()
			this.open_login = false
			this.highlight_user_guide = data.data
			return true
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	// logout function
	doLogout = () => {
		this.loading = true
		return Axios.post('logout').then(({ data }) => {
			this.loading = false
			if (localStorage.getItem('previous_token')) {
				localStorage.setItem('token', localStorage.getItem('previous_token'))
				localStorage.removeItem('previous_token')
				this.initiatAppOptions()
			} else {
				Axios.defaults.headers = {
					Accept: 'application/json'
				}
				this.setUser(null, null)
				this.superUser = null
				this.ledger = null
				this.ledger_base_currency = null
				this.menu = null
				this.loading = false
				this.clearLocalStorage()
			}
			return true
		}).catch(({ response: { data } }) => {
			this.loading = false
			return Promise.reject()
		})
	}

	clearLocalStorage = () => {
		localStorage.removeItem('local_org_country')
		localStorage.removeItem('token')
		localStorage.removeItem('cash_transaction_related_org_filter')
		localStorage.removeItem('transaction_related_org_filter')
		localStorage.removeItem('params')
	}

	// call api for forgot password link
	sendForgotPasswordLink = (data) => {
		return Axios.post('forgotpassword', data).then(({ data }) => {
			return true
		}).catch(({ response: { data } }) => {
			var errors = []
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					errors.push({ name, errors: data.errors[name] })
				})
			}
			return Promise.reject({ errors })
		})
	}

	// call api for reset password
	doForgotPassword = (data) => {
		return Axios.post('/user/updatenewpassword', data).then(({ data }) => {
			return true
		}).catch(({ response: { data } }) => {
			var errors = []
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					errors.push({ name, errors: data.errors[name] })
				})
			}
			return Promise.reject({ errors })
		})
	}

	doResetPassword = (data) => {
		return Axios.post('/user/reset/password', data).then(({ data }) => {
			return true
		}).catch(({ response: { data } }) => {
			var errors = []
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					errors.push({ name, errors: data.errors[name] })
				})
			}
			return Promise.reject({ errors })
		})
	}

	// call api to change default ledger
	changeLedger = (data) => {
		return Axios.post(`user/change/ledger`, data).then(({ data }) => {
			window.location.reload();
		}).catch(({ response: { data } }) => {
			return Promise.reject(data.errors)
		})
	}

	checkPermission = (module_id, permission_type) => {
		if (this.user && this.user.id === 1) {
			return true
		} else {
			if (this.permissions && this.permissions[module_id] && this.permissions[module_id].includes(permission_type)) {
				return true
			} else {
				return false
			}
		}
	}

}

decorate(UserStore, {
	fullscreen_class: observable,
	list_fullscreen: observable,
	user: observable,
	ledger: observable,
	superUser: observable,
	token: observable,
	menu: observable,
	loading: observable,
	open_login: observable,
	ledger_base_currency: observable,
	permissions: observable,
	highlight_user_guide: observable,
	setUser: action,
	doLogin: action,
	doLogout: action,
	doForgotPassword: action,
	sendForgotPasswordLink: action,
	doResetPassword: action,
	checkPermission: action,
	toggleListFullscreen: action
})
