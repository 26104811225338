import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class GlobalSettingStore {
	readValues = null

	ReadData = () => {
		return Axios.get(`globalsetting/read`).then(({ data }) => {
			this.readValues = data.data
			return data.data
		})
	}

	AddData = (formData) => {
		return Axios.post(`globalsetting/add`, formData).then((data) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(GlobalSettingStore, {
	readValues: observable,
	ReadData: action,
	AddData: action,
})
