import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Spin, Drawer } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import moment from 'moment'
import { vsmAllTransactions, vsmNotify, vsmTransactionItems } from '../../../../config/messages'
import ViewaETRComponent from './aETRViewComponent'

const ViewComponent = observer((props) => {
	const [form] = useForm()
	const [loading, setLoading] = useState(true)
	const [viewValues, setViewValues] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [entityType, setEntityType] = useState(null)
	const [hasItems, setHasItems] = useState(null)
	const [relatedTo, setRelatedTo] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const [extraField, setExtraFields] = useState()
	const [customStartDate, setCustomStartDate] = useState(false)
	const { OrgBankAccountStore, OrganisationStore, TransactionModelStore, AllTransactionStore, TransactionItemsStore, AllTransactionStore: {
		getRecordById, getCsvModals, setStatus, setVatRate, setMappingList, setItemsTable, transactionItems, setDependent } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore,
		modal: TransactionModelStore
	}

	const handleSubmit = (data) => {
		let inCSV_Columns_payload = ['Asset Type', 'Sell Rate', 'Period', 'Period Type', 'Tax Type Inc or Exc', 'EDSO', 'Repair and Maintenance', 'EoT Value', 'Batch ID', 'Exchange Ref ID', 'Reference', 'Tax ID On Sales', 'Current Period', 'Period Number Days', 'Period Days Passed']
		if (data.transaction_items) {
			data.transaction_items.forEach((item, index) => {
				if (!item.id) {
					csvColumn && csvColumn.forEach((CSVdata) => {
						if (inCSV_Columns_payload.includes(CSVdata.field_name)) {
							switch (CSVdata.field_name) {
								case 'Asset Type':
									item.asset_type = CSVdata.default_value === 1 ? 'fixed' : 'current'
									break;
								case 'Batch ID':
									item.batch = CSVdata.default_value
									break;
								case 'EoT Value':
									item.eot = CSVdata.default_value
									break;
								case 'Repair and Maintenance':
									item.rm_price = CSVdata.default_value
									break;
								case 'Sell Rate':
									item.sell_rate = CSVdata.default_value
									break;
								case 'Tax Type Inc or Exc':
									item.tax_type_inc_exc = CSVdata.default_value
									break;
								default:
									break;
							}
						}
					})
					item.tax_settings_id = extraField
					item.transaction_id = props.transactionId || null
					item.add_address = data.transaction_items[0].add_address ? 1 : 0
				}
			})
		}
		let payload = {
			transaction_items: data.transaction_items,
			common_address: {
				address_1: data.address_1,
				address_2: data.address_2,
				address_3: data.address_3,
				city: data.city,
				state_county: data.state_county,
				postal_code: data.postal_code,
				country_id: data.country_id
			}
		}
		if (!data.address_1 && !data.country_id) {
			delete payload.common_address;
		}
		TransactionItemsStore.AddData(payload).then(() => {
			close()
			if (props.handleApiCall) { props.handleApiCall() }
			vsmNotify.success({
				message: vsmTransactionItems.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		})
	}

	const handleApiCall = useCallback(() => {
		setLoading(true)
		setItemsTable(null)
		getRecordById({ id: props.transactionId }).then((data) => {
			setViewValues(data.data)
			setDependent(data.data)
			setMappingList([data.data.type_field_mapping])
			setStatus([data.data.status])
			setVatRate([data.data.tax_setting])
			setLoading(false)
		}).catch((data) => {
			vsmNotify.error({ message: vsmAllTransactions.InvalidRecord })
			setLoading(false)
		})
	}, [props.transactionId, setItemsTable, getRecordById, setDependent, setMappingList, setStatus, setVatRate])

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			handleApiCall()
		}
	}, [props.visible, handleApiCall])

	useEffect(() => {
		if (viewValues && props.visible) {
			getCsvModals(viewValues.type_field_mapping_id, "view", viewValues.selected_models)
		}
	}, [getCsvModals, props.visible, viewValues])

	// Set the form values to edit
	useEffect(() => {
		if (viewValues && props.visible) {
			store.bank.setCurrency([viewValues.global_currency])
			setCustomStartDate(viewValues.is_custom_start_date)
			form.setFieldsValue({
				role_id: viewValues.role_id,
				related_role_id: viewValues.related_role_id,
				entity_id: viewValues.entity_id ? viewValues.entity_id : null,
				related_entity_type: viewValues.related_entity_type,
				related_entity_id: viewValues.related_entity_id ? viewValues.related_entity_id : null,
				type_field_mapping_id: viewValues.type_field_mapping_id,
				currency_id: parseInt(viewValues.currency_id),
				face_value: currencyFormat(viewValues.face_value),
				status_id: viewValues.status_id,
				tax_settings_id: viewValues.tax_settings_id,
				posted_on: viewValues.posted_on && moment(viewValues.posted_on),
				traded_on: viewValues.traded_on && moment(viewValues.traded_on),
				issued_on: viewValues.issued_on && moment(viewValues.issued_on),
				start_date: viewValues.start_date && moment(viewValues.start_date),
				csv_columns: viewValues.csv_columns,
				model: viewValues.selected_models,
				repayment_schedule: viewValues.repayment_schedule,
				ltv_value: viewValues.ltv_value,
				transaction_items: viewValues.transactions_items && viewValues.transactions_items
			})
			for (let index = 0; index < viewValues.transactions_items.length; index++) {
				const element = viewValues.transactions_items[index];
				if (element.add_address === 1) {
					form.setFieldsValue({
						add_address: element.add_address ? true : false,
						address_1: element.address_1,
						address_2: element.address_2,
						address_3: element.address_3,
						city: element.city,
						state_county: element.state_county,
						postal_code: element.postal_code,
						country_id: element.country && element.country.id && element.country.id
					})
					OrganisationStore.dropdown_countries_list = [element.country];
					break;
				} else {
					form.setFieldsValue({
						add_address: 0
					})
				}
			}
			setExtraFields(viewValues.tax_settings_id)
			setCsvColumn(viewValues.csv_columns)
			setItemsTable(viewValues.transactions_items)
			setEntityType(viewValues.entity_type)
			setHasItems(viewValues.has_items)
			setRelatedTo(viewValues.related_entity_type)
			setOrgData(viewValues.entity_type === 2 ? viewValues.peoples : viewValues.organisations)
			setRelatedOrgData(viewValues.related_entity_type === "People" ? viewValues.related_peoples : viewValues.related_organisations)
			let arrayList = viewValues.journal_logs
			arrayList.forEach((item, i) => {
				item.index = i + 1;
			});
			store.modal.setArrayList(arrayList)
		}
	}, [setItemsTable, viewValues, OrganisationStore, props.visible, store.modal, store.bank, form, transactionItems, csvColumn, extraField])

	// Reset form and close view form
	const close = () => {
		props.close()
		props.setId(null)
		setViewValues(null)
		setHasItems(null)
		setCsvColumn(null)
		setEntityType(null)
		setRelatedTo(null)
		setRelatedOrgData(null)
		setOrgData(null)
		AllTransactionStore.csvModals = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`View Trade Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			className={"decrease_table_font_size remove-error-text"}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button onClick={close}>Back to Listing</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="viewform"
					layout="vertical"
					onFinish={handleSubmit}
				>
					{viewValues && [1, 112].includes(viewValues.type_field_mapping_id) ?
						<ViewaETRComponent
							form={form}
							extraField={extraField}
							currentValues={viewValues}
							tab={"view"}
							entity_type={entityType}
							related_to={relatedTo}
							orgData={orgData}
							relatedOrgData={relatedOrgData}
							csvColumn={csvColumn}
							hasItems={hasItems}
							openParentCashViewDrawer={props.openParentCashViewDrawer}
							close={close}
							handleApiCall={handleApiCall}
							initialValues={transactionItems}
							customStartDate={customStartDate}
							setCustomStartDate={setCustomStartDate}
						/>
						:
						<FormFieldComponent
							form={form}
							currentValues={viewValues}
							tab={"view"}
							entity_type={entityType}
							related_to={relatedTo}
							orgData={orgData}
							relatedOrgData={relatedOrgData}
							csvColumn={csvColumn}
							hasItems={hasItems}
							openParentCashViewDrawer={props.openParentCashViewDrawer}
							close={close}
							handleApiCall={handleApiCall}
						/>}
				</Form>
			</Spin>
		</Drawer>
	)
})

export default ViewComponent

// currency format with 2 decimal point
function currencyFormat(value) {
	let val = parseFloat(value.replace(/,/g, ""))
	val = val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
	return val
}
