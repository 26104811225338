import React, { useState } from 'react'
import { observer } from "mobx-react"
import InputComponent from '../../../../../components/InputComponent'
import { Col, Row, Spin } from 'antd'
import { vsmOrganisations } from '../../../../../config/messages'
import useStore from '../../../../../store'

const AddressSubElement = observer(({ onChange, field, setFetchCountry, fetchCountry }) => {
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const { OrganisationStore, OrganisationStore: { organisationValues } } = useStore()

	const onCountryChange = (e) => {
		localStorage.setItem('local_org_country', JSON.stringify(e))
	}

	return (
		<>
			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						label="Type"
						name={[field.name, 'address_type']}
						key={[field.key, 'address_type']}
						placeholder="Address Type"
						onChange={onChange}
						tooltip="The type of the address under which the address can be created.[eg.: Home, Office, Billing Address, Shipping Address]"
						rules={vsmOrganisations.validation.address_type}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 1" required
						name={[field.name, 'address_1']}
						key={[field.key, 'address_1']}
						placeholder="Address Line 1"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_1}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 2"
						name={[field.name, 'address_2']}
						key={[field.key, 'address_2']}
						placeholder="Address Line 2"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 3"
						name={[field.name, 'address_3']}
						key={[field.key, 'address_3']}
						placeholder="Address Line 3"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="City"
						name={[field.name, 'city']}
						key={[field.key, 'city']}
						placeholder="City"
						onChange={onChange}
						options={OrganisationStore.cityList}
						tooltip="It is a large human settlement place."
						rules={vsmOrganisations.validation.city}
						onFocus={() => fetchCity && OrganisationStore.getCityList().then(() => setFetchCity(false))}
						notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="State/County"
						name={[field.name, 'state_county']}
						key={[field.key, 'state_county']}
						placeholder="State/County"
						onChange={onChange}
						options={OrganisationStore.stateCountyList}
						tooltip="State is defined as a territory with its own government and borders within a larger country."
						rules={vsmOrganisations.validation.state}
						onFocus={() => fetchState && OrganisationStore.getStateCountyList().then(() => setFetchState(false))}
						notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Postal Code"
						name={[field.name, 'postal_code']}
						key={[field.key, 'postal_code']}
						placeholder="Postal Code"
						onChange={onChange}
						tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
						rules={vsmOrganisations.validation.postal_code}
						maxLength={16}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						required allowClear
						type="select"
						label="Country" placeholder="Country"
						name={[field.name, 'country_id']}
						key={[field.key, 'country_id']}
						onChange={(e) => {
							onChange()
							onCountryChange(e)
						}}
						options={{
							values: OrganisationStore.dropdown_countries_list || (
								(organisationValues && organisationValues.mutiple_addresses && organisationValues.mutiple_addresses.length > 0 && ((organisationValues.mutiple_addresses.length - 1) >= field.name) && organisationValues.mutiple_addresses[field.name].countries) ?
									([organisationValues.mutiple_addresses[field.name].countries]) : ([])
							),
							value_key: 'id',
							text_key: { key: ["iso_code_alpha2", " - ", "country_name"] }
						}}
						rules={vsmOrganisations.validation.country}
						tooltip="A country is a political state, nation, or territory which is controlled."
						onFocus={() => fetchCountry && OrganisationStore.getAllCountries().then(() => setFetchCountry(false))}
						notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>
			</Row>
		</>
	)
})

export default AddressSubElement
