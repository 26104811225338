import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class aETRReportsChildStore {
    agGrid = null
    setting_value = null

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = this.setting_value && this.setting_value.data && this.setting_value.data
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: columnConfig })
        }
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('child_aETR_report_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    getSetting = (id, payload) => {
        return Axios.post(`/report/get/table/grid/${id}`, payload).then(({ data }) => {
            this.setting_value = data
            return data
        }).catch((err) => {
            // let errors = [];
            // Object.keys(data.errors).forEach((name) => {
            //     errors.push({ name, errors: data.errors[name] });
            // });
            // data.errors = errors;
            // return Promise.reject(data);
        })
    }

    columnsConfig = (payload) => {
        return Axios.post('/report/table/grid/save', payload).then(({ data }) => {
            return data
        }).catch(({ response: { data } }) => {
            let errors = [];
            Object.keys(data.errors).forEach((name) => {
                errors.push({ name, errors: data.errors[name] });
            });
            data.errors = errors;
            return Promise.reject(data);
        });
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params
        // this.setFilter(params.api)
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay();
        }
    }
}

decorate(aETRReportsChildStore, {
    agGrid: observable,
    setting_value: observable,
    setupGrid: action,
    onGridChanged: action,
    onFilterChanged: action,
})