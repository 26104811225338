import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class DashboardStore {
	days_journals = 7
	days_activeuser = 7
	days_transactionmodels = 7
	days_tradeledgers = 7

	count_journals = 0
	count_activeuser = 0
	count_transactionmodels = 0
	count_tradeledgers = 0

	// set number of days in journals widget
	setDaysJournalCount = (value) => {
		this.days_journals = value
		this.getJournalCount()
	}

	// set number of days in active users widget
	setDaysUserCount = (value) => {
		this.days_activeuser = value
		this.getUserCount()
	}

	// set number of days in transactions widget
	setDaysTransactionCount = (value) => {
		this.days_transactionmodels = value
		this.getTransactionCount()
	}

	// set number of days in trade ledgers widget
	setDaysTradeLedgersCount = (value) => {
		this.days_tradeledgers = value
		this.getTradeLedgersCount()
	}

	// call api to get count of journals
	getJournalCount = (days = this.days_journals) => {
		Axios(`dashboard/journalcount/${days}`).then(({ data }) => {
			this.count_journals = data.data[0].count
		})
	}

	// call api to get count of active users
	getUserCount = (days = this.days_activeuser) => {
		Axios(`dashboard/usercount/${days}`).then(({ data }) => {
			this.count_activeuser = data.data[0].count
		})
	}

	// call api to get count of transactions
	getTransactionCount = (days = this.days_transactionmodels) => {
		Axios(`dashboard/transactioncount/${days}`).then(({ data }) => {
			this.count_transactionmodels = data.data[0].count
		})
	}

	// call api to get count of trade ledgers
	getTradeLedgersCount = (days = this.days_tradeledgers) => {
		Axios(`dashboard/ledgercount/${days}`).then(({ data }) => {
			this.count_tradeledgers = data.data[0].count
		})
	}
}

decorate(DashboardStore, {
	days_journals: observable,
	days_activeuser: observable,
	days_transactionmodels: observable,
	days_tradeledgers: observable,
	count_journals: observable,
	count_activeuser: observable,
	count_transactionmodels: observable,
	count_tradeledgers: observable,
	setDaysJournalCount: action,
	setDaysUserCount: action,
	setDaysTransactionCount: action,
	setDaysTradeLedgersCount: action,
	getJournalCount: action,
	getUserCount: action,
	getTransactionCount: action,
	getTradeLedgersCount: action
})
