import React from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../../../store'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionModelStore, TransactionModelStore: { handleRemove, array_list, journalLinesValues } } = useStore()

	// Make function call to delete existing record
	const handleSubmit = () => {
		const elementsIndex = array_list.findIndex(element => element.index === journalLinesValues.index)
		if (array_list.length === 1) {
			props.setErrorMessage(true)
		} else {
			props.handleChange()
		}
		if (array_list[elementsIndex] && array_list[elementsIndex].id) {
			var array = TransactionModelStore.deleted_list
			array.push(array_list[elementsIndex].id)
			TransactionModelStore.deleted_list = array
		}
		handleRemove(elementsIndex)
		close()
	}

	// close delete form
	const close = () => {
		props.close()
		TransactionModelStore.journalLinesValues = null
	}

	return (
		<Modal
			title={`Delete Journal Line`}
			centered
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteJournalLineform' htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteJournalLineform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to remove this Journal Line?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	)
})

export default DeleteComponent
