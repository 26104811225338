import React, { useState } from 'react'
import { observer } from "mobx-react"
import { Button, Checkbox, Form, Drawer, Row, Col, Divider } from 'antd'
import useStore from '../../../../store'
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'

const AdditionalColumnListComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const { AllTransactionStore } = useStore()
	var additionalColumn = JSON.parse(localStorage.getItem('ADDITIONAL_COLUMN_LIST'))
	var transaction_grid = JSON.parse(localStorage.getItem('transaction_grid'))

	const handleSubmit = (data) => {
		setSaving(true)
		localStorage.setItem("ADDITIONAL_COLUMN_LIST", JSON.stringify(data.additionalColumnList && data.additionalColumnList.sort((a, b) => (a - b))))
		var temp_transaction_grid = JSON.parse(localStorage.getItem('transaction_grid'))
		temp_transaction_grid && temp_transaction_grid.forEach((itemField) => {
			if (AllTransactionStore.transaction_local_grid.find(x => x.colId === itemField.colId)) {
				if (data.transaction_local_grid.includes(itemField.colId)) {
					itemField.hide = false
				} else {
					itemField.hide = true
				}
			}
		})
		localStorage.setItem('transaction_grid', JSON.stringify(temp_transaction_grid))
		AllTransactionStore.setupGrid(AllTransactionStore.agGrid)
		vsmNotify.success({
			message: vsmAllTransactions.add_additional_column
		})
		setSaving(false)
		close()
	}

	const handleChange = () => {
		form.validateFields().then((data) => {
			if (data.transaction_local_grid.length > 0) {
				setDisabled(false)
			} else {
				setDisabled(true)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const close = () => {
		form.resetFields()
		props.close()
	}

	return (
		<Drawer
			title="Add Additional Column in Listing"
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='additionalList' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className='ml-10' key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
				</div>
			]}
			placement='right'
			width={'75%'}>
			<Form
				form={form}
				id="additionalList"
				onFinish={handleSubmit}
				labelCol={{ span: 5 }}
				onChange={handleChange}>
				<Form.Item name='transaction_local_grid' initialValue={transaction_grid && transaction_grid.map(x => !x.hide && x.colId)}>
					<Checkbox.Group onChange={handleChange}>
						<Row gutter={24}>
							{AllTransactionStore.transaction_local_grid && AllTransactionStore.transaction_local_grid.map((action) => {
								return (
									<Col span={8}>
										<Checkbox value={action.colId}>{action.label}</Checkbox>
									</Col>
								)
							})}
						</Row>
					</Checkbox.Group>
				</Form.Item>
				<Divider />
				<Form.Item name='additionalColumnList' initialValue={additionalColumn}>
					<Checkbox.Group onChange={handleChange}>
						<Row gutter={24}>
							{AllTransactionStore.dropdown_list && AllTransactionStore.dropdown_list.map((action, index) => {
								return (
									<Col span={8}>
										<Checkbox value={action.id}>{action.field_name} ({action.field_type})</Checkbox>
									</Col>
								)
							})}
						</Row>
					</Checkbox.Group>
				</Form.Item>
			</Form>
		</Drawer>
	)
})

export default AdditionalColumnListComponent
