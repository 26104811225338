import React, { useState } from 'react'
import { Form, Button, Modal, Spin, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { InfoCircleOutlined } from '@ant-design/icons';
import { vsmLedgerMapBank, vsmNotify } from '../../../../../config/messages'
import InputComponent from '../../../../../components/InputComponent'

const CloneFieldComponent = observer((props) => {
	const [form] = Form.useForm()
	const { LedgerBankAccountStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fetchBank, setFetchBank] = useState(true)

	// Make function call to Deactivate exixting record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = LedgerBankAccountStore.mapBankValues.id
		LedgerBankAccountStore.CloneMapBankData(data).then(() => {
			vsmNotify.success({
				message: vsmLedgerMapBank.save
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false) })
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// reset form and close deactivate form
	const close = () => {
		props.close()
		form.resetFields()
		setFetchBank(true)
		setDisabled(true)
	}

	return LedgerBankAccountStore.mapBankValues ? (
		<Modal
			centered
			title={<span>Clone Field Mappings and Journal Mapping From Other Bank Account <small><Tooltip title="You can clone existing Fields and Journal mapping from other bank account if it same format."><InfoCircleOutlined /></Tooltip></small></span>}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<>
					<Button key="1" disabled={disabled} form='cloneform' loading={saving} htmlType="submit" type="primary">Clone</Button>
					<Button key="2" htmlType="button" type="primary" onClick={() => { close(); props.openFieldModal(LedgerBankAccountStore.mapBankValues, false) }}>Enter Manually</Button>
					<Button key="3" htmlType="button" onClick={close}>Cancel</Button>
				</>
			]}
			width="600px"
		>
			<Form form={form} id="cloneform"
				onChange={handleChange}
				onFinish={handleSubmit}
			>
				<InputComponent
					label="Bank Account" allowClear
					required name="bank_account_id"
					type="select" placeholder="Select Bank Account"
					onChange={handleChange}
					rules={vsmLedgerMapBank.validation.bank_account}
					onFocus={() => fetchBank && LedgerBankAccountStore.getBankAccountsList().then(() => setFetchBank(false))}
					notFoundContent={fetchBank ? <Spin size="small" /> : "No Record Found."}
					options={{
						values: LedgerBankAccountStore.bankAccountsList,
						value_key: "id",
						text_key: "bankAccount_names",
					}}
					autoComplete="off"
				/>
			</Form>
		</Modal >
	) : null
})

export default CloneFieldComponent
