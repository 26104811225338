import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import useStore from '../../../../store'
import { vsmOrgBankAccount, vsmNotify } from '../../../../config/messages'

const ActivateComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrgBankAccountStore: { ToggleData, currentValues } } = useStore()
	const [saving, setSaving] = useState()
	const { id } = useParams()

	// Make function call to activate exixting record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = currentValues.id
		var payload = { status: 1 }
		var params = { organisation_id: id }
		ToggleData(data, payload, params).then(() => {
			vsmNotify.success({
				message: vsmOrgBankAccount.activated
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false) })
	}

	// reset form and close activate form
	const close = () => {
		props.close()
		form.resetFields()
	}

	return currentValues ? (
		<Modal
			centered
			title={`Activate Bank Account - #${currentValues.id} - ${currentValues.banks.name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='activateform' loading={saving} htmlType="submit" type="primary">Activate</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="activateform" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to Activate this Bank account?</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default ActivateComponent
