import React from 'react'
import { Button, Col, Row } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons';

const NoRecordAlert = observer(({ setNoDataAlert, visible }) => {
	return (
		<Modal
			centered
			title={`No Data Filter`}
			visible={visible}
			onCancel={() => setNoDataAlert(false)}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" onClick={() => setNoDataAlert(false)} type="primary">OK</Button>,
			]}
		>
			<Row align="middle">
				<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
				<Col span={20}>
					<h3>Sorry, Selected filters don't have any data. Please try to rephrase your filter.</h3>
				</Col>
			</Row>
		</Modal>
	)
})

export default NoRecordAlert
