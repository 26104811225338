import React from 'react';
import { CurrencyFormat, DateComparator, CurrencySorting } from '../../../../utils/GlobalFunction';

export const gridOptions = {
    columnDefs: [{
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        sortable: false,
        floatingFilter: false,
        width: 55,
        suppressMenu: true,
        pinned: 'left'
    }, {
        headerName: '# ID',
        field: 'id',
        headerTooltip: "# ID",
        tooltipField: "id",
        filter: 'agNumberColumnFilter',
        pinned: 'left',
        width: 65
    }, {
        headerName: 'Date',
        headerTooltip: "Date",
        field: 'value_on',
        tooltipField: "value_on",
        filter: 'agDateColumnFilter',
        filterParams: {
            buttons: ['reset'],
            inRangeInclusive: true,
            comparator: DateComparator
        },
        width: 85,
    }, {
        headerName: 'Account Number',
        headerTooltip: "Account Number",
        field: 'bank_account_number',
        tooltipField: 'bank_account_number',
        width: 85
    }, {
        headerName: 'Transaction Details',
        headerTooltip: "Transaction Details",
        field: 'transaction_details',
        tooltipField: 'transaction_details',
        width: 220
    }, {
        headerName: 'Transaction Type',
        headerTooltip: "Transaction Type",
        field: 'cash_transactions_types.type_name',
        tooltipField: "cash_transactions_types.type_name",
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultToNothingSelected: true,
        },
        width: 100
    }, {
        headerName: 'Debit',
        headerTooltip: "Debit",
        field: 'debit',
        type: 'rightAligned',
        filter: "agSetColumnFilter",
        tooltipValueGetter: function (data) {
            return data.data && data.data.debit ? data.data.debit : '0.00'
        },
        comparator: CurrencySorting,
        cellRendererFramework: function (data) {
            return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
        },
        width: 75
    }, {
        headerName: 'Credit',
        headerTooltip: "Credit",
        field: 'credit',
        type: 'rightAligned',
        filter: "agSetColumnFilter",
        comparator: CurrencySorting,
        cellRendererFramework: function (data) {
            return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
        },
        tooltipValueGetter: function (data) {
            return data.data && data.data.credit ? data.data.credit : '0.00'
        },
        width: 75
    }, {
        headerName: 'Bank ref',
        headerTooltip: "Bank ref",
        tooltipField: "bank_reference",
        field: 'bank_reference',
        width: 110
    }, {
        headerName: 'Status',
        headerTooltip: "Status",
        tooltipField: "reconciliation_status",
        field: 'reconciliation_status',
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultToNothingSelected: true,
            buttons: ['apply', 'reset'],
        },
        width: 71,
    },]
}
