import React, { useEffect } from 'react'
import { Card, Button } from 'antd'
import { ReadOutlined } from '@ant-design/icons'
import useStore from '../store'
import { observer } from 'mobx-react'

const TradeWidget = observer(() => {
	const { DashboardStore: { getTradeLedgersCount, count_tradeledgers, setDaysTradeLedgersCount, days_tradeledgers } } = useStore()

	// get count of Trade Ledgers
	useEffect(() => {
		getTradeLedgersCount()
	}, [getTradeLedgersCount])

	return (
		<Card className="widget_wrapper">
			<div className="counter__widget">
				<div className="counter__widget__icon"><ReadOutlined /></div>
				<div className="counter__widget__description">
					<div className="counter__widget__name">TRADE LEDGERS</div>
					<div className="counter__widget__counts">{count_tradeledgers}</div>
				</div>
			</div>
			<div className="counter__widget__footer">
				<div className="counter__widget__buttons">
					<Button onClick={() => setDaysTradeLedgersCount(7)} type="link" size="small" className={"counter__widget__button " + (days_tradeledgers === 7 ? "cs_active" : "")}>7 Days</Button>
					<Button onClick={() => setDaysTradeLedgersCount(30)} type="link" size="small" className={"counter__widget__button " + (days_tradeledgers === 30 ? "cs_active" : "")}>30 Days</Button>
					<Button onClick={() => setDaysTradeLedgersCount(365)} type="link" size="small" className={"counter__widget__button " + (days_tradeledgers === 365 ? "cs_active" : "")}>12 Months</Button>
				</div>
			</div>
		</Card>
	)
})

export default TradeWidget
