import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Select, DatePicker, Radio, InputNumber, Divider, Space, Spin, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useStore from '../../../../../store'
import { observer } from 'mobx-react'
import { vsmNotify, vsmTaxSettings } from '../../../../../config/messages'
import moment from 'moment';

const { RangePicker } = DatePicker
const currentDate = new Date()
const lastMonthDate = new Date()
lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)
const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [add_tax_type_form] = Form.useForm()
	const { TaxSettingStore: { EditData, editValues, taxtype_list, AddTaxType, getAllTaxTypes }, UserStore } = useStore()
	const { UserStore: { user } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(false)
	const [isDate, setDate] = useState(false)
	const [isNonEditable, setNonEditable] = useState(true)
	const [isNewFields, setNewFields] = useState(false)
	const [fetchTaxType, setFetchTaxType] = useState(true)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		delete data["confirm"]
		EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTaxSettings.edit
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		setNonEditable(true)
		setDate(false)
		setNewFields(false)
		setDisabled(true)
		if (editValues) {
			form.setFieldsValue({
				id: editValues.id,
				tax_name: editValues.tax_name,
				tax_type: editValues.tax_type,
				tax_value: editValues.tax_value,
			})
		}
	}, [editValues, form, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			var openNewField = form.getFieldsValue().tax_value !== editValues.tax_value
			if (form.getFieldsValue().tax_value !== editValues.tax_value) {
				setNewFields(true)
			}
			if (openNewField) {
				if ((form.getFieldsValue().tax_value === "") || (form.getFieldsValue().tax_value === null) || (form.getFieldsValue().tax_value <= 0)) {
					form.setFieldsValue({
						tax_value: 0
					})
				}
				if (form.getFieldsValue().tax_value > 99.99) {
					form.setFieldsValue({
						tax_value: 99.99
					})
				}
				if (form.getFieldsValue().confirm && form.getFieldsValue().confirm.toLowerCase() === "confirm") {
					setDisabled(false)
				} else {
					setDisabled(true)
				}
			} else {
				setDisabled(false)
			}
		}).catch(d => {
			if (d.errorFields && d.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close edit form
	const close = () => {
		form.resetFields()
		setDisabled(false)
		setNonEditable(true)
		setNewFields(false)
		props.close()
	}

	// handle Date Picker visibility
	const handleDatePicker = (event) => {
		if (event.target.value === "data_in_specific_date_range") {
			setDate(true)
		} else {
			setDate(false)
		}
	}

	// enable tax value for editing
	const editAnyway = () => {
		setNonEditable(false)
	}

	// For adding tax type, only for super admin
	const addItem = (e) => {
		add_tax_type_form.validateFields().then((data) => {
			var payload = {
				enum_value: add_tax_type_form.getFieldsValue().enum_value,
				enum_type: "tax_type"
			}
			AddTaxType(payload).then(() => {
				getAllTaxTypes()
				add_tax_type_form.resetFields()
			}).catch(e => {
				if (e.errors) { add_tax_type_form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		})
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Tax Settings - #${editValues.id} - ${editValues.tax_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={650}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="tax_type" rules={vsmTaxSettings.validation.tax_type} label="Tax Type" required tooltip='Tax Type can be used to group taxes for easier identification, i.e. : VAT, Sales, Turnover.'>
							{
								(user.id !== 1) ? (
									<Select placeholder="Select Tax Type" onChange={handleChange}
										onFocus={() => fetchTaxType && getAllTaxTypes().then(() => setFetchTaxType(false))}
										notFoundContent={fetchTaxType ? <Spin size="small" /> : "No Record Found."}>
										{
											(taxtype_list && taxtype_list.map((item, index) => (
												<Select.Option key={index} value={item}>{item}</Select.Option>
											)))
										}
									</Select>
								) : (
									<Select
										placeholder="Add New Tax Type"
										onChange={handleChange}
										onFocus={() => fetchTaxType && getAllTaxTypes().then(() => setFetchTaxType(false))}
										notFoundContent={fetchTaxType ? <Spin size="small" /> : "No Record Found."}
										dropdownRender={(!UserStore.checkPermission(33, 'add_tax_type')) ? null : (
											menu => (
												<div>
													{menu}
													<Form form={add_tax_type_form} id="addtaxtypeform" className="add_item_dropdown_wrapper">
														<Form.Item name="enum_value" className="w-100 mb-0" rules={vsmTaxSettings.validation.enum_value}>
															<Input maxLength={50} />
														</Form.Item>
														<Button htmlType="button" className="ml-10" type="primary" form='addtaxtypeform' onClick={addItem}>
															<PlusOutlined /> Add item
														</Button>
													</Form>
												</div>
											)
										)}
									>
										{
											(taxtype_list && taxtype_list.map((item, index) => (
												<Select.Option key={index} value={item}>{item}</Select.Option>
											)))
										}
									</Select>
								)
							}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="tax_name" rules={vsmTaxSettings.validation.tax_name} label="Tax Name" required tooltip='Tax Name is Name of Tax that can be applied to different type of transactions when they are created. Taxes are defined based on Government regulations in your country and are linked with Journals, i.e. Sales Vat, Tax on Goods, No Tax.'>
							<Input placeholder="Enter Tax Name" maxLength={50} autoComplete="off" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="tax_value" label="Tax Value" required extra={[
							<span key="1">If you edit Tax Value, you will need to select some additional configuration too. <Button type="link" onClick={editAnyway} style={{ padding: 0, height: "auto" }}>Click here</Button> to edit it anyway.</span>
						]} tooltip='Tax Value is a Percentage value defined by Government to be used or applied on different type of goods or services (transactions).'>
							<InputNumber min={0} max={99.99} step={0.01}
								formatter={value => `${value}%`} parser={value => value.replace('%', '')}
								disabled={isNonEditable} onChange={handleChange} autoComplete="off" className='w-100' />
						</Form.Item>
					</Col>
				</Row>

				{(isNewFields) ? (
					<>
						<Divider />
						<Space direction="vertical">
							<label> New Tax Value will applied on : </label>
							<Form.Item initialValue="new_data_only" name="change_to_new_data" rules={vsmTaxSettings.validation.new_tax_value}>
								<Radio.Group buttonStyle="solid" onChange={handleDatePicker}>
									<Radio.Button key="1" value="all_past_data">Apply to all past data</Radio.Button>
									<Radio.Button key="2" value="data_in_specific_date_range">Apply to data in specific date range</Radio.Button>
									<Radio.Button key="3" value="new_data_only">Apply to New Data only</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</Space>
						{(isDate) ? (
							<Form.Item name="date_range" initialValue={[moment(lastMonthDate), moment(currentDate)]}>
								<RangePicker onChange={handleChange} className="ml-auto mr-auto" allowClear={false} />
							</Form.Item>
						) : (
							null
						)}
						<Form.Item name="confirm">
							<Input placeholder="Type 'CONFIRM' as this action cannot be undone." />
						</Form.Item>
					</>
				) : (
					null
				)}
			</Form>
		</Modal >
	) : null
})

export default EditComponent
