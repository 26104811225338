import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import OrgDetailComponent from '../../../OrganisationManagement/RoleMapping/component/OrgDetailComponent'
import InputComponent from '../../../../components/InputComponent'
import { PlusCircleTwoTone } from '@ant-design/icons';
import useStore from '../../../../store'
import { vsmOrganisations, vsmPeople } from '../../../../config/messages'
import { Form, Col, Row, Spin } from 'antd'
import debounce from 'lodash/debounce'
import PplDetailComponent from './PplDetailComponent';

const FormComponent = observer(({ onChange, form, handleSubmit, fetchRoles, fetchTags, setFetchRoles, setFetchTags, setCurrentTab, openAddModal, visible, id, currentTab, relatedToTab, rejectedkeys, setRejectedKeys, fetchRelatedRoles, setFetchRelatedRoles, type }) => {

	const { OrganisationStore, PeopleRoleMappingStore, PeopleStore, UserStore } = useStore()
	const [searchROrgKey, setSearchROrgKey] = useState()
	const [searchRPplKey, setSearchRPplKey] = useState()
	const [searchPplKey, setSearchPplKey] = useState()

	const onFinish = (data) => {
		handleSubmit(data)
	}

	// search string for dropdown
	const handleROrgSearch = debounce((val) => {
		OrganisationStore.getAllROrg({ search_for: val, related_entity_type: 1 })
	}, 500)

	// search string for dropdown
	const handleRPplSearch = debounce((val) => {
		PeopleStore.getPeopleSearchList({ search_for: val, related_entity_type: 2 })
	}, 500)

	const handleSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchPplKey(val);
			PeopleStore.getAllPeople({ search_for: val, mapping_type: 2 })
		};

		return debounce(loadOptions, 500);
	}, [PeopleStore])

	// get related organisation data
	const handleROrgChange = useCallback((data) => {
		if (data) {
			OrganisationStore.getOrgCardValue(data, "relatedOrg")
		} else {
			OrganisationStore.setROrganisationValues(null)
		}
	}, [OrganisationStore])

	// get related organisation data
	const handleRPplChange = useCallback((data) => {
		if (data) {
			PeopleStore.getPeopleCardValue(data, 'relatedPeople')
		} else {
			PeopleStore.rPeopleCardValue = null
		}
	}, [PeopleStore])

	// set the form values to add
	useEffect(() => {
		if (PeopleStore.peopleValues && visible) {
			PeopleStore.rPeopleValues = null
			form.setFieldsValue({
				people_id: PeopleStore.peopleValues.id,
			})
		}
	}, [OrganisationStore, form, visible, PeopleStore])

	const handleCardChange = (id) => {
		if (id) {
			PeopleStore.getPeopleCardValue(id, 'people')
		} else {
			PeopleStore.peopleCardValue = null
		}
	}

	const handleRoleChange = () => {
		if (form.getFieldValue("role_id") && form.getFieldValue("related_entity_type") === "People") {
			PeopleStore.getRelatedPeopleRoles({ role_id: form.getFieldValue("role_id") })
				.then(() => setFetchRelatedRoles(false))
			form.resetFields(["related_role_id"])
		}
	}

	// set the form values to add
	useEffect(() => {
		if (PeopleRoleMappingStore.editValues && visible) {
			if (PeopleRoleMappingStore.editValues.related_entity_type === "People") {
				handleRPplChange(PeopleRoleMappingStore.editValues.related_entity_id)
			} else {
				handleROrgChange(PeopleRoleMappingStore.editValues.related_entity_id)
			}
			setRejectedKeys(PeopleRoleMappingStore.editValues.role_id)
			form.setFieldsValue({
				people_id: PeopleRoleMappingStore.editValues.people.id,
				role_id: PeopleRoleMappingStore.editValues.role_id,
				related_role_id: PeopleRoleMappingStore.editValues.related_role_id,
				related_entity_type: PeopleRoleMappingStore.editValues.related_entity_type,
				related_entity_id: PeopleRoleMappingStore.editValues.related_entity_id,
				related_people_id: PeopleRoleMappingStore.editValues.related_people_id,
				exchange_ref_id: PeopleRoleMappingStore.editValues.exchange_ref_id ? PeopleRoleMappingStore.editValues.exchange_ref_id.toString() : null,
				tag_ids: (PeopleRoleMappingStore.editValues.tag_ids && PeopleRoleMappingStore.editValues.tag_ids.length > 0) ? PeopleRoleMappingStore.editValues.tag_ids : undefined
			})
			setCurrentTab(PeopleRoleMappingStore.editValues.related_entity_type)
		}

	}, [PeopleRoleMappingStore.editValues, form, visible, setCurrentTab, handleROrgChange, handleRPplChange, setRejectedKeys])
	return (
		<Form
			form={form}
			id="mapaddeditform"
			layout='vertical'
			onChange={onChange}
			onFinish={onFinish}
		>
			<Row gutter={24}>
				<Col span={12}>
					<InputComponent
						required
						type="select"
						label="People"
						name="people_id"
						placeholder="People"
						disabled={id}
						notFoundContent={PeopleStore.fetching ? <Spin size="small" /> : searchPplKey ? "No Record Found." : null}
						onSearch={handleSearch}
						onChange={() => {
							handleCardChange(form.getFieldValue('people_id'))
							onChange()
						}}
						options={{
							values: PeopleStore.drowpdown_people_list
								|| (
									PeopleRoleMappingStore.editValues && type === 'edit' && PeopleRoleMappingStore.editValues.people && [{
										id: PeopleRoleMappingStore.editValues.people.id,
										entity_name: PeopleRoleMappingStore.editValues.people.first_name + " " + (PeopleRoleMappingStore.editValues.people.last_name ? PeopleRoleMappingStore.editValues.people.last_name : '')
									}]
								)
								|| (PeopleStore.peopleValues && [{
									id: PeopleStore.peopleValues && PeopleStore.peopleValues.id,
									entity_name: PeopleStore.peopleValues && PeopleStore.peopleValues.first_name + " " + (PeopleStore.peopleValues.last_name ? PeopleStore.peopleValues.last_name : ''),
								}]),
							value_key: 'id',
							text_key: 'entity_name',
						}}
						tooltip="People is an entity (for example a Person) that has (or had in the past) – directly or indirectly - any business relationship with Credebt Exchange or with another Organisation"
						extra={[
							(UserStore.checkPermission(41, 'add') && !id) ? (
								<PlusCircleTwoTone className="ml-10" onClick={openAddModal} />
							) : null
						]}
					/>
				</Col>
				<Col span={12}>
					<InputComponent
						required type="select" allowClear label="People Role" name="role_id" placeholder="Select People Role"
						onChange={() => { handleRoleChange(); onChange() }}
						options={{
							values: PeopleStore.dropdown_pplRole_list || (PeopleRoleMappingStore.editValues && [{
								id: PeopleRoleMappingStore.editValues.role_id,
								role_name: PeopleRoleMappingStore.editValues.role_name
							}]),
							value_key: 'id',
							text_key: 'role_name',
						}}
						tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
						rules={vsmPeople.validation.organisation_role}
						onFocus={() => fetchRoles && PeopleStore.getPeopleRoles().then(() => setFetchRoles(false))}
						notFoundContent={fetchRoles ? <Spin size="small" /> : "No Record Found."}
					/>
				</Col>
				{
					PeopleStore.peopleCardValue && (
						<Col flex={1}>
							<PplDetailComponent data={PeopleStore.peopleCardValue} />
						</Col>
					)
				}
			</Row>

			<InputComponent
				required type="radio_button" label="Relate to" name="related_entity_type"
				initialValue={relatedToTab ? relatedToTab : "Organisation"}
				options={{
					values: [
						{ value: 'Organisation', text: 'Organisation' },
						{ value: 'People', text: 'People' },
						{ value: 'Ledger', text: UserStore.user.default_ledger_name },
					]
				}}
				tooltip="There will be option to select: Organisation/People/Ledger Name."
				rules={vsmOrganisations.validation.related_entity_type}
			/>
			{JSON.stringify(relatedToTab)}
			{
				currentTab === "Ledger" ? null : (
					currentTab === "Organisation" ?
						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Related Organisation" name="related_entity_id" placeholder="Related Organisation"
									onChange={(data) => {
										onChange(data)
										handleROrgChange(data)
									}}
									options={{
										values: OrganisationStore.dropdown_ROrg_list || (PeopleRoleMappingStore.editValues && [{
											id: PeopleRoleMappingStore.editValues.related_entity_id,
											entity_name: PeopleRoleMappingStore.editValues.related_entity_name,
										}]),
										value_key: 'id',
										text_key: 'entity_name',
									}}
									tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
									rules={vsmOrganisations.validation.related_organisation}
									filterOption={false}
									notFoundContent={OrganisationStore.fetching ? <Spin size="small" /> : (searchROrgKey) ? "No Record Found!" : null}
									onSearch={debounce((val) => { setSearchROrgKey(val); handleROrgSearch(val) }, 500)}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Related Role" onChange={onChange}
									options={{
										values: OrganisationStore.dropdown_orgRole_list || (PeopleRoleMappingStore.editValues && [{
											id: PeopleRoleMappingStore.editValues.related_role_id,
											role_name: PeopleRoleMappingStore.editValues.related_role_name,
										}]),
										value_key: 'id',
										text_key: 'role_name',
									}}
									tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
									rules={vsmOrganisations.validation.related_role}
									onFocus={() => fetchRelatedRoles && OrganisationStore.getOrgRoles().then(() => setFetchRelatedRoles(false))}
									notFoundContent={fetchRelatedRoles ? <Spin size="small" /> : "No Record Found."}
								/>
							</Col>
							{
								(OrganisationStore.rOrganisationValues) && (
									<Col flex={1}>
										<OrgDetailComponent data={OrganisationStore.rOrganisationValues} />
									</Col>
								)
							}
						</Row>
						:
						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Related People" name="related_entity_id" placeholder="Related People"
									onChange={async (data) => {
										onChange(data)
										await handleRPplChange(data)
									}}
									options={{
										values: PeopleStore.dropdown_RPpl_list || (PeopleRoleMappingStore.editValues && [{
											id: PeopleRoleMappingStore.editValues.related_entity_id,
											entity_name: PeopleRoleMappingStore.editValues.related_entity_name,
										}]),
										value_key: 'id',
										text_key: 'entity_name',
										rejected_keys: [form.getFieldValue('people_id')]
									}}
									tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
									rules={vsmPeople.validation.related_organisation}
									filterOption={false}
									notFoundContent={PeopleStore.fetching ? <Spin size="small" /> : (searchRPplKey) ? "No Record Found!" : null}
									onSearch={debounce((val) => { setSearchRPplKey(val); handleRPplSearch(val) }, 500)}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Select Related Role" onChange={onChange}
									options={{
										values: PeopleStore.dropdown_RpplRole_list || (PeopleRoleMappingStore.editValues && [{
											id: PeopleRoleMappingStore.editValues.related_role_id,
											role_name: PeopleRoleMappingStore.editValues.related_role_name,
										}]),
										value_key: 'id',
										text_key: 'role_name',
										rejected_keys: [rejectedkeys]
									}}
									disabled={!form.getFieldValue('role_id')}
									tooltip="People Role Relation is the connection between the Roles played by two different People."
									rules={vsmOrganisations.validation.related_role}
									onFocus={() => fetchRelatedRoles && PeopleStore.getRelatedPeopleRoles({ role_id: form.getFieldValue('role_id') }).then(() => setFetchRelatedRoles(false))}
									notFoundContent={fetchRelatedRoles ? <Spin size="small" /> : "No Record Found."}
								/>
							</Col>
							{
								PeopleStore.rPeopleCardValue && (
									<Col flex={1}>
										<PplDetailComponent data={PeopleStore.rPeopleCardValue} />
									</Col>
								)
							}
						</Row>
				)
			}

			<Row gutter={24}>
				<Col span={12}>
					<InputComponent
						mode="tags" type="select" allowClear label="Tags" name="tag_ids" placeholder="Select one or more tags" onChange={onChange}
						options={{
							values: PeopleRoleMappingStore.dropdown_tags_list || (PeopleRoleMappingStore.editValues && PeopleRoleMappingStore.editValues.tag_ids && PeopleRoleMappingStore.editValues.tag_ids.map((x, i) => {
								return {
									id: x,
									tag_name: PeopleRoleMappingStore.editValues.tags_names[i]
								}
							})),
							value_key: 'id',
							text_key: 'tag_name',
						}}
						tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						rules={vsmOrganisations.validation.tag_name}
						maxTagTextLength={10}
						tokenSeparators={[',']}
						onFocus={() => fetchTags && PeopleRoleMappingStore.getAllTagsList().then(() => setFetchTags(false))}
						notFoundContent={fetchTags ? <Spin size="small" /> : "No Record Found."}
					/>
				</Col>
				<Col span={12}>
					<InputComponent
						label="Exchange Reference Id" name="exchange_ref_id" placeholder="Exchange Reference Id" onChange={onChange}
						tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
						rules={vsmOrganisations.validation.exchange_ref_id}
						maxLength={100}
					/>
				</Col>
			</Row>
		</Form>
	)
})

export default FormComponent
