import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input } from 'antd'
import useStore from '../../../../../../store'
import { observer } from 'mobx-react'
import { vsmNotify, vsmTransactionTypes } from '../../../../../../config/messages'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionTypeStore: { EditData, editValues } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionTypes.edit
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		if (editValues) {
			form.setFieldsValue({
				type_name: editValues.type_name,
			})
		}
	}, [editValues, form, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Trade Type - #${editValues.id} - ${editValues.type_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Form.Item name="type_name" rules={vsmTransactionTypes.validation.type_name} label="Trade Type" required tooltip='Trade Type is a main category of Trade (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Trade Types can further be classified by Transaction Types.'>
					<Input placeholder="Trade Type" maxLength={100} />
				</Form.Item>
			</Form>
		</Modal>
	) : null
})

export default EditComponent
