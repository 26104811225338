import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './utils/AppRouter'
import LayoutComponent from './components/LayoutComponent'
import useStore from './store'
import { observer } from 'mobx-react'
import Login from './pages/Login'
import { message, Result } from 'antd'

import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("CompanyName=Credebt Exchange,LicensedApplication=Credebt Machine,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-009646,ExpiryDate=12_August_2021_[v2]_MTYyODcyMjgwMDAwMA==e5c74299c6744c6d24043ad82929a45b");

const App = observer(() => {
	const { UserStore: { open_login, setAxiosInterceptors } } = useStore()
	const [isOnline, setOnlineStatus] = useState()

	useEffect(() => {
		setAxiosInterceptors()
	}, [setAxiosInterceptors])

	useEffect(() => {
		if (isOnline === 'online') {
			message.success('You are online again.');
		}
	}, [isOnline])

	window.addEventListener('online', () => setOnlineStatus('online'));
	window.addEventListener('offline', () => setOnlineStatus('offline'));

	return (
		<BrowserRouter>
			<LayoutComponent>
				{
					open_login ? (<Login />) : (<AppRouter />)
				}
			</LayoutComponent>
			{(isOnline === 'offline') && (
				<div className="offline_section">
					<Result
						status="500"
						title="No Internet!"
						subTitle="Please check your internet connectivity."
					/>
				</div>
			)}
		</BrowserRouter>
	)
})

export default App
