import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { WarningFilled } from '@ant-design/icons';
import useStore from '../../../../store'
import { vsmRolemNagement, vsmNotify } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const { RoleManagementStore } = useStore()
	const Store = {
		RoleManagement: RoleManagementStore,
	}

	// set the form values to delete
	useEffect(() => {
		if (Store.RoleManagement.deleteValues) {
			form.setFieldsValue({
				name: Store.RoleManagement.deleteValues.role_name,
			})
		}
	}, [Store.RoleManagement.deleteValues, form])

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = Store.RoleManagement.deleteValues.id
		Store.RoleManagement.DeleteData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmRolemNagement.deleteRole
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		props.close()
	}

	return Store.RoleManagement.deleteValues ? (
		<Modal
			centered
			title={`Delete Role - #${Store.RoleManagement.deleteValues.id} - ${Store.RoleManagement.deleteValues.role_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want to remove this user role?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
