import React from 'react'
import { Card } from 'antd'
import { observer } from 'mobx-react'

const OrgDetailComponent = observer((props) => {
	return (
		<Card size="small" className="mb-20">
			<h3><b>{props.data.organisation_name} ({props.data.trade_name ? props.data.trade_name + ' - ' : ''}{props.data.global_countries ? props.data.global_countries.country_name : ''})</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						{(props.data.multiple_phone && props.data.multiple_phone.length > 0) ? (
							<tr>
								<td>Phone</td>
								<td>:</td>
								<td>{props.data.multiple_phone && props.data.multiple_phone.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{props.data.website ? (
							<tr>
								<td>Website</td>
								<td>:</td>
								<td>{props.data.website && props.data.website}</td>
							</tr>
						) : null}

						{props.data.addresses ? (
							<tr>
								<td>Address</td>
								<td>:</td>
								<td>
									{props.data.addresses &&
										<div>
											{props.data.addresses.address_1}
											{props.data.addresses.address_2 && (', ' + props.data.addresses.address_2)}
											{props.data.addresses.address_3 && (', ' + props.data.addresses.address_3)}
											{props.data.addresses.city && (', ' + props.data.addresses.city)}
											{props.data.addresses.state_county && (', ' + props.data.addresses.state_county)}
											{props.data.addresses.countries && (', ' + props.data.addresses.countries.country_name)}
											{props.data.addresses.postal_code && (', ' + props.data.addresses.postal_code)}
										</div>
									}
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</Card>
	)
})

export default OrgDetailComponent
