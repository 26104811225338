import Axios from 'axios'
import { action, decorate, observable } from 'mobx'
import moment from 'moment'
import { sequenceAccArray } from '../../utils/GlobalFunction'

export default class BankAuditReportStore {
    agGrid = null
    paymentsReceiptsagGrid = null
    paymentsReceiptsagGridExternal = null
    fYear = []
    listData = null
    account_number_array = []
    agGridNotIdentifiedLeft = null
    agGridNotIdentifiedRight = null
    not_identified_data_from = null
    not_identified_data_to = null
    max_week = null

    listTempPayload = null
    notIdentifiedDetail = null

    constructor() {
        let currentYear = moment().year()
        let dummyObj = { text: `Jan ${currentYear} - Dec ${currentYear}`, value: `Jan-${currentYear}:Dec-${currentYear}` }
        this.fYear.push(dummyObj)
    }

    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = localStorage.getItem('bank_audit_report')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
    }

    getFYear = () => {
        return Axios.get('report/financial/year').then(({ data }) => {
            this.fYear = data.data
            return data.data
        })
    }

    getList = (payload) => {
        this.listTempPayload = payload ? payload : this.listTempPayload
        // let temp = this.listTempPayload ? this.listTempPayload : payload
        let temp_data = {}
        return Axios.post('report/bank-audit-report', this.listTempPayload).then(({ data }) => {
            sequenceAccArray.forEach(acc_no => {
                Object.keys(data.data).forEach((api_acc) => {
                    if (acc_no === Number(api_acc)) {
                        temp_data['e' + api_acc] = data.data[api_acc]
                    }
                })
            })
            Object.keys(temp_data).forEach(mainAcc => {
                let singleAccDetail = temp_data[mainAcc]
                let weekKeys = Object.keys(singleAccDetail)
                weekKeys.forEach((week) => {
                    if (!['columns', 'currency'].includes(week)) {
                        let splitWeek = week.split('_')
                        let tempPaymentObj = {}
                        let tempReceiptObj = {}
                        singleAccDetail['columns']['payments'].forEach(item => tempPaymentObj[item] = 0)
                        singleAccDetail['columns']['receipts'].forEach(item => tempReceiptObj[item] = 0)
                        let temparray = weekKeys.map(x => !['columns', 'currency'].includes(x) && x.split('_')[0])
                        for (let index = 1; index <= Number(data.max_week); index++) {
                            if (!temparray.includes(index.toString())) {
                                singleAccDetail[`${index}_${splitWeek[1]}`] = {
                                    receipts: tempReceiptObj,
                                    payments: tempPaymentObj
                                }
                            }
                        }
                    }
                })
            })
            this.listData = temp_data
            this.max_week = data.max_week
        }).catch(({ response: { data } }) => {
            return Promise.reject(data)
        })
    }

    // Call preview report api
    PreviewReport = (formdata) => {
        return Axios.post('report/bank-audit-report/payment-receipt-transactions', formdata).then(({ data }) => {
            return data.data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    setupGridPaymentsReceipts = (params) => {
        this.paymentsReceiptsagGrid = params
        let columnConfigReceipt = localStorage.getItem('bank_audit_receipt_payment')
        if (this.paymentsReceiptsagGrid && this.paymentsReceiptsagGrid.columnApi && columnConfigReceipt) {
            this.paymentsReceiptsagGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfigReceipt) })
        }
    }

    setupGridPaymentsReceiptsDrawer = (params) => {
        this.paymentsReceiptsagGrid = params
        let columnConfigData = localStorage.getItem('bank_audit_receipt_payment_external')
        if (this.paymentsReceiptsagGridExternal && this.paymentsReceiptsagGridExternal.columnApi && columnConfigData) {
            this.paymentsReceiptsagGridExternal.columnApi.applyColumnState({ state: JSON.parse(columnConfigData) })
        }
    }

    // Set column width after resizing colums
    onGridChangedPaymentsReceipts = (params) => {
        localStorage.setItem('bank_audit_receipt_payment', JSON.stringify(params.columnApi.getColumnState()))
    }

    onGridChangedPaymentsReceiptsDrawer = (params) => {
        localStorage.setItem('bank_audit_receipt_payment_external', JSON.stringify(params.columnApi.getColumnState()))

    }

    // Setup grid and set column size to autosize
    setupGridNotIdentifiedLeft = (params) => {
        this.agGridNotIdentifiedLeft = params
    }

    // Setup grid and set column size to autosize
    setupGridNotIdentifiedRight = (params) => {
        this.agGridNotIdentifiedRight = params
    }

    getNotIdentifiedData = (formData) => {
        this.notIdentifiedDetail = formData ? formData : this.notIdentifiedDetail
        let temp = this.notIdentifiedDetail ? this.notIdentifiedDetail : formData
        return Axios.post(`report/bank-audit-report/errors-transactions`, temp).then(({ data }) => {
            this.not_identified_data_from = data.data.left
            this.not_identified_data_to = data.data.right
            data.data && data.data.right.forEach((item) => {
                this.account_number_array.push(item.bank_account_number)
            })
            return data.data
        })
    }

    manualVerification = (formData) => {
        return Axios.post('report/manually/identified', formData).then((data) => {
            return data
        })
    }

    getNotIdentifiedLeftData = (formData) => {
        return Axios.post(`report/bank-audit-report/errors-transactions-left`, formData).then(({ data }) => {
            this.not_identified_data_from = data.data.left
            return data.data
        })
    }

    getNotIdentifiedRightData = (formData) => {
        return Axios.post(`report/bank-audit-report/errors-transactions-right`, formData).then(({ data }) => {
            this.not_identified_data_to = data.data.right
            data.data && data.data.right.forEach((item) => {
                this.account_number_array.push(item.bank_account_number)
            })
            return data.data
        })
    }
}

decorate(BankAuditReportStore, {
    agGrid: observable,
    fYear: observable,
    paymentsReceiptsagGrid: observable,
    paymentsReceiptsagGridExternal: observable,
    listData: observable,
    account_number_array: observable,
    not_identified_data_from: observable,
    not_identified_data_to: observable,
    agGridNotIdentifiedLeft: observable,
    agGridNotIdentifiedRight: observable,
    listTempPayload: observable,
    notIdentifiedDetail: observable,
    max_week: observable,
    setupGrid: action,
    getFYear: action,
    getList: action,
    PreviewReport: action,
    setupGridPaymentsReceipts: action,
    onGridChangedPaymentsReceipts: action,
    setupGridPaymentsReceiptsDrawer: action,
    onGridChangedPaymentsReceiptsDrawer: action,
    getNotIdentifiedData: action,
    setupGridNotIdentifiedLeft: action,
    setupGridNotIdentifiedRight: action,
    manualVerification: action,
    getNotIdentifiedLeftData: action,
    getNotIdentifiedRightData: action
})