import React, { useState } from 'react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AllTransactionStore: { DeleteData, deleteValues } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	// Make transfer and delete button enable / disable
	const handleFieldChange = () => {
		if (form.getFieldsValue().confirm.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	// Reset form and close delete form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return deleteValues ? (
		<Modal
			centered
			title={`Delete Transaction - #${deleteValues.id} ${deleteValues.exchange_ref_id ? '- ' + deleteValues.exchange_ref_id : ''}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' disabled={disabled} loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				<Row>
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3 className="mb-20">Are you sure you want to remove this Transaction and all related data?</h3>
						<Form.Item style={{ marginBottom: 0 }} name="confirm">
							<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
