import React, { useCallback, useState } from 'react';
import { observer } from "mobx-react"
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import NumberFormat from 'react-number-format';
import PreviewDetailAuditTable from './PreviewDetailAuditTable';
import useStore from '../../../../store';
import PreviewCashReportTableElement from '../elements/PreviewCashReportTableElement'
import { Drawer, Spin } from 'antd';
import moment from 'moment';

const PreviewAuditReportTableElement = observer((props) => {
	let FYyear = props.financialYear?.split('-').slice(-1).toString()
	const [AccountDetail, setAccountDetail] = useState(null)
	const [DataType, setDataType] = useState(null)
	const [title, setTitle] = useState()
	const { SavedReportStore } = useStore()
	const [PreviewReport, setPreviewReport] = useState(null)
	const [openTransaction, SetTransaction] = useState(false)
	const [modeType, setModeType] = useState(null)

	var columnDefs = [{
		headerName: 'Group On',
		field: 'group_on',
		filter: false,
		sortable: false,
		pinned: 'left',
		width: 130,
		valueFormatter: function (params) {
			let _temp = params.data?.group_on?.split('-')
			if (FYyear && _temp?.length > 1) {
				if (params.node.rowIndex < 10) {
					return FYyear + '-W0' + (params.node.rowIndex)
				} else {
					return FYyear + '-W' + (params.node.rowIndex)
				}
			} else {
				return params.data.group_on
			}
		}
	}]

	const handleCellClick = (data, mode, account_number) => {
		setModeType(mode.charAt(0).toUpperCase() + mode.slice(1))
		SetTransaction(true)
		var payload = SavedReportStore.previewReportValues
		payload.bank_account_number = account_number
		payload.group_range = data.group_range
		payload.mode_type = mode
		payload.name = SavedReportStore.previewReportValues.name
		payload.code = SavedReportStore.previewReportValues.code
		payload.description = SavedReportStore.previewReportValues.description
		payload.report_type = 'audit'
		payload.action = "preview_child_report"
		SavedReportStore.PreviewReport(payload).then(data => {
			setPreviewReport(data.data)
		}).catch(e => {
			if (e.errors) { props.form.setFields(e.errors) }
		})
	}

	let list_data = []
	let list_object = {}
	let opening_object = {};
	let display_key = ''
	let PinnedData = []

	if (props.rowData) {
		Object.keys(props.rowData).forEach((key) => {
			let item = props.rowData[key]
			Object.keys(item).forEach((group) => {
				var value = item[group]
				if (group !== 'columns') {

					var s_group = group.split('_')
					if (s_group[0] < 10) { s_group[0] = '0' + s_group[0] }
					var c_group = s_group.join('-')
					if (list_object[group]) {
						list_object[group][key] = {
							payments: {
								main: value.payments ? value.payments.main : 0,
								errors: value.payments ? value.payments.errors : 0
							},
							receipts: {
								main: value.receipts ? value.receipts.main : 0,
								errors: value.receipts ? value.receipts.errors : 0
							},
							external: {
								main: value.external ? value.external.main : 0,
								errors: value.external ? value.external.errors : 0
							}
						}
					} else {
						list_object[group] = {
							group_on: c_group,
							group_range: c_group,
							account_number: key,
							[key]: {
								payments: {
									main: value.payments ? value.payments.main : 0,
									errors: value.payments ? value.payments.errors : 0
								},
								receipts: {
									main: value.receipts ? value.receipts.main : 0,
									errors: value.receipts ? value.receipts.errors : 0
								},
								external: {
									main: value.external ? value.external.main : 0,
									errors: value.external ? value.external.errors : 0
								}
							}
						}
					}
				}
				else {
					let value = item[group]
					if (list_object) {
						opening_object[key] = {
							// If show in all columns
							// payments: {
							// 	main: value.opening_balance ? value.opening_balance : 0,
							// 	errors: value.opening_balance ? value.opening_balance : 0
							// },
							receipts: {
								main: value.opening_balance ? value.opening_balance : 0,
								errors: value.opening_balance ? value.opening_balance : 0
							},
							// external: {
							// 	main: value.opening_balance ? value.opening_balance : 0,
							// 	errors: value.opening_balance ? value.opening_balance : 0
							// },
						}

						opening_object.group_on = 'Opening Balance';
						opening_object.group_range = 'Opening Balance';
						opening_object.account_number = key;
					}
				}
			})
			columnDefs.push({
				headerName: key,
				headerGroupComponentFramework: (params) => {
					return (
						<div className="d-flex align-items-center h-100 cs_pointer" onDoubleClick={() => {
							setDataType(null)
							setTitle('Account Number - ' + key)
							setAccountDetail(props.rowData[key])
						}}>{key}</div>
					)
				},
				children: [
					{
						headerName: 'Receipt',
						headerComponentFramework: () => {
							return (
								<div className="d-flex align-items-center h-100 cs_pointer" onDoubleClick={() => {
									setDataType('receipts')
									setTitle('Account Number - ' + key)
									setAccountDetail(props.rowData[key])
								}}>Receipt</div>
							)
						},
						field: `${key}.receipts`,
						width: 80,
						cellRendererFramework: (params) => (
							<div onDoubleClick={() => {
								if (params.data.group_on !== 'Total' /* && params.data.group_on !== 'Opening Balance' */) {
									setTitle('Account Number - ' + key)
									handleCellClick(params.data, 'receipt', key)
								}
							}} style={styles.cells}>
								{(params.data && params.data[key] && params.data[key].receipts) ? (
									<>
										<div><NumberFormat value={params.data[key].receipts.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
										{/* <div className={params.data[key].receipts.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].receipts.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div> */}
									</>
								) : <div style={styles.cells}>0.00</div>}
							</div>
						),
						cellStyle: { 'line-height': '16px', 'padding': '13px 7px 7px' },
						filter: false,
						sortable: false,
					},
					{
						headerName: 'Payment',
						headerComponentFramework: () => {
							return (
								<div className="d-flex align-items-center h-100 cs_pointer" onDoubleClick={() => {
									setDataType('payments')
									setTitle('Account Number - ' + key)
									setAccountDetail(props.rowData[key])
								}}>Payment</div>
							)
						},
						field: `${key}.payments`,
						width: 80,
						cellRendererFramework: (params) => (
							<div onDoubleClick={() => {
								if (params.data.group_on !== 'Total' /* && params.data.group_on !== 'Opening Balance' */) {
									setTitle('Account Number - ' + key)
									handleCellClick(params.data, 'payment', key)
								}
							}} style={styles.cells}>
								{(params.data && params.data[key] && params.data[key].payments) ? (
									<>
										<div><NumberFormat value={params.data[key].payments.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
										{/* <div className={params.data[key].payments.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].payments.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div> */}
									</>
								) : <div style={styles.cells}>0.00</div>}
							</div>
						),
						cellStyle: { 'line-height': '16px', 'padding': '13px 7px 7px' },
						filter: false,
						sortable: false,
					},
					{
						headerName: 'External',
						headerComponentFramework: () => {
							return (
								<div className="d-flex align-items-center h-100 cs_pointer" onDoubleClick={() => {
									setDataType('external')
									setTitle('Account Number - ' + key)
									setAccountDetail(props.rowData[key])
								}}>External</div>
							)
						},
						field: `${key}.external`,
						width: 80,
						cellRendererFramework: (params) => (
							<div onDoubleClick={() => {
								if (params.data.group_on !== 'Total' /* && params.data.group_on !== 'Opening Balance' */) {
									setTitle('Account Number - ' + key)
									handleCellClick(params.data, 'external', key)
								}
							}} style={styles.cells}>
								{(params.data && params.data[key] && params.data[key].external) ? (
									<>
										<div><NumberFormat value={params.data[key].external.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
										{/* <div className={params.data[key].external.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].external.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div> */}
									</>
								) : <div style={styles.cells}>0.00</div>}
							</div>
						),
						cellStyle: { 'line-height': '16px', 'padding': '13px 7px 7px' },
						filter: false,
						sortable: false,
					},
				],
			})
		})
		let list_total = {
			group_on: 'Total',
			group_range: 'Total',
			account_number: 'Total',
		}
		Object.keys(list_object).forEach((key) => {
			Object.keys(list_object[key]).forEach(account => {
				list_total[account] = list_total[account] || {}
				if (!['group_on', 'group_range', 'account_number'].includes(account)) {
					Object.keys(list_object[key][account]).forEach(item => {
						list_total[account][item] = list_total[account][item] || {}
						Object.keys(list_object[key][account][item]).forEach(value => {
							list_total[account][item][value] = list_total[account][item][value] || 0
							list_total[account][item][value] += list_object[key][account][item][value]
						})
					})
				}

			})
		})
		PinnedData.push(list_total)
		list_data.push(opening_object);
		let prev_key = null
		Object.keys(list_object)
			.sort((a, b) => {
				if (['month', 'week'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
					var _a = a.split('_')
					var _b = b.split('_')
					_a[1] = parseInt(_a[1])
					_a[0] = parseInt(_a[0])
					_b[1] = parseInt(_b[1])
					_b[0] = parseInt(_b[0])
					if ((_a[1] < _b[1]) || (_a[1] === _b[1] && _a[0] < _b[0])) {
						return -1;
					}
					if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {
						return 1;
					}
				} else if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
					var dateA = new Date(a), dateB = new Date(b)
					return dateA - dateB
				} else {
					if (a < b) { return -1; }
					if (a > b) { return 1; }
				}
				return 0;
			})
			.forEach((key) => {
				let new_key = ''
				if (prev_key) {
					if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						let date = new Date(prev_key)
						while (date < new Date(key)) {
							date = new Date(date.setDate(date.getDate() + 1))
							list_data.push({
								group_on: moment(date).format('YYYY-MM-DD'),
								group_range: moment(date).format('YYYY-MM-DD'),
							})
						}
					}
					if (['week', 'month'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						let max_limit = 12 // month
						if (SavedReportStore.previewReportValues.audit_group_on === 'week') {
							max_limit = SavedReportStore.max_week_count; // week
						}
						new_key = prev_key
						while (new_key !== key && key !== 'Total') {
							var _key = new_key.split('_')
							_key[1] = parseInt(_key[1])
							_key[0] = parseInt(_key[0])
							var _temp = _key[0] + 1

							if (_key[0] >= max_limit) {
								new_key = (1) + '_' + (_key[1] + 1)
								display_key = '01-' + (_key[1] + 1)
							} else {
								if (_temp < 10) { _temp = '0' + _temp }
								new_key = (_key[0] + 1) + '_' + _key[1]
								display_key = _temp + '-' + _key[1]
							}
							if (new_key !== key) {
								list_data.push({
									group_on: display_key,
									group_range: display_key,
								})
							}
						}
					}
					if (['year'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
						new_key = parseInt(prev_key)
						while (new_key !== parseInt(key) && key !== 'Total') {
							new_key = new_key + 1
							if (new_key !== parseInt(key)) {
								list_data.push({
									group_on: new_key,
									group_range: new_key,
								})
							}
						}
					}

				}
				list_data.push(list_object[key])
				prev_key = key
			})
	}

	SavedReportStore.auditExportData = props.rowData
	SavedReportStore.auditExportFormatedData = list_data
	SavedReportStore.auditExportFormatedPinnedData = PinnedData
	SavedReportStore.auditExportFYear = FYyear

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		}
	}

	const processCellForClipboard = useCallback((params) => {
		if (typeof params.value === 'string') {
			return params.value
		} else if (typeof params.value === 'object') {
			if (params.value.main) {
				return params.value.main
			}
			// else {
			// 	return params.value.errors
			// }
		}
	}, []);

	// useEffect(() => {
	// 	if (SavedReportStore.previewReportValues) {
	// 		SavedReportStore.getColumnSetting(SavedReportStore.previewReportValues.id)
	// 	}
	// }, [SavedReportStore])

	return (
		<div className="ag-theme-alpine cs_grid grid_wrapper">
			<AgGridReact
				rowHeight={30}
				rowData={list_data}
				modules={AllModules}
				columnDefs={columnDefs}
				pinnedBottomRowData={PinnedData}
				onGridReady={props.onGridReady}
				onBodyScroll={props.onGridReady}
				defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false, autoHeight: false }}
				suppressCopySingleCellRanges={false}
				suppressClipboardApi={true}
				enableRangeSelection={true}
				processCellForClipboard={processCellForClipboard}
			/>
			<PreviewDetailAuditTable DataType={DataType} onGridReady={props.onGridReady} title={title} AccountDetail={AccountDetail} close={() => setAccountDetail(null)} FYyear={FYyear} />
			<Drawer
				visible={openTransaction}
				onClose={() => {
					setPreviewReport(null)
					SetTransaction(false)
				}}
				title={`${title} - (${modeType})`}
				destroyOnClose={true}
				onCancel={() => {
					setPreviewReport(null)
					SetTransaction(false)
				}}
				width="75%"
			>
				<Spin spinning={!PreviewReport ? true : false} size="large">
					<PreviewCashReportTableElement onGridReady={SavedReportStore.setupGridList} form={props.form} rowData={PreviewReport} modeType={modeType} />
				</Spin>
			</Drawer>
		</div>
	)
})

export default PreviewAuditReportTableElement
