import React, { useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import useStore from '../../../../store'
import { observer } from 'mobx-react'
import NumberFormat from 'react-number-format'
import moment from 'moment'

const styles = {
	cells: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		height: '50%'
	}
}

const ListComponent = observer(() => {
	const ref = useRef()
	const { FxGainLossChildStore: { list_data, onGridReady } } = useStore()
	const columnDefsd = [{
		headerName: 'Week',
		headerTooltip: "Week",
		headerComponentParams: {
			template:
				'<span class="ag-cell-label-container">' +
				`<span style=width:100%;position:absolute;top:-22px;left:17px;>Week</span>
				<span ref="eText" class="ag-header-cell-text" style=width:100%;display:none; role="columnheader"></span>` +
				'</span>'
		},
		suppressMenu: true,
		field: 'group_on',
		valueGetter: (params) => {
			if (!['Total'].includes(params.data.group_on)) {
				if (params.data.group_on.length < 10) {
					return params.data.group_on.split('W').join('W0')
				} else {
					return params.data.group_on
				}
			}
		},
		tooltipValueGetter: (params) => {
			if (!['Total'].includes(params.data.group_on)) {
				if (params.data.group_on.length < 10) {
					return params.data.group_on.split('W').join('W0')
				} else {
					return params.data.group_on
				}
			}
		},
		floatingFilter: false,
		filter: false,
		sortable: false,
		pinned: 'left',
		lockPosition: 'left',
		width: 85
	}]

	let listdata = []
	let list_object = {}
	let PinnedData = []

	Object.values(list_data).forEach((bank) => {
		let colDef = {
			headerName: bank.nominal_ledger_code,
			children: [],
			headerTooltip: bank.nominal_ledger_code,
			filter: false,
			sortable: false
		}
		colDef.children.push({
			headerName: bank.account_number + " - " + bank.nominal_ledger_code,
			headerTooltip: bank.account_number,
			headerClass: "fx-gain-loss",
			filter: false,
			width: 95,
			sortable: false,
			suppressMenu: true,
			cellRendererFramework: (params) => (
				(params.data) ? (
					<div style={styles.cells}>
						<NumberFormat value={params.data?.[bank.account_number]?.fx_gain_loss ? params.data?.[bank.account_number]?.fx_gain_loss : '0.00'} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />

					</div>
				) : <div style={styles.cells}>0.00</div>
			),
			valueGetter: (params) => (
				(params.data) ? (
					<div style={styles.cells}>
						<NumberFormat value={params.data?.[bank.account_number]?.fx_gain_loss ? params.data?.[bank.account_number]?.fx_gain_loss : '0.00'} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				) : <div style={styles.cells}>0.00</div>
			),
			tooltipValueGetter: (params) => {
				return (params.data) ? (params.data?.[bank.account_number]?.fx_gain_loss) ? params.data?.[bank.account_number]?.fx_gain_loss : '0.00' : 0.00
			}

		})
		Object.keys(bank.weekno).forEach((group) => {
			if (Object.keys(list_object).length && Object.keys(list_object).includes(group)) {
				let cloneObj = { ...list_object[group] }
				cloneObj[bank.account_number] = {
					fx_gain_loss: bank.weekno[group].fx_gain_loss || 0
				}
				list_object[group] = cloneObj
			} else {
				list_object[group] = {
					group_on: group,
					[bank.account_number]: {
						fx_gain_loss: bank.weekno[group].fx_gain_loss || 0,
					}
				}
			}
		})
		columnDefsd.push(colDef)
	})


	if (Object.keys(list_data).length) {
		Object.keys(list_object).sort((a, b) => {
			var _a = a.split(' - W')
			var _b = b.split(' - W')
			_a[1] = parseInt(_a[1])
			_a[0] = parseInt(_a[0])
			_b[1] = parseInt(_b[1])
			_b[0] = parseInt(_b[0])
			if ((_a[1] < _b[1]) || (_a[1] === _b[1] && _a[0] < _b[0])) {

				return 1;

			}
			if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {

				return -1;
			}
			return 0;
		}).forEach((key) => {
			listdata.push(list_object[key])
		})
	}

	if (listdata && listdata.length) {
		let list_total = {
			group_on: 'Total'
		}
		listdata.forEach((item) => {
			list_total[item.group_on] = {
				fx_gain_loss: 0
			}
			Object.keys(item).forEach((hello_key) => {
				if (!['group_on'].includes(hello_key)) {
					list_total[item.group_on].fx_gain_loss = list_total[item.group_on].fx_gain_loss || 0
					list_total[item.group_on].fx_gain_loss += item[hello_key].fx_gain_loss
				}
			})
		})
		let difference_value = {
			group_on: 'Difference Value'
		}
		if (Object.keys(list_total) && Object.keys(list_total).length > 1) {
			Object.keys(list_total).forEach((item, index) => {
				difference_value[item] = {
					fx_gain_loss: 0
				}
				difference_value[item].fx_gain_loss = list_total[item].fx_gain_loss - (list_total[Object.keys(list_total)[(index + 1)]]?.fx_gain_loss ? Number(list_total[Object.keys(list_total)[(index + 1)]]?.fx_gain_loss).toFixed(2) : 0.00)
				difference_value[item].fx_gain_loss = Number(difference_value[item].fx_gain_loss).toFixed(2) === '-0.00' ? '0.00' : difference_value[item].fx_gain_loss
			})
		}
		PinnedData.push(list_total)
		PinnedData.push(difference_value)
	}

	columnDefsd.push({
		headerName: 'Total',
		headerComponentParams: {
			template:
				'<span class="ag-cell-label-container">' +
				`<span style=width:100%;position:absolute;top:-22px;left:17px;>Total</span>
				<span ref="eText" class="ag-header-cell-text" style=width:100%;display:none; role="columnheader"></span>` +
				'</span>'
		},
		headerTooltip: 'Total',
		filter: false,
		sortable: false,
		suppressMenu: true,
		pinned: 'right',
		lockPosition: 'right',
		width: 100,
		cellRendererFramework: (params) => (
			(params.data) ? (
				<div style={styles.cells}>
					{Object.keys(PinnedData[0]).map((total_key) => {

						return (
							total_key === params.data.group_on &&
							<NumberFormat value={PinnedData[0]?.[total_key]?.fx_gain_loss ? PinnedData[0]?.[total_key]?.fx_gain_loss : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						)
					})}
				</div>
			) : <div style={styles.cells}>0.00</div>
		),
		valueGetter: (params) => (
			(params.data) ? (
				<div style={styles.cells}>
					{Object.keys(PinnedData[0]).map((total_key) => {
						return (
							total_key === params.data.group_on && <NumberFormat value={PinnedData[0]?.[total_key]?.fx_gain_loss ? PinnedData[0]?.[total_key]?.fx_gain_loss : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						)

					})}
				</div>
			) : <div style={styles.cells}>0.00</div>
		),

		tooltipValueGetter: (params) => {
			return params.data ? PinnedData[0][params.data.group_on].fx_gain_loss : '0.00'

		}
	}, {
		headerName: 'Diff. Value',
		headerTooltip: "Diff. Value",
		headerComponentParams: {
			template:
				'<span class="ag-cell-label-container">' +
				`<span style=width:100%;position:absolute;top:-22px;left:17px;>Diff. Value</span>
				<span ref="eText" class="ag-header-cell-text" style=width:100%;display:none; role="columnheader"></span>` +
				'</span>'
		},
		filter: false,
		sortable: false,
		suppressMenu: true,
		lockPosition: 'right',
		pinned: 'right',
		width: 100,
		autoHeight: true,
		cellRendererFramework: (params) => (
			(params.data) ? (
				<div style={styles.cells}>
					{Object.keys(PinnedData[1]).map((total_key) => {
						return (
							total_key === params.data.group_on &&
							<NumberFormat value={PinnedData[1]?.[total_key]?.fx_gain_loss ? PinnedData[1]?.[total_key]?.fx_gain_loss : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						)
					})}
				</div>
			) : <div style={styles.cells}>0.00</div>
		),
		valueGetter: (params) => (
			(params.data) ? (
				<div style={styles.cells}>
					{Object.keys(PinnedData[1]).map((total_key) => {
						return (
							total_key === params.data.group_on && <NumberFormat value={PinnedData[1]?.[total_key]?.fx_gain_loss ? PinnedData[1]?.[total_key]?.fx_gain_loss : 0.00} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						)
					})}
				</div>
			) : <div style={styles.cells}>0.00</div>
		),
		tooltipValueGetter: (params) => {
			return params.data ? PinnedData[1][params.data.group_on].fx_gain_loss : '0.00'

		}
	})

	const processCellForClipboard = (params) => {
		if (!['Total', 'Week', 'Diff. Value'].includes(params.column.colDef.headerName)) {
			return params.value && params.value.props && params.value.props.children && params.value.props.children.props.value
		} else if (params.column && params.column.colDef.headerName === 'Week') {
			return params.value.split(' - ').join('-')
		} else {
			let datav
			params.value && params.value.props && params.value.props.children.forEach((data) => {
				if (typeof data != 'boolean') {
					return datav = data
				}
			})
			return datav.props.value
		}
	}
	const date = moment(new Date()).format('DD-MM-YYYY h:mm:ssa')
	const getContextMenuItems = (data) => {
		var result = [
			'copy',
			'copyWithHeaders',
			'paste',
			'separator',
			{
				name: 'Export',
				icon: `<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>`,
				subMenu: [
					{
						name: 'CSV Export',
						action: (datav) => {
							data.api && data.api.exportDataAsCsv({
								fileName: `Fx Gain-Loss Report ${date}`,
								processCellCallback: function (cell) {
									if (typeof cell.value === 'string') {
										return cell.value
									} else if (cell.column.colDef.headerName === 'Total') {

										return (
											PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									} else if (cell.column.colDef.headerName === 'Diff. Value') {
										return (
											PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									}
									else {
										return cell.value.props?.children?.props?.value
									}
								},
							})
						},
					},
					{
						name: 'Excel Export (.xlsx)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								fileName: `Fx Gain-Loss Report ${date}`,
								processCellCallback: function (cell) {
									if (typeof cell.value === 'string') {
										return cell.value
									} else if (cell.column.colDef.headerName === 'Total') {
										return (
											PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									} else if (cell.column.colDef.headerName === 'Diff. Value') {
										return (
											PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									}
									else {
										return cell.value.props?.children?.props?.value
									}
								},
							})
						},
					},
					{
						name: 'Excel Export (.xml)',
						action: () => {
							data.api && data.api.exportDataAsExcel({
								exportMode: 'xml',
								suppressTextAsCDATA: true,
								fileName: `Fx Gain-Loss Report ${date}`,
								processCellCallback: function (cell) {
									if (typeof cell.value === 'string') {
										return cell.value
									} else if (cell.column.colDef.headerName === 'Total') {
										return (
											PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[0]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									} else if (cell.column.colDef.headerName === 'Diff. Value') {
										return (
											PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss ? PinnedData[1]?.[cell.node.data.group_on]?.fx_gain_loss : 0.00
										)
									}
									else {
										return cell.value.props?.children?.props?.value
									}
								},
							})
						},
					}
				],
			}
		]
		return result
	}

	return (
		<div className="ag-theme-alpine grid_wrapper  no_data_height fx_gain_loss_data_header" style={{ height: 'calc(100vh - 100px)', width: 'auto' }} >
			<AgGridReact
				ref={ref}
				rowData={listdata}
				columnDefs={columnDefsd}
				modules={AllModules}
				defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
				columnTypes={LocalGridConfig.columnTypes}
				gridOptions={{ ...LocalGridConfig.options, floatingFiltersHeight: 0, pagination: false }}
				tooltipShowDelay={500}
				pinnedRightColumn={PinnedData}
				enableRangeSelection={true}
				onGridReady={onGridReady}
				getContextMenuItems={getContextMenuItems}
				processCellForClipboard={processCellForClipboard}

			/>
		</div>)
})

export default ListComponent
