import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Button, Form } from 'antd'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmNotify, vsmPeople } from '../../../../config/messages'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { peoplePhotoURL } from '../../../../utils/GlobalFunction'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleStore } = useStore()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [fileList, updateFileList] = useState([]);
	const [addAddress, setAddAddress] = useState(false)
	const [imgchanged, setImgchanged] = useState(false)

	const close = () => {
		setAddAddress(false)
		updateFileList([])
		PeopleStore.peopleValues = null
		form.resetFields()
		setSaving(false)
		setDisabled(true)
		setImgchanged(false)
		props.close()
	}

	const handleSubmit = (data) => {
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : null
		}

		if (addAddress) {
			if (data.address && data.address.length > 0) {
				data.address = data.address.filter(x => x && x)
				data.address = data.address.length > 0 ? JSON.stringify(data.address) : null
			}
		} else {
			data.address = null
		}

		if (data.email && data.email.length > 0) {
			data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
			data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
		}

		setSaving(true)
		var formData = PeopleStore.JsonToFormData(data)

		formData.append("add_address", addAddress === true ? 1 : 0)

		if (fileList.length > 0 && fileList[0] && imgchanged) {
			formData.append('photo_url', fileList[0].hasOwnProperty("originFileObj") ? fileList[0].originFileObj : fileList[0])
		} else if (fileList.length === 0) {
			formData.append('photo_status', 'deleted')
		}

		formData.append("date_of_birth", data.date_of_birth ? moment(data.date_of_birth).format("YYYY-MM-DD") : '')
		PeopleStore.EditData(formData, PeopleStore.peopleValues.id).then(() => {
			close()
			vsmNotify.success({
				message: vsmPeople.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = debounce(() => {
		form.validateFields().then((data) => {
			let checkbox = form.getFieldValue("add_address")
			let address = form.getFieldValue("address")
			address = address && address.filter(x => x && x)
			if (checkbox && (!address || (address && address.length === 0))) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}, 200)

	useEffect(() => {
		if (PeopleStore.peopleValues) {
			updateFileList(PeopleStore.peopleValues.photo_url ? ([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: peoplePhotoURL + PeopleStore.peopleValues.photo_url,
				},
			]) : [])
			if (PeopleStore.peopleValues.address && PeopleStore.peopleValues.address.length > 0) {
				setAddAddress(true)
			} else {
				setAddAddress(false)
			}
			form.setFieldsValue({
				salutation: PeopleStore.peopleValues.salutation ? PeopleStore.peopleValues.salutation : null,
				first_name: PeopleStore.peopleValues.first_name,
				last_name: PeopleStore.peopleValues.last_name,
				title: PeopleStore.peopleValues.title,
				date_of_birth: PeopleStore.peopleValues.date_of_birth ? moment(PeopleStore.peopleValues.date_of_birth, 'YYYY-MM-DD') : null,
				phone: PeopleStore.peopleValues.phone.length > 0 ? PeopleStore.peopleValues.phone : PeopleStore.initialValues.phone,
				email: PeopleStore.peopleValues.email.length > 0 ? PeopleStore.peopleValues.email : PeopleStore.initialValues.email,
				pss_number: PeopleStore.peopleValues.pss_number,
				address: PeopleStore.peopleValues.address,
				former_last_name: PeopleStore.peopleValues.former_last_name,
				notes: PeopleStore.peopleValues.notes,
				ppsn_document_type: PeopleStore.peopleValues.ppsn_document_type,
				pps_number: PeopleStore.peopleValues.pps_number,
				pronounced: PeopleStore.peopleValues.pronounced,
				add_address: PeopleStore.peopleValues.address && PeopleStore.peopleValues.address.length > 0 ? true : false
			})
		}
	}, [PeopleStore.peopleValues, form, PeopleStore.initialValues])

	return PeopleStore.peopleValues && (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit People #${PeopleStore.peopleValues.id} - ${PeopleStore.peopleValues.first_name} ${PeopleStore.peopleValues.last_name ? PeopleStore.peopleValues.last_name : ""}`}
			destroyOnClose={true}
			footer={[
				<div className="text-center">
					<Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
					<Button onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				onChange={handleChange}
				handleSubmit={handleSubmit}
				updateFileList={updateFileList}
				fileList={fileList}
				setAddAddress={setAddAddress}
				addAddress={addAddress}
				setImgchanged={setImgchanged}
			/>
		</Drawer>
	)

})

export default EditComponent
