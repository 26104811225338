import React, { useEffect } from 'react'
import { Table, Button, Tooltip } from 'antd'
import { observer } from 'mobx-react';
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons';
import useStore from '../../../../../store'

const FinancialYearSetting = observer((props) => {
	const { LedgerDetailStore: { list_data, getList }, UserStore } = useStore()

	useEffect(() => {
		getList()
	}, [getList])

	const ArchiveHandle = (prop) => {
		return (
			<div className="action-column">
				{(!UserStore.checkPermission(69, 'archive')) ? null : (
					(prop.data.data_status === false) ? (
						<Tooltip placement="topRight" title={"Restore Financial Year"}>
							<Button type="text" size="small" style={{ padding: '0 8 ' }} onClick={() => { props.openRestoreModal(prop.data) }}><DownSquareOutlined /></Button>
						</Tooltip>
					) :
						(<Tooltip placement="topRight" title={"Archive Financial Year"}>
							<Button type="text" disabled={prop.data.current_year === true} size="small" style={{ padding: '0 8' }} onClick={() => { props.openArchiveModal(prop.data) }}><UpSquareOutlined /></Button></Tooltip>)
				)}
			</div>
		)
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Financial Year',
			dataIndex: 'year',
		},
		{
			title: 'Action',
			key: 'action',
			render: (data) => <ArchiveHandle data={data} />,
		},
	];

	return (
		<div >
			<h3 className="title-size mt-10 mb-10">Data Archival by Financial Year</h3>
			<Table
				size="small"
				pagination={false}
				bordered
				dataSource={list_data}
				columns={columns}
				rowKey="index"
			/>
		</div>
	)
})


export default FinancialYearSetting
