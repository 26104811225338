import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { EyeFilled, DisconnectOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { PlusOutlined, EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import GridConfig from '../../../../config/GridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import { CurrencyFormat } from '../../../../utils/GlobalFunction'
import moment from 'moment'

const ListComponent = observer((props) => {
	const { openReconcileModal, openViewDrawer, openUnlinkModal, openEditDrawer, openDeleteDrawer, openTransViewDrawer } = props
	const { ReconciliationStore, UserStore, ReconciliationStore: { setupGrid, list_data, cashTotal, tradeTotal }, } = useStore()

	const ActionRenderer = (prop) => {

		return (
			<div className="action-column">
				{UserStore.checkPermission(77, 'reconciliation_mode') && <Button title="Reconcile" type="text" onClick={() => {
					ReconciliationStore.selectedIds = [prop.data.id]
					openReconcileModal(prop.data)
				}}><PlusOutlined /></Button>}
				{UserStore.checkPermission(76, 'view') && (<Button title="View Cash Transaction" type="text" onClick={() => { openViewDrawer(prop.data.id) }}><EyeOutlined /></Button>)}
				{UserStore.checkPermission(76, 'edit') && (<Button title="Edit Cash Transaction" type="text" onClick={() => { openEditDrawer(prop.data.id) }}><EditOutlined /></Button>)}
				{UserStore.checkPermission(76, 'delete') && (<Button title="Delete Cash Transaction" type="text" onClick={() => { openDeleteDrawer(prop.data) }}><DeleteOutlined /></Button>)}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			checkboxSelection: true,
			filter: false,
			sortable: false,
			floatingFilter: false,
			width: 55,
			suppressMenu: true,
			pinned: 'left',
		}, {
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			cellRenderer: "agGroupCellRenderer",
			width: 110
		}, {
			headerName: 'Date',
			field: 'posted_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 185
		}, {
			headerName: 'Bank Name',
			field: 'ledgers_bank.name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getBankFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.name)]);
					})
				}
			},
			width: 150
		}, {
			headerName: 'Account Number / IBAN',
			field: 'ledgers_bank.account_number',
			width: 200
		}, {
			headerName: 'Transaction Details',
			field: 'transaction_details',
			tooltipField: 'transaction_details',
			width: 220
		}, {
			headerName: 'Transaction Type',
			field: 'cash_transactions_types.type_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getTransactionTypesFilter().then((data) => {
						params.success([null, ...data.data.data.map(x => x.type_name)])
					})
				}
			},
			width: 150
		}, {
			headerName: 'Debit (+)',
			field: 'debit',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
			},
			width: 110
		}, {
			headerName: 'Credit (-)',
			field: 'credit',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: (data) => {
				return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
			},
			width: 110
		}, {
			headerName: 'Currency',
			field: 'global_currency.currency_code',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getCurrencyFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.currency_code)])
					})
				}
			},
			width: 130
		}, {
			headerName: 'Identified Category',
			field: 'cte_transaction_classifications.transaction_class_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getCatagoryFilter().then((data) => {
						params.success([null, ...data.data.data.map(x => x.transaction_class_name)])
					})
				}
			},
			width: 150
		}, {
			headerName: 'Exchange ID',
			field: 'exchange_ref_id',
			tooltipField: 'exchange_ref_id',
			width: 150
		}, {
			headerName: 'Organisation / People Name',
			field: 'entity_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))) : (params.data.organisations && params.data.organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getOrgPplFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			width: 220
		}, {
			headerName: 'Role',
			field: 'role_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getRoleFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			width: 220
		}, {
			headerName: 'Related Organisation / People Name',
			field: 'related_entity_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getOrgPplFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			width: 220
		}, {
			headerName: 'Related Role',
			field: 'related_role_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					ReconciliationStore.getRoleFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			width: 220
		}, {
			headerName: 'Status',
			field: 'status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Pending(Identified)', 'Partially Reconciled', 'Fully Reconciled']
			},
			width: 185
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 145
		}]
	}

	const detailGridOptions = {
		detailGridOptions: {
			columnDefs: [
				{
					headerName: 'TTE Transaction ID',
					field: 'id',
					filter: 'agNumberColumnFilter',
					pinned: 'left',
					width: 120
				}, {
					headerName: 'Mapping Type',
					field: 'mapping_name',
				}, {
					headerName: 'Transaction Ref',
					field: 'reference',
					tooltipField: 'reference'
				}, {
					headerName: 'Face Value',
					field: 'face_value',
					cellRendererFramework: (data) => data.data ? <CurrencyFormat value={data.data.face_value} /> : null
				}, {
					headerName: 'Debit',
					field: 'debit_value',
					type: 'rightAligned',
					cellRendererFramework: (data) => {
						if (data.data) {
							return <CurrencyFormat value={data.data.debit_value ? data.data.debit_value : 0} />
						}
						else {
							return null
						}
					},
				}, {
					headerName: 'Credit',
					field: 'credit_value',
					type: 'rightAligned',
					cellRendererFramework: (data) => {
						if (data.data) {
							return <CurrencyFormat value={data.data.credit_value ? data.data.credit_value : 0} />
						}
						else {
							return null
						}
					},
				}, {
					headerName: 'Traded On',
					field: 'traded_on',
					filter: 'agDateColumnFilter',
					filterParams: {
						buttons: ['reset'],
						suppressAndOrCondition: true,
					},
				}, {
					headerName: 'Posted On',
					field: 'posted_on',
					filter: 'agDateColumnFilter',
					filterParams: {
						buttons: ['reset'],
						suppressAndOrCondition: true,
					},
				}, {
					headerName: 'Reconciliation On',
					field: 'reconciled_on',
					filter: 'agDateColumnFilter',
					valueGetter: (params) => (params.data && params.data.reconciled_on) ? moment(params.data.reconciled_on).format('DD-MM-YYYY') : "",
					filterParams: {
						buttons: ['reset'],
						suppressAndOrCondition: true,
					},
				}, {
					headerName: 'Exchange ID',
					field: 'exchange_ref_id',
				}, {
					headerName: 'Actions',
					field: 'actions',
					sortable: false,
					filter: false,
					pinned: 'right',
					cellRendererFramework: function (data) {
						const { openUnlinkModal, openTransViewDrawer } = data.agGridReact.props
						return (<div className="action-column">
							<Button title="Unlink Transaction" type="text" onClick={() => openUnlinkModal(data.data)}><DisconnectOutlined /></Button>
							<Button title="View Trade Transaction" type="text" onClick={() => openTransViewDrawer(data.data)}><EyeFilled /></Button>
						</div>)
					},
				},
			],
			defaultColDef: {
				sortable: true,
				resizable: true,
			},
		},
		getDetailRowData: function (params) {
			params.successCallback(params.data.child_transactions_journal_logs);
		},
	}

	let columns = []
	let columnConfig = localStorage.getItem('reconciliation_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper pb-50">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openReconcileModal, openViewDrawer, openEditDrawer, openDeleteDrawer
				}}
				onGridReady={setupGrid}
				onSwitchChange={ReconciliationStore.onSwitchChange}
				onColumnResized={ReconciliationStore.onGridChanged}
				onColumnMoved={ReconciliationStore.onGridChanged}
				onColumnPinned={ReconciliationStore.onGridChanged}
				gridOptions={GridConfig.options}
				openUnlinkModal={openUnlinkModal}
				openTransViewDrawer={openTransViewDrawer}
				masterDetail={true}
				suppressRowClickSelection={true}
				detailCellRendererParams={detailGridOptions}
				rowSelection={'multiple'}
			/>
			<Row gutter={24}>
				<Col span={12}>
					<div className="mt-10">
						{ReconciliationStore.reconciliation_bottom_text && ReconciliationStore.reconciliation_bottom_text.map((text) =>
							<div>{text}</div>
						)}
					</div>
				</Col>
				<Col span={12}>
					<div className="text-right mt-10">
						<p>Total Cash Amount on this Page : <CurrencyFormat value={cashTotal ? cashTotal : 0} /></p>
						<p>Total Trade Amount on this Page : <CurrencyFormat value={tradeTotal ? tradeTotal : 0} /></p>
					</div></Col>
			</Row>
		</div>
	)
})

export default ListComponent
