import Axios from 'axios'
import { action, decorate, observable } from 'mobx'
import moment from 'moment'

export default class fxGainLossChildStore {
    list_data = null
    agGrid = null
    fYear = []
    bankAccList = null
    agGrid = null

    constructor() {
        let currentYear = moment().year()
        let dummyObj = { text: `Jan ${currentYear} - Dec ${currentYear}`, value: `Jan-${currentYear}:Dec-${currentYear}` }
        this.fYear.push(dummyObj)
    }

    //when the grid is ready then onGridReady called
    onGridReady = (params) => {
        this.agGrid = params
       
    }



    getBankAccList = (payload = {}) => {
        return Axios.post('/cte/transaction-classification/get/fx-gain-loss-bank-accounts', payload).then(({ data }) => {
            this.bankAccList = data.data.banks_accounts && data.data.banks_accounts
            return data.data.banks_accounts
        })
    }

    getFYear = () => {
        return Axios.get('report/financial/year').then(({ data }) => {
            this.fYear = data.data
            return data.data
        })
    }


    getList = (payload) => {
        return Axios.post('report/fx-gross-loss', payload).then(({ data }) => {
            this.list_data = data.data
            return data.data
        })
    }
}

decorate(fxGainLossChildStore, {
    list_data: observable,
    agGrid: observable,
    fYear: observable,
    bankAccList: observable,
    getList: action,
    getFYear: action,
    getBankAccList: action,
    onGridReady: action,
})