import React from 'react'
import { Row, Col, PageHeader } from 'antd'
import JournalWidget from '../widgets/JournalWidget'
import TradeWidget from '../widgets/TradeWidget'
import TransactionsWidget from '../widgets/TransactionsWidget'
import ActiveUserWidget from '../widgets/ActiveUsersWidget'
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
	const { t } = useTranslation();

	return (
		<PageHeader
			title={t('DashBoard.Dashboard')}
		>
			<Row gutter={15}>
				<Col xs={24} md={12} xl={6}>
					<JournalWidget />
				</Col>
				<Col xs={24} md={12} xl={6}>
					<TradeWidget />
				</Col>
				<Col xs={24} md={12} xl={6}>
					<TransactionsWidget />
				</Col>
				<Col xs={24} md={12} xl={6}>
					<ActiveUserWidget />
				</Col>
			</Row>
		</PageHeader>
	)
}

export default Dashboard
