import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Card, Button } from 'antd'
import { CreditCardFilled } from '@ant-design/icons'
import useStore from '../store'

const TransactionsWidget = observer(() => {
	const { DashboardStore: { getTransactionCount, count_transactionmodels, setDaysTransactionCount, days_transactionmodels } } = useStore()

	// get count of transactions
	useEffect(() => {
		getTransactionCount()
	}, [getTransactionCount])

	return (
		<Card className="widget_wrapper">
			<div className="counter__widget">
				<div className="counter__widget__icon"><CreditCardFilled /></div>
				<div className="counter__widget__description">
					<div className="counter__widget__name">TRANSACTIONS</div>
					<div className="counter__widget__counts">$ {count_transactionmodels}</div>
				</div>
			</div>
			<div className="counter__widget__footer">
				<div className="counter__widget__buttons">
					<Button onClick={() => setDaysTransactionCount(7)} type="link" size="small" className={"counter__widget__button " + (days_transactionmodels === 7 ? "cs_active" : "")}>7 Days</Button>
					<Button onClick={() => setDaysTransactionCount(30)} type="link" size="small" className={"counter__widget__button " + (days_transactionmodels === 30 ? "cs_active" : "")}>30 Days</Button>
					<Button onClick={() => setDaysTransactionCount(365)} type="link" size="small" className={"counter__widget__button " + (days_transactionmodels === 365 ? "cs_active" : "")}>12 Months</Button>
				</div>
			</div>
		</Card>
	)
})

export default TransactionsWidget
