import { Button, Drawer, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import useStore from '../../../../store';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { CurrencyFormat } from '../../../../utils/GlobalFunction';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon, vsmNotify } from '../../../../config/messages';
import AddComponent from '../../CashTransactions/components/AddComponent';
import { EditOutlined } from '@ant-design/icons'
import EditComponent from '../../CashTransactions/components/EditComponent';

const SubDaysComponent = observer((props) => {
	const { UserStore, QueryBankVsCashStore, CashTransactionStore } = useStore()
	const [listData, setListData] = useState(null)
	const [pinnedBottomRow, setPinnedBottomRow] = useState(null)
	const [openAddCashTransaction, setOpenAddCashTransaction] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [openEditCashTransaction, setOpenEditCashTransaction] = useState(false)
	const [defaultCteData, setDefaultCteData] = useState(null)
	const [spin, setSpin] = useState(false)

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		},
		red_bg: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%',
			backgroundColor: '#e52e2e',
			margin: '0 -15px',
			padding: '0 15px'
		}
	}

	// set id for edit / view drawer
	const setId = (id) => setTransactionId(id)

	const openEditModal = (data) => {
		setId(data.id)
		setOpenEditCashTransaction(true)
	}

	const closeEditModal = () => {
		setSpin(true)
		QueryBankVsCashStore.singleGetChildList(null)
		setOpenEditCashTransaction(false)
	}

	const ActionRenderer = (prop) => {
		return prop.data && (
			<div className="action-column">
				{UserStore.checkPermission(76, 'edit') && (<Button title="Edit Cash Transaction" type="text" onClick={() => { openEditModal(prop.data) }}><EditOutlined /></Button>)}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'Date',
			field: 'posted_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 90
		}, {
			headerName: 'Value Date',
			field: 'value_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 90
		}, {
			headerName: 'Bank',
			field: 'ledgers_bank.name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getBankFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.name)]);
					})
				}
			},
			hide: true,
			width: 100
		}, {
			headerName: 'Bank Account / IBAN',
			field: 'ledgers_bank.account_number',
			valueGetter: (params) => (params.data && params.data.ledgers_bank) ? params.data.ledgers_bank.account_number ? params.data.ledgers_bank.account_number : params.data.ledgers_bank.iban : '',
			width: 90,
		}, {
			headerName: 'Transaction Details',
			field: 'transaction_details',
			tooltipField: 'transaction_details',
			width: 175
		}, {
			headerName: 'Transaction Type',
			field: 'cash_transactions_types.type_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getTransactionTypesFilter().then((data) => {
						params.success([null, ...data.data.data.map(x => x.type_name)])
					})
				}
			},
			cellRendererFramework: function (data) {
				return data.data && data?.data?.transaction_details === 'Cash Closing:' ?
					<div style={parseInt(data.data.cash_transactions_types.type_name) !== parseInt(data.data.balance) ? styles.red_bg : styles.cells}>
						<CurrencyFormat value={data.data.cash_transactions_types?.type_name ? data.data.cash_transactions_types?.type_name : 0} />
					</div> : data.data.cash_transactions_types.type_name
			},
			width: 110
		}, {
			headerName: 'Debit (+)',
			field: 'debit',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.debit ? data.data.debit : 0} />
			},
			width: 107
		}, {
			headerName: 'Credit (-)',
			field: 'credit',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.credit ? data.data.credit : 0} />
			},
			width: 107
		}, {
			headerName: 'Ledger Balance',
			field: 'balance',
			type: 'rightAligned',
			filter: 'agNumberColumnFilter',
			cellRendererFramework: function (data) {
				return data.data && <CurrencyFormat value={data.data.balance ? data.data.balance : 0} />
			},
			width: 110
		}, {
			headerName: 'Customer Reference',
			field: 'customer_reference',
			width: 150
		}, {
			headerName: 'Bank Reference',
			field: 'bank_reference',
			width: 110
		}, {
			headerName: 'Currency',
			field: 'global_currency.currency_code',
			filter: 'agSetColumnFilter',
			valueGetter: (params) => (params.data && params.data.global_currency) ? params.data.global_currency.currency_code : "",
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getCurrencyFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.currency_code)])
					})
				}
			},
			hide: true,
			width: 75
		}, {
			headerName: 'Identified Category',
			field: 'cte_transaction_classifications.transaction_class_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getCatagoryFilter().then((data) => {
						params.success([null, ...data.data.data.map(x => x.transaction_class_name)])
					})
				}
			},
			hide: true,
			width: 100
		}, {
			headerName: 'Exchange ID',
			field: 'exchange_ref_id',
			tooltipField: 'exchange_ref_id',
			hide: true,
			width: 100
		}, {
			headerName: 'Organisation / People Name',
			field: 'entity_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))) : (params.data.organisations && params.data.organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getOrgPplFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			hide: true,
			width: 180
		}, {
			headerName: 'Cheque Number',
			field: 'cheque_number',
			filter: false,
			valueGetter: (params) => params.data && params.data.transaction_cheque_number_value && params.data.transaction_cheque_number_value.cheque_number ? params.data.transaction_cheque_number_value.field_value : null,
			width: 100
		}, {
			headerName: 'Role',
			field: 'role_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getRoleFilter({ name: "cash", type: 1 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			hide: true,
			width: 120
		}, {
			headerName: 'Related Organisation / People Name',
			field: 'related_entity_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getOrgPplFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_name)])
					})
				}
			},
			hide: true,
			width: 180
		}, {
			headerName: 'Related Role',
			field: 'related_role_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					CashTransactionStore.getRoleFilter({ name: "cash", type: 2 }).then((data) => {
						params.success([null, ...data.data.data.map(x => x.entity_role)])
					})
				}
			},
			hide: true,
			width: 120
		}, {
			headerName: 'Cleared Status',
			field: 'cleared_status',
			valueGetter: (params) => params.data && params.data.transaction_cleared_status_value && params.data.transaction_cleared_status_value.cleared_status ? params.data.transaction_cleared_status_value.field_value : null,
			filter: false,
			width: 100
		}, {
			headerName: 'Reconciliation Status',
			field: 'reconciliation_status_table',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Pending(Identified)', 'Partially Reconciled', 'Fully Reconciled']
			},
			hide: true,
			width: 144
		}, {
			headerName: 'Status',
			field: 'status_name',
			tooltipField: 'status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Pending', 'Imported / added', 'Auto Identified', 'Manually Verified', 'Problematic / Unidentified', 'Problematic / Identified']
			},
			hide: true,
			width: 144
		}, {
			headerName: 'Manual Notes',
			field: 'note',
			tooltipField: 'note',
			editable: true
		}, {
			headerName: 'Action',
			field: 'acrion',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 65
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('cte_transaction_grid_query_bank')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	const onCellEditingStopped = (params) => {
		let payload = {
			id: params.data.id,
			note: params.newValue
		}
		QueryBankVsCashStore.updateCashTransation(payload).then((data) => {
			vsmNotify.success({ message: 'Manual note updated successfully.' })
		})
	}

	const addCashTransation = () => {
		let payload = {
			bankName: `${props.title.split(' - ')[0].replace('View ', '')} - ${props.title && props.title.split(' - ')[1]}`,
			bankID: props.bankID,
			posted_on: props.title.split(' - ')[2],
			value_on: props.title.split(' - ')[2],
			global_currency: QueryBankVsCashStore.singleDaysListData && QueryBankVsCashStore.singleDaysListData.length && QueryBankVsCashStore.singleDaysListData[0]?.global_currency
		}
		setDefaultCteData(payload)
		setOpenAddCashTransaction(true)
	}

	const closeCashTransation = () => {
		setSpin(true)
		QueryBankVsCashStore.singleGetChildList(null)
		setOpenAddCashTransaction(false)
	}

	useEffect(() => {
		if (QueryBankVsCashStore.singleDaysListData && QueryBankVsCashStore.singleDaysListData.length) {
			let tempObj = QueryBankVsCashStore.singleDaysListData[QueryBankVsCashStore.singleDaysListData.length - 1]
			tempObj.cash_transactions_types = { 'type_name': tempObj.debit }
			tempObj.transaction_details = 'Cash Closing:'
			let sumDebit = 0
			let sumCredit = 0
			QueryBankVsCashStore.singleDaysListData.slice(0, -1).forEach((_x) => {
				if (_x.debit) {
					sumDebit += Number(_x.debit)
				}
				if (_x.credit) {
					sumCredit += Number(_x.credit)
				}
			})
			tempObj.debit = sumDebit
			tempObj.credit = sumCredit
			setPinnedBottomRow([tempObj])
			setListData(QueryBankVsCashStore?.singleDaysListData.slice(0, -1))
			setSpin(false)
		} else {
			setListData(QueryBankVsCashStore?.singleDaysListData)
		}
	}, [QueryBankVsCashStore.singleDaysListData])

	return (
		<>
			<Drawer
				visible={props.visible}
				onClose={props.close}
				title={props.title && props.title}
				placement='right'
				width={'80%'}
				destroyOnClose={true}
				extra={[
					<Button key='2' title="Enter Manual Cash Transaction" onClick={addCashTransation} className='mr-40'>New</Button>
				]}
				footer={[
					<div key="1" className="d-flex justify-content-center">
						<Button className="ml-10" onClick={props.close}>Close</Button>
					</div>
				]}
			>
				<Spin size="large" spinning={spin} tip={"Loading"}>
					<div className="ag-theme-alpine grid_wrapper no_data_height" style={{ height: QueryBankVsCashStore.singleDaysListData ? QueryBankVsCashStore.singleDaysListData.length * 30 + 44 : 80, minHeight: '80px', maxHeight: 'calc(100vh - 156px)' }}>
						<AgGridReact
							rowData={listData}
							modules={AllModules}
							columnDefs={columns}
							defaultColDef={LocalGridConfig.defaultColDef}
							columnTypes={LocalGridConfig.columnTypes}
							overlayNoRowsTemplate={vsmCommon.noRecord}
							onGridReady={QueryBankVsCashStore.singleDaysSetupGrid}
							gridOptions={LocalGridConfig.options}
							frameworkComponents={{
								ActionRenderer
							}}
							rowSelection={'multiple'}
							suppressRowClickSelection={true}
							onCellDoubleClicked={false}
							pinnedBottomRowData={pinnedBottomRow}
							onCellEditingStopped={onCellEditingStopped}
						/>
					</div>
				</Spin>
				{openAddCashTransaction && QueryBankVsCashStore.days_list_data && <AddComponent visible={openAddCashTransaction} close={closeCashTransation} defaultCteData={defaultCteData} />}
				{openEditCashTransaction && <EditComponent transactionId={transactionId} setId={setId} visible={openEditCashTransaction} close={closeEditModal} />}
			</Drawer>
		</>
	)
})

export default SubDaysComponent;