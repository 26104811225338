import React, { useEffect, useState } from 'react'
import { Form, Drawer, Spin, Card, Checkbox, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../../components/InputComponent'
import { vsmOrganisations, vsmTransactionItems } from '../../../../../config/messages'
import useStore from '../../../../../store'

const FormComponent = observer(({ title, visible, close, formButtons, form, form_id, setDisabled, handleSubmit, currentValues, tab }) => {
	const [fetchRate, setFetchRate] = useState(true)
	const [fetchClassification, setFetchClassification] = useState(true)
	const [fetchManufacturer, setFetchManufacturer] = useState(true)
	const [isChecked, setChecked] = useState(false)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const { AllTransactionStore, OrganisationStore, TransactionItemsStore } = useStore()

	// Set the state value
	useEffect(() => {
		if (visible) {
			setFetchRate(true)
			setFetchManufacturer(true)
			setFetchClassification(true)
			setChecked(false)
			setFetchCountry(true)
			setFetchCity(true)
			setFetchState(true)
			if (currentValues && currentValues.add_address) {
				setChecked(true)
			}
		}
	}, [visible, currentValues])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// check whether address checkbox is checked or not
	const addAddress = async (e) => {
		if (!e.target.checked) {
			form.resetFields(["postal_code", "address_1", "address_2", "address_3", "city", "state_county"])
		}
		await setChecked(e.target.checked)
		handleChange()
	}

	const handleValue = () => {
		var quantity = form.getFieldValue('quantity')
		var unit_price = form.getFieldValue('unit_price')
		if (quantity && unit_price) {
			form.setFieldsValue({ value: parseFloat(quantity) * parseFloat(unit_price) })
		} else {
			form.setFieldsValue({ value: parseFloat(0) })
		}
	}

	return (
		<Drawer
			title={title}
			visible={visible}
			onClose={close}
			placement='right'
			width={'75%'}
			footer={formButtons}
			destroyOnClose={true}
		>
			<Form form={form}
				id={form_id}
				layout='vertical'
				onChange={handleChange}
				onFinish={handleSubmit}
				autoComplete="off"
			>
				<Row gutter={24}>
					{tab !== 'view' &&
						<Col span={6}>
							<InputComponent
								required name="batch" label="Batch" placeholder="Batch"
								tooltip="A group of things or people dealt with at the same time or considered similar in type."
								rules={vsmTransactionItems.validation.batch} autoComplete="off"
								maxLength={100}
							/>
						</Col>
					}
					<Col span={6}>
						<InputComponent
							required name="asset_number" label="Asset Number" placeholder="Asset Number"
							tooltip="An asset number uniquely identifies the product. An asset number can be represented by either a license number for software or a service tag for a hardware appliance."
							rules={vsmTransactionItems.validation.assetnumber} autoComplete="off"
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							required name="deposit_received" label="Deposit Received" placeholder="Deposit Received"
							tooltip="A customer deposit could be money that a company receives from a customer prior to the company earning."
							rules={vsmTransactionItems.validation.deposit} autoComplete="off"
							maxLength={100}
						/>
					</Col>

					{tab !== 'view' &&
						<>
							<Col span={6}>
								<InputComponent
									required type="radio_button" label="Asset Type" name="asset_type" initialValue="fixed"
									onChange={handleChange}
									tooltip="Assets type will have two Subgroups defined: Current Assets and Fixed Assets."
									options={{
										values: [
											{ value: 'fixed', text: 'Fixed' },
											{ value: 'current', text: 'Current' },
										]
									}}
								/>
							</Col>
							<Col span={6}>
								<InputComponent
									required name="eot" label="EOT" placeholder="EOT"
									tooltip="Extension of Time (EOT) is a delay which could not be reasonably foreseen at the time of contract signing."
									rules={vsmTransactionItems.validation.eot} autoComplete="off"
								/>
							</Col>
						</>
					}
					<Col span={6}>
						<InputComponent
							required name="item_name" label="Asset Name" placeholder="Asset Name"
							tooltip="An item is one of a collection or list of objects."
							rules={vsmTransactionItems.validation.item} autoComplete="off"
							maxLength={100}
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							required name="quantity" label="Quantity" placeholder="Quantity" onChange={() => { handleValue(); handleChange() }}
							tooltip="The amount or number of something, especially that can be measured."
							rules={vsmTransactionItems.validation.quantity} autoComplete="off"
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							required name="unit_price" label="Unit Price" placeholder="Unit Price" onChange={() => { handleValue(); handleChange() }}
							tooltip="Unit price is the price for a single unit of measure of a product sold in more or less than the single unit."
							rules={vsmTransactionItems.validation.unitprice} autoComplete="off"
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							name="value" label="Value" placeholder="Value"
							tooltip="Value is the worth in goods, services or money of an object or person." disabled={true} autoComplete="off"
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							name="serial_no" label="Serial Number" placeholder="Serial Number"
							tooltip="A serial number is a unique identifier assigned incrementally or sequentially to an item, to uniquely identify it."
							rules={vsmTransactionItems.validation.serialnumber} autoComplete="off"
							maxLength={100}
						/>
					</Col>

					{tab !== 'view' &&
						<Col span={6}>
							<InputComponent
								required name="rm_price" label="Repair & Maintenance Price" placeholder="Repair & Maintenance Price"
								tooltip="The retail margin equals the difference between the price that you pay for an item and the price at which you sell the the item to customers." autoComplete="off"
								rules={vsmTransactionItems.validation.rmprice}
							/>
						</Col>
					}
					<Col span={6}>
						<InputComponent
							name="manufacturer" type="autocomplete" label="Supplier/Manufacturer" placeholder="Supplier/Manufacturer"
							tooltip="A person or company that makes goods for sale."
							rules={vsmTransactionItems.validation.manufacturer} autoComplete="off"
							onFocus={() => fetchManufacturer && TransactionItemsStore.getManufacturer().then(() => setFetchManufacturer(false))}
							notFoundContent={fetchManufacturer ? <Spin size="small" /> : "No Record Found."}
							options={TransactionItemsStore.manufacturer_list}
							maxLength={100}
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							required type="autocomplete" name="classification" label="Asset Classification" placeholder="Asset Classification"
							tooltip="Classification is the list of industry sectors."
							rules={vsmTransactionItems.validation.classification} autoComplete="off"
							onFocus={() => fetchClassification && TransactionItemsStore.getClassification().then(() => setFetchClassification(false))}
							notFoundContent={fetchClassification ? <Spin size="small" /> : "No Record Found."}
							options={TransactionItemsStore.classification_list}
							maxLength={100}
						/>
					</Col>
					{tab !== 'view' &&
						<Col span={6}>
							<InputComponent
								required type="select" name="tax_settings_id" rules={vsmTransactionItems.validation.vatrate}
								label="VAT Rate" placeholder="Select VAT Rate" onChange={handleChange}
								onFocus={() => fetchRate && AllTransactionStore.getVatRateList().then(() => setFetchRate(false))}
								notFoundContent={fetchRate ? <Spin size="small" /> : "No Record Found."} autoComplete="off"
								tooltip="VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
								options={{
									values: AllTransactionStore.vatRate_dropdown,
									value_key: "id",
									text_key: { key: ["tax_name", " - ", "tax_value"] },
								}}
							/>
						</Col>
					}
					<Col span={6}>
						<InputComponent
							name="site_number" label="Site No" placeholder="Site No"
							tooltip="The site number is the unique identifier for your Grid. You will most commonly see the site number used within your Access Domain."
							rules={vsmTransactionItems.validation.sitenumber} autoComplete="off"
							maxLength={100}
						/>
					</Col>
					{tab !== 'view' &&
						<Col span={6}>
							<InputComponent
								required type="select" name="tax_type_inc_exc" rules={vsmTransactionItems.validation.taxtype}
								label="Tax Type" placeholder="Select Tax Type" onChange={handleChange}
								tooltip="Tax Type can be used to group taxes for easier identification, i.e. : Vat, Sales, Turnover."
								options={{
									values: [{ text: "Inclusive", value: 1 }, { text: "Exclusive", value: 2 }]
								}}
							/>
						</Col>
					}
					<Col span={6}>
						<InputComponent
							required name="dd_amount_exc_vat" label="DD Amount ex VAT" placeholder="DD Amount ex VAT"
							tooltip="Direct Debits give a company permission to take money from your bank account on an agreed date."
							rules={vsmTransactionItems.validation.ddamount} autoComplete="off"
						/>
					</Col>
					<Col span={6}>
						<InputComponent
							type="textarea" label="Description" name="description" placeholder="Description"
							tooltip="The purpose of journal description is to mention  the financial transactions of a business, to be used for the future reconciling of accounts."
							rules={vsmTransactionItems.validation.description}
							maxLength={1000} autoComplete="off"
						/>
					</Col>
					<Col span={12}>
						<Form.Item className="mb-0" wrapperCol={{ offset: 8 }}>
							<Form.Item name="add_address" initialValue={false} valuePropName="checked">
								<Checkbox onChange={addAddress}>Add Address</Checkbox>
							</Form.Item>
						</Form.Item>
					</Col>
				</Row>
				{
					isChecked ? (
						<Card hidden={!isChecked}>
							<Row gutter={24}>
								<Col span={6}>
									<InputComponent
										required label="Address 1" name="address_1"
										placeholder="Address Line 1" onChange={handleChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_1}
										maxLength={1000}
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										label="Address 2" name="address_2"
										placeholder="Address Line 2" onChange={handleChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_2and3}
										maxLength={1000}
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										label="Address 3" name="address_3"
										placeholder="Address Line 3" onChange={handleChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_2and3}
										maxLength={1000}
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										allowClear type="autocomplete" name="city" label="City"
										placeholder="City" onChange={handleChange}
										options={OrganisationStore.cityList}
										tooltip="It is a large human settlement place."
										rules={vsmOrganisations.validation.city}
										onFocus={() => fetchCity && OrganisationStore.getCityList().then(() => setFetchCity(false))}
										notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										allowClear type="autocomplete" label="State/County" name="state_county"
										placeholder="State/County" onChange={handleChange}
										options={OrganisationStore.stateCountyList}
										tooltip="State is defined as a territory with its own government and borders within a larger country."
										rules={vsmOrganisations.validation.state}
										onFocus={() => fetchState && OrganisationStore.getStateCountyList().then(() => setFetchState(false))}
										notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										label="Postal Code" name="postal_code"
										placeholder="Postal Code" onChange={handleChange}
										tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
										rules={vsmOrganisations.validation.postal_code}
										maxLength={16}
									/>
								</Col>
								<Col span={6}>
									<InputComponent
										required allowClear type="select" label="Country" placeholder="Country"
										name="country_id" onChange={handleChange}
										options={{
											values: OrganisationStore.dropdown_countries_list || (currentValues && [currentValues.country]),
											value_key: 'id',
											text_key: { key: ["iso_code_alpha2", " - ", "country_name"] }
										}}
										rules={vsmOrganisations.validation.country}
										tooltip="A country is a political state, nation, or territory which is controlled."
										onFocus={() => fetchCountry && OrganisationStore.getAllCountries().then(() => setFetchCountry(false))}
										notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
							</Row>
						</Card>
					) : (null)
				}

			</Form>
		</Drawer>
	)
})

export default FormComponent
