import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Col, Row, Select, Spin } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmJournalGroups, vsmNotify } from '../../../../../config/messages'
import useStore from '../../../../../store'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { JournalGroupStore: { DeleteData, deleteValues, dropdown_list, getAllRecords } } = useStore()
	const [saving, setSaving] = useState()
	const [deleteButton, ToggleDeleteButton] = useState(true)
	const [deleteText, setDeleteText] = useState('Transfer and Delete')
	const [fetchJournal, setFetchJournal] = useState(true)

	// Make function call to delete exixting record
	const handleSubmit = (data) => {
		setDeleteText('Transferring existing Journals in to new Journal subgroup, Please wait..')
		setSaving(true)
		data.id = deleteValues.id
		data.count = deleteValues.association_count
		DeleteData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournalGroups.delete
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setDeleteText('Transfer and Delete')
			ToggleDeleteButton(true)
		})
	}

	// Make transfer and delete button enable / disable
	const handleFieldChange = () => {
		if (form.getFieldsValue().transfer_id && form.getFieldsValue().transfer_id > 0 && form.getFieldsValue().confirm === "CONFIRM") {
			ToggleDeleteButton(false)
		} else {
			ToggleDeleteButton(true)
		}
	}

	// set the form values to delete
	useEffect(() => {
		ToggleDeleteButton(true)
		if (deleteValues) {
			form.setFieldsValue({
				journal_group_name: deleteValues.journal_group_name,
			})
		}
	}, [deleteValues, form])

	return deleteValues ? (
		<Modal
			centered
			title={`Delete Journal Group - #${deleteValues.id} - ${deleteValues.journal_group_name}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				(deleteValues.association_count > 0) ? (
					<Button key="1" disabled={deleteButton} form='deleteform' loading={saving} htmlType="submit" type="primary" danger>{deleteText}</Button>
				) : (
						<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>
					),
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					ToggleDeleteButton(true)
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					(deleteValues.association_count > 0) ? (
						<>
							<Row>
								<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
								<Col span={20}>
									<h3 className="mb-20">This Journal subgroup is associated with one or more existing Journals. You must assign all existing Journals to another Journal subgroup before removing this Journal subgroup. Be aware that you cannot undo this action.</h3>
									<Form.Item name="transfer_id" rules={vsmJournalGroups.validation.transfer_id}>
										<Select showSearch placeholder="Search Journal Subgroup"
											optionFilterProp="children"
											onChange={handleFieldChange}
											onFocus={() => fetchJournal && getAllRecords().then(() => setFetchJournal(false))}
											notFoundContent={fetchJournal ? <Spin size="small" /> : "No Record Found."}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{
												(dropdown_list && dropdown_list.map((item, index) => (
													(deleteValues.id !== item.id) ? (
														<Select.Option key={index} value={item.id}>{item.journal_group_name}</Select.Option>
													) : null
												)))
											}
										</Select>
									</Form.Item>
									<Form.Item style={{ marginBottom: 0 }} name="confirm">
										<Input placeholder='Type "CONFIRM" to delete' onChange={handleFieldChange} />
									</Form.Item>
								</Col>
							</Row>
						</>
					) : (
							<>
								<Row align="middle">
									<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
									<Col span={20}>
										<h3>Are you sure you want to remove this Journal subgroup?</h3>
									</Col>
								</Row>
							</>
						)
				}
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
