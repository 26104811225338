import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'

const ListComponent = observer((props) => {
	const { openSetViewImportLogDetails, openVerifyDrawer, openViewDrawer } = props
	const { CTEImportStore, CTEImportStore: { viewLog_list, onFilterChanged, setupGridForViewLogs } } = useStore()

	return (
		<div className="ag-theme-alpine grid_wrapper pt-80" style={{ marginTop: "-80px" }}>
			<AgGridReact
				rowData={viewLog_list}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openSetViewImportLogDetails, openVerifyDrawer, openViewDrawer
				}}
				onColumnResized={CTEImportStore.onChildnGridChanged}
				onColumnMoved={CTEImportStore.onChildnGridChanged}
				onColumnPinned={CTEImportStore.onChildnGridChanged}
				onSortChanged={CTEImportStore.onChildnGridChanged}
				onGridReady={setupGridForViewLogs}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
			/>
		</div>
	)
})

export default ListComponent
