import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, PageHeader } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import AddComponent from './components/AddComponent'
import ListComponent from './components/ListComponent'
import DeleteComponent from './components/DeleteComponent'
import EditComponent from './components/EditComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import ViewComponent from './components/ViewComponent'
import FilterComponent from './components/FilterComponent'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import VerifyComponent from '../Import/components/VerifyComponent'
import PageNotFound from '../../PageNotFound'
import ReconcileComponent from '../Reconciliation/components/ReconcileTransaction'
import { vsmNotify } from '../../../config/messages'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { default as TradeTransactionViewComponent } from '../../TradeTransaction/AllTransactions/component/ViewComponent';
import { FilterOutlined, FileExcelOutlined, TableOutlined } from '@ant-design/icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const CashTransactions = observer(() => {
	const history = useHistory()
	const [addModal, setAddModal] = useState(useLocation().search === '?cte-manual-entry-popup=1')
	const [editModal, setEditModal] = useState(false)
	const [viewModal, setViewModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [filterModal, setFilterModal] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [verifyDrawer, setVerifyDrawer] = useState(false)
	const [reconcileModal, setReconcileModal] = useState(false)
	const [transViewDrawer, setTransViewDrawer] = useState(false)
	const { LedgerBankAccountStore, CTEImportStore, ReconciliationStore, UserStore, CashTransactionStore, CashTransactionStore: { per_page, list_data, list_status, advanceFilter, agGrid, setDeleteValues, setPageSize, exportLoading, listLoading, exportCilentSideData, exportData, setImportId, setupGrid, onGridChangedIcon } } = useStore()
	const { id } = useParams()

	history.listen((location, action) => {
		if (location.search === '?cte-manual-entry-popup=1') {
			setAddModal(true)
		} else {
			setAddModal(false)
		}
	})

	// set id on refresh form
	useEffect(() => {
		let data = id ? id : null
		setImportId(data)
	}, [setImportId, id])

	// set id for edit / view drawer
	const setId = (id) => setTransactionId(id)

	// Open form for add new cash transaction
	const openAddModal = () => {
		LedgerBankAccountStore.getDynamicFieldList()
		setAddModal(true)
	}

	// Close form for close new cash transaction
	const closeAddModal = () => {
		history.replace(history.location.pathname)
		setAddModal(false)
	}

	// Open form for edit existing cash transaction and set values to form
	const openEditModal = (data) => {
		setId(data.id)
		LedgerBankAccountStore.getDynamicFieldList()
		setEditModal(true)
	}

	// Close form of edit cash transaction
	const closeEditModal = () => setEditModal(false)

	// Open form for view existing cash transaction and set values to form
	const openViewModal = (data) => {
		setId(data.id || data)
		LedgerBankAccountStore.getDynamicFieldList()
		setViewModal(true)
	}

	// Close form of view cash transaction
	const closeViewModal = () => setViewModal(false)

	// Open form for Advance Filter cash transaction
	const openFilterModal = (data) => setFilterModal(true)

	// Close form of Advance Filter cash transaction
	const closeFilterModal = () => setFilterModal(false)

	// Open confirmation alert before deleting existing cash transaction
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	const openVerifyDrawer = (data) => {
		setVerifyDrawer(true)
		CTEImportStore.setVerifyValues({ ...data, data_from: 'inner_list' })
	}

	const closeVerifyDrawer = () => {
		setVerifyDrawer(false)
		setupGrid(agGrid)
	}

	// Open form for multiple Reconcile Cash Transaction with Trade Transaction’s Journal entry
	const openMultiReconcileModal = (data) => {
		if (agGrid) {
			let selected = agGrid.api.getSelectedRows()
			selected = selected.map(item => item.id)
			if (selected.length > 0) {
				ReconciliationStore.selectedIds = selected
				setReconcileModal(true)
				agGrid.api.deselectAll()
			}
			else { vsmNotify.error({ message: "Please select atleast one record to reconcile." }) }
		}
	}

	// Close form
	const closeReconcileModal = () => setReconcileModal(false)

	// open drawer for view trade transaction
	const openTransViewDrawer = (data) => {
		setId(data.transaction_id)
		setTransViewDrawer(true)
	}

	// close drawer for view
	const closeTransViewDrawer = () => setTransViewDrawer(false)

	if (list_status === 404) {
		return (
			<PageNotFound />
		)
	} else {
		return (
			<>
				<BreadcrumbComponent items={BreadcrumbConfig.CashTransactions.path} />
				{(!UserStore.checkPermission(76, 'list')) ? (<Unauthorized />) : (
					<PageHeader
						className={UserStore.fullscreen_class}
						title={BreadcrumbConfig.CashTransactions.title}
						extra={[
							UserStore.checkPermission(77, 'reconciliation_mode') && <Button className='ml-10' key="1" onClick={openMultiReconcileModal}>Reconciliation Mode</Button>,
							UserStore.checkPermission(76, 'advancefilter') && <Button key="2" type={advanceFilter && "primary"} onClick={openFilterModal} title="Advance Filter"><FilterOutlined /></Button>,

							UserStore.checkPermission(76, 'export') && <Button key="3" title='Export' loading={exportLoading} onClick={() => { advanceFilter ? exportCilentSideData() : exportData() }} disabled={listLoading || (list_data && list_data.length === 0)}><FileExcelOutlined /></Button>,

							UserStore.checkPermission(76, 'add') && <Button key="4" onClick={openAddModal}>New</Button>,

							<RecordPerPage key="5" defaultValue={per_page + ' per page'} onChange={setPageSize} moreOption={true} />,
							<Button key="6" title="Save Column Settings" onClick={onGridChangedIcon} disabled={!agGrid}><TableOutlined /></Button>,
							<Button key="7" title="Reset" onClick={CashTransactionStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,

							<EnterFullscreen key='8' />
						]}
					>
						<ListComponent openVerifyDrawer={openVerifyDrawer} openFilterModal={openFilterModal} openViewModal={openViewModal} openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
						<AddComponent visible={addModal} close={closeAddModal} />
						<ReconcileComponent visible={reconcileModal} close={closeReconcileModal} openViewDrawer={openViewModal} closeViewDrawer={closeViewModal} openParentTransViewDrawer={openTransViewDrawer} />
						<ViewComponent transactionId={transactionId} setId={setId} visible={viewModal} close={closeViewModal} openParentTransViewDrawer={openTransViewDrawer} />
						<EditComponent transactionId={transactionId} setId={setId} visible={editModal} close={closeEditModal} />
						<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
						<FilterComponent page="Filter" visible={filterModal} close={closeFilterModal} />
						<VerifyComponent visible={verifyDrawer} close={closeVerifyDrawer} />
						<TradeTransactionViewComponent transactionId={transactionId} setId={setId} visible={transViewDrawer} close={closeTransViewDrawer} openParentCashViewDrawer={openViewModal} />
					</PageHeader>
				)}
			</>
		)
	}
})

export default CashTransactions
