import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Checkbox, Tooltip, Form, Col, DatePicker, Spin, Row, Divider, Button } from 'antd'
import { InfoCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent'
import { vsmAllTransactions, vsmOrganisations, vsmTransactionItems, vsmTransactionReport } from '../../../../config/messages';
import useStore from '../../../../store';
import moment from 'moment';
import { PhoneOutlined, GlobalOutlined, EnvironmentFilled } from '@ant-design/icons';

const FormFieldComponent = observer((props) => {
	const [fetchRole, setFetchRole] = useState(true)
	const [fetchMapping, setFetchMapping] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [fetchVatRate, setFetchVatRate] = useState(true)
	const [roleRejectedKeys, updateRoleRejectedKeys] = useState([])
	const [mappingRejectedKeys, updateMappingRejectedKeys] = useState([])
	const [isChecked, setChecked] = useState(false)
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchManufacturer, setFetchManufacturer] = useState(true)
	const [fetchClassification, setFetchClassification] = useState(true)
	const { OrgBankAccountStore, UserStore, OrganisationStore, TransactionItemsStore, AllTransactionStore: { getMappingList, getStatusList, getVatRateList, dropdownLodaing, checkBox_dropdown, checkBox_dropdown_val, depedentList, mapping_dropdown, vatRate_dropdown, status_dropdown, csvModals } } = useStore()
	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore,
		user: UserStore
	}
	let data = depedentList && depedentList.entity.filter((entity) => {
		return entity.entity_name !== null
	})

	const currentDate = new Date()

	// check whether address checkbox is checked or not
	const addAddress = async (e) => {
		if (!e.target.checked) {
			props.form.resetFields(["postal_code", "address_1", "address_2", "address_3", "city", "state_county"])
		}
		await setChecked(e.target.checked)
		props.onChange()
	}

	const handleValue = (value, key) => {
		const fields = props.form.getFieldsValue()
		const { transaction_items } = fields
		if (transaction_items[key].quantity && transaction_items[key].unit_price) {
			Object.assign(transaction_items[key], { value: parseFloat(transaction_items[key].quantity) * parseFloat(transaction_items[key].unit_price) })
			props.form.setFieldsValue({ transaction_items })
		} else {
			Object.assign(transaction_items[key], { value: parseFloat(0) })
			props.form.setFieldsValue({ transaction_items })
		}
	}

	useEffect(() => {
		setFetchRole(true)
		setFetchMapping(true)
		setFetchStatus(true)
		setFetchVatRate(true)
		props.onChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	useEffect(() => {
		if (props.tab === "add") {
			props.form.setFieldsValue({ model: csvModals })
		}
		if (store.organisation.dropdown_orgRole_list) {
			let rejected_keys = [];
			store.organisation.dropdown_orgRole_list.forEach((item) => {
				if (!['Leasee', 'Originator'].includes(item.role_name)) {
					rejected_keys.push(item.id)
				}
			})
			updateRoleRejectedKeys(rejected_keys)
		}

		if (mapping_dropdown) {
			let mapping_rejected_keys = [];
			mapping_dropdown.forEach((item) => {
				if (!['a-ETR Trade', 'f-ETR Trade'].includes(item.mapping_name)) {
					mapping_rejected_keys.push(item.id)
				}
			})
			updateMappingRejectedKeys(mapping_rejected_keys)
		}

	}, [csvModals, props.tab, props.form, store.organisation.dropdown_orgRole_list, mapping_dropdown])

	return (
		<>
			<Row gutter={24}>
				<Col span={5}>
					<Row gutter={24}>
						<Col span={24}>
							{/* Organisation Role */}
							<InputComponent
								hidden
								required
								disabled={props.tab !== "add"}
								type="select"
								label="Organisation Role"
								name="role_id"
								placeholder="Select Organisation Role"
								tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
								rules={vsmAllTransactions.validation.organisation_role}
								onChange={props.onChange}
								onFocus={() => fetchRole && store.organisation.getOrgRoles().then(() => setFetchRole(false))}
								notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: props.tab === "add" ? store.organisation.dropdown_orgRole_list : (depedentList && depedentList.roles),
									value_key: "id",
									text_key: "role_name",
									rejected_keys: roleRejectedKeys
								}}
							/>
						</Col>

						<Col span={24}>
							{/* Organisation */}
							<InputComponent
								required
								disabled={props.tab !== "add"}
								type="select"
								label="Organisation"
								name="entity_id"
								placeholder="Select Organisation"
								tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
								rules={vsmOrganisations.validation.organisation}
								onChange={props.onChange}
								notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
								filterSort={(optionA, optionB) =>
									(optionA.children ? optionA.children : '').toLowerCase().localeCompare((optionB.children ? optionB.children : '').toLowerCase())
								}
								options={{
									values: data,
									value_key: "id",
									text_key: "entity_name",
								}}
							/>
						</Col>
						<Col span={24}>
							<InputComponent
								required
								type="select"
								label="Trade Transaction"
								name="type_field_mapping_id"
								placeholder="Select Trade Transaction"
								tooltip="Select Trade Transaction Name which is combination of Trade Type & Transaction Type. It Defines which fields and models will be generated on imported transactions."
								rules={vsmAllTransactions.validation.mapping}
								onChange={(e) => { props.onChange(); props.form.setFieldsValue({ asset_type: e === 1 ? 1 : 2 }) }}
								onFocus={() => fetchMapping && getMappingList().then(() => setFetchMapping(false))}
								notFoundContent={fetchMapping ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: mapping_dropdown,
									value_key: "id",
									text_key: "mapping_name",
									rejected_keys: mappingRejectedKeys
								}}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={10}>
					{props.orgData && <CardComponent props={props.orgData} />}
				</Col>
			</Row>
			<Divider />
			<Row gutter={24}>
				<Col span={4}>
					<InputComponent
						required
						type="select"
						label="Repayment Schedule"
						name="repayment_schedule"
						placeholder="Repayment Schedule"
						tooltip="Currency is a medium of exchange for goods and services. Select one which is base currency for this transaction."
						onChange={props.onChange}
						options={{
							values: [{ value: '1', text: 'Monthly Scheduled Repayment' }, { value: '2', text: 'Monthly Interest Only' }],
						}}
					/>
				</Col>

				{/* Currency */}
				<Col span={2}>
					<InputComponent
						required
						type="select"
						label="Currency"
						name="currency_id"
						placeholder="Select Currency"
						tooltip="Currency is a medium of exchange for goods and services. Select one which is base currency for this transaction."
						rules={vsmAllTransactions.validation.currency}
						initialValue={store.user.ledger_base_currency.id}
						onChange={props.onChange}
						options={{
							values: store.bank.dropdown_currency_list,
							value_key: "id",
							text_key: "currency_code"
						}}
					/>
				</Col>

				{/* Face value */}
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						disabled={props.tab === "view"}
						label="Face Value"
						name="face_value"
						placeholder="Face Value"
						tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
						rules={vsmAllTransactions.validation.face_value}
						onChange={props.onChange}
					/>
				</Col>

				{/* PTV or LTV */}
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						disabled={props.tab === "view"}
						label="PTV%"
						name="ltv_value"
						placeholder="PTV%"
						tooltip={"PTV should contain Dot (Decimal upto 2 places) and Numeric Values only. Other special characters and Alphabets are not allowed."}
						rules={vsmAllTransactions.validation.ltv_value}
						onChange={props.onChange}
					/>
				</Col>

				{/* Status */}
				<Col span={2}>
					<InputComponent
						required
						disabled={props.tab === "view"}
						type="select"
						label="Status"
						name="status_id"
						placeholder="Select Status"
						tooltip="Status is an attribute of a Transaction, it tells actual status of Transaction at the moment."
						rules={vsmAllTransactions.validation.status}
						onChange={props.onChange}
						initialValue={1}
						onFocus={() => props.tab === "edit" && (fetchStatus && getStatusList().then(() => setFetchStatus(false)))}
						notFoundContent={props.tab === "edit" && (fetchStatus ? <Spin size="small" /> : "No Record Found.")}
						options={{
							values: status_dropdown,
							value_key: "id",
							text_key: "status_name"
						}}
					/>
				</Col>

				{/* VAT Rate */}
				<Col span={3}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Purchase VAT Rate"
						name="tax_settings_id"
						placeholder="VAT Rate"
						tooltip="purchase VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						rules={vsmAllTransactions.validation.vat_rate}
						onChange={props.onChange}
						onFocus={() => fetchVatRate && getVatRateList().then(() => setFetchVatRate(false))}
						notFoundContent={fetchVatRate ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vatRate_dropdown,
							value_key: "id",
							text_key: { key: ["tax_name", " - ", "tax_value"] },
						}}
					/>
				</Col>

				{/* Posted On */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Posted On"
						name="posted_on"
						placeholder="Select Posted Date"
						tooltip="The posted date is the day, month, and year when the trade transaction is Posted."
						rules={vsmAllTransactions.validation.posted_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>

				{/* Traded On */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Traded On"
						name="traded_on"
						placeholder="Select Traded Date"
						tooltip="The traded date is the day, month, and year when the trade transaction is Traded."
						rules={vsmAllTransactions.validation.traded_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>

				{/* Issued on */}
				<Col span={3}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Issued On"
						name="issued_on"
						placeholder="Select Issued Date"
						tooltip="The Issued date is the day, month, and year when the trade transaction is Issued."
						rules={vsmAllTransactions.validation.issued_date}
						onChange={props.onChange}
					/>
				</Col>

				{/* Additional Field Values */}
				<Col span={3}>
					<InputComponent
						type="radio_button" disabled
						onChange={props.onChange}
						options={{
							values: [{ value: 1, text: 'a-ETR' }, { value: 2, text: 'f-ETR' }]
						}}
						label='Asset Type'
						tooltip="The traded date is the day, month, and year when the trade transaction is Traded."
						name='asset_type'
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						onChange={props.onChange}
						label='Sell Rate (%)'
						name='sell_rate'
						placeholder='Sell Rate(%)'
						tooltip={"Sell Rate should contain Dot (Decimal upto 2 places) and Numeric Values only. Other special characters and Alphabets are not allowed."}
						rules={vsmTransactionReport.validation.sell_rate}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						label='Period'
						name='period'
						placeholder='Period'
						tooltip={'Period allowed only Numbers'}
						rules={vsmTransactionReport.validation.period}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						type='select'
						label='Period Type'
						name='period_type'
						placeholder='Period Type'
						options={{
							values: [{ value: 1, text: 'Days' }, { value: 2, text: 'Weeks' }, { value: 3, text: 'Months' }, { value: 4, text: 'Years' }]
						}}
						tooltip={'Select Period Type'}
						rules={vsmTransactionReport.validation.period_type}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						type="radio_button"
						label='Tax Type'
						name='tax_type'
						options={{
							values: [{ value: 1, text: 'Inc' }, { value: 2, text: 'Exc' }]
						}}
					/>
				</Col>
				{/* <Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						label='EDSO'
						name='EDSO'
						placeholder='EDSO'
						tooltip={'EDSO allowed only Numbers'}
						rules={vsmTransactionReport.validation.EDSO}
					/>
				</Col> */}
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						onChange={props.onChange}
						label='R&M'
						name='repair_maintenance'
						placeholder='Repair and Maintenance'
						tooltip={'Repair and Maintenance should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed.'}
						rules={vsmTransactionReport.validation.repair_maintenance}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						className='text-right'
						onChange={props.onChange}
						label='EoT'
						name='eot'
						placeholder='EoT'
						tooltip={'EoT should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed.'}
						rules={vsmTransactionReport.validation.eot}
					/>
				</Col>
				<Col span={2}>
					<InputComponent
						required
						onChange={props.onChange}
						label='Batch ID'
						name='batch_id'
						placeholder='Batch ID'
						tooltip={'Batch ID can not be blank.'}
						rules={vsmTransactionReport.validation.batch_id}
					/>
				</Col>
				{/* <Col span={3}>
					<InputComponent
						onChange={props.onChange}
						label='Exchange Ref ID'
						name='exchange_ref_id'
						placeholder='Exchange Ref ID'
						tooltip={'Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software.'}
					/>
				</Col> */}
				<Col span={2}>
					<InputComponent
						onChange={props.onChange}
						label='Reference'
						name='reference'
						placeholder='Reference'
						tooltip={'Reference'}
					/>
				</Col>
				<Col span={3}>
					<InputComponent
						required
						type="select"
						label="Sales VAT Rate"
						name="sales_vat_rate_id"
						placeholder="Select VAT Rate"
						tooltip="Sales VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						rules={vsmAllTransactions.validation.sales_vat_rate}
						onChange={props.onChange}
						onFocus={() => fetchVatRate && getVatRateList().then(() => setFetchVatRate(false))}
						notFoundContent={fetchVatRate ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vatRate_dropdown,
							value_key: "id",
							text_key: { key: ["tax_name", " - ", "tax_value"] },
						}}
					/>
				</Col>
				<Col span={3}>
					<InputComponent
						type='checkbox'
						checked={props.customStartDate}
						disabled={props.tab !== "add"}
						label={'Asset Repayment Start Date'}
						name='is_custom_start_date'
						onChange={e => props.setCustomStartDate(e.target.checked)}
					/>
				</Col>

				{props.customStartDate &&
					<Col span={3}>
						<DatePickerComponent
							required
							disabled={props.tab !== "add"}
							label="Start Date"
							name="start_date"
							placeholder="Select Start Date Date"
							tooltip="The Start Date is the day, month, and year when the trade transaction was started."
							rules={vsmAllTransactions.validation.start_date}
							onChange={props.onChange}
						/>
					</Col>
				}
			</Row>

			<Card className="ant-col ant-form-item-control" title='Assets Details:'>
				<Form.List name="transaction_items">
					{(fields, { add, remove }) => {
						return (
							<div>
								{fields.map((field, index) => (
									<div key={field.key}>
										<Row gutter={24}>
											<Col span={22}>
												<Row gutter={24}>
													<Col span={2}>
														<InputComponent
															name={[field.name, "asset_number"]} key={[field.key, 'asset_number']} label="Asset Number" placeholder="Asset Number" onChange={props.onChange}
															tooltip="An asset number uniquely identifies the product. An asset number can be represented by either a license number for software or a service tag for a hardware appliance."
															rules={vsmTransactionItems.validation.assetnumber} autoComplete="off"
														/>
													</Col>
													<Col span={2}>
														<InputComponent
															className='text-right' name={[field.name, "deposit_received"]} key={[field.key, 'deposit_received']} label="Deposit Received" placeholder="Deposit Received" onChange={props.onChange}
															tooltip="A customer deposit could be money that a company receives from a customer prior to the company earning."
															rules={vsmTransactionItems.validation.deposit} autoComplete="off"
															maxLength={100}
														/>
													</Col>
													<Col span={3}>
														<InputComponent
															name={[field.name, "item_name"]} key={[field.key, 'item_name']} label="Asset Name" placeholder="Asset Name" onChange={props.onChange}
															tooltip="An item is one of a collection or list of objects."
															rules={vsmTransactionItems.validation.item} autoComplete="off"
															maxLength={100}
														/>
													</Col>
													<Col span={3}>
														<InputComponent
															name={[field.name, "serial_no"]} key={[field.key, 'serial_no']} label="Serial Number" placeholder="Serial Number" onChange={props.onChange}
															tooltip="A serial number is a unique identifier assigned incrementally or sequentially to an item, to uniquely identify it."
															rules={vsmTransactionItems.validation.serialnumber} autoComplete="off"
															maxLength={100}
														/>
													</Col>
													<Col span={2}>
														<InputComponent
															name={[field.name, "site_number"]} key={[field.key, 'site_number']} label="Site No" placeholder="Site No" onChange={props.onChange}
															tooltip="The site number is the unique identifier for your Grid. You will most commonly see the site number used within your Access Domain."
															rules={vsmTransactionItems.validation.sitenumber} autoComplete="off"
															maxLength={100}
														/>
													</Col>
													<Col span={2}>
														<InputComponent
															required name={[field.name, "quantity"]} key={[field.key, 'quantity']} label="Quantity" placeholder="Qty" onChange={(e) => { handleValue(e, field.key); props.onChange() }}
															tooltip="The amount or number of something, especially that can be measured."
															rules={vsmTransactionItems.validation.quantity} autoComplete="off"
														/>
													</Col>
													<Col span={2}>
														<InputComponent
															className='text-right' required name={[field.name, "unit_price"]} key={[field.key, 'unit_price']} label="Unit Price" placeholder="Unit Price" onChange={(e) => { handleValue(e, field.key); props.onChange() }}
															tooltip="Unit price is the price for a single unit of measure of a product sold in more or less than the single unit."
															rules={vsmTransactionItems.validation.unitprice} autoComplete="off"
														/>
													</Col>
													<Col span={2}>
														<InputComponent
															className='text-right' name={[field.name, "value"]} key={[field.key, 'value']} label="Value" placeholder="Value" onChange={props.onChange}
															tooltip="Value is the worth in goods, services or money of an object or person." disabled={true} autoComplete="off"
														/>
													</Col>
													<Col span={3}>
														<InputComponent
															name={[field.name, "manufacturer"]} key={[field.key, 'manufacturer']} type="autocomplete" label="Supplier/Manufacturer" placeholder="Supplier/Manufacturer"
															tooltip="A person or company that makes goods for sale." onChange={props.onChange}
															rules={vsmTransactionItems.validation.manufacturer} autoComplete="off"
															onFocus={() => fetchManufacturer && TransactionItemsStore.getManufacturer().then(() => setFetchManufacturer(false))}
															notFoundContent={fetchManufacturer ? <Spin size="small" /> : "No Record Found."}
															options={TransactionItemsStore.manufacturer_list}
															maxLength={100}
														/>
													</Col>
													<Col span={3}>
														<InputComponent
															required
															type="select" mode='tags' name={[field.name, "classification"]} key={[field.key, 'classification']} label="Asset Classification" placeholder="Asset Classification"
															tooltip="Classification is the list of industry sectors."
															onChange={(value) => { props.onChange(); if (value.length > 1) { value.pop(); } }}
															rules={vsmTransactionItems.validation.classification}
															onFocus={() => fetchClassification && TransactionItemsStore.getClassification().then(() => setFetchClassification(false))}
															notFoundContent={fetchClassification ? <Spin size="small" /> : "No Record Found."}
															options={{
																values: TransactionItemsStore.classification_list,
																value_key: 'value',
																text_key: 'value',
															}}
															maxLength={100}
														/>
													</Col>
													<Col span={3}>
														<InputComponent
															name={[field.name, "dd_amount_exc_vat"]} key={[field.key, 'dd_amount_exc_vat']} label="DD Amount ex VAT" placeholder="DD Amount ex VAT" onChange={props.onChange}
															tooltip="Direct Debits give a company permission to take money from your bank account on an agreed date."
															rules={vsmTransactionItems.validation.ddamount} autoComplete="off"
														/>
													</Col>
													<Col span={6}>
														<InputComponent
															type="textarea" label="Description" name={[field.name, "description"]} key={[field.key, 'description']} placeholder="Description" onChange={props.onChange}
															tooltip="The purpose of journal description is to mention  the financial transactions of a business, to be used for the future reconciling of accounts."
															rules={vsmTransactionItems.validation.description}
															maxLength={1000} autoComplete="off"
														/>
													</Col>
												</Row>
											</Col>
											<Col span={2}>
												<div className="d-flex align-items-center justify-content-center h-100">
													{
														(fields.length > 1) && (
															<Button type="danger" onClick={() => {
																remove(field.name)
																props.onChange()
															}} icon={<MinusOutlined />}></Button>
														)
													}
													{(index === (fields.length - 1)) && (fields.length < 12) && (
														<Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
															await add({ asset_number: null, id: null, deposit_received: 0 })
															props.onChange()
														}} icon={<PlusOutlined />} />
													)}
												</div>
											</Col>
										</Row>
										<Divider className='mb-20' />
									</div>
								))}
								<Col span={24}>
									<Form.Item className="mb-0 text-center" wrapperCol={{ offset: 11 }}>
										<Form.Item className="mb-0" name="add_address" checked={isChecked}>
											<Checkbox onChange={addAddress}>Add Address</Checkbox>
										</Form.Item>
									</Form.Item>
								</Col>
							</div>
						)
					}}
				</Form.List>
				{
					isChecked ? (
						<Card hidden={!isChecked} className='mb-20 mt-10'>
							<Row gutter={24}>
								<Col span={3}>
									<InputComponent
										required label="Address 1" name="address_1"
										placeholder="Address Line 1" onChange={props.onChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_1}
										maxLength={1000}
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										label="Address 2" name="address_2"
										placeholder="Address Line 2" onChange={props.onChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_2and3}
										maxLength={1000}
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										label="Address 3" name="address_3"
										placeholder="Address Line 3" onChange={props.onChange}
										tooltip="A contact point used to send physical forms of communication to the organisation."
										rules={vsmOrganisations.validation.address_2and3}
										maxLength={1000}
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										allowClear type="autocomplete" name="city" label="City"
										placeholder="City" onChange={props.onChange}
										options={OrganisationStore.cityList}
										tooltip="It is a large human settlement place."
										rules={vsmOrganisations.validation.city}
										onFocus={() => fetchCity && OrganisationStore.getCityList().then(() => setFetchCity(false))}
										notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										allowClear type="autocomplete" label="State/County" name="state_county"
										placeholder="State/County" onChange={props.onChange}
										options={OrganisationStore.stateCountyList}
										tooltip="State is defined as a territory with its own government and borders within a larger country."
										rules={vsmOrganisations.validation.state}
										onFocus={() => fetchState && OrganisationStore.getStateCountyList().then(() => setFetchState(false))}
										notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										label="Postal Code" name="postal_code"
										placeholder="Postal Code" onChange={props.onChange}
										tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
										rules={vsmOrganisations.validation.postal_code}
										maxLength={16}
									/>
								</Col>
								<Col span={3}>
									<InputComponent
										required allowClear type="select" label="Country" placeholder="Country"
										name="country_id" onChange={props.onChange}
										options={{
											values: OrganisationStore.dropdown_countries_list || (props.currentValues && [props.currentValues.country]),
											value_key: 'id',
											text_key: { key: ["iso_code_alpha2", " - ", "country_name"] }
										}}
										rules={vsmOrganisations.validation.country}
										tooltip="A country is a political state, nation, or territory which is controlled."
										onFocus={() => fetchCountry && OrganisationStore.getAllCountries().then(() => setFetchCountry(false))}
										notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
										autoComplete="off"
									/>
								</Col>
							</Row>
						</Card>
					) : (null)
				}
			</Card>


			{/* Select Models to Apply on Transaction */}
			<div className={props.csvColumn && props.csvColumn.length > 0 ? "mt-25" : ""}>
				{
					csvModals && csvModals.length > 0 && (
						<Card
							title={<div>
								<span>Select Models to Apply on Transaction</span>
								<Tooltip placement="topRight" title={"Model list is displayed based on selected mapping."}>
									<InfoCircleOutlined className="ml-10" />
								</Tooltip>
							</div>}
							className="ant-col ant-form-item-control"
						>
							<CheckboxComponent
								name="model"
								options={csvModals}
								disabled={props.tab !== "add"}
								onChange={props.onChange}
								dropdown={checkBox_dropdown}
								dropdown_val={checkBox_dropdown_val}
							/>
						</Card>
					)
				}
			</div>
		</>
	)
})

export default FormFieldComponent

const DatePickerComponent = ({ label, required, rules, name, tooltip, initialValue, ...rest }) => {
	return (
		<Form.Item label={label} required={required} tooltip={tooltip}>
			<Form.Item name={name} initialValue={initialValue} noStyle rules={rules}>
				<DatePicker {...rest} className={'d-flex w-100'} />
			</Form.Item>
		</Form.Item>
	)
}

const CheckboxComponent = ({ name, options, dropdown, onChange, disabled }) => {
	return (options && options.length >= 0) ? (
		disabled ? (
			<Form.Item name={name}>
				<Checkbox.Group
					valuePropName="checked"
					options={options}
					onChange={onChange}
					disabled={disabled}
				/>
			</Form.Item>
		) : (
			<Form.List name={name} initialValue={options} >
				{
					(fields) => (
						<Row gutter={24}>
							{fields.map((field, index) => {
								return options[index] && (
									<Col span={12} key={field.key}>
										<Row gutter={24}>
											<Col span={12}>
												<Form.Item
													valuePropName="checked"
													noStyle
													name={[field.name, "model"]}
													key={[field.key, "model"]}
													initialValue={index >= 0 && options[index].selected ? true : false}
												>
													<Checkbox disabled={disabled}>{index >= 0 && options[index].model_name}</Checkbox>
												</Form.Item>
											</Col>
											<Col span={12}>
												<InputComponent
													required
													type="select" disabled={disabled}
													initialValue={"Current Date"}
													options={{
														values: dropdown,
													}}
													onChange={onChange}
													name={[field.name, 'field']}
													key={[field.key, 'field']}
													placeholder="Select DB Field"
												/>
											</Col>
										</Row>
									</Col>
								)
							})}
						</Row>
					)
				}
			</Form.List>
		)
	) : null
}

const CardComponent = ({ props }) => {
	return (
		<Card size="small" className="mb-20">
			<h3><b>{props.organisation_name} ({props.trade_name ? props.trade_name + ' - ' : ''}{props.global_countries ? props.global_countries.country_name : ''})</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						{(props.multiple_phone && props.multiple_phone.length) ? (
							<tr>
								<td style={{ width: '30px' }}><PhoneOutlined /></td>
								<td>{props.multiple_phone.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{props.website ? (
							<tr>
								<td><GlobalOutlined /></td>
								<td>{props.website && props.website}</td>
							</tr>
						) : null}
						{props.addresses ? (
							<tr>
								<td><EnvironmentFilled /></td>
								<td><div>
									{props.addresses.address_1}
									{props.addresses.address_2 && (', ' + props.addresses.address_2)}
									{props.addresses.address_3 && (', ' + props.addresses.address_3)}
									{props.addresses.city && (', ' + props.addresses.city)}
									{props.addresses.state_county && (', ' + props.addresses.state_county)}
									{props.addresses.countries && (', ' + props.addresses.countries.country_name)}
									{props.addresses.postal_code && (', ' + props.addresses.postal_code)}
								</div>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</Card>
	)
}
