import React, { useEffect, useState } from 'react'
import { Form, Button, Input, Select, Card, Upload, message, Spin, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { vsmNotify, vsmLedgerDetails } from '../../../../../config/messages';
import useStore from '../../../../../store'
import { observer } from 'mobx-react';
import { default as OrganisationEditComponent } from '../../../../OrganisationManagement/AllOrganisations/component/EditComponent';
import { useHistory } from 'react-router-dom';
import Unauthorized from '../../../../Unauthorized';
import PageNotFound from '../../../../PageNotFound';

const LedgerDetail = observer((props) => {
	const [form] = Form.useForm()
	const { UserStore, OrganisationStore, LedgerDetailStore: { EditData, getAllCurrencyList, getAllTimeZoneList, currency_list, timezone_list, getRecordById, setEditValues, editValues } } = useStore()
	const [saving, setSaving] = useState(false)
	const [tip, setTip] = useState('Loading')
	const [disabled, setDisabled] = useState(false)
	const [imgDisabled, setImgDisabled] = useState(false)
	const [fileList, updateFileList] = useState([]);
	const [editModal, setEditModal] = useState(false)
	const [imgchanged, setImgchanged] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	let history = useHistory()

	const store = {
		Organisation: OrganisationStore,
		User: UserStore
	}

	let ledger_id = store.User.user.default_ledger_id

	// set id for edit / view drawer
	const setId = (id) => setTransactionId(id)

	// Set the form values to refresh
	useEffect(() => {
		setSaving(true)
		setTip('Loading')
		getRecordById({ id: ledger_id }).then((data) => {
			updateFileList(data.data.logo_url ? ([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: data.data.logo_url,
				},
			]) : [])
			setEditValues(data.data)
			form.setFieldsValue({
				name: data.data.name,
				organisation_id: data.data.organisation ? data.data.organisation.organisation_name : null,
				description: data.data.description,
				currency_id: data.data.currency_id,
				timezone_id: data.data.timezone_id
			})
			setSaving(false)
			setTip(null)
		}).catch(({ response }) => {
			if (response && response.status === 404) {
				setSaving('404')
			}
			if (response && response.status === 403) {
				setSaving('403')
			}
		})
		getAllCurrencyList()
		getAllTimeZoneList()
	}, [getAllCurrencyList, getAllTimeZoneList, getRecordById, form, setEditValues, ledger_id, updateFileList])

	// Open form for edit existing Organisation and set values to form
	const openEditModal = (data) => {
		store.Organisation.getAllCountries()
		setId(editValues.organisation_id)
		setEditModal(true)
		store.Organisation.agGrid = null
	}

	// To set form details
	const setFormDetails = () => {
		getRecordById({ id: ledger_id }).then((data) => {
			updateFileList(data.data.logo_url ? ([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: data.data.logo_url,
				},
			]) : [])
			setEditValues(data.data)
			form.setFieldsValue({
				name: data.data.name,
				organisation_id: data.data.organisation ? data.data.organisation.organisation_name : null,
				description: data.data.description,
				currency_id: data.data.currency_id,
				timezone_id: data.data.timezone_id
			})
		})
	}

	// Close form of edit Organisation
	const closeEditModal = () => {
		setEditModal(false)
		setFormDetails()
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		const formData = new FormData();
		formData.append('name', data.name);
		formData.append('currency_id', data.currency_id);
		if (data.description !== null) {
			formData.append('description', data.description);
		}
		formData.append('timezone_id', data.timezone_id);
		formData.append('organisation_id', editValues.organisation_id);

		if (fileList.length > 0 && fileList[0] && imgchanged) {
			formData.append('logo_url', fileList[0].hasOwnProperty("originFileObj") ? fileList[0].originFileObj : fileList[0])
		} else if (fileList.length === 0) {
			formData.append('logo_status', 'deleted')
		}

		let id = editValues.id
		setSaving(true)
		setTip('Saving')
		EditData(formData, id).then(() => {
			vsmNotify.success({
				message: vsmLedgerDetails.edit
			})
			store.User.setUserOptions(localStorage.getItem('token'))
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false); setTip(null) })
	}

	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
			setImgchanged(true)
		},
		fileList,
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				setImgDisabled(true)
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				setImgDisabled(true)
				return true;
			}
			else {
				updateFileList([file])
				setImgchanged(true)
				setImgDisabled(false)
				return false
			}
		}
	};

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "logo_url", errors: [] }])
		updateFileList([]);
		setImgchanged(false)
		setImgDisabled(false)
	}

	const uploadButton = (
		<div>
			<PlusOutlined />
		</div>
	);

	// close form and redirect to dashboard
	const close = () => {
		form.resetFields()
		history.push('/dashboard')
		setSaving(true)
		setTip('Loading')
		setImgchanged(false)
	}

	return (
		<>
			<Spin size="large" spinning={saving === true} tip={tip}>
				{
					(saving === '404') ? (
						<PageNotFound />
					) : (
						(saving === '403') ? (
							<Unauthorized />
						) : (
							(!UserStore.checkPermission(69, 'edit')) ? (<Unauthorized />) : (
								<Card>
									<Form form={form} id="addform"
										onFinish={handleSubmit}
										onChange={handleChange}
										layout='vertical'
									>
										<Row gutter={24}>
											<Col span={12}>
												<Form.Item name="name" rules={vsmLedgerDetails.validation.name} label="Ledger Name" required tooltip="Ledger Name is one of the basic parameters of a Organisation Ledger. Name must be unique.">
													<Input id="name" placeholder="Ledger Name" maxLength={100} />
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item name="organisation_id" rules={vsmLedgerDetails.validation.organisation} required label="Organisation"
													extra={[
														(UserStore.checkPermission(35, 'edit') && editValues && store.User.user && editValues.organisation && (store.User.user.user_role_id === 1 || editValues.organisation.ledger_id === ledger_id)) ? (
															<Button type="link" onClick={() => { openEditModal() }}> Edit Organisation</Button>
														) : null
													]} tooltip="Each Ledger is associated with one organisation to stores data">
													<Input placeholder="Organisation" disabled />
												</Form.Item>
											</Col>
										</Row>

										<Form.Item name="description" rules={vsmLedgerDetails.validation.description} label="Ledger Description" tooltip="It is used to further describe the Ledger for what it is used for. Ledgers  are used to store data for Transaction Models, Journals, Transactions, Journal Entries, Cash Transactions for a specific Organisation.">
											<Input.TextArea autoSize={{ minRows: 3 }} placeholder="Ledger Description" maxLength={1000} />
										</Form.Item>

										<Row gutter={24}>
											<Col span={12}>
												<Form.Item name="currency_id" rules={vsmLedgerDetails.validation.currency} label="Base Currency" required tooltip="Base Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger.">
													<Select showSearch placeholder="Select Currency"
														optionFilterProp="children"
														onChange={handleChange}
														filterOption={(input, option) =>
															option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														allowClear
													>
														{
															(currency_list && currency_list.map((item, index) => (
																<Select.Option key={index} value={item.id}>{item.currency_code}</Select.Option>
															)))
														}
													</Select>
												</Form.Item>
											</Col>

											<Col span={12}>
												<Form.Item name="timezone_id" rules={vsmLedgerDetails.validation.timezone} label="Timezone" required tooltip="Time Zone is a geographic region within which the same standard time is used.">
													<Select showSearch placeholder="Select Timezone"
														optionFilterProp="children"
														onChange={handleChange}
														filterOption={(input, option) =>
															option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														allowClear
													>
														{
															(timezone_list && timezone_list.map((item, index) => (
																<Select.Option key={index} value={item.id}>{item.timezone}</Select.Option>
															)))
														}
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<Form.Item name="logo_url" label="Logo" tooltip="Logo is a symbol or other small design adopted by an organisation to identify its products.">
											<Upload
												accept=".png, .jpg, .jpeg"
												fileList={fileList}
												onRemove={onRemoveImage}
												className="hideeyeicon"
												listType="picture-card"
												multiple={false}
												showUploadList={true}
												{...eventProps}
											>
												{fileList.length >= 1 ? null : uploadButton}
											</Upload>
										</Form.Item>

										<div className="text-center">
											{UserStore.checkPermission(69, 'edit') && <Button disabled={disabled || imgDisabled} className="mr-10" loading={saving} htmlType="submit" type="primary">Save</Button>}
											<Button onClick={() => { close() }}>Cancel</Button>
										</div>

										<OrganisationEditComponent transactionId={transactionId} setId={setId} visible={editModal} close={closeEditModal} />
									</Form>
								</Card>
							)
						)
					)
				}
			</Spin >
		</>
	)
})

export default LedgerDetail
