import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Card, Button } from 'antd'
import { CreditCardFilled } from '@ant-design/icons'
import useStore from '../store'
import { useTranslation } from 'react-i18next'

const JournalWidget = observer(() => {
	const { t } = useTranslation();
	const { DashboardStore: { getJournalCount, count_journals, setDaysJournalCount, days_journals } } = useStore()

	// get count of journals
	useEffect(() => {
		getJournalCount()
	}, [getJournalCount])

	return (
		<Card className="widget_wrapper">
			<div className="counter__widget">
				<div className="counter__widget__icon"><CreditCardFilled /></div>
				<div className="counter__widget__description">
					<div className="counter__widget__name">{t('DashBoard.Journals')}</div>
					<div className="counter__widget__counts">{count_journals}</div>
				</div>
			</div>
			<div className="counter__widget__footer">
				<div className="counter__widget__buttons">
					<Button onClick={() => setDaysJournalCount(7)} type="link" size="small" className={"counter__widget__button " + (days_journals === 7 ? "cs_active" : "")}>7 {t('Common_Data.Days')}</Button>
					<Button onClick={() => setDaysJournalCount(30)} type="link" size="small" className={"counter__widget__button " + (days_journals === 30 ? "cs_active" : "")}>30 {t('Common_Data.Days')}</Button>
					<Button onClick={() => setDaysJournalCount(365)} type="link" size="small" className={"counter__widget__button " + (days_journals === 365 ? "cs_active" : "")}>12 {t('Common_Data.Months')}</Button>
				</div>
			</div>
		</Card>
	)
})

export default JournalWidget
