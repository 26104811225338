import React from 'react'
import { observer } from "mobx-react";
import useStore from '../../../../../../store';
import { ActionRenderer, gridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../../config/LocalGridConfig'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../../../config/messages'

const ListComponent = observer((props) => {
	const { currentTab, openEditModal } = props
	const { PeopleStore } = useStore()

	return (
		<>
			<div className="ag-theme-alpine">
				<AgGridReact
					domLayout="autoHeight"
					pagination={false}
					rowData={PeopleStore.list_banks}
					modules={AllModules}
					columnDefs={gridOptions.columnDefs}
					defaultColDef={LocalGridConfig.defaultColDef}
					columnTypes={LocalGridConfig.columnTypes}
					overlayNoRowsTemplate={vsmCommon.noRecord}
					frameworkComponents={{
						ActionRenderer, currentTab, openEditModal
					}}
					onGridReady={PeopleStore.setupGridBanks}
					onColumnResized={PeopleStore.onChildnGridChanged}
					onColumnMoved={PeopleStore.onChildnGridChanged}
					onColumnPinned={PeopleStore.onChildnGridChanged}
					gridOptions={LocalGridConfig.options}
				/>
			</div>
		</>
	)
})

export default ListComponent
