import React, { useState } from 'react'
import { Form, Button } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import { vsmRegularExpression, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { RegularExpressionStore: { AddData } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// Make function call to add new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmRegularExpression.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return (
		<FormComponent
			form={form}
			form_id="addform"
			title="New Regular Expression"
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			formButtons={[
				<Button key="1" form='addform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		/>
	)
})

export default AddComponent
