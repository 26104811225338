import React from 'react'
import { observer } from 'mobx-react'
import { Button, Modal, Table } from 'antd'
import useStore from '../../../../store'

const ViewLedgerComponent = observer((props) => {

	const { UserManagementStore } = useStore()
	const Store = {
		UserManagement: UserManagementStore,
	}

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'First Name',
			dataIndex: 'first_name',
			key: 'first_name',
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			key: 'last_name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'User Add On',
			dataIndex: 'created_at',
			key: 'created_at',
		},
	];

	const columnsForRoleLedger = [
		{
			title: 'Ledger',
			render: (text, record) => {
				return record.ledger_name
			},
			width: 150,
		},
		{
			title: 'role',
			render: (text, record) => {
				return record.role_name
			},
			width: 150,
		},

	];

	// reset form and close add form
	const close = () => {
		props.close()
	}

	return Store.UserManagement.view_ledger_list && (
		<Modal
			centered
			title="View Ledgers"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={800}
		>
			<Table
				dataSource={Store.UserManagement.view_ledger_list}
				pagination={false} columns={columns}
				bordered={true} />

			<Table
				dataSource={Store.UserManagement.view_ledger_list[0].ledgers_role}
				pagination={false} columns={columnsForRoleLedger}
				bordered={true} />
		</Modal >
	)
})

export default ViewLedgerComponent
