import React from 'react'
import { Spin } from 'antd'

const ContentLoader = () => {
	return (
		<div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
			<Spin size="large" />
		</div>
	)
}

export default ContentLoader
