import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Col, Row, Input } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../../store'
import { vsmTransactionFields, vsmNotify } from '../../../../../config/messages'

const UnpublishComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionFieldStore: { TogglepublishData, unpublishValues, relation_list } } = useStore()
	const [saving, setSaving] = useState()
	const [unpublishButton, ToggleUnpublishButton] = useState(true)

	// Made function call to unpublish existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = unpublishValues.id
		data.status = false
		delete data["unpublish"]
		TogglepublishData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionFields.unpublish
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			ToggleUnpublishButton(true)
		})
	}

	// Handle on field change
	const handleFieldChange = () => {
		if (form.getFieldsValue().unpublish.toLowerCase() === "unpublish") {
			ToggleUnpublishButton(false)
		} else {
			ToggleUnpublishButton(true)
		}
	}

	// Set values to unpublish
	useEffect(() => {
		ToggleUnpublishButton(true)
	}, [unpublishValues, form])

	return (
		<Modal
			title={`Additional Confirmation to Unpublish`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='unpublishform' loading={saving} htmlType="submit" type="primary" danger disabled={unpublishButton}>Unpublish</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					ToggleUnpublishButton(true)
					props.close()
				}}>Cancel</Button>
			]}
		>

			<Form form={form} id="unpublishform" onFinish={handleSubmit}
				labelCol={{ span: 6 }}
			>
				<>
					<Row>
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>You need to confirm few additional items as listed below, to unpublish this Transaction Field as it is being used in one or more data :</h3>
							<ul>
								<span>
									<li>
										<span>
											Algorithm :
											{
												(
													relation_list && relation_list.algorithms.length > 0 ?
														(relation_list.algorithms.map((item) => (
															<span key={item.id}> {item.name}</span>
														)).reduce((prev, curr) => [prev, ', ', curr])) : <span> {""} </span>
												)
											}
										</span>
									</li>
								</span>

								<span>
									<li>
										<span>
											Associated fields with those algorithms :
											{
												(
													relation_list && relation_list.associated_field.length > 0 ?
														(relation_list.associated_field.map((item) => (
															<span key={item.id}> {item.name}</span>
														)).reduce((prev, curr) => [prev, ', ', curr])) : <span> {""} </span>
												)
											}
										</span>
									</li>
								</span>

								<span>
									<li>
										<span>
											Model :
											{
												(
													relation_list && relation_list.model.length > 0 ?
														(relation_list.model.map((item) => (
															<span key={item.id}> {item.name}</span>
														)).reduce((prev, curr) => [prev, ', ', curr])) : <span> {""} </span>
												)
											}
										</span>
									</li>
								</span>

								<span>
									<li>
										<span>
											Mapping :
											{
												(
													relation_list && relation_list.mappings.length > 0 ?
														(relation_list.mappings.map((item) => (
															<span key={item.id}> {item.name}</span>
														)).reduce((prev, curr) => [prev, ', ', curr])) : <span> {""} </span>
												)
											}
										</span>
									</li>
								</span>

							</ul>
							<h3>If you still want to unpublish it anyway,</h3>
							<ul>
								<li>Field will get removed from Mappings</li>
								<li>Models will be unpublished and journal lines associated with this Field will be removed</li>
								<li>Algorithm and associated fields will be unpublished</li>
							</ul>
							<h3>You can go and fix these items later to republish them. You cannot undo this action.</h3>

							<Form.Item style={{ marginBottom: 0 }} name="unpublish">
								<Input placeholder='Type "UNPUBLISH" to Confirm' onChange={handleFieldChange} />
							</Form.Item>
						</Col>
					</Row>
				</>
			</Form>
		</Modal>
	)
})

export default UnpublishComponent
