import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const RepeaterElement = observer(({ label, name, placeholder, tooltip = null, rules }) => {

	return (
		<div>
			<Form.List name={name} initialValue={[null]}>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							return (
								<div key={field.key}>
									<Row gutter={24}>
										<Col flex={1}>
											<InputComponent
												label={label}
												name={[field.name]} key={[field.key]}
												placeholder={placeholder}
												tooltip={tooltip}
												rules={rules}
											/>
										</Col>
										<Col className="text-right pt-30">
											{
												(fields.length > 1) && (
													<Button type="danger" onClick={() => remove(field.name)} icon={<MinusOutlined />}></Button>
												)
											}
											{(index === (fields.length - 1)) && (
												<Button className={fields.length > 1 ? 'ml-10' : ''} type="primary" onClick={() => add()} icon={<PlusOutlined />}></Button>
											)}
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default RepeaterElement
