import Axios from "axios";
import { action, decorate, observable } from "mobx";
import GridConfig from "../../config/GridConfig";
import { convertTextToID, globalStatus } from "../../utils/GlobalFunction";

export default class CashToCashReconciliationStore {
    list_data = null
    per_page = GridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    total_cte_count = 0
    reconcile_cash_to_cash_processed_count = 0
    filter_currency = []
    filter_bank = []
    loader = false
    filter_status = [
        { id: '0', status_name: 'Pending' },
        { id: '1', status_name: 'Imported / added' },
        { id: '2', status_name: 'Auto Identified' },
        { id: '3', status_name: 'Manually Verified' },
        { id: '4', status_name: 'Problematic / Unidentified' },
        { id: '5', status_name: 'Problematic / Identified' },
        { id: 6, status_name: 'Internal / Identified' }
    ]

    reconcile_transaction_status = [
        { id: 0, reconcile_transaction_status: 'Pending' },
        { id: 1, reconcile_transaction_status: 'Internal / Identified ' },
        { id: 2, reconcile_transaction_status: 'Internal / Reconciled' },
        { id: 3, reconcile_transaction_status: 'Payment / Identified' },
        { id: 4, reconcile_transaction_status: 'Payment / Recognised' },
        { id: 5, reconcile_transaction_status: 'Receipts  / Identified' },
        { id: 6, reconcile_transaction_status: 'Receipts / Recognised' },
        { id: 9, reconcile_transaction_status: 'Pending' },
        { id: 10, reconcile_transaction_status: 'Pending' },
        { id: 21, reconcile_transaction_status: 'Charges' },
        { id: 22, reconcile_transaction_status: 'Interests' },
    ]

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = GridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.gridOptionsWrapper.setProperty('cacheBlockSize', page);
        this.setupGrid(this.agGrid)
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    getCurrencyFilter = () => {
        return Axios.get(`getCurrencyFilter`).then(data => {
            this.filter_currency = data.data
            return data
        }).catch(data => {
            Promise.reject(data)
        })
    }

    getBankFilter = () => {
        return Axios.get(`getBankFilter`).then(data => {
            this.filter_bank = data.data
            return data
        }).catch(data => {
            Promise.reject(data)
        })
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        const { api } = params
        let columnConfig = localStorage.getItem('cashTocash_reconciliation_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
        var datasource = this.createDatasource(GridConfig.options)
        api.setServerSideDatasource(datasource)
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('cashTocash_reconciliation_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                let columnConfig = localStorage.getItem('cashTocash_reconciliation_grid')
                if (columnConfig) {
                    this.onGridChanged(params)
                }
                var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }

                if (filter_data.final_filter['status_name']) {
                    filter_data.final_filter['status_name'].values = convertTextToID(filter_data.final_filter['status_name'], this.filter_status, 'status_name', 'id')
                }

                if (filter_data.final_filter['reconciliation_status']) {
                    filter_data.final_filter['reconciliation_status'].values = convertTextToID(filter_data.final_filter['reconciliation_status'], this.reconcile_transaction_status, 'reconcile_transaction_status', 'id')
                }

                if (filter_data.final_filter['currency_code']) {
                    filter_data.final_filter['currency_code'].values = convertTextToID(filter_data.final_filter['currency_code'], this.filter_currency, 'currency_code', 'id')
                }

                if (filter_data.final_filter['bank_name']) {
                    filter_data.final_filter['bank_name'].values = convertTextToID(filter_data.final_filter['bank_name'], this.filter_bank, 'name', 'id')
                }

                var payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
                }

                this.getList(payload).then((data) => {
                    if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.data, data.total)
                    let columnConfig = localStorage.getItem('cashTocash_reconciliation_grid')
                    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                        if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
                        }
                    }
                })
            }
        }
    }

    handleReset = () => {
        this.advanceFilter = null
        localStorage.removeItem('cashTocash_reconciliation_grid')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)

    }


    // call api to get records
    getList = (payload = {}) => {
        return Axios.post(`cash/reconciliation/cash/to/cash/list`, payload).then(({ data }) => {
            if (data.data.length) {
                data.data.forEach((item) => {
                    item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
                    item.reconciliation_status = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status)
                })
            }
            this.list_data = data.data
            this.total = data.total
            this.current_page = data.current_page
            this.reconcile_cash_to_cash_processed_count = data.reconcile_cash_to_cash_processed_count
            this.total_cte_count = data.total_cte_count
            return data
        })
    }

    DoReconcile = () => {
        this.loader = true
        return Axios.get(`cash/reconciliation/bank/to/bank`).then(({ data }) => {
            this.loader = false
            return data
        }).catch(() => {
            this.loader = false
        })
    }
}

decorate(CashToCashReconciliationStore, {
    list_data: observable,
    per_page: observable,
    current_page: observable,
    total: observable,
    reconcile_cash_to_cash_processed_count: observable,
    total_cte_count: observable,
    loader: observable,
    setPageSize: action,
    setupGrid: action,
    onGridChanged: action,
    createDatasource: action,
    getList: action,
    DoReconcile: action,
    getBankFilter: action,
})