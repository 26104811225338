import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const OrgPeopleBankAccNoElement = observer(({ onChange }) => {
	return (
		<Form.List name="org_people_bank_acc_no">
			{
				(fields, { add, remove }) => {
					return fields && fields.map((field, index) => {
						return (
							<div className="w-100" key={field.key}>
								<Row gutter={10}>
									<Col flex={1}>
										<InputComponent
											required name={[field.name]} key={[field.key]}
											onChange={onChange}
											placeholder="Org/People Bank Account No"
											tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN). This will be searched among Organisations or People."
										/>
									</Col>
									<Col>
										<div className="d-flex">
											{
												(fields.length > 1) && (
													<Button type="danger" onClick={() => remove(field.name)} icon={<MinusOutlined />}></Button>
												)
											}
											{(index === (fields.length - 1)) && (
												<Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={() => add()} icon={<PlusOutlined />} />
											)}
										</div>
									</Col>
								</Row>
							</div>
						)
					})
				}
			}
		</Form.List >
	)
})

export default OrgPeopleBankAccNoElement
