import React, { useState, useEffect } from 'react'
import { Form, Button } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../../store'
import { vsmManualJournalLog, vsmNotify } from '../../../../../config/messages'

const ReverseComponent = observer((props) => {
	const [form] = Form.useForm()
	const { ManualJournalLogStore, ManualJournalLogStore: { ReverseData, reverseValues } } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState()

	// Make function call to edit existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = reverseValues.id
		data.transaction_id = (reverseValues.transaction && reverseValues.transaction.id) || null
		data.type = data.type === "Debit" ? "Credit" : "Debit"
		ReverseData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmManualJournalLog.reverse
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to edit
	useEffect(() => {
		if (reverseValues && props.visible) {
			form.setFieldsValue({
				model_id: reverseValues.model_id,
				transactions_type_id: reverseValues.transactions_type_id,
				journal_id: reverseValues.journal_id,
				type: reverseValues.credit_value && parseFloat(reverseValues.credit_value) > 0 ? "Credit" : "Debit",
				amount: reverseValues.credit_value && parseFloat(reverseValues.credit_value) > 0 ? reverseValues.credit_value : reverseValues.debit_value,
				description: reverseValues.description,
			})
		}
	}, [reverseValues, props.visible, form])

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		ManualJournalLogStore.dropdown = [{}]
		ManualJournalLogStore.mainDropdown = null
	}

	return reverseValues ? (
		<FormComponent
			form={form}
			form_id="reverseform"
			title={`Reverse Manual Journal Entry - #${reverseValues.id} ${reverseValues.model ? "- " + reverseValues.model.model_name : ""}`}
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			currentValues={reverseValues}
			formButtons={[
				<Button key="1" form='reverseform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		/>
	) : null
})

export default ReverseComponent
