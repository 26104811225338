import React, { useState } from 'react'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import { Form, Modal, Button } from 'antd'
import useStore from '../../../../../store'
import { vsmNotify, vsmPeopleRoles } from '../../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleRolesStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	// reset form and close add form
	const close = () => {
		PeopleRolesStore.relates_roles = null
		form.resetFields()
		setDisabled(true)
		props.close()
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			setDisabled(true)
		})
	}

	const handleSubmit = (data) => {
		PeopleRolesStore.AddData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmPeopleRoles.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	return (
		<Modal
			destroyOnClose={true}
			centered
			title="New People Role"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<FormComponent
				form={form}
				onChange={handleChange}
				handleSubmit={handleSubmit}
				tab={props.tab}
			/>
		</Modal>
	)
})

export default AddComponent
