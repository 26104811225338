import React, { useEffect, useState } from 'react'
import { Form, Button, DatePicker, Drawer } from 'antd'
import { observer } from 'mobx-react';
import moment from 'moment';
import useStore from '../../../../../store';
import { vsmCommon, vsmNotify, vsmTransactionModels } from '../../../../../config/messages';
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import NumberFormat from 'react-number-format';

const VerifyTransactionModal = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState(false)
	const [loader, setLoader] = useState(false);
	const [gridApi, setGridApi] = useState(null);
	const { TransactionModelStore, TransactionModelStore: { executeTransaction, getTransactionsList, transactions_list } } = useStore()

	const { RangePicker } = DatePicker;

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		let array = gridApi && gridApi.api.getSelectedNodes()
		if (array.length > 0) {
			let selectedId = [];
			array.map((item) => (
				selectedId.push(item.data.id)
			))

			var formdata = {
				model_id: props.verifyValues.id,
				type_field_mapping_id: props.verifyValues.type_field_mapping_id,
				from_date: form.getFieldsValue().date_range[0].format("YYYY-MM-DD"),
				to_date: form.getFieldsValue().date_range[1].format("YYYY-MM-DD"),
				transaction_ids: selectedId
			}
			setSaving(true)
			executeTransaction(formdata).then(() => {
				close()
				gridApi.api.deselectAll()
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => {
				setSaving(false)
			})
		} else {
			vsmNotify.error({
				message: vsmTransactionModels.selectedRecord
			})
		}
	}

	// Set the form values to verify & call transaction list api
	useEffect(() => {
		if (props.visible && props.verifyValues && !transactions_list) {
			const currentDate = new Date()
			const lastMonthDate = new Date()
			lastMonthDate.setMonth(lastMonthDate.getMonth() - 3)

			form.setFieldsValue({
				date_range: [moment(lastMonthDate), moment(currentDate)]
			})

			var payload = {
				model_id: props.verifyValues.id,
				from_date: moment(lastMonthDate).format("YYYY-MM-DD"),
				to_date: moment(currentDate).format("YYYY-MM-DD"),
				type_field_mapping_id: props.verifyValues.type_field_mapping_id
			}
			getTransactionsList(payload)
		}
	}, [form, props.visible, props.verifyValues, getTransactionsList, transactions_list])

	// on change date call api transaction list
	const handleDateRange = (date) => {
		setLoader(true)
		if (form.getFieldsValue().date_range) {
			let formdata = {
				model_id: props.verifyValues.id,
				type_field_mapping_id: props.verifyValues.type_field_mapping_id,
				from_date: form.getFieldsValue().date_range[0].format("YYYY-MM-DD"),
				to_date: form.getFieldsValue().date_range[1].format("YYYY-MM-DD")
			}
			getTransactionsList(formdata).then(() => {
				setLoader(false)
			})
		}
	}

	// modal close function
	const close = () => {
		props.close()
	}

	// Grid ready function
	const onGridReady = params => {
		setGridApi(params)
		const colIds = params.columnApi
			.getAllDisplayedColumns()
			.map(col => col.getColId());

		params.columnApi.autoSizeColumns(colIds, false)
	};

	// select all record
	const selectAll = () => {
		gridApi.api.selectAll()
	};

	// select first 1000 record
	const select1000 = () => {
		gridApi.api.forEachNode(function (node) {
			node.setSelected(node.rowIndex < 1000 ? true : false);
		});
	};

	// select first 100000 record
	const select10000 = () => {
		gridApi.api.forEachNode(function (node) {
			node.setSelected(node.rowIndex < 10000 ? true : false);
		});
	};

	// modal update function for no record found message
	const onModelUpdated = ($event) => {
		if (gridApi && gridApi.api.rowModel.rowsToDisplay.length === 0) {
			gridApi.api.showNoRowsOverlay();
		}
		if (gridApi && gridApi.api.rowModel.rowsToDisplay.length > 0) {
			gridApi.api.hideOverlay();
		}
	}

	return props.verifyValues ? (
		<Drawer
			title="Verify With Existing Transaction"
			visible={props.visible}
			onClose={close}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button form='addform' loading={saving} htmlType="submit" type="primary">Execute model on Selected Transaction/s.</Button>
					<Button className="ml-10" htmlType="button" onClick={() => { close() }}>Cancel</Button>
				</div>
			]}
			width={"75%"}
		>
			<Form form={form}
				id="addform"
				onFinish={handleSubmit}
			>

				<div className="tradedcenter mt-20">
					<Form.Item label="Posted On : ">
						<Form.Item name="date_range">
							<RangePicker allowClear={false} />
						</Form.Item>
					</Form.Item>
					<div className="ml-10">
						<Button loading={loader} type="primary" onClick={() => handleDateRange()}>Fetch Transactions</Button>
					</div>
				</div>

				<h4 className="text-center mb-20">
					<span>Total <NormalFormat value={transactions_list && transactions_list.length} /> Transactions Found on this filter : </span>
					<Button className="mr-10" type="primary" onClick={() => { selectAll() }}>Select All on This Page</Button>
					<Button className="mr-10" type="primary" onClick={() => { select1000() }}>Select First {transactions_list && transactions_list.length < 1000 ? transactions_list.length : "1,000"}</Button>
					<Button type="primary" onClick={() => { select10000() }}>Select All {transactions_list && transactions_list.length < 10000 ? transactions_list.length : "10,000"}</Button>
				</h4>

				<div className="ag-theme-alpine">
					<AgGridReact
						domLayout="autoHeight"
						modules={[ClientSideRowModelModule]}
						defaultColDef={defaultColDef}
						suppressRowClickSelection={true}
						rowSelection={'multiple'}
						columnDefs={columns}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						rowData={transactions_list}
						onGridReady={onGridReady}
						onModelUpdated={onModelUpdated}
						onColumnResized={TransactionModelStore.onChildnGridChanged}
						onColumnMoved={TransactionModelStore.onChildnGridChanged}
						onColumnPinned={TransactionModelStore.onChildnGridChanged}
						onSortChanged={TransactionModelStore.onChildnGridChanged}
					/>
				</div>

			</Form>
		</Drawer>
	) : null
})

export default VerifyTransactionModal

const NormalFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} />
	)
}

const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
	)
};

const columns = [
	{
		headerCheckboxSelection: true,
		checkboxSelection: true,
		filter: false,
		sortable: false,
		floatingFilter: false,
		width: 70,
		suppressMenu: true
	},
	{
		headerName: '#ID',
		field: 'id',
	},
	{
		headerName: 'Exchange ID',
		field: 'exchange_ref_id',
		valueGetter: function (params) {
			if (params.data && params.data.exchange_ref_id) {
				return params.data.exchange_ref_id
			} else {
				return null
			}
		},
	},
	{
		headerName: 'Transaction Ref',
		field: 'reference',
		valueGetter: function (params) {
			if (params.data && params.data.reference) {
				return params.data.reference
			} else {
				return null
			}
		},
	},
	{
		headerName: 'Traded',
		field: 'traded_on',
		type: 'rightAligned',
		valueGetter: function (params) {
			if (params.data && params.data.traded_on) {
				return params.data.traded_on
			} else {
				return null
			}
		},
	},
	{
		headerName: 'Posted',
		field: 'posted_on',
		type: 'rightAligned',
		valueGetter: function (params) {
			if (params.data && params.data.posted_on) {
				return params.data.posted_on
			} else {
				return null
			}
		},
	},
	{
		headerName: 'Face Value',
		field: 'face_value',
		type: 'rightAligned',
		valueGetter: function (params) {
			if (params.data && params.data.face_value) {
				return params.data.face_value
			} else {
				return null
			}
		},
		cellRendererFramework: function (data) {
			return data.data && <CurrencyFormat value={data.data.face_value} />
		},
	},
];

const defaultColDef = {
	editable: false,
	resizable: true,
	sortable: true,
	lockPosition: true,
	filter: 'agTextColumnFilter',
	filterParams: {
		suppressAndOrCondition: true,
	},
	cellStyle: { borderRight: '1px solid #dde2eb' },
	floatingFilter: true,
};
