import Axios from "axios";
import { decorate, observable, action } from "mobx"
import moment from "moment";
import { globalStatus } from "../../utils/GlobalFunction";

export default class QueryBankVsCashStore {
	list_data = null
	agGrid = null

	days_list_data = null
	daysAgGrid = null

	singleDaysListData = null
	singleDaysAgGrid = null

	fYear = []
	bankAccList = null

	listTempPayload = null
	getChildListPayload = null
	singleGetChildListPayload = null

	constructor() {
		let currentYear = moment().year()
		let dummyObj = { text: `Jan ${currentYear} - Dec ${currentYear}`, value: `Jan-${currentYear}:Dec-${currentYear}` }
		this.fYear.push(dummyObj)
	}

	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('query_bank_cash')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// reset all the server side filters
	handleReset = () => {
		localStorage.removeItem('query_bank_cash')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('query_bank_cash', JSON.stringify(params.columnApi.getColumnState()))
	}

	// call api to get records
	getList = (payload = {}) => {
		this.listTempPayload = payload ? payload : this.listTempPayload
		let temp = this.listTempPayload ? this.listTempPayload : payload
		return Axios.post(`report/query-bank-vs-cash`, temp).then(({ data }) => {
			if (temp.generate_on === 'date_range') {
				this.days_list_data = data.data
			} else {
				this.list_data = data.data
			}
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	getBankAccList = (payload = {}) => {
		return Axios.post('cte/transaction-classification/get/filtere-bank-accounts', payload).then(({ data }) => {
			this.bankAccList = data.data.banks_accounts && data.data.banks_accounts
			return data.data.banks_accounts
		})
	}

	getFYear = () => {
		return Axios.get('report/financial/year').then(({ data }) => {
			this.fYear = data.data
			return data.data
		})
	}

	daysSetupGrid = (params) => {
		this.daysAgGrid = params
		let columnConfig = localStorage.getItem('days_query_bank_cash')
		if (this.daysAgGrid && this.daysAgGrid.columnApi && columnConfig) {
			this.daysAgGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// reset all the server side filters
	daysHandleReset = () => {
		localStorage.removeItem('days_query_bank_cash')
		this.daysAgGrid.api.setFilterModel(null)
		this.daysAgGrid.api.setSortModel(null)
		this.daysAgGrid.columnApi.resetColumnState()
		this.daysAgGrid.api.onFilterChanged(null)
	}

	// Set column width after resizing colums
	onDaysGridChanged = (params) => {
		localStorage.setItem('days_query_bank_cash', JSON.stringify(params.columnApi.getColumnState()))
	}

	// call api to get records
	getChildList = (payload = {}) => {
		this.getChildListPayload = payload ? payload : this.getChildListPayload
		let temp = this.getChildListPayload ? this.getChildListPayload : payload
		return Axios.post(`report/query-bank-vs-cash/details`, temp).then(({ data }) => {
			this.days_list_data = data.data
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// 3rd step drawer
	singleDaysSetupGrid = (params) => {
		this.singleDaysAgGrid = params
		let columnConfig = localStorage.getItem('cte_transaction_grid_query_bank')
		if (this.singleDaysAgGrid && this.singleDaysAgGrid.columnApi && columnConfig) {
			this.singleDaysAgGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// reset all the server side filters
	singleDaysHandleReset = () => {
		localStorage.removeItem('cte_transaction_grid_query_bank')
		this.singleDaysAgGrid.api.setFilterModel(null)
		this.singleDaysAgGrid.api.setSortModel(null)
		this.singleDaysAgGrid.columnApi.resetColumnState()
		this.singleDaysAgGrid.api.onFilterChanged(null)
	}

	// Set column width after resizing colums
	onSingleDaysGridChanged = (params) => {
		localStorage.setItem('cte_transaction_grid_query_bank', JSON.stringify(params.columnApi.getColumnState()))
	}

	// call api to get records
	singleGetChildList = (payload = {}) => {
		this.singleGetChildListPayload = payload ? payload : this.singleGetChildListPayload
		let temp = this.singleGetChildListPayload ? this.singleGetChildListPayload : payload
		return Axios.post(`report/query-bank-vs-cash/transaction-details`, temp).then(({ data }) => {
			data.data.data.forEach((item, index) => {
				item.srno = index + 1
				item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
				item.reconciliation_status_table = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status)
			});
			this.singleDaysListData = data.data.data
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	updateCashTransation = (payload) => {
		return Axios.post('cash/transaction/noteupdate', payload).then((data) => {
			return data
		})
	}
}

decorate(QueryBankVsCashStore, {
	list_data: observable,
	agGrid: observable,
	fYear: observable,
	bankAccList: observable,
	daysAgGrid: observable,
	days_list_data: observable,
	singleDaysListData: observable,
	singleDaysAgGrid: observable,
	listTempPayload: observable,
	getChildListPayload: observable,
	singleGetChildListPayload: observable,
	setupGrid: action,
	handleReset: action,
	onGridChanged: action,
	getList: action,
	getFYear: action,
	getBankAccList: action,
	daysSetupGrid: action,
	daysHandleReset: action,
	onDaysGridChanged: action,
	getChildList: action,
	singleDaysSetupGrid: action,
	singleDaysHandleReset: action,
	onSingleDaysGridChanged: action,
	singleGetChildList: action,
	updateCashTransation: action
})