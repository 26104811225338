import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmImport, vsmNotify } from '../../../../config/messages'


const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { ImportStore: { deleteValues, DeleteData } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)

	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmImport.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Handle disable / enable button on field change
	const handleChange = () => {
		if (form.getFieldsValue().delete.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	// Reset form and close delete form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return deleteValues && (
		<>
			<Modal
				centered
				title={`Delete Import - #${deleteValues.id} ${deleteValues.type_field_mapping && deleteValues.type_field_mapping.mapping_name}`}
				visible={props.visible}
				onCancel={close}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1" disabled={disabled} form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Yes, Delete</Button>,
					<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
				]}
			>
				<Form form={form} id="deleteform" onFinish={handleSubmit}>
					{
						<>
							<Form.Item name="transfer_id" style={{ display: 'none' }}>
								<Input type="hidden" />
							</Form.Item>
							<Row align="middle">
								<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
								<Col span={20}>
									<h3>Are you sure you want to Delete Import entry and Remove all imported transactions?</h3>
									<Form.Item style={{ marginBottom: 0 }} name="delete">
										<Input placeholder='Type "CONFIRM" to confirm' onChange={handleChange} />
									</Form.Item>
								</Col>
							</Row>
						</>
					}

				</Form>
			</Modal>
		</>
	)
})

export default DeleteComponent
