import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer } from 'antd'
import useStore from '../../../../store'
import PreviewDetailTableElement from '../elements/PreviewDetailTableElement'
import RecordPerPage from '../../../../components/RecordPerPage'

const PreviewDetailComponent = observer((props) => {
	const { SavedReportStore } = useStore()
	useEffect(() => {
		var payload = SavedReportStore.formatFormData({
			...SavedReportStore.previewReportValues,
			...props.payload,
			action: 'child_data'
		})
		SavedReportStore.PreviewDetailPayload = { ...payload, ...props.payload }
	}, [SavedReportStore, props.payload])
	return (
		<Drawer
			onClose={props.close}
			title={props.title}
			destroyOnClose={true}
			visible={props.visible}
			onCancel={props.close}
			width="46%"
			extra={[
				<Button key="1" loading={SavedReportStore.exportLoading} onClick={SavedReportStore.exportGlobleData} disabled={!SavedReportStore.previewChildReportList}>Export CSV</Button>,
				<Button key='2' className='ml-15 mr-15' type={SavedReportStore.ZeroRecord ? 'default' : 'primary'}
					onClick={() => {
						SavedReportStore.ZeroRecord = !SavedReportStore.ZeroRecord
						SavedReportStore.setupPreviewDetailGrid(SavedReportStore.agPreviewDetailGrid)
					}}>View Non Zero Postings</Button>,
				<span className='mr-40'>
					<RecordPerPage className='mr-40' key="3" cteLogOptions={true} defaultValue={SavedReportStore.previewDetailGrid_per_page + ' per page'} onChange={SavedReportStore.setPreviewDetailGrid} />
				</span>
			]}
		>
			<PreviewDetailTableElement PreviewDetailPinnedRow={SavedReportStore.PreviewDetailPinnedRow} onGridReady={SavedReportStore.setupPreviewDetailGrid} />
		</Drawer>
	)
})

export default PreviewDetailComponent
