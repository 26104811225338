import { Form, Button, Modal, Row, Col, Upload, message } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import useStore from '../../../../store';
import { vsmFXCurrencies, vsmNotify } from '../../../../config/messages';
import sampleImportJournal from '../../../../assets/import-sample-fxcurrencies.csv'
const { Dragger } = Upload;

const ImportComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fileList, setFileList] = useState([])
	const [errorTable, setErrorTable] = useState(false)
	const [successCount, setSuccessCount] = useState()
	const [errorFileURL, setErrorFileURL] = useState()
	const { FXCurrenciesStore: { ImportFile } } = useStore()

	const handleSubmit = (data) => {
		setSaving(true)
		const formData = new FormData();
		formData.append('file', fileList[0]);
		ImportFile(formData).then((res) => {
			close()
			if (res && res.error_count !== 0) {
				setSuccessCount(res.success_count)
				setErrorFileURL(res.csv_link)
				setErrorTable(true)
			} else {
				vsmNotify.success({
					message: vsmFXCurrencies.import
				})
			}
		}).catch(e => {
			if (e.errors && e.errors.file && e.errors.file[0]) {
				message.error(e.errors.file[0]);
			}

			if (e.errors && e.errors[0] && e.errors[0].errors && e.errors[0].errors[0]) {
				message.error(e.errors[0].errors[0]);
			}
		}).finally(() => setSaving(false))
	}

	const close = () => {
		form.resetFields()
		setFileList([])
		setDisabled(true)
		setErrorTable(false)
		props.close()
	}

	const beforeUpload = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 20;
		if (!isCSV) {
			message.error('You can only upload CSV file!');
			setDisabled(true)
			return true;
		} else if (!isLt20M) {
			message.error('File must be  smaller then 20MB');
			setDisabled(true)
			return true;
		} else {
			setFileList([file])
			return false
		}
	}

	const handleFileChange = info => {
		if (info.fileList) {
			if ((info.file.status && info.file.status === 'error') || info.fileList.length <= 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		} else {
			setDisabled(true)
		}
	}

	return (
		<>
			<Modal title="Import Exchange Rate History" destroyOnClose={true}
				centered
				visible={props.visible}
				onCancel={close}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="1" form='importform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Import</Button>,
					<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
				]}
				width={700}>
				<Form form={form} id="importform" onFinish={handleSubmit} encType="multipart/form-data"
					acceptCharset="UTF-8">
					<>
						<Row gutter={24}>
							<Col span={12}>
								<Col>
									<Dragger
										accept=".csv"
										height={300}
										fileList={fileList}
										onRemove={() => setFileList([])}
										beforeUpload={beforeUpload}
										onChange={handleFileChange}
										name="file"
										showUploadList={true}
										multiple={false}
										action={process.env.React_APP_API_URL + 'journal/checkvalidfile/'}
										headers={{
											Authorization: 'Bearer ' + localStorage.getItem('token')
										}}
									>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">Drag your file here or click to upload</p>
									</Dragger>
								</Col>
							</Col>
							<Col span={12}>
								<h3>Please make sure file matches following criteria:</h3>

								<ul>
									<li>File format should be CSV.</li>
									<li>Maximum 20 MB file size allowed.</li>
									<li>Minimum 1 record and Maximum 20000 Records can be there.</li>
									<li>File should have first row for header with correct headings.</li>
									<li>Column count should match with the template file.</li>
									<li>All data is required for all columns for successful entry, otherwise that row entry will be ignored.</li>
								</ul>

								<a href={sampleImportJournal} download rel="noopener noreferrer">Download sample file here.</a>
							</Col>
						</Row>
					</>
				</Form>
			</Modal >

			{/* Modal for Error table after importing file */}
			<Modal title="Import Exchange Rate History"
				centered
				visible={errorTable}
				onCancel={close}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
				footer={[
					<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
				]}
				width={1000}>
				<h3 className="text-center">
					{successCount} Import Exchange Rate Records Successfully Created !<br />
				</h3>
				<div className="text-right mr-20">
					<a href={errorFileURL} rel="noopener noreferrer">Download file here</a>
				</div>
			</Modal>
		</>
	)
})

export default ImportComponent
