import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { vsmNotify, vsmUserManagement } from '../../../../config/messages'

const StatusComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const { UserManagementStore } = useStore()
	const Store = {
		UserManagement: UserManagementStore,
	}

	const handleSubmit = () => {
		setSaving(true)
		var payload = {
			id: Store.UserManagement.statusValues.id,
			Status: 0
		}
		Store.UserManagement.ActivateDeactivateDate(payload).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmUserManagement.deactiveUser
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	return Store.UserManagement.statusValues ? (
		<Modal
			centered
			visible={props.visible}
			onCancel={props.close}
			title={`Deavtivate User - #${Store.UserManagement.statusValues.id} - ${Store.UserManagement.statusValues.first_name}`}
			cancelButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deactivateform' loading={saving} htmlType="submit" type="primary" danger>Deactivate</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deactivateform" onFinish={handleSubmit}>
				{
					<Row align="middle">
						<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
						<Col span={20}>
							<h3>Are you sure you want deactivate this User?</h3>
						</Col>
					</Row>
				}
			</Form>
		</Modal>
	) : null
})

export default StatusComponent
