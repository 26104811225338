import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import ViewComponent from './component/ViewComponent'
import RecordPerPage from '../../../../../components/RecordPerPage'
import UnpublishComponent from './component/UnpublishComponent'
import { vsmNotify, vsmTransactionFieldMapping } from '../../../../../config/messages'
import Unauthorized from '../../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../../components/EnterFullscreen'

const TransactionFieldMapping = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [viewModal, setViewModal] = useState(false)
	const [unpublishModal, setUnpublishModal] = useState(false)
	const { UserStore, TransactionFieldMappingStore, TransactionFieldMappingStore: { per_page, setEditValues, setDeleteValues, setUnpublishValues, setPageSize, TogglepublishData } } = useStore()
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [viewModalOpen, setViewModalOpen] = useState(false)

	const store = {
		Mapping: TransactionFieldMappingStore,
		Fields: TransactionFieldMappingStore
	}

	// Open form for add new transaction type
	const openAddModal = () => {
		store.Fields.getAllTransactionFields()
		setAddModal(true)
	}

	// Close form for add new transaction type
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing transaction type and set values to form
	const openEditModal = (data) => {
		store.Fields.getAllTransactionFields()
		setEditValues(data)
		setEditModal(true)
		setEditModalOpen(true)
	}

	// Open form for edit transaction type
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing transaction type
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// Open view modal
	const openViewModal = (data) => {
		store.Fields.getAllTransactionFields()
		setEditValues(data)
		setViewModal(true)
		setViewModalOpen(true)
	}

	// Close view modal
	const closeViewModal = () => setViewModal(false)

	// On Toggle press open publish/unpublish modal
	const onSwitchChange = (checked, data) => {
		if (!checked) {
			setUnpublishValues(data)
			store.Mapping.agGrid.api.refreshCells({ force: true });
			setUnpublishModal(true)
		} else {
			handlePublish(data)
			setUnpublishModal(false)
		}
	}

	// Handle Publish and call function to publish record
	const handlePublish = (data) => {
		let formdata = {
			id: data.id,
			status: true
		}
		TogglepublishData(formdata).then(() => {
			vsmNotify.success({
				message: vsmTransactionFieldMapping.publish
			})
		}).catch(e => {
			store.Mapping.agGrid.api.refreshCells({ force: true });
		})
	}

	// Close unpublish modal
	const closeUnpublishModal = () => { setUnpublishModal(false) }

	useEffect(() => {
		if (!editModal) {
			setTimeout(() => {
				setEditModalOpen(false)
			}, 400)
		}

		if (!viewModal) {
			setTimeout(() => {
				setViewModalOpen(false)
			}, 400)
		}

	}, [editModal, viewModal])

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('fieldmapping_grid')
		TransactionFieldMappingStore.agGrid.api.setFilterModel(null)
		TransactionFieldMappingStore.agGrid.api.setSortModel(null)
		TransactionFieldMappingStore.agGrid.columnApi.resetColumnState()
		TransactionFieldMappingStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.TransactionFieldMapping.path} />
			{(!UserStore.checkPermission(72, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.TransactionFieldMapping.title}
					extra={[
						UserStore.checkPermission(72, 'add') && <Button key="1" onClick={openAddModal}>New</Button>,
						<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='4' />
					]}
				>
					<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} onSwitchChange={onSwitchChange} openViewModal={openViewModal} />
					<AddComponent visible={addModal} close={closeAddModal} />
					{editModalOpen && <EditComponent visible={editModal} close={closeEditModal} />}
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
					{viewModalOpen && <ViewComponent visible={viewModal} close={closeViewModal} />}
				</PageHeader>
			)}
		</>
	)
})

export default TransactionFieldMapping
