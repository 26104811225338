import React, { useState, useEffect } from 'react'
import { Form, Button } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../../store'
import { vsmManualJournalLog, vsmNotify } from '../../../../../config/messages'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState()
	const { ManualJournalLogStore, ManualJournalLogStore: { EditData, editValues } } = useStore()

	// Make function call to edit existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.transaction_id = (editValues.transaction && editValues.transaction.id) || null
		EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmManualJournalLog.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({
				model_id: editValues.model_id,
				transactions_type_id: editValues.transactions_type_id,
				journal_id: editValues.journal_id,
				type: editValues.credit_value && parseFloat(editValues.credit_value) > 0 ? "Credit" : "Debit",
				amount: editValues.credit_value && parseFloat(editValues.credit_value) > 0 ? editValues.credit_value : editValues.debit_value,
				description: editValues.description,
			})
		}
	}, [editValues, props.visible, form])

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		ManualJournalLogStore.dropdown = [{}]
		ManualJournalLogStore.mainDropdown = null
	}

	return editValues ? (
		<FormComponent
			form={form}
			form_id="editform"
			title={`Edit Manual Journal Entry - #${editValues.id} ${editValues.model ? "- " + editValues.model.model_name : ""}`}
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			currentValues={editValues}
			formButtons={[
				<Button key="1" form='editform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		/>
	) : null
})

export default EditComponent
