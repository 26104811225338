import React, { useEffect, useState } from 'react'
import { Form, Modal, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmRegularExpression } from '../../../../config/messages'
import useStore from '../../../../store'
import debounce from 'lodash/debounce'

const FormComponent = observer(({ title, visible, close, formButtons, form, form_id, setDisabled, handleSubmit }) => {
	const [outputStatus, setOutputStatus] = useState()
	const { RegularExpressionStore } = useStore()

	// Set the state value
	useEffect(() => {
		if (visible) {
			setOutputStatus(null)
		}
	}, [visible])

	const CheckValidateRegex = debounce(() => {
		var expression = form.getFieldValue("regex_value")
		var tempString = form.getFieldValue("string") ? form.getFieldValue("string") : ''
		var payload = {
			string: tempString,
			regex_value: expression
		}
		RegularExpressionStore.ValidateRegex(payload).then((res) => {
			form.setFieldsValue({ output: res ? res : 'No Match Found' })
			setOutputStatus(res.length > 0 ? "green" : "red")
			setDisabled(false)
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
			setOutputStatus(null)
			setDisabled(true)
		})
	}, 500)

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			CheckValidateRegex()
		}).catch(e => {
			setDisabled(true)
		})
	}

	return (
		<Modal
			centered
			title={title}
			visible={visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={formButtons}
		>
			<Form form={form}
				id={form_id}
				layout='vertical'
				onChange={handleChange}
				onFinish={handleSubmit}
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							required label="Expression Name" name="regex_name" placeholder="Expression Name"
							tooltip="Expression Name is one of the basic parameters for defining an Expression. Name must be unique and alphanumerical. "
							rules={vsmRegularExpression.validation.expression_name}
							maxLength={100}
							autoComplete="off"
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							required label="Regular Expression" name="regex_value" placeholder="Regular Expression"
							tooltip="Regular Expression is standard expression used to filter part of string matching with it. This will be used to filter organisation, people, IBAN, account number or Tag from Transaction Details string of Bank Statement."
							rules={vsmRegularExpression.validation.regular_expression}
							maxLength={200}
							autoComplete="off"
							prefix={["/"]}
							suffix={["/g"]}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							label="Verification String" name="string" placeholder="Verification String"
							tooltip="Verification String used to test regular expression and it’s output. Regular Expression will be applied on Verification String and output will be displayed in below field."
							rules={vsmRegularExpression.validation.verification_string}
							maxLength={500}
							autoComplete="off"
						/>
					</Col>
					<Col span={12}>
						<InputComponent
							disabled label="Output" name="output" placeholder="Output"
							tooltip="Output will get displayed over here after applying Regular expression on Verification string. This is used to test Regular expression."
							maxLength={100}
							autoComplete="off"
							style={{ borderColor: outputStatus }}
						/>
					</Col>
				</Row>

				<InputComponent
					required type="textarea" label="Description" name="description" placeholder="Description"
					tooltip="Description is additional details for given Regular Expression."
					rules={vsmRegularExpression.validation.description}
					maxLength={1000}
					autoComplete="off"
				/>
			</Form>
		</Modal>
	)
})

export default FormComponent
