import React, { Component, useEffect, useState } from 'react'
import { Form, Input, Button, Tooltip, Divider, Drawer } from 'antd'
import { observer } from 'mobx-react'
import { InfoCircleOutlined } from '@ant-design/icons';
import useStore from '../../../../../store'
import { vsmNotify, vsmAlgorithm } from '../../../../../config/messages'
import AlgorithmBuilder from '../../../../../components/AlgorithmBuilder';
import { DragDropContext } from 'react-beautiful-dnd';
import $ from 'jquery'

const ViewComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AlgorithmsStore } = useStore()
	const [viewValues, setViewValues] = useState(null)

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			AlgorithmsStore.getAllTransactionFields();
			AlgorithmsStore.getRecordById({ id: props.id }).then((data) => {
				setViewValues(data.data)
			}).catch(() => {
				vsmNotify.error({
					message: vsmAlgorithm.InvalidRecord
				})
				props.close()
			})
		}
	}, [props, AlgorithmsStore])

	class ViewComponent extends Component {
		constructor(props) {
			super(props)
			this.state = {
				formula: {},
				formula_status: 'error',
				saving: false,
				disabled: false,
				fields: [],
				field_list: this.props.field_list,
				readable_format: null
			}
		}

		componentDidMount() {
			if (this.props.viewValues) {
				form.setFieldsValue({
					id: this.props.viewValues.id,
					algorithm_name: this.props.viewValues.algorithm_name,
					algorithm_desc: this.props.viewValues.algorithm_desc,
				})
				this.setFormula(JSON.parse(this.props.viewValues.algorithm_json || '{}'))
				this.setState({
					readable_format: this.props.viewValues.readable_format
				})
			}

			window.onmousemove = logMouseMove;

			function logMouseMove(event) {
				let e = event || window.event;
				window.x = e.clientX
				window.y = e.clientY
			}
			var temp_interval = setInterval(function () {
				if ($('.sticky_fields').length > 0) {
					clearInterval(temp_interval)
					var stickyTop = $('.sticky_fields').offset().top;

					$(window).scroll(function () {
						var windowTop = $(window).scrollTop();

						if (stickyTop < windowTop) {
							$('.sticky_fields').css('top', (windowTop - stickyTop) + 'px');
						} else {
							$('.sticky_fields').css('top', '0');
						}
					});
				}
			}, 200)
		}

		// reset form and close view form
		close = () => {
			this.props.setViewValues(null)
			this.props.close()
		}

		setFormula = (formula) => {
			var readable_format = this.props.AlgoStore.createReadableFormula(formula)
			this.setState({
				formula,
				fields: readable_format.fields,
			})
		}

		render() {
			return this.props.viewValues ? (
				<>
					<Drawer
						title={`View Algorithm Details - #${this.props.viewValues.id} - ${this.props.viewValues.algorithm_name}`}
						visible={this.props.visible}
						onClose={this.close}
						placement='right'
						width={'75%'}
						destroyOnClose={true}
						footer={[
							<div className="text-center">
								<Button onClick={this.close}>Cancel</Button>
							</div>
						]}
					>
						<Form form={this.props.form}
							labelCol={{ span: 5 }}
							className="builder_wrapper"
						>
							<DragDropContext>
								<Form.Item name="algorithm_name" rules={vsmAlgorithm.validation.algorithm_name} label="Algorithm Name" required tooltip='Algorithms is a tool for building a finite sequence of defined mathematical calculations optionally embedded within logical expressions. Algorithms are used in Transaction Models to calculate values for Journal Entries. Every Algorithm has to have its own unique Id and a Label.'>
									<Input disabled placeholder="Algorithm Name" maxLength={100} />
								</Form.Item>

								<Form.Item name="algorithm_desc" rules={vsmAlgorithm.validation.algorithm_desc} label="Algorithm Description" required tooltip='More details about Algorithm, i.e. what it does and what it depends on, etc.'>
									<Input.TextArea disabled placeholder="Algorithm Description" maxLength={1000} />
								</Form.Item>
								<h3>
									<span>Algorithm Builder</span>
									<small>
										<Tooltip title="Main Algorithm Builder with different combination of conditions and arithmetic values and formulas to run. This is core of algorithm and will generate values based on field inputs in Transactions.">
											<InfoCircleOutlined size={10} className="ml-10" />
										</Tooltip>
									</small>
								</h3>
								<div className="view_wrapper valid_always">
									<AlgorithmBuilder main={this.state.formula} formula={this.state.formula} setFormula={this.setFormula} />
								</div>
								<div className="mt-10">
									<h4>
										<span>Algorithm Parsed</span>
										<Tooltip title="This is status of Algorithm whether it is Parsed successfully or not with all given formulas and conditions.">
											<InfoCircleOutlined size={10} className="ml-10" />
										</Tooltip>
									</h4>
									<div className={this.props.viewValues.last_parse_result === 'Success' ? "text-success parsed_wrapper" : "text-danger parsed_wrapper"}>
										{this.props.viewValues.last_parse_result === 'Success' ? 'Parse Success' : 'Parse Error that must be resolved.'}
									</div>
								</div>
								<div className="mt-10">
									<h4>
										<span>Readable Format</span>
										<Tooltip placement="topRight" title="It text-string formatted representation of the Algorithm. It can be easily readable by users.">
											<InfoCircleOutlined className="ml-10" />
										</Tooltip>
									</h4>
									<div className={this.props.viewValues.last_parse_result === 'Success' ? 'text-success' : 'text-danger parsed_wrapper'}>
										{this.state.readable_format ? this.state.readable_format : "Algorithm Box (Automatically fill algorithm in this box)"}
									</div>
								</div>
							</DragDropContext>

							<Divider />
						</Form>
						<div className="formulize-field-wrapper" style={{ display: "none" }}>
							{this.state.field_list && this.state.field_list.length > 0 ? this.state.field_list.map((item, index) => (
								<div
									className={"formulize-custom draggable_" + item.id}
									data-value={JSON.stringify({ item: item.id })}
								>
									{item.field_name}
								</div>
							)) : <div className="text-center">No Record Found</div>}
						</div>
					</Drawer>
				</>
			) : null
		}
	}

	return (
		<ViewComponent form={form} field_list={AlgorithmsStore.transaction_field_list} viewValues={viewValues} AlgoStore={AlgorithmsStore} {...props} setViewValues={setViewValues} />
	)

})

export default ViewComponent
