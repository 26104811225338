import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../components/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import useStore from '../../../../store';

const CustomFieldsElement = observer(({ initialValue, onChange, form }) => {
	const { LedgerBankAccountStore } = useStore()

	const fieldList = LedgerBankAccountStore.dynamicFieldList

	const handleChange = async () => {
		onChange()
	}

	return (
		<div className="w-100">
			<Form.List name='cte_fields' initialValue={[{ operator: "=" }]}>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							let options = { rules: [{ required: false },] }
							return (
								<div className="w-100" key={field.key}>
									<Row gutter={24} style={{ flexWrap: 'nowrap' }}>
										<Col offset={6} span={8}>
											<InputComponent
												type="select"
												placeholder="Select Transaction Field"
												onChange={handleChange}
												options={{
													values: fieldList,
													value_key: "id",
													text_key: "field_name"
												}}
												name={[field.name, 'id']}
												key={[field.key, 'id']}
											/>
										</Col>
										<Col span={2}>
											<InputComponent
												type="select"
												placeholder="Select Operator"
												onChange={handleChange}
												options={{
													values: [
														{ text: "=", value: "=" }
													]
												}}
												initialValue={initialValue}
												name={[field.name, 'operator']}
												key={[field.key, 'operator']}
											/>
										</Col>
										<Col flex={1}>
											<InputComponent
												placeholder="Value"
												onChange={handleChange}
												{...options}
												name={[field.name, 'value']}
												key={[field.key, 'value']}
											/>
										</Col>
										<Col className="text-center d-flex">
											{(fields.length > 1) && (
												<Button type="danger" onClick={() => { remove(field.name); handleChange() }} icon={<MinusOutlined />}></Button>
											)}

											{(index === (fields.length - 1) && (fields.length < 12)) && (
												<Button className={fields.length > 1 ? 'ml-10' : ''} type="primary" onClick={() => { add(); handleChange() }} icon={<PlusOutlined />}></Button>
											)}
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default CustomFieldsElement
