import React from 'react'
import { CurrencyFormat, DateComparator } from '../../../../../utils/GlobalFunction'
import { Badge } from 'antd'

export const gridOptions = {
	columnDefs: [{
		checkboxSelection: true,
		filter: false,
		sortable: false,
		floatingFilter: false,
		width: 70,
		suppressMenu: true,
		pinned: 'left',
	}, {
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80,
	}, {
		headerName: 'Exchange ID',
		field: 'exchange_ref_id',
		valueGetter: (params) => (params.data && params.data.exchange_ref_id) ? params.data.exchange_ref_id : null,
		width: 130
	}, {
		headerName: 'Transaction Ref',
		field: 'reference',
		valueGetter: (params) => (params.data && params.data.reference) ? params.data.reference : null,
		width: 130,
	}, {
		headerName: 'Traded',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Posted',
		field: 'posted_on',
		filter: 'agDateColumnFilter',
		minWidth: 220,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		type: 'rightAligned',
		cellRendererFramework: (data) => {
			return data.data && <CurrencyFormat value={data.data.face_value ? data.data.face_value : 0} />
		},
		width: 110
	}, {
		headerName: 'Outstanding',
		field: 'outstanding',
		type: 'rightAligned',
		cellRendererFramework: (data) => {
			return data.data && <CurrencyFormat value={data.data.outstanding ? data.data.outstanding : 0} />
		},
		width: 130
	},
	{
		headerName: 'Reconciliation Status',
		field: 'status_name',
		cellRendererFramework: (data) => {
			return data.data.status_name ? (
				<Badge
					className="site-badge-count-109"
					count={data.data.status_name}
					style={{ backgroundColor: '#52c41a' }}
				/>
			) : null
		},
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}]
}
