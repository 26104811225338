import React from 'react'
import { Form, Input } from 'antd'

const CustomComponent = (props) => {
	let extra = {}
	let invInput = /^(?!.*([.-]).*\1)[.A-Za-z0-9- ]+$/

	if (!props.formula.value) {
		if (props.formula.value === null || props.formula.value === undefined) {
			extra.validateStatus = 'error'
		} else if (typeof (props.formula.value) === 'string' && props.formula.value.trim() === '') {
			extra.validateStatus = 'error'
		}
	} else if (!invInput.test(props.formula.value)) {
		extra.validateStatus = 'error'
	}
	return (
		<Form.Item {...extra} >
			<Input defaultValue={props.formula.value} value={props.formula.value} onChange={(e) => props.handleChange(e.target.value)} className="w-100" placeholder="Custom Value Textbox" />
		</Form.Item>
	)
}

export default CustomComponent
