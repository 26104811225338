import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import { DateComparator } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'
import { Typography } from 'antd'

const PreviewCashReportTable = observer((props) => {
	const { BankAuditReportStore, BankAuditReportStore: { onGridChangedPaymentsReceiptsDrawer } } = useStore()
	const { Title } = Typography;
	let total = 0;

	let gridOptions = {
		columnDefs: [
			{ headerName: '# ID', field: 'id', headerTooltip: "# ID", tooltipField: "id", filter: 'agNumberColumnFilter', pinned: 'left', width: 64 },
			{
				headerName: 'Posted Date', field: 'date', headerTooltip: "Posted Date", tooltipField: "date", filter: 'agDateColumnFilter', width: 85,
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					comparator: DateComparator
				}
			},
			{
				headerName: 'Transaction Details', field: 'transaction_details',
				headerTooltip: "Transaction Details",
				tooltipField: "transaction_details",
				width: 155,
				valueGetter: (params) => (params.data && params.data.transaction_details) ? params.data.transaction_details : "",
			},
			{
				headerName: 'Transaction Type', field: 'transactions_type', headerTooltip: "Transaction Type", tooltipField: "transactions_type", filter: 'agSetColumnFilter',
				width: 80,
				filterParams: {
					defaultToNothingSelected: true,
				},
				valueGetter: (params) => (params.data && params.data.transactions_type) ? params.data.transactions_type : "",
			},
			{
				headerName: `${props.modeType ? props.modeType.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : 'Amount (Debit / Credit)'}`, field: 'amount', filter: 'agNumberColumnFilter',
				headerTooltip: `${props.modeType ? props.modeType.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') : 'Amount (Debit / Credit)'}`,
				tooltipField: "amount",
				width: 80,
				cellRendererFramework: (params) => (params.data) ? (
					<div style={styles.cells}>
						<NumberFormat value={params.data.amount} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
					</div>
				) : null
			},
			{
				headerName: 'Currency', field: 'currency_code', filter: 'agSetColumnFilter',
				headerTooltip: "Currency",
				tooltipField: "currency_code",
				width: 51,
				filterParams: {
					defaultToNothingSelected: true,
				}
			},
			{
				headerName: 'Reconciled Bank', field: 'reconciled_bank', filter: 'agNumberColumnFilter',
				headerTooltip: "Reconciled Bank",
				tooltipField: "reconciled_bank",
				width: 80,
				cellRendererFramework: (params) => (params.data) ? (
					<NumberFormat value={params.data.reconciled_bank} displayType={'text'} />
				) : null
			}, {
				headerName: 'Currency', field: 'currency_code', filter: 'agSetColumnFilter',
				tooltipField: "currency_code",
				headerTooltip: "Currency",
				width: 51,
				filterParams: {
					defaultToNothingSelected: true,
				}
			}, {
				headerName: 'Organisation / People Name',
				headerTooltip: 'Organisation / People Name',
				field: 'entity_name',
				tooltipField: 'entity_name',
				filter: 'agSetColumnFilter',
				width: 180
			}, {
				headerName: 'Role',
				headerTooltip: 'Role',
				field: 'role_id',
				tooltipField: 'role_id',
				filter: 'agSetColumnFilter',
				width: 78
			}, {
				headerName: 'Related Organisation / People Name',
				headerTooltip: 'Related Organisation / People Name',
				field: 'related_entity_name',
				tooltipField: 'related_entity_name',
				filter: 'agSetColumnFilter',
				width: 180
			}, {
				headerName: 'Related Role',
				headerTooltip: 'Related Role',
				field: 'related_role_id',
				tooltipField: 'related_role_id',
				filter: 'agSetColumnFilter',
				width: 120
			}, {
				headerName: 'Status',
				headerTooltip: 'Status',
				field: 'reconciliation_status',
				tooltipField: 'reconciliation_status',
				filter: 'agSetColumnFilter',
				width: 144
			}
		]
	}

	if (props.rowData) {
		props.rowData.map(item => {
			if (item.amount) {
				total = total + item.amount
			}
			return item
		})
	}

	const styles = {
		cells: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			justifyContent: 'center',
			height: '50%'
		}
	}

	let columns = []
	let columnConfig = null /* As null for not showing child data */
	if (columnConfig) {
		let data = columnConfig
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	if (props.rowData && Array.isArray(props.rowData)) {
		return (
			<div className="" style={{ height: 'calc(100vh - 120px)', marginBottom: 15 }}>
				<div className="ag-theme-alpine grid_wrapper reduce_height">
					<AgGridReact
						rowData={props.rowData}
						modules={AllModules}
						columnDefs={columns}
						defaultColDef={{ ...LocalGridConfig.defaultColDef, floatingFilter: true }}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						onGridReady={BankAuditReportStore.setupGridPaymentsReceiptsDrawer}
						gridOptions={{ ...LocalGridConfig.options, pagination: false }}
						onColumnResized={onGridChangedPaymentsReceiptsDrawer}
						onColumnMoved={onGridChangedPaymentsReceiptsDrawer}
						onColumnPinned={onGridChangedPaymentsReceiptsDrawer}
						onSortChanged={onGridChangedPaymentsReceiptsDrawer}
						rowHeight={30}
					/>
				</div>
				<Typography className='text-right mb-0 pt-5'>
					<Title level={5}>Total: {Number(total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Title>
				</Typography>
			</div>
		)

	} else {
		return null
	}
})

export default PreviewCashReportTable
