import React from 'react'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import useStore from '../../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditDrawer, openViewDrawer, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{(UserStore.checkPermission(22, 'view')) && <Button title="View Trade Transaction" type="text" onClick={() => { openViewDrawer(props.data.id) }}><EyeOutlined /></Button>}
			{(UserStore.checkPermission(22, 'edit')) && <Button title="Edit Trade Transaction" type="text" onClick={() => { openEditDrawer(props.data.id) }}><EditOutlined /></Button>}
			{(UserStore.checkPermission(22, 'delete')) && <Button title="Delete Trade Transaction" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Exchange ID',
		field: 'exchange_ref_id',
		width: 150
	}, {
		headerName: 'Mapping Type',
		field: 'mapping_name',
		width: 150
	}, {
		headerName: 'Organisation/People Name',
		field: 'entity_name',
		width: 150
	}, {
		headerName: 'Related Organisation/People Name',
		field: 'related_entity_name',
		width: 150
	}, {
		headerName: 'Transaction Reference',
		field: 'reference',
		width: 150
	}, {
		headerName: 'Traded',
		field: 'traded_on',
		width: 150
	}, {
		headerName: 'Posted',
		field: 'posted_on',
		width: 185
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		width: 150
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
